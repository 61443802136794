<template>
  <Modal :name="'team_2'" ref="modal" :isWhite="true">
    <p>如果你想瞭解更多關於爸爸育兒的資訊，歡迎採取下列行動喔！</p>
    <br>
    <br>
    <h4>查看相關數據：</h4>
    <p>
      •　2020年 信誼基金會<a href="https://bit.ly/3xLMrZR" target="_blank"> ⟪新世代爸爸育兒參與大調查⟫ </a><br/>
      •　財團法人婦女權益促進發展基金會<a href="https://bit.ly/36IXbMF" target="_blank"> ⟪2020 回家吧育兒調查報告書⟫ </a>的研究結果
    </p>
    <br>
    <br>
    <h4>日常行動：</h4>
    <p>
      •　查看部落客 <a href="https://bit.ly/3rdYc8G" target="_blank">隱藏角色 的臉書粉絲專頁，看爸爸作為主要照顧者的日常</a><br>
      •　查看 2019 年<a href="https://bit.ly/3rbReRQ" target="_blank"> ⟪爸比的育兒日常攝影展⟫ </a>線上展覽
    </p>
    <br>
    <br>
    <h4>法律面：</h4>
    <p>•　可以多了解勞基法、性別就業平等法的相關條文，並以「懷孕歧視」、「友善生育職場」等關鍵字搜尋，閱讀更多深入報導與介紹。（以<a href="https://bit.ly/3wMQvrg" target="_blank">婦女新知基金會</a>為例）</p>
    <br>
    <br>
    <h4>心理面：</h4>
    <p>•　可以多認識男性諮商，了解現代男性在社會中所承受的壓力，以及重新重視男性的心理健康（以<a href="https://bit.ly/3rfMivd" target="_blank">城男舊事心驛站</a>為例）</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2018 Dadable 爸爸大冒險 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
