<template>
  <Modal :name="'partner_11'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>浪犬博士-狗兒家庭教育學院／子馨(Lucy)</h3>
    <p>關於【浪犬博士 - 狗兒家庭教育學院】</p>
    <p>#讓訓練變得好玩又簡單</p>
    <p>#享受與狗狗相處最純真的快樂</p>
    <p>由訓練師 Lucy 創立的「浪犬博士」社會企業，讓每個人都能用正確的方式和狗兒和平相處。</p>
　　 <p>透過讓訓練變得簡單又好玩，協助狗狗家長透過多元且有趣的方式學習將訓練融入生活之中，並用狗狗的方式疼愛狗，享受與狗狗相處最純真的快樂。</p>
    <p>將訓練的盈餘投入於領養制度的優化、動保教育以及下一代的校園教育，以社會企業永續發展的模式，針對有養狗的人、準備要養狗的人，以及未來要養狗的下一代的教育能正向循環，持續下去。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_11.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
