<template>
  <Modal :name="'giant_room_5'" ref="modal">
    <br/>
    <br/>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <h5 class="t-center">(古壁畫的某一段落，紀錄著這段話⋯⋯)</h5>
    <br/>
    <br/>
    <p>「第一年的過程當中，我有一段時間生病，中間過程沒有參與，後來我再加入的時候是最終成果展，同時，青年署當時有另一個大計畫在華山展覽。我是負責接待部長的，部長那時候從某一個展場過來，覺得那一個展還好，就提早來迴響的展，他一開始來的時候，並不是我們原本預期他要出現的時間點，大家如常的進行，而部長就站在那邊，就聽完大家現在正在做的事，然後他和十個攤位的青年，一組組深入的對談，我相信他那天是有感動的。</p>
    <p>我大概有接待過他幾次，好幾次的大活動都發現他沒有待的時間像我們想像那麼久，迴響這次這麼專心的聽完，跟每一個團隊互動，也是印象唯一一次。</p>
    <p>成果展那天，和團隊的個性有關，整個策展很大張旗鼓的布置，對傳統公部門觀點來說是不是可以省？是不是不必要？但是我當下就明白，每一個展場的東西，無論是人或非人的布置也好，對每一個行動者都會造成影響的，即便是一個眼神，或是一個理念，都會讓這個行動長的不一樣，這些外溢的校果，是不可預期的驚喜。</p>
    <p>這個計畫讓我知道，無論你是哪個角色，身份高低，都會被參與的人感動，也會覺得我們作為公部門，應該還可以再做些甚麼。</p>
    <p>我更相信，任何年輕人，即便小小的點、小小的想法，可能有點大膽、有點天馬行空，過去即便沒有看過的案子，感覺起來不可能發生，我都要給他們機會，讓他們可以萌芽，迴響也影響我，會去鼓勵每一個人，如果他們來到我面前，告訴我一個想法，我也都會盡能力所及，鼓勵大家去試試看。」</p>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
