<template>
  <Modal :name="'planet_honesty'" ref="modal" :isWhite="true">
    <h3>真誠 Honesty</h3>
    <p class="t-center">「真誠是與這個世界溝通的基石。」</p>
    <br />
    <p>真誠是不貪心，不去假裝自己沒有的東西，不去武裝心底其實覺得不對的事情，真誠是開始樂意擁抱自己。</p>
    <p>迴響重視的價值是，你是否清楚明白，面對這個世界，其實自己已然具足，毋須更多妝點了。</p>
    <img src="../../images/world/NPC/events/Planet1Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
