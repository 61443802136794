<template>
  <Modal :name="'y2020_9'" ref="modal" :isWhite="true">
    <img src="./images/2020_9.jpg" alt="">
    <p>做網站要崩潰時，那一天所有人跑到陽台去看外面的彩虹，這約莫時半年前的事，第四屆正要開啟報名的時候。</p>
    <p>現在，到了尾聲，也應該是快要崩潰之際吧，所有人 WFH 在家裡面遠端連線 、支持，完成我們心目中那最完整、能串連所有人事物的成果。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
