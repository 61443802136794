<template>
  <Modal :name="'ax_checkin'" ref="modal" :isWhite="true">
    <h5><b>團隊才剛起步的階段，成員彼此還不熟，開會討論怪尷尬的...怎麼辦？</b></h5>
    <br />
    <br />
    <p>女神就是因為罹患尷尬癌後，修練成佛的，我的結論是，這種事情很講緣分。</p>
    <p>你可以試著邀請大家寫這份使用說明書：<a href="https://docs.google.com/presentation/d/1sI-PF-x75jv8d8Vi3Hme3-eB_ZbCGPWiDiMjMXExJL8/edit#slide=id.gd599a005f5_0_0" target="_blank">使用說明書範例</a>，增進彼此的瞭解。</p>
    <p>但如果是沒有團隊心理安全感的環境，寫的內容反而會讓人很尷尬，如果想要有簡單的方式開始，或許可以在每次會議開始時 Check-in 一下吧！</p>
    <p>這裡是由迴響團隊夥伴 / Alphar / 網路鋼琴手 / 新科 IOS 工程師 翊勤製作的 <a href="https://echim2016.github.io/check-in-box/#" target="_blank">Check-in Box</a> 歡迎使用，並勇於貢獻題目。 ​​</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
