<template>
  <Modal :name="'y2018_4'" ref="modal">
    <!-- <img src="./images/2018_4-1.jpg" alt=""> -->
    <p>休息，充電，反思，也是行動重要的一環。</p>
    <p>第二屆也更想凸顯這一件事，因此在活動的過程還有空間上，有幸有機會，讓夥伴們具象化日本和室的空間，也邀請了瑜珈老師到現場，讓青年團隊可以放鬆、舒展一下。</p>
    <p>有時候執行團隊，真的是一群不會放過自己的人，克服挑戰、限制，把真正想呈現、表達的事，盡力做到完整，為的就是那一刻的感受。</p>
    <!-- <img src="./images/2018_4-2.jpg" alt=""> -->
    <!-- <img src="./images/2018_4-3.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
