<template>
  <Modal :name="'partner_4'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>文化銀行／璦婷</h3>
    <p>關於【文化銀行】</p>
    <p>＃用活的方式保存我們的文化</p>
    <p>文化銀行由一群關注到傳統文化正在流逝的青年所發起，以各種方式採集、保留臺灣傳統技藝及生活樣態。文化銀行不僅留存記憶的圖像及形體，更要發揮人文思考的價值，使文化不再只是名詞，而以生動面貌重新活在你我的生活中。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_4.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
