<template>
  <Modal :name="'y2018_5'" ref="modal">
    <!-- <img src="./images/2018_5-1.jpg" alt=""> -->
    <p>完成任務包，經過評選後進入到大會師的組別，每一組都有設計思考教練的陪伴。</p>
    <p>青年會在什麼時候找迴響教練呢？</p>
    <p>...不知道怎麼開會、和夥伴吵架沒有共識的時候；下一次的行動要實驗的目標有點模糊的時候；想做的事情好像超乎能力、卻又想挑戰自己的時候...</p>
    <p>在三個月的時間裡，和教練聊聊的過程中，似乎也就在某些青年的行為模式裡，養成了許多新的思考方法，打破習慣，往不曾想過的地方試試。</p>
    <!-- <img src="./images/2018_5-2.jpg" alt=""> -->
    <!-- <img src="./images/2018_5-3.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
