// import { createRouter, createWebHistory } from 'vue-router'
// import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
import Map from '../../map.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../../home.vue'),
    // component: () => import('../../actionroad.vue'),
  },
  {
    path: '/actionroad',
    name: 'Actionroad',
    component: () => import('../../actionroad.vue'),
  },
  { // all teams
    path: '/universe',
    name: 'Universe',
    component: () => import('../../universe.vue')
  },
  { // team landing page
    path: '/universe/:team_id(\\d+)',
    name: 'Team',
    component: () => import('../../team.vue'),
  },
  { // all teams
    path: '/universe/:function',
    name: 'UniverseAnimation',
    component: () => import('../../universe.vue')
  },
  { // team page edit
    path: '/universe/:team_id/edit',
    name: 'TeamEdit',
    component: () => import('../../team_edit.vue'),
  },
  { // activities list
    path: '/universe/:team_id/activities',
    name: 'Activities',
    component: () => import('../../activities.vue'),
  },
  { // new activity page
    path: '/universe/:team_id/activity/new',
    name: 'ActivityNew',
    component: () => import('../../activity_edit.vue'),
  },
  { // activity page
    path: '/universe/:team_id/activity/:activity_id',
    component: () => import('../../activity.vue'),
  },
  { // activity page edit
    path: '/universe/:team_id/activity/:activity_id/edit',
    name: 'ActivityEdit',
    component: () => import('../../activity_edit.vue'),
  },
  {
    path: '/universe/*',
    name: '404',
    component: () => import('../../universe.vue')
    // or 404?
  },
  {
    path: '/universe_exhibition',
    name: 'Exhibition',
    component: () => import('../../exhibition.vue')
  },
  {
    path: '/universe_exhibition/lessons',
    name: 'ExhibitionLessons',
    component: () => import('../../exhibition_lessons.vue')
  },
  {
    path: '/universe_exhibition/plans',
    name: 'ExhibitionPlans',
    component: () => import('../../exhibition_plans.vue')
  },
  {
    path: '/universe_exhibition/methodology',
    name: 'ExhibitionMethology',
    component: () => import('../../exhibition_methodology.vue')
  },
  {
    path: '/universe_exhibition/:team_id(\\d+)',
    name: 'ExhibitionTeam',
    component: () => import('../../exhibition_team.vue')
  },
  {
    path: '/universe_exhibition/:team_id/edit',
    name: 'ExhibitionTeamEdit',
    component: () => import('../../exhibition_team_edit.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/map/dev',
    name: 'DevelopForm',
    component: () => import('../../map/develop.vue')
  }
]


// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
const router = new VueRouter({
  mode: 'history',
  // notice that use history mode in Rails I should research it
  routes: routes
})

// const app = new Vue({
//   routes: routes,
//   el:"#app"
// })

export default router
