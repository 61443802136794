<template>
  <Modal :name="'s2_team_type_tree_4'" ref="modal" :isWhite="true">
    <h5 class="t-center">團隊樣貌</h5>
    <br>
    <h3>Side Project</h3>
    <p>迴響不覺得都要辭去工作、全心投入，才叫做為自己的議題行動，在自己的人生中創造一個 Side Project 的空間，有時會長得大一點、有時小一點，對團隊來說更能平衡人生中的不同需求，也是迴響常見的樣貌。</p>
    <br>
    <br>
    <br>
    <h3>2019 暗房裡的人</h3>
    <p>最初的提案是：「我們如何能夠為受刑人和更生人在與社會之間建立一座可以相互溝通與理解的橋梁？」</p>
    <p>這是提案者與親戚間的真實議題，在迴響的過程中拜訪相關的基金會、矯正單位來了解議題的真實限制、在人生百味的重修舊好空間舉辦了小型展覽，除了讓觀展者有基礎可以同理更生人，也找到第一波的議題參與者，推出了「貓頭鷹送信計畫」，讓人能和受刑人建立書信關係，交換故事。對暗房裡的人來說，犯罪脈絡背後其實有許多故事與人性，而不是一群惡人。</p>
    <p>暗房裡的人離開迴響後正式成立協會，雖然成員都有自己的正職工作，但成員一樣投入非常多的時間，持續經營。</p>
    <br>
    <br>
    <br>
    <h5 class="t-center">像這樣的團隊，還有 2018 SUS 750、2018 Money 好朋友、2018 Be There 不見不散、2019 找自己、2019 眾森、2019 波波研究室、2019 生活擺態、2019 smbes 詩門倍思...</h5>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
