<template>
  <Modal :name="'s0_board'" ref="modal" :isWhite="true"> 
    <br>
    <br>
    <br>
    <br>
    <h3>警告</h3>
    <p>因應颱風烟花來襲造成吊橋損毀，為確保行動者安全，迴圈森林區及相關設施暫時封閉，預計將於 2021/7/30 再次開放。</p>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
