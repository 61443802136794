<template>
  <Modal :name="'y2020_8'" ref="modal" :isWhite="true">
    <img src="./images/2020_8-1.jpg" alt="">
    <p>今年的重大突破之一，就是拉長青年的行動，和以行之有年的議題團隊，一起合作、探詢：「號召志工的心法，把人找來，然後呢？」</p>
    <p>六組議題團隊，過去幾年下來，都透過實際行動累積一定程度與深度的知識、經驗，也藉由這次的合作機會，試著寫出「號召的方法論」，找到創新志工的新可能。</p>
    <p>第一步，往往非常困難，有機會邀請您來聽我們的線上直播，分享這一次的實驗心得。</p>
    <img src="./images/2020_8-2.jpg" alt="">
    <img src="./images/2020_8-3.jpg" alt="">
    <img src="./images/2020_8-4.jpg" alt="">
    <img src="./images/2020_8-5.jpg" alt="">
    <img src="./images/2020_8-6.jpg" alt="">
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
