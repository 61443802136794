<template>
  <Modal :name="'team_3'" ref="modal" :isWhite="true">
    <br>
    <br>
    <h4>首先，在聽到任何大學生的負面消息時，你可以⋯</h4>
    <p>•　停止第一時間的批判（eg現在大學生抗壓性都這麼低嗎？他是不是沒有見過世面？ＸＸ畢業有什麼用？）</p>
    <p>•　相信大學生的群體是多元的，每個行為背後一定有個人、社會等多面向的成因</p>
    <p>•　試著跳脫既有的理解，與認識的大學生開啟一段「開放」的對話，了解他的背景、所學、追求、煩惱⋯⋯簡單的對話就能為大學生帶來能量</p>
    <br>
    <br>
    <h4>若想了解更多議題現況，你可以閱讀⋯</h4>
    <p>•　相關報導：報導者<a href="https://bit.ly/2UgKs0V" target="_blank">《強光下的暗憂－背負情緒隱傷的歌學業成就青年》</a>專題</p>
    <p>•　相關團隊：<a href="https://bit.ly/3ijzgsm" target="_blank">微亮計畫</a></p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2020 失敗練習 Warm up again 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
