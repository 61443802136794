<template>
  <Modal :name="'partner_3'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>藺子／怡雅</h3>
    <p>關於【藺子】</p>
    <p>#落實工藝傳承 #建構以生產者為本的在地_文化_設計_生態圈</p>
　　 <p>「藺子」品牌工作室於 2016 年 11 月正式成立，怡雅秉持著希望產業能永續發展的初衷，促成了藺子的誕生。以「匯合過去與現在的永續生活設計」為出發，藉由設計加值與在地編藺師手工製作，達到環境友善、合理的收購價、工藝傳承的共好精神。</p>
　　 <p>怡雅與夥伴全力投入在產業鏈的復育，從藺草種植、原料處理與配送、與工藝師建立合作、品牌行銷到教育傳承。此外，我們也透過高於市價的收購標準，為工藝師們打造更理想、更穩定的就業環境。怡雅希望能夠帶領團隊串起苑裡鎮整體社區及其鄰近的鄉鎮藺草文化產業。5年內在當地創造120個工作機會，吸引人才投入產業傳承。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_3.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
