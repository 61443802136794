<template>
  <Modal :name="'s1_stone_tablet'" :isWhite="true" ref="modal">
    <h3>迴響的十年願景</h3>

    <h4>引言：認真看待一件傻事</h4>
    <p>此刻的台灣，「真的去實踐」常常被視為一件傻事。我們著迷於方便、快速、及時可用的方法或觀點，依賴專家卻又輕視專家，好像我們自己無所不能；對付出行動的人高傲的評論，對自己內心的渴望自卑而壓抑；因為太少回應內心的渴望，也漸漸聽不到自己真實的聲音，只能人云亦云，極化的言論快速獲得贊同，誰覺得有哪裡怪怪的，想要試著做點什麼，反而變成一件更傻的事...</p>
    <p>現在，想請你回想一個這樣的時刻，當身邊的人帶著發光的眼睛和你說：「欸，你們有沒有想過...」「你聽聽看！我在想，或許可以...」「我有一個新的想法...」時...你是怎麼回應他的呢？無論你是大力支持，還是澆下一盆成熟的冷水，我們都希望可以提供你一個重新看待「行動」的觀點。</p>

    <h4>迴響的初心：相信行動的價值</h4>
    <p>相信偶爾幹點傻事，是值得被鼓勵的事，因為...</p>
    <p>實際試著做做看以後，人會變的謙虛，認清理想和現實間的差距，瞭解我們還需要多少努力，和彼此的幫助，才能共同成就一個更理想的社會；</p>
    <p>實際試著做做看以後，人會變的清楚，認清自己真的喜歡的事情，不再忽視內心的渴望，也能更細心的照顧其他人的渴望；</p>
    <p>實際試著做做看以後，人會變的勇敢，即便能做到的有限，也會因為更清楚自己做得到的事情，而有了要變的更好的信念，終於能夠為自己所有的而堅持，去面對更困難的事。</p>

    <h4>迴響的願景：讓行動成為一個自然而然的選項</h4>
    <p>直到我們一起生活在這樣一個社會：</p>
    <p>當你有不同意見的時候，大家會在肯定或否定之前，先期待你實際行動後的反思和結果，因為每個人都會同意，這個社會有太多值得並存的價值，有很多我們還沒有能力發現的事，面對變動和挑戰，我們永遠要撐開一些空間給「試著做看看」。</p>
    <p>當更多人放心地為自己相信的事情投入行動，我們就能一起明白，要達成理想有多麼困難，在對立的觀點之間，需要有更多實際的行動支撐起對話的橋樑，於是你可以過來，我也可以過去，在交會的時候明白，我們其實沒有這麼不同；於是沒有人需要屈就於待在原地抱怨，你不需要變的極端才有存在的價值，每一條路，都值得走，每一個人，都有機會被接受。</p>

    <h4>迴響的使命：給心裡還有渴望的人，一個採取行動的藉口</h4>
    <p>今年（2021）是忌諱行動的一年，我們變的不敢出門，討厭別人群聚，在網路上指謫我們覺得做錯事情的人，沒有幾天風向改變，我們又開始尋找新的錯誤，等待下一個發洩的機會。雖然很負面，但這種時刻也幫助我們重新認識，其實每個人的心裡都存著一股能量，在疫情的期間因為沒有出口，而毫無道理的釋放。</p>
    <p>怎麼會憑空生氣呢？我們是壞的人嗎？如果靜下心來仔細一想，這股「想做點什麼」的能量，應該是來自於我們對理想狀態的渴望。</p>
    <p>除了對社會的期待，也有對自己的期待。總是想創造一些屬於自己的東西、想知道自己會變成什麼樣子、想看見不一樣的世界、想為珍惜的人事物真的做點什麼，一旦渴望無法化為行動時，就成了很深的挫折，轉為同樣程度的負面能量，藉由攻擊他者消化這種失望。</p>
    <p>是阿，無論未來的世界裡，行動如何被鼓勵，採取行動仍代表把自己丟進可能會失敗的處境，代表為自己的決定負責，代表為那些被捨棄的選項負責，「要不要試試看呢？」面對這種超級值得卻步的時刻，我們需要夥伴、需要工具、需要拉出能看清自己的空間、需要更多與外界溝通自己想法的機會，即便相信行動的價值，這些需求仍無可迴避的存在，這是迴響要一直這樣走下去的原動力。</p>
    <p>迴響計畫基於相信行動的價值，致力於將青年對理想社會（理想中的自己）的渴望，轉換成可以採取行動的方案，設計階段性的目標和支持系統，讓每一次行動都有學習的效益，讓有共同信念的夥伴們，一起期待你的下一步，讓每個人都能體會，看見自己的行動超展開的樂趣。</p>
    <p>未來，當你心中的渴望發芽時，要記得總會有一個計畫會支持你，用你可以做得到的方式去嘗試。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
