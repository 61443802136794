<template>
  <div class="dialog_screen">
    <div class="dialog_image" v-if="player.interaction.nowDialogImage">
      <!-- <img :src="require('../images/world/dialog/' + player.interaction.nowDialogImage + '.jpg')"/> -->
      <vue-img-loader :src="returnDialogImage(player.interaction.nowDialogImage)"
                :preview="returnPreviewDialogImage(player.interaction.nowDialogImage)"
                centerType="contain"
                class="lazyloading"
                transition="fade"></vue-img-loader>
      <!-- <div class="loading_text">
        Loading⋯⋯
        width="200"
        height="100"
      </div> -->
    </div>
    <div class="dialog">
      <div class="dialog_box" v-if="player.interaction.nowDialog">
        <div v-if="NPCShot" class="shot">
          <img :src="require('../images/world/NPC/shot/' + NPCShot + '.svg')"/>
        </div>
        <img class="bg" src="../images/world/dialog_frame.svg"/>
        <div :class="{name: true, player_name: isTalkingToMySelf}">
          {{dialogName}}
        </div>
        <!-- <vue-typed-js v-if="player.interaction.nowDialog" :strings="[player.interaction.nowDialog]"
          :startDelay="0" :typeSpeed="25" :showCursor="true" :cursorChar="'_'" :contentType="'html'">
          <div class="dialog_text typing"></div>
        </vue-typed-js> -->
        <div class="dialog_text" v-html="player.interaction.nowDialog"></div>
        <div class="next_icon frame-anim-2">
          <img draggable="false" src="../images/home/next_1.svg" alt="">
          <img draggable="false" src="../images/home/next_2.svg" alt="">
        </div>
        <div class="answers" v-if="hasAnswers">
            <img class="bg" style="height: 100%" :src="answerBoxImageSrc + '#svgView(preserveAspectRatio(none))'"/>
          <div v-for="(answer, index) in player.interaction.nowAnswers"
               :class="{answer: true, active: index == player.interaction.nowAnswerIndex && answer.text}" :key="index"
               @mouseover="setAnswerIndex(index)" @click="press_A()">
            <img v-if="index == player.interaction.nowAnswerIndex && answer.text" class="arrow" src="../images/home/polygon.svg" alt="">
            {{answer.text}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { get_random_int, is_arrow_key, is_A_key, delay } from 'js/map/global.js'
import VueImgLoader from 'vue-img-loader';

export default {
  name: 'Dialog',
  components: {
    'vue-img-loader': VueImgLoader
  },
  data: function () {
    return {
      dialogHasSpecialName: '',
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }
  },
  computed: {
    ...mapState([
      'map', 'player', 'backpack', 'modal', 'missions', 'dialogs'
    ]),
    ...mapGetters([
      'whatIsPickingUp', 'treeIsBeside'
    ]),
    NPCShot: function() {
      const mission_name = this.map.mission[this.player.avatar.x][this.player.avatar.y]
      if (mission_name && !this.isTalkingToMySelf) {
        const npc_name = this.missions[mission_name].npc
        const npc = this.dialogs[npc_name]
        if (npc && npc.shot) return npc.shot
        else return false
      }
      else return false
    },
    dialogName: function() {
      if (this.player.isTakingPicture) return this.dialogs['Atou'].name
      if (this.dialogHasSpecialName) {
        if (this.dialogHasSpecialName == 'elfName') {
          if (this.player.elfName) return this.player.elfName
          else return '迴響小小人'
        }
        else return this.dialogHasSpecialName
      }
      let mission_name = this.map.mission[this.player.avatar.x][this.player.avatar.y]
      let npc_name = this.missions[mission_name].npc
      if (!this.isTalkingToMySelf) {
        if (this.dialogs[npc_name].name == 'elfName') {
          if (this.player.elfName) return this.player.elfName
          else return '迴響小小人'
        }
        else return this.dialogs[npc_name].name
      } else {
        return this.player.name
      }
    },
    isTalkingToMySelf: function() {
      if (this.player.isTakingPicture) return false
      if (this.dialogHasSpecialName) {
        if (this.dialogHasSpecialName == this.player.name) return true
        else return false
      }
      let mission_name = this.map.mission[this.player.avatar.x][this.player.avatar.y]
      let npc_name = this.missions[mission_name].npc
      if (this.dialogs[npc_name].name) return false
      else return true
    },
    answerBoxImageSrc: function() {
      if (this.isSafari) return require('../images/world/answer_frame.png')
      else return require('../images/world/answer_frame.svg')
      // note that safari cannot stretch svg, so use png instead
    },
    hasAnswers: function() {
      if (this.player.interaction.nowAnswers && this.player.interaction.nowAnswers[0]) return this.player.interaction.nowAnswers[0].text
      return false
    }
  },
  created: function() {
  },
  mounted: function() {
    this.initial_mission_name_in_map()
    this.initial_vuex_function()
  },
  methods: {
    ...mapActions(['openModal', 'getNewItem', 'forbidActionForMillisecond',
    'elfRun', 'editItemNumber', 'reminder', 'postIt', 'missionComplete',
    'activateCableCar', 'getNewAislePass', 'readyToTakePicture', 'takePicture', 'takeOnBoat',
    'takeOffBoat', 'completeFirst', 'clearFog', 'transfer', 'delEvents',
    'savePlayerStateInCookie']),
    ...mapMutations([
      'assignMissionMapValue', 'setDialog', 'setDialogImage',
      'missionOngoing', 'setAnswers', 'setAnswerIndex', 'initialDialogFunction',
      'playSoundEffect', 'recordEvent', 'recordDelMission', 'getDog',
      'recordDelNPC', 'removeDelNPC', 'getElf'
    ]),
    initial_vuex_function() {
      let functions = {
        'pressArrowKey': this.press_arrow_key,
        'pressA': this.press_A,
        'pressEsc': this.press_esc,
        'dialog': this.dialog
      }
      this.initialDialogFunction(functions)
    },
    returnDialogImage(nowDialogImage) {
      try {
        // console.log(require('../images/world/dialog/' + nowDialogImage + '.jpg'))
        return require('../images/world/dialog/' + nowDialogImage + '.jpg')
      }
      catch(error) {
        return require('../images/world/dialog/' + nowDialogImage + '.png')
      }
    },
    returnPreviewDialogImage(nowDialogImage) {
      try {
        return require('../images/world/dialog/preview/' + nowDialogImage + '.jpg')
      }
      catch(error) {
        return require('../images/world/dialog/preview/' + nowDialogImage + '.png')
      }
    },
    initial_mission_name_in_map() {
      let mission_names = Object.keys(this.missions)
      for (let i = 0; i < mission_names.length; i++) {
        let mission_name = mission_names[i]
        let mission_triggers = this.missions[mission_name].triggers
        if (mission_triggers) {
          for (let j = 0; j < mission_triggers.length; j++) {
            let x = mission_triggers[j].x
            let y = mission_triggers[j].y
            this.assignMissionMapValue({'x': x, 'y': y, 'value': mission_name})
          }
        }
      }
    },
    press_A(e) {
      this.playSoundEffect('dialog_select')
      this.execute_mission()
      this.savePlayerStateInCookie()
    },
    execute_mission(e) {
      // execute with dialog.js and mission.js
      let mission_name = this.map.mission[this.player.avatar.x][this.player.avatar.y]
      if (this.elf_dialog_or_run()) return
      else if (this.player.isTakingPicture) mission_name = 'Atou'
      else if (mission_name === false) return this.reset_dialog()
      else if (this.this_mission_is_deleted(mission_name)) return this.reset_dialog()

      const now_mission = this.missions[mission_name]
      if (now_mission) {
        const mission_dialog = this.dialogs[now_mission.npc]
        if (now_mission.talkToMyself) {
          this.activate_dialog(mission_name, mission_dialog['onlyTalking'])
        }
        else {
          // execute mission
          if (this.check_array(mission_name, 'missionComplete')) {
            this.activate_dialog(mission_name, mission_dialog['finished'])
          } else if (this.check_all_conditions(mission_name)) {
            this.activate_dialog(mission_name, mission_dialog['justFinish'])
          } else if (this.check_array(mission_name, 'missionOngoing')) {
            this.activate_dialog(mission_name, mission_dialog['onGoing'])
          } else {
            this.activate_dialog(mission_name, mission_dialog['notFinish'])
          }
        }
      }
      else alert('something wrong! cannot find the mission')
    },
    elf_dialog_or_run() {
      // special one
      const defaultAnswer = this.player.interaction.nowAnswers[0]
      if (!defaultAnswer) return false
      if (defaultAnswer.elf) {
        // second sentence
        let dialogJson = {
            'changeName': '小小人',
            'npcDialog': defaultAnswer.elf,
            'player': [{
              'elfFrom': defaultAnswer.from
            }]
        }
        this.dialog(dialogJson)
        return true
      }
      else if (defaultAnswer.elfFrom) {
        // third sentence
        let dialogJson = {
            'changeName': '小小人',
            'npcDialog': defaultAnswer.elfFrom,
            'player': [
              {
                'elfRun': true
              }
            ]
        }
        this.dialog(dialogJson)
        return true
      }
      else if (defaultAnswer.elfRun) {
        // forth sentence (get item)
        this.elfRun()

        // seed
        setTimeout(() => {
          let dialogJson = {
            'changeName': this.player.name,
            'npcDialog': '剛剛那是什麼？他留下了<mark>小小人種子</mark>給我⋯⋯按 B 打開背包來查看吧！'
          }
          this.dialog(dialogJson)
        }, 500)
      }
      return false
    },
    this_mission_is_deleted(mission_name) {
      for (let delMission of this.player.state.delMissions) {
        if (mission_name == delMission) return true
      }
      return false
    },
    check_array(mission_name, state) {
      for (let item of this.player.state[state]) {
        if (item == mission_name) return true
      }
      return false
    },
    check_all_conditions(mission_name) {
      let pass = true
      if (!this.missions[mission_name].conditions) return pass
      const condition_list = Object.keys(this.missions[mission_name].conditions)
      for (let condition_name of condition_list) {
        if (!this.check_condition(mission_name, condition_name)) pass = false
      }
      return pass
    },
    check_condition(mission_name, item_name) {
      const items = this.missions[mission_name].conditions[item_name]
      for (let thisItem of items) {
        let complete_this_item = false
        for (let myItem of this.player.state[item_name]) {
          if (thisItem == myItem || thisItem == myItem.name) {
            complete_this_item = true
            break
          }
        }
        if (!complete_this_item) return false
      }
      return true
    },
    async activate_dialog(mission_name, dialog_set) {
      if (!dialog_set) return false // silence
      if (!this.player.interaction.nowDialog) { // if we are not dialoging now
        this.dialog(dialog_set[0]) // the first dialog
        if (this.dialogs[mission_name].sound) this.playSoundEffect(this.dialogs[mission_name].sound)
        this.forbidActionForMillisecond(400) // wait for animation
        // console.log(this.player.interaction.nowAnswers)
      }
      else if (!this.has_no_answers()) {
        const choose_answer = this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex]
        const commands = Object.keys(choose_answer)
        const next_id = choose_answer.next
        for (let command of commands) {
          const is_command = (command != 'next' && command != 'text') && command != 'ifNoItemGoTo'
          if (is_command) this.execute_special_command(mission_name, command, dialog_set)
        }
        // console.log(next_id)
        if ((next_id != null && !choose_answer.delItems) && dialog_set[next_id]) this.dialog(dialog_set[next_id]) // after press A -> go to another dialog
        else if (choose_answer.ifNoItemGoTo) {}// do nothing, special command handle it
        else this.reset_dialog() // end dialog
      }
      else this.reset_dialog() // if has no answers, still end dialog
    },
    // has_special_command() {
    //   if (this.has_no_answers()) return false
    //   if (this.player.interaction.nowDialog) { // is in dialog
    //     return this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex].command
    //   }
    //   else return false
    // },
    // get_next_id() {
    //   if (!this.player.interaction.nowDialog) {
    //     return 0 // note that 0 is the first index
    //   }
    //   if (this.has_no_answers()) return false
    //   if (this.player.interaction.nowDialog) { // is in dialog
    //     return this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex].next
    //   }
    // },
    has_no_answers() {
      if (!this.player.interaction.nowAnswers || this.player.interaction.nowAnswers.length == 0) {
        return true // there is no answers
      }
      return false
    },
    // check_if_rewards() {
    //   // note that if there is any reward set in the answer, player can get it just by selecting the answer
    //   let now_choose = this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex]
    //   return now_choose && now_choose.rewards
    // },
    get_all_rewards(mission_name) {
      // note that I push the reward to player's nowAnswers in dialog()
      // note that I don't use category now
      // console.log(this.player.interaction.nowAnswers)
      const all_rewards = this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex].rewards
      if (!all_rewards) return
      // const reward_list = Object.keys(all_rewards)
      // for (let reward_category of reward_list) {
      // const items = all_rewards[reward_category]
      for (let item_name of all_rewards) {
        this.getNewItem({
          // 'category': reward_category,
          'name': item_name
        })
        this.forbidActionForMillisecond(1000)
      }
      // }
    },
    async execute_special_command(mission_name, command, dialog_set) {
      const choose_answer = this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex]
      if (command == 'event') {
        let events = choose_answer.event
        for (let event of events) {
          this.recordEvent(event)
        }
      }
      else if (command == 'delItems') {
        this.exchangeItem(mission_name, dialog_set)
      }
      else if (command == 'rewards') {
        if (!choose_answer.delItems) this.get_all_rewards(mission_name)
        if (this.player.state.isFirst) this.reminder('按下鍵盤 B 鍵，可以打開背包查看物品')
        // note that if the mission need to del item, we should check if item number is right
      }
      else if (command == 'complete') {
        // this.del_item_in_array(mission_name, 'missionOngoing') // temp
        this.missionComplete(mission_name)
      }
      else if (command == 'ongoing') {
        if (this.player.state.missionOngoing.findIndex((element) => element == mission_name) == -1) {
          this.missionOngoing(mission_name)
        }
      }
      else if (command == 'modal') {
        const modal_name = choose_answer.modal
        this.openModal(modal_name)
        this.playSoundEffect('ding')
        // const map_dom = document.getElementsByClassName('map')[0]
        // map_dom.style['will-change'] = 'transform'
      }
      else if (command == 'modalWithDelay') {
        const modal_name = choose_answer.modalWithDelay
        setTimeout(() => {
          console.log(modal_name)
          this.openModal(modal_name)
          this.playSoundEffect('ding')
        }, 2200)
      }
      else if (command == 'delMission') {
        this.recordDelMission(choose_answer.delMission)
      }
      else if (command == 'delNPCs') {
        for (let delNPC of choose_answer.delNPCs) {
          const dom = document.getElementsByClassName(delNPC)[0]
          setTimeout(() => {
            dom.classList.add('fadeOut')
          }, 700)
          setTimeout(() => {
            if (dom) dom.style.display = 'none'
            else console.log('error! there is no ' + delNPC + ' in our NPC assets!')
          }, 1500)

          this.recordDelNPC(delNPC)
        }
      }
      else if (command == 'recoverNPCs') {
        for (let recoverNPC of choose_answer.recoverNPCs) {
          this.removeDelNPC(recoverNPC)
        }
      }
      else if (command == 'getDog') {
        this.getDog()
      }
      else if (command == 'getElf') {
        this.getElf()
      }
      else if (command == 'input') {
        this.postIt(choose_answer.input)
      }
      else if (command == 'passAisle') {
        const aisle_names = choose_answer.passAisle
        for (let aisle_name of aisle_names) {
          this.getNewAislePass(aisle_name)
        }
      }
      else if (command == 'readyToTakePicture') {
        this.readyToTakePicture()
      }
      else if (command == 'takePicture') {
        // console.log('hey')
        this.takePicture()
      }
      else if (command == 'cableCar') {
        this.activateCableCar()
      }
      else if (command == 'boat') {
        this.takeOnBoat()
      }
      else if (command == 'offBoat') {
        this.takeOffBoat()
      }
      else if (command == 'completeFirst') {
        this.completeFirst()
      }
      else if (command == 'clearFog') {
        this.clearFog()
      }
      else if (command == 'transfer') {
        this.transfer(choose_answer.transfer)
      }
      else if (command == 'transferAndDialog') {
        await this.transfer(choose_answer.transferAndDialog)
        this.press_A()
      }
      else if (command == 'delEvents') {
        for (let delEvent of choose_answer.delEvents) {
          this.delEvents(delEvent)
        }
      }
      // else {alert('bugs: 未定義的指令 ' + command)}
    },
    dialog(dialog_content) {
      this.dialogHasSpecialName = '' // initial
      if (dialog_content.changeName) this.dialogHasSpecialName = dialog_content.changeName
      if (this.dialogHasSpecialName == 'player') this.dialogHasSpecialName = this.player.name
      let npcSay = ''
      if (typeof dialog_content.npcDialog === 'object') { // which means this dialog is a random array
        npcSay = this.random_dialog(dialog_content.npcDialog).replace('player', this.player.name)
        npcSay = npcSay.replace('elfName', this.player.elfName)
        this.setDialog(npcSay)
      }
      else {
        npcSay = dialog_content.npcDialog.replace('player', this.player.name)
        npcSay = npcSay.replace('elfName', this.player.elfName)
        this.setDialog(npcSay || '（npc 沒有回應）')
      }
      this.setDialogImage(dialog_content.image? dialog_content.image : '')
      // console.log(dialog_content)
      this.setAnswers(dialog_content.player || [])
      this.setAnswerIndex(0)
    },
    exchangeItem(mission_name, dialog_set) {
      const choose_answer = this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex]
      const needItems = choose_answer.delItems
      let playerItems = this.player.state.items
      let buySuccess = true
      for (let needItem of needItems) {
        let hasThisItem = false
        for (let myItem of playerItems) {
          if (needItem.name == myItem.name) {
            if (needItem.number <= myItem.number) {
              hasThisItem = true
            }
          }
        }
        if (!hasThisItem) {
          buySuccess = false
          break
        }
      }
      if (buySuccess) {
        if (choose_answer.rewards) this.get_all_rewards(mission_name)
        this.delPlayerItems()
        if (choose_answer.next) this.dialog(dialog_set[choose_answer.next])
        else this.reset_dialog() // end dialog to show reward
      }
      else {
        const choose_answer = this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex]
        const next_id = choose_answer.ifNoItemGoTo
        // console.log(dialog_set[next_id])
        this.dialog(dialog_set[next_id])
      }
    },
    delPlayerItems() {
      const choose_answer = this.player.interaction.nowAnswers[this.player.interaction.nowAnswerIndex]
      const needItems = choose_answer.delItems
      console.log(choose_answer)
      let playerItems = this.player.state.items
      for (let needItem of needItems) {
        for (let myItem of playerItems) {
          if (needItem.name == myItem.name) {
            const newNumber = myItem.number - needItem.number
            this.editItemNumber({
              'name': myItem.name,
              'number': newNumber
            })
          }
        }
      }
    },
    random_dialog(dialog_array) {
      return dialog_array[get_random_int(dialog_array.length)]
    },
    reset_dialog() {
      if (this.player.interaction.whatIGet) this.player.interaction.whatIGet = ''
      this.setDialog('')
      this.setDialogImage('')
      this.setAnswers([])
      this.setAnswerIndex(0)
    },
    press_arrow_key(e) {
      this.select_answer(e)
    },
    select_answer(e) {
      let index = this.player.interaction.nowAnswerIndex
      let max = this.player.interaction.nowAnswers.length
      let newIndex = null;
      if (e.code === "ArrowUp" || e.code === "ArrowLeft") {
        if (index - 1 >= 0) {
          newIndex = this.player.interaction.nowAnswerIndex - 1
        }
        else newIndex = max-1
      }
      else if (e.code === "ArrowDown" || e.code === "ArrowRight") {
        if (index + 1 < max) {
          newIndex = this.player.interaction.nowAnswerIndex + 1
        }
        else newIndex = 0
      }
      if(newIndex!==null && newIndex!==index) {
        this.setAnswerIndex(newIndex)
        this.playSoundEffect('dialog_choose');
      }
    },
    press_esc(e) {
      this.reset_dialog()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeOut {
  animation: 0.4s fadeOut both;
}

.dialog_screen {
  transform-style: preserve-3d;
  height: 100vh;
  position: fixed;
  width: 100vw;
}

.dialog_image {
  transform: translate3d(0, 0, 9999px);
  position: fixed;
  width: 60%;
  height: calc(91% - 300px);
  left: 20%;
  top: 5%;
  // overflow: hidden;
  animation: fadeIn 0.7s both;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(3px 4.781132px 6.24906px rgba(60, 145, 149, 0.5));

  .loading_text {
    padding: 20px 30px;
    background: $rethink_night_green;
    color: $text_highlight;
    border-radius: 10px;
    opacity: 0.9;
    animation: fadeIn 0.3s 0.5s both;
  }
}

.dialog {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  transform: translate3d(0, 0, 9999px);
  bottom: 140px;
  font-size: 14px;
  letter-spacing: 0.56px;
  text-align: left;
  color: $new_world_dark_blue;

  .dialog_box {
    position: relative;
    width: 491px;
    height: 157px;
    // height: 12.8vw; // depends on bg
    // min-height: 185px;
    animation-name: fadeInUp;
    animation-fill-mode: both;
    animation-duration: 0.8s;
    filter: drop-shadow(0px 1px 8px rgba(60, 145, 149, 0.2));

    .bg {
      position: relative;
    }

    .shot {
      position: absolute;
      top: -109px;
    }

    .name {
      position: absolute;
      top: -18px;
      left: -1%;
      background: $new_world_green;
      padding: 12px;
      border-radius: 8px;
      font-weight: 700;
    }

    .player_name {
      background: #FFB33C;
    }

    .dialog_text {
      position: absolute;
      top: 0;
      padding: 55px 70px;
      line-height: 24px;
    }
    .dialog_text ::v-deep a {
      color: #439D8D;
    }

    .next_icon {
      position: absolute;
      right: 50px;
      bottom: 50px;
      width: 20px;
    }

    .answers {
        position: absolute;
        // width: 26%;
        max-width: 40%;
        // height: 46%; // strech with answer
        padding: 5% 7% 9% 12%;
        bottom: 80%;
        right: -18%;

        .bg {
          position: absolute;
        }

        .answer {
          cursor: pointer;
          position: relative;
          margin-top: 20px;
          color: #FF924A;
          line-height: 18px;

          .arrow {
            position: absolute;
            margin-left: -20px;
            margin-top: 4px;
          }
        }

        .active {
          color: $new_world_dark_blue;
        }
      }
  }



}

@media screen and (max-width: 760px) {

  .dialog_image {
    width: 70vw;
    left: 15vw;
    top: 30%;
    height: 40%;
  }

  // below iPad
  .dialog {
    font-size: 3.4vw;
    letter-spacing: 0.44px;
    bottom: auto;
    top: 12vh;

    .dialog_box {
      // width: 227px;
      width: 70vw;
      height: auto;
      background: white;
      border-radius: 10px;

      .shot {
        display: none;
      }

      .bg {
        display: none;
      }

      .name {
        // font-size: 8px;
        font-size: 2.5vw;
        letter-spacing: 1.44px;
        // padding: 5px 10px;
        padding: 1.6vw 3.2vw;
        border-radius: 5px;
        left: 0;
        // top: -9px;
        top: -2.5vw;
      }

      .dialog_text {
        position: relative;
        // padding: 20px;
        padding: 6.25vw;
        // line-height: 18.75px;
        line-height: 5.8vw;
        // height: 64px;
        height: 20vw;
      }

      .next_icon {
        // right: 10px;
        right: 3vw;
        bottom: auto;
        // top: 82px;
        top: 21vw;
        // width: 20px;
        width: 6.25vw;
      }

      .answers {
        position: relative;
        max-width: 100%;
        width: calc(100% - 3.5vw);
        // padding: 10px 10px 10px 0;
        padding: 3vw 1.5vw 3vw 2vw;
        top: 0;
        right: 0;
        background: $new_world_yellow;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        justify-content: center;

          .answer {
            // margin: 0 0 0 24px;
            margin: 0 0 0 6.5vw;
            font-size: 3.3vw;
            line-height: 3.75vw;
            letter-spacing: 0.38px;

            .arrow {
              // margin-left: -15px;
              margin-left: -4.6vw;
              margin-top: 0;
              width: 2.8vw;
            }
          }

        }
    }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

</style>
