<template>
  <Modal :name="'y2020_5'" ref="modal" :isWhite="true">
    <img src="./images/2020_5-1.jpg" alt="">
    <p>每一年大會師的現場，重頭戲之一是工作坊，對機制組的夥伴，都是新的挑戰。</p>
    <p>我們持續優化所有硬體（思考方法、行動迴圈包）和軟體（活動安排、適度的社交交流、迴響精神的鼓舞），當這些狀態平衡，才能讓我們輸出的知識體系，被青年更好理解、使用。</p>
    <p>「學到一生能帶著走的能力，」是驗證迴響不只是創業競賽，最好的證據。</p>
    <img src="./images/2020_5-2.jpg" alt="">
    <img src="./images/2020_5-3.jpg" alt="">
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
