<template>
  <Modal :name="'planet_devoted'" ref="modal" :isWhite="true">
    <h3>用心 Devoted</h3>
    <p class="t-center">「如果可以，讓我們都選擇不走方便或苟且的路。」</p>
    <br />
    <p>用心是看見了什麼在意的事情，不別過頭去，是相信這一時一刻只有我們，不能重來也無法回去。</p>
    <p>迴響重視的價值是，不心存僥倖。</p>
    <img src="../../images/world/NPC/events/Planet14Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
