<template>
  <Modal :name="'s2_studio_2_time_capsule'" ref="modal">
    <h3>行動迴圈小故事</h3>
    <p class="note">本文截錄自<a href="https://issuu.com/jarahchou/docs/_____191007____27.3m___" target="_blank">《行動旅人求生筆記》</a>／作者 Jarah</p>
    <h4>畫得比說的好聽</h4>
    <p>「當你要和一個人說明一個複雜的概念時，永遠要學著用視覺化的方式溝通」。</p>
    <p>我記得有一次我們卡在一個地方好幾天了，一直沒辦法把行動的理論與計畫中的不同階段連結在一起，討論已經進行了一個多小時，還是沒有任何進展，我沮喪地坐在討論室裡面，突然想到我們也會在學院課程中告訴學員的事情：「溝通的時候，記得把想法視覺化」。</p>
    <p>所以我到走廊推了一個隔間牆，掛上一片白板，拿一支筆開始畫，雖然只有簡單的線條跟潦草的字，但我們似乎漸漸更掌握所有討論的內容：重要的不重要的、內容與內容之間的關係、還沒有回答的問題......當我們能夠「看見」之後，好像一切都開始變得清晰，與此同時，行動迴圈也成為三個階段(註1)演進的基礎理論。除了內部的討論溝通，視覺化的簡報也幫助我們到外面提案時，讓對方在短時間內理解計畫的相關規劃。</p>
    <p>印象深刻的一次是我和 Rax 兩個人到台大創意創業中心，與執行長曾正忠討論迴響計畫在創業孵化這一塊的相關性，也是透過視覺化的方式來做說明，執行長聽完之後，很明確地點出我們的定位應該著重在精實創業所提到三個階段（註2）中的第一階段：問題／解決方案匹配（Problem Solution Fit），來幫助可能往後要創業的團隊做初步議題與構想的瞭解與驗證，因為這一塊的孵化在創業圈裡面比較少人耕耘，迴響計畫有其發揮的空間。</p>
    <p>這對我們來說是一個關鍵的時刻，因為有了這樣的談話，幫助我們離找出毛線頭又更近了一步。</p>
    <br>
    <br>
    <h4>行動迴圈初期的發展歷程</h4>
    <img src="./images/studio_2_time_capsule-1.png" alt=""/>
    <p>▲　終於依照行動迴圈、精實執行設計好迴響計畫的框架，那段時間每有機會就找一位前輩請教，這是在創創中心曾執行長辦公室，一邊手繪一邊解釋迴響計畫的紀錄。</p>
    <br>
    <img src="./images/studio_2_time_capsule-2.jpg" alt=""/>
    <p>▲　建構行動迴圈的初期，除了執著於迴圈本身的架構，也在思考每跑完一圈，下一階段代表甚麼意思。創辦階段的總召 Jarah 是很習慣圖像化思考的人，常常一邊畫圖、一邊討論。注意到了嗎？當年的行動迴圈架構非常簡單，只有行動跟目標而已。</p>
    <br>
    <img src="./images/studio_2_time_capsule-3.jpg" alt=""/>
    <p>▲　平面的迴圈在線性的計畫中，不好呈現層層推進、越做越深的概念，只好往立體的方向思考了。</p>
    <br>
    <br>
    <p><b>有方法，人們才容易跟上來</b></p>
    <p>回過頭來看，當初把迴響定位在「從想法到行動」，並且試圖從真實的訪談、各種理論（設計思考、  精實執行、  精實創業等）之中，建構出一套「強調行動」的基礎方法論是很正確的設定。</p>
    <p>因為逼迫自己用理論化的框架去思考，而非只是把它視為另一場創新創業活動，讓我們在整體的邏輯檢證、相關工具的使用上面，都更強調系統性。也因為有理論化，「把想法變行動」的這場呼籲才會有價值，若只是零散發生在片刻的行動，或許並不會被認為是可以嘗試、可以學習、甚至是一件重要的事情。</p>
    <br>
    <br>
    <p>註1：行動階段：行動迴圈一圈一圈的要轉去哪裡呢？ 迴響根據團隊的發展階段，參考了精實創業的觀點，提供了基本的階段目標，從 Problem Validation 到 Product-Market fit，讓團隊可以有意識的前進。<br>註2：精實創業三個階段：為 Eric Ries 於<a href="https://www.google.com/search?q=%E7%B2%BE%E5%AF%A6%E5%89%B5%E6%A5%AD+%E7%94%A8%E5%B0%8F%E5%AF%A6%E9%A9%97%E7%8E%A9%E5%87%BA%E5%A4%A7%E4%BA%8B%E6%A5%AD" target="_blank">《精實創業：用小實驗玩出大事業》</a>這本書中提出的概念，說明一個新創事業應由 Problem-Solution Fit（問題與解法匹配）、Product-Market Fit （產品與市場匹配） 和 Scale（規模化）三個不同的階段組成。</p>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
