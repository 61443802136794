<template>
  <div class="objects">
    <!-- duplicate object -->
    <template v-for="bigCategory in objects">
      <template v-for="(object, key) in bigCategory">
        <!-- key = objectName; object contain attribute -->
        <template v-for="(coordinate, j) in object.coordinates">
          <!--  -->
          <div
            v-show="isSafari? is_object_visible(coordinate.x, coordinate.y) : true"
            :key="'object-'+key+'-'+j"
            :class="[key, 'object']"
            :ref="key"
            draggable="false"
            :name="coordinate.x.toString() + coordinate.y.toString()"
            :style="
              'width: ' + (bigCategory[key].width * unit) + 'px;' +
              'left: ' + (coordinate.x * unit) + 'px;' +
              'top: ' + (coordinate.y * unit) + 'px;' +
              'margin-left: ' + (bigCategory[key].shift.x * unit) + 'px;' +
              'margin-top: ' + (bigCategory[key].shift.y * unit) + 'px;' +
              'transform: ' + 'translate3d(0px, 0px, ' + (object.alwaysBottom? 0 : (coordinate.y-1)) + 'px)'">
              <!-- +(isSafari? ';will-change: display;' : ';') -->
              <img :src="require('../images/world/objects/' + key + '.svg')" :alt="key"/>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { delay, get_random_int, is_A_key } from 'js/map/global.js'

export default {
  name: 'Objects',
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState([
      'map', 'unit', 'player', 'drops', 'windowWidth', 'windowHeight', 'viewWidth', 'viewHeight',
      'objects', 'grids', 'isSafari'
    ]),
    ...mapGetters([
      'whereTheTreeX', 'treeAroundMe', 'whatIsPickingUp', 'ableToFish', 'fishOnLeft', 'digSthFromHole'
    ])
  },
  created: function() {
  },
  mounted: function() {
    const in_order = async () => {
      await this.set_invisible_colliders()
      // this.set_special_aisle() // move to map.vue
    }
    in_order()
    this.initial_vuex_function()
    this.set_trees_map() // note that tree is special cuz it can be pushed, so they have an identical map
    this.set_objects_collider() // note that some object was born with collider by set value 'collide' in map.js
    this.set_invisible_fish()
    this.set_invisible_hole()
  },
  methods: {
    ...mapMutations([
      'assignTreeMapValue', 'initialObjectsFunction', 'assignMapValue', 'playSoundEffect'
    ]),
    ...mapActions([
      'dropSth', 'goFishing', 'randomly_from'
    ]),
    initial_vuex_function() {
      let functions = {
        'pressA': this.press_A
      }
      this.initialObjectsFunction(functions)
    },
    set_trees_map() {
      const treeCategories = Object.keys(this.objects['tree'])
      for (let treeName of treeCategories) {
        const tree_coordinates = this.objects['tree'][treeName].coordinates
        for (let index in tree_coordinates) {
          const x = tree_coordinates[index].x
          const y = tree_coordinates[index].y
          this.assignMapValue({
            'category': 'tree',
            'x': x,
            'y': y,
            'value': treeName
          })
        }
      }
    },
    set_objects_collider() {
      // note: in map.js, object which set var 'collide' true will process this function
      const categories = Object.keys(this.objects)
      for (let categoryName of categories) {
        const objects = Object.keys(this.objects[categoryName])
        for (let objectName of objects) {
          let object = this.objects[categoryName][objectName]
          if (object.collide) { // when object has this collide attribute
            for (let coordinate of object.coordinates) {
              this.assignMapValue({
                'category': 'collider',
                'x': coordinate.x,
                'y': coordinate.y,
                'value': false
              })
            }
          }
        }
      }
    },
    set_invisible_colliders() {
      for (let i = 0; i < this.grids.colliders.length; i++) {
        let x = this.grids.colliders[i].x
        let y = this.grids.colliders[i].y
        this.assignMapValue({
          'category': 'collider',
          'x': x,
          'y': y,
          'value': false
        })
      }
      const missionKeys = Object.keys(this.grids.specialColliders)
      for (let missionKey of missionKeys) {
        const missionCollidars = this.grids.specialColliders[missionKey]
        for (let missionCollidar of missionCollidars) {
          let x = missionCollidar.x
          let y = missionCollidar.y
          this.assignMapValue({
            'category': 'collider',
            'x': x,
            'y': y,
            'value': false
          })
        }
      }
    },

    set_invisible_fish() {
      // note that fish is the only nested in grid
      const fisheryCategories = Object.keys(this.grids.fish)
      for (let fisheryName of fisheryCategories) {
        const fisheryCoordinate = this.grids.fish[fisheryName]
        for (let i = 0; i < fisheryCoordinate.length; i++) {
          let x = fisheryCoordinate[i].x
          let y = fisheryCoordinate[i].y
          this.assignMapValue({
            'category': 'fish',
            'x': x,
            'y': y,
            'value': fisheryName
          })
        }
      }

    },
    set_invisible_hole() {
      for (let i = 0; i < this.grids.hole.length; i++) {
        let x = this.grids.hole[i].x
        let y = this.grids.hole[i].y
        this.assignMapValue({
          'category': 'hole',
          'x': x,
          'y': y,
          'value': true
        })
      }
    },
    press_A(e) {
      if (is_A_key(e)) {
        if (this.whatIsPickingUp) return // pick up is in drop.vue
        else if (this.digSthFromHole) return
        else if (this.ableToFish) return // this.goFishing() // note that this is execute by avatar.vue
        else if (this.treeAroundMe) this.push_tree()
      }
    },
    async push_tree() {
      let coordinate = this.treeAroundMe
      let dom_name = coordinate.x.toString() + coordinate.y.toString()
      const tree_dom = document.getElementsByName(dom_name)[0]
      const tree_name = tree_dom.classList[0]
      tree_dom.classList.add('tree_animation')
      await delay(500)
      tree_dom.classList.remove('tree_animation')
      this.drop_something_from_tree(coordinate, tree_name)
    },
    async drop_something_from_tree(coordinate, tree_name) {
      let x = coordinate.x
      const y = coordinate.y
      if (this.whereTheTreeX < 0 && !this.map.drop[x-1][y]) x-- // drop left
      else if (!this.map.drop[x+1][y]) x++ // drop right
      else if (!this.map.drop[x-1][y]) x-- // drop left, which player step on
      else return
      // const dropOn = {'x': x, 'y': y}
      // this.randomly_from('tree', dropOn)
      // const getItem =
      if (this.drops[tree_name]) {
        this.playSoundEffect('tree_drop_obj')
        this.dropSth({
          'x': x,
          'y': y,
          'value': await this.randomly_from(tree_name)
        })
      }
      else {
        console.log('no drop from this tree!')
      }
    },
    is_object_visible(x, y) {
      // return true
      return (x < this.player.avatar.x + this.viewWidth/2)
        && (x > this.player.avatar.x - this.viewWidth/2)
        && (y < this.player.avatar.y + this.viewHeight/2)
        && (y > this.player.avatar.y - this.viewHeight/2)
      // const playerX = Math.round(this.player.avatar.x / 50) * 50
      // const playerY = Math.round(this.player.avatar.y / 50) * 50
      // return (x < playerX + this.viewWidth/2)
      //   && (x > playerX - this.viewWidth/2)
      //   && (y < playerY + this.viewHeight/2)
      //   && (y > playerY - this.viewHeight/2)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

.objects {
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  // will-change: contents;

  div {
    position: absolute;
    // transition: 0.3s steps(30, end);
    // will-change: display;
    // will-change: transform;
    img {
      // will-change: transform;
      width: 100%;
    }
  }
  .tree_animation {
    img {
      animation: tree_be_pushed 0.5s both;
    }
    @keyframes tree_be_pushed {
      from {
        transform: translateX(0);
      }
      33% {
        transform: translateX(5px);
      }
      66% {
        transform: translateX(-5px);
      }
      to {
        transform: translateX(0px);
      }
    }
  }
}

</style>
