<template>
  <Modal :name="'s1_partners'" ref="modal" :isWhite="true">
    <h3>行動夥伴</h3>
    <h4>新的名字有新的力量</h4>
    <p>行動夥伴顧名思義，就是我們的夥伴，是執行團隊的夥伴，在初期幫忙力推這個計畫，也是每一屆青年團隊的夥伴，陪伴團隊度過第一步行動的卡關點。</p>
    <p>計畫創立初期，在討論如何支持青年的行動時，不免俗的參考了其他計畫，帶入了「業師」的角色，只是怎麼想都不太對勁，因為太被動了。</p>
    <p>我們成長的教育環境，「老師」往往被視為一個給予解答、能解決問題的角色，有問題要先請教老師才能動作。於是為了跳脫刻板印象，迴響用「行動夥伴」稱呼這些一般計畫設定為業師的角色。</p>
    <p>進一步就能重新思考，如果我們是在為青年團隊尋找給力的夥伴...</p>
    <br>
    <br>
    <h4>「以行動為中心」的陪伴模式</h4>
    <p>許多人會好奇，業師與行動夥伴的差別是什麼？其實這個問題把我們丟進了傳統業師諮詢的框架，那是假設「有經驗的人比較知道該怎麼做，因此可以藉由傳授或對談的方式，給予沒有經驗的人指導，幫助他們達成目標。」</p>
    <p>迴響計畫以促成青年行動為中心，如果我們從「如何讓行動更容易發生」、「讓行動的結果更有效益」的角度重新思考「有經驗的人如何陪伴青年」這件事，自然而然會做出不同的決定：</p>
    <br>
    <p><b>建立同理的基礎</b></p>
    <p>迴響計畫的行動夥伴多是創業 3-5 年內的創業者或組織創辦人，能珍惜第一步行動的價值，因此比起直接給予答案，他們比較偏向提出問題，幫助青年釐清行動的盲點。</p>
    <p>他們能深深體會跨出第一步的掙扎和矛盾，所掌握的資源也有限，在引導下一步行動時，不會提出其實青年明顯做不到的建議。他們不像業師，比較像事業剛做大，但還願意回來吃家聚的系上學長姐，陪你度過那些搖擺不定、偶遇成就的時刻。</p>
    <br>
    <h5><b>建立平等的交流關係</b></h5>
    <p>夥伴的年齡多半與青年提案者相仿，除了能交流的話題變多，比較容易建立「諮詢」以外的連結外，因為無須豎立為人師的角色身份，溝通上也比較直來直往。</p>
    <p>一般業師諮詢常常受限於時間、拘於禮貌，業師可能只能粗淺的瞭解計畫後，就要給出有道理的回饋，而團隊為了取得業師的認同，會講太多其實還不成熟的解決方案，卻鮮少真的溝通遇到的問題，結果常常「業師金玉良言，全是自己經驗」與青年得議題對不上。</p>
    <br>
    <h5><b>創造互惠的連結</b></h5>
    <p>行動夥伴所處的組織大多都在發展初期，他們的「行動方案」雖然趨於成熟，但仍會謹慎思考資源的運用，多半都期待能借力使力，尋找各種創造價值的機會。</p>
    <p>青年的行動有時會和夥伴的事業互補，在夥伴的空間中辦理活動，也幫助夥伴觸及到新的客群，第二屆投入書寫療癒的 Date Myself 就曾與玖樓合作，在共居空間辦理書寫活動；青年處理的議題，有時是夥伴注意到卻無力投入的面向，由夥伴協助重構議題，幫助青年思考如何連結不同的資源。</p>
    <p>此外，看到這些青年熱血投入行動，許多夥伴回饋這讓自己想起了當年創業時的模樣，獲得了新的能量，也想格外用力的「照顧」這些新起的小老弟們，看見他們的成長。</p>
    <br>
    <br>
    <h4>「以行動為中心」的合作模式</h4>
    <p>最後，行動夥伴也是執行團隊的夥伴，迴響創立初期，為了找到將想法化為行動的關鍵，我們在一個月內完成了15場以上的採訪，從中整理出數十萬字的逐字稿，一邊抽絲剝繭，一邊將學習轉化為迴響的行動迴圈。</p>
    <p>行動夥伴們與執行團隊的年紀也相仿，在大型活動出席、評審過程的溝通中，我們偶爾也會吵架，這些衝突和火花，幫助我們持續滾動修正，我們的機制、評選標準是否真得有：「幫助青年採取行動」、「協助青年在行動的過程獲得學習」、「幫助青年以他們的行動連結到更多資源，看見更大的世界」</p>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
