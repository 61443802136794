<template>
  <div class="guide_map">
    <div class="bg">
      <img draggable="false" src="../images/world/guide_map.png" alt="">
    </div>
    <div class="avatar_dot" :style="
                            'left:' + (player.avatar.x / xMax) * 100 + '%;' +
                            'top:' + ((player.avatar.y-24) / yMax) * 100 + '%;'">

    </div>
    <!-- <svg class="avatar_dot" width="10" height="10" viewBox="-50 -50 300 300" :style="
                            'left:' + (player.avatar.x / xMax) * 100 + '%;' +
                            'top:' + ((player.avatar.y-24) / yMax) * 100 + '%;'">>
      <polygon class="triangle" stroke-linejoin="round" points="100,0 0,200 200,200"/>
    </svg> -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'GuideMap',
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState([
      'xMax', 'yMax', 'unit', 'player', 'windowWidth', 'windowHeight'
    ]),
    ...mapGetters([
      'whatIsPickingUp', 'whereTheDropIsX', 'whereTheDropIsY'
    ])
  },
  created: function() {
  },
  mounted: function() {
  },
  methods: {
    ...mapActions(['getNewItem', 'pickUpAndDelDrop']),
    ...mapMutations([
      'initialDropsFunction', 'labelAvatarIsActing'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

.guide_map {
  position: absolute;
  left: 33px;
  bottom: 30px;
  width: 220px;
  height: 283px;
  border-radius: 10px;
  // background: red;
  font-size: 0;
  animation: fadeIn 2s both;

  .bg {
    border: $new_world_white 3px solid;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    // padding: 0 10px;
    background: white;

    img {
      width: 100%;
    }
  }

  .avatar_dot {
    position: absolute;
    // width: 10px;
    // height: 10px;
    // background: red;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 12px 7px;
    // border-color: transparent transparent #ff9a47 transparent;
    border-color: transparent transparent #f6484d transparent;
    // fill: #0f0;
    // stroke: #0f0;
    // stroke-width: 10;

    // border-radius: 1px;
    margin-top: 6.5%;
    margin-left: -0.5%;
  }
}

</style>
