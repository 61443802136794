<template>
  <Modal :name="'planet_faith'" ref="modal" :isWhite="true">
    <h3>信念 Faith</h3>
    <p class="t-center">「讓心成為一座山，即便有風暴也堅定不移。」</p>
    <br />
    <p>信念是不再執著於尋找答案，是所有為什麼最後的解釋，是看不見光的時候，鑿穿一個孔的小槌子。</p>
    <p>迴響重視的價值是，為大於你自己的東西努力，看見終點之後的終點，相信我們最終都會到達。</p>
    <img src="../../images/world/NPC/events/Planet8Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
