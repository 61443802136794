<template>
  <Modal :name="'partner_10'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>對話千層派／品汶(Pin)</h3>
    <p>關於【對話千層派】</p>
    <p>#突破同溫層 #政治即生活 #對話工具包</p>
    <p>近幾年因為關注社會議題，常被親友貼上政治狂熱的標籤，同時也發現在各年齡層或各領域都會聽到「政治歸政治」的說法，於是集結了志同道合的夥伴創立了對話千層派，希望能慢慢推動普遍台灣人對政治的印象，從「我們不談政治」的句點，轉換成讓「聊政治可以很日常」的起點。</p>
    <p>透過提供對話工具包、舉辦對話工作坊，帶動更多人在日常生活中，用勇敢、理性、溫和的態度，持之以恆地對話。期待有一天談政治不再是件火爆、尷尬或會被貼標籤的事，就跟我們平常聊星座或電影一樣平常，甚至很有趣！</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_10.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
