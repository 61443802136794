<template>
  <Modal :name="'ax_decision'" ref="modal" :isWhite="true">
    <h5><b>團隊的會議時有衝突，不太知道要怎麼解決...怎麼辦？</b></h5>
    <br />
    <br />
    <p>女神有很多種，會議也有很多種。</p>
    <p>開會總是得與人交流，不管是資訊或是狀態，都有可能互相影響，釐清自己和對方是「帶著什麼心情狀態，需要什麼樣的資訊才能做出決定」是避免會議進入無效或衝突的可操作元素，意思是，還是有不可操作的元素啦，比如...人品？</p>
    <br />
    <p>迴響的會議有：</p>
    <ul>
      <li><b>Brain一下</b>：單純想要一些新靈感，沒有想要被批判、檢討邏輯，靈感越新越跳脫框架越好。</li>
      <li><b>Kickoff</b>：負責人對現況掌握度低，需要一些本來就掌握足夠資訊，同步成本低的夥伴一起釐清怎麼開始一件困難的事情。</li>
      <li><b>例會</b>：一般的例會。<br/></li>
      <li><b>同步會議</b>：有會影響到眾人的事件，想請大家一起以知道為原則，解答問題為輔的會議，會刻意設計討論的流程，確保大家循序漸進的瞭解一件事情。</li>
      <li><b>推進會議</b>：有明確推進進度的需求，會在事前大致設計好所有人在會議中扮演的角色，確認好「真的能一起推進」的事情有哪些，並在結束時整理好分工 跟 Action Item。</li>
      <li><b>xx松</b>：有強烈推進進度的需求，想邀請所有相關人等空下至少 3 小時的時間一起投入這件事情，就算只是在現場吃便當也行。</li>
    </ul>
    <br />
    <p>也有以角色區分的：</p>
    <ul>
      <li><b>馬可波羅</b>：希望對方能扮演幫助自己看到新世界、不同觀點的角色，用這樣的心情來對待待會要討論的內容。</li>
      <li><b>福爾摩斯</b>：希望對方能扮演釐清問題癥結點，用旁觀者的角度點醒自己的角色。</li>
    </ul>
    <br />
    <p>以上這些，也有可能會在衝突發生的當下才釐清「欸，不是...我是想請你當馬可波羅啦」。相信我，即使如此是事後進行，有釐清還是比較好。</p>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
