<template>
  <Modal :name="'y2019_1'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2019_1.jpg" alt=""> -->
    <p>「好的，那就開始吧。」</p>
    <p>第三屆的挑戰，或許你可以看看品牌紀元，了解前後脈絡。</p>
    <p>在所有活動都有些限縮的條件下，迴響在第三屆時，開始思考「鼓勵行動」最根基的要件，到底有哪些呢？</p>
    <p>精簡的行動，該具焦在何處？</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
