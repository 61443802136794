<template>
  <div class="drops">
    <div v-for="(drop, index) of appearence.drops" :key="index" class="drop"
                                                    :style="
                                                    'width: ' + (0.8 * unit) + 'px;' +
                                                    'height: ' + (0.8 * unit) + 'px;' +
                                                    'left: ' + (drop.x * unit) + 'px;' +
                                                    'top: ' + (drop.y * unit) + 'px;' +
                                                    'z-index: ' + (drop.y) + ';' +
                                                    'transform: ' + 'translate3d(0px, 0px, ' +
                                                    ((drop.name == 'elf')? drop.y-1 : 0) +'px)'
                                                    ">
      <img :class="[drop.name]" v-if="drop.name != 'elf'" draggable="false" :src="require('../images/world/drops/'+drop.name+'.svg')" alt=""/>
      <div v-else class="elf" :name="index">
        <!-- only for elf -->
        <img class="sit" src="../images/world/drops/elfSit.svg" alt="" draggable="false"/>
        <img class="stand" src="../images/world/drops/elfStand.svg" alt="" draggable="false"/>
        <img class="jump" src="../images/world/drops/elfJump.svg" alt="" draggable="false"/>
        <img class="run1" src="../images/world/drops/elfRun1.svg" alt="" draggable="false"/>
        <img class="run2" src="../images/world/drops/elfRun2.svg" alt="" draggable="false"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Drops',
  data: function () {
    return {
      is_picking_up: false
    }
  },
  computed: {
    ...mapState([
      'appearence', 'unit', 'player', 'windowWidth', 'windowHeight'
    ]),
    ...mapGetters([
      'whatIsPickingUp', 'whereTheDropIsX', 'whereTheDropIsY'
    ])
  },
  created: function() {
  },
  mounted: function() {
    this.initial_vuex_function()
  },
  methods: {
    ...mapActions(['getNewItem', 'pickUpAndDelDrop']),
    ...mapMutations([
      'initialDropsFunction', 'labelAvatarIsActing'
    ]),
    initial_vuex_function() {
      this.initialDropsFunction({'pressA': this.press_A})
    },
    press_A(e) {
      if (!this.is_picking_up) {
        this.is_picking_up = true
        // setTimeout(() => {this.pick_up()}, 300)
        this.pick_up()
      }
    },
    pick_up() {
      const thing = this.whatIsPickingUp
      if (thing) {
        this.getNewItem({
          'name': thing
        })
      }
      this.pickUpAndDelDrop({
        'x': this.player.avatar.x + this.whereTheDropIsX,
        'y':this.player.avatar.y + this.whereTheDropIsY,
      })
      this.is_picking_up = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

.drops {
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;

  .drop {
    position: absolute;
    // width: 20px;
    // height: 20px;

    img {
      position: absolute;
      animation: drop 0.2s both linear;
      width: 100%;
      margin-left: -29%;
    }

    .elf {
      position: absolute;
      width: 80%;
      margin-top: 0;
      margin-left: 0;

      img {
        display: none;
        width: 100%;
        top: 0;
        left: 0;
      }
      .sit {
        display: block;
      }
    }

    .elfJump {
      margin-top: 95%;
      margin-left: -85%;
      .sit {
        display: none;
      }
      .stand, .jump {
        display: block;
        opacity: 0;
      }
      .stand {
        animation: frame 2s infinite;
      }
      .jump {
        animation: frame 2s infinite;
        animation-delay: 1s;
      }
    }

    .elfRun {
      animation: elfRun 2.7s both linear;
      margin-top: 95%;
      margin-left: -85%;
      .sit {
        display: none;
      }
      .run1, .run2 {
        display: block;
        opacity: 0;
      }
      .run1 {
        animation: frame 0.2s infinite;
      }
      .run2 {
        animation: frame 0.2s infinite;
        animation-delay: 0.1s;
      }
    }

    .elfRunReverse {
      animation: elfRunLeft 2.7s both linear;
      img {
        transform: scaleX(-1);
      }
    }

    // .apple {
    //   width: 75%;
    //   opacity: 0.8;
    // }
    //
    // .branch {
    //   opacity: 0.5;
    // }
  }

  @keyframes elfRun {
    0% {
      transform: translateX(0px);
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: translateX(777px);
      opacity: 0;
    }
  }

  @keyframes elfRunLeft {
    0% {
      transform: translateX(0px);
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: translateX(-777px);
      opacity: 0;
    }
  }

  @keyframes frame {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    53% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes drop {
    from {
      transform: rotate(15deg) translateX(10px) translateY(-15px);
    }
    50% {
      transform: rotate(5deg) translateX(10px) translateY(-15px);
    }
    to {
      transform: rotate(0deg) translateX(10px) translateY(15px);
    }
  }
}

</style>
