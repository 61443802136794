<template>
  <Modal :name="'y2017_5'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2017_5.jpg" alt=""> -->
    <p>第一屆，就排了這樣的大合照。</p>
    <p>十年後，會是什麼模樣呢？</p>
    <p>或許，看到這裡，你可以想想十年前的自己，能想像現在的模樣嗎？</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
