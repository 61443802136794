<template>
  <Modal :name="'s2_studio_3_time_capsule'" ref="modal">
    <h3>關於便利貼</h3>
    <p class="note">本文截錄自<a href="https://issuu.com/jarahchou/docs/_____191007____27.3m___" target="_blank">《行動旅人求生筆記》</a>／作者 Jarah</p>
    <h4>便利貼之上，人人平等</h4>
    <p>便利貼是我們在團隊討論時很常用到的一個工具，最主要的原因是，只要想法被寫在了便利貼上面，就沒有誰講話更有份量、誰講話比較大聲的問題。當便利貼被貼到牆上之後，所有想法就如同一幅畫在大家面前被展示，從此它便不會消失。更好的是，便利貼上面的重複貼黏膠讓你能夠移動它，將想法做不同的排列組合，提供一個幫助團隊共同思考和架構的過程。</p>
    <p>我們也將這樣的技巧告訴所有的參與團隊，希望能默默融入進他們自己討論的過程中，像是在一階行動期間在南北各舉辦一場的迴響之夜，我們帶了許多便利貼到現場帶大家一起實作腦力激盪；或是在衝刺之夜的時候放在現場供團隊取用；二階行動期間各組的設計思考教練也會使用便利貼，幫助團隊釐清目前所遇到的困難。一步一步都是希望讓團隊可以慢慢熟悉、學習便利貼的使用。</p>
    <p>事後採訪「最後一個晚安」時，團隊曾經說他們以往不曾發現便利貼這麼好用，甚至在衝刺之夜的時候，心裡還小小OS：「有需要用到這麼多便利貼嗎？」，但後來發現真的很好用。便利貼讓每個人都能夠發表自己的想法，不會因為團員主動發言的傾向而受影響（像鈺淳和亭儀是會比較主動說話的人，子晴相對話比較少，所以我們常常討論的時候比較容易忽略掉子晴，導致她就在旁邊默默當個美男子XD）。</p>
    <p>將便利貼貼到牆上後，每個人就上面的內容發言，就算是設計思考中所謂發散的過程，都分享完之後就知道「喔！那現在應該要開始收斂」，越來越懂得掌握團隊討論的節奏。團隊從一開始不會用、亂貼一通，到教練前往團隊所在地——高雄，在咖啡廳的一面黑牆上帶著大家實際使用，慢慢地熟悉它的操作方式。</p>
    <p>話說回來，迴響在第二年應該會開發一門「如何正確使用便利貼」的課程，這不是玩笑話，你眼中拿來留言請室友幫忙買便當的便利貼，在視覺化討論的領域中，其實是一門頗有內容的學問。到時如果有確認公開的教學資源，我們也會在網站或粉專上讓大家知道，就敬請追蹤囉！</p>
    <img src="./images/studio_3_time_capsule.jpg" alt=""/>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
