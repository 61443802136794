<template>
  <Modal :name="'s0_epitome'" ref="modal">
    <p>
      迴響的精采與豐盛，只有親自體會，<br/>
      在此之前，邀您打開想像，<br/>
      觀賞行動者在迴響會遇到的里程碑，<br/>
      一起感受所有行動者創造的珍貴時刻。
    </p>
    <br/>
    <br/>

    <h3>計畫說明會</h3>
    <img src="./images/epitome-1.jpg" alt="">
    <h4 class="t-center">參與說明會就是我的第一步！</h4>
    <p>你可能不知道，四年來有一定程度的行動者，就是在說明會的現場被點燃，甚至就是他關鍵的轉捩點，開始認識不同行動者的社會議題，因為過去「行動」不在他們的選項裡。</p>
    <p>迴響珍惜每一次和大眾相遇的機會，從生活經驗談「行動」百百種的模樣、引導反思生活上被限制行動的情境、持續討論大眾心中隱約覺得怪怪的地方、讓與會者也能寫下簡短的宣言，和執行團隊、教練、講者、同行聽眾，初步談談模糊的感受和過往的經驗。</p>
    <p>社會裡，除了存在著人們避而不談的大象現況，更多的是正等著時機參與行動的響應者，看著每一雙被撼動的眼睛，我們知道我們為何而堅持、為何而在。</p>
    <p>在此，也誠心謝謝這四年所有的工作人員，和迴響一同共創無數場、線上線下、無法被複製的迴響說明會。</p>
    <br/>
    <br/>
    <br/>
    <br/>

    <h3>任務包審核</h3>
    <img src="./images/epitome-2.jpg" alt="">
    <h4 class="t-center">累積 819 人參與線上任務，從提問開始展開行動</h4>
    <p>迴響的網路提案門檻，非常精要：「一句不帶任何假設的提問」就是你的提案，而只要通過這項提案（但其實問對問題，也不簡單喔～），就能展開線上的任務包挑戰。</p>
    <p>審核任務包的機制，正是以青年迴響計畫的教練們為首，陪伴大家一起逐步展開各自的行動，從訪談紀錄、整理洞察、驗證釐清，到發想出可以實際行動的方案。</p>
    <p>每一個提案都讓我們重新認識台灣現況，不同議題帶來新的學習，我們必須更加用心的反饋和討論，提出團隊可能面臨的關卡，避免陷入自圓其說、無意識地陷入行動的陷阱、糾結在無法觸碰的框架......</p>
    <p>我們不會比參賽者更有先見之明，能預先知道解決之道，只能陪伴行動團隊提問，反覆驗證，從手中一步步穫得的資訊，換取新的觀點，找到一個「現在可以做的」切入點，就一起實際試試看吧！</p>
    <br/>
    <br/>
    <br/>
    <br/>

    <h3>青年迴響大會師</h3>
    <img src="./images/epitome-3.jpg" alt="">
    <h4 class="t-center">行動者的開學典禮，為同樣原因而聚集</h4>
    <p>經歷過任務包的夥伴們，其實早已踏上各自的英雄旅程，而大會師是所有人第一次面對面的場合。</p>
    <p>聚集各路人馬，最難得可貴的，就是能坐下來，好好認識彼此：「從哪裡來？為何而來？拓展了什麼視野？」</p>
    <p>最重要的，就是真的能遇到同樣堅持、學習如何面對失敗的同路人，不只是會遇見其他的行動團隊，還有迴響的團隊、持續成長的行動夥伴，和人們相互共創的現場連結。</p>
    <p>這兩天的活動，也會再讓大家理解什麼是行動迴圈，甚至能和教練一起討論卡關的部分，當又再次開始面對自我批判否定的磨練時，旁邊有一群夥伴，也都正在誠實釐清：「是否真的在意這個提案？如果我嘗試沒成功，我該怎麼調整？還是放棄？」</p>
    <p>行動者的開學典禮，將會因為每個人獨一無二的行動旅程，而有各自精采的收穫。</p>
    <br/>
    <br/>
    <br/>
    <br/>

    <h3>Pitch Day 評選</h3>
    <img src="./images/epitome-4.jpg" alt="">
    <h4 class="t-center">當你踏上未竟之路，「收穫」才是真正的成功</h4>
    <p>迴響計畫相信，當一個人的提案，經過了行動、反思，來回的驗證並大膽再繼續試錯之後，就要更勇敢地，向世界分享你的看見，行動團隊將會用 12-15 分鐘的時間進行提案，與評審發表時，就彷彿是向外再踏一步，讓更多未知的資源、可能性，因你而生。</p>
    <p>每一年聚集的評審陣容，都因當年團隊的議題而來，他們都是相關議題的深耕者、創業家、革新者，由這些先行者作為團隊的評審，光是想像就非常興奮。</p>
    <p>對迴響而言，重要的不是能馬上成功，或是要立即找到最有效的解決方案，而是在這一連串長達三個月以上的體驗中，行動者真切地體驗過什麼是失敗、有達成短期目標、想到的方法讓利害關係人有所共鳴、能為議題多增加不同時代的觀點和切點，讓自己所投入的時間、精力，都轉換成社會上的資源。</p>
    <p>這些先行的評審們，非常樂意和團隊們討論這些尚未被驗證、還不太成熟的想法，願意聽聽青年們落地的執行成果後，用全力相挺、支持每一個想要延續的行動，在 Pitch Day 當天，都會分享自身所擁有的經驗、建議和心法。</p>
    <p>這樣的連結，無論會不會直接或間接創造未來的資源，都已經讓每一位行動者明白：「只要自己行動、嘗試了，資源就在自己的手中。」</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
