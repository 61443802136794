<template>
  <Modal :name="'s2_three_spiritual'" ref="modal" :isWhite="true">
    <h3>行動路上的三大精神</h3>
    <p>迴響的三大精神是三種心態，回應三種在採取行動的路上會遇到的問題，也可以說是三個提醒，幫助我們在還沒有方法和工具時，知道如何面對挫折和矛盾，繼續前進。</p>
    
    <br>
    <h4>先動，再說</h4>
    <p>我們都缺乏行動自信。</p>
    <p>所以花很多時間在討論、查找資料，辯論誰的觀點才是正確的，卻不會付諸行動。道理很簡單，因為想法放在腦子裡，或拿出來講，都不會失敗，只有將之付諸行動，才會需要面對失敗。</p>
    <p>而我們都害怕失敗，無一例外，所以我們容易忽視行動帶來的可能性，和那些只有真得去做才能獲得的學習。</p>
  
    <!-- 熱水壺 -->
    <p>每年的迴響，都會遇到這樣的團隊，想要再多查一些資料、再多讀一些文獻，來讓自己「有資格」為在意的事做點什麼。但其實，更多資料只會讓我們更難開始，誤會什麼都有人做過了，放棄用具體的行動，建立屬於自己觀點的機會。</p>
    <p>迴響大會師的第二天早上，是青年出發採取行動的時段，一旦在會場遇到仍在查資料的團隊，我們都會不厭其煩地問他「那你當初在意的議題是什麼？」「你原本想怎麼做？」「你要不要就先試著這樣做做看？」。屢試不爽，他們總是在出門後獲得更多。</p>
    <p>執行團隊自己也受這樣的精神影響，所以不會有延續超過一日的爭執，因為隔天不同想法的原型（Prototype）就會被做出來，拿給彼此測試後，馬上就會得到有意義的反餽，任務包就是在這樣反覆測試的過程中誕生。</p>

    <br>
    <h4>懷抱夢想，但從做得到的事情開始</h4>
    <p>實際試試看並不簡單，尤其是拿著完美的計畫，想跨出最有效益的一大步時，期待越大，往往失望也越大，很容易就澆熄了繼續前進的熱情。</p>
    <p>迴響的經驗是，再遠大的夢想都有起點，再困難的事情，都有簡單做的方式，找出第一個能做到的事情並勇敢實踐，是對自己的夢想負責最好的方式。</p>
  
    <!-- 熱水壺 -->
    <p>迴響第一屆團隊「讓我罩顧你」的提案，是做一個可以吸收口水的口罩，讓顏面神經失調，容易流口水的患者可以有尊嚴的出門，也降低照護者的負擔。要做口罩，是不是要找工廠？不用，團隊成員訂購了各種現成的吸水口罩，都發現效果不佳，最後將衛生棉縫上口罩製作出第一個 Prototype，至此，他們至少可以知道患者願不願意戴口罩，和他們會需要口罩的真實情境。</p>


    <br>
    <h4>將遭遇的困難，轉化成學習的機會</h4>
    <p>即便已經試著行動了，也從自認為做得到的事情開始，還是會被現實打臉，有時甚至會腫到看不清楚前面的路。</p>
    <p>沒錯...行動其實沒有想像中那麼美好！但遇到困難與挫敗時，要記得我們仍有選擇，可以選擇當他是敵人，抱怨或逃跑，也可以選擇面對，發現學習的機會。迴響的態度是，在哪裡跌倒，就在哪裡躺好。</p>
    <p>遇到困難和挑戰，不妨休息一下，然後放低姿態，從學習的角度重新看待眼前的挫折。因為每一次挫折，都是撞進理想和現實之間的差距，你可以調低理想，當然也可以藉此反思我們真正的理想是什麼？還可以怎麼達到。</p>
  
    <!-- 熱水壺 -->
    <p>迴響自創立之初到現在，遇到的困難幾乎都促成後續的創新。最早期的困難，是到底要設立哪些徵件的主題類別？一般計畫都會有「永續環境」、「社會文化」、「智慧物聯」...等等的主題分類，參與者提交符合該主題的提案，主辦單位從各個類別中挑選出固定的名額晉級。然而，迴響最初因為希望青年都能從自己的生活和在意的問題出發，題目固然包羅萬象，但極難事前提出有系統的分類。</p>
    <p>不過，為什麼要分類呢？我們當時想，既然要做一個新的計畫，那就要打破框架，重新思考每一個「覺得好像應該要做而做的事情」的事。</p>
    <p>一般計畫的分類目的，有的是為了增加多元性，觸及到關注不同議題的族群，也增加篩選到好團隊的機率，有的是希望主題訂定後，便於邀請相關領域的評審和業師，確保篩選的公平和輔導的正當性。然而，這些分類多半都模糊籠統，多數時候不一定具有實質意義。</p>
    <p>幾經考量後，迴響決定不做分類，反而因此重新意識到，我們是一個鼓勵青年採取「第一步」行動的計畫，我們的重點不是培力青年在各個領域提出多專業的解決方案，而是回應這個跨出第一步所需要面臨的共同困難，釐清行動計畫的初期會面對的挑戰，並建構克服的工具和方法。</p>
    <p>這個概念加深了要整理「行動方法論」的信念，指引了行動夥伴的訪談、招募，也幫助我們將計畫設計的重點聚焦在迴響裡的階段設計和促成行動的評選機制。</p>

    <br>
    <br>
    <h3>迴響精神的意義</h3>
    <p>迴響的三大精神，涵蓋了行動的三個主要情境，開始、面對卡關時和面對失敗的時刻，雖然說是一個提醒，我們卻默默希望他能是更大更遠的東西。</p>
    <p>我們期待迴響的精神，能把一時一刻我們最理想的樣子存起來，當我們感到孤立無援時，幫助我們認出夥伴，互相提醒、彼此打氣；當我們的身心已不再有力時，仍然能支持我們，一直朝理想前進。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
