<template>
  <Modal :name="'giant_room_2'" ref="modal">
    <br/>
    <br/>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <h5 class="t-center">(古壁畫的某一段落，紀錄著這段筆記⋯⋯)</h5>
    <br/>
    <br/>
    <p>「沒有真正的行動時，要回看自己的熱情，一直想尋求解答時，先學會重新描述問題。</p> 
    <p>團隊開始行動後，難免會因為多處碰壁而不相信自己、對現況感到不安，開始想要延後行動，和專家或更有經驗的人尋求解答。但，採取行動其實是很神奇的事情，有的時候行動本身就是目的，團隊會迴避知道最終的答案，害怕發現自己過往所有的行動，會不會都是徒勞，沒辦法軸轉，也停不下來。</p>
    <p>為了讓前進的方向能深刻的被檢視，避免我們藉由支線的行動迴避關鍵的問題，只是想要『看起來』很努力，迴響計畫在大會師後，除了設計思考教練，也同時會為團隊安排一位行動夥伴。」</p>
    <br/>
    <br/>
    <p>這段文案原意是用在介紹行動夥伴對青年團隊的意義為何，現在想起來，對迴響計畫本身也很適用。第一屆時只是帶著一股憨膽，相信行動是有價值的，第一步行動是有方法的，我們一定可以整理出來！但也是在一邊怕、一邊訪談當年的 15 位行動夥伴後，才真正確信是如此。關於行動夥伴，依舊非常清楚每一次約訪時的場景，在第一屆大會師，湊齊所有人在一桌享用晚會的餐點時，我們根本不敢靠近，覺得那是神聖不可侵犯的領域，自己如此渺小不堪，沒有值得交流的東西。</p>
    <p>四年以後，一樣在大會師的現場，在向青年團隊介紹行動夥伴前，我們在休息室裡像許久不見的老朋友那樣打鬧，現在想起來，這大概就是成為夥伴的魔法了吧。先接受所有關於自己的、不堪的狀況，不刻意凸顯，也不刻意隱藏，隨著時間過去，一直持續帶著和原來一樣的信念，做著該做的事情，當所有人都能確定你會在那裡，用某個一如既往的姿態，朝那個一再被訴說的目的地前進，「夥伴」關係就建立起來了。</p>
    <p>同時，這種夥伴關係，是可以擴大、可以超連結的。我們將行動夥伴介紹給青年團隊，與其說是運用計畫經費「安排業師」，不如說是把夥伴對我們的信任給出去，同時也確信這些進入大會師的青年，都能掌握同樣的魔法，成為彼此行動的夥伴。</p>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
