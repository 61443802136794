<template>
  <Modal :name="'s2_studio_1_time_capsule'" ref="modal">
    <h3>任務包誕生的小故事</h3>
    <p>任務包的誕生，是來自於一個「鼓勵行動的計畫」內生的矛盾，這樣的計畫，如果讓人提交計畫書之後，回覆說：「很抱歉，你沒有通過」的話，不是一開始就在否定行動嗎！？</p>
    <br>
    <br>
    <h4>「不看計畫書的完整度，而是看你的行動力」</h4>
    <p>不比誰會寫企劃，而是比誰能在時間內，最快、最頻繁的去行動，迴響很清楚，行動一定可以快速擴張對議題的想像，行動之後，通常團隊要做的事情也會修改許多。</p>
    <p>任務包的規則很單純，在截止時間之前可以無限次提交，每次提交，都會收到教練對任務的回饋，不通過的意思不是在跟你說你不行前進，而是提醒你哪裡還可以做得更好，不然下階段會很辛苦，你可以根據回饋修正後重新提交，通過後則可以看到下個任務挑戰。</p>
    <br>
    <br>
    <h4>「邊做任務包，其實已經開始行動、學習」</h4>
    <p>每年超過 100 組的行動團隊報名，但是迴響在實體活動的限制下，其實只 有 20-30 組能從任務包階段晉級，比起「篩選」，迴響更希望能在任務包階段就幫助「想行動卻沒有方法的團隊」前進，於是致力於提升任務包的「教學成分」，第三屆開始，每個任務變成線上課程，上完課就可以直接出發，即使最終沒有入選，其實也已經有成長和突破。</p>
    <br>
    <br>
    <h4>「任務包的開始，本身也是一個最小可行的行動」</h4>
    <p>一開始設計任務包是在兩件事情中取捨，到底是要寫的足夠有引導性，還是要打開一些自由度?寫得太過清楚，會讓大家行動解嗨，覺得只是按照指示寫作業交作業而已，寫得太開放，又擔心青年看不懂，因為說明太少而感到困惑，當時執行團隊中的這兩派擁護者分別用半個小時各自完成一份任務包的草案，拿給團隊中最沒有設計思考包袱的素人夥伴看看，獲得的回饋是兩份都很難據以採取行動。</p>
    <p>最終我們發現，任務包的重點不是文字的設計，而是互動和流程的設計，不管引導文字清楚還是模糊，要將任務包看成溝通的媒介而不是終點，要設計的是任務包的體驗流程，而非單純的文字。</p>
    <p>當焦點一轉移，本來對立的觀點，突然就可以在流程的不同階段體現各自在意的價值，慢慢因為歷屆青年的回饋，迭代成今天的任務包。</p>
    <br>
    <br>
    <p>註：<br>•　離開迴響後約有 69% 的青年再使用過任務包概念展開行動<br>•　離開迴響後，這些青年又多帶了約 5 倍數量給「沒參加過迴響的青年」一起使用任務包概念來面對不同議題，展開第一步行動<br>•　每年都會有不少組別，無論議題是甚麼，他們的第一步行動都是「設計一個任務包」，也算是體驗好有口碑的證明吧！</p>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
