<template>
  <Modal :name="'y2018_3'" ref="modal">
    <!-- <img src="./images/2018_3.jpg" alt=""> -->
    <p>絹印的概念，不，或是說，「一人一件擁有自己獨一無二的衣服」的信念，就這樣被流傳下來。</p>
    <p>動手做的過程，總是印象深刻，永遠都是新的體驗，甚至讓離開學校很久的 30 世代青年，也有眼睛一亮的時刻。</p>
    <p>「創造」是會讓人幸福的，而任何一個微小的舉動，都能為自己的生活帶來不一樣的感動。</p>
    <p>（雖然還是要一邊教學、洗絹網、圖顏料、用刮板...）</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
