// import main_happy from './bgms/main/happy.mp3'
// import main_silent from './bgms/main/silent.wav'
// import addon_fire from './bgms/addon/fire.wav'
// import addon_waterfall from './bgms/addon/waterfall.wav'
// import ding from './effect/ding.wav'
// import tab_cho from './effect/tab_cho.wav'
// import tab_select from './effect/tab_select.wav'
// import drop_fish_point from './effect/drop_fish_point.mp3'
// import gain_obj from './effect/gain_obj.wav'
// import mission_complete from './effect/mission_complete.wav'
// import npc_m from './effect/npc_m.wav'
// import open_b from './effect/open_b.wav'
// import shake_tree from './effect/shake_tree.wav'
// import tog_popup from './effect/tog_popup.wav'
// import tree_drop_obj from './effect/tree_drop_obj.wav'
// import hmw from './effect/hmw.wav'
// import main_happy from './bgm_main_happy.mp3'
// import main_cave from './bgm_main_cave.mp3'
// import main_top from './bgm_main_top.mp3'

const data = {
  main_happy: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/main/happy.mp3',
  main_cave: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/main/cave.mp3',
  main_top: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/main/top.mp3',
  main_examine: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/main/examine.mp3',
  addon_wind: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/addon/wind.mp3',
  addon_birds: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/addon/birds.mp3',
  addon_fire: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/addon/fire.mp3',
  addon_waterfall: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/addon/waterfall.mp3',
  addon_osawa: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/addon/osawa.mp3',
  addon_lake_goddess: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/bgms/addon/lake_goddess.mp3',
  ding: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/ding.wav',
  dialog_choose: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/tab_cho.wav',
  dialog_select: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/tab_select.wav',
  drop_fish_point: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/drop_fish_point.mp3',
  gain_obj: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/gain_obj.mp3',
  mission_complete: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/mission_complete.wav',
  npc_m: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/npc_m.wav',
  npc_m_2: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/npc_m_2.wav',
  npc_m_3: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/npc_m_3.mp3',
  npc_m_4: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/npc_m_4.mp3',
  npc_m_alder: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/npc_m_alder.mp3',
  npc_m_god: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/npc_m_god.mp3',
  npc_partner_m: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/effect_partner_npc_m.wav',
  npc_partner_f: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/effect_partner_npc_f.wav',
  npc_bear: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/npc_bear.mp3',
  open_b: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/open_b.wav',
  close_b: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/close_b.wav',
  shake_tree: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/shake_tree.wav',
  tog_popup: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/tog_popup.wav',
  tree_drop_obj: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/tree_drop_obj.wav',
  hmw: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/hmw.wav',
  fly_fishing: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/fly_fishing.mp3',
  effect_walk_step: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect_walk_step.mp3',
  take_pic: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/take_pic.mp3',
  take_cable_car: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/take_cable_car.mp3',
  rock_moving: 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/sound/effect/rock_moving.mp3',
}

export default data
