<template>
  <Modal :name="'giant_room_1'" ref="modal">
    <br/>
    <br/>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <h5 class="t-center">(古壁畫的某一段落，紀錄著這段話⋯⋯)</h5>
    <br/>
    <br/>
    <p>「這群志工夥伴從招募進來後就不會離開，後續有什麼活動大家都很會參與，就好像磁鐵一樣，這裡有股莫名的引力吸引著他們，而為什麼會如此是因為對志工動機進行蠻嚴格的篩選，會希望進來的志工，對於迴響或對於團隊都有一定程度的好奇以及自我想要學習與了解的事情，也因此可能填寫志工報名表單時，沒有寫的很強烈的好奇與積極心，有可能我們就是在另一個更適合的地方見面了。</p>
    <p>那也因為我們招募這樣的志工，培訓說明會簡報就是在志工訓練環節裡面最重要的一環，在簡報中要傳遞迴響的團隊氛圍、處事態度與合作默契，並在說明會現場設計執行團隊與志工互相認識的橋斷，因為在活動當下沒有特別分誰是志工，大家都是迴響執行團隊引以為傲的一員，這是從第一屆的志工行前說明會就有這樣的感覺，只是二屆在把他更加釐清了！</p>
    <p>這是從我在迴響計畫第一屆擔任志工時，參與志工說明會那時心裡滿忐忑不安，也很好奇自己會做什麼事，對於迴響是什麼也好奇，對於參與志工之後的自己也好奇，這場說明會不是那麼的正式，非常自由和有趣，團隊內部自我介紹的每一個人是那麼的新奇和開放，說明過程中Jarah說過其實你就是我們的一份子，在那刻得到了份歸屬感，迴響志工從二屆到現在想要給志工們的感受其中之一，就是個歸屬感，想要讓你認同自己的價值與行動，一切都是有機會的。</p>
    <p>我覺得應該就是很謝謝他們願意來當我們的志工，在活動過程中，那麼的用心感受與付出，如果沒有他們，迴響可能不會那麼成功！」</p>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
