<template>
  <Modal :name="'y2020_7'" ref="modal" :isWhite="true">
    <img src="./images/2020_7-1.jpg" alt="">
    <p>如同每一屆的評審，每一屆的行動夥伴，每一次的評選，都再一次提醒著迴響夥伴，「我們坐在所謂的評審室、評審桌前看到的提案，有些人可能是用盡了勇氣，第一次踏出第一步行動的人；有些人可能深陷議題許久，第一次成為行動者去改變現況...」</p>
    <p>而面對這樣的團隊，我們如何秉持著迴響初衷？讓評審給予的不只是夠不夠資格獲得資源，而是團隊們還可以得到什麼方法和幫助，讓青年們自行決定下一步的可能。</p>
    <img src="./images/2020_7-2.jpg" alt="">
    <img src="./images/2020_7-3.jpg" alt="">
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
