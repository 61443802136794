<template>
  <Modal :name="'y2017_3'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2017_3-1.jpg" alt=""> -->
    <p>讓所有人穿上同一款衣服，可以讓現場更有凝聚感。</p>
    <p>青年迴響的 T-shirt，應該要設計成什麼模樣？是代表社會議題的大享嗎？還是不斷出現的行動小人？是否要有迴響 LOGO 或標語？哪一句比較讓人印象深刻？</p>
    <p>這時間突然有人說：「為什麼我們不讓穿的人自己決定想要什麼圖樣？」</p>
    <p>那就絹印吧！買全素的衣服，讓青年在大會師 DIY，完成屬於自己的衣服！</p>
    <!-- <img src="./images/2017_3-2.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
