<template>
  <Modal :name="'s2_team_type_tree_6'" ref="modal" :isWhite="true">
    <h5 class="t-center">團隊樣貌</h5>
    <br>
    <h3>一起迴響</h3>
    <p>「我們如何能讓更多青年為自己在意的事展開行動？」其實每年迴響都會收到...跟迴響一樣的提案。在迴響的過程中，其實有些行動者會慢慢變成我們的朋友，慢慢的也會認識到生活、工作的其他面向，後來互相邀請，成為迴響的團隊。</p>
    <p>對有些人來說，他也想要讓更多人可以感受到迴響當初帶給他的體驗，也有些人，是對迴響的工作模式感到興趣。對迴響來說，也希望迴響最終變成一個大社群，行動者們彼此照應，一起做著各式各樣有趣的事情。</p>
    <br>
    <br>
    <p>2017 浪犬博士行動夥伴 Lucy、2017 參與任務包 力堃、2017 志工 Sara、2017 志工 高雯、2017 水地圖 周穎、2018 Date Myself 君芃、2018 Be There 不見不散 Piske、2018 解憂咖啡館 杜蒨妮、2018 Dadable 謝璿...</p>
    <p>這些人後來都在迴響的執行團隊一起工作過。</p>
    <br>
    <br>
    <br>
    <!-- <h5 class="t-center">像這樣的團隊，還有 2019 小紅帽、2017 浪犬博士...</h5> -->
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
