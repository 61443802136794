<template>
  <Modal :name="'s2_studio_1'" ref="modal" :isWhite="true">
    <h3>任務包</h3>
    <p>任務包是靑年迴響計畫開發的一套行動工具，在計畫提案的階段，團隊對議題有個模糊的認識、還不確定可以怎麼解決問題時，任務包用線上課程搭配學習單，提供基本的行動知識與工具，讓團隊可以有步驟且有條理地一步步前進，一邊向內探索團隊成員間觀點的差異，一邊向外有實際採取的第一步行動的指引，其中的任務包含：</p>
    <p>•　辨識議題假設：協助團隊消化彼此對於議題的觀點，豐富對議題的認識<br>•　規劃與執行訪談：協助團隊藉由訪談接觸到不同觀點<br>•　問題整理框架：協助團隊消化訪談資料，將問題聚焦，取得團隊共識<br>•　發想的架構與指引：協助團體發揮創意，想像不同的可能性</p>
    <br>
    <br>
    <p>經過四年的反覆修正，今年迴響計畫希望能讓更多還在猶豫、對於行動還不是很有把握的青年，能夠使用任務包資源展開自己的第一步，因此決定將完整的任務包全面公開。</p>
    <p>這本<a href="https://gmail.us6.list-manage.com/subscribe?u=38244faddfcf581ece1c2d6fb&id=71db184b96" target="_blank">任務包概覽手冊</a>，將帶你初步認識任務包的運作邏輯與行動價値，可以立即下載，馬上聞香。如果你有實際專案想要開始使用任務包展開第一步，也可以<a href="http://missions.rethinktaiwan.com/" target="_blank">任務包實戰基地</a>實際體驗任務包的流程。</p>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
