<template>
  <Modal :name="'planet_bravery'" ref="modal" :isWhite="true">
    <h3>勇氣 Bravery</h3>
    <p class="t-center">「今天就是放手一搏的好日子。」</p>
    <br />
    <p>勇氣，是一種跨越限制的狀態，是在喜歡的人面前誠實，是在龐大的壓力下還能走的坦蕩。</p>
    <p>迴響重視的價值是，請求准許不如請求原諒，覺得該做的事情，定有屬於你的原因，出發是選擇放下限制，由別人加諸的，留給其他人去遵循。</p>
    <img src="../../images/world/NPC/events/Planet13Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
