<template>
  <Modal :name="'s1_intro_stone'" ref="modal" :isWhite="true">
    <h4>「我們如何能鼓勵更多年輕人為自己在乎的事情採取行動？」</h4>
    <p>這裡記載了迴響從 2017 年開始，每年重要的一些決定或里程碑。</p>
    <p>每年，我們也是行動中的團隊，持續嘗試計畫的不同可能性。有些成功的部分會連續出現在好幾屆，也有不少嘗試，隨著時間消失或調整。</p>
    <p>若你也在舉辦有點相似性的計畫、社群、或者單純好奇這些亮點的前後脈絡、效果，歡迎聯絡我們聊聊具體的經驗。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
