<template>
  <Modal :name="'partner_6'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>創咖啡／LEO</h3>
    <p>關於【創咖啡】</p>
    <p>#我們的鼓舞年輕人創業不是在打高空 #而是我們挺你</p>
    <p>創咖啡是一間擁有許多新鮮的肝、熱血的年輕人聚集的咖啡館。</p>
    <p>為了解決台灣創業環境不鼓勵失敗、害怕失敗的氛圍。創辦人 Leo 在成立創咖啡前曾擔任上市櫃公司及金融業的高階主管，但是這樣的成就反而讓他產生疑惑，開始尋找人生的答案。</p>
    <p>創咖啡不做好高騖遠的事情，只是讓創業家知道有一個地方，來這裡會有人挺你、支持你、在這裡找到志同道合的夥伴。我們也不知道能做到多少，但是只要有夢想、理想，就應該付諸實踐。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_6.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
