<template>
  <Modal :name="'s1_intro_route'" ref="modal" :isWhite="true">
    <h3>登頂步道：跟隨迴響的足跡</h3>
    <p>無論你是第一次認識迴響，還是老夥伴，在你選擇一條想前行的路後，邊探索、邊重新思考：「在行動路上，我真正需要的是什麼？」</p>
    <h4>觀展心態</h4>
    <p>迴響鼓勵行動，也支持「反思後，暫停」的決定。往往初步行動皆迎來許多挑戰、甚至失敗，若直接放棄、或一意孤行，都是非常可惜的事；接下來該你體驗，當你踏入迴響山頭，面臨諸多選擇時，一路上豐富的資源，會駐足？還是路過？一切依你為主。</p>
    <h4>一探所有</h4>
    <p>迴響相信，「真實的行動，是最純粹的感動」，而我們始終先打頭陣、創造藉口，從活動的儀式設計、別出心裁的紀念物、廣邀多元領域的行動夥伴與先行者，讓每一屆與會者，看見我們實驗的全貌－上述這些不為人知的時刻，在下一個路口，等你體會。</p>
    <h4>不妨試試</h4>
    <p>當你沉浸在迴響的感動時刻，記得也要繼續走，直到迴響世界的頂峰。是的，任何一條路，都會穿越雲海與森林，到達那至高點時，可以閉上眼睛，跟著遙想 10 年後的台灣，還有內心深處的真實叩問。不妨試試，留下你在此的證明，成為世界的行動者。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
