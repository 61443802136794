<template>
  <Modal :name="'item_picture'" ref="modal">
    <div id="picture_materials">
      <img class="bg" src="./images/picture_bg.svg" alt="">
      <img class="elf" :src="object_imgs.elf[player.state.pictureElfImgIndex]" alt="" />
      <img class="user" :src="object_imgs.user[player.state.pictureUserImgIndex]" alt="" />
      <img class="dog" :src="object_imgs.dog[player.state.pictureDogImgIndex]" alt="" />
    </div>
    <h3>登頂照</h3>
    <p>{{moment(this.player.state.pictureDate).format('YYYY.MM.DD')}} 這天，好不容易爬到了迴響行動大山山頂。</p>
    <p>上面的風景好美，常駐在山頂的攝影師說，從他那拍過去的 view，能把群山的景致和遠方閃爍的光芒，拍成一幅 19 世紀名畫<a href="https://zh.wikipedia.org/wiki/%E9%9B%BE%E6%B5%B7%E4%B8%8A%E7%9A%84%E6%97%85%E4%BA%BA#/media/File:Caspar_David_Friedrich_-_Wanderer_above_the_sea_of_fog.jpg" target="_blank">《霧海上的旅人》</a>，他這麼一說我直接買單，花了 2 顆小小人果實請他幫我拍照了。</p>
    <p v-if="!player.state.pictureHasDog && !player.state.pictureHasElf">好希望下次能有「夥伴」可以一起登山一起拍照呢！</p>
    <canvas ref="canvas" id="picture_canvas" width="1920px" height="1080px"></canvas>
    <!-- <button v-on:click="generate()">產生合照</button> -->
    <a class="button" v-on:click="save()">下載我的登頂照片</a>
  </Modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { get_random_int, download } from 'js/map/global.js'
import Modal from './modal.vue';
import moment from 'moment'
import picture_user_1 from './images/picture_user_1.svg'
import picture_dog_1 from './images/picture_dog_1.svg'
import picture_dog_2 from './images/picture_dog_2.svg'
import picture_dog_3 from './images/picture_dog_3.svg'
import picture_elf_1 from './images/picture_elf_1.svg'
import picture_elf_2 from './images/picture_elf_2.svg'
import picture_elf_3 from './images/picture_elf_3.svg'

export default {
  components: {
    Modal
  },
  computed: {
    ...mapState([
      'player'
    ]),
    // ...mapMutations([
    //   'updatePicture'
    // ])
  },
  data: function () {
    return {
      // ctx: null,
      canvas: null,
      object_imgs: {
        user: [picture_user_1],
        dog: [picture_dog_1, picture_dog_2, picture_dog_3],
        elf: [picture_elf_1, picture_elf_2, picture_elf_3],
      }
    }
  },
  methods: {
    moment,
    async generate(commit) {
      console.log('take!')

      let nameList = `${this.player.name==''?'無名的行動者':this.player.name}`;
      if(this.player.state.hasElf) nameList += `、${this.player.elfName==''?'小精靈':this.player.elfName}`;
      if(this.player.state.hasDog) nameList += `、${this.player.dogName==''?'狗狗':this.player.dogName}`;

      commit('updatePicture', {
        hasPicture: true,
        pictureDate: new Date(),
        pictureNameList: nameList,
        pictureHasElf: this.player.state.hasElf,
        pictureHasDog: this.player.state.hasDog,
        pictureHmw: this.player.hmw,
        pictureUserImgIndex: get_random_int(this.object_imgs.user.length),
        pictureDogImgIndex: get_random_int(this.object_imgs.elf.length),
        pictureElfImgIndex: get_random_int(this.object_imgs.dog.length),
      })
    },
    draw() {
      console.log(this.player.state)
      this.canvas = document.querySelector('#picture_canvas');
      let dom_bg = document.querySelector('#picture_materials .bg')
      let dom_user = document.querySelector('#picture_materials .user')

      const ctx = this.canvas.getContext('2d');
      ctx.drawImage(dom_bg,0,0,this.canvas.width,this.canvas.width*dom_bg.height/dom_bg.width);

      //draw elf
      if(this.player.state.pictureHasElf) {
        let dom_elf = document.querySelector('#picture_materials .elf');
        ctx.drawImage(dom_elf,0,0,this.canvas.width,this.canvas.width*dom_elf.height/dom_elf.width);
      }
      // draw dog
      if(this.player.state.pictureHasDog) {
        let dom_dog = document.querySelector('#picture_materials .dog');
        ctx.drawImage(dom_dog,0,0,this.canvas.width,this.canvas.width*dom_dog.height/dom_dog.width);
      }
      // draw user
      ctx.drawImage(dom_user,0,0,this.canvas.width,this.canvas.width*dom_user.height/dom_user.width);

      ctx.font = "16px Arial";
      ctx.fillStyle = '#609C9F';
      ctx.textAlign = "start";
      ctx.fillText(this.player.state.pictureNameList, 24, 1030);
      ctx.fillText(`${moment(this.player.state.pictureDate).format('YYYY.MM.DD')} 迴響行動大山 登頂成功`, 24, 1055);
      if(this.player.state.pictureHmw !== '') {
        ctx.textAlign = "end";
        ctx.fillText(`看見的大象是：「${this.player.state.pictureHmw}」`, 1920-24, 1055);
      }
    },
    save() {
      let dataURL = this.canvas.toDataURL("image/png");
      download(dataURL, `迴響行動大山_${moment(this.player.state.pictureDate).format('YYYYMMDD_HHmm')}.png`, "image/png")
    }
  }
}
</script>

<style scoped lang="scss">
canvas#picture_canvas {
  max-width: 100%;
  border: solid 10px #fff;
  border-radius: 2px;
  box-sizing: border-box;
}
#picture_materials {
  position: relative;
  display: none;
  img {
    position: absolute;
    left: 0;
    top: 0;
  }
}
a.button {
  display: block;
  text-align: center;
  border-radius: 16px;
  line-height: 44px;
  width: 200px;
  margin: 32px auto 40px;
  background: #27E1B5;
  color: #074958 !important;
}
</style>
