



export var items = {
  'passport': {
    name: '登山證',
    onlyOne: true,
    // intro: '行動者的登山證，讓我們在這片濃霧中能夠站穩腳步',
    intro: '操作指南：按 <mark>A 鍵</mark>與人事物互動或對話，<mark>B 鍵</mark>打開背包，<mark>Q 鍵</mark>退出對話或關閉視窗。',
    category: '寶物',
    use: {
      // text: '山屋櫃檯給了我這張登山證，當作我來過這裡的證明！'
      // text: '跟 NPC 對話時，選擇<mark>不同的選項</mark>，可以得到截然不同的回覆喔！可以<mark>重複</mark>跟 NPC 對話尋找線索。'
      text: '給這個世代的青年，一個行動的藉口！',
    }
  },
  'ax': {
    name: '斧頭',
    // onlyOne: true,
    intro: '樵夫所使用的斧頭，非常的普通，不曉得<mark>掉進湖裡</mark>能不能拿到金銀斧頭呢？⋯⋯',
    category: '道具',
    use: {
      text: '這把斧頭似乎跟<mark>迴響大澤</mark>的傳說有關，不如去下區的大澤看一看吧？',
      // modal: 'ax_strange'
    }
  },
  'ax-decision': {
    name: '決策斧頭',
    onlyOne: true,
    intro: '用來劈開未知的斧頭。',
    category: '道具',
    use: {
      modal: 'ax_decision'
    }
  },
  'ax-checkin': {
    name: '缺應斧頭',
    onlyOne: true,
    intro: '用來劈開尷尬的斧頭。',
    category: '道具',
    use: {
      modal: 'ax_checkin'
    }
  },
  'ax-strange': {
    name: '怪怪斧頭',
    onlyOne: true,
    intro: '用來劈開無力的斧頭。',
    category: '道具',
    use: {
      modal: 'ax_strange'
    }
  },
  'ax-sync': {
    name: '同步斧頭',
    onlyOne: true,
    intro: '用來劈開冗餘的斧頭。',
    category: '道具',
    use: {
      // text: '這把斧頭似乎跟<mark>迴響大澤</mark>的傳說有關，不如去下區的大澤看一看吧？',
      modal: 'ax_sync'
    }
  },
  'ax-value': {
    name: '價值斧頭',
    onlyOne: true,
    intro: '用來劈開猜疑的斧頭。',
    category: '道具',
    use: {
      // text: '這把斧頭似乎跟<mark>迴響大澤</mark>的傳說有關，不如去下區的大澤看一看吧？',
      modal: 'ax_value'
    }
  },
  'ax-teamwork': {
    name: '默契斧頭',
    onlyOne: true,
    intro: '用來冗餘誤會的斧頭。',
    category: '道具',
    use: {
      // text: '這把斧頭似乎跟<mark>迴響大澤</mark>的傳說有關，不如去下區的大澤看一看吧？',
      modal: 'ax_teamwork'
    }
  },
  'fishingRob': {
    name: '釣竿',
    onlyOne: true,
    intro: '釣魚的工具，去<mark>閃閃發光</mark>的水面按<mark> A </mark>試試看能釣到什麼吧！',
    category: '道具',
    use: {
      text: '仔細查看釣竿，發現原來不需要在包包裡點按，只要直接在水邊按<mark> A </mark>就好，多喬幾次位子試試看吧！',
    }
  },
  'postit': {
    name: '便利貼',
    onlyOne: true,
    intro: '設計思考教練們常用的工具，背後沾有奇怪的黏液。裡面似乎寫了<mark>教練的詳細介紹</mark>？',
    category: '道具',
    use: {
      modal: 's2_studio_3'
    }
  },
  'sandpaper': {
    name: '砂紙',
    onlyOne: true,
    intro: '鼴鼠們用地底下的堅硬礦石反覆敲打製成的砂紙，應該可以用來磨一些堅硬的<mark>石頭</mark>？',
    category: '道具',
    use: {
      text: '用手搓一搓砂紙後，感覺超痛的。帶著砂紙到迴圈森林的深處吧，似乎不需要特別按 A 使用。'
    }
  },
  'brush': {
    name: '毛刷',
    onlyOne: true,
    intro: '狐狸尾巴的毛做成的毛刷，看起來好像有偷工減料的嫌疑⋯⋯似乎能用來清掃<mark>磨石</mark>產生的沙塵？',
    category: '道具',
    use: {
      text: '聽見了迷樣的聲音：「你不尊重狐！狐生氣了！」帶著毛刷到迴圈森林的深處吧，不需要特別按 A 使用。'
    }
  },
  'chisel': {
    name: '鑿刀',
    onlyOne: true,
    intro: '據說是老河狸所掉下的門牙所製成的，真的能用嗎？⋯⋯話說這硬度好像比<mark>石頭</mark>還硬。',
    category: '道具',
    use: {
      text: '拿起鑿刀聞了一聞，怎麼好像有菜渣的味道⋯⋯帶著鑿刀去迴圈森林的深處吧，不需要特別按 A 使用。'
    }
  },
  'loopPack': {
    name: '行動錦囊',
    onlyOne: true,
    intro: '協助登頂者能持續行動的行動錦囊，裡面有<mark>行動迴圈</mark>的秘訣，<mark>按 A 鍵</mark>能打開查看。',
    category: '道具',
    use: {
      modal: 's2_studio_2'
    }
  },
  'missionPack': {
    name: '戰術背包',
    onlyOne: true,
    intro: '協助登頂者跨出第一步行動的戰術背包，裡面有<mark>任務包</mark>的資訊，<mark>按 A 鍵</mark>能打開查看。',
    category: '道具',
    use: {
      modal: 's2_studio_1'
    }
  },
  'stone-flavor': {
    name: '溫石',
    onlyOne: true,
    intro: '阿德的石頭，據說掃地僧常偷去放在懷裡止饑，成分是滑石。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_1',
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-hsu': {
    name: '砭石',
    onlyOne: true,
    intro: '皓甯的石頭，中醫刮痧用的神器，拿著就想來刮一下⋯⋯<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_2',
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-sunnyrush': {
    name: '紅磚',
    onlyOne: true,
    intro: '怡雅的石頭，跟藺草一樣從苑裡帶來的特產，上好品質。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_3'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-dog': {
    name: '化石',
    onlyOne: true,
    intro: 'Lucy 的石頭，某天愛犬泰瑞在山上挖出來的，不拿去鑑定一下嗎？<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_11'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-flaneur': {
    name: '鹽',
    onlyOne: true,
    intro: '維綱的石頭，共享生活的餐桌上不可或缺的存在，不是攝影用的銀鹽。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_5'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-leo': {
    name: '木炭',
    onlyOne: true,
    intro: 'LEO 的石頭，可以烘出炭培咖啡，濃厚得讓人想起創業之苦。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_6'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-happen': {
    name: '礫石',
    onlyOne: true,
    intro: '家緯的石頭，來自好伴旁的台中綠川，有淨化水質的用途。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_9'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-calls': {
    name: '冰河水晶',
    onlyOne: true,
    intro: '子鈞的石頭，喜馬拉雅山脈產出的特殊水晶，感覺有強大的磁場。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_7'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-chat': {
    name: '薔薇石英',
    onlyOne: true,
    intro: '品汶的石頭，又稱為芙蓉石，象徵著愛與包容。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_10'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-bankculture': {
    name: '漢白玉',
    onlyOne: true,
    intro: '璦婷的石頭，上次採訪磨石子老師傅的時候得到的，名字很美。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_4'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-wink': {
    name: '隕石',
    onlyOne: true,
    intro: '張維的石頭，星星眨眨眼，星塵掉下來。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_12'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-pley': {
    name: '石油',
    onlyOne: true,
    intro: '阿屁的石頭，逆轉未來遊戲中狂剷共和國的經濟命脈，其實是塑膠做的道具。<mark>按 A 鍵</mark>能打開查看。',
    category: '石頭',
    use: {
      modal: 'partner_8'
      // text: '這是<mark>石頭湯</mark>任務的開端，等待其他行動夥伴上線吧！'
    }
  },
  'stone-recipe': {
    name: '石頭湯的食譜',
    onlyOne: true,
    intro: '是食譜，不是石內卜。<mark>按 A 鍵</mark>能打開查看，裡面有石頭湯的故事。',
    category: '石頭',
    use: {
      modal: 'item_stone_soup'
    }
  },
  'flower-wizard': {
    name: '曼陀羅',
    onlyOne: true,
    intro: '覺察巫師的花，心理學家榮格將其比擬為內在自我人格的核心。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_10'
    }
  },
  'flower-LRRH': {
    name: '康乃馨',
    onlyOne: true,
    intro: '小紅帽的花，母性之花，象徵不朽的母愛。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_5'
    }
  },
  'flower-family': {
    name: '紅玫瑰',
    onlyOne: true,
    intro: '行動奶爸的花，父親節的花，花語是「生命中的太陽」。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_2'
    }
  },
  'flower-movie-rabbit': {
    name: '爆米花',
    onlyOne: true,
    intro: '電影兔的花，你都吃甜的還是鹹的？。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_9'
    }
  },
  'flower-climber': {
    name: '苦花',
    onlyOne: true,
    intro: '森林登山客的信物，是台灣溪流常見的魚。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_4'
    }
  },
  'flower-snow-man': {
    name: '雪花',
    onlyOne: true,
    intro: '雪堡的花，產於雪地溫泉，冷熱交會之處。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_7'
    }
  },
  'flower-elf': {
    name: '向日葵',
    onlyOne: true,
    intro: '關懷小精靈的花，寓意是「沉默的爱，沒有說出口的愛」。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_3'
    }
  },
  'flower-tour-guide': {
    name: '鳳凰花',
    onlyOne: true,
    intro: '提燈嚮導的花，畢業季常見，花語是「離別、思念、火熱青春」。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_6'
    }
  },
  'flower-bear': {
    name: '豬五花',
    onlyOne: true,
    intro: '黑雄的花，寒風中就想與三五好友煮一碗熱騰騰的火鍋。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_11'
    }
  },
  'flower-fisherman': {
    name: '花心',
    onlyOne: true,
    intro: '釣魚的人的花，更生人之間的默契，唱給服刑結束獄友的一首歌。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_1'
    }
  },
  'flower-carpentar': {
    name: '木頭雕花',
    onlyOne: true,
    intro: '迦瞿的花，傳統二手傢俱常見的，美麗的木頭雕花。<mark>按 A 鍵</mark>能打開查看。',
    category: '花',
    use: {
      modal: 'team_8'
    }
  },
  'picture': {
    name: '登頂照',
    onlyOne: true,
    intro: '登頂行動大山時，阿頭幫我拍的照片，<mark>點按 A 鍵</mark>能打開查看。',
    category: '寶物',
    use: {
      modal: 'item_picture'
    }
  },
  'badge': {
    name: '小人徽章',
    onlyOne: true,
    intro: '迴響大神出現後，遺留給我的紀念品。閃閃發亮的徽章，隱約能感覺到某種神力？',
    category: '寶物',
    use: {
      text: '搭船的時候不能使用，請先上岸吧。',
      god: true,
      transferAndDialog: {x: 206, y: 20}
    }
  },
  'action-traffic-light': {
    name: '行動紅綠燈',
    onlyOne: true,
    intro: '迴響經典的紀念品，是一個手作的燈座，紅綠燈從一屆以來就是迴響重要的象徵。',
    category: '寶物',
    use: {
      text: '行動的路上，停、看、聽。'
    }
  },
  'time-capsule-forest': {
    name: '時空膠囊',
    onlyOne: true,
    intro: 'yo',
    category: '寶物',
    use: {
      text: 'yo'
    }
  },
  'time-capsule-campfire': {
    name: '時空膠囊',
    onlyOne: true,
    intro: 'yo',
    category: '寶物',
    use: {
      text: 'yo'
    }
  },
  'time-capsule-mountain': {
    name: '時空膠囊',
    onlyOne: true,
    intro: 'yo',
    category: '寶物',
    use: {
      text: 'yo'
    }
  },
  // 'tinder': {
  //   name: '行動火種',
  //   intro: '老協作所傳承的火種，即便在濕氣很重的時候也能點火。',
  //   category: '道具',
  //   use: {
  //     // text: '在原地看著火種發呆⋯⋯才發現火種原來只要帶在身上就可以點火了，不需要特別按 A 使用。'
  //     transfer: {'x': 41, 'y': 284}
  //   }
  // },
  'matches': {
    name: '火柴盒',
    intro: '為了鼓勵行動，老協作送了我一盒充滿能量的火柴，即便在<mark>濕氣</mark>很重的情況下也能點起火來。',
    category: '道具',
    use: {
      text: '在原地看著火柴發呆⋯⋯原來只要帶著火柴去跟<mark>營火</mark>對話就好，不需要特別按 A 使用。'
    }
  },
  'book-first': {
    name: '考古筆記',
    intro: '上次遇到一個<mark>考古學家</mark>交給我的，聽說可以藉此讀懂<mark>古老巨人</mark>的壁畫故事？',
    category: '道具',
    use: {
      text: '<a href="https://issuu.com/jarahchou/docs/_____191007____27.3m___" target="_blank">《行動旅人求生筆記》</a>，記載著第一屆青年迴響計畫的誕生、幕後與願景'
    }
  },
  'rookies-guide': {
    name: '武林秘笈',
    intro: 'yo',
    category: '寶物',
    use: {
      text: '<a href="https://drive.google.com/file/d/0B0sbkZN71AsGSnhUVVNFUjRQNDg/view" target="_blank">《Rookie\'s Guide 設計思考新手手冊》</a>，設計思考入門觀念與工具應用'
    }
  },
  'walkie-talkie': {
    name: '對講機',
    intro: '迴響團隊的對講機，調對頻率後<mark>按 A 使用</mark>，似乎會發生不可思議的事情⋯⋯',
    category: '寶物',
    use: {
      transferAndDialog: {'x': 41, 'y': 293},
      text: '搭船的時候不能使用，請先上岸吧。'
    }
  },
  'apple': {
    name: '蘋果',
    intro: '品種眾多，有些一台要上萬塊，有些則是用來驅趕醫生。這麼高科技的產品，拿去給<mark>木匠弟弟</mark>鑑定吧？',
    category: '素材',
    use: {
      text: '雖然想張開嘴巴吃掉，但迴響小人好像沒有畫嘴巴。去找找看<mark>大澤前的木匠</mark>問個清楚吧。'
      // transfer: {'x': 206, 'y': 24},
      // text: '搭船的時候不能使用，請先上岸吧。',
      // god: true
    }
  },
  'branch': {
    name: '樹枝',
    intro: '尋常的樹枝，經過加工的話會是很萬用的素材。木頭⋯⋯加工⋯⋯咦？<mark>木匠</mark>？',
    category: '素材',
    use: {
      text: '揮一揮樹枝喊：「去去武器走」，得到的只有尷尬。去找找看<mark>山屋旁的木匠</mark>看能怎麼使用樹枝吧。'
      // transferAndDialog: {'x': 41, 'y': 286},
      // text: '搭船的時候不能使用，請先上岸吧。'
    }
  },
  'orange': {
    name: '柳橙',
    intro: '為什麼楊柳能搖下柳橙咧⋯⋯真是無心插柳柳橙汁。',
    category: '素材',
    use: {
      text: '當生命給你一顆檸檬時，把它榨成檸檬汁；當生命給你柳橙時⋯⋯就做成<a href="https://www.facebook.com/CleanYoo/" target="_blank">柑淨柚</a>吧！？'
    }
  },
  'nail': {
    name: '鐵釘',
    intro: '搖蘇鐵掉下來的。蘇鐵在生長期間需要大量鐵元素，許多人會將鐵釘釘在其主幹，以保持生機哦！',
    category: '素材',
    use: {
      text: '去找找看<mark>山屋旁的木匠</mark>，應該可以拿鐵釘來製作一些工具！'
    }
  },
  'seed': {
    name: '小小人種子',
    intro: '小小人遺留下來的種子，蘊含特別的能量，似乎會萌芽出某些行動？⋯⋯<mark>多搖一些</mark>以備不時之需吧！',
    category: '寶物',
    use: {
      text: '貌似有很多人在搜集果實？也許可以拿去跟人<mark>以物易物</mark>也說不定。好像只要推樹，就能找到小小人呢⋯⋯'
    }
  },
  'bottle': {
    name: '寶特瓶',
    intro: '溪流裡常見的垃圾，但沒想到大澤裡也有，這個世界的垃圾似乎比想像中還齊全啊⋯⋯',
    category: '素材',
    use: {
      text: '發揮你的環保精神，拿去給木匠弟弟看看，說不定能夠循環再利用！'
    }
  },
  'hot-spring-egg': {
    name: '溫泉蛋',
    intro: '山頂的溫泉釣出的蛋，蛋黃呈半熟狀態，好想吃掉啊⋯⋯',
    category: '素材',
    use: {
      text: '熱騰騰的溫泉蛋，似乎是大澤的木匠弟弟很愛的食物啊？'
    }
  },
  'lightbulb': {
    name: '燈串',
    intro: '第四屆大會師所使用的戶外燈串，營造氣氛的絕佳幫手。',
    category: '素材',
    use: {
      text: '看起來是辦活動後忘記拆下來了，拿去給<mark>木匠弟弟</mark>看看，也許是不錯的材料。'
    }
  },
  'amber': {
    name: '琥珀',
    intro: '這是松科松屬植物的樹脂化石，據說能包住原始的昆蟲。',
    category: '素材',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'clay': {
    name: '黏土',
    intro: '孩子喜歡玩的黏土。',
    category: '素材',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'fishing-line': {
    name: '釣魚線',
    intro: '是釣魚線耶，可以拿來<mark>釣魚</mark>嗎？',
    category: '素材',
    use: {
      text: '去找找看<mark>山屋旁的木匠</mark>，應該可以拿便釣魚線來製作一些工具！'
    }
  },
  'ledToy': {
    name: '發光飛箭',
    intro: '用彈弓往夜空發射的玩具，第二屆大會師閉幕儀式的亮點，為此當初特地從夜市大量掃貨。',
    category: '素材',
    use: {
      text: '往上丟後一邊降落一邊發出絢麗的藍光，默默許下了讓世界變得更鼓勵行動的心願⋯⋯'
    }
  },
  'facialMask': {
    name: '口罩',
    intro: '清楚標示著 Made in Taiwan 字樣的國產防疫口罩。沾濕了已不能使用，不過本來就不能亂撿。',
    category: '垃圾',
    use: {
      text: '好希望趕快度過新冠肺炎之災，否則往後的活動照片都看不見大家完整的面孔了嗚⋯⋯'
    }
  },
  'plasticBag': {
    name: '塑膠袋',
    intro: '當初發明塑膠袋的人，是為了讓袋子能重複利用，因而能減少垃圾，可如今塑膠袋卻成為最惡名昭彰的垃圾⋯⋯',
    category: '垃圾',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'soap': {
    name: '肥皂',
    intro: '肯定是有人泡溫泉不小心遺留在溫泉裡的吧？釣到這個還真是沒用。',
    category: '垃圾',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'rethinkTowel': {
    name: '迴響毛巾',
    intro: '第一屆迴響大會師贈送的紀念品，讓青年們拭去行動中流下的汗水和感動的淚水。',
    category: '垃圾',
    use: {
      text: '在這個世界裡只能當作垃圾，隱約感覺到上面散發了設計師的怨念⋯⋯'
    }
  },
  'hat': {
    name: '帽子',
    intro: '芒草原中撿到，不曉得是哪個網美在那邊拍照被風吹掉的。',
    category: '垃圾',
    use: {
      text: '翻開標籤一看，果然不是<a href="https://www.google.com/search?client=safari&rls=en&q=%E8%97%BA%E5%AD%90&ie=UTF-8&oe=UTF-8" target="_blank">藺子</a>做的帽子。風吹日曬只會讓藺草帽更強韌才是。'
    }
  },
  'skyLantern': {
    name: '天燈',
    intro: '乘載著很多觀光客的願望，但最後都落腳在山裡成了林務員的惡夢',
    category: '素材',
    use: {
      text: '話說行動夥伴璦婷與文化銀行正推行一款<a href="http://bankofculture.com/7393-2" target="_blank">永續天燈</a>，讓垃圾不落山林！'
    }
  },
  'balloon': {
    name: '氣球',
    intro: '即使會飛走還是令人非常想要的氣球，是為了第一屆行動節特別印製的限定款。',
    category: '垃圾',
    use: {
      text: '氣球上寫著 HMW___? 底線部分可以寫下你在意的議題。小小人一定很愛。'
    }
  },
  'tinBox': {
    name: '便當鐵盒',
    intro: '迴響也想為環保盡一份力，大小活動都會訂購鐵盒便當。但每次好像都會弄丟幾個。',
    category: '垃圾',
    use: {
      text: '打開蓋子的聲音很令人懷念，快拿回去還給<a href="https://www.google.com/maps/d/u/0/viewer?mid=1FWig3BKuaGWws0px1wnTsfhJVprnZMXA&ll=25.041536922058285%2C121.54103902476733&z=14" target="_blank">店家</a>吧！'
    }
  },
  'usedPostIt': {
    name: '用過的 Post-it',
    intro: '開會時非常實用的工具，可以讓提出的眾多點子被平等地檢視，也能輕鬆為相近的概念分組。',
    category: '垃圾',
    use: {
      text: '背後的殘膠其實很難被清理，只能當作一般垃圾燒掉，創意也是有代價的呢⋯⋯'
    }
  },
  'paperAirplane': {
    name: '紙飛機',
    intro: '第一屆大會師時，行動者們各自將「對自己的鼓勵」寫在上面，當自己氣餒時去抽一隻。',
    category: '垃圾',
    use: {
      text: '打開來看，上面寫著「壓力能讓你變瘦」⋯⋯其他人寫的自己去<a href="https://issuu.com/jarahchou/docs/_____191007____27.3m___/208" target="_blank">這裏</a>看吧。'
    }
  },
  'fishBone': {
    name: '魚骨頭',
    intro: '唯獨工程師的愛貓跟主人玩的機會變少，特此獻上一隻魚補償。',
    category: '水生',
    use: {
      text: '話說，是誰在水底下吃魚？難道是澤中女神嗎⋯⋯'
    }
  },
  'frog': {
    name: '青蛙',
    intro: '青蛙，每個叫聲都有押韻，看來是很強的饒舌歌手。',
    category: '水生',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'hotSpringFish': {
    name: '溫泉魚',
    intro: '自從不用再吃腳皮之後，牠們看起來非常快樂。子非我，安知我不知魚之樂？',
    category: '水生',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'brookShrimp': {
    name: '溪蝦',
    intro: '小巧的底棲性魚類，有許多親戚，多樣性非常之大的魚類',
    category: '水生',
    use: {
      text: '釣蝦是因為台灣當初引進泰國蝦而興盛的獨有文化呢。'
    }
  },
  'bitterFish': {
    name: '苦花',
    intro: '台灣高山溪流常見的魚，內臟的部分微苦，但對泰雅族人來說可是「真正的魚」',
    category: '水生',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'shrimp': {
    name: '蝦虎',
    intro: '釣蝦是因為台灣當初引進泰國蝦而興盛的獨有文化呢。',
    category: '水生',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'sakuraSalmon': {
    name: '櫻花鉤吻鮭',
    intro: '救命啊這是臺灣的保育類動物⋯⋯不小心就釣起來了，趕快放生回去吧，以免觸法！',
    category: '水生',
    use: {
      text: '小小人表示牠相當奮力地說著「挖國寶內！」。還是趕快放生吧。咦，沒有放生功能？'
    }
  },
  'perchEel': {
    name: '鱸鰻',
    intro: '很大尾的一條鱸鰻啊！感覺有很多地盤和手下，不是好惹的貨色。',
    category: '水生',
    use: {
      text: '其實幫助了大澤的生態平衡，是個非常老實的傢伙，果然不能用外表去評斷一個生命呢。'
    }
  },
  'carp': {
    name: '鯉魚',
    intro: '鯉魚欸，這隻該不會是鯉魚當中的⋯⋯王？',
    category: '水生',
    use: {
      text: '目前看起來只會彈跳，但假以時日也能進化成一條煞氣的龍吧？'
    }
  },
  'mountainCarp': {
    name: '山鰱',
    intro: '與苦花一樣是台灣高山溪流的當家之一，因為側邊的花紋而又被叫一枝花。',
    category: '水生',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'fortuneSnail': {
    name: '福壽螺',
    intro: '當初為了食用而引進台灣的外來種，沒想到卻為害了台灣本土的生物。',
    category: '水生',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'brazilianTortoise': {
    name: '巴西龜',
    intro: '是生態的殺手，經常幫當作寵物飼養。',
    category: '水生',
    use: {
      text: '雖然作為寵物很可愛，但也常因為棄養而造成當地生態的問題。是誰把牠拋棄的？'
    }
  },
  'maGao': {
    name: '馬告',
    intro: '名稱是泰雅語的 Maqaw，意指生生不息。平地人稱山胡椒，相形見絀。',
    category: '植物',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'seedStrobile': {
    name: '毬果',
    intro: '毬果是裸子植物數量最多的果實型態之一。',
    category: '植物',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'youngFernLeaf': {
    name: '幼蕨葉',
    intro: '捲曲成問號的蘇鐵幼葉，看起來真的非常有迴圈森林的特色啊。',
    category: '植物',
    use: {
      text: '等等，仔細一瞧小地圖，莫非整個迴響大本營的路線就是一個「迴」字 ！？'
    }
  },
  'birdsNestFern': {
    name: '鳥巢蕨',
    intro: '攀附在巨木上的蕨類植物，另一個俗稱是山蘇，在山地是常見的食材。',
    category: '植物',
    use: {
      text: '有點餓了，想炒來吃。'
    }
  },
  'willowBranches': {
    name: '柳枝',
    intro: '沒有錯啦，就是觀世音菩薩手上那根。',
    category: '植物',
    use: {
      text: '揮一揮楊柳枝，灑下的露水讓人神清氣爽，原來楊枝甘露這名稱就是這麼來的。'
    }
  },
  'cotton': {
    name: '棉絮',
    intro: '做衣服能使用的棉絮。',
    category: '植物',
    use: {
      text: '木棉花開的時候，棉花會掉到地上喔。'
    }
  },
  'samara': {
    name: '翅果',
    intro: '特化出雙翼，可以飛行的果實，讓人聯想到竹蜻蜓。',
    category: '植物',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'plumBossom': {
    name: '梅花',
    intro: '梅花梅花幾月開？一月開不開？',
    category: '植物',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'berries': {
    name: '莓果',
    intro: '身為野孩子的證明，就是能夠一眼看出草叢裡能吃的小果實，並隨手摘下來當零嘴享用。',
    category: '植物',
    use: {
      text: '好酸⋯⋯！'
    }
  },
  'lotusLeaf': {
    name: '荷葉',
    intro: '像一支傘一樣，漂亮的荷葉，果然是大湖的標配。',
    category: '植物',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'aquatic': {
    name: '水草',
    intro: '常見的水草，生長在水中的植物。要種家裡的魚缸嗎？',
    category: '植物',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'euphorbiaLanceolata': {
    name: '長戟大兜蟲',
    intro: '孩子心目中夢幻般的甲蟲之王，飼養起來也是特別困難的，不愧是王者。',
    category: '昆蟲',
    use: {
      text: '據說迴響第一屆某個很喜歡甲蟲的成員家裡逃走了一隻，說不定就是牠⋯⋯'
    }
  },
  'millipede': {
    name: '馬陸',
    intro: '很適合代表迴響的蟲蟲，這麼多腳一定很有行動力。然而並不是昆蟲而是倍足綱。',
    category: '昆蟲',
    use: {
      text: '戳一下後又捲了起來，別跟我說你沒有這麼玩過。然而並不是昆蟲而是倍足綱。'
    }
  },
  'ant': {
    name: '螞蟻',
    intro: '勤勞的螞蟻，小心別被他爬到身上了。',
    category: '昆蟲',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'dragonfly': {
    name: '蜻蜓',
    intro: '仔細一看，蜻蜓長得還挺可怕的啊！',
    category: '昆蟲',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'chafer': {
    name: '金龜子',
    intro: '每年迴響開始籌備時都是金龜子的交配季節，當牠們完成繁衍的工作時，就換迴響開跑了。',
    category: '昆蟲',
    use: {
      text: '鮮豔的甲殼非常討喜，但是偷偷放在同事桌上應該會造成困擾吧，傳達感謝也要適可而止。'
    }
  },
  'cicadaShell': {
    name: '蟬殼',
    intro: '金蟬脫殼之後遺留下來的外殼。',
    category: '昆蟲',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'ladybug': {
    name: '瓢蟲',
    intro: '看起來很可愛但其實是肉食性，可以作為防治農業害蟲的重要角色。',
    category: '昆蟲',
    use: {
      text: '可惜的是，這在這款遊戲裡好像沒什麼用。'
    }
  },
  'firefly': {
    name: '螢火蟲',
    intro: '在黑夜中非常美麗，很多人喜歡夜遊賞螢火蟲。',
    category: '昆蟲',
    use: {
      text: '打開燈來看，長得挺像蟑螂的⋯⋯'
    }
  },
}

export var drops = {
  cedar: [ // 樟樹
    { empty: 3 },
    { elf: 4 },
    { branch: 1 },
    { lightbulb: 1 },
    { euphorbiaLanceolata: 1 }, // 長戟大兜蟲
  ],
  sapling: [
    { empty: 3 },
    { elf: 2 },
    { maGao: 2 },
    { branch: 1 },
    { millipede: 1 },
    { usedPostIt: 1 }
  ],
  sapling2: [
    { empty: 3 },
    { elf: 2 },
    { branch: 2 },
    { seedStrobile: 2 },
    { paperAirplane: 1 }
  ],
  cypress: [
    { empty: 3 },
    { elf: 2 },
    { branch: 2 },
    { seedStrobile: 2 },
    { paperAirplane: 1 }
  ],
  pine: [
    { empty: 3 },
    { elf: 2 },
    { branch: 2 },
    { seedStrobile: 2 },
    { paperAirplane: 1 }
  ],
  cycad: [
    { empty: 3 },
    { elf: 2 },
    { youngFernLeaf: 2 },
    { nail: 2 },
    { 'tinBox': 1},
  ],
  sequoia: [
    { empty: 3 },
    { elf: 2 },
    { skyLantern: 1 },
    { birdsNestFern: 2 },
    { amber: 1 }
  ],
  willow: [
    { empty: 3 },
    { elf: 2 },
    { orange: 1 },
    { willowBranches: 2 },
    { dragonfly: 1 },
    { balloon: 1}
  ],
  oak: [
    { empty: 3 },
    { elf: 2 },
    { branch: 1 },
    { apple: 2 },
    { ant: 1 },
    { skyLantern: 1 }
  ],
  maple: [
    { empty: 3 },
    { elf: 2 },
    { branch: 1 },
    { samara: 2 },
    { chafer: 1 },
    { ledToy: 1 }
  ],
  juniper: [
    { empty: 3 },
    { elf: 2 },
    { branch: 1 },
    { cotton: 3 },
    { chafer: 1},
  ],
  poplar: [
    { empty: 3 },
    { elf: 2 },
    { branch: 1 },
    { cotton: 3 },
    { chafer: 1},
  ],
  poplarWither: [
    { empty: 3 },
    { elf: 2 },
    { branch: 3 },
    { skyLantern: 2 }
  ],
  sequoiaWither: [
    { empty: 3 },
    { elf: 2 },
    { branch: 3 },
    //
    { skyLantern: 2 }
  ],
  fern: [
    { empty: 4 },
    { berries: 3 },
    { cicadaShell: 1 },
    { elf: 2 }
  ],
  awn: [
    { empty: 4 },
    { hat: 1 },
    { ladybug: 1 },
    { elf: 4 }
  ],
  lake: [ // 大澤
    { bottle: 2 },
    { ax: 1 },
    { lotusLeaf: 1},
    { fishBone: 1},
    { perchEel: 1},
    { carp: 1},
    { fortuneSnail: 1},
    { brazilianTortoise: 1}
  ],
  river: [
    { plasticBag: 2 },
    { aquatic: 3 },
    { branch: 1 },
    { brookShrimp: 1 },
    { shrimp: 1 },
    { sakuraSalmon: 1}
  ],
  spring: [
    { 'hot-spring-egg': 3 },
    { soap: 1 },
    { rethinkTowel: 1 },
    { facialMask: 3 },
    { hotSpringFish: 2 }
  ],
  forest: [
    { plasticBag: 2 },
    { aquatic: 3 },
    { branch: 1 },
    { brookShrimp: 1 },
    { shrimp: 1 },
    { sakuraSalmon: 1}
  ],
  swamp: [
    { plasticBag: 2 },
    { aquatic: 3 },
    { branch: 1 },
    { brookShrimp: 1 },
    { shrimp: 1 },
    { sakuraSalmon: 1}
  ],
  // hole: [ // 狗狗挖洞
  //   { apple: 4 }
  // ]
}
