<template>
  <div v-if="backpack.isOpen" class="backpack">
    <div @click="click_to_close()" class="green_mask"></div>

    <div class="popup">
      <div class="decoration_tag"></div>
      <div class="tabs">
        <div @click="setBackpackTabIndex(0)" :class="{tab: true, active: backpack.tabIndex == 0}">物品</div>
        <div @click="setBackpackTabIndex(1)" :class="{tab: true, active: backpack.tabIndex == 1}">任務</div>
        <!-- <div @click="setBackpackTabIndex(2)" :class="{tab: true, active: backpack.tabIndex == 2}">設定</div> -->
      </div>
      <div class="tab_intro">
        <img src="../images/world/keyboard/B_key.svg" draggable="false"/>
        切換<template v-if="!check_mobile()">背包空間</template>
      </div>
      <div v-show="backpack.tabIndex == 0" class="each_page">
        <div class="inventory_area">
          <template v-for="index in player.state.inventoryMax">
            <div :class="{cell: true,
                          props: (getItemCategory(index) === '道具'),
                          ingredient: (getItemCategory(index) === '素材'),
                          garbage: (getItemCategory(index) === '垃圾'),
                          treasure: (getItemCategory(index) === '寶物'),
                          stone: (getItemCategory(index) === '石頭'),
                          flower: (getItemCategory(index) === '花'),
                          bug: (getItemCategory(index) === '昆蟲'),
                          water: (getItemCategory(index) === '水生'),
                          plant: (getItemCategory(index) === '植物'),
                          active: backpack.inventoryIndex === index - 1}"
                          @click="setBackpackIndex({
                                    'category': 'inventory',
                                    'number': index-1})">
              <template v-if="backpack.sortedItems[index-1]">
                <img :src="require('../images/world/drops/'+backpack.sortedItems[index-1].name+'.svg')" draggable="false"/>
                <div class="item_number" v-if="backpack.sortedItems[index-1].number > 1 && !itemIsOnlyOne(index)">
                  x{{backpack.sortedItems[index-1].number}}
                </div>
              </template>
            </div>
            <br v-if="index % 5 === 0"/>
          </template>
        </div>
        <div class="intro">
          <template v-if="chosenItem">
            <div class="name">{{chosenItemName}}</div>
            <div class="category">分類：{{chosenItem.category}}</div>
            <template v-if="!itemIsUsing && !showUsingMessage">
              <div v-html="chosenItem.intro"></div>
              <div class="next_icon frame-anim-2">
                <img draggable="false" src="../images/home/next_1.svg" alt="">
                <img draggable="false" src="../images/home/next_2.svg" alt="">
              </div>
            </template>
            <div v-else-if="itemIsUsing && !showUsingMessage" class="usage">
              是否查看{{chosenItemName}}？
              <div class="item_answers">
                <div :class="{'item_answer': true, 'active': itemUseIndex == 1}">
                  使用
                </div>
                <div :class="{'item_answer': true, 'active': itemUseIndex == 0}">
                  不要
                </div>
              </div>
            </div>
            <div v-else-if="showUsingMessage" class="using_message">
              <div v-html="usingMessage"></div>
            </div>
          </template>
        </div>
      </div>
      <div v-show="backpack.tabIndex == 1" class="each_page">
        <div class="inventory_area">
          <template v-for="index in player.state.missionMax">
            <div :class="{cell: true,
                          mission_cell: true,
                          active: backpack.missionIndex === index-1}"
                          @click="setBackpackIndex({
                                    'category': 'mission',
                                    'number': index-1})">
              <img :src="missionImg(backpack.allMissions[index-1])" draggable="false"/>
              <img v-if="isComplete(backpack.allMissions[index-1])" class="complete" src="../images/world/missions/complete.svg"/>
            </div>
            <br v-if="index % 5 === 0"/>
          </template>
          <!-- <template v-for="index in backpack.missionMax">
            <div :class="{cell: true,
                          mission_cell: true,
                          active: backpack.missionIndex === index - 1}"
                          @click="setBackpackIndex({
                                    'category': 'mission',
                                    'number': index-1})">
              <template v-if="backpack.sortedMission[index-1]">
                <img :src="missionImg(index)" draggable="false"/>
                <img v-if="backpack.sortedMission[index-1].status == 'missionComplete'" src="../images/world/missions/complete.svg"/>
              </template>
            </div>
            <br v-if="index % 5 === 0"/>
          </template> -->
        </div>
        <div :class="{intro: true, completeIntro: isComplete(chosenMission.npc)}">
          <template v-if="chosenMission">
            <div class="name">{{chosenMission.missionTitle}}</div>
            <div class="category">NPC：{{getNPCName(chosenMission.npc)}}</div>
            <!-- note that this 'ongoing' is special rule, not player.state -->
            <div v-if="isComplete(chosenMission.npc)" v-html="chosenMission.finishIntro"></div>
            <div v-else-if="isOngoing(chosenMission.npc)" v-html="translateMessage(chosenMission.intro)"></div>
            <div v-else v-html="chosenMission.notTakenIntro"></div>
          </template>
        </div>
      </div>
      <!-- <div v-show="backpack.tabIndex == 2" class="each_page">
        <div class="inventory_area">
          <div>
            <label for="fullname">名字</label>
            <input name="fullname" type="text" v-model="player.name" />
          </div>
          <div>
            <label for="sound">音樂/音效</label>
            <input name="sound" type="checkbox" v-model="backpack.isSoundBGMOpened" v-on:click="toggleSound()" />
          </div>
        </div>
      </div> -->
    </div>

    <img class="keyboard_hint" src="../images/world/keyboard_hint.svg"/>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
// import { items } from 'js/map/items.js'
import { check_mobile } from '../js/map/global.js'

export default {
  name: 'Backpack',
  data: function () {
    return {
      itemIsUsing: false,
      itemUseIndex: 0, // only 0 and 1
      showUsingMessage: false,
      usingMessageTimeId: 0
    }
  },
  computed: {
    ...mapState([
      'player', 'backpack', 'modal', 'items', 'missions', 'dialogs'
    ]),
    usingMessage: function() {
      const playerItem = this.backpack.sortedItems[this.backpack.inventoryIndex]
      if (this.items[playerItem.name].use) return this.items[playerItem.name].use.text
      else return false
    },
    chosenItem: function() {
      const playerItem = this.backpack.sortedItems[this.backpack.inventoryIndex]
      return (playerItem)? this.items[playerItem.name] : false
    },
    chosenItemName: function() {
      return this.itemName(this.backpack.inventoryIndex)
    },
    chosenMission: function() {
      const playerMission = this.backpack.allMissions[this.backpack.missionIndex]
      return (playerMission)? this.missions[playerMission] : false
      // const playerMission = this.backpack.sortedMission[this.backpack.missionIndex]
      // return (playerMission)? this.missions[playerMission.name] : false
    },
    // chosenMissionName: function() {
    //   return this.missionName(this.backpack.missionIndex)
    // },
  },
  created: function() {
  },
  mounted: function() {
    this.initial_vuex_function()
  },
  methods: {
    ...mapActions(['toggleSound', 'inventorySwitch', 'reorder_items',
    'reorder_missions', 'openModal', 'transferAndDialog']),
    ...mapMutations([
      'setBackpackTabIndex', 'setBackpackIndex', 'initialBackpackFunction',
      'removeDelNPC'
    ]),
    check_mobile,
    translateMessage(message) {
      message = message.replace('stone', this.stoneNumber())
      message = message.replace('stele', this.steleNumber())
      message = message.replace('branch', this.branchNumber())
      message = message.replace('bottle', this.bottleNumber())
      // message = message.replace('clay', this.clayNumber())
      // message = message.replace('ledtoy', this.ledtoyNumber())
      message = message.replace('hotspringegg', this.hotspringeggNumber())
      // message = message.replace('aquatic', this.aquaticNumber())
      message = message.replace('lightbulb', this.lightbulbNumber())
      message = message.replace('apple', this.appleNumber())
      message = message.replace('seed', this.seedNumber())
      message = message.replace('titan', this.titanNumber())
      message = message.replace('flower', this.flowerNumber())
      return message
    },
    itemIsOnlyOne(index) {
      if (this.backpack.sortedItems[index-1])
        return this.items[this.backpack.sortedItems[index-1].name].onlyOne
      else
        return false
    },
    getItemCategory(index) {
      if (this.backpack.sortedItems[index-1])
        return this.items[this.backpack.sortedItems[index-1].name].category
      else
        return false
    },
    click_to_close() {
      this.inventorySwitch(false)
      console.log('hey')
    },
    initial_vuex_function() {
      let functions = {
        'pressArrowKey': this.press_arrow_key,
        'pressA': this.press_A,
        'pressB': this.press_B,
        'pressEsc': this.press_esc
      }
      this.initialBackpackFunction(functions)
    },
    itemName(index) {
      const playerItem = this.backpack.sortedItems[index]
      if (playerItem) {
        const itemCodename = playerItem.name
        const itemDetail = this.items[itemCodename]
        if (itemDetail) {
          const itemChinese = itemDetail.name
          return (itemChinese)? itemChinese : itemCodename
        }
        else return 'wrong: no item data'
      }
      else return 'wrong: backpack error'
    },
    missionImg(name) {
      try {
        if (this.isOngoing(name) || this.isComplete(name)) return require('../images/world/missions/'+name+'.svg')
        else return require('../images/world/missions/notTaken/'+name+'.svg')
        // return require('../images/world/missions/'+this.backpack.sortedMission[index-1].name+'.svg')
      }
      catch(error) {
        console.log('../images/world/missions/'+name+'.svg')
        // console.log('../images/world/missions/'+this.backpack.sortedMission[index-1].name+'.svg')
      }
    },
    isOngoing(missionName) {
      if (missionName == 'PartnerFlavor') {
        if (this.stoneNumber() === 0) return false
      }
      if (missionName == 'Broomman') {
        if (this.steleNumber() === 0) return false
      }
      if (missionName == 'Carpentar') {
        if (!this.branchNumber() && !this.bottleNumber() && !this.clayNumber() && !this.ledtoyNumber() && !this.seedNumber()) return false
      }
      if (missionName == 'Archaeologist') {
        if (!this.titanNumber()) return false
      }
      if (missionName == 'LakeGoddess') {
        if (!this.flowerNumber()) return false
      }
      if (missionName == 'ActionGod') {
        return false
      }
      return true
    },
    stoneNumber() {
      let count = 0
      for (let item of this.backpack.sortedItems) {
        if (this.items[item.name].category == '石頭') count++
      }
      return count
    },
    steleNumber() {
      let count = 0
      for (let mission of this.player.state.missionComplete) {
        if (mission.search(/stone/i) != -1) count++
      }
      return count
    },
    lightbulbNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'lightbulb') count = item.number
      }
      return count
    },
    appleNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'apple') count = item.number
      }
      return count
    },
    aquaticNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'aquatic') count = item.number
      }
      return count
    },
    hotspringeggNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'hot-spring-egg') count = item.number
      }
      return count
    },
    branchNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'branch') count = item.number
      }
      return count
    },
    bottleNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'bottle') count = item.number
      }
      return count
    },
    clayNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'clay') count = item.number
      }
      return count
    },
    ledtoyNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'led-toy') count = item.number
      }
      return count
    },
    seedNumber() {
      let count = 0
      for (let item of this.player.state.items) {
        if (item.name == 'seed') count = item.number
      }
      return count
    },
    titanNumber() {
      let count = 0
      for (let missionName of this.player.state.missionComplete) {
        // console.log(missionName.search(/TurnOnLieHouse/i))
        if (missionName.search(/TurnOnLieHouse/i) != -1) count++
        // if (event.search(/LieHouseRoof/i) != -1) count--
        // console.log(event)
      }
      return count
    },
    flowerNumber() {
      let count = 0
      for (let item of this.backpack.sortedItems) {
        if (this.items[item.name].category == '花') count++
      }
      return count
    },
    // missionName(index) {
    //   const codeName = this.backpack.sortedMission[index].name
    //   const missionChineseTitle = this.missions[codeName].missionTitle
    //   return (missionChineseTitle)? missionChineseTitle : codeName
    // },
    isComplete(missionName) {
      if (missionName == 'FerryMan') {
        for (let event of this.player.state.events) {
          if (event == 'LakeGoddess') return true
        }
      }
      for (let missionCompleteName of this.player.state.missionComplete) {
        if (missionCompleteName == missionName) return true
      }
      return false
    },
    getNPCName(codeName) {
      // if (codeName == 'LakeGoddess') return '擺渡人'
      // if (codeName == 'Altar') return '祭台'
      if (this.dialogs[codeName]) return this.dialogs[codeName].name
      else return 'N/A'
    },
    press_arrow_key(e) {
      this.showUsingMessage = false
      clearTimeout(this.usingMessageTimeId)
      if (this.itemIsUsing) {
        if (this.itemUseIndex == 0 ) this.itemUseIndex++
        else if (this.itemUseIndex == 1 ) this.itemUseIndex--
      }
      else {
        if (this.backpack.tabIndex == 0) this.select_backpack_grid(e, 'inventory')
        if (this.backpack.tabIndex == 1) this.select_backpack_grid(e, 'mission')
      }
    },
    select_backpack_grid(e, category) {
      if (e.code === "ArrowLeft" && this.backpack[category + 'Index'] > 0) {
        this.setBackpackIndex({
          'category': category,
          'number': this.backpack[category + 'Index']-1
        })
      }
      else if (e.code === "ArrowRight" && this.backpack[category + 'Index']+1 < this.player.state[category + 'Max']) {
        this.setBackpackIndex({
          'category': category,
          'number': this.backpack[category + 'Index']+1
        })
      }
      else if (e.code === "ArrowUp" && (category == 'mission' && this.backpack[category + 'Index'] == this.player.state[category + 'Max'] - 1))  {
        // special one
        this.setBackpackIndex({
          'category': category,
          'number': this.backpack[category + 'Index']-3
        })
        this.adjust_inventory_scroll()
      }
      else if (e.code === "ArrowUp" && this.backpack[category + 'Index']-5 >= 0)  {
        this.setBackpackIndex({
          'category': category,
          'number': this.backpack[category + 'Index']-5
        })
        this.adjust_inventory_scroll()
      }
      else if (e.code === "ArrowDown" && this.backpack[category + 'Index']+5 < this.player.state[category + 'Max']) {
        this.setBackpackIndex({
          'category': category,
          'number': this.backpack[category + 'Index']+5
        })
        this.adjust_inventory_scroll()
      }
      else if (e.code === "ArrowDown" && category == 'mission') {
        this.setBackpackIndex({
          'category': category,
          'number': this.player.state[category + 'Max']-1
        })
        this.adjust_inventory_scroll()
      }
    },
    adjust_inventory_scroll() {
      let inventoryWindow = document.getElementsByClassName('inventory_area')[0]
      let chosenCell = document.getElementsByClassName('cell')[this.backpack.inventoryIndex]
      if (inventoryWindow && chosenCell) {
        let windowHeight = inventoryWindow.offsetHeight
        let gridHeight = chosenCell.offsetHeight
        if (inventoryWindow.scrollTop > chosenCell.offsetTop) {
          // cell hide beyond
          inventoryWindow.scrollTop = chosenCell.offsetTop - 14
          // if (this.backpack.inventoryIndex < 5) inventoryWindow.scrollTop -= 7
        }
        else if (inventoryWindow.scrollTop + windowHeight < chosenCell.offsetTop + gridHeight) {
          // cell hide in bottom
          inventoryWindow.scrollTop = chosenCell.offsetTop - windowHeight + gridHeight + 14
        }
      }
    },
    press_A(e) {
      if (this.backpack.tabIndex == 0) {
        const chosenItem = this.backpack.sortedItems[this.backpack.inventoryIndex]
        const usage = this.items[chosenItem.name].use
        if (this.showUsingMessage) {
          this.showUsingMessage = false
          // clearTimeout(this.usingMessageTimeId)
        }
        else if (usage) {
          const commands = Object.keys(usage)
          for (let command of commands) {
            // console.log(command)
            if (!this.itemIsUsing) {
              this.itemUseIndex = 1
              this.itemIsUsing = true
              break
            }
            else {
              if (this.itemUseIndex == 0) {
                this.itemIsUsing = false
                break
              }
              if (command == 'text' && !this.items[chosenItem.name].use.transferAndDialog) {
                this.showUsingMessage = true
                // this.usingMessageTimeId = setTimeout(() => {
                //   this.showUsingMessage = false
                // }, 4000)
                this.itemIsUsing = false
              }
              else if (command == 'modal') {
                this.inventorySwitch(false)
                this.openModal(usage.modal)
                this.itemIsUsing = false
              }
              else if (command == 'transferAndDialog') {
                if (this.player.state.hasBoat) {
                  this.showUsingMessage = true
                  this.itemIsUsing = false
                  break
                } else {
                  this.transferAndDialog(usage.transferAndDialog)
                  this.press_esc()
                  this.itemIsUsing = false
                }
              }
              else if (command == 'god') {
                if (!this.player.state.hasBoat) {
                  this.removeDelNPC('ActionGod')
                  this.removeDelNPC('ActionGodFloor')
                  // this.itemIsUsing = false
                }
              }
            }
          }
          // console.log(this.showUsingMessage)
          // console.log(this.itemIsUsing)
        }
      }
    },
    press_B(e) {
      if (!this.backpack.isOpen) {
        this.itemIsUsing = false
        this.reorder_items()
        this.reorder_missions()
        this.setBackpackIndex({
          'category': 'inventory',
          'number': 0
        })
        this.setBackpackIndex({
          'category': 'mission',
          'number': 0
        })
        this.inventorySwitch(true)
        this.setBackpackTabIndex(0)
      }
      else {
        let index = this.backpack.tabIndex + 1
        if (index > 1) this.press_esc()
        this.setBackpackTabIndex(index)
      }
    },
    press_esc(e) {
      this.itemIsUsing = false
      this.inventorySwitch(false)
    },
    // reorder_items() {
    //   this.backpack.sortedItems = []
    //   const orders = ['寶物', '道具', '素材', '垃圾']
    //
    //   for (let order of orders) {
    //     for (let item of this.player.state.items) {
    //       if (order == this.items[item.name].category) {
    //         this.backpack.sortedItems.push(item)
    //       }
    //     }
    //   }
    //   console.log(this.backpack.sortedItems)
    // },
    // reorder_missions() {
    //   this.backpack.sortedMission = []
    //   const myComplete = this.player.state.missionComplete
    //   const myOngoing = this.player.state.missionOngoing
    //   for (let missionName of myOngoing) {
    //     if (!this.missions[missionName].noShow) this.backpack.sortedMission.push(missionName)
    //   }
    //   for (let missionName of myComplete) {
    //     if (!this.missions[missionName].noShow) this.backpack.sortedMission.push(missionName)
    //   }
    //   this.backpack.sortedMission.reverse()
    //   // console.log(this.backpack.sortedMission)
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

.backpack {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 10000px);
  width: 100vw;
  height: 100vh;
  top: 0;
  // pointer-events: none;

  .popup {
    position: relative;
    background: $new_world_yellow;
    filter: drop-shadow(0px 0.781132px 6.24906px rgba(60, 145, 149, 0.2));
    border-radius: 50px;
    width: 540px;
    // height: 600px;
    // height: calc(90vh - 200px);
    // overflow-x: hidden;
    // overflow-y: scroll;
    margin-top: -7vh;
    pointer-events: all;


    .decoration_tag {
      position: absolute;
      width: 64px;
      height: 16px;
      left: 50%;
      margin-left: -32px; // 64/2
      margin-top: -6px;
      background: $new_world_green;
    }

    .tabs {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: left;
      margin-top: 20px;
      width: 58%;
      margin-left: 8%;
      // height: 60px;

      .tab {
        display: inline-block;
        padding: 24px 20px;
        font-size: 18px;
        // font-family: 'Noto Sans CJK TC';
        font-style: normal;
        font-weight: bold;
        color: $new_world_orange;
        letter-spacing: 0.34em;
        cursor: pointer;

        &:hover {
          color: $new_world_dark_blue;
        }
      }
      .active {
        color: $new_world_dark_blue;
      }
    }

    .tab_intro {
      position: absolute;
      right: 47px;
      top: 40px;
      font-size: 12px;
      color: $new_world_orange;

      img {
        width: 20px;
        vertical-align: -50%;
      }
    }

    .each_page {

      .inventory_area {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        background: #FFB33C;
        padding: 32px 40px;
        // height: 289px;
        height: calc(52vh - 200px);
        overflow-y: scroll;

        .cell {
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 78px;
          max-width: 78px;
          min-width: 78px;
          height: 78px;
          background: $new_world_orange;
          border-radius: 15px;
          // margin: 7px;
          margin: 2px;
          border: 5px solid #FFB33C;
          vertical-align: top;
          font-size: 14px;
          flex: 0 0 1;
          cursor: pointer;

          img {
            width: 75%;
            margin-top: -3px;
          }

          .item_number {
            position: absolute;
            font-size: 14px;
            color: white;
            bottom: 6px;
            right: 6px;
          }
        }
        .active {
          border-color: $new_world_white;
          // background: $new_world_dark_blue;
          // color: $new_world_orange;
        }
        .props {
          background: #167070;
        }
        .ingredient {
          background: #98AFAF;
        }
        .garbage {
          background: #6A8484;
        }
        .treasure {
          background: #27E1B5;
        }
        .stone {
          background: #FFD143;
        }
        .flower {
          background: #16AC91;
        }
        .bug {
          background: #895400;
        }
        .water {
          background: #93E3D2;
        }
        .plant {
          background: #58B1A1;
        }

        .mission_cell {
          position: relative;
          img {
            position: absolute;
            width: 100%;
            margin: 0;
          }
          .complete {
            width: 125%;
            margin-left: 11%;
            margin-top: -15%;
          }
        }
      }

      .completeIntro {
        opacity: 0.4;
      }

      .intro {
        position: relative;
        color: $new_world_dark_blue;
        padding: 32px 116px 32px 50px;
        letter-spacing: 0.64px;
        line-height: 24px;
        font-size: 15px;
        height: 100px; // dangerous

        ::v-deep a {color: #439D8D !important;}

        .next_icon {
          position: absolute;
          right: 50px;
          bottom: 50px;
          width: 20px;
        }

        .name {
          font-weight: bold;
          margin-bottom: 14px;
          letter-spacing: 2.8px;
          font-size: 18px;
        }

        .category {
          position: absolute;
          top: 32px; // padding
          right: 44px; // padding
          font-weight: bold;
          letter-spacing: 1.4px;
          font-size: 14px;
        }

        .usage {
          margin-top: 10px;
          .item_answers {
            margin-top: 8px;
            .item_answer {
              display: inline-block;
              margin-right: 20px;
            }
            .active {
              color: #FF665c;
              font-weight: bold;
            }
          }
        }
      }
    }

  }

  .green_mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 143, 120, 0.5);
  }

  .keyboard_hint {
    position: absolute;
    display: inline-block;
    max-width: 100%;
    bottom: 30px;
  }
}

@media screen and (max-width: 520px) {
  .backpack {

    .popup {
      margin-top: -14vh;
      width: 85vw;
      height: 94.4vw;
      border-radius: 25px;

      .decoration_tag {
        height: 7px;
      }

      .tabs {
        margin: 0;
        margin-left: 3.5vw;

        .tab {
          padding: 4vw 3vw;
          font-size: 3.4vw;
        }
      }

      .tab_intro {
        right: 7.5vw;
        top: 3.1vw;
      }

      .each_page {

        .inventory_area {
          padding: 3vw;
          height: 49vw;

          .cell {
            width: 12vw;
            max-width: 12vw;
            min-width: 12vw;
            height: 12vw;
            margin: 1.5vw;
            border-radius: 10px;
            font-size: 10px;
          }
        }

        .intro {
          font-size: 11px;
          line-height: 15px;
          letter-spacing: 0.44px;
          padding: 4vw 9vw;

          // ::v-deep a {color: #439D8D !important;}

          .name {
            font-size: 11px;
            line-height: 18.75px;
            letter-spacing: 1.1px;
          }

          .category {
            font-size: 10px;
            line-height: 18.75px;
            letter-spacing: 1px;
            top: 4vw;
            right: 6.2vw;
          }
        }
      }

    }
  }

}

@media screen and (max-height: 780px) {
	.backpack {
    // height: 780px;
    .keyboard_hint {
      display: none;
    }

    .popup {
      // height: 500px;

      .each_page {
        .inventory_area {
          // height: 205px;
        }
      }
    }
  }
}

</style>
