<template>
  <Modal :name="'team_6'" ref="modal" :isWhite="true">
    <br>
    <br>
    <h4>想展開行動，「酵母菌」建議你可以⋯</h4>
    <p>•　透過臺灣大學社會學系主辦的 <a href="https://bit.ly/3wLjWtX" target="_blank">作伙學！</a> 認識學習歷程回顧的重要性！</p>
    <p>•　用 <a href="https://bit.ly/3rhzTHh" target="_blank">Club Adventure 工具包</a> 回顧社團經驗吧！</p>
    <p>　◦　如果你是學生，透過工具包挖掘自己的學習旅程吧！</p>
    <p>　◦　如果你是家長、老師，帶著工具包與你的孩子、學生一起操作看看！</p>
    <p>想嘗試［工具包］但不確定怎麼操作？希望有人能辦一場相關的 Workshop？聯絡 <a href="https://bit.ly/36HtgEt" target="_blank">酵母菌</a>，與我們聊聊吧！</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2019 酵母菌 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <h4>另外，「探索引路人」建議你可以這樣展開行動⋯</h4>
    <p>•　如果是在學大學生，不妨花時間把自己在科系中的經驗整理，提供高中生參考。</p>
    <p>•　如果家有高中生正在煩惱升學，試著帶著他找尋科系資料，或把自己的經驗傳承給他。</p>
    <p>•　如果是高中生，試著自己尋找科系內容，課餘時也能搜尋相關的組織團體介紹科系或進行議題倡議（議題可以跟科系有關），同時也參考師長們的意見。</p>
    <p>•　若想找大學科系學長姐詢問，歡迎上我們的粉專 <a href="https://bit.ly/36EyYal" target="_blank">找自己生涯探索-高中生選系諮詢</a></p>
    <p>•　迴響計畫的行動團隊也有很多的夥伴關心不同的議題，想要認識議題、放進自己的學習歷程，也可以嘗試聯繫與詢問。</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2019 探索引路人（找自己） 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
