<template>
  <Modal :name="'ax_strange'" ref="modal" :isWhite="true">
    <h5><b>團隊動能突然下降了，許多成員的工作開始有延遲或未達成原本理想中狀態..怎麼辦？</b></h5>
    <br />
    <br />
    <p>這個不要問女神，問你的團隊夥伴。</p>
    <p>你有沒有帶著團隊停下腳步，試著反思一下現況哪裡怪怪的？還是你總是覺得就你一個人在推，好像其他地方都使不上力，沒有辦法到達理想的狀態？</p>
    <p>覺得怪怪的，是一種超級明確的溫度計，不管聰明才智如何，每個人都會有這種感覺怪怪的能力，這種怪怪的感覺，如果一直被壓抑，就會讓人提不起勁，不知道現在到底在為什麼努力，越來越不能成為理想中的自己。</p>
    <p>這種「怪怪的」感覺，多半來自「目標」與「手段」的不一致，不管是客觀來講，其實目標是達不到的，或沒有達到的線索以致於大家感到無力，這方面可以歸於對理想狀態的追求出了問題；另一方面，也可以檢查手段，現在的手段是不是大家所能掌握的？或許為了達成不可能的目標，大家已經 Overload 了；最後，還可以再檢查，會不會錯把多重目標放在一個手段中，錯誤的預期目前的專案可以達成多個目標，但其實，這些目標之間是相衝突的？</p>
    <p>推薦你用 30 分鐘的時間，不用有任何架構，帶著團隊做一次「反思會議」單純花 5-7 分鐘，請大家在現場，不互相打擾的狀態下，各自整理自己覺得怪怪的地方，用大部分的時間請大家輪流分享，最後初步整理一下怪怪的面向，不一定要做出結論。</p>
    <p>當達不到理想狀態時，大部分的領導者，都會想要「Push」團隊往前，認為是慢了、不好了，卻沒有想到運用夥伴的觀點「Pull」出幫助我們釐清是不是根本只是目標跟手段分離了。</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
