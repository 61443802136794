<template>
  <Modal :name="'s2_big_tree_hole'" ref="modal">
    <p>初代行動者登頂後，因為看到了十年的願景，於是聚集在迴圈森林討論，該怎麼樣才能幫助更多人採取行動。</p>
    <p>他們想要支持行動的信念太過強烈，竟具現化為不同的小精靈，留在迴圈森林，偷聽歷代登頂者的行動經驗，日復一日，他們整理出不同的工具和方法，希望能為這個世界做點甚麼。</p>
    <p>然而，無論怎麼努力，小精靈也走不出這個森林，他們只能活在鼓勵行動的環境，這讓不少精靈夥伴感到疲憊，有些時刻甚至想要放棄。</p>
    <br>
    <br>
    <br>
    <p>感謝你，點起這把火炬，這是歷代行動者們集會的樹洞，他們曾在這裡天真地討論著行動和世界關係，已經很久沒有被點亮了...</p>
    <p>火光照亮周遭的壁畫，裡面講述著初代行動者的故事和信念，如果可以，請你將這份支持行動的信念帶出這片森林。去鼓勵身邊的人，當他們有真的很想做到，卻看起來很傻的事情；先不要澆他們冷水，當我們的社會已經有夠多的嘲諷和敵意；用行動支持他們，你會驚訝於行動的連鎖反應，看見更多想像不到的美好事情。</p>
    <p>會有奇蹟的吧，當越來越多人，用心照顧這些行動的渴望，你也要隨時注意，或許會在教室的窗沿、辦公桌前，注意到這些支持行動的小精靈。</p>
    <br>
    <br>
    <br>
    <p>
      When the whole world is silent, even one voice becomes powerful.<br>
      Malala Yousafzai
    </p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
