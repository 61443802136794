<template>
  <Modal :name="'planet_choice'" ref="modal" :isWhite="true">
    <h3>選擇 Choice</h3>
    <p class="t-center">「沒有那麼多的『一定要怎樣』，我們永遠能做出選擇。」</p>
    <br />
    <p>選擇是靠近自由的宣告，是責任的起點，是起跑的哨音。</p>
    <p>迴響相信的價值是，在困頓時記得你仍有所選擇，特別是在那些爭執中的第三種選擇。</p>
    <img src="../../images/world/NPC/events/Planet6Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
