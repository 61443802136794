<template>
  <Modal :name="'s0_fire'" :title="'營火'" ref="modal" :isWhite="true">
    <h3>Rethink, As Always.</h3>
    <div class="t-center">
    <p>▴</p>
    <p>
      2017年 某一天<br/>
      有一個人問了另一個人<br/>
      我們如何能<br/>
      鼓勵更多年輕人<br/>
      為自己在意的事採取行動？<br/>
      <br/>
      計畫展開　始於一句提問<br/>
      青年聚集　漸成迴響<br/>
    </p>
    <p>▴</p>
    <p>
      每一年都當最後一屆在辦<br/>
      每一屆盡力從行動者身上學習<br/>
      我們永遠會是一個「還在路上的」團隊<br/>
      <br/>
      認識不同青年內心在意的社會議題<br/>
      用行動方法試出相對應的解法<br/>
      我們試圖透過流程「彰顯每一個提案的價值」<br/>
    </p>
    <p>▴</p>
    <p>
      因為　我們不只是創業競賽<br/>
      更想成為你第一步行動的夥伴<br/>
      因為　我們相信行動是有方法的<br/>
      而你可以從做得到的開始<br/>
      <br/>
      只要讓行動展開<br/>
      就會離理想的世界更近一步<br/>
    </p>
    <p>▴</p>
    <p>
      成為　那第一聲響吧<br/>
      <br/>
      勇敢指認房間裡的大象<br/>
      與社會談論你的觀點<br/>
      行動並進　嘗試解決、優化、創新的可能<br/>
    </p>
    <p>▴</p>
    <p>
      第一步　是所有開始前最重要的一步<br/>
      <br/>
      當青年能如此攪動社會的秩序<br/>
      有沒有可能　也帶動銀髮族重新開始？<br/>
      有沒有機會　也鼓舞少年們舉手發言？<br/>
    </p>
    <p>▴</p>
    <p>
      你有問題　我們有方法<br/>
      你敢提問　我們就一起<br/>
      <br/>
      這是青年迴響計畫<br/>
      最大膽、也最堅定的遙想<br/>
    </p>
    <p>▴</p>
    <p>
      於是　迴響　各式各樣<br/>
      <br/>
      相信行動　於是行動<br/>
      <br/>
      一路上　你會遇見同樣勇敢的人<br/>
      執行團隊、行動團隊、行動夥伴、教練<br/>
      <br/>
      一路上　不是只有風光模樣<br/>
      會夾雜情緒　會跌撞狼狽<br/>
      再靠著方法　從失敗中汲取經驗<br/>
    </p>
    <p>▴</p>
    <p>
      你　我　眾人<br/>
      透過行動　思考　交流<br/>
      成為　你最獨一無二的樣子<br/>
      成為　自由之身<br/>
      <br/>
      青年迴響　如你一般<br/>
      持續長成　持續試錯　持續擴張自己<br/>
      去發現自己和社會相處的百種方式<br/>
    </p>
    <p>▴</p>
    <p>
      我們無法設限　我們沒有絕對<br/>
      再一次　再說一次<br/>
      我們相信　行動　的價值<br/>
      面對理想和現實的落差<br/>
      唯有實際的經驗<br/>
      會告訴我們該如何拉近距離<br/>
    </p>
    <p>▴</p>
    <p>
      給你一個行動的藉口<br/>
      讓行動超展開<br/>
      於是　迴響是百花盛開的模樣：<br/>
      遞減瓶裝水的使用量、流浪犬和家長的關係、二手衣服的去處、女性胸部解放、大自然與人的交流、如何讓人在憂鬱中開口、牙醫教育如何普及、永續珠寶的發展、親子理財的重要性、更生人如何重新生活、月經汙名化等翻轉、學校經驗的傳承、都市居民如何參與公共討論、原住民小孩參與傳統文化、異溫層溝通、陰柔特質的力量… …<br/>  
    </p>
    <p>▴</p>
    <p>
      你問　迴響是什麼？<br/>
      或許　你也可以成為我們其中之一的答案。
    </p>
    </div>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style scoped lang="scss">

</style>
