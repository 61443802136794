<template>
  <Modal :name="'team_7'" ref="modal" :isWhite="true">
    <!-- <p>如果你想瞭解更多關於爸爸育兒的資訊，歡迎採取下列行動喔！</p> -->
    <br>
    <br>
    <h4>Ａ.想認識異溫層溝通這個議題，可以追蹤這兩個粉絲專頁：</h4>
    <p>•　<a href="https://bit.ly/36FuOir" target="_blank">行動山棧花</a></p>
    <p>•　<a href="https://bit.ly/3xQvgGr" target="_blank">對話千層派・全民聊未來</a></p>
    <br>
    <br>
    <h4>Ｂ.想體驗吸收異溫層資訊：</h4>
    <p>•　打開三立電視台和TVBS這兩個頻道（或是任何你知道立場不一樣的頻道），找出他們共同的議題（eg. 對指揮中心進口疫苗的看法）並且比較一下</p>
    <p>•　找到一個和你政治立場不同的親友，問他上次總統大選支持誰、為什麼這樣選擇</p>
    <p>• 到不同政治立場的政治人物社群、粉專，找出共同的議題並且觀察貼文和底下的的留言</p>
    <br>
    <br>
    <h4>Ｃ.遇到了怎麼想？</h4>
    <p>•　需要放下的心態：「你一定是錯的」、「你都只看片面資訊」、「你先聽我說完」、「我要說服你」</p>
    <p>•　深吸一口氣，然後擁抱的心態：「我先聽你說說看」、「我知道的不一定最正確」、「我想知道你怎麼想」</p>
    <p>•　面對不同想法時，可以反思是什麼原因造成雙方想法的差異，並且同理對方。</p>
    <br>
    <br>
    <h4>Ｄ.遇到了怎麼做？</h4>
    <p>•　想與異溫層交流，可先從分享比較不具威脅性的想法開始，再決定後續要不要繼續互動、互動要談什麼、怎麼談</p>
    <p>•　觀察對方的溝通目的是說服還是分享（tips：看C的第一點的句子，符合兩個以上可能是說服）</p>
    <p>•　深呼吸、冷靜</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2019 異溫層溝通 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
