<template>
  <div :class="[{map_modal: true, white_modal: isWhite}, name]" v-if="open" v-show="show" ref="map_modal">
    <div class="dark_bg" @click="closeModal(name)"></div>
    <div class="progress_bar"></div>
    <div class="modal">
      <!-- <div v-if="title" class="modal_title">
        {{title}}
      </div><hr /> -->
      <div class="modal_box">
        <div class="modal_scroll_box" tabindex="0" ref="scrollbox">
          <!-- tabindex make explorer can focus on it, then use arrow key to scroll this box -->
          <div class="modal_content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <img @click="closeModal(name)" class="Q_hint" :src="require('../../images/world/Q_hint'+(isWhite? '_black' : '')+'.svg')"/>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { check_mobile } from '../../js/map/global.js'

export default {
  name: 'Modal',
  data: function () {
    return {
      open: false,
      show: true,
      pressATimeID: 0
    }
  },
  props: ['name', 'title', 'isWhite'],
  mounted: function() {
    window.addEventListener('scroll', e => this.launch_scroll_progress_bar(), true)
  },
  methods: {
    ...mapActions(['closeModal']),
    ...mapMutations([
      'initialModalFunction'
    ]),
    launch_scroll_progress_bar(e) {
      if (open && document.getElementsByClassName('modal_content')[0] && this.$refs.scrollbox) {
        const contentHeight = document.getElementsByClassName('modal_content')[0].offsetHeight
        const now_progress = this.$refs.scrollbox.scrollTop
        const box_padding = window.getComputedStyle(this.$refs.scrollbox, null).getPropertyValue('padding-top').replace('px', '') * 2
        const max_progress = (contentHeight + box_padding) - this.$refs.scrollbox.offsetHeight
        const ratio = now_progress / max_progress
        const progress_bar = document.getElementsByClassName('progress_bar')[0]
        progress_bar.style.width = (ratio * 100) + '%'
      }
    },
    press_down_A(e) {
      if (this.$refs.scrollbox) {
        if (e.repeat) {
          // console.log(this.pressATimeID)
          if (!this.pressATimeID) {
            this.pressATimeID = setInterval(() => {
              this.$refs.scrollbox.scrollTop += 35
              // console.log('hey')
            }, 125)
          }
        }
        else this.$refs.scrollbox.scrollTop += 50
        // console.log(this.$refs.scrollbox.scrollTop)
      }
    },
    press_up_A(e) {
      clearInterval(this.pressATimeID)
      this.pressATimeID = 0
      // console.log('clear')
    },
    press_esc(e) {
      this.closeModal(this.name)
      // const map_dom = document.getElementsByClassName('map')[0]
      // map_dom.style['will-change'] = 'auto'
    }
  }
}
</script>

<style scoped lang="scss">
</style>
