<template>
  <Modal :name="'y2019_stone'" ref="modal" :isWhite="true">
    <h3>2019 年，是取捨的一屆</h3>
    <p>這年，計畫的預算相比過去下降許多，我們在有限的資源下，要很努力的看到真正核心的東西是什麼。當然，得知消息的當下有很多的失望與挫折，但回顧起來，卻是協助我們踩穩的一年。</p>
    <h4>製作任務包線上課程，讓行動知識更好吸收：</h4>
    <p>把任務包的核心知識錄製成線上課程，讓過往都是文字的行動知識教學，可以有多種吸收方式。</p>
    <p>隔年再推了一把，把任務包公開化。</p>
    <h4>沒有獎金的 Pitch Day，反而聚焦回行動者當下的真正狀態與需求：</h4>
    <p>沒有獎金預算的 Pitch ，反而讓我們更仔細的思考團隊的需求，將團隊分成三種類型，因此讓團隊與評審更適切的互動，直接給予同理、建議、鼓勵或是一些非金錢但重要的行動資源。</p>
    <h4>開發迴圈工具包，讓行動迴圈「可行」：</h4>
    <p>當年把概念性的行動迴圈對應的工具都開發出來，還做了一個<a href="https://rethinktaiwan2027.wixsite.com/rethinktaiwantool" target="_blank">工具網站</a>的雛形，協助團隊每次討論、行動，都可以有清楚的目標。 </p>
    <h4>第一次迴響同學會：</h4>
    <p>半開玩笑的在最後一場活動抽籤兩組團隊，實際上真的發生囉！讀到這裡的迴響校友，要不要一起舉辦下一場啊？</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
