<template>
  <Modal :name="'planet_challenge'" ref="modal" :isWhite="true">
    <h3>挑戰 Challenge</h3>
    <p class="t-center">「最美好的事往往也是最困難的 。」</p>
    <br />
    <p>挑戰是把自己跟更大的系統連接起來，是相信每一個當下都只是「小局」，把大局放在心裡，那些張牙舞爪的東西，就會變成西瓜或香蕉皮，吃掉或者乾脆繞過去。</p>
    <p>迴響重視的價值是，面對挑戰，懂得把自己變大，也能夠把自己縮的很小。</p>
    <img src="../../images/world/NPC/events/Planet7Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
