<template>
  <Modal :name="'s2_team_type_tree_2'" ref="modal" :isWhite="true">
    <h5 class="t-center">團隊樣貌</h5>
    <br>
    <h3>選擇停下來</h3>
    <p>行動後，更看清世界真正的樣子，發現實在是太難了，現在暫時沒辦法用兩三個人的行動就促成什麼變化。行動不是責任，不行動也不是逃避，只是重新呼應當下的內心，還有其他也想要投入的事情。</p>
    <br>
    <br>
    <br>
    <h3>2019 醫起針自由</h3>
    <p>最初的提案是：「我們如何能在夜間幫助需要注射藥品的病患度過難關？」</p>
    <p>團隊以個人經驗提案，家中有慢性病患者，有臨時注射藥物的需求，但注射的動作，對患者和家人來說，可能有操作上的挑戰或心理上的壓力，是團隊成員日常面對的困境。</p>
    <p>在行動的過程中，團隊發現要影響這個議題，牽扯最大的是法規的挑戰。小規模的解決方案：希望可以媒合他人協助注射的點子，也會觸犯相關法律，無法執行。短時間可觸及到的病友協會、醫療單位，也沒有影響法規的管道或立場。</p>
    <p>行動之後，發現現有資訊與身份的條件下，找不到切入點，因此團隊選擇好好整理當下的經驗與學習即可，先停下腳步。</p>
    <br>
    <br>
    <br>
    <h5 class="t-center">這類團隊樣貌目前佔迴響團隊的多數</h5>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
