var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.backpack.isOpen)?_c('div',{staticClass:"backpack"},[_c('div',{staticClass:"green_mask",on:{"click":function($event){return _vm.click_to_close()}}}),_vm._v(" "),_c('div',{staticClass:"popup"},[_c('div',{staticClass:"decoration_tag"}),_vm._v(" "),_c('div',{staticClass:"tabs"},[_c('div',{class:{tab: true, active: _vm.backpack.tabIndex == 0},on:{"click":function($event){return _vm.setBackpackTabIndex(0)}}},[_vm._v("物品")]),_vm._v(" "),_c('div',{class:{tab: true, active: _vm.backpack.tabIndex == 1},on:{"click":function($event){return _vm.setBackpackTabIndex(1)}}},[_vm._v("任務")])]),_vm._v(" "),_c('div',{staticClass:"tab_intro"},[_c('img',{attrs:{"src":require("../images/world/keyboard/B_key.svg"),"draggable":"false"}}),_vm._v("\n      切換"),(!_vm.check_mobile())?[_vm._v("背包空間")]:_vm._e()],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.backpack.tabIndex == 0),expression:"backpack.tabIndex == 0"}],staticClass:"each_page"},[_c('div',{staticClass:"inventory_area"},[_vm._l((_vm.player.state.inventoryMax),function(index){return [_c('div',{class:{cell: true,
                        props: (_vm.getItemCategory(index) === '道具'),
                        ingredient: (_vm.getItemCategory(index) === '素材'),
                        garbage: (_vm.getItemCategory(index) === '垃圾'),
                        treasure: (_vm.getItemCategory(index) === '寶物'),
                        stone: (_vm.getItemCategory(index) === '石頭'),
                        flower: (_vm.getItemCategory(index) === '花'),
                        bug: (_vm.getItemCategory(index) === '昆蟲'),
                        water: (_vm.getItemCategory(index) === '水生'),
                        plant: (_vm.getItemCategory(index) === '植物'),
                        active: _vm.backpack.inventoryIndex === index - 1},on:{"click":function($event){return _vm.setBackpackIndex({
                                  'category': 'inventory',
                                  'number': index-1})}}},[(_vm.backpack.sortedItems[index-1])?[_c('img',{attrs:{"src":require('../images/world/drops/'+_vm.backpack.sortedItems[index-1].name+'.svg'),"draggable":"false"}}),_vm._v(" "),(_vm.backpack.sortedItems[index-1].number > 1 && !_vm.itemIsOnlyOne(index))?_c('div',{staticClass:"item_number"},[_vm._v("\n                x"+_vm._s(_vm.backpack.sortedItems[index-1].number)+"\n              ")]):_vm._e()]:_vm._e()],2),_vm._v(" "),(index % 5 === 0)?_c('br'):_vm._e()]})],2),_vm._v(" "),_c('div',{staticClass:"intro"},[(_vm.chosenItem)?[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.chosenItemName))]),_vm._v(" "),_c('div',{staticClass:"category"},[_vm._v("分類："+_vm._s(_vm.chosenItem.category))]),_vm._v(" "),(!_vm.itemIsUsing && !_vm.showUsingMessage)?[_c('div',{domProps:{"innerHTML":_vm._s(_vm.chosenItem.intro)}}),_vm._v(" "),_vm._m(0)]:(_vm.itemIsUsing && !_vm.showUsingMessage)?_c('div',{staticClass:"usage"},[_vm._v("\n            是否查看"+_vm._s(_vm.chosenItemName)+"？\n            "),_c('div',{staticClass:"item_answers"},[_c('div',{class:{'item_answer': true, 'active': _vm.itemUseIndex == 1}},[_vm._v("\n                使用\n              ")]),_vm._v(" "),_c('div',{class:{'item_answer': true, 'active': _vm.itemUseIndex == 0}},[_vm._v("\n                不要\n              ")])])]):(_vm.showUsingMessage)?_c('div',{staticClass:"using_message"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.usingMessage)}})]):_vm._e()]:_vm._e()],2)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.backpack.tabIndex == 1),expression:"backpack.tabIndex == 1"}],staticClass:"each_page"},[_c('div',{staticClass:"inventory_area"},[_vm._l((_vm.player.state.missionMax),function(index){return [_c('div',{class:{cell: true,
                        mission_cell: true,
                        active: _vm.backpack.missionIndex === index-1},on:{"click":function($event){return _vm.setBackpackIndex({
                                  'category': 'mission',
                                  'number': index-1})}}},[_c('img',{attrs:{"src":_vm.missionImg(_vm.backpack.allMissions[index-1]),"draggable":"false"}}),_vm._v(" "),(_vm.isComplete(_vm.backpack.allMissions[index-1]))?_c('img',{staticClass:"complete",attrs:{"src":require("../images/world/missions/complete.svg")}}):_vm._e()]),_vm._v(" "),(index % 5 === 0)?_c('br'):_vm._e()]})],2),_vm._v(" "),_c('div',{class:{intro: true, completeIntro: _vm.isComplete(_vm.chosenMission.npc)}},[(_vm.chosenMission)?[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.chosenMission.missionTitle))]),_vm._v(" "),_c('div',{staticClass:"category"},[_vm._v("NPC："+_vm._s(_vm.getNPCName(_vm.chosenMission.npc)))]),_vm._v(" "),(_vm.isComplete(_vm.chosenMission.npc))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.chosenMission.finishIntro)}}):(_vm.isOngoing(_vm.chosenMission.npc))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.translateMessage(_vm.chosenMission.intro))}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.chosenMission.notTakenIntro)}})]:_vm._e()],2)])]),_vm._v(" "),_c('img',{staticClass:"keyboard_hint",attrs:{"src":require("../images/world/keyboard_hint.svg")}})]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"next_icon frame-anim-2"},[_c('img',{attrs:{"draggable":"false","src":require("../images/home/next_1.svg"),"alt":""}}),_vm._v(" "),_c('img',{attrs:{"draggable":"false","src":require("../images/home/next_2.svg"),"alt":""}})])}]

export { render, staticRenderFns }