<template>
  <Modal :name="'y2018_8'" ref="modal">
    <!-- <img src="./images/2018_8-1.jpg" alt=""> -->
    <p>「帶走一顆屬於你的行星。」</p>
    <p>這是專屬於迴響的浪漫吧。</p>
    <p>迴響創造了行動的城市，也創了行動的星系，然後用我們的語彙，想告訴青年：「嘿，你可能找不到怎麼描述自己的定位和價值，但那或許只是因為目前台灣社會，還沒有符合你的體系而已啦。」</p>
    <p>送青年一顆星球，點亮心中的光火，沒想到，其中一位青年的媽媽，拿著迴響的星球徽章說：「喏，給妳的，好好玩吧。」</p>
    <!-- <img src="./images/2018_8-2.jpg" alt="">
    <img src="./images/2018_8-3.jpg" alt="">
    <img src="./images/2018_8-4.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
