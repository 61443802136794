<template>
  <Modal :name="'s2_team_type_tree_3'" ref="modal" :isWhite="true">
    <h5 class="t-center">團隊樣貌</h5>
    <br>
    <h3>接棒發揮影響力</h3>
    <p>在行動的過程中，發展出議題有趣也有效的解決方式，讓合作單位看到創新的可能性。對團隊來說，短期職涯還有其他的選擇，讓更有資源、更專業的人把方案接續下去，其實對議題會有更大的幫助。</p>
    <br>
    <br>
    <br>
    <h3>2018 Dadable</h3>
    <p>最初的提案是：「我們如何能夠讓爸爸多參與育兒的過程，改善家庭中不平均的家務分工？」</p>
    <p>其實團隊失敗多次，最終才找到兩個有趣的切入點，分別是「父親育兒攝影展」來提升更多爸爸參與這個議題、「諮商、社群等等父親的支持系統」來協助經驗的傳承以及情緒上的支持。團隊有 1-2 次小型的行動實驗了解到這些解方是有潛力的。</p>
    <p>雖然行動有好的成果，但兩位成員在大四的人生階段，還想多方嘗試、有求職壓力，最終將點子分別交接給過程中認識的合作單位，由婦女權益基金會持續辦理父親育兒攝影展、而城男舊事心驛站也針對育兒父親提供相關的服務與活動，將點子持續經營下去。</p>
    <p>在階段性任務完成後，團隊後來為另一個在乎的議題展開行動，成立 DOXA 哲學豆沙，提供國小到高中的哲學思辨課程，希望提供大家「能認識、暢談哲學問題」的空間</p>
    <br>
    <br>
    <br>
    <!-- <h5 class="t-center">像這樣的團隊，還有 2019 小紅帽、2017 浪犬博士...</h5> -->
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
