<template>
  <Modal :name="'team_4'" ref="modal" :isWhite="true">
    <br>
    <br>
    <h4>如果想多認識自然及台灣這片土地，或想學習如何正確共好地接近山林：</h4>
    <p>歡迎參與眾森的線下實體活動「來去山林住一晚」或「一日山林走走」，活動資訊會不定期公布再粉專上。</p>
    <p>•　<a href="https://www.facebook.com/everybeing19/" target="_blank">眾森 facebook</a><br>•　<a href="https://www.instagram.com/everybeing19" target="_blank">眾森 instagram</a></p>
    <br>
    <br>
    <p>•　若自己想去山林或海邊從事戶外活動的時候，記得多上網做些功課，降低意外的風險，有不理解的也歡迎私訊粉專詢問我們，很樂意跟大家共同討論。</p>
    <p>•　週末假日也可以嘗試去公園，脫下鞋子赤腳走在草地上，細細感受腳底與土壤與草地接觸的感覺，遇到樹木可以擁抱他或靜靜的坐在他底下，並將每一次的感受記錄下來，可以觀察每次感受的變化。</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2019 眾森 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
