<template>
  <Modal :name="'team_11'" ref="modal" :isWhite="true">
    <!-- <p>如果你想瞭解更多關於爸爸育兒的資訊，歡迎採取下列行動喔！</p> -->
    <br>
    <br>
    <h4>如果你對這個議題有興趣，可以展開的小行動：</h4>
    <p>•　對於周圍的親朋好友，不妨試著把大家都歸零，在身邊的人又做出你不能理解的行為時，先別先入為主的想：「他怎麼又來了？！」試著聽他多說一些，搞不好就連是熟識的人，都能發現一隱藏在行為背後的你不曾認識的那個他。</p>
    <p>•　關注<a href="https://bit.ly/3xPo9y6" target="_blank">漂泊俱樂部粉專</a>，近期會舉辦標籤議題的線上小活動，與來自不同背景的青年交流標籤下的真實感受。</p>
    <p>•　晚上沒事就騎著車到街上到公園晃晃，感受一下街邊風貌，觀察不同人的生活。大膽一點的話也可以主動和陌生人聊個兩句，當作飯後運動其實還蠻有趣的。</p>
    <br>
    <br>
    <h4>推薦的資訊來源：</h4>
    <p>•　關注 <a href="https://bit.ly/2UX8Ww7" target="_blank">逆風劇團</a>，他們會分享很多相關展演活動還有與少年們相處過程的點滴</p>
    <p>•　關注 <a href="https://bit.ly/2UUn8WO" target="_blank">人約盟</a> 定期舉辦的論壇</p>
    <p>•　公共電視 <a href="https://bit.ly/3reYe0g" target="_blank">誰來晚餐第3季第38集 少年之家-沒血緣的大家庭</a></p>
    <p>•　關注 <a href="https://bit.ly/3wXI90l" target="_blank">華人創傷知情推廣團隊</a>，了解創傷知情，知情自己也知情他人。</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2020 漂泊俱樂部 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
