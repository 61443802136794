<!-- note that this component include appearence and trigger event. no collider & no mission -->
<template>
  <div class="NPCs">
    <div v-for="(npc, key) in npcs" :class="{[key]: true, roof: key.includes('Roof')}" :style="return_npc_style(npc)" @click="click_for_magic_npc(key)" :ref="key">
      <!-- <img v-if="trigger_on_mission_map(key)" :src="require('../images/world/NPC/' + key + 'Mission.svg')" :alt="key" draggable="false"/> -->
      <!-- , trigger: trigger_on_mission_map(key) -->
      <img :src="get_npc_img_url(key)" :alt="key" draggable="false" v-if="get_npc_img_url(key) && !(player.state.hasDog && key == 'dog')"/>
    </div>

    <div v-for="(animation, key) in animations" :class="[key, 'animation']"
                                                :style="return_npc_style(npcs[key])" :key="key" @click="click_for_magic_npc(key)">
      <template v-for="imageName in animation">
        <img :src="get_npc_img_url(imageName, 'animations')" :alt="key" draggable="false"/>
      </template>
    </div>

    <!-- light house, include house and roof, and roof would disappear by map.event (use ref) -->
    <div v-for="(eventImage, index) in player.state.events" :class="[eventImage]" :ref="eventImage" @click="click_for_magic_npc(eventImage)"
                                                            :style="return_npc_style(npcs[eventImage], eventImage)" :key="index">
      <img v-if="!eventAnimations[eventImage] && get_npc_img_url(eventImage, 'events', 'Event')" :src="get_npc_img_url(eventImage, 'events', 'Event')"
           class="fadeIn" :alt="eventImage" draggable="false"/>
      <div v-else class="animation fadeIn">
        <template v-for="animationImageName in eventAnimations[eventImage]">
          <img :src="get_npc_img_url(animationImageName, 'animations')" :alt="animationImageName" draggable="false"/>
        </template>
      </div>
    </div>

    <div class="Dog" :style="return_npc_style(dog)" ref="dog">
      <img :src="require('../images/world/NPC/dog.svg')" alt="dog" draggable="false"/>
    </div>
  </div>
</template>

<!-- <div class="CabinRoof" :style="return_npc_style({
                                  width: 23,
                                  shift: { 'x': 0, 'y': -21 },
                                  coordinate: { 'x':139,'y':215.1 }
                                })" ref="CabinRoof">
  <img class="CabinRoofImg" alt="CabinRoof" draggable="false"/>
</div> -->

<script>
import { mapState, mapMutations } from 'vuex';
import { animations, eventAnimations, dog } from '../js/map/map.js'
import { delay } from '../js/map/global.js'

export default {
  name: 'NPCs',
  data: function () {
    return {
      animations, // map.js
      eventAnimations,
      dog,
      nowMission: '', // to record what I trigger -> for avatar move and npc turn their head
      nowDisappear: '', // to collect now event disappear!
    }
  },
  computed: {
    ...mapState([
      'npcs', 'map', 'unit', 'player', 'windowWidth', 'windowHeight', 'events'
    ]),
    chooseNPCName: {
      get () {
        return this.$store.state.chooseNPCName
      },
      set (value) {
        this.$store.commit('setChosenNPCName', value)
      }
    }
  },
  created: function() {
    this.initial_vuex_function()
  },
  mounted: function() {
    const execute_in_order = async () => {
      await this.initial_event_name_in_map()
      // this.preload_cabin_roof()
      // this.detect_event_and_disappear() // detect event when start
      // this.load_del_NPC_and_disapeear() // load state when start
      // this.detect_mission_and_trigger_border()
    }
    execute_in_order()
  },
  methods: {
    ...mapMutations([
      'assignEventMapValue', 'initialNPCsFunction', 'recordEvent'
    ]),
    click_for_magic_npc(key) {
      this.chooseNPCName = key
    },
    get_npc_img_url(key, route, suffix) {
      if (route && suffix) key = route + '/' + key + suffix
      else if (route) key = route + '/' + key
      try {
        return require('../images/world/NPC/' + key + '.svg')
      }
      catch(error) {
        // console.log('note: no img -> ' + key)
        return false
      }
    },
    initial_event_name_in_map() {
      let event_names = Object.keys(this.events)
      for (let i = 0; i < event_names.length; i++) {
        let event_name = event_names[i]
        let event_triggers = this.events[event_name].triggers
        for (let j = 0; j < event_triggers.length; j++) {
          let x = event_triggers[j].x
          let y = event_triggers[j].y
          this.assignEventMapValue({'x': x, 'y': y, 'value': event_name})
        }
      }
    },
    initial_vuex_function() {
      this.initialNPCsFunction({'pressArrowKey': this.press_arrow_key})
    },
    return_npc_style(npc, eventImage) {
      let isRoof = false
      if (eventImage && eventImage.includes('Roof')) {
        isRoof = true
      }
      // console.log(npc)
      // const npc = this.npcs[npcName]
      if (npc && npc.width) {
        return 'width: ' + (npc.width * this.unit) + 'px;' +
         'left: ' + (npc.coordinate.x * this.unit) + 'px;' +
         'top: ' + (npc.coordinate.y * this.unit) + 'px;' +
         'margin-left: ' + (npc.shift.x * this.unit) + 'px;' +
         'margin-top: ' + (npc.shift.y * this.unit) + 'px;' +
         'transform: ' + 'translate3d(0px, 0px, ' + (parseInt(npc.coordinate.y) + (isRoof? 1 : 0)) + 'px)'
      }
      else console.log('no this npc style: ' + npc)
    },
    async press_arrow_key(e) {
      await delay(300)
      this.detect_event_and_disappear()
      this.detect_event_and_appear()
      this.detect_mission_and_trigger_border()
    },
    detect_mission_and_trigger_border() {
      const missionName = this.map.mission[this.player.avatar.x][this.player.avatar.y]
      if (missionName) {
        if (this.nowMission && missionName != this.nowMission) {this.remove_mission_trigger()}
        const doms = document.getElementsByClassName(missionName)
        if (doms) {
          for (let dom of doms) {
            dom.classList.add('trigger')
            this.nowMission = missionName
          }
        }
        else {
          // console.log('sth wrong. there is no dom (npcs.vue: detect_mission_and_trigger_border)')
        }
      }
      else if (this.nowMission) {this.remove_mission_trigger()}
    },
    remove_mission_trigger() {
      const doms = document.getElementsByClassName(this.nowMission)
      for (let dom of doms) {
        dom.classList.remove('trigger')
        this.nowMission = ''
      }
    },
    async detect_event_and_disappear() {
      const eventName = this.map.event[this.player.avatar.x][this.player.avatar.y]
      // console.log(eventName)
      if (eventName) {
        const theFirst = await this.ifNoRoofAddIt(eventName) // add eventRoof here
        const doms = this.$refs[eventName]
        // if (doms) {
        //   if (Array.isArray(doms)) {
            for (let dom of doms) dom.style.animation = '0.4s fadeOut both'
          // }
        // console.log(this.$refs[eventName])
          // else doms.style.animation = '0.4s fadeOut both'
          this.nowDisappear = eventName
        // }
        // else console.log('event has no NPC img: ' + eventName)
      }
      return true
    },
    async detect_event_and_appear() {
      const eventName = this.map.event[this.player.avatar.x][this.player.avatar.y]
      // note that map.event means disappear, and player event means change image
      if (this.nowDisappear && this.nowDisappear != eventName) { // means it walk out
        const doms = this.$refs[this.nowDisappear]
        // if (doms) {
        //   if (Array.isArray(doms)) {
            for (let dom of doms) dom.style.animation = '0.4s fadeIn both'
          // }
          // else doms.style.animation = '0.4s fadeIn both'
          this.nowDisappear = ''
        // }
        // else console.log('event has no NPC img: ' + eventName)
        // }
      }
    },
    ifNoRoofAddIt(eventName) {
      // console.log(this.player.state.events)
      for (let myEvent of this.player.state.events) {
        if (eventName == (myEvent+'Roof') && !myEvent.toString().includes('Roof')) {
          if (this.hasThisEvent(myEvent) && !this.hasThisEvent(myEvent+'Roof')) {
            // note that I have trigger this event but its roof didn't change
            this.recordEvent(myEvent+'Roof')
            return true
          }
        }
      }
      return false
    },
    hasThisEvent(eventName) {
      for (let myEvent of this.player.state.events) {
        if (myEvent == eventName) return true
      }
      return false
    },
    trigger_on_mission_map(key) { // for NPC img
      // console.log(this.map.mission[this.player.avatar.x][this.player.avatar.y])
      return (this.map.mission[this.player.avatar.x][this.player.avatar.y] === key)
    },
    trigger_on_event_map(key) {
      return (this.map.event[this.player.avatar.x][this.player.avatar.y] === key)
    },
    // preload_cabin_roof() {
    //   let img = new Image()
    //   img.onload = () => {
    //     // let dom = this.$refs.CabinRoof[0]
    //     let dom = document.getElementsByClassName('CabinRoofImg')[0]
    //     dom.setAttribute('src', img.src);
    //   }
    //   img.src = require('../images/world/NPC/CabinRoof.svg')
    // },
    // trigger_event(eventName) {
    //   for (let i in this.player.state.events) {
    //     if (eventName == this.player.state.events[i]) {
    //       console.log('event img: waste efficiency!')
    //       return true
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

.NPCs {
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  div {
    position: absolute;
    cursor: pointer;
    // will-change: transform;
    // transition: 0.3s steps(30, end);
    .animation {
      position: absolute;
      width: 100%;
    }
    img {
      // pointer-events: none;
      position: absolute;
      width: 100%;
    }
  }
  .animation {
    img {
      opacity: 0;
      animation: flashThree 1s both linear infinite;
      // will-change: opacity;
    }
    img:nth-child(1) {
    }
    img:nth-child(2) {
      animation-name: flashThree-2;
      // animation-delay: 0.38s;
    }
    img:nth-child(3) {
      animation-name: flashThree-3;
      // animation-delay: 0.76s;
    }
  }
  .fadeOut {
    animation: 0.4s fadeOut both;
  }
  .fadeIn {
    animation: 0.4s 0.2s fadeIn both; // 0.4s animation delay is for new Roof react time
  }
  .roof {
    // will-change: opacity;
    pointer-events: none;
  }
  // rethink white
  .trigger {
    filter: drop-shadow(0px 0px 1px rgba(243, 243, 243, 1)) drop-shadow(0px 0px 2px rgba(243, 243, 243, 1));
  }
  .FerryMan.trigger {
    filter:	none;
  }
  // pure white
  .Sage.trigger, .Bonfire.trigger {
    filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 2px rgba(255, 255, 255, 1));
  }
  // pure white strong
  .Carpentar.trigger, .ToolFishingRod.trigger, .ToolAx.trigger, .y2017_1.trigger, .y2017_2.trigger, .y2017_3.trigger, .y2017_4.trigger, .y2017_5.trigger, .y2017_6.trigger, .y2017_7.trigger, .y2017_8.trigger, .y2017_stone.trigger {
    filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 2px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 2px rgba(255, 255, 255, 1));
  }
  // grey
  .FinalStone.trigger, .Sign1.trigger, .Hiker1.trigger, .Hiker2.trigger, .Hiker3.trigger, .CableCarStation.trigger, .Sign2.trigger, .CentryGate.trigger {
    filter: drop-shadow(0px 0px 1px rgba(190, 190, 190, 1)) drop-shadow(0px 0px 1px rgba(190, 190, 190, 1));
  }
  // orange
  .PartnerFlavor.trigger, .PartnerHsu.trigger {
    // yellow filter: drop-shadow(0px 0px 1px rgba(249, 205, 50, 1)) drop-shadow(0px 0px 1px rgba(249, 205, 50, 1));
    filter: drop-shadow(0px 0px 1px rgba(247, 152, 71, 1)) drop-shadow(0px 0px 1px rgba(247, 152, 71, 0.5));
  }
  // Green
  .IssueCarpentar.trigger, .IssueFisherman.trigger  {
    filter: drop-shadow(0px 0px 1px rgba(28, 101, 101, 0.3)) drop-shadow(0px 0px 1px rgba(64, 211, 173, 0.7));
  }
  // Dark Green
  .Maple.trigger, .Cycad.trigger, .Oak.trigger, .Willow.trigger, .Juniper.trigger, .Cedar.trigger, .Areca.trigger {
    filter: drop-shadow(0px 0px 1px rgba(28, 101, 101, 0.5)) drop-shadow(0px 0px 1px rgba(64, 211, 173, 1));
  }
  // special
  .Alder.trigger {
    filter: drop-shadow(0px 0px 1px rgba(243, 243, 243, 1));
  }
  .CableCarOpen, .CableCarBottom, .AtouShot {
    display: none;
  }
  .CableCarBottom {
    transition: 20s linear;
  }
  .GondolaCable {
    pointer-events: none;
  }
  // reverse
  .IssueMovieRabbit,.IssueLRRH, .IssueWizard, .IssueFisherman, .y2017_1, .y2017_2, .y2017_3, .y2017_6, .PartnerPley {
    img {
      transform: scaleX(-1);
    }
  }
  .LakeGoddess {
    animation: floatUp 2.5s both;
  }
}

.ElfHelp {
  animation: flashOpacity 2s infinite both;
}

.GodLight {
  img {
    animation: spin 15s infinite both linear;
  }
}

@keyframes spin {
  0% {
    opacity: 0.8;
    transform: rotate(0deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
    transform: rotate(360deg);
  }
}

@keyframes flashOpacity {
  from {
    opacity: 0.2;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes floatUp {
  from {
    opacity: 0;
    transform: translate3d(0px, 150px, 331px);
  }
  to {
    opacity: 1;
    transform: translate3d(0px, 0px, 331px);
  }
}

</style>
