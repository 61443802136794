<template>
  <Modal :name="'partner_2'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>複雜生活節／皓甯</h3>
    <p>關於【複雜生活節】</p>
    <p>#工作狂網友見面會 #八年級自定義論壇</p>　
    <p>複雜生活節由一群有以下特質的人們組成：連續工作狂、反覆實踐、自稱系邊...。也是在 40 天之內籌備、250 人參加、一場屬於八年級生的聚會。</p>
    <p>複雜生活節相信，論壇除了討論「成功」之外還有更多的可能性。於是逐漸發展出以「成長」為名、說做就做的祭典。</p>
    <p>身兼中醫師的皓甯，也是複雜生活節的總召，對學生社團活動有著近乎痴迷的狂熱。他善用身邊累積的許多厲害美宣、行政庶務人才等資源，和自己賭了一把。期望透過活動找到一起成長的人，在探索的過程中得到更多反饋。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_2.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
