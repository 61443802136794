<template>
  <Modal :name="'y2018_7'" ref="modal">
    <!-- <img src="./images/2018_7-1.jpg" alt=""> -->
    <p>第二屆的行動節，是規模最大的一屆，不知道下一次還有行動節的時候，會長成什麼模樣？</p>
    <p>每一組團隊，都有設計師韋呈的心意，為團隊的議題畫下相符合的插畫搭配，這除了是要了解議題以外，更是花了無數的時間在構思。</p>
    <p>在華山的展場裡，每一個團隊使用被分配到的區塊，去展現兩個月的成果，有靜態展、有互動體驗、有商品販售、有分享新知。</p>
    <p>熱鬧喧嘩，看見了社會上某一小部分的行動縮影，光是如此，就覺得激勵人心。</p>
    <!-- <img src="./images/2018_7-2.jpg" alt="">
    <img src="./images/2018_7-3.jpg" alt="">
    <img src="./images/2018_7-4.jpg" alt="">
    <img src="./images/2018_7-5.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
