<template>
  <Modal :name="'s0_tools'" ref="modal" :isWhite="true">
    <h3>行動工具櫃</h3>
    <h4>有效的行動是可以學習跟進步的</h4>
    <p>踏上行動之路，登頂除了要有決心，其實還有很大一部分是技術。我們相信成功的行動不是一命二運三風水式的隨機產物，與其指望更多的時勢造出來的英雄，把社會上不理想的狀況變不見，不如讓更多看似平凡的人，也能有效行動。</p>
    <p>迴響計畫從 2017 年開始，每年向計畫中的 20-40 組團隊學習、測試，從他們每一個團隊身上ㄎ一ㄤ（台語中類似借用的意思）了一些有用的裝備，開發出能幫助更多人的行動方法論！讓你在參加的過程更容易定位自己的問題、找到排除困難的可能。</p>
    <br/>
    <h4 class="t-center">階段設計</h4>
    <p>一個行動如果有多重目標的話，常常會多頭馬車，不只重要的事情沒有達成，也可能消耗團隊間的合作動能。</p>
    <p>把行動拆成「來吧！問題」、「去吧！行動」、「上吧！大夥」的三個行動階段，看似口語化，實際上卻是在指引團隊每個階段的清楚目標，比較能夠按部就班達到。</p>
    <p>有這樣的路徑，也可以比較容易判斷問題的先後順序。迴響不在一開始就要求團隊找到商業模式、找到倡議目標，不是因為覺得那不重要、看輕長遠計畫的價值，而是希望留給團隊一個行動的空間，先專注在「想要回應的問題是什麼?」這個根本問題上，而不是把心力放在現階段本來就無法回答的挑戰上，最終導致放棄。</p>
    <br/>
    <h4 class="t-center">任務包挑戰</h4>
    <p>在「來吧！問題」階段，是你從想法到行動的第一步，如果你本來的方式已經有用了，那其實⋯大概也不用來參加迴響計畫了。</p>
    <p>任務包挑戰一共有六項，每項都有完整的說明、操作步驟、技巧示範影片，將「釐清跟認識議題」、「反思和辨認自己的假設」、「發想」和「行動計畫」拆解開來，讓你每個階段都能從做得到的開始，逐步因為嘗試了任務包中的行動，而跨出原本的思考範疇，更了解議題，找到能有效前進的切入點。</p>
    <p>每一屆計畫都會有 10~15 位受過訓練，並有實務操作經驗的教練協助審核，以「我們如何能幫助提案團隊們更好的行動」為目標，在每一次團隊提交任務包後給予回饋。各階段有清楚的通關條件，如果沒通過，你也會收到明確的調整方向，可以重新挑戰。</p>
    <p>相比於請大家交一份完整的報告來給主辦單位評分，我們相信我們也用行動跟團隊硬碰硬，比較能讓你有一起動起來的力氣，也更符合迴響在意行動力的意義。簡單來說，你在行動的時候，我們跑得也很喘啦！來尬車啊！</p>
    <p>就算最終沒有通過篩選，在過程中也可以說是直接完成了一次專案式學習，學習一種面對議題的方式。</p>
    <br/>
    <h4 class="t-center">行動迴圈</h4>
    <img calss="w-350" src="./images/action-loop.svg" alt="">
    <p>有效的行動不是單純的「忙碌」，而是會讓人「越做越深」。很多團隊在採取了第一步行動後，其實不知道怎麼消化行動的成果，指引接下來還可以往哪裡去。</p>
    <p>行動迴圈是改良自「設計思考」與「精實執行」兩種方法論的流程和工具，在行動、反思和發想部分，有具體的方法引導我們達成階段性目標，在新資訊、新觀點和下一步行動方案處，有清楚的學習單，類似商業模式帆布（Business Model Canvas），幫助我們整理階段性成果，也讓不同的團隊得以分享行動過程中的學習。</p>
    <p>行動迴圈的設計，讓你在團隊目標有些模糊的狀況下，仍保有基本的邏輯，凝聚團隊內的共識，讓每一次的討論、行動都能更有意義。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
