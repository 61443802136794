<template>
  <Modal :name="'y2019_5'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2019_5.jpg" alt=""> -->
    <p>這一踢，陪伴我們好多次社群發文的時刻。</p>
    <p>喔，這兩位在前兩屆是志工、是教練呢，當她們終於準備好的時候，也來開啟了自己的行動，成為第三屆的行動團隊！</p>
    <p>迴響的資源，等著被各位使用，只要你隨時準備好，就用你喜歡的方式和角色，獲取你要的支持吧。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
