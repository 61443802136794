import { render, staticRenderFns } from "./three_spiritual.vue?vue&type=template&id=65698642&scoped=true&"
import script from "./three_spiritual.vue?vue&type=script&lang=js&"
export * from "./three_spiritual.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65698642",
  null
  
)

export default component.exports