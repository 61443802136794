<template>
  <Modal :name="'y2018_1'" ref="modal">
    <!-- <img src="./images/2018_1-1.jpg" alt=""> -->
    <p>大會師的現場，就是讓青年團隊不間斷在做「自我介紹」的時刻。</p>
    <p>在迴響計畫，青年會逐步地拉近自己和社會的距離，慢慢去建構行動和世界的關係，而我們是誰？我們要處理什麼題目？我們嘗試什麼解決方法？我碰到什麼難題？</p>
    <p>當每一次表達自己為何出發，要去向哪裡時，眼下的路，就會逐漸清晰。</p>
    <!-- <img src="./images/2018_1-2.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
