<template>
  <Modal :name="'y2019_8'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2019_8-1.jpg" alt=""> -->
    <p>當製作費用、獎金，通通都減少時，我們該如何維持以往的規模，給新的青年同樣的資源呢？</p>
    <p>在這樣變動的一年，感謝仍然有行動夥伴的支持，持續陪著迴響，和青年討論一個又一個新的看見、新的社會議題、新的洞察，然後於此同時，行動夥伴們也看見新的自己。</p>
    <!-- <img src="./images/2019_8-2.jpg" alt="">
    <img src="./images/2019_8-3.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
