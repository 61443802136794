<template>
  <Modal :name="'s2_studio_3'" ref="modal" :isWhite="true">
    <h3>設計思考教練</h3>
    <h4>設計思考教練，協助團隊將問題化為學習的機會</h4>
    <p>迴響提供了許多行動的方法與工具，但是每個團隊都有不同的步調和風格，沒有一個完美的工具可以適用團隊所有的問題。</p>
    <p>設計思考教練團，是一群熟悉這些行動工具、會議引導技巧的小精靈，從實體活動大會師開始，每組會配對一位教練，第一線了解團隊困難，陪伴團隊將問題化為學習的機會、將衝突化為對話的開端。</p>
    <br>
    <br>
    <h4>教練也是關鍵的角色，協助迴響計畫有方法的「以使用者為中心」</h4>
    <p>通常如果行動者參加到一個不太適合的計畫，可能會直接在初期選擇離開，或者是低參與的完成計畫，而低度參與時，參加者也不會覺得自己有資格給予回饋，教練至少每 1-2 週會與團隊互動一次，能在第一線了解團隊真正遇到的狀況，也沒有「一定要團隊成功」的成果壓力，能真的站在團隊的處境思考，間接也讓主辦單位獲得如何改善的線索。</p>
    <br>
    <br>
    <h4>這裡是迴響歷屆的教練，是每一屆的迴響能跑回本壘得分的關鍵<br>以此紀念迴響的最佳投捕組合，他們是這個計畫最前線的代言人，用行動接住每一個兩好三壞的時刻</h4>
    <p>第一屆 黃閔駿、Jarah、Rax、姚瑞鵬、張庭嘉、李俊廷、巫曉涵<br>第二屆 白羽平、劉靜文、王鵬傑、梁藝瀠、黃閔駿、曹伊裴、薛名喨、鐘弘育、曾振皓、古展弦、簡瓅、姚瑞鵬、吳思璇、許翊勤<br>第三屆 王鵬傑、邱純慧、梁藝濚、鄭惠文、黃培陞、謝睿哲、張庭嘉、黃俊豪、姚瑞鵬、簡瓅、Jarah、吳威廷、劉靜文<br>第四屆 王鵬傑、姚瑞鵬、簡瓅、梁藝濚、鄭惠文、鍾孟翰、李晨馨、胡抽抽、陳ㄧ心、邱純慧、蕭仲恩、林鼎惟、劉昀瑄、雷文鳳、黃昱翔、鄭柏軒、謝璿</p>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
