<template>
  <Modal :name="'giant_room_3'" ref="modal">
    <br/>
    <br/>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <h5 class="t-center">(古壁畫的某一段落，紀錄著這段文字⋯⋯)</h5>
    <br/>
    <br/>
    <p>在行動的開始階段，團隊還有許多需要磨合的地方，對於目標、方向的觀點可能也很分歧，如果恰好處的來，能夠直來直往的討論，衝突很快就會變成新的行動，然而，如果頻率不對，在大部分的情況下，可能會迴避衝突，讓其實應該更早出現、更早討論的矛盾變成未來的定時炸彈。</p>
    <p>為了讓行動不會靠緣分，我們會為晉級大會師的每一個組別，安排一位「設計思考教練」。從實體活動大會師開始，每組會依據團隊溝通狀況、議題方向，配對一位教練，第一線了解團隊困難，陪伴團隊將問題化為學習的機會、將衝突化為對話的開端。</p>
    <br/>
    <p>迴響第四屆的教練頭之一 姚瑞鵬 常說，每年擔任迴響的教練，對他而言是一種贖罪，知道無論如何這一年有做了對社會有益的事。教練的任務到了中後期就開始要面對各種拔河，為了更瞭解如何引導團隊而深入議題，因為深入議題而適合於扮演團隊成員之一的角色，又因為擔心自己的投入會擠壓到團隊行動的機會，而常常要顯得很抽離。這是迴響歷年都會遭遇的矛盾，卻也是張力的主要來源，有矛盾，才能打開選擇的空間，沒有指定一種方式，才會形成各種方式都有可能的多元局面。</p>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
