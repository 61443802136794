<template>
  <Modal :name="'planet_desire'" ref="modal" :isWhite="true">
    <h3>渴望 Desire</h3>
    <p class="t-center">「永遠都知道還有更好的可能。」</p>
    <br />
    <p>渴望，是因為撞見未來而興奮不已。你也有未來突然變的透明清晰的時刻麼？想像力從渴望中迸發，還未發生的場景一幕一幕飛馳而過，好像你立時擁有了什麼一樣。</p>
    <p>迴響重視的價值是，心無悸動時退後一步，看見最初的渴望在哪裡，用帶著心跳的行動連結未來和現在。</p>
    <img src="../../images/world/NPC/events/Planet12Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
