<template>
  <Modal :name="'planet_open'" ref="modal" :isWhite="true">
    <h3>開放 Open</h3>
    <p class="t-center">「擁抱各種過去、現在和未來。」</p>
    <br />
    <p>開放是牽好自己邊境的牧羊犬，任他吠叫，也要和上門的未來點點頭，好好招呼現在的需求，讓過去的執著經過。</p>
    <p>迴響相信的價值是，打開門來，邀請他們，該留的會留，想走的會走。</p>
    <img src="../../images/world/NPC/events/Planet10Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
