<template>
  <Modal :name="'y2020_stone'" ref="modal" :isWhite="true">
    <h3>2020-2021 年，是嘗試與世界連結的一屆</h3>
    <p>與世界連結的意思是，過往的迴響似乎踩在一種得天獨厚的環境，所有的嘗試都是我們的「想要」，但今年的執行考量了更多「未來延續」、「迴響以外的可能」</p>
    <h4>開發任務包線上軟體，讓回饋更精準：</h4>
    <p>線上化，讓資料的連貫性、教練回饋是更流暢的。你也可以在<a href="http://missions.rethinktaiwan.com/" target="_blank">公開化的任務包</a>體驗看看。 </p>
    <h4>開發迴圈工具包線上軟體，並沒有實際協助更好行動：</h4>
    <p>不是每個團隊都仰賴這麼清晰的邏輯分析來行動的，有時候，反而讓團隊不要想得那麼清楚，趕快行動，會更有收穫。</p>
    <p>經過兩年的嘗試，我們判斷為「教練的工具」，用來判斷團隊的整體狀況再給予回饋，而不需要每個團隊都需要如此有邏輯的工具。</p>
    <h4>眾力小宇宙的嘗試，是對的主題，卻不是團隊當下最需要的資源：</h4>
    <p>眾力小宇宙是在討論如何集眾人之力的主題，社會創新團隊要有效發揮影響力，的確常常會遇到要號召更多人的情境。</p>
    <p>嘗試後不如預期的是，迴響團隊這個階段真正的需求，經過三個月的衝刺後，或許不需要急著在行動的進度上推進，而是需要整頓團隊動力、先把一些已知的事情做得更多次來建立自信與團隊穩定度。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
