<template>
  <Modal :name="'planet_bridge'" ref="modal" :isWhite="true">
    <h3>對話 Bridge</h3>
    <p class="t-center">「在小島間搭起橋樑，讓彼此去到更遠的地方。」</p>
    <br />
    <p>對話，是與一部分的自己和解，是瞭解自己其實有盲點，是承認自己的不足，對話是接受我們需要對方。</p>
    <p>迴響相信的價值是，不知道該怎麼辦的時候，選擇對話比選擇隱瞞還要有效，講清楚、說明白就好。</p>
    <img src="../../images/world/NPC/events/Planet2Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
