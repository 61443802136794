<template>
  <div v-if="isPost" class="postit">
    <div class="green_mask"></div>
    <div class="hint">（請輸入文字）</div>
    <div class="postit_paper">
      <img class="bg" src="../images/world/postit.svg"/>
      <div class="question" v-html="question"></div>
      <textarea v-model="content" type="text" placeholder="寫在這裡吧！" class="textarea" :maxlength="(key == 'hmw'? 50 : 15)"/>
    </div><br />
    <div class="post_it_btn_set">
      <div class="btn finish_btn" @click="save_and_exit()">
        完成了
      </div>
      <div class="exit_link" @click="exit()">
        讓我再想一下
      </div>
      <img class="mouse_hint" src="../images/world/mouse_hint.svg"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'PostIt',
  data: function () {
    return {
      key: '',
      content: '',
      isPost: false,
      question: '我看見的大象是：'
    }
  },
  computed: {
    ...mapState([
      'player', 'backpack', 'modal', 'items', 'missions', 'dialogs'
    ])
  },
  created: function() {
  },
  mounted: function() {
    this.$store.commit('initialPostItFunction', {
      'post': this.postit}
    )
  },
  methods: {
    async postit(payload) {
      this.content = this.player[payload.key]
      this.question = payload.question
      this.key = payload.key
      this.isPost = true
      await this.$store.commit('labelAvatarIsActing', true)
      let dom = document.getElementsByClassName('textarea')[0]
      dom.focus()
      const dialog_dom = document.getElementsByClassName('dialog')[0]
      dialog_dom.style.opacity = "0"
    },
    async save_and_exit() {
      await this.$store.commit('inputPlayerData', {
        'message': this.content,
        'key': this.key
      })
      this.exit()
    },
    exit() {
      this.content = ''
      this.isPost = false
      this.$store.commit('labelAvatarIsActing', false)
      const dialog_dom = document.getElementsByClassName('dialog')[0]
      dialog_dom.style.opacity = "1"
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

.postit {
  position: fixed;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-wrap: wrap;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // padding-top: 21%;
  transform: translate3d(0, 0, 9999px);

  .green_mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 143, 120, 0.5);
  }

  .hint {
    // margin-top: 25vh;
    margin-top: calc(94vh - 640px);
    margin-bottom: 24px;
    position: relative;
    color: $new_world_white;
    // color: white;
    font-size: 15px;
    letter-spacing: 1.5px;
  }

  .postit_paper {
    position: relative;
    display: inline-block;
    width: 374px;
    height: 324px;
    color: $new_world_dark_blue;
    // top: 50%;
    // background: yellow;

    ::placeholder {
      color: rgba(15, 143, 120, 0.5);
    }

    .question {
      position: relative;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1.8px;
      margin: 30px 0 65px 0;
    }

    textarea {
      position: relative;
      border: 0;
      width: calc(100% - 76px);
      height: calc(100% - 150px);
      padding: 0 38px;
      background: transparent;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.56px;
      resize: none;
      color: $new_world_dark_blue;
    }
  }

  .post_it_btn_set {
    position: absolute;
    width: 100%;
    bottom: 30px;
  }

  .finish_btn {
    position: relative;
    margin-top: 83px;
    color: $green;
    border: 0;
    background: $new_world_white;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 12px 50px;
    border-radius: 50px;
  }

  .exit_link {
    position: relative;
    font-size: 14px;
    letter-spacing: 1.4px;
    margin-top: 30px;
    color: $new_world_white;
    text-decoration: underline;
    cursor: pointer;
  }

  .mouse_hint {
    display: inline-block;
    margin-top: 30px;
    width: 92px;
    // margin-left: -46px;
  }
}

@media screen and (max-height: 780px) {
	.postit {
    .hint {
      margin-top: 10vh;
    }
  }
}

@media screen and (max-height: 680px) {
	.postit {
    .hint {
      opacity: 0;
      margin-top: 2vh;
    }
  }
}

@media screen and (max-height: 600px) {
	.postit {
    height: 600px;
  }
}


</style>
