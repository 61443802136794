<template>
  <Modal :name="'planet_kindness'" ref="modal" :isWhite="true">
    <h3>溫柔 Kindness</h3>
    <p class="t-center">「善待人的不完美，尤其是那些與自己不同的地方。」</p>
    <br />
    <p>溫柔是選擇看見對方的不安、疑慮和恐懼，造一池冷熱適中的溫泉，讓對方泡進去放鬆而渾然不覺。</p>
    <p>迴響相信的價值是，無論順境或逆境，我們都學著保留一點餘裕，放慢腳步，對彼此溫柔一些。</p>
    <img src="../../images/world/NPC/events/Planet9Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
