<template>
  <Modal :name="'y2020_2'" ref="modal" :isWhite="true">
    <img src="./images/2020_2-1.jpg" alt="">
    <p>謹以此代表，謝謝所有歷屆概念啊攝影公司的攝影師們。</p>
    <p>這些攝影師，不只是攝影師，還是具備迴響精神的攝影師，跟著一起行動、靜下心來反思、用心感受場布的細節。</p>
    <p>這些攝影師，不只是攝影師，還是跟著一起響應、熱切回應號召者的夥伴，試著從鏡頭的語彙，去述說他們看見的事件、紀錄內心感動的時刻。</p>
    <p>謝謝你們，非常非常。</p>
    <img src="./images/2020_2-2.jpg" alt="">
    <img src="./images/2020_2-3.jpg" alt="">
    <img src="./images/2020_2-4.jpg" alt="">
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
