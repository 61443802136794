<template>
  <Modal :name="'partner_7'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>遠山呼喚／子鈞</h3>
    <p>關於【遠山呼喚】</p>
    <p>#讓世界上每個孩子 #都能獲得公平受教育的權利</p>
    <p>2015 年 4 月，渴望跳脫短期服務的一群大學生集結在一起，隨後一場地震帶遠山呼喚深入尼泊爾的震央村落。直到今天，他們持續以「教育種植計劃」為2700位孩子扭轉教育環境。</p>
    <p>遠山呼喚透過教育種植計畫的 4 大面向：募集教育資金(帶孩子回學校)，建置學習資源(改造閱讀空間、請學校增加閱讀課、獎勵閱讀學習單)，鼓勵親職參與(舉辦家長日、親子講堂)，以及增加學習動機(從首都找來偏鄉長大的成功人士做演講)在偏鄉種下教育。</p>
    <p>2019 年，遠山呼喚將震央成功經驗移植到尼泊爾大地震的第二震央—吉里鎮，2020 年更擴大至 10 所學校，目前正透過長期教育，扭轉 4000 位孩子的未來！</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_7.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
