<template>
  <Modal :name="'team_5'" ref="modal" :isWhite="true">
    <!-- <p>很開心跟你聊天！</p> -->
    <br>
    <br>
    <h4>想了解更多月經議題，歡迎追蹤：</h4>
    <p>
      •　<a href="https://bit.ly/3kvvsqZ" target="_blank">小紅帽 FB 粉專</a><br>
      •　<a href="https://bit.ly/3z5Co1O" target="_blank">小紅帽的 IG</a>
    </p>
    <br>
    <br>
    <h4>想更進一步參與月經的討論、時事分析、社群觀察，即刻加入：</h4>
    <p>
      •　<a href="https://bit.ly/3esc2PJ" target="_blank">小紅帽的紅粉聊天室</a>，掌握最新活動消息與國際輿情！<br>
      •　第五季月經故事信箱<a href="https://pse.is/3h9gwd" target="_blank">《小紅帽月經故事電台》</a>現正點播中，歡迎 Call in 你的月經故事
    </p>
    <br>
    <br>
    <p>＃月經就是月經，不是「大姨媽」、「好朋友」也不是「那個」，今天起，一起直接稱呼它吧！</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2019 小紅帽 Little Red Hood 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
