<template>
  <Modal :name="'ThreeSpiritual'" :title="'三大精神'" ref="modal">
    踏上行動之路，登頂除了要有決心，其實還有很大一部分是技術。我們相信成功的行動不是一命二運三風水式的隨機產物，與其指望更多的時勢造出來的英雄，把社會上不理想的狀況變不見，不如讓更多看似平凡的人，也能有效行動。<br />
    <br />
    迴響計畫從 2017 年開始，每年向計畫中的 20-40 組團隊學習、測試，從他們每一個團隊身上ㄎ一ㄤ（台語中類似借用的意思）了一些有用的裝備，開發出能幫助更多人的行動方法論！讓你在參加的過程更容易定位自己的問題、找到排除困難的可能。<br />
    <br
    踏上行動之路，登頂除了要有決心，其實還有很大一部分是技術。我們相信成功的行動不是一命二運三風水式的隨機產物，與其指望更多的時勢造出來的英雄，把社會上不理想的狀況變不見，不如讓更多看似平凡的人，也能有效行動。<br />
    <br />
    迴響計畫從 2017 年開始，每年向計畫中的 20-40 組團隊學習、測試，從他們每一個團隊身上ㄎ一ㄤ（台語中類似借用的意思）了一些有用的裝備，開發出能幫助更多人的行動方法論！讓你在參加的過程更容易定位自己的問題、找到排除困難的可能。<br />
    踏上行動之路，登頂除了要有決心，其實還有很大一部分是技術。我們相信成功的行動不是一命二運三風水式的隨機產物，與其指望更多的時勢造出來的英雄，把社會上不理想的狀況變不見，不如讓更多看似平凡的人，也能有效行動。<br />
    <br />
    迴響計畫從 2017 年開始，每年向計畫中的 20-40 組團隊學習、測試，從他們每一個團隊身上ㄎ一ㄤ（台語中類似借用的意思）了一些有用的裝備，開發出能幫助更多人的行動方法論！讓你在參加的過程更容易定位自己的問題、找到排除困難的可能。<br />
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style scoped lang="scss">

</style>
