<template>
  <div class="modal_screen">
    <ThreeSpiritual ref="ThreeSpiritual"/>
    <Test ref="Test"/>
    <s0-intro ref="s0_intro" />
    <s0-fire ref="s0_fire" />
    <s0-epitome ref="s0_epitome" />
    <s0-tools ref="s0_tools" />
    <s0-board ref="s0_board" />

    <giant-room-1 ref="giant_room_1" />
    <giant-room-2 ref="giant_room_2" />
    <giant-room-3 ref="giant_room_3" />
    <giant-room-4 ref="giant_room_4" />
    <giant-room-5 ref="giant_room_5" />
    <giant-room-6 ref="giant_room_6" />

    <s1-intro-route ref="s1_intro_route" />
    <s1-intro-stone ref="s1_intro_stone" />
    <s1-stone-tablet ref="s1_stone_tablet" />
    <s1-partners ref="s1_partners" />
    <y2017-stone ref="y2017_stone" />
    <y2017-1 ref="y2017_1" />
    <y2017-2 ref="y2017_2" />
    <y2017-3 ref="y2017_3" />
    <y2017-4 ref="y2017_4" />
    <y2017-5 ref="y2017_5" />
    <y2017-6 ref="y2017_6" />
    <y2017-7 ref="y2017_7" />
    <y2017-8 ref="y2017_8" />
    <y2018-stone ref="y2018_stone" />
    <y2018-1 ref="y2018_1" />
    <y2018-2 ref="y2018_2" />
    <y2018-3 ref="y2018_3" />
    <y2018-4 ref="y2018_4" />
    <y2018-5 ref="y2018_5" />
    <y2018-6 ref="y2018_6" />
    <y2018-7 ref="y2018_7" />
    <y2018-8 ref="y2018_8" />
    <y2019-stone ref="y2019_stone" />
    <y2019-1 ref="y2019_1" />
    <y2019-2 ref="y2019_2" />
    <y2019-3 ref="y2019_3" />
    <y2019-4 ref="y2019_4" />
    <y2019-5 ref="y2019_5" />
    <y2019-6 ref="y2019_6" />
    <y2019-7 ref="y2019_7" />
    <y2019-8 ref="y2019_8" />
    <y2019-9 ref="y2019_9" />
    <y2020-stone ref="y2020_stone" />
    <y2020-1 ref="y2020_1" />
    <y2020-2 ref="y2020_2" />
    <y2020-3 ref="y2020_3" />
    <y2020-4 ref="y2020_4" />
    <y2020-5 ref="y2020_5" />
    <y2020-6 ref="y2020_6" />
    <y2020-7 ref="y2020_7" />
    <y2020-8 ref="y2020_8" />
    <y2020-9 ref="y2020_9" />

    <s2-three-spiritual ref="s2_three_spiritual" />
    <s2-big-tree-hole ref="s2_big_tree_hole" />
    <s2-theory-of-change ref="s2_theory_of_change" />
    <s2-studio-1 ref="s2_studio_1" /><s2-studio-2 ref="s2_studio_2" /><s2-studio-3 ref="s2_studio_3" />
    <s2-studio-1-time-capsule ref="s2_studio_1_time_capsule" /><s2-studio-2-time-capsule ref="s2_studio_2_time_capsule" /><s2-studio-3-time-capsule ref="s2_studio_3_time_capsule" />
    <s2-team-type-tree-1 ref="s2_team_type_tree_1" /><s2-team-type-tree-2 ref="s2_team_type_tree_2" /><s2-team-type-tree-3 ref="s2_team_type_tree_3" /><s2-team-type-tree-4 ref="s2_team_type_tree_4" /><s2-team-type-tree-5 ref="s2_team_type_tree_5" /><s2-team-type-tree-6 ref="s2_team_type_tree_6" /><s2-team-type-tree-7 ref="s2_team_type_tree_7" />

    <team-1 ref="team_1" /><team-2 ref="team_2" /><team-3 ref="team_3" /><team-4 ref="team_4" /><team-5 ref="team_5" /><team-6 ref="team_6" /><team-7 ref="team_7" /><team-8 ref="team_8" /><team-9 ref="team_9" /><team-10 ref="team_10" /><team-11 ref="team_11" />
    <partner-1 ref="partner_1" /><partner-2 ref="partner_2" /><partner-3 ref="partner_3" /><partner-4 ref="partner_4" /><partner-5 ref="partner_5" /><partner-6 ref="partner_6" /><partner-7 ref="partner_7" /><partner-8 ref="partner_8" /><partner-9 ref="partner_9" /><partner-10 ref="partner_10" /><partner-11 ref="partner_11" /><partner-12 ref="partner_12" />

    <planet-bravery ref="planet_bravery" />
    <planet-bridge ref="planet_bridge" />
    <planet-challenge ref="planet_challenge" />
    <planet-choice ref="planet_choice" />
    <planet-desire ref="planet_desire" />
    <planet-devoted ref="planet_devoted" />
    <planet-empathy ref="planet_empathy" />
    <planet-faith ref="planet_faith" />
    <planet-honesty ref="planet_honesty" />
    <planet-iteration ref="planet_iteration" />
    <planet-kindness ref="planet_kindness" />
    <planet-open ref="planet_open" />
    <planet-reflection ref="planet_reflection" />
    <planet-trust ref="planet_trust" />
    <planet-union ref="planet_union" />

    <ax-checkin ref="ax_checkin" />
    <ax-decision ref="ax_decision" />
    <ax-strange ref="ax_strange" />
    <ax-sync ref="ax_sync" />
    <ax-teamwork ref="ax_teamwork" />
    <ax-value ref="ax_value" />

    <item-picture ref="item_picture" />
    <item-stone-soup ref="item_stone_soup" />

    <credits ref="credits" />
  </div>
</template>

<script>
import ThreeSpiritual from './modal/three_spiritual.vue'
import Test from './modal/test.vue'

import s0_intro from './modal/s0-intro.vue'
import s0_fire from './modal/s0-fire.vue'
import s0_epitome from './modal/s0-epitome.vue'
import s0_tools from './modal/s0-tools.vue'
import s0_board from './modal/s0-board.vue'

import giant_room_1 from './modal/giant-room-1.vue'
import giant_room_2 from './modal/giant-room-2.vue'
import giant_room_3 from './modal/giant-room-3.vue'
import giant_room_4 from './modal/giant-room-4.vue'
import giant_room_5 from './modal/giant-room-5.vue'
import giant_room_6 from './modal/giant-room-6.vue'


import s1_intro_route from './modal/s1-intro-route.vue'
import s1_intro_stone from './modal/s1-intro-stone.vue'
import s1_partners from './modal/s1-partners.vue'
import y2017_stone from './modal/y2017-stone.vue'
import y2017_1 from './modal/y2017-1.vue'
import y2017_2 from './modal/y2017-2.vue'
import y2017_3 from './modal/y2017-3.vue'
import y2017_4 from './modal/y2017-4.vue'
import y2017_5 from './modal/y2017-5.vue'
import y2017_6 from './modal/y2017-6.vue'
import y2017_7 from './modal/y2017-7.vue'
import y2017_8 from './modal/y2017-8.vue'
import y2018_stone from './modal/y2018-stone.vue'
import y2018_1 from './modal/y2018-1.vue'
import y2018_2 from './modal/y2018-2.vue'
import y2018_3 from './modal/y2018-3.vue'
import y2018_4 from './modal/y2018-4.vue'
import y2018_5 from './modal/y2018-5.vue'
import y2018_6 from './modal/y2018-6.vue'
import y2018_7 from './modal/y2018-7.vue'
import y2018_8 from './modal/y2018-8.vue'
import y2019_stone from './modal/y2019-stone.vue'
import y2019_1 from './modal/y2019-1.vue'
import y2019_2 from './modal/y2019-2.vue'
import y2019_3 from './modal/y2019-3.vue'
import y2019_4 from './modal/y2019-4.vue'
import y2019_5 from './modal/y2019-5.vue'
import y2019_6 from './modal/y2019-6.vue'
import y2019_7 from './modal/y2019-7.vue'
import y2019_8 from './modal/y2019-8.vue'
import y2019_9 from './modal/y2019-9.vue'
import y2020_stone from './modal/y2020-stone.vue'
import y2020_1 from './modal/y2020-1.vue'
import y2020_2 from './modal/y2020-2.vue'
import y2020_3 from './modal/y2020-3.vue'
import y2020_4 from './modal/y2020-4.vue'
import y2020_5 from './modal/y2020-5.vue'
import y2020_6 from './modal/y2020-6.vue'
import y2020_7 from './modal/y2020-7.vue'
import y2020_8 from './modal/y2020-8.vue'
import y2020_9 from './modal/y2020-9.vue'
import s1_stone_tablet from './modal/s1-stone-tablet.vue'

import s2_three_spiritual from './modal/s2-three-spiritual.vue'
import s2_big_tree_hole from './modal/s2-big-tree-hole.vue'
import s2_studio_1 from './modal/s2-studio-1.vue'
import s2_studio_2 from './modal/s2-studio-2.vue'
import s2_studio_3 from './modal/s2-studio-3.vue'
import s2_studio_1_time_capsule from './modal/s2-studio-1-time-capsule.vue'
import s2_studio_2_time_capsule from './modal/s2-studio-2-time-capsule.vue'
import s2_studio_3_time_capsule from './modal/s2-studio-3-time-capsule.vue'
import s2_theory_of_change from './modal/s2-theory-of-change.vue'
import s2_team_type_tree_1 from './modal/s2-team-type-tree-1.vue'
import s2_team_type_tree_2 from './modal/s2-team-type-tree-2.vue'
import s2_team_type_tree_3 from './modal/s2-team-type-tree-3.vue'
import s2_team_type_tree_4 from './modal/s2-team-type-tree-4.vue'
import s2_team_type_tree_5 from './modal/s2-team-type-tree-5.vue'
import s2_team_type_tree_6 from './modal/s2-team-type-tree-6.vue'
import s2_team_type_tree_7 from './modal/s2-team-type-tree-7.vue'

import team_1 from './modal/team-1.vue'
import team_2 from './modal/team-2.vue'
import team_3 from './modal/team-3.vue'
import team_4 from './modal/team-4.vue'
import team_5 from './modal/team-5.vue'
import team_6 from './modal/team-6.vue'
import team_7 from './modal/team-7.vue'
import team_8 from './modal/team-8.vue'
import team_9 from './modal/team-9.vue'
import team_10 from './modal/team-10.vue'
import team_11 from './modal/team-11.vue'

import partner_1 from './modal/partner-1.vue'
import partner_2 from './modal/partner-2.vue'
import partner_3 from './modal/partner-3.vue'
import partner_4 from './modal/partner-4.vue'
import partner_5 from './modal/partner-5.vue'
import partner_6 from './modal/partner-6.vue'
import partner_7 from './modal/partner-7.vue'
import partner_8 from './modal/partner-8.vue'
import partner_9 from './modal/partner-9.vue'
import partner_10 from './modal/partner-10.vue'
import partner_11 from './modal/partner-11.vue'
import partner_12 from './modal/partner-12.vue'

import planet_bravery from './modal/planet-bravery.vue'
import planet_bridge from './modal/planet-bridge.vue'
import planet_challenge from './modal/planet-challenge.vue'
import planet_choice from './modal/planet-choice.vue'
import planet_desire from './modal/planet-desire.vue'
import planet_devoted from './modal/planet-devoted.vue'
import planet_empathy from './modal/planet-empathy.vue'
import planet_faith from './modal/planet-faith.vue'
import planet_honesty from './modal/planet-honesty.vue'
import planet_iteration from './modal/planet-iteration.vue'
import planet_kindness from './modal/planet-kindness.vue'
import planet_open from './modal/planet-open.vue'
import planet_reflection from './modal/planet-reflection.vue'
import planet_trust from './modal/planet-trust.vue'
import planet_union from './modal/planet-union.vue'

import ax_decision from './modal/ax-decision.vue'
import ax_checkin from './modal/ax-checkin.vue'
import ax_strange from './modal/ax-strange.vue'
import ax_sync from './modal/ax-sync.vue'
import ax_teamwork from './modal/ax-teamwork.vue'
import ax_value from './modal/ax-value.vue'

import item_picture from './modal/item-picture.vue'
import item_stone_soup from './modal/item-stone-soup.vue'
// import

import credits from './modal/credits.vue'

export default {
  components: {
    ThreeSpiritual, Test,
    's0-intro': s0_intro, 's0-fire': s0_fire, 's0-epitome': s0_epitome, 's0-tools': s0_tools, 's0-board': s0_board,
    'giant-room-1': giant_room_1, 'giant-room-2': giant_room_2, 'giant-room-3': giant_room_3, 'giant-room-4': giant_room_4, 'giant-room-5': giant_room_5, 'giant-room-6': giant_room_6,

    's1-intro-route': s1_intro_route,
    's1-intro-stone': s1_intro_stone,
    's1-partners': s1_partners,
    'y2017-stone': y2017_stone,
    'y2017-1': y2017_1, 'y2017-2': y2017_2, 'y2017-3': y2017_3, 'y2017-4': y2017_4, 'y2017-5': y2017_5, 'y2017-6': y2017_6, 'y2017-7': y2017_7, 'y2017-8': y2017_8,
    'y2018-stone': y2018_stone,
    'y2018-1': y2018_1, 'y2018-2': y2018_2, 'y2018-3': y2018_3, 'y2018-4': y2018_4, 'y2018-5': y2018_5, 'y2018-6': y2018_6, 'y2018-7': y2018_7, 'y2018-8': y2018_8,
    'y2019-stone': y2019_stone,
    'y2019-1': y2019_1, 'y2019-2': y2019_2, 'y2019-3': y2019_3, 'y2019-4': y2019_4, 'y2019-5': y2019_5, 'y2019-6': y2019_6, 'y2019-7': y2019_7, 'y2019-8': y2019_8, 'y2019-9': y2019_9,
    'y2020-stone': y2020_stone,
    'y2020-1': y2020_1, 'y2020-2': y2020_2, 'y2020-3': y2020_3, 'y2020-4': y2020_4, 'y2020-5': y2020_5, 'y2020-6': y2020_6, 'y2020-7': y2020_7, 'y2020-8': y2020_8, 'y2020-9': y2020_9,
    's1-stone-tablet': s1_stone_tablet,

    's2-three-spiritual': s2_three_spiritual,
    's2-big-tree-hole': s2_big_tree_hole,
    's2-theory-of-change': s2_theory_of_change,
    's2-studio-1': s2_studio_1, 's2-studio-2': s2_studio_2, 's2-studio-3': s2_studio_3,
    's2-studio-1-time-capsule': s2_studio_1_time_capsule, 's2-studio-2-time-capsule': s2_studio_2_time_capsule, 's2-studio-3-time-capsule': s2_studio_3_time_capsule,
    's2-team-type-tree-1': s2_team_type_tree_1, 's2-team-type-tree-2': s2_team_type_tree_2, 's2-team-type-tree-3': s2_team_type_tree_3, 's2-team-type-tree-4': s2_team_type_tree_4, 's2-team-type-tree-5': s2_team_type_tree_5, 's2-team-type-tree-6': s2_team_type_tree_6, 's2-team-type-tree-7': s2_team_type_tree_7,

    'team-1': team_1, 'team-2': team_2, 'team-3': team_3, 'team-4': team_4, 'team-5': team_5, 'team-6': team_6, 'team-7': team_7, 'team-8': team_8, 'team-9': team_9, 'team-10': team_10, 'team-11': team_11,
    'partner-1': partner_1, 'partner-2': partner_2, 'partner-3': partner_3, 'partner-4': partner_4, 'partner-5': partner_5, 'partner-6': partner_6, 'partner-7': partner_7, 'partner-8': partner_8, 'partner-9': partner_9, 'partner-10': partner_10, 'partner-11': partner_11, 'partner-12': partner_12,

    'planet-bravery': planet_bravery, 'planet-bridge': planet_bridge, 'planet-challenge': planet_challenge, 'planet-choice': planet_choice, 'planet-desire': planet_desire, 'planet-devoted': planet_devoted, 'planet-empathy': planet_empathy, 'planet-faith': planet_faith, 'planet-honesty': planet_honesty, 'planet-iteration': planet_iteration, 'planet-kindness': planet_kindness, 'planet-open': planet_open, 'planet-reflection': planet_reflection, 'planet-trust': planet_trust, 'planet-union': planet_union,

    'ax-decision': ax_decision, 'ax-checkin': ax_checkin, 'ax-strange': ax_strange, 'ax-sync': ax_sync, 'ax-teamwork': ax_teamwork, 'ax-value': ax_value,

    'item-picture': item_picture,
    'item-stone-soup': item_stone_soup,

    'credits': credits,
  },
  name: 'Modals',
  data: function () {
    return {
    }
  },
  created: function() {
  },
  mounted: function() {
    this.initial_all_modals()
  },
  methods: {
    initial_all_modals() {
      let keys = Object.keys(this.$refs)
      // console.log(keys)
      for (let key of keys) {
        this.$store.commit('initialModal', {
          'name': key,
          'modal': this.$refs[key]
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '../scss/global';
@import 'scss/modal';

.modal_screen {
  transform-style: preserve-3d; height: 100%;
}

// define in assets
.map_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  // padding-top: 10%;
  transform: translate3d(0, 0, 9999px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s;

  .dark_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 62, 66, 0.85);
  }

  .progress_bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    background: $green;
    height: 6px;
  }

  .modal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    filter: drop-shadow(0px 0.781132px 6.24906px rgba(60, 145, 149, 0.2));
    width: 900px;
    // padding-top: 10%;
    padding-top: 5vh;

    .modal_title {
      height: auto;
      line-height: 22px;
      // background: $green;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      // color: #EEE;
      color: $new_world_light_green;
      font-weight: bold;
      letter-spacing: 2px;
      // font-size: 18px;
      font-size: 20px;
      // padding: 12px 0;
      padding: 24px 0;
      width: 240px;
      text-align: center;
      @media screen and (max-width: 700px) {
        font-size: 11px;
      }
    }

    .modal_box {
      position: relative;
      // background: white;
      border-radius: 10px;
      color: white;
      // color: $new_world_dark_blue;
      overflow: hidden;
      // border: 3px solid $green;
      width: 900px;

      // display: flex;
      // align-items: center;
      .modal_scroll_box {
        // height: 330px;
        // height: calc(90% - 210px);
        // height: calc(97% - 210px);
        // height: calc(97% - 70px);
        // height: 97%;
        height: 90vh;
        overflow-y: scroll;
        // padding: 80px 100px;
        padding: 40px 100px;
        box-sizing: border-box;
        scroll-behavior: smooth;

        .modal_content {
          a {
            color: #27E1B5;
          }
          .t-center {
            text-align: center;
          }
          img {
            display: block;
            width: 100%;

            // margin: 0 auto 16px;
            margin: 0 auto 24px;
            border-radius: 8px;
            &.w-350 {
              width: 350px;
            }
          }
          h3 {
            margin: 0 0 36px;
            font-size: 22px;
            line-height: 30px;
            text-align: center;
          }
          h4 {
            font-size: 18px;
            line-height: 32px;
            padding: 12px 0 0;
            margin: 0 0 22px;
          }
          h5 {
            font-size: 14px;
            line-height: 22px;
            padding: 0;
            margin: 0;
          }
          p {
            margin: 0 0 24px;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.64px;
          }
          ul {
            margin: 0 0 1.5rem;
            li {
              font-size: 16px;
              font-weight: 400;
              line-height: 28px;
              letter-spacing: 0.64px;
              padding-left: 20px;
              position: relative;
              margin: 0 0 0.5rem;
              &::before {
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: #006464;
                left: 0;
                top: 11px;
              }
            }
          }
          .note {
            font-size: 14px;
            text-align: right;
            margin: 0;
          }
          @media screen and (max-width: 700px) {
            h3 {
              margin: 0 0 24px;
              font-size: 18px;
            }
            h4 {
              font-size: 16px;
              line-height: 20px;
            }
            p {
              font-size: 11px;
              line-height: 18.75px;
              letter-spacing: 0.44px;
            }
          }
        }
      }
    }


  }

  .Q_hint {
    position: absolute;
    bottom: 30px;
    left: 45px;
    width: 69px;
    cursor: pointer;
    pointer-events: auto;
  }
}

.white_modal {
  .dark_bg {
    background: rgba(255, 255, 255, 0.85);
  }
  .modal {
    .modal_title {
      color: $new_world_dark_blue;
    }
    .modal_box {
      color: $new_world_dark_blue;
      .modal_scroll_box {
        .modal_content {
          a {
            color: #006464;
          }
        }
      }
    }
  }
}

.credits {
  .dark_bg {
    background: rgba(255, 255, 255, 0.95);
  }
  .modal .modal_box .modal_scroll_box .modal_content h3 {
    font-size: 30px;
  }
  .modal .modal_box .modal_scroll_box .modal_content h4 {
    font-size: 22px;
    line-height: 60px;
  }
  .modal .modal_box .modal_scroll_box .modal_content p  {
    margin: 0 0 18px;
    line-height: 28px;
  }
  text-align: center;
}

@media screen and (max-width: 700px) {
  .map_modal {
    // margin-top: -6vh;

    .modal {
      // width: 272px;
      width: calc(100vw - 50px);

      .modal_title {
        padding: 3px 15px;
        width: auto;
      }

      .modal_box {
        // width: 272px;
        width: 100%;
        border-width: 1px;
        .modal_scroll_box {
          padding: 30px 30px;
          // height: calc(100% - 60px);
        }

        .progress_bar {
          height: 4px;
        }
      }
    }

    .Q_hint {
      top: 12px;
      width: 42px;
      left: 6px;
    }
  }
}



</style>
