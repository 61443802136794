<template>
  <Modal :name="'giant_room_4'" ref="modal">
    <br/>
    <br/>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <h5 class="t-center">(古壁畫的某一段落，紀錄著這段文字⋯⋯)</h5>
    <br/>
    <br/>
    <p>我們知道議題深度有時不能靠一時的行動，而是需要時間的累積，我們希望讓團隊在較少資源的情況下，還是能找到槓桿目前行動能量，持續創造更多價值的機會點，其中一個方式，我們認為是讓更多人參與你的行動，也就是「號召」。</p>
    <p>議題團隊是一群在各自議題上都有創新實踐經驗的社會創新組織，針對「集眾人之力」這個主題組成共備團體，找到讓一群人一起促成一件事的方法。事實上，這個團體有時候更像一個心理互助會，在交流組織和志工的反思過程中相濡以沫。因為就算是已經有組織、有實作經驗的議題團隊，也時常需要煩惱如何更有效的行動，並且找到資源。</p>
    <p>好民文化行動協會的芳如就曾說：</p>
    <p>「回想半年前，有一段時間非常沮喪，既要為組織運作所需的財務週轉想辦法，又希望趁每年推動大型專案時做青年志工培力。想做事，又擔心沒資源。這時候剛好遇到創新志工計畫，好民便以「議題成功嶺」來提案，希望透過主題式的長期活動（自由路上藝術節為期2個月、共15個活動），作為志工的實際演練場。</p>
    <p>現在想起來不可思議，真心感謝當時投入的夥伴，讓藝術節能一邊舉辦活動、一邊進行志工培力，讓優化的力量在活動進行中產生，讓活動在執行中不斷優化。優化，不再是死板的檢討報告，而是行動者能量的具體呈現！我們的志工如果每堂培訓都參加了，就有：人文社會課程的培訓（轉型正義）、線上活動宣傳、與社會大眾應對溝通的技巧提升、提升活動支援的技巧、何謂專業活動行政心法、活動攝影增能、媒體寫作、甚至如何開檢討會。」</p>
    <p>當如何「集眾人之力」被反覆思考，創新志工模式就有更多種的可能。若要動員不同專業、不同觀點的異溫層，對自己在意的議題，要挖的越深，才能夠找出底層溝通的邏輯。深化議題之後，如何號召、培訓響應者？ 如何做議題行銷？ 如何做志工保溫？都是和更大的社會連結的重要問題。</p>
    <p>而這些志工經驗常是許多青年生命轉變的關鍵時刻，這些議題團隊便想方設法，為發展志工的典範轉移而努力。</p>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
