<template>
  <Modal :name="'s2_team_type_tree_5'" ref="modal" :isWhite="true">
    <h5 class="t-center">團隊樣貌</h5>
    <br>
    <h3>換個題目繼續動</h3>
    <p>一個人當然可以有很多在乎的議題，在迴響的過程，除了議題的推進，更重要的也帶走了行動的工具。我們希望青年離開迴響後，遇到自己在乎的社會議題、生活挑戰、工作困境、內心渴望，都能以行動去回應、嘗試。</p>
    <br>
    <br>
    <br>
    <br>
    <h3>2019 遠霧散去時我想起，後成立酵母菌</h3>
    <p>原先提案為：「我們如何能夠幫助高中生審視在社團活動中獲得的成就與寶貴經驗，並將之延續？」</p>
    <p>迴響之中，暫時沒有找到明確的切入點，但兩人一樣持續嘗試不同的專案，最終雖然不是在做特定社會議題，但兩人都希望透過行動催化身旁的每個人，發酵並創造讓人躍躍欲試的新事物！</p>
    <br>
    <br>
    <br>
    <br>
    <h5 class="t-center">像這樣的團隊還有：2018 Dadable，後成立「DOXA 哲學豆沙」，以及更多非正式的小行動在各處發生...</h5>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
