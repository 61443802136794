<template>
  <Modal :name="'y2019_2'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2019_2-1.jpg" alt=""> -->
    <p>說明會的場次，沒有辦法想前兩屆一樣到處跑跳，但是第三屆又想要突破迴響的受眾，希望可以接觸到更廣泛的年齡層。</p>
    <p>不只是在學學子，在上班族的青年，也是我們希望可以擾動的對象；想處理的議題，也不一定要是社會創新範疇所流行的環保議題，而可能是更接近自己生命、來自生活底層的議題，從最深刻的事出發，再困難也會找到一個開頭。</p>
    <p>今年開始邀請青年團隊，一同分享行動的歷程，向外回饋給更多群眾。</p>
    <!-- <img src="./images/2019_2-2.jpg" alt="">
    <img src="./images/2019_2-3.jpg" alt="">
    <img src="./images/2019_2-4.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
