<template>
  <Modal :name="'partner_1'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>人生百味／阿德</h3>
    <p>關於【人生百味】</p>
    <p>＃消除貧窮的行動實驗室</p>
    <p>人生百味相信，世界上沒有一種人叫做「窮人」，流浪、落魄、貧困都是一種狀態，是人生的不同風貌、不同味道。</p>
    <p>透過各種簡單有趣的計畫，讓社會上每一個人都有機會嘗試照顧周圍弱勢的人們。</p>
    <p>人生百味不改變人，而是改變街頭的風景，使街頭成為明亮友善的地方，人們願意放慢腳步，注意與關心周遭的人事物。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_1.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
