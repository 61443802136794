<template>
  <Modal :name="'planet_reflection'" ref="modal" :isWhite="true">
    <h3>反思 Reflection</h3>
    <p class="t-center">「我是誰？我正在做什麼？為什麼？」</p>
    <br />
    <p>反思，是以採取下一步行動為前提問自己問題，是認真看待過去的時間，是開始懂得為明天作準備。</p>
    <p>迴響重視的價值是，在每一個當下，清楚手段、目的、情緒和原因。</p>
    <img src="../../images/world/NPC/events/Planet3Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
