<template>
  <Modal :name="'planet_trust'" ref="modal" :isWhite="true">
    <h3>信任 Trust</h3>
    <p class="t-center">「永遠相信對方已經用盡全力。」</p>
    <br />
    <p>信任是不再踽踽獨行，是把一部分的自己交出去，像雀巢檸檬茶的廣告，可以張開雙臂躺下去。</p>
    <p>迴響相信的價值是，質疑不會讓事情推進，接受然後靠近，才能一起解決問題。</p>
    <img src="../../images/world/NPC/events/Planet15Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
