<template>
  <Modal :name="'ax_value'" ref="modal" :isWhite="true">
    <h5><b>團隊的文化越來越不健康，有問題時會互相推卸責任，資訊會偷藏...怎麼辦？</b></h5>
    <br />
    <br />
    <p>女神我一直都是孑然一身，不會遇到「文化」的問題，不過有些從旁觀察的經驗，與你分享。</p>
    <p>文化這種事情，與領導人和一致性有關，領導者是什麼樣子，團隊就會是什麼樣子，口頭的文化沒有在行為上相符，出現不一致的時候沒有點出來討論，都是文化的殺手，簡單來說，團隊文化比鄉民的玻璃心還要脆弱，要破壞團隊文化，比吃冰棒還簡單，沒有什麼辦法，只能由領導團隊的人一直強調，感覺怪怪的時候提出來討論而已。這裡是迴響的團隊文化，公開比較容易受到檢核，我們的皮也會繃的緊一點：<a href="https://docs.google.com/presentation/d/1wZVOE9-Kb8DeigcGlDI4JEgB-HMAcQf9EgGDzJaVoU8/edit#slide=id.g9b42d09b5b_0_819" target="_blank">迴響文化與個人工作說明書</a>。</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
