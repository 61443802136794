<template>
  <Modal :name="'y2020_1'" ref="modal" :isWhite="true">
    <img src="./images/2020_1.jpg" alt="">
    <p>三大精神，貫穿每一個行動，牽引著每一位迴響人。</p>
    <p>「先動，再說」</p>
    <p>「懷抱夢想，但從做得到的事情開始」</p>
    <p>「將遇到的困難，孵化成學習的機會」</p>
    <p>這些概念很容易懂，但不容易做得到；做到了，也不見得能持久；維持了，還要有容乃大，讓夥伴和自己都可以經歷這些體驗。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
