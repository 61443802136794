<template>
  <Modal :name="'y2019_6'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2019_6.jpg" alt=""> -->
    <p>無論在哪裡，加入迴響，就會有教練們支持行動團隊！</p>
    <p>每一位教練，也都跟著團隊在學習，不只是認識台灣青年現在關注的議題，也從帶領團隊的過程中，增強自己如何和不同類型的行動者溝通的技巧，看看自己如何以協助者的角度去推動團隊的對話。</p>
    <p>這是一個行動者的大學校，所有人都一起成長進步。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
