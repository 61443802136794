<template>
  <div class="status_remind" ref="reminder">
    <div class="remind_box">
      <div class="remind_title">
        小提醒
      </div>
      <div v-html="missionTitle"></div>
    </div>
  </div>
</template>

<script>
import { delay } from 'js/map/global.js'

export default {
  name: 'Reminder',
  data: function () {
    return {
      missionTitle: '',
    }
  },
  created: function() {
  },
  mounted: function() {
    this.$store.commit('initialReminder', this.show_remind_message)
  },
  methods: {
    async show_remind_message(text) {
      this.missionTitle = text
      let remind_dom = document.getElementsByClassName('status_remind')[0]
      remind_dom.style['display'] = 'none';
      // remind_dom.style['will-change'] = 'display, opacity'
      await delay(500)
      remind_dom.style['display'] = 'flex';
      // remind_dom.style['will-change'] = 'auto'
      // console.log(remind_dom)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

.status_remind {
  position: fixed;
  width: 100%;
  top: 100px;
  display: flex;
  display: none; // default
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 9999px);
  animation: flash 7s both;
  // will-change: display, opacity;

  .remind_box {
    background: white;
    border-radius: 100px;
    padding: 40px 90px;
    font-size: 14px;
    line-height: 22px;
    color: $new_world_dark_blue;
    display: flex;
    justify-content: center;
    text-align: center;

    .remind_title {
      position: absolute;
      display: inline-block;
      top: -18px;
      background: $text_highlight;
      font-weight: bold;
      padding: 10px 20px;
      border-radius: 8px;
    }
  }
}

@keyframes flash {
  from {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

</style>
