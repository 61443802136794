<template>
  <Modal :name="'team_8'" ref="modal" :isWhite="true">
    <br>
    <br>
    <h4>如果你對二手傢具議題有興趣，可以採取以下行動：</h4>
    <p>•　疫情期間，多了很多時間在家，跟傢俱有更長的相處和互動時間，趁這個機會好好觀察自己的傢俱，幫他擦拭整理乾淨，或者可以試著動腦動手改造它吧！加個喜歡樣式的桌巾、椅墊，或者拆掉一扇櫃子的門，讓居家空間增加一點新意，搞不好也是個培養第二興趣、專長的機會喔！</p>
    <p>•　如果你正好想淘汰或者購入傢俱，不仿嘗試對環境更友善的管道，轉賣給親友、找找二手傢俱賣場，或者參考傢俱達人的建議。</p>
    <p>•　現在很多傢俱購買回家是需要組裝的，那你有想過傢俱也是可以改造的嗎？有興趣你可以試看看喔！</p>
    <br>
    <br>
    <p>•　我們也正在收集大家對於二手傢俱議題有興趣的問題，歡迎幫我們填寫<a href="https://bit.ly/3hJfbwv" target="_blank">問卷</a></p>
    <p>•　或者直接搜尋<a href="https://bit.ly/3rehTNU" target="_blank">二手傢俱循環 OK 蹦</a>，我們會現身為你解惑！</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2020 二手傢具循環 OK 繃 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
