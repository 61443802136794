<template>
  <Modal :name="'Test'" :title="'德州撲克賭局'" :isWhite="true" ref="modal">
    Hey，Buy in 一次兩百
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style scoped lang="scss">

</style>
