<template>
  <div class="world">
    <div id="window">
      <Avatar></Avatar>
      <div class="map transition" v-show="!isLoading" :style="transitionStyle">
        <!-- note that this class is moving map, it would translate when avatar walking -->
        <template v-if="isSafari">
          <img class="background map_background" :style="background_style()" src="./images/world/lil_bg.svg"/>
          <img v-if="!isMobile && !player.state.clearFog" class="background fog map_background" :style="heavy_fog_style" src="./images/world/heavy_fog.png"/>
          <img v-if="!isMobile" class="background map_background" :style="background_style(true)" src="https://rethink4ever.s3.ap-northeast-1.amazonaws.com/other_pic/fog.png"/>
        </template>
        <template v-else>
          <img class="background map_background" :style="background_style()" src="./images/world/lil_bg.svg"/>
          <img v-if="!isMobile && !player.state.clearFog" class="background fog map_background" :style="heavy_fog_style" src="./images/world/heavy_fog.png"/>
          <img v-if="!isMobile" class="background map_background" :style="background_style(true)" src="./images/world/fog.svg"/>
        </template>
        <img class="background map_background" id="map_bg" :style="background_style()"/> <!-- load in js -->

        <!-- loading="lazy" -->
        <NPCs></NPCs>
        <!-- note that this is just for appearence. no collider & no mission -->
        <Drops></Drops>
        <!-- note: for drops appearence and pick it up. not for "add new drops" -->
        <Objects></Objects>
        <!-- duplicated objects are in here -->
      </div>
      <Keyboard v-show="!isLoading"></Keyboard>
      <Remind></Remind>
      <Backpack></Backpack>
      <Dialog></Dialog>
      <Modals></Modals>
      <PostIt></PostIt>
      <div v-if="isInTeachingMode" class="teaching_screen">
        <div class="intro_content cover" v-if="player.teachingStep == 0">
          <div class="content">
            <!-- <h1>迴響大本營</h1> -->
            <img class="title" src="./images/home_new/map-title" alt="迴響大本營" />
            <h5>RPG 線上展</h5>
            <br/>
            <h4>四年來的林林總總都記錄在這了！</h4>
            <br/>
            <p>青年迴響計畫從 2017 年開始，致力協助青年為自己在意的社會議題跨出第一步行動。發展至 2020 年，首次嘗試以 RPG 線上展的形式，將過去四年來累積的故事，策劃並呈現於此。</p>
            <br/>
            <p>迴響大本營 RPG 線上展，帶你以輕鬆有趣的互動方式：從登頂歷程，回顧迴響計畫四年來的里程碑與 2027 願景；從解決森林動物的問題，認識迴響的行動工具；從大澤遊湖探險，認識迴響團隊文化重視的行動價值；到處走走，與偶遇的 NPC 們聊聊，認識參與在迴響裡的大大小小故事與人們。</p>
            <br/>
            <p>空下一些時間，按下「開始探索」立即開始體驗吧！</p>
            <br/>
            <p></p>
            <a @click="player.teachingStep++">開始探索</a>
          </div>
        </div>
        <div class="intro_content" v-if="player.teachingStep == 1">
          <img class="intro_img arrow_key" src="./images/world/teaching_keyboard.svg"/>
          <div class="text">
            使用鍵盤的上下左右鍵<br />來移動迴響小人
          </div>
        </div>
        <template v-if="player.teachingStep == 2">
          <img :style="counterStyle" class="counter transition" src="./images/world/teaching_counter.svg"/>
          <div class="intro_content">
            <img class="intro_img A_key" src="./images/world/keyboard/A_key.svg"/>
            <div class="text">
              移動至山屋櫃檯面前<br />
              使用鍵盤的 A 鍵進行對話
            </div>
          </div>
        </template>
      </div>
      <div class="fixed_view" v-if="!isMobile">
        <GuideMap v-if="player.state.clearFog"></GuideMap>
        <div class="backpack_btn">
          <img class="backpack_img" src="./images/world/layout/backpack.svg">
          <div class="backpack_hover_list" @click="inventorySwitch(true)">
            <img @click="click_backpack(0)" class="backpack_img" src="./images/world/layout/item.svg">
            <img @click="click_backpack(1)" class="backpack_img" src="./images/world/layout/mission.svg">
          </div>
        </div>
        <div :class="{music_btn: true, active: backpack.isSoundEffectOpened}" style="right: 90px" @click="toggleEffectSound()">
          <img class="music_img music_on" :src="require('./images/world/layout/music_effect_on.svg')">
          <img class="music_img music_off" :src="require('./images/world/layout/music_effect_off.svg')">
        </div>
        <div :class="{music_btn: true, active: backpack.isSoundBGMOpened}" @click="toggleBGMSound()">
          <img class="music_img music_on" :src="require('./images/world/layout/music_BGM_on.svg')">
          <img class="music_img music_off" :src="require('./images/world/layout/music_BGM_off.svg')">
        </div>
      </div>

      <!-- Develop mode -->
      <template v-if="is_development">
        <div class="close_dev_mode_btn" @click="click_developer_switch()">
          switch
        </div>
        <div class="restart_btn close_dev_mode_btn" @click="clearCookie()">
          re-start
        </div>
        <div class="teleportation">
          <select v-model="developer.teleportation_npc">
            <option v-for="(npc, key) in npcs">
              {{key}}
            </option>
          </select>
          <div class="btn" @click="dev_go_to_npc_position()">
            Go!
          </div>
        </div>
        <div v-if="developer.magic_pointer_mode" class="setting">
          name: <input class="magic_npc_input" type="text" v-model="chooseNPCName"/><br />
          <span class="btn" @click="choose_npc(chooseNPCName)">Search</span><span class="btn" @click="move_npc(chooseNPCName)">Come Here</span><br />
          <br />
          width: <input type="number" v-model="chooseNPC.width"/><br />
          left: <input type="number" v-model="chooseNPC.coordinate.x"/><br />
          top: <input type="number" v-model="chooseNPC.coordinate.y"/><br />
          margin-left: <input type="number" v-model="chooseNPC.shift.x"/><br />
          margin-top: <input type="number" v-model="chooseNPC.shift.y"/><br />
          <span class="btn" @click="get_npc_position(chooseNPCName)">Get JSON</span>
        </div>
        <div v-if="developer.switch">
        <div class="grid_view" :style="'margin-left:' + (-0.54 * unit) + 'px;'">
          <div v-for="y in viewHeight" class="grid_row">
            <div v-for="x in viewWidth" class="grid"
            :style="'width:' + (unit-2) + 'px;' +
                    'height:' + (unit-2) + 'px;'"
             @click="click_grid(x, y)" @mouseover="hover_grid(x, y)"></div>
          </div>
          <div class="show_x_y">
            ({{developer.preview.x}},{{developer.preview.y}})
          </div>
          <div class="export_btn" @click="get_the_json()">Gotcha!</div>
          <div class="develop_btn_set">
            <div :class="{develop_btn: true, active: (developer.objectIndex == 0)}"
                 @click="developer.objectIndex = 0">
              <img src="./images/world/develop/square.svg" style="width: 12px;"/>
            </div>
            <div :class="{develop_btn: true, active: (developer.objectIndex == 1)}"
                 @click="developer.objectIndex = 1">
              <img src="./images/world/develop/tree.svg" style="width: 15px;"/>
            </div>
            <div :class="{develop_btn: true, active: (developer.objectIndex == 2)}"
                 @click="developer.objectIndex = 2">
              <img src="./images/world/develop/lawn.svg" style="width: 15px;"/>
            </div>
          </div>
        </div>
        <div v-for="coordinate in grids.colliders" class="collider transition"
          :style="'width:' + (unit-2) + 'px;' +
                  'height:' + (unit-2) + 'px;' +
                  'left: ' + (coordinate.x * unit) + 'px;' +
                  'top: ' + (coordinate.y * unit) + 'px;' +
                  'margin-left:' + (-0.54 * unit) + 'px;' +
                  'transform: ' + 'translate3d(' + ((windowWidth / 2)-(player.avatar.x * unit)) + 'px,' +
                  ((windowHeight / 2)-(player.avatar.y * unit)) + 'px, 9999px);'"></div>
        <!-- <div v-for="coordinate in grids.mission_grids" class="mission_grid transition"
          :style="'width:' + (unit-2) + 'px;' +
                  'height:' + (unit-2) + 'px;' +
                  'left: ' + (coordinate.x * unit) + 'px;' +
                  'top: ' + (coordinate.y * unit) + 'px;' +
                  'transform: ' + 'translate3d(' + ((windowWidth / 2)-(player.avatar.x * unit)) + 'px,' +
                  ((windowHeight / 2)-(player.avatar.y * unit)) + 'px, ' + coordinate.y + 'px);'"></div> -->

        <!-- <div v-for="coordinate in grids.bgm_main_grids" class="bgm_main_grid transition"
          :style="'opacity:' + (coordinate.v==0 ? 0.1 : coordinate.v) + ';' +
                  'width:' + (unit-2) + 'px;' +
                  'height:' + (unit-2) + 'px;' +
                  'left: ' + (coordinate.x * unit) + 'px;' +
                  'top: ' + (coordinate.y * unit) + 'px;' +
                  'margin-left:' + (-0.54 * unit) + 'px;' +
                  'transform: ' + 'translate3d(' + ((windowWidth / 2)-(player.avatar.x * unit)) + 'px,' +
                  ((windowHeight / 2)-(player.avatar.y * unit)) + 'px, ' + coordinate.y + 'px);'"></div>
        <div v-for="coordinate in grids.bgm_addon_grids" class="bgm_addon_grid transition"
          :style="'opacity:' + (coordinate.v==0 ? 0.1 : coordinate.v) + ';' +
                  'width:' + (unit-2) + 'px;' +
                  'height:' + (unit-2) + 'px;' +
                  'left: ' + (coordinate.x * unit) + 'px;' +
                  'top: ' + (coordinate.y * unit) + 'px;' +
                  'margin-left:' + (-0.54 * unit) + 'px;' +
                  'transform: ' + 'translate3d(' + ((windowWidth / 2)-(player.avatar.x * unit)) + 'px,' +
                  ((windowHeight / 2)-(player.avatar.y * unit)) + 'px, ' + coordinate.y + 'px);'"></div> -->
      </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { delay } from 'js/map/global.js'
// import Avatar from 'map/avatar.vue'
const Avatar= () => import(
  'map/avatar.vue'
  /* webpackPreload: true */
  /* webpackChunkName: "avatar" */
);
import Backpack from 'map/backpack.vue'
import Dialog from 'map/dialog.vue'
import Drops from 'map/drops.vue'
import Objects from 'map/objects.vue'
import NPCs from 'map/npcs.vue'
import Remind from 'map/remind.vue'
import Modals from 'map/modals.vue'
import PostIt from 'map/postit.vue'
import Keyboard from 'map/keyboard.vue'
import GuideMap from 'map/guide_map.vue'
import { sounds } from 'js/map/sounds.js' // used by develop mode
import { check_mobile } from 'js/map/global.js'
export default {
  components: {
    Avatar, Backpack, Dialog, Drops, Objects, NPCs, Remind, Modals, Keyboard, PostIt, GuideMap
  },
  props: ['is_development'],
  data: function () {
    return {
      'developer': {
        'switch': false,
        'objectIndex': 2, // 0: colliders, 1: trees...
        'preview': {'x': 0, 'y': 0},
        'magic_pointer_mode': false,
        'magic_hover_mode': false,
        'teleportation_npc': ''
      },
      isLoading: true,
      isMobile: false,
      sounds, // just for develop mode
      volume: 1,
      arrowKeyTimeId: 0,
      temp_grids: [], // just for develop mode
      chooseNPC: { // just for develop mode
        width: 1,
        shift: { "x": 0, "y": 0 },
        coordinate: { "x": 0, "y": 0 }
      }
    }
  },
  computed: {
    ...mapState([
      // 'isLoading',
      'map', 'unit', 'player', 'backpack', 'windowWidth', 'windowHeight',
      'xMax', 'modal', 'viewWidth', 'viewHeight', 'npcs', 'isSafari',
      'missions', 'objects', 'grids', 'isInTeachingMode' // these three are for develop
    ]),
    ...mapGetters([
      'whatIsPickingUp', 'transitionStyle'
    ]),
    chooseNPCName: {
      get () {
        return this.$store.state.chooseNPCName
      },
      set (value) {
        this.$store.commit('setChosenNPCName', value)
      }
    },
    counterStyle: function() {
      const x = (this.windowWidth / 2)-((this.player.avatar.x+0.54) * this.unit)
      const y = (this.windowHeight / 2)-(this.player.avatar.y * this.unit)
      return 'width: ' + (5 * this.unit) + 'px;' +
             'left: ' + (156 * this.unit) + 'px;' +
             'top: ' + (204.8 * this.unit) + 'px;' +
             'transform: ' + 'translate3d('+x+'px, '+y+'px, 0px)'
    },
    heavy_fog_style: function() {
      return 'width: ' + (96 * this.unit) + 'px;' +
       'left: ' + (98 * this.unit) + 'px;' +
       'top: ' + (188 * this.unit) + 'px;' +
       'transform: ' + 'translate3d(0px, 0px, 1000px)'
    }
  },
  created: function() {
    this.$gtag.pageview({ page_path: window.location.pathname, page_title: '迴響大本營線上展' });
    window.addEventListener('resize', this.adjust_window_size);

    const execute = async () => {
      this.$store.commit('initialAllMaps') // must before rendor
      if (check_mobile()) {
        this.isMobile = true
        await this.changeUnitValue(17.5)
      }
      if (this.developer.switch) await this.develop_initial()

      // cookie
      await this.assignPlayerStateByCookie()
      if (this.player.state.isFirst && !check_mobile()) this.activate_newbie_teaching()
      this.set_special_aisle()
      this.load_del_NPC_and_disapeear()
      this.moveAndDetectNPCEvent()
      // console.log(this.player)
    }
    execute()
  },
  mounted: function() {
    const execute = async () => {
      this.isLoading = false
      this.initial_window_value()
      this.loading_bg_img()
      // this.will_change_set()

      if (check_mobile() && this.backpack.isSoundBGMOpened) this.toggleBGMSound()

      // developer
      if (this.is_development) document.addEventListener('keyup', this.developer_magic);
      // no use
      // if (check_mobile()) this.initial_mobile()
      // disableScroll()
    }
    execute()
    setInterval(this.savePlayerStateInCookie, 2000)
  },
  destroyed: function() {
    window.removeEventListener('resize', this.adjust_window_size);
  },
  methods: {
    ...mapMutations([
      'clearCookie', 'changeUnitValue',
      'assignMapValue', 'setAllSpecialColliders', 'setBackpackTabIndex',
      'setPlayerCoordinate'
    ]),
    ...mapActions([
      'assignPlayerStateByCookie', 'endLoading', 'activate_newbie_teaching', 'inventorySwitch',
      'toggleBGMSound', 'toggleEffectSound', 'reorder_items', 'reorder_missions', 'reminder',
      'set_special_aisle', 'moveAndDetectNPCEvent', 'savePlayerStateInCookie'
    ]),
    // will_change_set() {
    //   const map_dom = document.getElementsByClassName('map')[0]
    //   map_dom.style['will-change'] = 'transform'
    //   setTimeout(() => {
    //     const map_dom = document.getElementsByClassName('map')[0]
    //     map_dom.style['will-change'] = 'auto'
    //   }, 3000)
    // },
    load_del_NPC_and_disapeear() {
      for (let delNPC of this.player.state.delNPCs) {
        const dom = document.getElementsByClassName(delNPC)[0]
        // console.log(dom)
        if (dom) dom.style.display = 'none'
        else console.log('error! there is no ' + delNPC + ' in our NPC assets!')
      }
    },
    dev_go_to_npc_position() {
      const choose_npc = this.npcs[this.developer.teleportation_npc]
      const go_to_x = parseInt(choose_npc.coordinate.x)
      const go_to_y = parseInt(choose_npc.coordinate.y)
      // console.log(go_to_x + ' ' + go_to_y)
      this.setPlayerCoordinate({'x': go_to_x, 'y': go_to_y})
    },
    click_backpack(index) {
      if (index == 0) this.reorder_items()
      if (index == 1) this.reorder_missions()
      this.setBackpackTabIndex(index)
    },
    loading_bg_img() {
      // if (this.player.isFirst) await delay(4000)
      let img = new Image()
      img.onload = () => {
        let dom = document.getElementById('map_bg')
        dom.setAttribute('src', img.src);
        this.$store.commit('initialSounds') // note that after bg, load the music
        // this.endLoading()
      }
      // img.src = 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/other_pic/bg.jpg'
      // img.src = 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/other_pic/bg.webp'
      // img.src = 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/other_pic/bg.png'
      // if (this.isSafari) img.src = require('./images/world/temp_bg.png')
      // else img.src = require('./images/world/temp_bg.svg')
      if (this.isSafari) img.src = 'https://rethink4ever.s3.ap-northeast-1.amazonaws.com/other_pic/bg.png'
      else img.src = require('./images/world/bg_0915.svg')
      // else img.src = require('./images/world/temp_bg.jpg')
    },
    initial_window_value() {
      const windowDOM = document.getElementById('app')
      // console.log(windowDOM)
      windowDOM.style.overflow = 'hidden'
    },
    developer_magic(e) {
      if (e.code === 'KeyP') this.pointer_event_auto()
      if (e.code === 'KeyH') {
        this.developer.magic_hover_mode = !this.developer.magic_hover_mode
        console.log(this.developer.magic_hover_mode? 'magic hover mode on!' : 'magic hover mode off!')
      }
    },
    pointer_event_auto() {
      this.developer.magic_pointer_mode = !this.developer.magic_pointer_mode
      console.log(this.developer.magic_pointer_mode? 'magic pointer mode on!' : 'magic pointer mode off!')
      const dom = document.getElementsByClassName('NPCs')[0]
      const dom_2 = document.getElementsByClassName('objects')[0]
      if (dom.style['pointer-events'] == 'auto') dom.style['pointer-events'] = 'none'
      else dom.style['pointer-events'] = 'auto'
      if (dom_2.style['pointer-events'] == 'auto') dom_2.style['pointer-events'] = 'none'
      else dom_2.style['pointer-events'] = 'auto'
    },
    // former
    initial_mobile() {
      const elements = document.querySelectorAll('*')
      for (let i = 0 ; i < elements.length; i++) {
        // -webkit-touch-callout:none;
        elements[i].style['-webkit-touch-callout'] = 'none'
        elements[i].style['-webkit-user-select'] = 'none'
        elements[i].style['-khtml-user-select'] = 'none'
        elements[i].style['-moz-user-select'] = 'none'
        elements[i].style['-ms-user-select'] = 'none'
        elements[i].style['user-select'] = 'none'
      }
    },
    adjust_window_size() {
      console.log('adjust yo!')
      this.$store.commit('assignWindowWidth', window.innerWidth)
      this.$store.commit('assignWindowHeight', window.innerHeight)
    },
    background_style(always_top) {
      let style = 'width:' + (this.unit * this.xMax) + 'px;'
      if (always_top) style += 'transform: translate3d(0, 0, 380px);'
      return style
      // 'transform: ' + 'translate3d(' + ((this.windowWidth / 2)-(this.player.avatar.x * this.unit)) + 'px,' +
      // ((this.windowHeight / 2)-(this.player.avatar.y * this.unit)) + 'px, ' + '0px);'
    },
    // develop
    choose_npc(npc_name) {
      if (this.npcs[npc_name]) {
        this.chooseNPC = this.npcs[npc_name]
      }
      else alert('no this npc:' + npc_name)
    },
    move_npc(npc_name) {
      if (this.npcs[npc_name]) {
        this.npcs[npc_name].coordinate.x = this.player.avatar.x
        this.npcs[npc_name].coordinate.y = this.player.avatar.y
      }
      else alert('no this npc:' + npc_name)
    },
    get_npc_position(npc_name) {
      if (this.npcs[npc_name]) {
        this.reminder('copy ' + npc_name + 's JSON to clipboard!')
        const value = "'" + npc_name + "': " + JSON.stringify(this.npcs[npc_name]) + ',';
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
      else alert('no this npc:' + npc_name)
    },
    develop_initial() {
      // this.insert_mission_as_grid()
      // this.insert_bgm_main_as_grid()
      // this.insert_bgm_addon_as_grid()
    },
    click_developer_switch() {
      this.developer.switch = !this.developer.switch
      this.develop_initial()
    },
    insert_mission_as_grid() {
      let mission_grids = new Array()
      let mission_names = Object.keys(this.missions)
      for (let i = 0; i < mission_names.length; i++) {
        let mission_name = mission_names[i]
        let mission_triggers = this.missions[mission_name].triggers // coordinates
        for (let j = 0; j < mission_triggers.length; j++) {
          let x = mission_triggers[j].x
          let y = mission_triggers[j].y
          let json = {'x': x, 'y': y}
          mission_grids.push(json)
        }
      }
      this.grids['mission_grids'] = mission_grids
      // console.log(this.grids)
    },
    insert_bgm_main_as_grid() {
      let mission_grids = new Array()
      let mission_names = Object.keys(this.sounds.Main)
      for (let i = 0; i < mission_names.length; i++) {
        let mission_name = mission_names[i]
        let mission_triggers = this.sounds.Main[mission_name] // coordinates
        for (let j = 0; j < mission_triggers.length; j++) {
          let x = mission_triggers[j].x
          let y = mission_triggers[j].y
          let json = {'x': x, 'y': y, v: mission_triggers[j].v}
          mission_grids.push(json)
        }
      }
      this.grids['bgm_main_grids'] = mission_grids
    },
    insert_bgm_addon_as_grid() {
      let mission_grids = new Array()
      let mission_names = Object.keys(this.sounds.Addon)
      for (let i = 0; i < mission_names.length; i++) {
        let mission_name = mission_names[i]
        let mission_triggers = this.sounds.Addon[mission_name] // coordinates
        for (let j = 0; j < mission_triggers.length; j++) {
          let x = mission_triggers[j].x
          let y = mission_triggers[j].y
          let json = {'x': x, 'y': y, v: mission_triggers[j].v}
          mission_grids.push(json)
        }
      }
      this.grids['bgm_addon_grids'] = mission_grids
      console.log(this.grids)
    },
    hover_grid(x, y) {
      this.developer.preview.x = this.player.avatar.x + (x - 40)
      this.developer.preview.y = this.player.avatar.y + (y - 31)
      x = this.player.avatar.x + (x - this.viewWidth/2 - 1)
      y = this.player.avatar.y + (y - this.viewHeight/2 - 1)
      if (this.developer.magic_hover_mode) this.magic_create(x, y)
    },
    magic_create(x, y) {
      const category = this.get_dev_category_name()
      const name = this.get_dev_object_name()
      let coordinate = {
        'x': x,
        'y': y
      }
      if (name != 'collider' && !this.has_the_object(category, name, coordinate)) {
        this.add_the_object(category, name, coordinate)
      }
      else if (name == 'collider' && !this.has_the_grid(coordinate)) {
        this.add_the_grid(coordinate)
      }
    },
    click_grid(x, y) {
      // note that (38, 21) = avatar position
      x = this.player.avatar.x + (x - this.viewWidth/2 - 1)
      y = this.player.avatar.y + (y - this.viewHeight/2 - 1)
      // alert(x + ',' + y)
      let coordinate = {
        'x': x,
        'y': y
      }
      this.build_the_object(coordinate)
      if (typeof this.map.mission[x][y] === 'string') alert(this.map.mission[x][y])
      // for jennifer develop
      this.temp_grids.push({ x, y, v: 0 })
      // console.log(JSON.stringify(this.temp_grids))
    },
    async build_the_object(coordinate) {
      const category = await this.get_dev_category_name()
      const name = await this.get_dev_object_name()
      if (name != 'collider') {
        this.isLoading = true
        let index = this.has_the_object(category, name, coordinate)
        if (index) await this.delete_the_object(category, name, index)
        else this.add_the_object(category, name, coordinate)
        this.isLoading = false
      }
      else {
        let index = this.has_the_grid(coordinate)
        if (index) await this.delete_the_grid(index)
        else this.add_the_grid(coordinate)
      }
    },
    has_the_object(category, name, coordinate) {
      for (let i = 0; i < this.objects[category][name].coordinates.length; i++) {
        if (this.objects[category][name].coordinates[i].x == coordinate.x &&
            this.objects[category][name].coordinates[i].y == coordinate.y) {
              return i
            }
      }
      return false
    },
    async delete_the_object(category, name, index) {
      let x = this.objects[category][name].coordinates[index].x
      let y = this.objects[category][name].coordinates[index].y
      // vuex
      this.map.collider[x][y] = true
      await this.objects[category][name].coordinates.splice(index, 1)
    },
    async add_the_object(category, name, coordinate) {
      // vuex
      this.map.collider[coordinate.x][coordinate.y] = false
      await this.objects[category][name].coordinates.push(coordinate)
    },
    has_the_grid(coordinate) {
      for (let i = 0; i < this.grids.colliders.length; i++) {
        if (this.grids.colliders[i].x == coordinate.x &&
            this.grids.colliders[i].y == coordinate.y) return i
      }
      return false
    },
    async delete_the_grid(index) {
      let x = this.grids.colliders[index].x
      let y = this.grids.colliders[index].y
      // vuex
      this.map.collider[x][y] = true
      await this.grids.colliders.splice(index, 1)
    },
    async add_the_grid(coordinate) {
      // vuex
      this.map.collider[coordinate.x][coordinate.y] = false
      await this.grids.colliders.push(coordinate)
    },
    async get_the_json() {
      let now_category = await this.get_dev_category_name()
      let now_dev = await this.get_dev_object_name()
      this.reminder('copy ' + now_dev + 's coordinates to clipboard!')
      let value
      if (now_dev != 'collider') value = 'triggers: ' + JSON.stringify(this.objects[now_category][now_dev].coordinates)
      else value = JSON.stringify(this.grids.colliders)
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    get_dev_object_name() {
      if (this.developer.objectIndex == 0) return 'collider'
      else if (this.developer.objectIndex == 1) return 'cypress'
      else if (this.developer.objectIndex == 2) return 'devFlower'
    },
    get_dev_category_name() {
      if (this.developer.objectIndex == 0) return ''
      else if (this.developer.objectIndex == 1) return 'tree'
      else if (this.developer.objectIndex == 2) return 'lawn'
    },
    // end of developer mode
  }
}
</script>

<style lang="scss">
@import 'scss/global';
// @import 'scss/map/global';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.lazyloading {
  img {
    // position: absolute;
    // max-width: 100%;
    // max-height: 100%;
    border-radius: 10px;
    // z-index: 999;
  }
}

.teaching_screen {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $new_world_white;
  opacity: 0.7;
  transform: translate3d(0, 0, 99999px);
  display: flex;
  justify-content: center;
  transform-style: preserve-3d;
  text-align: center;

  .counter {
    position: absolute;
  }

  .intro_content {
    position: absolute;
    display: inline-block;
    // text-align: left;
    bottom: 16vh;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    .title {
      margin: 0 0 .5rem;
    }

    .intro_img {
      margin-right: 30px;
      vertical-align: bottom;
    }

    .arrow_key {
      width: 150px;
    }

    .A_key {
      width: 45px;
    }

    .text {
      display: inline-block;
      text-align: left;
    }

    &.cover {
      pointer-events: all;
      // transform: translate3d(0, 0, 99999px);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      transform-style: preserve-3d;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .content {
        max-width: 600px;
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        h4 {
          font-style: italic;
        }
        p {
          text-align: left;
          font-size: 14px;
          font-weight: 500;
        }
        a {
          border: 2px #006464 solid;
          border-radius: 10px;
          padding: 2px 12px;
          display: inline-block;
          margin-top: 2rem;
          // margin-right: 5px;
          color: #006464;
          text-decoration: none;
          cursor: pointer;
        }
      }
      // &::before {
      //   content: '';
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   background: $new_world_white;
      //   opacity: 0.7;
      //   z-index: -1;
      // }
    }
  }
}

.world {
  position: relative;
  background: #FFFFFF;
  width: 100vw;
  // height: 100vh;
  // WebKit-based browsers will ignore this
  // height: -moz-available;
  // Mozilla-based browsers will ignore this.
  // height: -webkit-fill-available;
  // For WebKit-based browsers, Edge not support this
  // height: fill-available;
  height: 100vh;
  max-height: stretch;
  overflow: hidden;
  // transform-style: preserve-3d;
}
#window {
  position: absolute;
  width: 100%;
  height: 100%;
  // pointer-events: none;
  transform-style: preserve-3d;
  .map {
    position: absolute;
    font-size: 0;
    pointer-events: none;
    transform-style: preserve-3d;
    will-change: transform;
    .background {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      // opacity: 0.5;
    }
    .map_background {
      // will-change: transform;
      // will-change: scroll-position;
    }
  }
  //////////////// all below can be del ////////////////
  .setting {
    pointer-events: all;
    position: fixed;
    font-size: 14px;
    right: 25px;
    bottom: 100px;
    transform: translate3d(0, 0, 9999px);
    text-align: right;
    background: white;
    padding: 30px;
    border-radius: 20px;
    line-height: 25px;
    font-weight: bold;

    .btn {
      padding: 3px 13px;
    }
  }
  // temp
  .fixed_view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    text-align: center;
    transform: translate3d(0, 0, 9998px);
    // z-index: 999999;
    pointer-events: none;
    transform-style: preserve-3d;

    .backpack_btn {
      position: absolute;
      top: 20px;
      left: 23px;
      pointer-events: all;
      cursor: pointer;
      width: auto;
      height: 60px;

      .backpack_img {
        width: 66px;
      }

      .backpack_hover_list {
        display: none;
        // display: inline-block;
        margin-left: 10px;
        vertical-align: top;
        margin-top: -2px;

        img {
          margin-right: 7px;
          width: 40px;
        }
      }

      &:hover {
        .backpack_hover_list {
          display: inline-block;
        }
      }

    }

    .music_btn {
      position: absolute;
      right: 40px;
      top: 20px;
      cursor: pointer;
      pointer-events: auto;
      .music_on {
        display: none;
      }
      .music_off {
        display: block;
      }

      // &:first-child {
      //   right: 80px;
      // }
      // &:hover {
      //   .music_on {
      //     display: block;
      //   }
      //   .music_off {
      //     display: none;
      //   }
      // }
    }

    .active {
      .music_on {
        display: block;
      }
      .music_off {
        display: none;
      }
      // &:hover {
      //   .music_on {
      //     display: none;
      //   }
      //   .music_off {
      //     display: block;
      //   }
      // }
    }
  }
  .teleportation {
    position: fixed;
    transform: translate3d(0, 0, 99999px);
    bottom: 15px;
    right: 20px;
    select {
      background: $rethink_blue;
      border-color: $rethink_blue;
      color: $text_lightest;
      border-radius: 10px;
      padding: 6px;
    }
  }
  .close_dev_mode_btn {
    position: fixed;
    top: 80px;
    right: 20px;
    padding: 5px 10px;
    border-radius: 2px;
    font-weight: bold;
    background: $rethink_blue;
    color: $text_lightest;
    font-size: 14px;
    pointer-events: all;
    cursor: pointer;
    z-index: 999;
    border-radius: 10px;
    transform: translate3d(0, 0, 99999px);
  }
  .restart_btn {
    top: 110px;
    border-radius: 10px;
  }
  // develop
  .collider {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: red;
    opacity: 0.2;
    pointer-events: none;
  }
  .mission_grid {
    position: absolute;
    width: 23px;
    height: 23px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: blue;
    opacity: 0.2;
    pointer-events: none;
  }
  .bgm_main_grid {
    position: absolute;
    width: 23px;
    height: 23px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.4);
    opacity: 0.2;
    pointer-events: none;
  }
  .bgm_addon_grid {
    position: absolute;
    width: 23px;
    height: 23px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: brown;
    opacity: 0.2;
    pointer-events: none;
  }
  .grid_view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; // x
    align-content: center; // y
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 999;
    transform: translate3d(0, 0, 9999px);
    // white-space: nowrap;
    .grid_row {
      display: flex;
      .grid {
        border: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
        pointer-events: all;
        &:hover {
          background: rgba(0, 200, 0, 0.2);
        }
      }
    }
    .show_x_y {
      position: absolute;
      top: 15px;
      left: 20px;
      font-size: 14px;
    }
    .develop_btn_set {
      position: absolute;
      bottom: 30px;
      left: 40px;
      .develop_btn {
        display: inline-flex;
        margin-right: 12px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $rethink_night_green;
        align-items: center;
        justify-content: center;
        pointer-events: all;
        cursor: pointer;
        opacity: 0.5;
        img {
          opacity: 0.8;
        }
        &:hover {
          opacity: 1;
        }
      }
      .active {
        opacity: 1;
      }
    }
    .export_btn {
      position: absolute;
      bottom: 60px;
      right: 12px;
      padding: 10px 20px;
      border-radius: 10px;
      background: $rethink_blue;
      font-size: 14px;
      font-weight: bold;
      color: $text_lightest;
      pointer-events: all;
      cursor: pointer;
    }
  }
}

.world {
  ::-webkit-scrollbar {
    width: 6px;
    background-color: black;
  }
  * {
    // -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    // -webkit-tap-highlight-color:rgba(0,0,0,0);
   }
  textarea, input, .map_modal, .dialog_text {
    -webkit-user-select:text;
    -khtml-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
  }
  .map_modal, .dialog_text, .backpack {
    * {
      -webkit-user-select:text;
      -khtml-user-select:text;
      -moz-user-select:text;
      -ms-user-select:text;
      user-select:text;
      touch-action:auto;
    }
  }
  hr {
    width: 100%;
    opacity: 0;
    margin-top: -2px;
  }
  .transition {
    // will-change: transform;
    transition-property: translate3d;
    // transition: 0.4s ease;
    // transition: 0.3s steps(30, end);
    transition: 0.22s linear; // 0.27 safari
    // transition: 0.22s steps(20, end);
    // -moz-transition: 0.5s steps(50, end); // firefox
    -moz-transition: 0.28s linear; // firefox
  }

  mark { // highlight
    // font-weight: normal;
    color: #FF665c;
    background: transparent;
  }
}
</style>
