<template>
  <Modal :name="'team_9'" ref="modal" :isWhite="true">
    <br>
    <br>
    <p>如果你想針對二輪戲院的議題展開第一步行動，最直接的就實際走訪一趟二輪戲院吧！</p>
    <p>這份<a href="https://bit.ly/3kCMFOW" target="_blank">懶人包</a>，讓你第一次看二輪戲院就上手（內附全台各第二輪戲院資訊）</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2018 Be there 不見不散 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
