<template>
  <Modal :name="'s2_team_type_tree_1'" ref="modal" :isWhite="true">
    <h5 class="t-center">團隊樣貌</h5>
    <br>
    <h3>加速行動中</h3>
    <p>有些團隊在迴響期間，除了感受到個人對議題、團隊的強烈動機，也找到明確的議題切入點，以正職投入程度，成立協會、公司來讓自己可以持續影響議題的一部分，在過程中持續累積品牌，讓自己逐漸能與其他資源連結。</p>
    <br>
    <br>
    <br>
    <h3>2018 Date Myself</h3>
    <p>最初的提案是：「我們如何能夠讓人自發性地，發出正確的、可被解讀的求救信號？」</p>
    <p>其實團隊最初的構想，是希望可以製作一個網站，讓人有需要的時候可以找到心理相關的資源，但在行動的過程，團隊發現缺乏的並不是資源，而是有些人甚至「不知道自己有需求、不知道可以開口」，於是結合成員書寫的專業，邀請大家把時間留給自己：Date Myself。</p>
    <p>在迴響期間共實驗了四場活動，更確立了重要的行動洞察：當人們開口前或後，最重要的不是引薦給哪個單位，或是給予立即的建議和幫助，最重要的是在建立信任後，「同理」、「聆聽」，就是最好接住當事人的方法。</p>
    <p>參加迴響計畫，本來只是待業中的一個小嘗試，現在品牌靈魂君芃還是嘗試不同的異業合作、活動、產品...來持續讓更多人和自己對話、關注自己的心理需求。</p>
    <br>
    <br>
    <br>
    <h5 class="t-center">像這樣的團隊，還有 2019 小紅帽、2017 浪犬博士...</h5>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
