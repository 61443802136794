<template>
  <Modal :name="'y2017_8'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2017_8.jpg" alt=""> -->
    <p>在最後結尾的時候，應該要是個盛大的慶典，像是所有人都在為「行動」慶祝著。</p>
    <p>在迴響最後的這一個時刻，沒有所謂的輸贏，參觀的路人也是行動者，有一天所有人都會為了自己在意的事行動。</p>
    <p>而當行動者踏上自己的旅程時，他所散發的能量和決心，就像派對盛典一樣，吸引所有人的注意，並且決定一起投入試試。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
