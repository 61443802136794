<template>
  <Modal :name="'credits'" ref="modal" :isWhite="true">
    <p>Credits</p>
    <br />
    <h4>青年迴響計畫 歷屆執行團隊</h4>
    <br />
    <h4>2020 - 2021</h4>
    <p><b>總召、行動團隊管理</b><br>簡瓅、謝璿</p>
    <p><b>網站組</b><br>阮琛婷、余韋呈、胡哲愷</p>
    <p><b>品牌行銷</b><br>陳君芃</p>
    <p><b>設計組</b><br>游文瑀、李奕</p>
    <p><b>工程組</b><br>李心毅、李坤樸、陳彥求</p>
    <p><b>機制組</b><br>姚瑞鵬、王鵬傑、梁藝瀠、鄭惠文</p>
    <p><b>教練組</b><br>林鼎惟、梁藝瀠、黃昱翔、胡哲愷、鄭惠文、蕭仲恩、劉昀瑄、<br>鄭柏軒、雷文鳳、鍾孟翰、邱純慧、李晨馨、陳一心</p>
    <p><b>議題團隊組</b><br>周家緯、賴婷姿、黃昱翔</p>
    <p><b>活動與團隊管理組</b><br>劉建成、鄭媛心、蔡旻軒、陳信安、高雯、許翊勤</p>
    <p><b>志工組</b><br>周穎、林律潔、林芝肜、童筑君、張喬茵、洪雅筠、吳安倢、周宇心、林貫佑、李韋萱</p>
    <p><b>行動夥伴</b><br>王維綱、人生百味 巫彥德、藺子 廖怡雅、複雜生活節 許皓甯、文化銀行 邵璦婷、<br>玩轉學校 林哲宇、浪犬博士 林子馨、創咖啡 楊育明、對話千層派 李品汶、遠山呼喚 林子鈞、好伴社計 周家緯、ZAZA 眨眨眼 張維</p>
    <p><b>議題團隊</b><br>人生百味、好民文化協會、粉紅豹文化、壹零玖伍工作室、臺灣愛行動協會、數位外交協會</p>
    <p><b>評審組</b><br>魚肚白設計 王詩婷、一碼 黃芳惠、人生百味 巫彥德、浪犬博士 林子馨、創咖啡 楊育明、<br>社企流 iLab 龍映涵、Impact Hub 陳昱築、藺子 廖怡雅、遠山呼喚 林子鈞、<br>aaaaCityflaneurs 王維綱、對話千層派 李品汶、文化銀行 邵璦婷、<br>眨眨眼 張維、RE-THINK 重新思考 王滋鮮、心靈之美心理治療所 張艾如、<br>逆風劇團 成瑋盛、張希慈、聚樂邦 林志育、REnato lab 歐陽藹寧、林事務所 林承毅</p>
    <p><b>攝影夥伴</b><br>概念啊攝影公司</p>
    <p><b>燈光＆音響夥伴</b><br>野果創意工作室</p>
    <p><b>展場花藝</b><br>無事生活</p>
    <br />
    <br />
    <br />
    <h4>2019</h4>
    <p><b>總召</b><br>簡瓅</p>
    <p><b>品牌行銷</b><br>陳君芃</p>
    <p><b>設計群</b><br>薛名喨、高郡悅、林芸萱</p>
    <p><b>網站</b><br>胡哲愷</p>
    <p><b>活動體驗</b><br>張喬茵、張力堃、杜蒨妮</p>
    <p><b>資源連結</b><br>簡瓅、張力堃、杜蒨妮</p>
    <p><b>孵化機制</b><br>周鈺婷、姚瑞鵬、吳威廷、許翊勤、劉建成</p>
    <p><b>教練管理</b><br>劉靜文</p>
    <p><b>教練群</b><br>王鵬傑、梁藝瀠、鄭惠文、邱純慧、黃培陞、張庭嘉、謝睿哲、黃俊豪</p>
    <p><b>志工管理</b><br>高雯、杜蒨妮</p>
    <p><b>志工群</b><br>劉容宇、林怡瑩、朱映綺、余采玲、潘信達、楊逸頎、蔡嘉珊、林怡宣</p>
    <p><b>活動管家</b><br>周穎</p>
    <p><b>財務</b><br>章芙萍、黃郁文</p>
    <p><b>先賢夥伴</b><br>劉建成、余韋呈、王詩婷</p>
    <p><b>行動夥伴</b><br>玖樓 王維綱、人生百味 巫彥德、藺子 廖怡雅、複雜生活節 許皓甯、文化銀行 邵璦婷、<br>玩轉學校 林哲宇、浪犬博士 林子馨、創咖啡 楊育明、對話千層派 李品汶、<br>遠山呼喚 林子鈞、好伴社計 連真、林祖儀</p>
    <p><b>評審組</b><br>社企流 iLab 龍映涵、KPMG 侯家楷、Impact Hub 陳昱築、創咖啡 楊育明、<br>台北市都市更新處更新經營科 胡如君、奧沃市場趨勢顧問 林宛瑩、海湧工作室 陳人平、<br>複雜生活節 許皓甯、淡江大學諮商曁職涯輔導組 許凱傑、人生百味 巫彥德、好伴社計 連真、<br>玩轉學校 林哲宇、浪犬博士 林子馨、魚肚白設計 王詩婷</p>
    <p><b>攝影夥伴</b><br>概念啊攝影公司</p>
    <br />
    <br />
    <br />
    <h4>2018</h4>
    <p><b>專案管理與團隊經營</b><br>周鈺婷</p>
    <p><b>資源統籌與大型活動管理</b><br>王詩婷</p>
    <p><b>視覺設計與活動展場規劃</b><br>余韋呈</p>
    <p><b>團隊管理</b><br>劉建成、周穎</p>
    <p><b>行銷</b><br>黃彥禎</p>
    <p><b>設計與活動組</b><br>張容瑄、阮琛婷</p>
    <p><b>機制與教練管理</b><br>姚瑞鵬、簡瓅</p>
    <p><b>教練組</b><br>白羽平、劉靜文、王鵬傑、梁藝瀠、黃閔駿、曹伊裴、<br>薛名喨、鐘弘育、曾振皓、古展弦、吳思璇</p>
    <p><b>行動夥伴組</b><br>林子馨、游馥宇</p>
    <p><b>志工管理</b><br>許翊勤、高雯</p>
    <p><b>志工</b><br>翁晨馨、黃靜盈、游喜和、劉育全、蔡佳蓉、蕭妙芹、陳宜琳、呂芷瑋、蘇紫雯、<br>賴怡秀、彭語汎、柯昱均、李芝瑩、楊詩琳、施又寧、邱莉婷、黃昱翔、陳柏毅、<br>鍾孟勳、朱映綺、莊惟媜、林冠全、蘇紫茜、劉芳瑀、康琇婷、王品人</p>
    <p><b>財務管理</b><br>高雯</p>
    <p><b>行動夥伴</b><br>人生百味 巫彥德、浪犬博士 林子馨、工具時代 留鈺淳、文化銀行 邵璦婷、<br>FRNC 李品汶、藺子 廖怡雅、創咖啡 王崇政、玖樓 王維綱、好棒棒愛家事 楊宇綸、<br>好伴社計 連真、複雜生活節 許皓甯、玩轉學校 林哲宇、貳貳柒酒吧 李學誠、<br>讓狂人飛 洪璿岳、大學前必修課 陳睨</p>
    <p><b>評審組</b><br>台大創創中心執行長 曾正忠、林事務所 林承毅、<br>雜學校 蘇仰志、智榮基金會 李竺姮、泛科學 雷雅淇</p>
    <p><b>攝影夥伴</b><br>概念啊攝影公司</p>
    <p><b>燈光＆音響夥伴</b><br>野果創意工作室</p>
    <p><b>展場花藝</b><br>TA Deco</p>
    <br />
    <br />
    <br />
    <h4>2017</h4>
    <p><b>發起人</b><br>劉建成</p>
    <p><b>共同發起</b><br>串串 Change</p>
    <p><b>資源統籌與大型活動管理</b><br>王詩婷</p>
    <p><b>專案管理與團隊經營</b><br>周鈺婷</p>
    <p><b>設計群</b><br>余韋呈、王品人</p>
    <p><b>行銷與志工管理</b><br>施又寧</p>
    <p><b>網站工程師</b><br>胡哲愷、賴冠廷</p>
    <p><b>帳務管理</b><br>鍾孟翰</p>
    <p><b>活動組</b><br>曹伊裴</p>
    <p><b>遊俠</b><br>周昭蓉、高雯、邱鬧、吳思璇</p>
    <p><b>網站介面規劃與設計</b><br>王馨怡</p>
    <p><b>教練管理</b><br>巫曉涵</p>
    <p><b>教練</b><br>姚瑞鵬、張庭嘉、李俊廷、黃閔駿</p>
    <p><b>志工</b><br>黃思婷、何亭誼、林蓮馨、董元富、吳思璇、徐筱瑜、李筌偉、<br>邱莉婷、黃柏實、徐亦立、林祐任、高雯、黃翎育</p>
    <p><b>行動夥伴</b><br>文化銀行 邵璦婷、城市浪人 張希慈、職人 石展丞、複雜生活節 許皓甯、<br>人生百味 巫彥德、沃草 林祖儀、美感教科書 陳慕天、N次坊 侯智薰、<br>玖樓 王維綱、Hahow 好學校 黃彥傑、星球實驗創作空間 陳禹安、<br>好伴社計 連真、藺子 廖怡雅、好日子 高海琪、格外有意思 陳其農</p>
    <p><b>評審組</b><br>佔空間 張宗舜 (Argi)、老寮 邱星崴、臺大社會系 陳東升、<br>台灣吧 謝政豪、雜學校 蘇仰志、報導者 房慧真、活水社企 楊家彥</p>
    <p><b>攝影夥伴</b><br>概念啊攝影公司</p>
    <p><b>燈光＆音響夥伴</b><br>野果創意工作室</p>
    <p><b>展場花藝</b><br>TA Deco</p>
    <p><b>主題曲製作</b><br>洪立</p>
    <br />
    <br />
    <br />
    <p><b>2017 - 2021 迴響計畫線上成果展 製作群</b></p>
    <p><b>執行統籌</b><br>群像創造</p>
    <br />
    <h4>《迴響大本營》</h4>
    <p><b>網站開發</b><br>胡哲愷、阮琛婷</p>
    <p><b>美術＆設計</b><br>余韋呈</p>
    <p><b>專案管理</b><br>阮琛婷</p>
    <p><b>文案</b><br>簡瓅、劉建成、陳君芃</p>
    <p><b>腳本</b><br>胡哲愷、周鈺婷、阮琛婷</p>
    <p><b>配樂＆音效</b><br>史丹周</p>
    <p><b>測試</b><br>蔡旻軒、黃昱翔、郭軒綸、吳姿諭、古展弦、杜倩妮</p>
    <p><b>特別感謝</b><br>Dadable、Be There 不見不散、生活擺態、暗房裡的人、小紅帽、眾森、二手傢俱循環ok蹦、<br>酵母菌、探索引路人、異溫層溝通、失敗練習 Warm Up Again、漂泊俱樂部</p>
    <p><b>工程師的貓</b><br>Qbur</p>
    <br />
    <br />
    <br />
    <h4>《眾力小宇宙》</h4>
    <p><b>議題團隊</b><br>人生百味、好民文化協會、粉紅豹文化、壹零玖伍工作室、臺灣愛行動協會、數位外交協會</p>
    <p><b>青年團隊</b><br>巫癒子、漂泊俱樂部、一即是參 人文異術、失敗練習 Warm Up Again、大地之子 農夫黑皮膚</p>
    <p><b>責任編輯</b><br>好伴設計 周家緯、黃昱翔</p>
    <p><b>網頁開發</b><br>胡哲凱、阮琛婷</p>
    <p><b>美術＆設計</b><br>余韋呈</p>
    <p><b>專案管理</b><br>阮琛婷</p>
    <br />
    <br />
    <br />
    <h4>《任務包實戰基地》</h4>
    <p><b>任務包公開化執行</b><br>姚瑞鵬、王鵬傑</p>
    <p><b>網頁開發</b><br>酉是數據科技 王鵬傑、李心毅、李坤樸、陳彥求</p>
    <p><b>美術＆設計</b><br>酉是數據科技  黃婕茵</p>
    <br />
    <br />
    <br />
    <h4>《號召響應者指南》</h4>
    <p><b>編輯群</b><br>人生百味、好民文化協會、粉紅豹文化、壹零玖伍工作室、臺灣愛行動協會、數位外交協會</p>
    <p><b>責任編輯</b><br>好伴設計</p>
    <p><b>排版＆設計</b><br>游文瑀</p>
    <br />
    <br />
    <br />
    <h4>《2021 影響力報告書》</h4>
    <p><b>編輯群</b><br>簡瓅、劉建成、陳君芃、姚瑞鵬、王鵬傑</p>
    <p><b>排版＆設計</b><br>王品人</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <p><b>主辦機關</b><br>教育部青年發展署</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <p><b>承辦單位</b><br>Alpha Team</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <p><b>合作單位</b><br>D-School NTU 台大創新設計學院</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h5>© 2021 RethinkTaiwan2027 all rights reserved.</h5>
    <br />
    <br />
    <!-- <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br /> -->
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
