<template>
  <Modal :name="'team_1'" ref="modal" :isWhite="true">
    <!-- <p>很開心聽到你這麼說！</p> -->
    <br>
    <br>
    <h4>如果你想「看見」議題⋯</h4>
    <p>
      •　追蹤<a href="https://bit.ly/3klekUF" target="_blank">暗房裡的人</a>，定期會發布許多議題小補帖！（包含受刑／更生人故事、議題知識等）<br>
      •　影迷們推薦：Gordon Ramsay地獄廚神之監獄風雲、噬罪者、我們與惡的距離、我的兒子是死刑犯、惡之畫、鹹水雞的滋味
    </p>
    <br>
    <br>
    <h4>如果你想「同理」這群人⋯</h4>
    <p>
      •　看到犯罪新聞報導、產生情緒前，先想一下：為什麼他會犯罪？<br>
      •　聽到「更生人」這個詞時，先不要急著面露嫌惡、趕快逃走，你可以把他當作一般的陌生人，若有機會的話，給予一句關心、一句好奇都可能成為他的力量！
    </p>
    <br>
    <br>
    <h4>如果你想用「行動」參與議題⋯</h4>
    <p>
      •　參與<a href="https://bit.ly/2VDcPXt" target="_blank">監所關注小組</a>所舉辦的通信計畫，與受刑人交個筆友、互相認識吧！<br>
      •　拜訪<a href="https://bit.ly/3eqZwjv" target="_blank">利伯他茲教育基金會</a>所經營的社會企業，<a href="https://bit.ly/3erEHEz" target="_blank">七品聚圓夢餐廳</a>的更生大哥們非常友善呢～餐點也很好吃哦！<br>
      •　追蹤暗房裡的人 疫情更生路企劃，關心受到疫情影響的更生店家們
    </p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2019 暗房裡的人 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
