<template>
  <div class="keyboard" v-if="check_mobile()">
    <div class="arrow_keys" v-touch:end="cancel_move">
      <div class="arrow_up key_btn" name="ArrowUp" v-touch:start="touch_up_handler" v-touch:move="cancel_move">
        <img :src="require('../images/world/keyboard/arrow_key'+ (forbidden_arrow_key()? '_grey' : '') +'.svg')" draggable="false"/>
        <img class="focus_btn" src="../images/world/keyboard/arrow_key_active.svg" draggable="false"/>
      </div><hr />
      <div class="arrow_left key_btn" name="ArrowLeft" v-touch:start="touch_left_handler" v-touch:move="cancel_move">
        <img :src="require('../images/world/keyboard/arrow_key'+ (forbidden_arrow_key()? '_grey' : '') +'.svg')" draggable="false"/>
        <img class="focus_btn" src="../images/world/keyboard/arrow_key_active.svg" draggable="false"/>
      </div>
      <div class="arrow_right key_btn" name="ArrowRight" v-touch:start="touch_right_handler" v-touch:move="cancel_move">
        <img :src="require('../images/world/keyboard/arrow_key'+ (forbidden_arrow_key()? '_grey' : '') +'.svg')" draggable="false"/>
        <img class="focus_btn" src="../images/world/keyboard/arrow_key_active.svg" draggable="false"/>
      </div><hr />
      <div class="arrow_down key_btn" name="ArrowDown" v-touch:start="touch_down_handler" v-touch:move="cancel_move">
        <img :src="require('../images/world/keyboard/arrow_key'+ (forbidden_arrow_key()? '_grey' : '') +'.svg')" draggable="false"/>
        <img class="focus_btn" src="../images/world/keyboard/arrow_key_active.svg" draggable="false"/>
      </div>
    </div>
    <div class="munipulate_keys">
      <div class="A_key key_btn" v-touch="touch_A_handler">
        <img :src="require('../images/world/keyboard/A_key'+ (forbidden_A_key()? '_grey' : '') +'.svg')" draggable="false"/>
        <img class="focus_btn" src="../images/world/keyboard/A_key_active.svg" draggable="false"/>
      </div>
      <div class="B_key key_btn" v-touch="touch_B_handler">
        <img :src="require('../images/world/keyboard/B_key'+ (forbidden_B_key()? '_grey' : '') +'.svg')" draggable="false"/>
        <img class="focus_btn" src="../images/world/keyboard/B_key_active.svg" draggable="false"/>
      </div>
      <div class="esc_key key_btn" v-touch="touch_esc_handler">
        <img src="../images/world/keyboard/esc_key.svg" draggable="false"/>
        <img class="focus_btn" src="../images/world/keyboard/esc_key_active.svg" draggable="false"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { is_arrow_key, is_A_key, check_mobile } from '../js/map/global.js'

export default {
  name: 'Keyboard',
  data: function () {
    return {
      nowKey: '',
      timeId: {
        ArrowUp: 0,
        ArrowDown: 0,
        ArrowLeft: 0,
        ArrowRight: 0
      }
    }
  },
  computed: {
    ...mapState([
      'map', 'player', 'backpack', 'modal', 'functions', 'isInTeachingMode'
    ]),
    ...mapGetters([
      'whatIsPickingUp', 'treeAroundMe', 'ableToFish', 'digSthFromHole', 'getDogRunTime'
    ])
  },
  created: function() {
  },
  mounted: function() {
    this.mobile_initialize()
    document.addEventListener('keydown', this.press_no_delay_arrow_key); // for avatar move
    document.addEventListener('keydown', this.press_arrow_key);
    document.addEventListener('keyup', this.cancel_arrow_key_repeat);
    document.addEventListener('keyup', this.press_A);
    document.addEventListener('keydown', this.press_repeat_A);
    document.addEventListener('keyup', this.press_B);
    document.addEventListener('keyup', this.press_esc);
  },
  methods: {
    ...mapMutations([
      'setTeachingMode'
    ]),
    ...mapActions([
      'forbidActionForMillisecond', 'reminder'
      // , 'savePlayerStateInCookie'
    ]),
    check_mobile,
    mobile_initialize() {
      if (check_mobile()) {
        addEventListener('contextmenu', function(e){
          e.preventDefault();
        });
      }
    },
    forbidden_arrow_key() {
      return this.modal.isOpen
    },
    forbidden_A_key() {
      return this.backpack.isOpen
    },
    forbidden_B_key() {
      return this.modal.isOpen || this.player.interaction.nowDialog
    },
    touch_up_handler() {
      // console.log('touch_up')
      this.no_delay_arrow_key('ArrowUp') // for avatar run
      this.press_arrow_key({'code':'ArrowUp'})
    },
    touch_left_handler() {
      this.no_delay_arrow_key('ArrowLeft') // for avatar run
      this.press_arrow_key({'code':'ArrowLeft'})
    },
    touch_down_handler() {
      this.no_delay_arrow_key('ArrowDown') // for avatar run
      this.press_arrow_key({'code':'ArrowDown'})
    },
    touch_right_handler() {
      this.no_delay_arrow_key('ArrowRight') // for avatar run
      this.press_arrow_key({'code':'ArrowRight'})
    },
    touch_A_handler() {
      this.press_A({'code': 'KeyA'})
    },
    touch_B_handler() {
      this.press_B({'code': 'KeyB'})
    },
    touch_esc_handler() {
      this.press_esc({'code': 'Escape'})
    },
    cancel_move() {
      this.clear_up_and_down_move()
      this.clear_left_and_right_move()
    },
    cancel_arrow_key_repeat(e) {
      if (e.code == 'ArrowRight' || e.code == 'ArrowLeft') this.clear_left_and_right_move()
      if (e.code == 'ArrowUp' || e.code == 'ArrowDown') this.clear_up_and_down_move()
    },
    clear_up_and_down_move() {
      setTimeout(() => {
        clearInterval(this.timeId['ArrowUp'])
        clearInterval(this.timeId['ArrowDown'])
      }, 10)
    },
    clear_left_and_right_move() {
      setTimeout(() => {
        clearInterval(this.timeId['ArrowRight'])
        clearInterval(this.timeId['ArrowLeft'])
      }, 10)
    },
    press_no_delay_arrow_key(e) {
      if (!e.repeat) this.no_delay_arrow_key(e.code)
    },
    no_delay_arrow_key(key) {
      // if (this.nowKey && this.nowKey != key) {
        let timeKeys = Object.keys(this.timeId)
        for (let timeKey of timeKeys) {
          // all other time id stop
          clearInterval(this.timeId[timeKey])
        }
      // }
      // this.nowKey = key
      this.no_delay_arrow_key_function({'code': key, 'repeat': false}) // walk
      this.timeId[key] = setInterval(() => {
        this.no_delay_arrow_key_function({'code': key, 'repeat': true}) // run
      }, 180)
      // this.savePlayerStateInCookie()
    },
    no_delay_arrow_key_function(e) {
      if (is_arrow_key(e) && (!this.modal.isOpen && !this.player.interaction.isActing)) {
        if (this.player.state.isFirst && this.player.teachingStep == 1) {
          this.setTeachingMode(false)
          this.player.teachingStep++
          setTimeout(() => {
            this.reminder('外頭的霧太濃了⋯⋯先進去上面的山屋吧')
          }, 2000)
        }
        // this.savePlayerStateInCookie()
        if (!this.backpack.isOpen && !this.player.interaction.nowDialog) {
          this.functions.avatar.pressArrowKey(e)
          this.functions.NPCs.pressArrowKey(e)
        }
      }
    },
    press_arrow_key(e) {
      if (is_arrow_key(e) && (!this.modal.isOpen && !this.player.interaction.isActing)) {
        if (this.backpack.isOpen) {
          this.functions.backpack.pressArrowKey(e)
          // this.savePlayerStateInCookie()
        }
        else if (this.player.interaction.nowDialog) {
          this.functions.dialog.pressArrowKey(e)
          // this.savePlayerStateInCookie()
        }
      }
    },
    press_B(e) {
      if (e.code === 'KeyB' && !this.player.interaction.isActing && !this.isInTeachingMode) {
        if (!this.modal.isOpen) {
           // && !this.player.interaction.nowDialog
          this.functions.backpack.pressB(e)
        }
      }
    },
    press_repeat_A(e) {
      if ((is_A_key(e) && !this.player.interaction.isActing) || this.player.isTakingPicture) {
        if (this.modal.isOpen && !(this.player.state.isFirst && this.player.teachingStep == 2)) {
          this.functions.modal.pressDownA(e)
        }
      }
    },
    press_A(e) {
      if ((is_A_key(e) && !this.player.interaction.isActing) || this.player.isTakingPicture) {
        if (this.player.state.isFirst && this.player.teachingStep == 2) {
          if (this.player.avatar.x >= 158 && this.player.avatar.x <= 160) {
            if (this.player.avatar.y == 210) { // counter
              this.setTeachingMode(false)
              this.player.teachingStep++
            }
          }
        }
        if (this.modal.isOpen) {
          // note: move to 'press_repeat_A'
          this.functions.modal.pressUpA(e)
        }
        else if (this.backpack.isOpen) {
          this.functions.backpack.pressA(e)
        }
        else {
          if (this.player.interaction.nowDialog) { // in dialog now
            // keep dialog or end dialog
            // console.log('keyboard')
            this.functions.dialog.pressA(e)
            // note that this is for pick dialog. if I stand next tree or on item but still dialog, must close it first
          }
          else if (this.whatIsPickingUp) {
            // pick sth up
            this.functions.avatar.pressA(e) // pick up animation
            this.functions.drops.pressA(e)
            this.forbidActionForMillisecond(700)
          }
          else if (this.ableToFish) {
            this.functions.avatar.pressA(e)
            this.functions.objects.pressA(e)
          }
          else if (this.treeAroundMe) {
            // push tree
            this.functions.avatar.pressA(e) // push tree animation
            this.functions.objects.pressA(e)
            this.forbidActionForMillisecond(700)
          }
          else if (this.map.mission[this.player.avatar.x][this.player.avatar.y]) {
            // start dialog for mission or talking
            this.functions.dialog.pressA(e)
          }
          // else if (this.digSthFromHole) {
          //   this.functions.avatar.pressA(e)
          //   this.functions.objects.pressA(e)
          //   this.forbidActionForMillisecond(2000 * this.getDogRunTime + 1500)
          // }
        }
      }
    },
    press_esc(e) {
      if ((e.code === 'Escape' || e.code === 'KeyQ') && !this.player.interaction.isActing) {
        if (this.modal.isOpen) {
          this.functions.modal.pressEsc(e)
        }
        else if (this.backpack.isOpen) {
          this.functions.backpack.pressEsc(e)
        }
        else if (this.player.interaction.nowDialog) {
          this.functions.dialog.pressEsc(e)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/global';

.keyboard {
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 3000px);
  pointer-events: none;

  .key_btn {
    width: 50px;
    cursor: pointer;
    pointer-events: auto;
    // pointer-events: auto;
    img {
      pointer-events: none;
      width: 100%;
    }

    .focus_btn {
      display: none;
    }

    &:active {
      img {
        display: none;
      }
      .focus_btn {
        display: block;
      }
    }
  }

  .arrow_keys {
    position: absolute;
    bottom: 30px;
    // width: 50%;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-wrap: wrap;
    // -webkit-touch-callout:none;
    // background: red;
    width: 130px;
    left: 10%;

    .arrow_up {
      margin-bottom: -15px;
    }

    .arrow_left {
      margin-right: 15px;
      transform: rotate(270deg);
    }

    .arrow_right {
      margin-left: 15px;
      transform: rotate(90deg);
    }

    .arrow_down {
      margin-top: -15px;
      transform: rotate(180deg);
    }

  }

  .munipulate_keys {
    position: absolute;
    bottom: 30px;
    right: 10%;
    width: 110px;
    height: 100px;
    // transform-style: preserve-3d;

    .key_btn {
      position: absolute;
    }

    .A_key {
      bottom: 0;
      left: 0;
      // transform: translate3d(0, 0, 9999px);
    }

    .B_key {
      right: 30px;
    }

    .esc_key {
      right: 0;
      bottom: 0;
      // transform: translate3d(0, 0, 9999px);
      img {
        width: 84%;
      }
    }
  }

}

@media screen and (min-width: 700px) {
  // ipad
  .keyboard {
    .key_btn {
      width: 70px;
    }

    .arrow_keys {
      width: 210px;
      left: 4%;
    }

    .munipulate_keys {
      right: 4%;
      width: 150px;
      height: 136px;
    }
  }
}

</style>
