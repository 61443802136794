<template>
  <Modal :name="'ax_sync'" ref="modal" :isWhite="true">
    <h5><b>團隊需要同步的資訊很多，但每次會議似乎都很冗...怎麼辦？</b></h5>
    <br />
    <br />
    <p>女神也有資訊處理的極限，何況是人。</p>
    <p>會議的設計，是迴響常見的挑戰，因為大部分人為兼職工作，且許多專案同步進行，卻又需要互相連貫，避免品牌失了味道、鼓勵行動變成強迫行動，於是會議設計就成了關鍵。</p>
    <p>迴響的原則，是管他天要下雨娘要嫁人，先用輕鬆的話題更新每個人的狀況，讓我們感覺我們是在跟「人」開會，而不是跟「專案」追進度。</p>
    <p>其次，留一個時間讓所有人現場更新一下工作狀況（通常會事前請大家填寫，但，你知道的...人性），並整理待會兒要跨組溝通的事項。先填寫完的，可以先看其他人的內容，這個階段結束再讓每個人分享（如果有需要跨組溝通的話）</p>
    <p>最後會進入議程，迴響會先用這個 <a href="https://forms.gle/cWctYfzTD276JzNfA" target="_blank">表單</a> 蒐集大家的議程，並用一個演算法分配時間讓全體的議程先，小議程分別處理。</p>
    <p>如此，開完大議程的會議，如果沒有小議程的人，可以先行休息或視需求加入其他議程。</p>
    <p>這裡是一份迴響會議記錄的範本，讓你宛如親身經歷：<a href="https://docs.google.com/document/d/1aSsf9JxlKBRH5kFOl3nwpQYLsN7s1MWpSIfzjalCQZM/edit" target="_blank">20201014_全員到齊</a></p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
