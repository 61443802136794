const RethinkIntro = {
  70: { "npcDialog": "首先要澄清！我們「不是」創業競賽，而是以更好的社會為目標的<mark>行動孵化計畫</mark>。", "player": [ { "next": "71", text: '行動孵化計畫？' } ] } ,
  71: { "npcDialog": "是的！只要提出你在意的<mark>議題</mark>，即便很模糊，迴響會提供<mark>資源</mark>，陪你一步步把它變得清晰。", "player": [ { text: '可以提什麼議題？', "next": "710"}, { text: '會提供什麼資源？', "next": "711" } ] } ,
  710: { "npcDialog": "流浪動物、身心疾病、教育、媒體、環保，甚至農業、女性不穿內衣⋯⋯都是過往參賽團隊曾提出的議題！", "player": [ { text: '原來如此', "next": "73" }, { text: '會提供什麼資源？', "next": "711" } ] } ,
  711: { "npcDialog": "從問題定義，一路到驗證解方的<mark>行動方法論</mark>，還有前輩<mark>行動夥伴</mark>跟<mark>設計思考教練</mark>的陪伴！", "player": [ { text: '原來如此', "next": "73" }, { text: '可以提什麼議題？', "next": "710"} ] } ,
  73: { "npcDialog": "你所身處的 RPG 世界，就是迴響四年來所累積的點點滴滴，你能藉由探索這個世界更深入了解我們！", "player": [ { "next": 75, "text": "我知道了！" }, { next: 74, "text": "想看更多資訊" } ] },
  74: { "npcDialog": "如果想要取得更多即時資訊可以到迴響計畫的<a href='https://www.facebook.com/RethinkTaiwan2027' target='_blank'>紛絲專頁</a>，或者到<a href='http://rethinktaiwan.com/' target='_blank'>官網入口頁</a>，可以看見許多不同主題唷！", "player": [ { "text": "我知道了！", next: 75 } ] } ,
  // 10: { "npcDialog": "青年迴響計畫是一個為期三個月的<mark>行動孵化計畫</mark>，至今已是第四屆，一路走來誕生了許多行動團隊⋯⋯", "player": [ { "next": 11 } ] } ,
  // 11: { "npcDialog": "只要告訴我們「<mark>你看見的社會問題</mark>」，即便很模糊，迴響也會陪你一步步把它變得清晰。", "player": [ { "next": 12, "text": "跟創業比賽有什麼不同？" } ] } ,
  // 12: { "npcDialog": "我們不要求你的想法能賺錢，不需要你的計畫夠完整，只要你有想要行動的心，我們就給你一個<mark>行動的藉口！</mark>", "player": [ { "text": "想要逛展！", next: 14 }, { "text": "想要了解更多！", next: 13 } ] } ,
}

const GodBasic = {
  0: { "npcDialog": "我是迴響大神，是迴響計畫第一屆，由執行團隊們構思出來的<mark>虛擬神祇</mark>。", "player": [ { "next": 100 } ], changeName: '迴響大神' } ,
  100: { "npcDialog": "看似詼諧的存在，其實是為了淡化「主辦單位」這種象徵，把更多的能動性交還給青年。", "player": [ { "next": 6 } ], changeName: '迴響大神' } ,
  // 1: { "npcDialog": "迴響團隊希望藉由「非主辦單位」的一個更高的力量，凝聚大家，讓大家一起自發的做點什麼。", "player": [ { "next": 2 } ], changeName: '迴響大神' } ,
  // 2: { "npcDialog": "因而，我誕生在世上，引領著青年們宣誓。", "player": [ { "next": 3 } ], changeName: '迴響大神' } ,
  // 3: { "npcDialog": "想一想，如果是一個具體的人類組織，在計畫的開始掌握號召大家的權力⋯⋯", "player": [ { "next": 4 } ], changeName: '迴響大神' } ,
  // 4: { "npcDialog": "這個權力就不容易移轉，無法在青年之間流動。", "player": [ { "next": 5 } ], changeName: '迴響大神' } ,
  // 5: { "npcDialog": "這個權力是什麼呢？就是「<mark>可以做些什麼</mark>」的意念。", "player": [ { "next": 6 } ], changeName: '迴響大神' } ,
  6: { "npcDialog": "在迴響裡，這個核心是一種自然而然覺得，自己<mark>可以做些什麼</mark>的信念。", "player": [ { "next": 7 } ], changeName: '迴響大神' } ,
  7: { "npcDialog": "不需要道理，不用辯解也不怕被奪去。像地球轉動、宇宙擴張那樣，源自於很根本、無從解釋的<mark>能動性</mark>。", "player": [ { "next": 9 } ], changeName: '迴響大神' } ,
  // 8: { "npcDialog": "", "player": [ { "next": 9 } ], changeName: '迴響大神' } ,
  9: { "npcDialog": "迴響大本營其實就是一個<mark>隱喻</mark>，森林裡鮮活的所有東西，都被「可以做些什麼」的意念滋養著。", "player": [ { "next": 101 } ], changeName: '迴響大神' } ,
  101: { "npcDialog": "為的是想告訴你，不論在這裡或是現實世界，只要願意<mark>行動</mark>，其實你真的「可以做些什麼」。", "player": [ { "next": 14 } ], changeName: '迴響大神' } ,
  // 10: { "npcDialog": "為的是支持在網站裡來去的你，也能在過程裡感到「<mark>可以做些什麼</mark>」。", "player": [ { "next": 14 } ], changeName: '迴響大神' } ,
  // 11: { "npcDialog": "回想上一次，你興高采烈的提議，卻被周遭的人拒絕的經驗。", "player": [ { "next": 12 } ], changeName: '迴響大神' } ,
  // 12: { "npcDialog": "其實沒有任何事情真的阻擋你去嘗試，但，結果應該很明顯。", "player": [ { "next": 13 } ], changeName: '迴響大神' } ,
  // 13: { "npcDialog": "可見，我們是多麼需要被<mark>支持</mark>。", "player": [ { "next": 14 } ], changeName: '迴響大神' } ,
  14: { "npcDialog": "謹以最後這段對話，紀念曾一起共事過的人們，也謝謝你，在迴響大本營裡走到這裡。", "player": [ { "next": 15 } ], changeName: '迴響大神' } ,
}

const StoneBasic = {
  // 0: { "npcDialog": "這座山上似乎散佈著不少大石頭？⋯⋯", "changeName": "player" }
  0: { "npcDialog": "⋯⋯", "changeName": "石頭" }
}

const PartnerIntroBasic = {
  200: { "npcDialog": "咦！還沒有人和你介紹過嗎？", "player": [ { "next": 201, "text": "沒有欸⋯麻煩了！" }, { "text": "呃⋯好像有，那沒事了！" }, ] },
  201: { "npcDialog": "迴響邀請了一群社會上比較早開始行動的人們，叫<mark>行動夥伴</mark>。", "player": [ { "next": 202 } ] } ,
  202: { "npcDialog": "算是一群<mark>投身社會創新領域 3-5 年</mark>的前輩。", "player": [ { "next": 203 } ] } ,
  203: { "npcDialog": "因為<mark>剛起步不久</mark>，能體會行動者們的挫折、迷惘、警喜和成就。", "player": [ { "next": 204 } ] } ,
  204: { "npcDialog": "也因為已行動過一陣子了，能站在剛起步行動的團隊前面一些<mark>多指引一步</mark>。", "player": [ { "text": "原來如此，想了解更多！", "modal": "s1_partners" }, { "text": "已了解，謝啦！", "next": 101 } ] },
}

const PartnerWinkBasic = {
  0: { "npcDialog": "哈囉！！！我是迴響行動夥伴<mark>張維</mark>！（眨眼）", "player": [ { "text": "妳是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] } ,

  // 想聊聊天
  1: { "npcDialog": "想和我聊什麼？", "player": [ { "text": "想對迴響青年說？", "next": 40 }, { "text": "第一年來迴響心得是？", "next": 20 }, { "text": "聽說你結婚了！而且⋯⋯", "next": 30 } ] },

  // 自我介紹
  10: { "npcDialog": "目前是非營利組織「<a href='https://www.facebook.com/zazaeye.tw' target='_blank'>ZAZA 眨眨眼</a>」的創辦人哦～", "player": [ { next: 11 } ] },
  11: { "npcDialog": "今年是我第一次來擔任迴響的行動夥伴！超開心的～（眨眼）", "player": [ { next: 101 } ] },


  // 第一年來迴響心得是？
  20: { "npcDialog": "哦～這個嘛⋯⋯要聊到我其實在大學時，就有很多參加<mark>創業競賽</mark>的經驗了！", "player": [ { "next": 21 } ] } ,
  21: { "npcDialog": "當初蠻幸運的，得到了很多成果。<br>不過競賽氛圍大多是<mark>勝者為王，敗者為寇</mark>的氛圍。", "player": [ { "next": 22 } ] } ,
  22: { "npcDialog": "現在做<a href='https://www.facebook.com/zazaeye.tw' target='_blank'>眨眨眼</a>，漸漸瞭解到很多重要的經驗<mark>不一定</mark>是成功經驗。", "player": [ { "next": 23 } ] } ,
  23: { "npcDialog": "反而是摔得鼻青臉腫，知道自己<mark>有所不能</mark>的這些經驗。", "player": [ { "next": 24 } ] } ,
  24: { "npcDialog": "而在迴響的氛圍是，<br>有人可以跑起來，有人可以跌倒一下，都被<mark>均等</mark>地看待。", "player": [ { "next": 25 } ] } ,
  25: { "npcDialog": "行動夥伴的角色就是<mark>好好的陪伴</mark>！我覺得這個是在迴響看到最感動的部分～", "player": [ { "next": 101, "text": "酷！好棒的分享" } ] } ,

  // 聽說你結婚了！而且⋯⋯
  30: { "npcDialog": "哈哈哈！對啊 🧡 山頂上那個拍照的人是我老公！大家都叫他阿頭～正在經營概念啊！", "player": [ { "next": 31 } ] } ,
  31: { "npcDialog": "準備好 2 顆小小人種子去找他，就可以幫你拍美美的登頂照哦！", "player": [ { "next": 32, "text": "小小人種子？" } ] } ,
  32: { "npcDialog": "你不知道種子嗎？（眨眼眨眼）⋯⋯去樹下按 A 玩玩看吧！", "player": [ { "text": "好耶！謝謝", next: 101 } ] } ,

  //想對迴響青年說？
  40: { "npcDialog": "人生有許多遊戲關卡，我們一起玩！", "player": [ { "next": 101, text: "好～～" } ] },

  // 提示亮亮的是釣點

  ...PartnerIntroBasic,
}
const PartnerCallsBasic = {
  0: { "npcDialog": "哈囉，我是迴響行動夥伴<mark>子鈞</mark>。<br>", "player": [ { "text": "你是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] } ,

  // 想聊聊天
  1: { "npcDialog": "想和我聊什麼？", "player": [ { "text": "給行動青年的話？", "next": 20 }, { "text": "最近出書了？", "next": 30 } ] },

  // 自我介紹
  10: { "npcDialog": "我是 NPO <a href='https://www.callsoverridges.org/about' target='_blank'>遠山呼喚</a>共同創辦人，今年是我第一次擔任迴響的行動夥伴，請多指教！", "player": [ { next: 11, 'text': "創辦故事？" } ] },
  11: { "npcDialog": "源自大二那年對未來感到<mark>迷惘</mark>，對生活感到<mark>麻木</mark>⋯⋯，於是休學了。", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "那時選擇走上眾人眼中的異途，到尼泊爾震央投入偏鄉種植教育，也創辦了「遠山呼喚」。", "player": [ { "next": 13 } ] } ,
  13: { "npcDialog": "在<mark>追逐理想</mark>的過程中，有許多解答因此越來越清晰⋯⋯", "player": [ { "next": 14 } ] } ,
  14: { "npcDialog": "於是也把過程的傷疤，又苦又痛的經驗都寫成書了。《<a href='https://www.google.com/search?q=%E8%88%87%E5%85%B6%E9%BA%BB%E6%9C%A8%E5%89%8D%E9%80%B2%EF%BC%8C%E4%B8%8D%E5%A6%82%E5%8B%87%E6%95%A2%E8%BF%B7%E5%A4%B1' target='_blank'>與其麻木前進，不如勇敢迷失</a>》，分享給你。", "player": [ { "next": 15, text: "謝謝" } ] } ,
  15: { "npcDialog": "你也是<mark>渴望改變</mark>的人嗎？", "player": [ { "next": 16, "text": "應該是吧！" }, { "text": "不太確定⋯", "next": 16 } ] } ,
  16: { "npcDialog": "一起用<mark>行動</mark>衝撞這個世界！", "player": [ { "next": 101 } ] } ,

  // 給行動青年的話？
  20: { "npcDialog": "與其麻木前進，不如勇敢迷失。", "player": [ { "next": 21 } ] },
  21: { "npcDialog": "如果夢想無比重要，十年一夢又如何？", "player": [ { "next": 101 } ] },

  // 最近出書了？
  30: { "npcDialog": "對的！最近出了一本書《<a href='https://www.books.com.tw/products/0010893410' target='_blank'>與其麻木前進，不如勇敢迷失</a>》。", "player": [ { "next": 31, "text": "是怎麼樣的書呢？" } ] },
  31: { "npcDialog": "裡面紀錄了我自己這五年來，面對種種抉擇的冒險日記。推薦給行動中迷惘的青年。", "player": [ { "next": 32, "text": "原來如此！" } ] },
  32: { "npcDialog": "當你<mark>無懼地劃過夜空</mark>，成為一道<mark>獨特的生命流光</mark>⋯⋯", "player": [ { "next": 33 } ] },
  33: { "npcDialog": "許多人的生命也正在<mark>被你點亮</mark>。", "player": [ { "next": 101, "text": "好美的句子⋯⋯" } ] },

  ...PartnerIntroBasic,
}
const PartnerLeoBasic = {
  0: { "npcDialog": "嘿！我是迴響行動夥伴 <mark>LEO</mark>！", "player": [ { "text": "你是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] } ,

  // 想聊聊天
  1: { "npcDialog": "想聊什麼？", "player": [ { "text": "給青年一句話？", "next": 20 }, { "text": "你覺得迴響計畫？", "next": 30 } ] },

  // 自我介紹
  10: { "npcDialog": "我是<a href='https://trustcafe.com.tw/menu/' target='_blank'>創咖啡</a>創辦人，是個以咖啡館為載體⋯⋯", "player": [ { "next": 11 } ] },
  11: { "npcDialog": "背後是致力於推廣創新思考與創業家精神的新創孵化平台！", "player": [ { "next": 101 } ] },

  // 給青年一句話？
  20: { "npcDialog": "有空，帶著你的問題、需要的資源，來<a href='https://www.facebook.com/trustcafe/' target='_blank'>創咖啡</a>坐坐吧！", "player": [ { "next": 21 } ] } ,
  21: { "npcDialog": "這裡有很多可能！<mark>行動青年，我們挺你！</mark>", "player": [ { "next": 101, 'text': '謝謝創咖啡⋯⋯我會的！' } ] } ,

  // 你覺得迴響計畫？
  30: { "npcDialog": "我覺得迴響是<mark>很用心</mark>的青年提案計畫啊！迴響是可能改變台灣未來的<mark>年輕人舞台</mark>！", "player": [ { "next": 31 } ] } ,
  31: { "npcDialog": "三屆的時候預算比較不理想，我覺得很問號啊！好的投資標的應該要<mark>加碼</mark>才對！", "player": [ { "next": 32, "text": "！" } ] } ,
  32: { "npcDialog": "抱歉抱歉，太激動了⋯⋯", "player": [ { "next": 33 } ] } ,
  33: { "npcDialog": "我只是希望，台灣那麼多熱血的年輕人，可以真的發芽，甚至<mark>持續灌溉</mark>到變成大樹！", "player": [ { "next": 34 } ] } ,
  34: { "npcDialog": "有空，帶著你的問題、需要的資源，來<a href='https://www.facebook.com/trustcafe/' target='_blank'>創咖啡</a>坐坐吧！", "player": [ { "next": 101, 'text': '謝謝創咖啡⋯⋯我會的！' } ] } ,

  ...PartnerIntroBasic,
}
const PartnerPleyBasic = {
  0: { "npcDialog": "嘿！我是迴響行動夥伴<mark>阿屁</mark>。我光腳沒關係吧？", "player": [ { "next": 1, "text": "好像還真的沒關係" } ] } ,
  1: { "npcDialog": "好啊，我們要怎麼交流？就直接<mark>直話直說</mark>吧！", "player": [ { "next": 2, "text": "會怎麼指引行動中的青年？" }, { "text": "聽說成為爸爸了，恭喜！", "next": "9" }, { "text": "行動夥伴是⋯？", "next": 200 } ] } ,

  // 會怎麼指引行動中的青年？
  2: { "npcDialog": "當然先問，你，為什麼要做這件事？", "player": [ { "next": 3 } ] } ,
  3: { "npcDialog": "或是，更直接一點，這件事你<mark>不做的話，會死嗎？</mark>", "player": [ { "next": 4, "text": "哦⋯" } ] } ,
  4: { "npcDialog": "不是要馬上回答啦！", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "我跟團隊聊的時候，很常不是在討論進度，而是在聊<mark>內在狀態</mark>。", "player": [ { "next": 6, "text": "！" } ] } ,
  6: { "npcDialog": "有時候很急，反而可以<mark>試著緩緩</mark>、蹲一下。", "player": [ { "next": 7 } ] } ,
  7: { "npcDialog": "不要去想別人，而是想<mark>自己想要什麼</mark>，去更貼近自己。", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "去行動吧！<mark>我陪你一起行動</mark>！", "player": [ { "next": 101, "text": "太感人了⋯" } ] } ,

  // 聽說成為爸爸了，恭喜！
  9: { "npcDialog": "對啊，去年來迴響是人夫，今年是爸爸了！", "player": [ { "next": 10 } ] } ,
  10: { "npcDialog": "說到這個，想跟你分享今年在迴響發生的<mark>小故事</mark>，", "player": [ { "next": 11, "text": "請說！" } ] } ,
  11: { "npcDialog": "今年整天大會師活動，我的兒子和太太都在旁邊，", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "到結業式時，兒子已經超過五個小時<mark>沒睡</mark>，快沒電了！", "player": [ { "next": 13 } ] } ,
  13: { "npcDialog": "大家陸續分享感動，我們都聽得享受，但每次分享後的齊聲鼓掌都會讓<mark>兒子嚇一跳</mark>，連帶我也緊張心疼起來。", "player": [ { "next": 14 } ] } ,
  14: { "npcDialog": "當時我鼓起勇氣舉手說：「可以幫我個忙嗎？兒子快沒電，用<mark>撒花手勢取代鼓掌</mark>好嗎？」", "player": [ { "next": 15 } ] } ,
  15: { "npcDialog": "我很信任<mark>迴響的 DNA 與文化</mark>，所以我知道這個地方大家接的住。", "player": [ { "next": 16 } ] } ,
  16: { "npcDialog": "這個請求其實也是我的「<mark>行動</mark>」，兒子出生後，我也想讓他參與這個我喜歡的聚會", "player": [ { "next": 17 } ] } ,
  17: { "npcDialog": "我想試著撐起一個「<mark>不再需要做出工作與育兒選擇</mark>」的空間", "player": [ { "next": 18 } ] } ,
  18: { "npcDialog": "撐起一個「不再是爸爸在台前，而妻小只能在後頭躲著、怕打擾到大家」的空間", "player": [ { "next": 19 } ] } ,
  19: { "npcDialog": "這是我今年的行動故事，和你分享⋯", "player": [ { "next": 101, "text": "太感人了⋯⋯" } ] } ,

  ...PartnerIntroBasic,
}
const PartnerHsuBasic = {
  0: { "npcDialog": "嗨！我是迴響行動夥伴<mark>許皓甯</mark>。", "player": [ { "text": "你是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] } ,

  // 想聊聊天
  1: { "npcDialog": "想和我聊什麼？", "player": [ { "text": "最近在做什麼？", "next": 20 }, { "text": "給行動青年一句話？", "next": 30 } ] },

  // 自我介紹
  10: { "npcDialog": "我是中醫師、<a href='https://www.facebook.com/ComplexFestival' target='_blank'>複雜生活節</a>發起人，也是獨立書店<a href='https://www.facebook.com/Reference.bookstore' target='_blank'>引書店</a>老闆。", "player": [ { "text": "好斜槓", "next": 11 }, { "text": "時代力量也有許皓甯", "next": 11 } ] },
  11: { "npcDialog": "恩對，那位也是我！", "player": [ { "next": 101, "text": "原來如此（好斜槓！）" } ] },

  // 最近在做什麼？
  20: { "npcDialog": "最近阿，我給自己出了一個題目⋯⋯", "player": [ { "next": 21, "text": "題目？那是什麼" } ] },
  21: { "npcDialog": "希望可以達成<mark>平衡</mark>吧！", "player": [ { "next": 22 } ] },
  22: { "npcDialog": "我希望維持自己是一個<mark>聰明友善的大人</mark><br>既看重<mark>可行性</mark>，也看重<mark>冒險的決心</mark>！", "player": [ { "next": 23, "text": "Woow" } ] } ,
  23: { "npcDialog": "假設自己的工作室發展太好，導致<mark>創造力</mark>下降，就不是我期望的⋯⋯", "player": [ { "next": 24 } ] } ,
  24: { "npcDialog": "<mark>要嚴肅也要樂在其中</mark>，這些都要<mark>平衡</mark>！", "player": [ { "next": 25 } ] } ,
  25: { "npcDialog": "今年來當迴響行動夥伴，接觸新的團隊，也是我在<mark>檢視自己的熱情</mark>。", "player": [ { "next": 26 } ] } ,
  26: { "npcDialog": "檢測我是不是還能提出有趣的解方，持續知道新的東西，隨著年紀增長，也<mark>不改變自己的初心</mark>。", "player": [ { "next": 27, "text": "原來如此" } ] } ,
  27: { "npcDialog": "這只是我小小的題目，那你呢？你<mark>給自己的題目</mark>會是什麼？", "player": [ { "next": 101, "text": "恩⋯我會想想的" } ] } ,

  // 來點行動指引
  30: { "npcDialog": "行動中的青年啊，盡可能試著⋯⋯", "player": [ { "next": 31 } ] } ,
  31: { "npcDialog": "發揮現今優勢，迎擊過往劣勢吧！", "player": [ { "next": 32, "text": "會試試！" } ] } ,
  32: { "npcDialog": "恩，加油！", "player": [ { "next": 101 } ] } ,

  // 很多人都會提到第一階生活節，很多人都還是說第一屆的那個晚上
  // 很久很久以前我看到 Tedtax，心中產生一種困惑，如果要辦類似的論壇行演講，為什麼我要自己辦？人家已經做到這個程度，要馬就是貼牌說自己是 Tedx***，那陣字我很喜歡跟朋友去辦營隊活動，
  // 他影響我很大，最難聽的富平
  // 為什麼營隊不可以翹課？身為營隊愛好者，講師找這個程度，幹嘛不來，如果我今天花自己的錢和假期，結果我還不能自己決定自己的進出，我就想去寫筆記請假，這樣是禁止的，使用者不是最重要的，反而主辦方

  ...PartnerIntroBasic,
}
const PartnerSunnyrushBasic = {
  0: { "npcDialog": "你好，我是迴響行動夥伴<mark>怡雅</mark>！", "player": [ { "text": "妳是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] },

  // 想聊聊天
  1: { "npcDialog": "想和我聊什麼？", "player": [ { "next": 20, "text": "給行動青年的話？" }, { "next": 40, "text": "鐵軌上的風景真美欸！" }, { "next": 30, "text": "為什麼願意當四屆行動夥伴？" }, { "next": 200, "text": "行動夥伴是⋯？" } ] } ,

  // 自我介紹
  10: { "npcDialog": "我是<a href='https://www.sunnyrush.com/' target='_blank'>藺子</a>的創辦人～", "player": [ { next: 11 } ] },
  11: { "npcDialog": "擔任迴響的行動夥伴也到第四年了，是老朋友囉！", "player": [ { next: 101 } ] },

  // 給行動青年的話？
  20: { "npcDialog": "唔⋯⋯我覺得行動真的很不容易、很辛苦。", "player": [ { next: 21 } ] },
  21: { "npcDialog": "但我覺得呢，我們只要努力的去做好我們<mark>相信</mark>的事情就好！", "player": [ { next: 101, text: '好溫柔的行動夥伴 > <' } ] },

  // 為什麼願意當四屆行動夥伴？
  30: { "npcDialog": "我從第一年就開始當行動夥伴，幾乎每次大會師都會因太感動而哭⋯⋯", "player": [ { "next": 31 } ] } ,
  31: { "npcDialog": "那個場合總會遇見好多團隊，大家不一定成功，但都很有<mark>熱情</mark>、<mark>理想</mark>。", "player": [ { "next": 32 } ] } ,
  32: { "npcDialog": "坦白說自己在做的事情，有時候會感到很無力，覺得<mark>熱情快被澆熄</mark>⋯⋯", "player": [ { "next": 33 } ] } ,
  33: { "npcDialog": "但在迴響看到其實世界上還有很多跟我們<mark>一樣</mark>的人，就會覺得還可以<mark>走下去</mark>。<br>這也是我每年回來的原因！只要迴響有邀請我就想來！", "player": [ { "next": 101, "text": "也太感人⋯⋯" } ] } ,

  // 走在鐵軌上的風景真美
  40: { "npcDialog": "真的～你是第一次來這嗎？鐵軌<mark>兩頭的山洞風景</mark>也都很美哦！", "player": [ { "next": 41 } ] } ,
  41: { "npcDialog": "雖然聽說有<mark>熊</mark>會在附近出沒⋯⋯但沒聽說過受傷事件。還是要注意一下安全哦！", "player": [ { "next": 42 } ] } ,
  42: { "npcDialog": "欣賞完<mark>兩頭的山洞風景</mark>，一定要繼續往上爬哦！", "player": [ { "next": 43 } ] } ,
  43: { "npcDialog": "<mark>行動大山的山頂風景</mark>，此生一定要去一趟呢！", "player": [ { "next": 101 } ] } ,

  ...PartnerIntroBasic,
}
const PartnerFlaneurBasic = {
  0: { "npcDialog": "嗨！我是迴響行動夥伴<mark>維綱</mark>。", "player": [ { "text": "你是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] } ,

  // 想聊聊天
  1: { "npcDialog": "想和我聊什麼？", "player": [ { "text": "來點行動指引？", "next": 20 }, { "text": "今年有哪些超展開？", "next": 30 }, { "text": "為什麼來當行動夥伴？", "next": 40 }, { "text": "你在拍什麼？", "next": 50 } ] },

  // 自我介紹
  10: { "npcDialog": "我是共生住宅<a href='https://9floor.co/' target='_blank'>玖樓</a>的創辦人之一，雖然前陣子已經離開囉～", "player": [ { next: 11 } ] },
  11: { "npcDialog": "我來迴響當了四年行動夥伴欸！算是老朋友了。", "player": [ { next: 101 } ] },

  // 來點行動指引？
  20: { "npcDialog": "還有甚麼問題？就是這一步，和下一步，然後再多問自己問題！", "player": [ { "next": 101 } ] } ,

  // 今年有哪些超展開？
  30: { "npcDialog": "喔～我出書了你有 follow 上嗎？《<a href='https://www.books.com.tw/products/0010858386' target='_blank'>借一段有你的時光：我們用青春打造的城市風景</a>》", "player": [ { "next": 31 } ] } ,
  31: { "npcDialog": "也歡迎來 follow 我的 <a href='https://www.instagram.com/cityflaneurs/' target='_blank'>IG - 城市漫遊者 cityflaneurs</a>！", "player": [ { "next": 101 } ] } ,

  // 為什麼來擔行動夥伴？
  40: { "npcDialog": "其實每屆的<mark>心態不太一樣</mark>，今年呢，是因為可以接觸到年輕世代！", "player": [ { "next": 41 } ] } ,
  41: { "npcDialog": "從第一屆的題目看到第四屆，可以看到趨勢，像我有觀察到<mark>心靈療癒</mark>的題目變多了。", "player": [ { "next": 42 } ] } ,
  42: { "npcDialog": "了解這個城市<mark>在乎什麼</mark>，對我來說就是重要的 Insight。", "player": [ { "next": 43 } ] } ,
  43: { "npcDialog": "再來是，也很期待每年可以找一些有趣的團隊，來我的<mark>空間</mark>合作看看！", "player": [ { "next": 44 } ] } ,
  44: { "npcDialog": "我有時也會幫他們拍拍美照作為禮物，對後續<mark>品牌建立</mark>很有幫助。", "player": [ { "next": 101, "text": "很棒欸！" }, { "text": "好讚！希望有一天也能請你拍照！", "next": 101 } ] } ,

  // 你在拍什麼？
  50: { "npcDialog": "那個方向是<mark>迴響之森</mark>，一生一定要去探險一回的美麗森林！", "player": [ { "next": 51 } ] } ,
  51: { "npcDialog": "那裡生氣蓬勃，不只是眾多生物的聚集地，還駐紮了 3 座<mark>小小人行動工坊</mark>⋯⋯", "player": [ { "next": 52 } ] } ,
  52: { "npcDialog": "等我爬完行動大山後，就會過去一趟。<br>你呢？會在那見到你嗎？", "player": [ { "next": 101 } ] } ,

  ...PartnerIntroBasic,
}
const PartnerHappenBasic = {
  0: { "npcDialog": "嗨～我是迴響行動夥伴<mark>家緯</mark>！", "player": [ { "text": "你是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] } ,

  // 想聊聊天
  1: { "npcDialog": "想聊什麼？", "player": [ { "text": "為什麼在這？", "next": 20 }, { "text": "如何看待失敗的行動？", "next": 40 }, { "text": "隨便閒聊", "next": 30 } ] },

  // 自我介紹
  10: { "npcDialog": "我是<a href='https://www.facebook.com/HappenSocialDesign/' target='_blank'>好伴社計</a>的創辦人之一。", "player": [ { "next": 11 } ] },
  11: { "npcDialog": "之前就有默默在 follow 迴響，但今年是我第一次參與其中！而且第一次就是<mark>執行團隊</mark>兼<mark>行動夥伴</mark>呢！", "player": [ { "next": 101 } ] },

  // 為什麼在這？
  20: { "npcDialog": "這裡是 2020 年，與世界接軌的一年啊！", "player": [ { "next": 21 } ] },
  21: { "npcDialog": "我負責這屆迴響的「號召」新嘗試，和 6 組議題團隊研發了<a href='https://drive.google.com/file/d/1WI-IctXuhBl5vqHscDNLzPRHnoYqPNIW/view' target='_blank'>號召響應者指南</a>⋯⋯", "player": [ { "next": 22 } ] },
  22: { "npcDialog": "也和 11 組行動團隊一起進到「號召」階段，歡迎來<a href='http://www.rethinktaiwan.com/universe_exhibition/plans' target='_blank'>眾力小宇宙成果展</a>晃晃！", "player": [ { "next": 101 } ] },

  // 如何看待失敗的行動？
  40: { "npcDialog": "我自己是那種，要<mark>親身</mark>經歷過失敗經驗才罷休的人。<br>沒吃過屎，不會知道屎難吃啦！", "player": [ { "next": 41, "text": "是⋯是的！（屎 > < ⋯⋯）" } ] } ,
  41: { "npcDialog": "迴響讓青年可以在計畫中直接<mark>呈現失敗</mark>，我認為是一種<mark>尊重青年</mark>的方式。", "player": [ { "next": 42 } ] } ,
  42: { "npcDialog": "讓青年更容易有這種<mark>用行動去親身經歷</mark>的精神，對自己的課業、生活，真的都是很重要的事！", "player": [ { "next": 101, "text": "恩⋯能遇到家緯這樣的前輩真的很幸福！" } ] } ,

  // 隨便閒聊
  30: { "npcDialog": "雖然這樣講好像有點中二⋯⋯", "player": [ { "next": 31, "text": "？" } ] },
  31: { "npcDialog": "但你們已經比當年的我還要<mark>勇敢</mark>了，我當年的綽號可是<a href='https://www.google.com/search?q=%E9%9C%B8%E7%8E%8B%E7%B6%A0&rlz=1C5CHFA_enTW900TW900&oq=%E9%9C%B8%E7%8E%8B%E7%B6%A0' target='blank'>霸王綠</a>！", "player": [ { "next": 101 } ] } ,

  ...PartnerIntroBasic,
}
const PartnerBankCultureBasic = {
  0: { "npcDialog": "哈囉～～我是迴響行動夥伴<mark>璦婷</mark>～", "player": [ { "text": "妳是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] },

  // 想聊聊天
  1: { "npcDialog": "想和我聊什麼？", "player": [ { "next": 20, "text": "給行動青年的提醒？" }, { "next": 30, "text": "為什麼來當行動夥伴？" }, { "next": 40, "text": "來個⋯行動指引？" }] } ,

  // 自我介紹
  10: { "npcDialog": "我是<a href='http://bankofculture.com/' target='_blank'>文化銀行</a>的創辦人。", "player": [ { next: 11 } ] },
  11: { "npcDialog": "這是我第四年擔任行動夥伴了，雖然很認同迴響也喜歡回來⋯⋯", "player": [ { next: 12 } ] },
  12: { "npcDialog": "但還是期待下屆能有行動夥伴新血，能取代我呢！", "player": [ { next: 101 } ] },

  // 給行動青年的提醒？
  20: { "npcDialog": "我覺得行動有很多種可能⋯⋯", "player": [ { next: 21 } ] },
  21: { "npcDialog": "可以大膽一點，不要只是想開粉專！", "player": [ { next: 101 } ] },

  // 為什麼來當行動夥伴？
  30: { "npcDialog": "我從第一屆到現在都很<mark>認同迴響</mark>，跟我創業的過程很類似。", "player": [ { "next": 2 } ] } ,
  31: { "npcDialog": "當時什麼都不懂，<mark>只是一個 Idea 跟夥伴</mark>，就到處闖。", "player": [ { "next": 3 } ] } ,
  32: { "npcDialog": "經歷的這些過程，對某些人應該也有<mark>幫助</mark>吧！尤其是像我這種行動派的。", "player": [ { "next": 101, "text": "哇⋯⋯酷！" } ] } ,

  // 來個⋯行動指引？
  40: { "npcDialog": "坐著想再多都只是想，只要站起來<mark>行動</mark>，<mark>改變</mark>就開始了！", "player": [ { "next": 41 } ] } ,
  41: { "npcDialog": "一個月<mark>不只</mark>可以訪談、問專家，其實可以更有爆發力！", "player": [ { "next": 42 } ] } ,
  42: { "npcDialog": "就<mark>去做吧</mark>！在行動中學習！好嗎？", "player": [ { "next": 101, "text": "⋯好！" } ] } ,

  ...PartnerIntroBasic,
}
const PartnerDogBasic = {
  0: { "npcDialog": "哈囉～我是迴響行動夥伴 <mark>Lucy</mark>！", "player": [ { "text": "妳是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] },

  // 想聊聊天
  1: { "npcDialog": "想和我聊什麼？", "player": [ { "next": 118, "text": "跟狗狗互動的技巧？" }, { "next": 20, "text": "在迴響的故事？" }, { "text": "來個⋯行動指引？", "next": 30 }] } ,

  // 自我介紹
  10: { "npcDialog": "我是<a href='https://www.facebook.com/DogtorStrayEdu' target='_blank'>浪犬博士</a>的創辦人，也是一位<mark>狗狗訓練師</mark>哦！", "player": [ { next: 11 } ] },
  11: { "npcDialog": "你問我迴響怎麼樣？當然很好啊，迴響怎麼安排，我都會答應啦！", "player": [ { next: 101 } ] },

  // 跟狗狗互動的技巧？"
  118: { "npcDialog": "每隻獨一無二的狗兒都有適合的相處方式喔，我可以跟你分享一些知識。", "player": [ { "next": 119, "text": "洗耳恭聽" } ] } ,
  119: { "npcDialog": "狗狗的吠叫有分很多種，其中一種是<mark>警戒的吠叫</mark>，他們會對著有危險的方向大叫。", "player": [ { "next": 1110, "text": "該怎麼辦呢？" } ] } ,
  1110: { "npcDialog": "因為他們很害怕，所以你可以起身<mark>去幫他檢查</mark>那裡有沒有危險，讓狗狗知道你會負責。", "player": [ { "next": 1111, "text": "原來如此" } ] } ,
  1111: { "npcDialog": "盡量不要讓他們自己去查看喔！萬一真的看到陌生人或危險事物，他們會更敏感害怕。", "player": [ { "next": 1112 } ] } ,
  1112: { "npcDialog": "還有阿，狗狗經常會<mark>甩動身體</mark>，你知道是為什麼嗎？", "player": [ { "next": 1113, "text": "不知道" } ] } ,
  1113: { "npcDialog": "有很多種可能，可能是把髒東西甩掉，或是放鬆肌肉，還有就是他們想甩掉「<mark>壓力</mark>」。", "player": [ { "next": 1114, "text": "壓力？" } ] } ,
  1114: { "npcDialog": "對呀，狗狗容易因為某些事情感到有壓力，比如被抱抱、被侷限自由，或是跟<mark>陌生人</mark>相處之類的。", "player": [ { "next": 1115, "text": "懂了！" } ] } ,
  1115: { "npcDialog": "最後就是，狗狗喜歡在路上找動物<mark>屍體或垃圾</mark>，並跟他們翻滾在一起⋯⋯", "player": [ { "next": 1116, "text": "好臭！" } ] } ,
  1116: { "npcDialog": "對我們來說雖然很困擾、很臭，但對他們而言，這麼強烈的氣味，就像<mark>香水</mark>一樣！", "player": [ { "next": 1117, "text": "原來如此" } ] } ,
  1117: { "npcDialog": "祝你也能跟狗狗良好的互動喔！話說迴響<mark>山屋前</mark>好像躺著一隻狗⋯⋯", "player": [ {"text": "對欸，我來去認識牠！", next: 101 } ] },


  // 在迴響的故事？
  20: { "npcDialog": "其實，我是迴響 2017 年第一屆的<mark>參加者</mark>啦！", "player": [ { "next": 21 } ] } ,
  21: { "npcDialog": "那時候，我的行動夥伴是<mark>許皓甯</mark>，我到現在，還會想起來大會師結束的<mark>那天晚上</mark>⋯⋯", "player": [ { "next": 22 } ] } ,
  22: { "npcDialog": "我跟皓甯在大廳多聊了兩個小時，聊到整個空間只剩我們的椅子沒收。", "player": [ { "next": 23, "text": "哇嗚！" } ] } ,
  23: { "npcDialog": "我整個嚇到啊！竟然有前輩真的在我們旁邊<mark>一起解決問題</mark>。", "player": [ { "next": 24 } ] } ,
  24: { "npcDialog": "後來也成為行動夥伴，是因為想要<mark>延續</mark>我的感動", "player": [ { "next": 25 } ] } ,
  25: { "npcDialog": "想讓更多行動者，也可以被這樣<mark>真誠的協助</mark>給支持！", "player": [ { "next": 26, "text": "天哪⋯⋯這故事也太感人！" } ] } ,
  26: { "npcDialog": "很開心你也喜歡我的故事！", "player": [ { "next": 101 } ] },


  // 來個⋯行動指引？
  30: { "npcDialog": "「突破自我懷疑，最重要的是<mark>涵容自己</mark>」", "player": [ { "next": 31 } ] },
  31: { "npcDialog": "行動中，不免會有<mark>自我懷疑</mark>的時刻⋯⋯", "player": [ { "next": 32 } ] },
  32: { "npcDialog": "最近一次是覺得自己成長的不夠多不夠快，達不到我預期的期待與目標⋯⋯", "player": [ { "next": 33 } ] },
  33: { "npcDialog": "但，對自我的期待過高，達不到時又嚴厲地責備自己，會是種很痛苦的折磨⋯⋯", "player": [ { "next": 34 } ] },
  34: { "npcDialog": "「我做得很差...我是不是不適合？」、「為什麼我不能再努力一點？」", "player": [ { "next": 35 } ] },
  35: { "npcDialog": "不斷這樣逼迫自己不斷前進，只會累到一再也無法招架的程度，甚至可能變成「<mark>被動受傷者</mark>」思維", "player": [ { "next": 36 } ] },
  36: { "npcDialog": "一不小心，可能自己傷害了自己，也傷害了最珍視的夥伴們家人們⋯⋯", "player": [ { "next": 37,  "text": "恩⋯⋯"  } ] },
  37: { "npcDialog": "最後是一位<mark>創業前輩</mark>在電話中，解放了我內心的苦：", "player": [ { "next": 38 } ] },
  38: { "npcDialog": "「我認識的 Lucy 在每個時刻都用盡全力努力。<mark>你已經做得很好</mark>。」", "player": [ { "next": 39 } ] },
  39: { "npcDialog": "「所謂的成長思維，是覺得<mark>自己已經很好，只是想要更好</mark>。」", "player": [ { "next": 40 } ] },
  40: { "npcDialog": "眼淚不停地流下，原來那份痛苦，是自己<mark>無法接納自己</mark>。", "player": [ { "next": 41 } ] },
  41: { "npcDialog": "「面對不喜歡的真實，才能真正出發」", "player": [ { "next": 42 } ] },
  42: { "npcDialog": "原來所謂成長思維，是種不卑不亢的穩定力量。", "player": [ { "next": 43 } ] },
  43: { "npcDialog": "接納自己的現狀，不自卑也不自滿，溫和地一步一腳印成長。", "player": [ { "next": 44 } ] },
  44: { "npcDialog": "最近的心路歷程⋯⋯分享給你！", "player": [ { "next": 101, "text": "好真實的分享⋯（給你一個擁抱）" } ] },

 ...PartnerIntroBasic,
}
const PartnerChatBasic = {
  0: { "npcDialog": "嗨嗨！我是迴響行動夥伴<mark>品汶</mark>！", "player": [ { "text": "妳是⋯⋯？", "next": 10 }, { "next": 1, "text": "想聊聊天" }, { "text": "行動夥伴是⋯？", "next": 200 } ] } ,

  // 想聊聊天
  1: { "npcDialog": "想和我聊什麼？", "player": [ { "text": "送給行動者一句話？", "next": 40 }, { "text": "為什麼來當行動夥伴？", "next": 20 }, { "text": "來點⋯行動指引？", "next": 30 } ] },

  // 自我介紹
  10: { "npcDialog": "我是<a href='https://www.facebook.com/ChatforTaiwan/' target='_blank'>對話千層派·全民聊未來</a>發起人！然後，最近有在錄一個 Podcast，叫《<a href='https://www.mymusic.net.tw/podcast/channel/12005' target='_blank'>愛的幾趴火</a>》！", "player": [ { next: 11 } ] },
  11: { "npcDialog": "歡迎來聽我聊聊 #感情經營、#伴侶溝通、#親密關係 以及如何讓愛火恆燃的大小事哦～", "player": [ { next: 101 } ] },

  // 送給行動者一句話？
  40: { "npcDialog": "Be the change you want to see in the world！", "player": [ { "next": 101 } ] } ,

  // 為什麼來當行動夥伴？
  20: { "npcDialog": "其實跟團隊互動的過程中，我也無形把一些學到的事情、心態、思路<mark>整理起來</mark>。", "player": [ { "next": 21 } ] } ,
  21: { "npcDialog": "平常沒人問我的話，就只是在腦中的事情，所以覺得蠻<mark>有趣</mark>的～", "player": [ { "next": 101, "text": "原來如此" } ] } ,

  // 來點⋯行動指引？
  30: { "npcDialog": "不管<mark>行動後</mark>有沒有如預期、滿不滿意⋯⋯", "player": [ { "next": 31 } ] } ,
  31: { "npcDialog": "只要有<mark>嘗試</mark>，可能會確認自己想繼續做！", "player": [ { "next": 32 } ] } ,
  32: { "npcDialog": "也很可能發現那不是自己想要做的，至少都會<mark>更確定下一步</mark>，不會是白費的！", "player": [ { "next": 101, "text": "好的⋯⋯我會記在心上的！" } ] } ,

  ...PartnerIntroBasic,
}
const PartnerFlavorBasic = {
  1: { "npcDialog": "我之前看過的一本<mark>《<a href='https://www.books.com.tw/products/0010667256' target='_blank'>真實烏托邦</a>》</mark>有提到，要面對一整套的社會體系，框架非常大。", "player": [ { "next": 2 } ] } ,
  2: { "npcDialog": "社會體系框架大到我們很難變成一個很大的個體打敗它，而且打敗也可能只會<mark>變成下一個它</mark>⋯⋯", "player": [ { "next": 3 } ] } ,
  3: { "npcDialog": "書裡提到的是用<mark>侵蝕跟瓦解</mark>，去影響大結構。而一塊一塊的單位，就是<mark>行動</mark>！", "player": [ { "next": 4, "text": "好棒的見解" } ] } ,
  5: { "npcDialog": "在過程中<mark>一定會遭遇失敗</mark>，但生命本來就是持續累積的事情。", "player": [ { "next": 6 } ] } ,
  6: { "npcDialog": "與失敗相處，讓<mark>失敗成為養份</mark>。失敗了大不了就回家睡個幾天就好，這就是我的行動指引！", "player": [ { text: '受益良多！', "next": "4" } ] } ,
  7: { "npcDialog": "迴響邀請了一群社會上比較早開始行動的人們，叫<mark>行動夥伴</mark>。", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "算是一群投身社會創新領域 3-5 年的前輩。", "player": [ { "next": 9 } ] } ,
  9: { "npcDialog": "因為<mark>剛起步不久</mark>，能體會行動者們的挫折、迷惘、警喜和成就。", "player": [ { "next": 10 } ] } ,
}

const IssueWizardBasic = {
  2: { "npcDialog": "要認識自己，需要<mark>自我覺察</mark>，但現今卻不是人人能做到", "player": [ { "next": 3, "text": "你繼續說吧！" }, { "text": "打擾了，我想我自己逛逛就好" } ] } ,
  3: { "npcDialog": "很多人認為自我覺察是很玄的東西，感覺非常困難，", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "但實際上是我們原本就會的事情，只需要<mark>「喚醒」</mark>這個能力就好。", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "比如在日常生活中靜下心認真呼吸、細嚼慢嚥吃飯、聆聽環境聲音，", "player": [ { "next": 6 } ] } ,
  6: { "npcDialog": "這些都已經在做「自我覺察」了，hoho。", "player": [ { "next": 7, "text": "那為什麼我們要做自我覺察呢？" } ] } ,
  7: { "npcDialog": "這是個好問題，我來舉個例子吧！", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "經常有人說要強迫自己「跳出舒適圈」才能成長，", "player": [ { "next": 9 } ] } ,
  9: { "npcDialog": "但如果沒有自我覺察 — 也就是不能檢視自我狀態、身心靈是否平衡，", "player": [ { "next": 10 } ] } ,
  10: { "npcDialog": "僅是為跳脫而跳脫，很可能是外國月亮比較圓的心理，只會加深匱乏感，hoho。", "player": [ { "next": 11, "text": "原來如此阿" } ] } ,
  11: { "npcDialog": "hoho，另外，現代也有許多人認為「做自己、愛自己」是最重要的事，", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "但其實在那之前，更重要的首要任務是認識「何謂自己」，", "player": [ { "next": 13 } ] } ,
  13: { "npcDialog": "而「自己」又是與社會互動過程中映照出的概念，", "player": [ { "next": 14 } ] } ,
  14: { "npcDialog": "並不只是自我的無限放大，hoho。", "player": [ { "next": 15 } ] } ,
  15: { "npcDialog": "hoho，最後，不論是自我覺察，還是對理想生活型態的探索，", "player": [ { "next": 16 } ] } ,
  16: { "npcDialog": "都是持續變動的過程，<mark>沒有標準答案或適用所有人的方法</mark>，更沒有終點。", "player": [ { "next": 17 } ] } ,
  17: { "npcDialog": "期待你之後能時時對自己抱持好奇喔！hoho⋯⋯", "player": [ { "next": 18 } ] } ,
}

const IssueLRRHBasic = {
  2: { "npcDialog": "這要從<mark>月經貧窮</mark>開始說起，你想聽我說嗎？", "player": [ { "next": 3, "text": "好阿！" }, { "text": "還是⋯⋯先算了吧" } ] } ,
  3: { "npcDialog": "月經貧窮泛指人們因經濟困難，無法取得或負擔足夠的生理用品，", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "進而導致如長期陰部感染、不孕、長期憂鬱等健康問題的一社會現象喔。", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "而月經平權就是希望能夠幫助這些人，終結月經平窮、消弭不平等！", "player": [ { "next": 6, "text": "這種事情，應該比較多發生在貧窮的國家吧！" } ] } ,
  6: { "npcDialog": "蹦！不出所料，你的想法和大多數人一樣⋯⋯", "player": [ { "next": 7 } ] } ,
  7: { "npcDialog": "但事實上這是全球現象，<mark>臺灣</mark>目前也有許多人深陷月經貧窮的困境喔，", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "只是因為很少人提及，更因月經污名化，需求沒有被提出、被看見。", "player": [ { "next": 9, "text": "恩，我想起來了，好像有聽過那個誰提過" }, { "text": "這太驚人了，我以前從來不知道這種問題！", "next": "9" } ] } ,
  9: { "npcDialog": "不止如此，這個問題是<mark>「無論城鄉」</mark>都有的現象喔。", "player": [ { "next": 10 } ] } ,
  10: { "npcDialog": "以小紅帽目前穩定服務的個案來說，遍及全臺 12 個縣市，", "player": [ { "next": 11 } ] } ,
  11: { "npcDialog": "城鄉間的數量沒有顯著差異，因為只要有經濟困境，就會有月經貧窮。", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "因此，小紅帽在提供物資幫助的同時，也希望能夠普及<mark>月經教育</mark>，", "player": [ { "next": 13 } ] } ,
}

const IssueFamilyBasic = {
  2: { "npcDialog": "這不稀奇喔！平時我和我老婆都要上班，我們就輪流照顧孩子。", "player": [ { "next": 3 } ] } ,
  3: { "npcDialog": "我們家是雙薪家庭，在<mark>育兒</mark>上也是由爸爸和媽媽<mark>平均分配</mark>的哦！", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "很多人以為育兒是母親的天職，所以小孩給媽媽帶一定比較好。", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "但其實只要<mark>參與育兒行動</mark>，爸爸也會分泌催產素，讓腦中的「<mark>親職神經迴路</mark>」變得活躍！", "player": [ { "next": 6, "text": "原來，臺灣現在的兩性平權已經如此進步了！" } ] } ,
  6: { "npcDialog": "是阿，比起亞洲其他國家，臺灣的確是進步了，但老實說⋯⋯", "player": [ { "next": 7 } ] } ,
  7: { "npcDialog": "行政院主計處的資料顯示，在家務勞動和育兒分工上，仍然是<mark>女性承擔比較多責任</mark>喔。", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "相對來說，男性就是承擔更多賺錢養家的責任。", "player": [ { "next": 9 } ] } ,
  9: { "npcDialog": "所以兩性在平等參與育兒的事情上，還有<mark>很大的進步空間</mark>！", "player": [ { "next": 10, "text": "恩？這是寶寶在哭嗎？" } ] } ,
  10: { "npcDialog": "最近這孩子變得不好安撫，讓我有點手足無措的⋯⋯", "player": [ { "next": 11 } ] } ,
  11: { "npcDialog": "嘻嘻，但不需要擔心，因為我可以到<mark>社群</mark>裡請教其他奶爸的經驗。", "player": [ { "next": 12, "text": "社群！那是什麼東西？" } ] } ,
  12: { "npcDialog": "那是一個專門讓<mark>新手爸爸</mark>們交流<mark>育兒經驗</mark>的活動，", "player": [ { "next": 13 } ] } ,
}

const IssueMovieRabbitBasic = {
  3: { "npcDialog": "二輪戲院最大的特色就是<mark>票價便宜</mark>，平均看一場電影只需要 <mark>40-80 元</mark>左右。不到首輪戲院的四分之一。", "player": [ { "next": 5 } ] } ,
  // 4: { "npcDialog": "", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "而且戲院多半飲食自由、憑手章自由進出，非常彈性。", "player": [ { "next": 6, "text": "但是，我印象中的二輪戲院都髒髒舊舊的耶⋯⋯" } ] } ,
  6: { "npcDialog": "二輪戲院多位於<mark>舊市場與商圈</mark>附近，並且陪伴著我們一起長大。", "player": [ { "next": 7 } ] } ,
  7: { "npcDialog": "難免會形成<mark>髒亂老舊</mark>的刻板印象，不過這並<mark>不是事實</mark>喔！", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "現在的二輪戲院都會定期維護內部環境，營造乾淨舒適的觀影空間；", "player": [ { "next": 9 } ] } ,
  9: { "npcDialog": "另外，也會因應新聲光技術的新片，同步提升播映的軟硬體設備。", "player": [ { "next": 10, "text": "原來～是我以前誤會它了" } ] } ,
  10: { "npcDialog": "二輪戲院這種觀影型式，陪臺灣走過了<mark>半個世紀</mark>。", "player": [ { "next": 11 } ] } ,
  11: { "npcDialog": "但隨著外資戲院加入、線上觀影平台的崛起，", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "激烈的競爭讓許多戲院悄悄<mark>熄了燈</mark>，我每次想到都覺得難過QQ", "player": [ { "next": 13, "text": "唉呀，聽起來真可惜" } ] } ,
  13: { "npcDialog": "不過，目前全台仍然還有<mark>十幾間二輪戲院</mark>存在喔。", "player": [ { "next": 14 } ] } ,
  14: { "npcDialog": "希望大家破除迷思，踴躍到二輪戲院觀影！咻！", "player": [ { "next": 15 } ] } ,
}

const IssueClimberBasic = {
  2: { "npcDialog": "有些人覺得去山林或海邊從事<mark>自然野外活動</mark>是<mark>危險</mark>的行為，你的想法是什麼呢？", "player": [ { "next": 3, "text": "很危險！應禁止野外活動" }, { "text": "不危險啊，遵守警示標語就好", "next": "3" } ] } ,
  3: { "npcDialog": "原來你是這樣想阿！", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "我登山多年的經驗告訴我，野外活動其實不是只有「<mark>危險</mark>」跟「<mark>不危險</mark>」兩個答案。", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "如何「<mark>降低風險</mark>」才是重要的！", "player": [ { "next": 6 } ] } ,
  6: { "npcDialog": "例如<mark>出發前的準備</mark>、<mark>對環境的認識</mark>、<mark>野外急救技能</mark>等，都能大幅降低意外的發生喔。", "player": [ { "next": 7 } ] } ,
  7: { "npcDialog": "所以下次去野外活動時，記得不能什麼都不準備就出發唷！", "player": [ { "next": 8, "text": "真有趣，我還想聽你說更多！" } ] } ,
  8: { "npcDialog": "這樣阿⋯⋯最近越來越多人在談論「<mark>土地開發</mark>」和「<mark>環境保護</mark>」哪一個重要，你有什麼想法呢？", "player": [ { "next": 9, "text": "土地開發重要" }, { "text": "環境保護重要", "next": "9" }, { "text": "兩者應該取得平衡", "next": "9" } ] } ,
  9: { "npcDialog": "嗯嗯，身為一個長期的登山者，我認為我們可以找出<mark>與自然共好</mark>的方式喔！", "player": [ { "next": 10 } ] } ,
  10: { "npcDialog": "開發時盡量找到適合的技術與開發區域，將對自然的影響降到最低，", "player": [ { "next": 11 } ] } ,
}

const IssueSnowManBasic = {
  2: { "npcDialog": "是這樣的，上禮拜我在網路上發表對政府防疫的看法，", "player": [ { "next": 3 } ] } ,
  3: { "npcDialog": "本意是想與大家交流討論，但卻引發了<mark>筆戰</mark>，", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "有一群人開始<mark>留言攻擊</mark>我，讓我十分害怕。", "player": [ { "next": 5, "text": "這種事情經常發生，不用放在心上啦！" } ] } ,
  5: { "npcDialog": "謝謝你安慰我，我想這是因為網路上的資訊交流<mark>並非一對一</mark>，", "player": [ { "next": 6 } ] } ,
  6: { "npcDialog": "大家看似「對等」的筆戰，實則更像是<mark>「多對一」的圍剿</mark>，", "player": [ { "next": 7 } ] } ,
  7: { "npcDialog": "這其實會讓大家越來越不敢在<mark>異溫層</mark>的社群互動，", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "同溫層也因此而變得更厚，更難發生交流。", "player": [ { "next": 9, "text": "你這麼說也是有道理" } ] } ,
  9: { "npcDialog": "我其實希望大家在和異溫層討論政治議題的時候，盡量<mark>減少不理性的表現</mark>，達成好的溝通與理解。", "player": [ { "next": 11, "text": "那應該怎麼做呢？" } ] } ,
  // 10: { "npcDialog": "", "player": [ { "next": 11, "text": "那應該怎麼做呢？" } ] } ,
  11: { "npcDialog": "這是個很好的問題，可以從「我們如何看待<mark>衝突</mark>」談起，", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "你認為，當我們討論<mark>政治議題</mark>、<mark>起衝突</mark>的時候，哪個想法是比較適合的呢？", "player": [ { "next": 13, "text": "衝突和吵架是不好的，一定要避免" }, { "text": "多說多錯，最好都不要講話！", "next": "13" } ] } ,
  13: { "npcDialog": "原來你是這樣想的啊⋯⋯大多數人都有「<mark>衝突就是不好的</mark>」的想法", "player": [ { "next": 14 } ] } ,
  14: { "npcDialog": "但是其實啊，衝突可以分成<mark>建設性</mark>的和<mark>破壞性</mark>的，", "player": [ { "next": 15 } ] } ,
}

const IssueElfBasic = {
  2: { "npcDialog": "啊⋯⋯你問我大學生的煩惱是什麼啊？這是個好問題。因為他們的煩惱五花八門。", "player": [ { "next": 4 } ] } ,
  // 3: { "npcDialog": "", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "總歸來說，在一路升學與出社會間的過渡期，思考「<mark>如何成功</mark>與<mark>面對失敗</mark>」是他們的重要課題。", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "所以他們對「<mark>成功</mark>」與「<mark>失敗</mark>」的看法，很經常的在生活中影響他們的情緒。", "player": [ { "next": 6 } ] } ,
  6: { "npcDialog": "你覺得，<mark>成功</mark>是如何定義的呢？", "player": [ { "next": 7, "text": "每個人的成功各有不同" }, { "text": "社會賦予的，像事業有成、結婚生子。", "next": "7" } ] } ,
  7: { "npcDialog": "部分答對，但更全面一點來說，成功是由「<mark>社會期待</mark>」以及「<mark>個人認定</mark>」交錯而成。", "player": [ { "next": 9 } ] } ,
  // 8: { "npcDialog": "", "player": [ { "next": 9 } ] } ,
  9: { "npcDialog": "無法達到任何一方的期待時，仍在追尋<mark>自我認同</mark>的大學生就可能產生害怕、焦慮、沮喪等<mark>情緒課題</mark>。", "player": [ { "next": 11, "text": "原來如此，那如果面對有這樣困擾的大學生，我們該怎麼做呢？" } ] } ,
  // 10: { "npcDialog": "", "player": [ { "next": 11, "text": "原來如此，那如果面對有這樣困擾的大學生，我們該怎麼做呢？" } ] } ,
  11: { "npcDialog": "不妨我們來看一個例子吧！", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "你的朋友 John 邊滑「實習透視鏡」的 FB 社團邊抱怨他最近找不到實習的煩惱。", "player": [ { "next": 13 } ] } ,
  13: { "npcDialog": "其實 John 只是看到大家都在找實習而感到焦躁，彷彿自己也應該做些什麼。", "player": [ { "next": 14 } ] } ,
  14: { "npcDialog": "此時你要怎麼幫助他比較好呢？", "player": [ { "next": 15, "text": "幫他尋找競爭優勢" }, { "text": "提供你所知道的面試技巧、怎麼寫履歷等等。", "next": 15, } ] } ,
  15: { "npcDialog": "原來你是這樣想的阿！", "player": [ { "next": 16 } ] } ,
  16: { "npcDialog": "因為我們假設 John 只是在窮緊張，根本不需要找實習，所以如果這時跟他分享一堆實習技巧與心得，那他可能會<mark>更加焦慮</mark>喔。", "player": [ { "next": 18 } ] } ,
  // 17: { "npcDialog": "", "player": [ { "next": 18 } ] } ,
}

const IssueTourGuideBasic = {
  2: { "npcDialog": "你知道，臺灣現行的 <mark>108 課綱</mark>所強調的是什麼嗎？", "player": [ { "next": 3, "text": "自主探索、解決問題的能力" }, { "text": " 基本考科之外的學習與反思", "next": "3" } ] } ,
  3: { "npcDialog": "你真厲害！以往的課綱重視考試成績，而新課綱實施後，學生必須培養<mark>自主探索</mark>、<mark>學習與反思</mark>的能力。", "player": [ { "next": 5 } ] } ,
  // 4: { "npcDialog": "", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "依據每個人<mark>興趣</mark>、<mark>特質</mark>不同，就會有不同的<mark>學習軌跡與歷程</mark>。", "player": [ { "next": 6, "text": "學生該為此改變做什麼準備呢？" } ] } ,
  6: { "npcDialog": "我們會鼓勵學生提早思考適合的類組與科系，更有方向的進行<mark>主題式探索</mark>、建立<mark>學習歷程</mark>。", "player": [ { "next": 8 } ] } ,
  // 7: { "npcDialog": "", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "以課程表現為基礎，豐富日後申請大學的個人資料。<mark>社團</mark>的確是一個很好幫助學生自我探索的地方喔！", "player": [ { "next": 10, "text": "聽起來很像參加社團在做的事情耶！" } ] } ,
  // 9: { "npcDialog": "", "player": [ { "next": 10 } ] } ,
  10: { "npcDialog": "許多人可能以為學生社團只是提供交朋友與放鬆的功能，但其實有許多同學也會期待透過社團<mark>累積經驗與能力</mark>，", "player": [ { "next": 12 } ] } ,
  // 11: { "npcDialog": "", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "在社團中可以訓練<mark>團隊溝通與合作</mark>、<mark>跨領域問題解決</mark>、<mark>時間管理</mark>等。", "player": [ { "next": 13, "text": "你身為老師一定懂得很多，才能引導學生。" } ] } ,
  13: { "npcDialog": "雖然學生在有升學疑問時的確會詢問我們，但實際上老師們的經驗與所知也有限，並不瞭解所有的科系，甚至職業，", "player": [ { "next": 15 } ] } ,
  // 14: { "npcDialog": "", "player": [ { "next": 15 } ] } ,
  15: { "npcDialog": "況且在這個時代，科系與職業的組合也是<mark>不斷快速變動</mark>的，", "player": [ { "next": 16 } ] } ,
}

const IssueBearBasic = {
  3: { "npcDialog": "黑嘿，你和別人真的很不一樣，居然願意靠近我、和我說話。", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "雖然我做過一些錯事，比如蹺課打架鬧事，但我覺得我並<mark>沒有別人想的那麼壞</mark>。", "player": [ { "next": 5 } ] } ,
  5: { "npcDialog": "我其實<mark>挺喜歡幫助人</mark>，比如我上週和無家者大哥聊天、買吃的給他。", "player": [ { "next": 6 } ] } ,
  6: { "npcDialog": "我的夢想是長大之後可以<mark>幫助和我有一樣遭遇的少年</mark>喔！黑嘿。", "player": [ { "next": 7, "text": "那⋯⋯你為什麼以前要做那些不好的事？" } ] } ,
  7: { "npcDialog": "雖然有點難以啟齒⋯⋯但我<mark>在家裡感覺不到溫暖</mark>，所以才在外面認識了一些朋友。", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "我跟他們做那些壞事，成天喝酒的爸爸才會注意到我。", "player": [ { "next": 9 } ] } ,
  9: { "npcDialog": "老師有說過，很多像我這樣的少年都面臨許多<mark>家庭與學校的困境</mark>。", "player": [ { "next": 10 } ] } ,
  10: { "npcDialog": "比如棄養、家人自殺、與藥癮者共居、目睹或遭受家庭暴力，還有成績落後、不懂如何與人建立健康關係⋯⋯", "player": [ { "next": 12, "text": "原來高關懷少年承受了那麼多壓力啊" } ] } ,
  // 11: { "npcDialog": "", "player": [ { "next": 12, "text": "原來高關懷少年承受了那麼多壓力啊" } ] } ,
  12: { "npcDialog": "黑嘿，是啊，老師也有說，這些高壓狀態會<mark>影響大腦前額葉的發展</mark>。", "player": [ { "next": 13 } ] } ,
  13: { "npcDialog": "容易造成<mark>注意力不集中</mark>、<mark>衝動</mark>或<mark>攻擊</mark>的行為。", "player": [ { "next": 14, "text": "聽起來很讓人心疼" } ] } ,
  14: { "npcDialog": "我希望，大家對我們這種少年不要有「<mark>先入為主</mark>」的想法。", "player": [ { "next": 15 } ] } ,
}

const IssueFishermanBasic = {
  2: { "npcDialog": "他從我還小就很疼我，是很親的長輩，但⋯⋯他兩年前<mark>因犯罪入獄</mark>，", "player": [ { "next": 3 } ] } ,
  3: { "npcDialog": "好不容易一個月前出獄，成為所謂的「<mark>更生人</mark>」，但聽說最近<mark>再犯</mark>被抓了⋯⋯", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "這個話題不是那麼輕鬆，你還有興趣繼續聽嗎？", "player": [ { "next": 5, "text": "好啊，你說說！" }, { "text": "沒興趣，你繼續釣魚吧！" } ] } ,
  5: { "npcDialog": "好的，那你知道<mark>更生人出獄後</mark>會面臨什麼問題嗎？", "player": [ { "next": 6, "text": "經濟困難、沒有地方住" }, { "text": "受歧視而找不到工作", "next": "6" }, { "text": "以上都是", "next": "6" } ] } ,
  6: { "npcDialog": "是阿，你說的沒錯⋯⋯更生人出獄後主要面臨三大問題：<mark>沒地方住、沒工作、沒人愛</mark>。", "player": [ { "next": 7 } ] } ,
  7: { "npcDialog": "而根據法務部統計，許多罪犯都有<mark>經濟困難</mark>、<mark>低學歷</mark>以及<mark>家庭失能</mark>的情況。", "player": [ { "next": 8 } ] } ,
  8: { "npcDialog": "以上這些原因，影響了他們在面對困境時是否會「<mark>選擇</mark>」犯罪。", "player": [ { "next": 9, "text": "原來是這樣阿！那你叔叔是為何入獄呢？" } ] } ,
  9: { "npcDialog": "他是因為<mark>毒品罪</mark>入獄的喔，在臺灣因毒品罪入獄的比例<mark>近乎一半</mark>。", "player": [ { "next": 10 } ] } ,
  10: { "npcDialog": "老實說，我叔叔個性溫合，他<mark>從來沒有傷害過別人</mark>。", "player": [ { "next": 11 } ] } ,
  11: { "npcDialog": "但大眾受媒體報導重大傷害案件的影響，聽到某人有<mark>前科</mark>就退避三舍，", "player": [ { "next": 12 } ] } ,
  12: { "npcDialog": "形成了大部分人對「更生人」的印象， 增加了他們<mark>回歸社會的阻礙</mark>。", "player": [ { "next": 13 } ] } ,
}

const IssueCarpentarBasic = {
  2: { "npcDialog": "那麼我先從挑選講起好了，你知道近年來大多數人選擇<mark>平價傢俱</mark>的原因是什麼嗎？", "player": [ { "text": "便宜好運送與組裝", "next": "4" }, { "text": "汰換風格無負擔", "next": "4" } ] } ,
  // 3: { "npcDialog": "你知道近年來大多數人選擇平價傢俱的原因是什麼嗎？", "player": [ { "text": "便宜好運送與組裝", "next": "4" }, { "text": "汰換換風格無負擔", "next": "4" } ] } ,
  4: { "npcDialog": "你說的沒錯，只不過⋯⋯就是因為要達到這種<mark>易拆裝</mark>與<mark>快速汰換</mark>的特點，", "player": [ { "next": "5" } ] } ,
  5: { "npcDialog": "因此平價傢俱大多採用使用<mark>年限短的組合板材質</mark>，", "player": [ { "next": "6" } ] } ,
  6: { "npcDialog": "它們損壞速度快，不容易進入回收利用的循環，造成<mark>環境負擔</mark>。", "player": [ { "next": "7", "text": " 如果我們想保護環境，還有什麼其他選擇嗎？" } ] } ,
  7: { "npcDialog": "當然有喔，你可以選擇購買<mark>二手傢俱</mark>，還有 <mark>DIY 改造傢俱</mark>，讓傢俱脫胎換骨、有新的功用。", "player": [ { "next": "9" } ] } ,
  // 8: { "npcDialog": "", "player": [ { "next": "9" } ] } ,
  9: { "npcDialog": "當然，這些都比不上我個人最喜歡的「<mark>撿傢俱</mark>」啦！", "player": [ {text: "撿傢俱？？", "next": "10" } ] } ,
  10: { "npcDialog": "恩？你問我要去哪裡撿？<mark>馬路旁的電線桿</mark>、<mark>回收箱旁</mark>都經常可以看見別人<mark>丟棄的傢俱</mark>呀，", "player": [ { "next": "12" } ] } ,
  // 11: { "npcDialog": "", "player": [ { "next": "12" } ] } ,
  12: { "npcDialog": "這些傢俱有些<mark>狀態良好</mark>，擦拭灰塵後就能繼續用，實在是很棒！", "player": [ { "next": "13", "text": "那那⋯⋯你知道如何回收大型傢俱嗎？" } ] } ,
  13: { "npcDialog": "你說到另一個重點了，<mark>傢俱淘汰</mark>也是我觀察很多人會有的需求，", "player": [ { "next": "14" } ] } ,
  14: { "npcDialog": "家裡不合用的傢俱，但功能還正常，捨不得直接報廢⋯⋯其實各縣市<mark>環保局</mark>均有協助<mark>回收大型傢俱</mark>，", "player": [ { "next": "16" } ] } ,
  // 15: { "npcDialog": "", "player": [ { "next": "16" } ] } ,
}

const FirstActionElfBasic = {
  2: { "npcDialog": "那是由六個任務組成的行動挑戰。心中有議題，腿上無動力的青年，可以經由任務包踏出<mark>第一步行動</mark>！", "player": [ { text: '還是不懂', "next": "5" } ] } ,
  3: { "npcDialog": "一個人用的話，可以<mark>釐清糾結不清的觀點</mark>⋯⋯", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "一群人用，可以具現化每個人的想法，用討論任務的方式<mark>交換觀點</mark>！", "player": [ { text: '還是不懂', "next": 5 } ] }
}

const Beaver1Basic = {
  0: { "npcDialog": "Hey 河狸們！Justin Beaver！Ahh⋯⋯抱歉，你們心情不好嗎 ヽ༼ಢ_ಢ༽ﾉ", "player": [ { "next": 1, "text": "怎麼了？" } ], "changeName": "elfName" } ,
  1: { "npcDialog": "河狸一族很苦惱⋯⋯因為上游正在進行<mark>水庫工程</mark>。", "player": [ { "next": 2 } ], "changeName": "elfName" } ,
  2: { "npcDialog": "雖然不知道完工後的具體影響，但這種沒有考慮到其他生物的做法，鐵定是有問題的吧。", "player": [ { "next": 100, "text": "該怎麼幫助他們" } ], "changeName": "elfName" } ,
}

const LoopElfBasic = {
  3: { "npcDialog": "行動迴圈是一套採取行動的方法論，整合了幫助<mark>發想</mark>、<mark>決策</mark>和<mark>反思</mark>的工具。", "player": [ { "next": 4, text: "還是不懂" } ]} ,
  4: { "npcDialog": "依照迴圈，可以<mark>清楚知道每次行動的目的</mark>，逐步提升對議題的認知！", "player": [ { "next": 7, "text": "像是什麼？" } ]} ,
  5: { "npcDialog": "當我們試著去解決某個問題，卻不知道從何開始，又或者開始了，卻不知道下一步是什麼 ٩(;ʘ¿ʘ;)۶", "player": [ { "next": 6 } ]} ,
  6: { "npcDialog": "行動迴圈是行動者的羅盤，幫助判斷何時該<mark>停下來反思</mark>、或尋找<mark>新的切入點</mark>、或採取<mark>行動</mark>來驗證想法。", "player": [ { "next": 7, "text": "酷！" } ]} ,
}

const FoxBasic = {
  0: { "npcDialog": "你看！他們是經常跌倒的狐狸唷，因為他們很腳滑！⋯⋯抱歉，我還是先閉嘴吧 ಥ⌣ಥ", "player": [ { "next": 1, "text": "他們看起來心情不好" } ], "changeName": "elfName"  } ,
  1: { "npcDialog": "是這樣子的⋯⋯退伍後的狐狸正在跟當兵的同梯們相聚，他發現大家都有一個困擾。", "player": [ { "next": 2, "text": "什麼困擾？" } ], "changeName": "elfName"  } ,
  2: { "npcDialog": "就是常常會被先入為主的<mark>刻板印象</mark>影響，認為狐狸很狡猾、愛騙人、會偷東西⋯⋯", "player": [ { "next": 3, "text": "你剛剛是不是說了腳滑的笑話" } ], "changeName": "elfName"  } ,
  3: { "npcDialog": "抱歉 இдஇ⋯⋯他們還說，入伍那天有人鋼盔不見，大家都懷疑是他們做的。", "player": [ { "next": 4, "text": "好可憐" } ], "changeName": "elfName"  } ,
  4: { "npcDialog": "這群狐狸想要扭轉這種形象！他們聊一聊之後，打算花一個半月來做一個<mark>網站</mark>。", "player": [ { "next": 5, "text": "" } ], "changeName": "elfName"  } ,
  5: { "npcDialog": "把狐狸界的好狐好事通通放上網站，扭轉狐作非為的形象。", "player": [ { "next": 6 } ], "changeName": "elfName"  } ,
  6: { "npcDialog": "問題只有一個，就是⋯⋯不知道如何聯繫上遠親火狐，而他們之中也沒有人會寫程式。", "player": [ { "next": 7, "text": "那該怎麼辦" } ], "changeName": "elfName"  } ,
}

const MoleBasic = {
  0: { "npcDialog": "看上面！他們是很會挖土的<mark>鼴鼠</mark>，他們看起來也遇上一些麻煩了⋯⋯Σ(°△°|||)", "player": [ { "next": 1, "text": "一探究竟" } ], "changeName": "elfName" } ,
  1: { "npcDialog": "森林裡的鼴鼠幫，因為人類世界的垃圾過量，傾倒到森林，汙染了土壤，導致原本的棲地越來越小⋯⋯", "player": [ { "next": 2 } ], "changeName": "elfName" } ,
  2: { "npcDialog": "他們出去辦了幾次抗爭「行動」，但因為地鼠的身分⋯⋯只被視為是邊緣人的 DISS。", "player": [ { "next": 3, "text": "諧音梗嗎" } ], "changeName": "elfName" } ,
  3: { "npcDialog": "他們的抗爭都沒有得到什麼回應⋯⋯現在正煩惱於下一步該怎麼做。", "player": [ { "next": 4, "text": "該怎麼辦呢" } ], "changeName": "elfName" } ,
}

const Beaver2Basic = {
  0: { "npcDialog": "快看！我們又遇到了<mark>河狸</mark>，他們是不是遇到什麼困難呢？", "player": [ { "next": 1, "text": "快去問他們" } ], "changeName": "elfName" } ,
  1: { "npcDialog": "原來如此⋯⋯河狸一族們沮喪地發現水庫工程的水很深啊。", "player": [ { "next": 2, "text": "那是當然的啊" } ], "changeName": "elfName" } ,
  2: { "npcDialog": "他們沒辦法直接影響承包商作出承諾，主動與利害關係人們溝通風險。", "player": [ { "next": 3 } ], "changeName": "elfName" } ,
  3: { "npcDialog": "慶幸的是，他們得知其實承包商是願意在兼顧利益和生態的平衡下，進行溝通和做出讓步的", "player": [ { "next": 4 } ], "changeName": "elfName" } ,
  4: { "npcDialog": "只是⋯⋯<mark>新的行動</mark>可能是甚麼呢⋯⋯？想到這裡就又卡住了", "player": [ { "next": 5, "text": "該怎麼辦呢" } ], "changeName": "elfName" } ,
}

const CoachElfBasic = {
  3: { "npcDialog": "教練是受過<mark>設計思考</mark>訓練的迴響執行團隊成員，來自各行各業。", "player": [ { "next": 4 } ] } ,
  4: { "npcDialog": "我們熟悉<mark>發散</mark>和<mark>收斂</mark>的討論工具，也能從客觀的角度協助團隊釐清現況。", "player": [ { "next": "7" } ] } ,
  5: { "npcDialog": "教練的用途很多，陪伴、擅長傾聽、小心釐清、大膽求證，是迴響珍貴的寶藏！", "player": [ { "next": 6 } ] } ,
  6: { "npcDialog": "並且帶有改善社會議題的熱情，用自己的時間和心力投入團隊行動。", "player": [ { "next": 7 } ] } ,
}

const BearBasic = {
  0: { "npcDialog": "他們是從都市返鄉的恩愛熊夫妻唷！Eh？他們好像也遇到問題了⋯⋯", "player": [ { "next": 1, "text": "問問看他們" } ], "changeName": "elfName" } ,
  1: { "npcDialog": "熊夫妻對都市的工作感到倦怠，兩人決定回到森林並投入自己真正在意的事情。", "player": [ { "next": 2 } ], "changeName": "elfName" } ,
  2: { "npcDialog": "他們都有點擔心山上部落裡小熊們的教育問題，也經歷了幾次的行動和反思。", "player": [ { "next": 3 } ], "changeName": "elfName" } ,
  3: { "npcDialog": "雖然獲得了不少觀點，但對於下一步似乎一直無法達成共識⋯⋯", "player": [ { "next": 4, "text": "該怎麼辦呢" } ], "changeName": "elfName" } ,
}


export var dialogs = {
  Hiker2017: {
    name: '伊介',
    justFinish: {
      0: { "npcDialog": "這個登頂步道，是迴響一年一年的精選回顧，你所在的地方是 2017 年，也就是第一屆！"},
    }
  },
  BalloonMan: {
    name: '博綸',
    justFinish: {
      0: { "npcDialog": "登頂過程啊，不需要有要看完全部的壓力，挑有興趣的看就好！攤位畢竟很多嘛⋯⋯", "player": [ { "next": 1 } ]},
      1: { "npcDialog": "不過，很推薦至少能攀到山頂看看喔，風景可美了。" },
    }
  },
  FirstFog: {
    name: '濃霧',
    justFinish: {
      0: { "npcDialog": "霧太濃了⋯⋯沒有辦法出去。", changeName: 'player' }
    }
  },
  EndingOne: {
    name: '146MHz',
    justFinish: {
      0: { "npcDialog": "player，我們都在對講機的同一個頻率喔！", "player": [ { "next": 1 } ], "changeName": "山屋櫃檯" } ,
      1: { "npcDialog": "櫃檯、老協作、行動夥伴、過往的行動團隊，甚至小動物、小小人、擺渡人⋯⋯大家都在這裡！", "player": [ { "next": 8 } ], "changeName": "player" } ,
      // 2: { "npcDialog": "嗨，player，要一起打電動嗎？還有問題的話，隨時找我們這些<mark>行動夥伴</mark>聊聊吧。", "player": [ { "next": 3 } ], "changeName": "人生百味 - 阿德" } ,
      // 3: { "npcDialog": "是阿德！他們是行動夥伴，不像業師一樣高高在上，會陪我們吃午餐、聊想法，甚至一起合作提案。", "player": [ { "next": 100 } ], "changeName": "player" } ,
      // 100: { "npcDialog": "player，還記得月經貧窮的議題嗎？還有其他團隊在意的<mark>議題</mark>⋯⋯放在心中吧！", "player": [ { "next": 4 } ], "changeName": "小紅帽" } ,
      // 4: { "npcDialog": "帶著議題跟我們談天的 NPC，他們是過往幾屆的<mark>行動團隊</mark>，跟他們聊天讓我有好多收穫！", "player": [ { "next": 101 } ], "changeName": "player" } ,
      // 101: { "npcDialog": "player，謝謝你帶著<mark>行動工具</mark>來森林幫助我們！森林裡的小動物都很想念你喔。", "player": [ { "next": 5 } ], "changeName": "河狸" } ,
      // 5: { "npcDialog": "任務包、迴圈包⋯⋯是迴響提供給行動者們的<mark>行動工具</mark>。啊！還有陪伴每個團隊的<mark>設計思考教練</mark>。", "player": [ { "next": 6 } ], "changeName": "player" } ,
      // 102: { "npcDialog": "player，可別忘記我辛苦得帶你遊湖，還召喚出澤中女神啊⋯⋯", "player": [ { "next": 6 } ], "changeName": "擺渡人" } ,
      // 6: { "npcDialog": "是迴響的擺渡人！我當然記得浮在大澤上的星球和斧頭！那是迴響重視的<mark>文化</mark>與<mark>團隊價值</mark>。", "player": [ { "next": 8 } ], "changeName": "player" } ,
      // 6: { "npcDialog": "好高興能夠認識大家，讓我對迴響的認識更深了一點。", "player": [ { "next": 7 } ], "changeName": "player" } ,
      // 7: { "npcDialog": "我終於體認到「<mark>行動</mark>」的意涵。不行動就像死水，塵封自己心裡的期待，也讓世界瀰漫濃霧，變得沈寂⋯⋯", "player": [ { "next": 8 } ], "changeName": "player" } ,
      8: { "npcDialog": "player，雖然整個迴響大本營的探險歷程，我們總<mark>鼓勵</mark>採取行動。", "player": [ { "next": 9 } ], "changeName": "老協作" } ,
      9: { "npcDialog": "但我們也希望讓大家正視⋯⋯每個人都會有<mark>無力</mark>的一刻，我們不需要永遠都很有力！", "player": [ { "next": 10 } ], "changeName": "老協作" } ,
      10: { "npcDialog": "雖然你在這裡解了許多迴響大本營的任務，但最後一個任務，我們希望跟你有關，聊聊你<mark>心中的大石頭</mark>。", "player": [ { text: '聊聊我自己嗎？',"next": 12 } ], "changeName": "老協作" } ,
      // 11: { "npcDialog": "對呀！難得所有人一起聚在這裡，和我們聊聊你自己吧！player，你有你<mark>在意的事情</mark>嗎？", "player": [ { "next": 12 } ], "changeName": "elfName" } ,
      12: { "npcDialog": "跟大象不同，任何你所在意的、放在心中的、過不去的，想面對、想沈澱的，是否有這顆石頭呢？", "player": [ { "next": 13, "text": "填寫心中石頭", input: { question: '放下你心中的石頭：', key: 'myStone' } } ], "changeName": "山屋櫃檯" } ,
      13: { "npcDialog": "放下心中的石頭，迎來了你自己在這裡的<mark>結局</mark>⋯⋯很高興認識你，歡迎你來此一遊，player。", "player": [ { "next": 14 } ], "changeName": "山屋櫃檯" } ,
      14: { "npcDialog": "關於網站剩下的彩蛋，如果還意猶未盡，我來跟你說明吧！", "player": [ { "text": "好的！", "transferAndDialog": {x: 159, y: 210} }, { next: 15, "text": "不用了" } ], "changeName": "山屋櫃檯" },
      15: { "npcDialog": "好的，那我們中斷對講機吧⋯⋯", "player": [ { "transfer": {x: 150, y: 225} } ], "changeName": "山屋櫃檯" },
    }
  },
  FerryManInLake: {
    name: '擺渡人',
    justFinish: {
      0: { "npcDialog": "要上岸了嗎？", "player": [ { "offBoat": true, recoverNPCs: ['FerryMan'], "text": "好" }, { "text": "不要" } ] }
    }
  },
  FerryMan: {
    name: '擺渡人',
    justFinish: {
      // 0: { "npcDialog": "搭船嗎？但現在大澤裡還沒什麼東西⋯⋯是可以讓你去晃晃啦。", "player": [ {, "text": "我要搭船" }, { "text": "不要" } ] }
      0: { "npcDialog": "年輕人，小心不要落水啊⋯⋯", "player": [ { "next": 1, "text": "想要搭船" }, { "text": "這裡是哪裡？", "next": "3" }, { "text": "沒事了" } ] } ,
      1: { "npcDialog": "搭船啊⋯⋯給我一個小小人種子就載你一程。", "player": [ { next: 10, "text": "好啊", delItems: [{name: 'seed',number: 1}], ifNoItemGoTo: 2 }, { "text": "沒有種子啦", next: 2 } ] } ,
      10: { "npcDialog": "坐穩了⋯⋯", "player": [ { "boat": true, delNPCs: ['FerryMan'] } ] } ,
      2: { "npcDialog": "在這裡探險怎能沒有種子？⋯⋯去多<mark>搖樹</mark>吧。", "player": [ {} ] } ,
      3: { "npcDialog": "這裡是迴響大澤，澤面上飄浮著十五顆星球，澤中央有一個神秘的傳說⋯⋯", "player": [ { "next": 4, "text": "星球？" }, { "text": "傳說？", "next": "6" } ] } ,
      4: { "npcDialog": "那些漂浮的星球，是代表迴響的十五個精神⋯⋯這些純粹又簡單的概念，一直存在迴響的文化中。", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "希望你也能遇見觸動你心底的那顆星球。", "player": [ { "text": "我知道了" } ] } ,
      6: { "npcDialog": "據說這片大澤住著一個<mark>澤中女神</mark>，詳情我不是很清楚⋯⋯不如你自己去看看吧。", "player": [ { "text": "好的" } ] }
    }
  },
  CoachElfTrunk: {
    name: '教練時空膠囊',
    justFinish: {
      0: { "npcDialog": "這裡是 Coach Elf 的工房！可以窺見便利貼<mark>誕生的過程</mark>⋯⋯", "player": [ { "next": 1, "modal": "s2_studio_3_time_capsule", "text": "探頭去看" }, { "text": "沒什麼好看的" } ], "changeName": "elfName" }
    }
  },
  LoopElfTrunk: {
    name: '迴圈包時空膠囊',
    justFinish: {
      0: { "npcDialog": "這裡是 Loop Elf 的工房！可以窺見行動錦囊<mark>誕生的過程</mark>⋯⋯", "player": [ { "next": 1, "modal": "s2_studio_2_time_capsule", "text": "探頭去看" }, { "text": "沒什麼好看的" } ], "changeName": "elfName" }
    }
  },
  ActionElfTrunk: {
    name: '任務包時空膠囊',
    justFinish: {
      0: { "npcDialog": "這裡是 First Action Elf 的工房！可以窺見戰術背包<mark>誕生的過程</mark>⋯⋯", "player": [ { "next": 1, "modal": "s2_studio_1_time_capsule", "text": "探頭去看" }, { "text": "沒什麼好看的" } ], "changeName": "elfName" }
    }
  },
  Mural: {
    name: '樹洞裡的壁畫',
    notFinish: {
      0: { "npcDialog": "這裡的牆壁上好像有些什麼，但光線太暗了，看不見東西⋯⋯", "changeName": "elfName" }
    },
    justFinish: {
      0: { "npcDialog": "這個壁畫記載了初代行動者的信念，感覺有滂薄的能量從中湧出⋯⋯", "player": [ { "text": "仔細查看", "modal": "s2_theory_of_change" }, { "text": "我只是經過" } ] }
    }
  },
  TrunkLight: {
    name: '古老的樹',
    notFinish: {
      // useless
      0: { "npcDialog": "沒有<mark>火柴</mark>的話，是沒辦法點亮火炬的。", "player": [ { "next": 1 } ], "changeName": "elfName" } ,
      1: { "npcDialog": "山屋的<mark>老協作</mark>好像可以幫忙，去找他問問看吧。", "changeName": "player" }
    },
    justFinish: {
      0: { "npcDialog": "是否用火柴<mark>點亮火炬</mark>？", "player": [ { "next": 1, "text": "是", event: ['Trunk', 'TrunkFire', 'TrunkTorch'], complete: '迴圈森林的奇觀：點燃了火炬，照亮了牆上的古老壁畫。，', delMission: 'TrunkLight' }, { "text": "先等等" } ] }
    }
  },
  Areca: {
    name: '精選團隊七',
    justFinish: {
      0: { "npcDialog": "這是過往的行動者</mark>所種下的種子，他們雖與<mark>迴響擦肩</mark>而過，卻走得更遠，現在依然行動著。", "player": [ { "text": "想聽故事！", "modal": "s2_team_type_tree_7" }, { "text": "我只是經過" } ], "changeName": "elfName" }
    }
  },
  Cedar: {
    name: '精選團隊六',
    justFinish: {
      0: { "npcDialog": "這是過往的行動者所種下的種子。他們在參與迴響過後，<mark>加入了迴響的團隊</mark>，成為我們的一份子！", "player": [ { "text": "想聽故事！", "modal": "s2_team_type_tree_6" }, { "text": "我只是經過" } ], "changeName": "elfName" }
    }
  },
  Juniper: {
    name: '精選團隊五',
    justFinish: {
      0: { "npcDialog": "這是過往的行動者所種下的種子，他們雖停下了原本在迴響的行動，<mark>選擇了其他議題</mark>繼續前行！", "player": [ { "text": "想聽故事！", "modal": "s2_team_type_tree_5" }, { "text": "我只是經過" } ], "changeName": "elfName" }
    }
  },
  Willow: {
    name: '精選團隊四',
    justFinish: {
      0: { "npcDialog": "這是過往的行動者所種下的種子，他們把參加迴響的議題當作了正職外的 <mark>Side Project</mark>，至今仍持續前行！", "player": [ { "text": "想聽故事！", "modal": "s2_team_type_tree_4" }, { "text": "我只是經過" } ], "changeName": "elfName" }
    }
  },
  Oak: {
    name: '精選團隊三',
    justFinish: {
      0: { "npcDialog": "這是過往的行動者所種下的種子，據說他們把行動<mark>接棒給其他人</mark>了，發揮了很好的影響力。", "player": [ { "text": "想聽故事！", "modal": "s2_team_type_tree_3" }, { "text": "我只是經過" } ], "changeName": "elfName" }
    }
  },
  Cycad: {
    name: '精選團隊二',
    justFinish: {
      0: { "npcDialog": "這是過往的行動者所種下的種子，他們在行動後，理解議題的更多樣貌，最後聽從內心的聲音選擇<mark>停下來</mark>。", "player": [ { "text": "想聽故事！", "modal": "s2_team_type_tree_2" }, { "text": "我只是經過" } ], "changeName": "elfName" }
    }
  },
  Maple: {
    name: '精選團隊一',
    justFinish: {
      0: { "npcDialog": "這是過往的行動者所種下的種子，他們如今已經把當初參賽的議題當作<mark>正職</mark>，全心投入中⋯⋯", "player": [ { "text": "想聽故事！", "modal": "s2_team_type_tree_1" }, { "text": "我只是經過" } ], "changeName": "elfName" }
    }
  },
  FinalStone: {
    name: '三大精神',
    notFinish: {
      0: { "npcDialog": "Eh？表面已經有些損毀的石碑，看不清楚上頭的文字，是否<mark>修復</mark>？", "player": [ { "next": "2", "text": "修修看" }, { "text": "還是算了", "next": "1" } ], "changeName": "elfName" } ,
      1: { "npcDialog": "（一陣風吹過，石碑有點搖晃，有如風中蟾蜍。）", "player": [ {} ], "changeName": "損毀的石碑" } ,
      2: { "npcDialog": "Hmmm⋯⋯損毀的有些嚴重，似乎需要一些工具，如果有<mark>砂紙</mark>、<mark>鑿刀</mark>和<mark>毛刷</mark>應該就好辦了。", "player": [ { "next": 3 } ], "changeName": "elfName" } ,
      3: { "npcDialog": "或許森林中的<mark>小動物</mark>們會有這些工具！不過沒關係，這可以之後再來修，你<mark>先往前走</mark>吧！", "player": [ { "text": "我知道了！" } ], "changeName": "elfName" }
    },
    justFinish: {
      0: { "npcDialog": "Eh？表面已經有些損毀的石碑，看不清楚上頭的文字，是否<mark>修復</mark>？", "player": [ { "next": "2", "text": "修修看" }, { "text": "還是算了", "next": "1" } ], "changeName": "elfName" } ,
      1: { "npcDialog": "（一陣風吹過，石碑有點搖晃，有如風中蟾蜍。）", "player": [ {} ], "changeName": "損毀的石碑" } ,
      2: { "npcDialog": "咦，手怎麼就自己動起來了，剛剛小動物們送我的<mark>砂紙</mark>、<mark>鑿刀</mark>和<mark>毛刷</mark>，全部派上用場了！", "player": [ { "next": 3, event: ['FinalStone'], delNPCs: ['FinalStone'] } ], "changeName": "player" } ,
      3: { "npcDialog": "啊⋯⋯原來如此，其實這些精神本來就存在石頭中，我只是把不需要的部份去掉而已。", "player": [ { "next": 4 } ], "changeName": "player" } ,
      4: { "npcDialog": "石像雕刻完成，上面的文字看起來好像是⋯⋯<mark>三大精神</mark>。", "player": [ { "text": "仔細查看石碑", "modal": "s2_three_spiritual", complete: '石碑完成' } ] }
    },
    finished: {
      0: { "npcDialog": "石像雕刻完成，上面的文字看起來好像是⋯⋯<mark>三大精神</mark>。", "player": [ { "text": "仔細查看石碑", "modal": "s2_three_spiritual" } ] }
    }
  },
  Beaver3: {
    name: '河狸一族',
    notFinish: {
      0: { "npcDialog": "河狸們好像還有問題沒有解決，要不要到下游去找他們呢？", "player": [{"text": "馬上去" } ], "changeName": "elfName"  }
    },
    justFinish: {
      0: { "npcDialog": "Weeee！又遇到了<mark>河狸一族</mark>，這次他們看起來很開心！", "player": [ { "next": 1 } ], "changeName": "elfName"  } ,
      1: { "npcDialog": "自從上次的發想後，河狸一族找上了擅長程式設計的老友單身漢——孤狗，設計了一款視訊軟體。", "player": [ { "next": 2 } ], "changeName": "elfName"  } ,
      2: { "npcDialog": "那款視訊軟體能夠即時呈現背景資訊，在過程中調整參數，視覺化地了解環境影響。", "player": [ { "next": 3 } ], "changeName": "elfName"  } ,
      3: { "npcDialog": "搭配這個軟體運用了區塊鏈的技術，將決議的過程保存，不會有會議記錄不見的問題。", "player": [ { "next": 4, "text": "也太超展開了⋯⋯" } ], "changeName": "elfName"  } ,
      4: { "npcDialog": "且會議後達成的公共效益，將會由承包商評估，邀請與會者聯名發布。", "player": [ { "next": 5 } ], "changeName": "elfName"  } ,
      5: { "npcDialog": "第一場會議因為行動夥伴 <mark>Leo</mark> 出借場地、<mark>Jerry</mark> 幫忙拍照、<mark>張維</mark>協助行銷而轟動一時。", "player": [ { "next": 6, "text": "太酷了！" } ], "changeName": "elfName"  } ,
      6: { "npcDialog": "地方官員注意到後，推動將迴圈森林化為集水區加強保護，河狸一族也順利的將家園遷到此處。", "player": [ { "next": 7, "text": "٩(๑❛ᴗ❛๑)۶" } ], "changeName": "elfName"  } ,
      7: { "npcDialog": "為了答謝您的幫助，他們將這裡命名為：<mark>player大壩</mark>", "player": [ { "next": 8, "text": "好榮幸⋯⋯能幫到他們真是太好了", "complete": "協助河狸解決困難，美好的結局！" } ], "changeName": "elfName"  }
    },
    finished: {
      0: { "npcDialog": "歡迎來player大壩玩～" }
    }
  },
  Bear: {
    name: '熊夫妻',
    notFinish: {
      ...BearBasic,
      4: { "npcDialog": "還記得森林左上角的<mark>教練小小人</mark>嗎？他們一定有辦法的！", "player": [ {"text": "立刻去找他們" } ], "changeName": "elfName" }
    },
    justFinish: {
      ...BearBasic,
      4: { "npcDialog": "不是有拿到教練小小人給的<mark>便利貼</mark>嗎？那出來用吧！", "player": [ { "next": 5, "text": "對耶！" } ], "changeName": "elfName" } ,
      5: { "npcDialog": "好壯觀！熊夫妻分別用便利貼下載了自己對這個議題的想法，直到貼成整個牆面⋯⋯", "player": [ { "next": 6 } ], "changeName": "elfName" } ,
      6: { "npcDialog": "初步分類以後才驚訝的發現，原來他們倆個一直在關注的是<mark>不同的問題</mark>！", "player": [ { "next": 7 } ], "changeName": "elfName" } ,
      7: { "npcDialog": "熊老公在意的是部落小孩未來的<mark>教育機會</mark>，熊太太則特別關注隔代教養下，熊寶寶們的<mark>心理健康</mark>問題。", "player": [ { "next": 8 } ], "changeName": "elfName" } ,
      8: { "npcDialog": "乍聽之下好像都是教育，但其實應該用不同的方式著力⋯⋯", "player": [ { "next": 9, "text": "他們打算怎麼做呢？" } ], "changeName": "elfName" } ,
      9: { "npcDialog": "發現這件事情後，熊爸爸投入了行動夥伴阿屁的團隊，協助做好孩子們的未來教育。", "player": [ { "next": 10 } ], "changeName": "elfName" } ,
      10: { "npcDialog": "熊媽媽則與藺子聯手，投入苑裡地方的爺孫心輔議題。", "player": [ { "next": 11, "text": "太好了！", "complete": "幫助熊夫妻釐清彼此的想法！太好了" } ], "changeName": "elfName" } ,
    },
    finished: {
      0: { "npcDialog": "熊夫妻用便利貼溝通想法後，明確地知道了彼此的想法唷！ʕ•̀o•́ʔ", "player": [ { "text": "加油！" }, { "next": 1, "text": "他們的想法是？" } ], "changeName": "elfName"  } ,
      1: { "npcDialog": "熊老公在意的是部落小孩未來的<mark>教育機會</mark>，熊太太則特別關注隔代教養下，熊寶寶們的<mark>心理健康</mark>問題。", "player": [ { "text": "想起來了！" } ], "changeName": "elfName"  }
    }
  },
  CoachElf: {
    name: 'Coach Elf',
    justFinish: {
      0: { "npcDialog": "Cooool！我們是 Coach Elf，伴你一起<mark>教練</mark>小小人！", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "我們在這裡磨練怎麼覺察話語背後的考量，接住團隊裡<mark>不同型態的觀點</mark>。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "再判斷是否犀利的直球對決，還是用變化球緩和討論的氛圍。", "player": [ { "next": 3, "text": "教練是什麼" }, { "text": "教練可以用來幹嘛", "next": "5" } ] } ,
      ...CoachElfBasic,
      7: { "npcDialog": "既然你對教練有興趣，就送你這個我們常用的便利貼吧！", "player": [ { "next": 8, "text": "" } ] } ,
      8: { "npcDialog": "覺得怪怪的時候，花一點時間帶著團隊夥伴把覺得怪怪的地方寫下來，常常會帶來意想不到的突破喔！", "player": [ {"text": "馬上查看！", "rewards": [ "postit" ], "complete": "獲得能幫助我們整理想法的——便利貼！", modal: 's2_studio_3' },{"text": "先收在包包", "rewards": [ "postit" ], "complete": "獲得能幫助我們整理想法的——便利貼！"} ] } ,
    },
    finished: {
      0: { "npcDialog": "Cooool！還有什麼事情找我嗎？≖‿≖", "player": [ { "next": "3", "text": "教練是什麼" }, { "text": "教練可以用來幹嘛", "next": "5" } ]  },
      ...CoachElfBasic,
      7: { "npcDialog": "想知道更多細節的話，<mark>點按 B 鍵</mark>打開你的背包，選擇便利貼<mark>按 A 鍵</mark>就可以囉！Cooool！", "player": [ { "text": "知道了！" } ] }
    }
  },
  Beaver2: {
    name: '河狸',
    notFinish: {
      ...Beaver2Basic,
      5: { "npcDialog": "還記得森林右上角的<mark>小小人迴圈聚落</mark>嗎？去找他們拿法寶吧！", "player": [ {"text": "立刻去找他們" } ], "changeName": "elfName" }
    },
    justFinish: {
      ...Beaver2Basic,
      5: { "npcDialog": "記得小小人迴圈聚落給的錦囊嗎？根據<mark>行動迴圈</mark>，你會怎麼指引他們的下一步呢？", "player": [ { "next": "7", "text": "採取最小可行行動" }, { "next": "8", "text": "回頭反思" }, { "next": "9", "text": "發想新的切入點" } ], "changeName": "elfName" } ,
      6: { "npcDialog": "根據<mark>行動迴圈</mark>，你會怎麼指引他們的下一步呢？", "player": [ { "next": 7, "text": "採取最小可行行動" }, { "next": "8", "text": "回頭反思" }, {"next": "9",  "text": "發想新的切入點" } ], "changeName": "elfName" } ,
      7: { "npcDialog": "行動⋯⋯但是，此時更重要的，好像是得先有個下一步的方向。", "player": [ { "next": "6", "text": "再想想別的辦法" } ], "changeName": "elfName" } ,
      8: { "npcDialog": "幾次會議後，都得到一樣的結論⋯⋯行動卡關了，沒有新的資訊可以反思了。", "player": [ { "next": "6", "text": "再想想別的辦法" } ], "changeName": "elfName" } ,
      9: { "npcDialog": "原來如此，他們其實也想有新的切入點，但該怎麼做呢？", "player": [ { "next": 10, "text": "翻一翻錦囊" } ], "changeName": "elfName" } ,
      10: { "npcDialog": "（行動錦囊掉出一張古老的「<a href='https://docs.google.com/presentation/d/e/2PACX-1vTXXWxpPsf53rsvaOUDWtNEEjwuXTTr7itzPpqvWg-HRKPfAf1_S6GeZESyrW5mQJoyc-AThBIhYMWv/pub?start=false&loop=false&delayms=3000' target='_blank'>HMW 問句</a>」，據說能幫助<mark>切換視角</mark>，從複雜觀點中找到行動的切入點。）", "player": [ { "next": 11 } ], "changeName": "player" } ,
      11: { "npcDialog": "Wow⋯⋯河狸們發想了很多新的 HMW，「我們如何能讓願意溝通成為承包商的正向品牌形象？」", "player": [ { "next": 12 } ], "changeName": "elfName"  } ,
      12: { "npcDialog": "「我們如何設計溝通的介面消除兩邊的資訊門檻？」", "player": [ { "next": 13 } ], "changeName": "elfName"  } ,
      13: { "npcDialog": "「我們如何降低溝通的成本？」", "player": [ { "next": 14 } ], "changeName": "elfName"  } ,
      14: { "npcDialog": "「我們如何能讓這個溝通對雙方都有正向回饋？」", "player": [ { "next": 15 } ], "changeName": "elfName"  } ,
      15: { "npcDialog": "自從 HMW 問句出現後，大家又開始充滿靈感了！", "player": [ { "next": 16, "text": "太好了！" } ], "changeName": "elfName"  } ,
      16: { "npcDialog": "但是⋯⋯河狸們感到很苦惱，因為他們已經沒有東西可以答謝你了。 (ఠ్ఠ˓̭ఠ్ఠ)", "player": [ { "text": "沒關係啦", "complete": "再度幫助河狸們！寫著他們想到新的切入點，太好了！" } ], "changeName": "elfName"  }
    },
    finished: {
      0: { "npcDialog": "河狸們得到行動錦囊的幫助後，正在<mark>發想新的切入點</mark>唷！ (*≧▽≦)", "player": [ { "text": "加油！" }, { "next": 1, "text": "他們的發想是？" } ], "changeName": "elfName"  } ,
      1: { "npcDialog": "河狸們發想了很多新的方向，「我們如何能讓願意溝通成為承包商的正向品牌形象？」", "player": [ { "next": 2 } ], "changeName": "elfName"  } ,
      2: { "npcDialog": "「我們如何設計溝通的介面消除兩邊的資訊門檻？」", "player": [ { "next": 3 } ], "changeName": "elfName"  } ,
      3: { "npcDialog": "「我們如何降低溝通的成本？」", "player": [ { "next": 4 } ], "changeName": "elfName"  } ,
      4: { "npcDialog": "「我們如何能讓這個溝通對雙方都有正向回饋？」", "player": [ { "text": "想起來了！" } ], "changeName": "elfName"  }
    }
  },
  Mole: {
    name: '鼴鼠',
    notFinish: {
      ...MoleBasic,
      7: { "npcDialog": "還記得我們經過的那群<mark>小小人迴圈聚落</mark>嗎？他們應該可以幫得上鼴鼠！", "player": [ {"text": "立刻去找他們" } ], "changeName": "elfName" }
    },
    justFinish: {
      ...MoleBasic,
      4: { "npcDialog": "記得小小人迴圈聚落給的錦囊嗎？根據<mark>行動迴圈</mark>，你會怎麼指引他們的下一步呢？", "player": [ { "next": "6", "text": "採取最小可行行動" }, { "text": "回頭反思", "next": "7" }, { "text": "發想新的切入點", "next": "14" } ], "changeName": "elfName" } ,
      5: { "npcDialog": "根據<mark>行動迴圈</mark>，你會怎麼指引他們的下一步呢？", "player": [ { "next": 6, "text": "採取最小可行行動" }, { "text": "回頭反思", "next": "7" }, { "text": "發想新的切入點", "next": "14" } ], "changeName": "elfName" } ,
      6: { "npcDialog": "鼴鼠已經採取過許多行動了⋯⋯此時大家心裡隱約有些不同於抗爭的想法，但<mark>還沒有共識</mark>。", "player": [ { "next": "5", "text": "再想想別的辦法" } ], "changeName": "elfName" } ,
      7: { "npcDialog": "對啊！鼴鼠們恍然大悟，行動後的下一步，<mark>不見得是另一個行動</mark>⋯⋯但具體該怎麼做呢？", "player": [ { "next": 8 } ], "changeName": "elfName" } ,
      8: { "npcDialog": "（打開行動的錦囊後，掉出一張古老的「<a href='https://docs.google.com/presentation/d/e/2PACX-1vRAhvItruXiwDqQ6tVv2GW_8mzTK9qDVdITm1fIn1MEgZODe8Yzp2LG8aV0flD3gCxQMADxi2-Z9rFg/pub?start=false&loop=false&delayms=3000' target='_blank'>打包捕捉表</a>」，據說是行動後<mark>整理新資訊</mark>的法寶。）", "player": [ { "next": 9 } ], "changeName": "player" } ,
      9: { "npcDialog": "原來如此，鼴鼠幫用了打包捕捉表，整理完大家過往行動的<mark>經驗和觀點</mark>⋯⋯", "player": [ { "next": 10 } ], "changeName": "elfName" } ,
      10: { "npcDialog": "他們驚訝的發現，抗爭本來是為了換取關注，但幾次行動下來反而讓他們更加孤立。", "player": [ { "next": 11 } ], "changeName": "elfName" } ,
      11: { "npcDialog": "既然是為了換取關注，呈現生活的困境，喚起同理似乎比較能與更多關注這件事情的人有所連結。", "player": [ { "next": 12 } ], "changeName": "elfName" } ,
      12: { "npcDialog": "找到這個切入點，他們原本灰暗的眼睛，都亮了起來！", "player": [ { "next": 13 } ], "changeName": "elfName" } ,
      13: { "npcDialog": "為了答謝你，鼴鼠幫主決定送你用地底下的堅硬礦石反覆敲打製成的「<mark>砂紙</mark>」，雖然暫時不知道有什麼用⋯⋯", "player": [ { "text": "哇！謝謝你們", "rewards": [ "sandpaper" ], "complete": "幫助了鼴鼠幫反思過往行動，可喜可賀！" } ], "changeName": "elfName" } ,
      14: { "npcDialog": "Hmmm⋯⋯他們曾天馬行空地想過幾個，但<mark>還沒有作出決策的觀點</mark>呀 ಥ⌣ಥ", "player": [ { "next": "5", "text": "再想想別的辦法" } ], "changeName": "elfName" }
    },
    finished: {
      0: { "npcDialog": "鼴鼠們得到行動錦囊的幫助後，正在<mark>反思新的方向</mark>唷！ (*≧▽≦)", "player": [ { "text": "加油！" }, { "next": 1, "text": "他們的反思是？" } ], "changeName": "elfName"  } ,
      1: { "npcDialog": "他們決定呈現自己在森林生活的困境，去喚起大眾的同理以產生連結。", "player": [ { "text": "想起來了！" } ], "changeName": "elfName"  }
    }
  },
  Fox: {
    name: '好狐',
    notFinish: {
      ...FoxBasic,
      7: { "npcDialog": "我想到了！剛剛我們經過的那群<mark>小小人迴圈聚落</mark>，他們的方法應該可以幫得上狐狸！", "player": [ {"text": "立刻去找他們" } ], "changeName": "elfName" }
    },
    justFinish: {
      ...FoxBasic,
      7: { "npcDialog": "記得小小人迴圈聚落給的錦囊嗎？根據<mark>行動迴圈</mark>，你會怎麼指引他們的下一步呢？", "player": [ { "next": 9, "text": "採取最小可行行動" }, { "next": 16,  "text": "回頭反思" }, { "next": 17,  "text": "發想新的切入點" } ], "changeName": "elfName"  } ,
      8: { "npcDialog": "根據行動迴圈，你會怎麼指引他們的下一步呢？", "player": [ { "next": 9, "text": "採取最小可行行動" }, { "next": 16,  "text": "回頭反思" }, { "next": 17,  "text": "發想新的切入點" } ], "changeName": "elfName"  } ,
      9: { "npcDialog": "狐狸們覺得好像是不錯的方向，那要怎麼開啟<mark>最小的可行行動</mark>呢？", "player": [ { "next": 10, "text": "翻一翻錦囊" } ], "changeName": "elfName"  } ,
      10: { "npcDialog": "（打開行動的錦囊後，掉出一張古老的「<a href='https://docs.google.com/presentation/d/e/2PACX-1vRGpgse7m7ta2iph7QRYWvChrw80pjK_2Lz258yWQD9zQWhgPStjAEvu58-ksYrmYCQBp8CyC4KWbEK/pub?start=false&loop=false&delayms=3000' target='_blank'>行動規畫書</a>」，據說是行動前整理假設，<mark>幫助聚焦</mark>的法寶。）", "player": [ { "next": 11 } ], "changeName": "elfName" , "changeName": "player" } ,
      11: { "npcDialog": "原來如此⋯⋯狐群們討論後發現他們<mark>對議題的了解太少了</mark>，他們並不知道對狐狸有壞印象的人在想些什麼。", "player": [ { "next": 12 } ], "changeName": "elfName"  } ,
      12: { "npcDialog": "直接做一個網站成本太高，也不確定會不會有幫助，所以他們決定先做一本繪本，把汙名化的情境整理出來。", "player": [ { "next": 13 } ], "changeName": "elfName"  } ,
      13: { "npcDialog": "他們可以先邀請那些對狐狸印象不好的人讀讀，聽聽他們的想法，再看要怎麼繼續行動。", "player": [ { "next": 14, "text": "你快去讀吧" } ], "changeName": "elfName"  } ,
      14: { "npcDialog": "我⋯⋯我沒有討厭他們啦！ (ఠ్ఠ ˓̭ ఠ్ఠ) 話說他們正在討論要怎麼騙狸貓來幫他們畫畫⋯⋯", "player": [ { "next": 15, "text": "真的很狡猾啊⋯⋯" } ], "changeName": "elfName"  } ,
      15: { "npcDialog": "Ahh！他們為了感謝你的幫助，決定送給你用狐狸尾巴的毛做成的「<mark>毛刷</mark>」，雖然暫時不知道有什麼毛用⋯⋯", "player": [ { "text": "他們其實很善良呀！", "rewards": [ "brush" ], "complete": "幫助狐狸踏出最小可行行動，太棒了！" } ], "changeName": "elfName"  },
      16: { "npcDialog": "但是⋯⋯狐狸們都<mark>還沒有任何行動</mark>，反思就只是沆瀣一氣的檢討他人。", "player": [ { "next": "8", "text": "再想想別的辦法" } ], "changeName": "elfName"  } ,
      17: { "npcDialog": "狐狸們曾天馬行空地想過幾個，但都比網站還不可行⋯⋯他們對這個議題的<mark>了解還太少了</mark>呀。", "player": [ { "next": "8", "text": "再想想別的辦法" } ], "changeName": "elfName" }
    },
    finished: {
      0: { "npcDialog": "狐狸們得到行動錦囊的幫助後，正在努力地開啟他們的<mark>最小行動</mark>唷！(￣▽￣)~*", "player": [ { "text": "加油！" }, { "next": 1, "text": "他們的行動是？" } ], "changeName": "elfName"  } ,
      1: { "npcDialog": "他們想先做一本繪本，把汙名化的情境整理出來，讓那些有刻板印象的人先讀讀看。", "player": [ { "text": "想起來了！" } ], "changeName": "elfName"  }
    }
  },
  LoopElf: {
    name: 'Loop Elf',
    justFinish: {
      0: { "npcDialog": "player，你看那個神秘的三角形！他們是<mark>善於應對卡關</mark>的 Loop Elf！", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "Loooop！我們是 Loop Elf，在這裡練習有意識地依照<mark>迴圈</mark>行動！ ʕ •̀ o •́ ʔ", "player": [ { "next": 2, "text": "依照迴圈行動？" } ] } ,
      2: { "npcDialog": "當我們在面對某個議題，卻遇到顛簸卡關的時候，就可以依照<mark>行動迴圈</mark>的指向行動！", "player": [ { "next": 3, "text": "行動迴圈是甚麼" }, { "text": "行動迴圈可以用來幹嘛", "next": "5" } ] } ,
      ...LoopElfBasic,
      7: { "npcDialog": "看你好像很有興趣，這個<mark>行動錦囊</mark>就送給你吧！裡面有更詳細的解說唷。", "player": [ { "next": 8, "text": "也太棒了吧" } ] } ,
      8: { "npcDialog": "Loooop！只要能好好地判斷目前在迴圈的位置，就能幫卡關的青年<mark>指引下一步行動</mark>唷！", "player": [ { "text": "馬上打開", "complete": "獲得能幫助我們持續行動的——迴圈包！", "modal": "s2_studio_2", "rewards": [ "loopPack" ] }, { "text": "先收在包包", "complete": "獲得能幫助我們持續行動的——迴圈包！", "rewards": [ "loopPack" ] } ] }
    },
    finished: {
      0: { "npcDialog": "Looooop！還有什麼事情找我嗎？「(°ヘ°)", "player": [ { "next": "3", "text": "行動迴圈是什麼" }, { "text": "行動迴圈可以用來幹嘛", "next": "5" } ]  },
      ...LoopElfBasic,
      7: { "npcDialog": "想知道更多細節的話，<mark>點按 B 鍵</mark>打開你的背包，選擇行動錦囊<mark>按 A 鍵</mark>就可以囉！Looooop！", "player": [ { "text": "知道了！" } ] }
    }
  },
  Beaver1: {
    name: '河狸一家',
    notFinish: {
      ...Beaver1Basic,
      100: { "npcDialog": "我知道了！剛剛我們經過的那群小小人聚落，他們是我的朋友，應該可以幫得上他們！", "player": [ {"text": "立刻去找他們" } ], "changeName": "elfName" }
    },
    justFinish: {
      ...Beaver1Basic,
      100: { "npcDialog": "player！趕快拿出剛剛小小人聚落給的<mark>戰術背包</mark>吧！", "player": [ { "next": "3", "text": "好！拿去吧" } ], "changeName": "elfName" } ,
      3: { "npcDialog": "河狸們眼睛為之一亮⋯⋯他們打開戰術背包，按照裡面的工具一邊<mark>釐清假設</mark>、一邊找<mark>利害關係人訪談</mark>⋯⋯", "player": [ { "next": 4 } ], "changeName": "elfName" } ,
      4: { "npcDialog": "終於！他們在訪談後的逐字稿裡看出端倪，更清楚他們想要解決的問題是⋯⋯", "player": [ { "next": 5 } ], "changeName": "elfName" } ,
      5: { "npcDialog": "「大型工程無法<mark>在初期喚起利害關係人的危機意識</mark>，常常等到事情無法挽回才費盡力氣進行無效的抗爭。」", "player": [ { "next": 6 } ], "changeName": "elfName" } ,
      6: { "npcDialog": "現在每隻河狸看來都躍躍欲試了！(≧▽≦) 太好了！player！", "player": [ { "next": 7 } ], "changeName": "elfName" } ,
      7: { "npcDialog": "為了答謝你的幫助，河狸一家決定送給你用老河狸的門牙製成的「<mark>鑿刀</mark>」。", "player": [ { "next": 8, "text": "那是什麼？" } ], "changeName": "elfName" } ,
      8: { "npcDialog": "這可是他們打造家園的重要工具，雖然暫時不知道有甚麼用⋯⋯ (^～^;)ゞ", "player": [ { text: '還是很謝謝他！', rewards:['chisel'], complete: '幫助了河狸一家釐清他們的問題，太好了！' } ], "changeName": "elfName" }
    },
    finished: {
      0: { "npcDialog": "河狸一族得到戰術背包的幫助後，正在努力地發想下一步行動唷！٩(^ᴗ^)۶", "player": [ { "text": "加油！" }, { "next": 1, "text": "他們的問題是？" } ], "changeName": "elfName"  } ,
      1: { "npcDialog": "「大型工程無法<mark>在初期喚起利害關係人的危機意識</mark>，常常等到事情無法挽回才費盡力氣進行無效的抗爭。」", "player": [ { "text": "想起來了！" } ], "changeName": "elfName"  }
    }
  },
  FirstActionElf: {
    name: 'First Action Elf',
    justFinish: {
      0: { "npcDialog": "player，你看右邊！他們善於<mark>跨出第一步行動</mark>的 First Action Elf！", "player": [ { "next": 100 } ], "changeName": "elfName" },
      100: { "npcDialog": "Weeeee！我們是 First Action Elf，在這裡開發能讓人能<mark>安心採取行動<mark>的戰術背包 (⁎˃ᆺ˂)", "player": [ { "next": 1, "text": "戰術背包？" } ] } ,
      1: { "npcDialog": "行動者們把它稱作<mark>任務包</mark>，他能幫助想要行動的人，快而穩健地踏出第一步 (;｀O´)o", "player": [ { "next": 2, "text": "任務包是什麼" }, { "text": "任務包可以用來幹嘛", "next": "3" } ] } ,
      ...FirstActionElfBasic,
      5: { "npcDialog": "不如這個戰術背包就送給你吧，他可以幫助躊躇不前的人開始行動！", "player": [ { "next": 6, "text": "謝謝你們！" }, { "next": 6, "text": "怎麼好意思" } ] } ,
      6: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開 Σ(▼□▼メ)", "player": [ { "rewards": [ "missionPack" ], "text": "馬上打開！", "modal": "s2_studio_1", "complete": "獲得能幫助我們踏出第一步行動的——任務包！" }, { "rewards": [ "missionPack" ], "complete": "獲得能幫助我們踏出第一步行動的——任務包！", "text": "先收在包包" } ] }
    },
    finished: {
      0: { "npcDialog": "Weeee！player！還有什麼事情找我嗎？<(｀^´)>", "player": [ { "next": "2", "text": "任務包是什麼" }, { "text": "任務包可以用來幹嘛", "next": "3" } ] },
      ...FirstActionElfBasic,
      5: { "npcDialog": "想知道更多細節的話，<mark>點按 B 鍵</mark>打開你的背包，選擇戰術背包<mark>按 A 鍵</mark>就可以囉！Weee！", "player": [ { "text": "好多背包⋯⋯" } ] }
    }
  },
  ElfHelp: {
    name: 'elfName',
    sound: 'rock_moving',
    justFinish: {
      0: { "npcDialog": "這是被大風吹落的岩石，岩塊好像在<mark>搖動</mark>？", "player": [ { "next": 1, "text": "推動岩塊" }, { "text": "不予理會", "next": "11" } ], "changeName": "player" } ,
      1: { "npcDialog": "好像有什麼聲音？", "player": [ { "next": 2 } ], "changeName": "player" } ,
      2: { "npcDialog": "Ouch⋯⋯好痛啊，總算脫險了⋯⋯Yo，是你救了我嗎？", "player": [ { "next": 3, "text": "你在哪？", getElf: true, delNPCs: ['ElfHelp'],  } ], "changeName": "未知的聲音" } ,
      3: { "npcDialog": "我在你的<mark>肩膀上</mark>喔，player！", "player": [ { "next": 4, "text": "你怎麼知道我的名字？" } ] } ,
      4: { "npcDialog": "傻蛋，瀏覽器的 Cookie 有寫啊！但你竟然看的到我，只有真心相信行動價值的人，才有辦法看到我。", "player": [ { "next": "6", "text": "點點頭" }, { "text": "回嗆他", "next": "5" } ] } ,
      5: { "npcDialog": "Oh yeah...I do believe that the only impossible journey is the one you never begin.", "player": [ { "next": 6, "text": "Fine" } ] } ,
      6: { "npcDialog": "無論如何，謝謝你，player。", "player": [ { "next": 100} ] } ,
      100: { "npcDialog": "我們小小人世代都以助人為目標，沒想到今天變成我被你幫助，你要逛<mark>迴圈森林</mark>嗎？", "player": [ { "next": 7, "text": "當然好" } ] } ,
      7: { "npcDialog": "Weeee，就讓我跟著你走吧！我可以幫你<mark>聽懂動物的語言</mark>喔。", "player": [ { "next": 8, "text": "幫小小人取名", input: { question: '幫小小人取個名吧！：', key: 'elfName' } } ] } ,
      8: { "npcDialog": "你說什麼⋯⋯", "player": [ { "next": 9 } ] } ,
      9: { "npcDialog": "<mark>elfName</mark>？Wow 第一次有人這樣叫我。", "player": [ { "next": 10 } ] } ,
      10: { "npcDialog": "迴圈森林比較特別，建議跟沿途的所有人事物都互動看看，<mark>不要遺漏囉</mark>！", "player": [ {text: 'Go!', "complete": "得到了小小人夥伴！可以進入森林探險囉", passAisle: ['bridge'], delMission: 'ElfHelp', delNPCs: ['ForestEntranceFog']} ] } ,
      11: { "npcDialog": "“Don't put limitations on yourself. Other people will do that for you.” – James Cameron", "player": [ {} ], "changeName": "森林深處的聲音" }
    },
  },
  NormalAlder: {
    name: '看似平凡無奇的長者',
    justFinish: {
      0: { "npcDialog": "小伙子⋯⋯你知道歷代登頂的行動者，最大的感悟是什麼嗎？", "player": [ { "next": 1, "text": "是什麼？" }, { "text": "不想知道"} ] } ,
      1: { "npcDialog": "他們體會到萬物和諧共存的關鍵，在於讓事情動起來⋯⋯", "player": [ { "next": 2, "text": "動起來？" } ] } ,
      2: { "npcDialog": "是的，也就是<mark>行動</mark>所產生的漣漪。然而，靜止的、不相信改變的、被動的，常常會讓衝突和矛盾延續。", "player": [ { "next": 99 } ] } ,
      99: { "npcDialog": "你抬頭看看我們面前的這座瀑布⋯⋯", "player": [ { "next": 100, "text": "什麼也沒看見", event: ['Rainbow'] } ] } ,
      100: { "npcDialog": "現在呢？", "player": [ { "next": 3, "text": "是彩虹！" } ] } ,
      3: { "npcDialog": "你看，這瀑布動起來以後⋯⋯飛濺的水珠在陽光下形成<mark>彩虹</mark>。", "player": [ { "next": 4, "text": "哇⋯⋯好美" } ] } ,
      4: { "npcDialog": "於是我們知道⋯⋯至少有七個層次，每一個令人困擾的議題，都<mark>不只有對立的兩極</mark>。", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "你永遠可以在這些顏色之間，找到一個<mark>適合自己</mark>的。認識與自己互補的，欣賞其餘不同的⋯⋯", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "哎，哈哈哈⋯⋯我又在憶當年了⋯⋯年輕人，你要前往<mark>迴圈森林</mark>嗎?\n", "player": [ { "next": 7, "text": "是！我要前往" }, { "text": "不，還是回頭好了", "next": "9" } ] } ,
      7: { "npcDialog": "有許多人曾在迴響這片迴圈森林裡，為他們所在乎的議題行動著，而森林提供了一些<mark>協助</mark>。", "player": [ { "next": 8, text: "什麼協助？" } ] } ,
      8: { "npcDialog": "去找找<mark>森林裡的動物</mark>吧，只要你有足夠的耐心，一定聽得懂的。去吧，才沒有他們講的這麼神秘。", "player": [ {} ] } ,
      9: { "npcDialog": "「真正的發現之旅，不在於尋找新的風景，而是擁有一雙新的眼睛。」——Marcel Proust", "player": [ {} ], "changeName": "森林深處的聲音" }
    }
  },
  Atou: {
    name: '概念啊！阿頭',
    sound: 'npc_m_2',
    notFinish: {
      0: { "npcDialog": "那個⋯⋯前面的山頂風景很美，如果喜歡的話，來找我<mark>拍張照片</mark>吧？", "player": [ { "text": "好啊！", "next": "1" }, { "text": "嗯⋯⋯再考慮一下" } ] } ,
      1: { "npcDialog": "拍一張照片要兩顆<mark>小小人果實</mark>喔，便宜的啦！", "player": [ { "next": 2, "text": "給你", delItems: [{name: 'seed',number: 2}], ifNoItemGoTo: 3 }, { "text": "我沒有種子啦⋯⋯", "next": "3" } ] } ,
      2: { "npcDialog": "好的，讓我們移駕到拍攝現場⋯⋯", "player": [ {readyToTakePicture: true, next: 4} ] } ,
      3: { "npcDialog": "沒種子的話⋯⋯就去<mark>多搖幾顆樹</mark>吧，站在樹下按 A，直到小小人掉下來就可以了。", "player": [ {} ] },
      4: { "npcDialog": "準備好了嗎？五、四、三、二、一⋯⋯", "player": [ {text: '準備好了！', takePicture: true, next: 5} ] },
      5: { "npcDialog": "山上的風景很棒吧！這張照片給你留著當紀念，還想拍照的話隨時來找我吧！", "player": [ {rewards:['picture'], complete: '行動大山的奇觀：看見了山頂的奇觀，與頂上風景合影！'} ] },
    },
    finished: {
      0: { "npcDialog": "想再拍一張嗎？熟客拍一張照片只要一顆<mark>小小人果實</mark>，便宜的啦！", "player": [ { "next": 2, "text": "給你", delItems: [{name: 'seed',number: 1}], ifNoItemGoTo: 3 }, { "text": "我沒有種子啦⋯⋯", "next": "3" } ] } ,
      2: { "npcDialog": "好的，讓我們移駕到拍攝現場⋯⋯", "player": [ {readyToTakePicture: true, next: 4} ] } ,
      3: { "npcDialog": "沒種子的話⋯⋯就去<mark>多推幾顆樹</mark>吧，站在樹下按 A，直到小小人掉下來就可以了。", "player": [ {} ] },
      4: { "npcDialog": "準備好了嗎？五、四、三、二、一⋯⋯", "player": [ {text: '準備好了！', takePicture: true, next: 5} ] },
      5: { "npcDialog": "山上的風景很棒吧！這張照片給你留著當紀念，還想拍照的話隨時來找我吧！", "player": [ {rewards:['picture']} ] },
    }
  },
  LakeGoddess: {
    name: '澤中女神',
    notFinish: {
      // 水底下看起來有東
      0: { "npcDialog": "這裡的水有點深⋯⋯水底下似乎有什麼？", "player": [ { "next": 1 } ], changeName: 'player' },
      1: { "npcDialog": "年輕人，這裡就是傳說中<mark>澤中女神</mark>的所在地啊，是否要丟下你身上的斧頭呢？", "player": [ { text: '好', "next": 3, delItems: [{name: 'ax',number: 1}], ifNoItemGoTo: 2 }, {text: '我沒有斧頭', next: 2}, {text: '沒事了'} ], changeName: '擺渡人' },
      2: { "npcDialog": "這樣啊⋯⋯你身上沒有斧頭，進來大澤以前你有遇到<mark>木匠姊弟</mark>嗎？去找他們製作吧！", changeName: '擺渡人' },
      3: { "npcDialog": "（把身上的斧頭丟入湖中央⋯⋯）", "player": [ { "next": 4, "event": [ "LakeGoddess"], ongoing: '迴響大澤的奇觀：你丟下斧頭，召喚出了澤中女神！'  } ], changeName: 'player'  },
      4: { "npcDialog": "⋯⋯", "player": [ { "next": 30 } ] } ,
    },
    onGoing: {
      // 女神現身，完成大澤區的主線任務（擺渡人）
      // 0: { "npcDialog": "這裡的水有點深⋯⋯水底下似乎有什麼？", "player": [ { "next": 1 } ], changeName: 'player' },
      // 1: { "npcDialog": "手邊正好有一把斧頭，是否丟下斧頭？", "player": [ { "next": 2, "text": "好", "event": [ "LakeGoddess"] }, { "text": "不好" } ], changeName: 'player' },
      99: { "npcDialog": "⋯⋯", "player": [ { "next": 30 } ] } ,
      30: { "npcDialog": "喂，你們這些行動者，一直把斧頭砸下來，真的不怕出人命嗎？", "player": [ { "next": 4, "text": "我⋯⋯" } ] } ,
      4: { "npcDialog": "你知道水底下已經沉了幾百支斧頭了嗎？女神都不用睡覺嗎？", "player": [ { "next": 5, "text": "這⋯⋯", "event": [ "AxCheckin", "AxDecision", "AxStrange", "AxSync", "AxTeamwork", "AxValue" ] } ] } ,
      5: { "npcDialog": "唉！真拿你們沒辦法⋯⋯行動者日以繼夜丟下這些斧頭，我只好把當中的意念，轉化成了你周圍的這六把。", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "行動不只是一個人的事，為了走更長遠的路，你得要記取這些前人的教訓。你去仔細看看這些斧頭吧！", "player": [ { "next": 0 } ] } ,
      0: { "npcDialog": "話說⋯⋯行動者們之前送我的花都乾枯了啊，既然你都來了，能不能幫我去<mark>拿花</mark>呢？", "player": [ { "next": 8, "text": "花？" } ] } ,
      8: { "npcDialog": "你在旅行的時候，是否有遇到一些會跟你談論議題的人呢？他們會給你與議題相關的花，我總共需要 11 朵。", "player": [ { "next": 9 } ] } ,
      9: { "npcDialog": "11 朵花都蒐集完的話，再回來找我吧！", "player": [ { } ] } ,
      // 10: { "npcDialog": "等所有的花都蒐集完之後，再回來找我吧", "player": [ { } ] }
    },
    justFinish: {
      0: { "npcDialog": "行動者，我在你身上聞到了花草芬芳⋯⋯你蒐集完全部的花了？", "player": [ { "next": 1, "text": "沒錯！", "event": [ "Altar" ] } ] } ,
      1: { "npcDialog": "嗯⋯⋯這些花瀰漫著行動者們浪漫的芬芳氣息，讓我心花怒放！", "player": [ { "next": "2" } ] } ,
      2: { "npcDialog": "我就原諒你之前丟斧頭的過錯吧！", "player": [ { "next": 3, "text": "原來還在記恨⋯⋯", "complete": "獻給女神十一朵花，完成了女神的贖罪！" } ] } ,
      3: { "npcDialog": "話說，你知道迴響的傳說——迴響大神嗎？", "player": [ { "next": "1" } ] }
    },
    finished: {
      0: { "npcDialog": "你知道迴響的傳說——迴響大神嗎？", "player": [ { "next": 1, "text": "大神" } ] } ,
      1: { "npcDialog": "據說在探索完這個世界的<mark>所有任務</mark>後，就可以取得找到大神的線索⋯⋯", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "你包包裡有個任務指南對吧？等裡面的十個任務都完成後，去山頂找 <mark>2027 願景石碑</mark>吧！", "player": [ {} ] }
    }
  },
  ActionGod: {
    name: '？？？',
    sound: 'npc_m_god',
    justFinish: {
      ...GodBasic,
      15: { "npcDialog": "送你最後一個紀念品——<mark>小人徽章</mark>，這是每一屆迴響都有的經典紀念物。", "player": [ { "next": "16" } ], changeName: '迴響大神' } ,
      16: { "npcDialog": "但願我們都能打開自己的心，關注身邊那些時常會繃出來，<mark>可以按 A 繼續，卻總是被忽略的人事物。</mark>", "player": [ { "next": "17" } ], changeName: '迴響大神' } ,
      17: { "npcDialog": "player，到此一遊。", "player": [ { "text": "結束遊戲", "rewards": [ "badge" ], complete: '召喚迴響大神，迎來了遊戲的結局。', modalWithDelay: 'credits', "transfer": {x: 206, y: 25}, "delNPCs": [ "ActionGod", "ActionGodFloor" ] } ], changeName: 'player' }
    },
    finished: {
      ...GodBasic,
      15: { "npcDialog": "但願我們都能打開自己的心，關注身邊那些時常會繃出來，<mark>可以按 A 繼續，卻總是被忽略的人事物。</mark>", "player": [ { "next": "16"} ], changeName: '迴響大神' } ,
      // 16: { "npcDialog": "player，到此一遊。", "player": [ { "text": "結束遊戲", "transfer": {x: 206, y: 25}, "delNPCs": [ "ActionGod", "ActionGodFloor" ], modalWithDelay: 'credits' } ], changeName: 'player' },
      16: { "npcDialog": "player，到此一遊。", "player": [ { "text": "結束遊戲", "rewards": [ "badge" ], complete: '召喚迴響大神，迎來了遊戲的結局。', modalWithDelay: 'credits', "transfer": {x: 206, y: 25}, "delNPCs": [ "ActionGod", "ActionGodFloor" ] } ], changeName: 'player' }
    },
  },
  Carpentar: {
    // TODO: 抽by趴 - 大致完成了，但腳本會想請抽看看有沒有想修改的
    name: '木匠弟弟',
    justFinish: {
      0: { "npcDialog": "（錘子敲打的聲音不絕於耳⋯⋯）你在做什麼？", "player": [ { "next": 1 } ], "changeName": "player" } ,
      1: { "npcDialog": "歡迎光臨！我是木匠弟弟，會利用迴響大本營的<mark>素材</mark>製作各種道具喔。", "player": [ { "next": 2, "text": "素材？" }, { "text": "再見" } ] } ,
      2: { "npcDialog": "沒錯，這裡其實蘊含著許多珍貴素材的地方呢⋯⋯", "player": [ { "next": 3, "text": "像是什麼？" } ] } ,
      3: { "npcDialog": "比如你在路上看到的各種不同<mark>樹種</mark>，試著站到他旁邊按 A 試試看，就可以搖下一些酷東西喔！", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "搖下來的東西，站到上面<mark>按 A</mark>，就能把它<mark>撿</mark>到包包裡面，帶到我這裡來。", "player": [ { "next": 5, "text": "你在製作些什麼呢？" } ] } ,
      5: { "npcDialog": "我作品的詳細介紹擺在前面的三張桌子喔，有興趣的話，靠過去看一下吧！", "player": [ { "text": "好！" } ] }
    }
  },
  ToolTrafficLight: {
    name: '木匠弟弟',
    notFinish: {
      0: { "npcDialog": "這個東西看起來很特別欸！是什麼呀？", "player": [ { "next": 1 } ], changeName: 'player' } ,
      1: { "npcDialog": "我在製作一個叫<mark>行動紅綠燈</mark>的桌燈哦！" , "player": [ { text: '那是什麼？', next: 2 }, { text: '我也好想有一個！', next: 8 }, { text: '好哦，加油！（轉身離開）' } ]},
      2: { "npcDialog": "這個嘛⋯⋯要說到其實每屆迴響像有傳統似的，總喜歡<mark>製作紀念物</mark>給參與的團隊！", "player": [ { next: 3, "text": "天啊！該不會都是手作的！" } ]},
      3: { "npcDialog": "恩！這個行動紅綠燈就是第一屆的紀念物哦！", "player": [ { next: 4 } ]},
      4: { "npcDialog": "背後主要理由可能是⋯⋯，希望透過這些<mark>有形物品</mark>", "player": [ { next: 5 } ]},
      5: { "npcDialog": "讓每個團隊的<mark>行動精神</mark>能憑依其上！", "player": [ { next: 6 } ]},
      6: { "npcDialog": "在迴響計畫的旅程結束後，回過頭來能夠記得此刻<mark>自己的願景</mark>⋯⋯", "player": [ { next: 7 } ]},
      7: { "npcDialog": "然後，能夠找到<mark>一直行動下去的藉口</mark>。", "player": [ { next: 8, text: "也太感人了吧⋯⋯我也好想有一個！" } ]},
      8: { "npcDialog": "來參加下一屆迴響啊！發漏迴響的<a href='https://www.facebook.com/RethinkTaiwan2027' target='_blank'>粉專</a>，不錯過報名訊息啦！", "player": [ { next: 9, text: "好喔⋯⋯但我還是想擁有一個紅綠燈！" } ]},
      9: { "npcDialog": "哎呀，就是想要行動紅綠燈是嗎⋯⋯材料不好蒐集呢⋯⋯", "player": [ { next: 10, text: "交給我！說吧！" } ]},

      10: { "npcDialog": "幫我蒐集到<mark>十根樹枝</mark>、<mark>五個寶特瓶</mark>、<mark>一個燈串</mark>、<mark>一顆蘋果</mark>、<mark>一顆溫泉蛋</mark>、<mark>五顆小小人種子</mark>，就幫你製作！",
            "player": [ { "next": 11, "text": "給你", delItems: [{name: 'seed',number: 5},{name: 'branch',number: 10},{name: 'bottle',number: 5},{name: 'hot-spring-egg',number:1},{name: 'apple', number:1},{name:'lightbulb', number:1}], ifNoItemGoTo: 100 },
                        { "text": "怎麼取得素材？", "next": 14 }] } ,
      11: { "npcDialog": "哇⋯⋯沒想到素材都集齊了呀，我這就開始製作！", "player": [ { "next": 12 } ] } ,
      12: { "npcDialog": "（木匠弟弟神乎其技地把樹枝、寶特瓶、燈串重組起來，利用蘋果的黑科技讓紅綠燈發了光。）", "player": [ { "next": 1000 } ] } ,
      1000: { "npcDialog": "（然後拿起溫泉蛋，撥開之後吃掉，接著小心翼翼捧起小小人種子，放進存錢筒⋯⋯）", "player": [ { "next": 13 } ] } ,
      13: { "npcDialog": "拿去吧！這是你要的行動紅綠燈！", "player": [ { "text": "太棒了！謝謝你！", rewards: ['action-traffic-light'], complete: "完成任務：製作紀念物！"  }, ] } ,

      // 取得素材
      14: { "npcDialog": "不知道怎麼取得素材嗎？你想問的是哪一個呢？",
            "player": [ { "next": 15, "text": "樹枝" }, { "text": "寶特瓶", "next": 16 }, { "text": "燈串", "next": 17 }, { "text": "蘋果", "next": 19 }, { "text": "溫泉蛋", "next": 99 }, { "text": "小小人種子", "next": 18 } ] } ,
      15: { "npcDialog": "樹枝的話⋯⋯這是個很普遍的素材，只要站在<mark>樹</mark>底下按 <mark>A</mark>，多搖幾次樹就可以拿到囉。", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": 14 } ] } ,
      // TODO: 抽by趴 - 要怎麼掰要去 大澤or溪流or大壩，取得寶特瓶 (ಥ﹏ಥ)(ಥ﹏ಥ)(ಥ﹏ಥ)
      16: { "npcDialog": "寶特瓶？下區的迴響大澤經常有人亂丟垃圾⋯⋯但首先你會需要一支<mark>釣竿</mark>。", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": 14 } ] } ,
      17: { "npcDialog": "燈串呀⋯⋯回音山屋前面狗狗趴著的地方，有一棵<mark>特別的樹</mark>，試著去搖搖看？", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": 14 } ] } ,
      // 17: { "npcDialog": "黏土？在大澤附近的地面挖得到。嘗試帶上山屋旁那隻狗狗，它能幫助你挖出地面下的物品。", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": 14 } ] } ,
      18: { "npcDialog": "這座森林裡的<mark>小小人</mark>經常躲在樹上，<mark>搖樹</mark>的時候，說不定就會把他們搖下來了⋯⋯", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": 14 } ] },
      // 19: { "npcDialog": "發光玩具", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": 14 } ]  },
      19: { "npcDialog": "蘋果的市值可高了⋯⋯這地圖上的蘋果樹非常稀有，在左區登山的路上，<mark>行動夥伴 Leo </mark>旁邊就有一棵。", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": 14 } ]  },
      99: { "npcDialog": "嘿嘿，等你做出<mark>釣竿</mark>，可以釣東西的話，去左區山上的<mark>溫泉</mark>釣釣看吧！", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": 14 } ]  },
      100: { "npcDialog": "我在你的背包裡沒有看到應有的素材喔！是不是還沒蒐集完呢？", "player": [ { "text": "了解，我蒐集完再過來" }, { "text": "要怎麼蒐集？", "next": 14 } ] }
    },
    finished: {
      0: { "npcDialog": "謝謝你幫我完成了我的畢生傑作——行動紅綠燈！" } ,
      // 1: { "npcDialog": "不行。", "player": [ { "text": "OK⋯⋯" } ] }
    }
  },
  ToolFishingRod: {
    name: '木匠弟弟',
    notFinish: {
      0: { "npcDialog": "要製作釣竿嗎？我需要<mark>十根樹枝</mark>、<mark>一條釣魚線</mark>、<mark>五顆小小人種子</mark>。", "player": [ { "next": 1, "text": "給你", delItems: [{name: 'seed',number: 5},{name: 'branch',number: 10},{name: 'fishing-line',number:1}], ifNoItemGoTo: 100 }, { "text": "怎麼取得素材？", "next": "4" }, { "text": "沒事了，再見" } ] } ,
      1: { "npcDialog": "哇⋯⋯沒想到素材都集齊了呀，我這就開始製作！", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "（木匠弟弟把小小人種子收進口袋後，動力大提升，拿出鐵鎚東敲西敲，馬上就做出了釣竿。）", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "拿去吧！這是你要的釣竿！仔細觀察有水的地方，如果水面<mark>閃閃發亮</mark>，在旁邊按 A 就可以釣魚囉！", "player": [ { "text": "謝謝你！", rewards: ['fishingRob'] } ] } ,
      4: { "npcDialog": "不知道怎麼取得素材嗎？你想問的是哪一個呢？", "player": [ { "next": 5, "text": "樹枝" }, { "text": "釣魚線", "next": "6" }, { "text": "小小人種子", "next": "8" } ] } ,
      5: { "npcDialog": "樹枝的話⋯⋯這是個很普遍的素材，只要站在<mark>樹</mark>底下按 <mark>A</mark>，多搖幾次樹就可以拿到囉。", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": "4" } ] } ,
      6: { "npcDialog": "釣魚線？要不要去山屋<mark>角落</mark>找找看呢？我上次在<mark>掛軸箱</mark>裡有看到。", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": "4" } ] } ,
      // 7: { "npcDialog": "便當鐵盒是迴響常用的餐具！說不定左區前往<mark>行動大山的路途</mark>上能夠撿到呢⋯⋯", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": "4" } ] } ,
      8: { "npcDialog": "這座森林裡的<mark>小小人</mark>經常躲在樹上，<mark>搖樹</mark>的時候，說不定就會把他們搖下來了⋯⋯", "player": [ { "text": "我知道了" }, { "text": "還想問其他的" } ] },
      100: { "npcDialog": "我在你的背包裡沒有看到應有的素材喔！是不是還沒蒐集完呢？", "player": [ { "text": "了解，我蒐集完再過來" }, { "text": "要怎麼蒐集？", "next": "4" } ] }
    },
    justFinish: {
      0: { "npcDialog": "想製作釣竿嗎？但我看你已經有釣竿了呀，把心力省下來做別的事吧！", "player": [ { "next": 1, "text": "不可以做兩根嗎？" } ] } ,
      1: { "npcDialog": "不行。", "player": [ { "text": "OK⋯⋯" } ] }
    }
  },
  ToolAx: {
    name: '木匠弟弟',
    notFinish: {
      // 、<mark>一個便當鐵盒</mark>, { "text": "便當鐵盒", "next": "7" }
      0: { "npcDialog": "要製作斧頭嗎？我需要<mark>五根樹枝</mark>、<mark>五根鐵釘</mark>跟<mark>三顆小小人種子</mark>。", "player": [ { "next": 1, "text": "給你", delItems: [{name: 'seed',number: 3},{name: 'branch',number: 5}, {name: 'nail', number: 5}], ifNoItemGoTo: 100 }, { "text": "怎麼取得素材？", "next": "4" }, { "text": "沒事了，再見" } ] } ,
      1: { "npcDialog": "哇⋯⋯沒想到素材都集齊了呀，我這就開始製作！", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "（木匠弟弟用鐵鎚把便當鐵盒打成斧頭的刀片、組裝所有部件，最後把小小人種子收進口袋裡⋯⋯）", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "拿去吧！這是你要的斧頭！偷偷告訴你一個秘密，把斧頭丟進下區的<mark>迴響大澤</mark>，會發生不得了的事喔！", "player": [ { "text": "謝謝你！", rewards: ['ax'] } ] } ,
      4: { "npcDialog": "不知道怎麼取得素材嗎？你想問的是哪一個呢？", "player": [ { "next": 5, "text": "樹枝" }, { "text": "鐵釘", "next": "6" }, { "text": "小小人種子", "next": "8" } ] } ,
      5: { "npcDialog": "樹枝的話⋯⋯這是個很普遍的素材，只要站在<mark>樹</mark>底下按 <mark>A</mark>，多搖幾次樹就可以拿到囉。", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": "4" } ] } ,
      6: { "npcDialog": "鐵釘啊⋯⋯如果你有在路上看到<mark>蘇鐵</mark>的話，試著按 A 搖搖他們吧！蘇鐵可能在迴圈森林會比較多吧⋯⋯", "image": 'info_Cycad', "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": "4" } ] } ,
      // 7: { "npcDialog": "便當鐵盒是迴響常用的餐具！說不定左區前往<mark>行動大山的路途</mark>上能夠撿到呢⋯⋯", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": "4" } ] } ,
      7: { "npcDialog": "便當啊⋯⋯如果你有在路上看到<mark>蘇鐵</mark>的話，試著按 A 搖搖他們吧！蘇鐵可能在迴圈森林會比較多吧⋯⋯", "player": [ { "text": "我知道了" }, { "text": "還想問其他的", "next": "4" } ] } ,
      8: { "npcDialog": "這座森林裡的<mark>小小人</mark>經常躲在樹上，<mark>搖樹</mark>的時候，說不定就會把他們搖下來了⋯⋯", "player": [ { "text": "我知道了" }, { "text": "還想問其他的" } ] },
      100: { "npcDialog": "我在你的背包裡沒有看到應有的素材喔！是不是還沒蒐集完呢？", "player": [ { "text": "了解，我蒐集完再過來" }, { "text": "要怎麼蒐集？", "next": "4" } ] }
    },
    justFinish: {
      0: { "npcDialog": "想製作斧頭嗎？但我看你已經有斧頭了呀，把心力省下來做別的事吧！", "player": [ { "next": 1, "text": "可是我想要很多把啊" } ] } ,
      1: { "npcDialog": "喔，其實啊，你做好釣竿以後去下區的大澤釣魚，就能釣到一堆斧頭了啦⋯⋯", "player": [ { "text": "蛤？？？" } ] }
    }
  },
  Planet1: {
    name: '真誠 Honesty',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet1" ], "delNPCs": [ "Planet1" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「真誠是與這個世界溝通的基石。」", "player": [ { "text": "探索星球", modal: 'planet_honesty' } ] },
    },
    finished: {
      0: { "npcDialog": "「真誠是與這個世界溝通的基石。」", "player": [ { "text": "探索星球", modal: 'planet_honesty' } ] }
    }
  },
  Planet2: {
    name: '對話 Bridge',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet2" ], "delNPCs": [ "Planet2" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「在小島間搭起橋樑，讓彼此去到更遠的地方。」", "player": [ { "text": "探索星球", modal: 'planet_bridge' } ] },
    },
    finished: {
      0: { "npcDialog": "「在小島間搭起橋樑，讓彼此去到更遠的地方。」", "player": [ { "text": "探索星球", modal: 'planet_bridge' } ] }
    }
  },
  Planet3: {
    name: '反思 Reflection',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet3" ], "delNPCs": [ "Planet3" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「我是誰？我正在做什麼？為什麼？」", "player": [ { "text": "探索星球", modal: 'planet_reflection' } ] },
    },
    finished: {
      0: { "npcDialog": "「我是誰？我正在做什麼？為什麼？」", "player": [ { "text": "探索星球", modal: 'planet_reflection' } ] }
    }
  },
  Planet4: {
    name: '同理 Empathy',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet4" ], "delNPCs": [ "Planet4" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「多一點體諒和一顆關心他人的心，世界就會大不相同。」", "player": [ { "text": "探索星球", modal: 'planet_empathy' } ] },
    },
    finished: {
      0: { "npcDialog": "「多一點體諒和一顆關心他人的心，世界就會大不相同。」", "player": [ { "text": "探索星球", modal: 'planet_empathy' } ] }
    }
  },
  Planet5: {
    name: '迭代 Iteration',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet5" ], "delNPCs": [ "Planet5" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「前進，修正，再前進。」", "player": [ { "text": "探索星球", modal: 'planet_iteration' } ] },
    },
    finished: {
      0: { "npcDialog": "「前進，修正，再前進。」", "player": [ { "text": "探索星球", modal: 'planet_iteration' } ] }
    }
  },
  Planet6: {
    name: '選擇 Choice',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet6" ], "delNPCs": [ "Planet6" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「沒有那麼多的『一定要怎樣』，我們永遠能做出選擇。」", "player": [ { "text": "探索星球", modal: 'planet_choice' } ] },
    },
    finished: {
      0: { "npcDialog": "「沒有那麼多的『一定要怎樣』，我們永遠能做出選擇。」", "player": [ { "text": "探索星球", modal: 'planet_choice' } ] }
    }
  },
  Planet7: {
    name: '挑戰 Challenge',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet7" ], "delNPCs": [ "Planet7" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「最美好的事往往也是最困難的 。」", "player": [ { "text": "探索星球", modal: 'planet_challenge' } ] },
    },
    finished: {
      0: { "npcDialog": "「最美好的事往往也是最困難的 。」", "player": [ { "text": "探索星球", modal: 'planet_challenge' } ] }
    }
  },
  Planet8: {
    name: '信念 Faith',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet8" ], "delNPCs": [ "Planet8" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「讓心成為一座山，即便有風暴也堅定不移。」", "player": [ { "text": "探索星球", modal: 'planet_faith' } ] },
    },
    finished: {
      0: { "npcDialog": "「讓心成為一座山，即便有風暴也堅定不移。」", "player": [ { "text": "探索星球", modal: 'planet_faith' } ] }
    }
  },
  Planet9: {
    name: '溫柔 Kindness',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet9" ], "delNPCs": [ "Planet9" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「善待人的不完美，尤其是那些與自己不同的地方。」", "player": [ { "text": "探索星球", modal: 'planet_kindness' } ] },
    },
    finished: {
      0: { "npcDialog": "「善待人的不完美，尤其是那些與自己不同的地方。」", "player": [ { "text": "探索星球", modal: 'planet_kindness' } ] }
    }
  },
  Planet10: {
    name: '開放 Open',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet10" ], "delNPCs": [ "Planet10" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「擁抱各種過去、現在和未來。」", "player": [ { "text": "探索星球", modal: 'planet_open' } ] },
    },
    finished: {
      0: { "npcDialog": "「擁抱各種過去、現在和未來。」", "player": [ { "text": "探索星球", modal: 'planet_open' } ] }
    }
  },
  Planet11: {
    name: '團結 Union',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet11" ], "delNPCs": [ "Planet11" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「一個人走得快，一群人走得遠。」", "player": [ { "text": "探索星球", modal: 'planet_union' } ] },
    },
    finished: {
      0: { "npcDialog": "「一個人走得快，一群人走得遠。」", "player": [ { "text": "探索星球", modal: 'planet_union' } ] }
    }
  },
  Planet12: {
    name: '渴望 Desire',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet12" ], "delNPCs": [ "Planet12" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「永遠都知道還有更好的可能。」", "player": [ { "text": "探索星球", modal: 'planet_desire' } ] },
    },
    finished: {
      0: { "npcDialog": "「永遠都知道還有更好的可能。」", "player": [ { "text": "探索星球", modal: 'planet_desire' } ] }
    }
  },
  Planet13: {
    name: '勇氣 Bravery',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet13" ], "delNPCs": [ "Planet13" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「今天就是放手一搏的好日子。」", "player": [ { "text": "探索星球", modal: 'planet_bravery' } ] },
    },
    finished: {
      0: { "npcDialog": "「今天就是放手一搏的好日子。」", "player": [ { "text": "探索星球", modal: 'planet_bravery' } ] }
    }
  },
  Planet14: {
    name: '用心 Devoted',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet14" ], "delNPCs": [ "Planet14" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「如果可以，讓我們都選擇不走方便或苟且的路。」", "player": [ { "text": "探索星球", modal: 'planet_devoted' } ] },
    },
    finished: {
      0: { "npcDialog": "「如果可以，讓我們都選擇不走方便或苟且的路。」", "player": [ { "text": "探索星球", modal: 'planet_devoted' } ] }
    }
  },
  Planet15: {
    name: '信任 Trust',
    justFinish: {
      0: { "npcDialog": "點亮星球？", "player": [ { "next": 1, complete: true, "text": "好", "event": [ "Planet15" ], "delNPCs": [ "Planet15" ] }, { "text": "不好" } ] },
      1: { "npcDialog": "「永遠相信對方已經用盡全力。」", "player": [ { "text": "探索星球", modal: 'planet_trust' } ] },
    },
    finished: {
      0: { "npcDialog": "「永遠相信對方已經用盡全力。」", "player": [ { "text": "探索星球", modal: 'planet_trust' } ] }
    }
  },
  AxCheckin: {
    name: '缺應斧頭',
    notFinish: {
      0: { "npcDialog": "這裡的水有點深⋯⋯水底下似乎有什麼？", "player": [ { "next": 1 } ], changeName: 'player' },
      1: { "npcDialog": "傳說中<mark>澤中女神</mark>曾出沒在這附近，往<mark>更深</mark>一點的地方前進看看吧⋯⋯", "player": [], changeName: '擺渡人' },
    },
    justFinish: {
      0: { "npcDialog": "斧頭上有一段話：「團隊才<mark>剛起步</mark>階段，成員彼此還不熟，開會討論怪尷尬的⋯⋯怎麼辦？」", "player": [ { "next": 1, text: "對欸怎麼辦？" }, { "text": "沒興趣，離開" } ], changeName: 'player' },
      1: { "npcDialog": "想知道答案？帶走它吧！<br>在背包裡能<mark>查看</mark>斧頭內更詳細的資訊。", "player": [ { "text": "好！（伸出手摸斧頭）", next: 2 }, { "text": "放棄，離開" } ], changeName: '澤中女神' },
      2: { "npcDialog": "好欸！斧頭拿起來了！", "player": [ { "text": "收進包包", rewards: ['ax-checkin'], "delEvents": ['AxCheckin'], delMission: ['AxCheckin'] } ], changeName: 'player' },
    },
  },
  AxDecision: {
    name: '決策斧頭',
    notFinish: {
      0: { "npcDialog": "這裡的水有點深⋯⋯水底下似乎有什麼？", "player": [ { "next": 1 } ], changeName: 'player' },
      1: { "npcDialog": "傳說中<mark>澤中女神</mark>曾出沒在這附近，往<mark>更深</mark>一點的地方前進看看吧⋯⋯", "player": [], changeName: '擺渡人' },
    },
    justFinish: {
      0: { "npcDialog": "斧頭上有一段話：「團隊的<mark>會議</mark>時有衝突，不太知道要怎麼解決⋯⋯怎麼辦？」", "player": [ { "next": 1, text: "對欸怎麼辦？" }, { "text": "沒興趣，離開" } ], changeName: 'player' },
      1: { "npcDialog": "想知道答案？帶走它吧！<br>在背包裡能<mark>查看</mark>斧頭內更詳細的資訊。", "player": [ { "text": "好！（伸出手摸斧頭）", next: 2 }, { "text": "放棄，離開" } ], changeName: '澤中女神' },
      2: { "npcDialog": "好欸！斧頭拿起來了！", "player": [ { "text": "收進包包", rewards: ['ax-decision'], "delEvents": ['AxDecision'], delMission: ['AxDecision'] } ], changeName: 'player' },
    },
  },
  AxStrange: {
    name: '怪怪斧頭',
    notFinish: {
      0: { "npcDialog": "這裡的水有點深⋯⋯水底下似乎有什麼？", "player": [ { "next": 1 } ], changeName: 'player' },
      1: { "npcDialog": "傳說中<mark>澤中女神</mark>曾出沒在這附近，往<mark>更深</mark>一點的地方前進看看吧⋯⋯", "player": [], changeName: '擺渡人' },
    },
    justFinish: {
      0: { "npcDialog": "斧頭上有一段話：「團隊<mark>動能突然下降</mark>了，許多成員的工作開始有延遲或未達成原本理想中狀態⋯⋯怎麼辦？」", "player": [ { "next": 1, text: "對欸怎麼辦？" }, { "text": "沒興趣，離開" } ], changeName: 'player' },
      1: { "npcDialog": "想知道答案？帶走它吧！<br>在背包裡能<mark>查看</mark>斧頭內更詳細的資訊。", "player": [ { "text": "好！（伸出手摸斧頭）", next: 2 }, { "text": "放棄，離開" } ], changeName: '澤中女神' },
      2: { "npcDialog": "好欸！斧頭拿起來了！", "player": [ { "text": "收進包包", rewards: ['ax-strange'], "delEvents": ['AxStrange'], delMission: ['AxStrange'] } ], changeName: 'player' },
    },
  },
  AxSync: {
    name: '同步斧頭',
    notFinish: {
      0: { "npcDialog": "這裡的水有點深⋯⋯水底下似乎有什麼？", "player": [ { "next": 1 } ], changeName: 'player' },
      1: { "npcDialog": "傳說中<mark>澤中女神</mark>曾出沒在這附近，往<mark>更深</mark>一點的地方前進看看吧⋯⋯", "player": [], changeName: '擺渡人' },
    },
    justFinish: {
      0: { "npcDialog": "斧頭上有一段話：「團隊需要<mark>同步的資訊很多</mark>，但每次會議似乎都很冗⋯⋯怎麼辦？」", "player": [ { "next": 1, text: "對欸怎麼辦？" }, { "text": "沒興趣，離開" } ], changeName: 'player' },
      1: { "npcDialog": "想知道答案？帶走它吧！<br>在背包裡能<mark>查看</mark>斧頭內更詳細的資訊。", "player": [ { "text": "好！（伸出手摸斧頭）", next: 2 }, { "text": "放棄，離開" } ], changeName: '澤中女神' },
      2: { "npcDialog": "好欸！斧頭拿起來了！", "player": [ { "text": "收進包包", rewards: ['ax-sync'], "delEvents": ['AxSync'], delMission: ['AxSync'] } ], changeName: 'player' },
    },
  },
  AxTeamwork: {
    name: '默契斧頭',
    notFinish: {
      0: { "npcDialog": "這裡的水有點深⋯⋯水底下似乎有什麼？", "player": [ { "next": 1 } ], changeName: 'player' },
      1: { "npcDialog": "傳說中<mark>澤中女神</mark>曾出沒在這附近，往<mark>更深</mark>一點的地方前進看看吧⋯⋯", "player": [], changeName: '擺渡人' },
    },
    justFinish: {
      0: { "npcDialog": "斧頭上有一段話：「和夥伴的工作習慣不同，有時會產生<mark>衝突</mark>，但好像不知道怎麼講開的話⋯⋯怎麼辦？」", "player": [ { "next": 1, text: "對欸怎麼辦？" }, { "text": "沒興趣，離開" } ], changeName: 'player' },
      1: { "npcDialog": "想知道答案？帶走它吧！<br>在背包裡能<mark>查看</mark>斧頭內更詳細的資訊。", "player": [ { "text": "好！（伸出手摸斧頭）", next: 2 }, { "text": "放棄，離開" } ], changeName: '澤中女神' },
      2: { "npcDialog": "好欸！斧頭拿起來了！", "player": [ { "text": "收進包包", rewards: ['ax-teamwork'], "delEvents": ['AxTeamwork'], delMission: ['AxTeamwork'] } ], changeName: 'player' },
    },
  },
  AxValue: {
    name: '價值斧頭',
    notFinish: {
      0: { "npcDialog": "這裡的水有點深⋯⋯水底下似乎有什麼？", "player": [ { "next": 1 } ], changeName: 'player' },
      1: { "npcDialog": "傳說中<mark>澤中女神</mark>曾出沒在這附近，往<mark>更深</mark>一點的地方前進看看吧⋯⋯", "player": [], changeName: '擺渡人' },
    },
    justFinish: {
      0: { "npcDialog": "斧頭上有一段話：「團隊的<mark>文化</mark>越來越不健康，有問題時會互相推卸責任，資訊會偷藏⋯⋯怎麼辦？」", "player": [ { "next": 1, text: "對欸怎麼辦？" }, { "text": "沒興趣，離開" } ], changeName: 'player' },
      1: { "npcDialog": "想知道答案？帶走它吧！<br>在背包裡能<mark>查看</mark>斧頭內更詳細的資訊。", "player": [ { "text": "好！（伸出手摸斧頭）", next: 2 }, { "text": "放棄，離開" } ], changeName: '澤中女神' },
      2: { "npcDialog": "好欸！斧頭拿起來了！", "player": [ { "text": "收進包包", rewards: ['ax-value'], "delEvents": ['AxValue'], delMission: ['AxValue'] } ], changeName: 'player' },
    },
  },
  Altar: {
    name: '祭台',
    notFinish: {
      0: { "npcDialog": "看起來似乎是個祭台？是用來放什麼的呢⋯⋯", changeName: 'player' },
    },
    justFinish: {
    },
  },
  PartnerFlavor: {
    name: '人生百味 - 阿德',
    shot: 'PartnerFlavorShot',
    sound: 'npc_partner_m',
    notFinish: {
      0: { "npcDialog": "嗨！我是行動夥伴阿德。<br>來一起煮<mark>石頭湯</mark>吧！", "player": [ { "next": 1, "text": "你對行動的看法？" }, { "text": "來點行動指引吧！", "next": "5" }, { "text": "行動夥伴是⋯⋯？", "next": "7" } ] } ,
      ...PartnerFlavorBasic,
      4: { "npcDialog": "和你聊得很盡興呢！送給你我的<mark>石頭</mark>，想了解我在做什麼，可以<mark>從背包內使用它</mark>來閱讀。", "player": [{ next: 11 } ] } ,
      10: { "npcDialog": "也因為已行動過一陣子了，能站在剛起步行動的團隊前面一些多指引一步。", "player": [ { "next": 1, "text": "那...你對行動的看法是？" }, { "text": "能給我點行動指引嗎？", "next": "5" }, ] },
      // 11: { "npcDialog": "話說還有<mark>十一個</mark>行動夥伴⋯⋯他們怎麼遲到了這麼久，感覺要等<mark>下次上線</mark>才會來了。", "player": [{ text: '期待！', "rewards": [ "stone-flavor" ], "ongoing": "獲得了阿德的石頭，等待其他行動夥伴上線吧！" } ] },
      11: { "npcDialog": "路上遇到和我一樣<mark>黃黃的小巨人</mark>，去聊聊他們的故事，聊得興致來了也會給你他們的石頭哦！", "player": [{ next: 12 } ] },
      12: { "npcDialog": "等你蒐集完<mark>其他行動夥伴</mark>的石頭，再回來找我<mark>煮石頭湯</mark>吧！", "player": [ { text: '沒問題！', "rewards": [ "stone-flavor" ], "ongoing": "獲得了阿德的石頭，快去尋找其他行動夥伴的石頭吧！" } ] } ,
      // , "event": ["StoneBowlHalf"]
    },
    onGoing: {
      0: { "npcDialog": "嗨！我是行動夥伴阿德。恩～<mark>石頭</mark>都蒐集完了嗎？", "player": [ { "next": 11, "text": "我該怎麼做？" }, { "next": 1, "text": "你對行動的看法？" }, { "text": "來點行動指引吧！", "next": "5" }, { "text": "行動夥伴是⋯⋯？", "next": "7" } ] } ,
      ...PartnerFlavorBasic,
      10: { "npcDialog": "也因為已行動過一陣子了，能站在剛起步行動的團隊前面一些多指引一步。", "player": [ { "text": "原來如此，可以說更多嗎？", "modal": "s1_partners" } ] },
      11: { "npcDialog": "幫我搜集到各地行動夥伴的<mark> 12 顆石頭</mark>，我就能煮石頭湯了。", "player": [ {next: 12} ] } ,
      12: { "npcDialog": "路上遇到和我一樣<mark>黃黃的小巨人</mark>，去聊聊他們的故事，聊得興致來了就會給你石頭哦！" } ,
    },
    justFinish: {
      0: { "npcDialog": "我搜集到 12 顆石頭了！所以<mark>石頭湯</mark>到底是什麼？", "changeName": "player", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "太好了！我可以完成這鍋石頭湯了！","player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "你想知道這些石頭代表甚麼意思嗎？代表這份<mark>食譜</mark>應該開始對你有些意義了⋯⋯", "player": [ { "next": 3 } ] } ,
      // 2: { "npcDialog": "", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "為了答謝你，這本<mark>石頭湯食譜</mark>就送給你，從裡面細讀吧！", "player": [ { "next": 4, "text": "獲取石頭湯食譜" }, { "text": "之後再說吧" } ] } ,
      4: { "npcDialog": "喔對了，只是想做個提醒，這裡的石頭湯，跟我們百味的不太一樣。", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "歡迎上<a href='http://www.rethinktaiwan.com/universe_exhibition' target='_blank'>眾力小宇宙網站</a>，進一步了解<a href='http://rethinktaiwan.com/universe_exhibition/2' target='_blank'>人生百味的石頭湯／溫石計畫</a>", "player": [ { "rewards": [ "stone-recipe" ], "complete": "完成石頭湯任務了！趕快翻開食譜來看看吧。", "event": ["StoneBowlFull"], "text": "好的！" } ] }
    },
    finished: {
      0: { "npcDialog": "怎麼了，要找我打電動嗎？", "player": [{ "next": 100, "text": "行動大神？" }, { "next": 1, "text": "你對行動的看法？" }, { "text": "來點行動指引吧！", "next": "5" }, { "text": "行動夥伴是⋯⋯？", "next": "7" } ] } ,
      100: { "npcDialog": "行動大神啊？我記得那個，哈哈⋯⋯那是曾經在迴響的大會師活動現場出現的神。", "player": [ { text: '神？', "next": 101 } ] } ,
      101: { "npcDialog": "想一探究竟的話，就<mark>解完所有任務</mark>吧。連按兩次 B 就可以知道你還剩多少任務了！", "player": [ { text: '顯靈？',"next": 101 } ] } ,
      ...PartnerFlavorBasic,
      4: { "npcDialog": "還想知道什麼呢？", "player": [ { "next": 1, "text": "你對行動的看法？" }, { "next": 5, "text": "來點行動指引吧！" }, { "text": "行動夥伴是⋯？", "next": 7 }, { "text": "沒事了，bye！" } ] },
      10: { "npcDialog": "也因為已行動過一陣子了，能站在剛起步行動的團隊前面一些多指引一步。", "player": [ { "text": "原來如此，可以說更多嗎？", "modal": "s1_partners" } ] },
    }
  },
  PartnerBankCulture: {
    name: '文化銀行 - 璦婷',
    shot: 'PartnerBankCultureShot',
    sound: 'npc_partner_f',
    justFinish: {
      ...PartnerBankCultureBasic,
      101: {
        "npcDialog": "送你我的<mark>石頭 - 漢白玉</mark>，上次採訪磨石子老師傅的時候得到的，名字很美。",
        "player": [ { "text": "好耶！謝謝", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭哦～",
        "player": [ { "text": "有空經過會去找他的！", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-bankculture" ], "complete": "獲得了璦婷的漢白玉！", "text": "先收在包包" },
          { "rewards": [ "stone-bankculture" ], "text": "查看石頭", "modal": "partner_4", "complete": "獲得了璦婷的漢白玉！" } ] },
    },
    finished: {
      ...PartnerBankCultureBasic,
      0: { "npcDialog": "哈囉～我是迴響行動夥伴<mark>璦婷</mark>！", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" } ] },
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  PartnerCalls: {
    name: '遠山呼喚 - 子鈞',
    shot: 'PartnerCallsShot',
    sound: 'npc_m_3',
    justFinish: {
      ...PartnerCallsBasic,
      101: {
        "npcDialog": "山頂風景真的很美⋯⋯剛剛那位<mark>攝影師</mark>幫我拍的登頂照也很棒，只要 2 個小小人種子很值得！",
        "player": [ { "text": "小小人種子？", next: 102 }, { "text": "還沒拍，立馬去", next: 103 }, { "text": "拍了，喜歡！", next: 103 },  ] },
      102: { "npcDialog": "你不知道種子？⋯⋯去樹下按 A 玩玩看！有小小人就有小小人種子。", "player": [ { "text": "好！謝謝", next: 103 } ] } ,
      103: {
          "npcDialog": "登頂後，要搭紅纜車下山前，真的<mark>不能錯過</mark>！",
          "player": [ { next: 104 } ] },
      104: {
        "npcDialog": "有緣認識，給你我的<mark>石頭 - 冰河水晶</mark>，喜馬拉雅山脈產出的特殊水晶。",
        "player": [ { "text": "好酷的石頭！謝謝！", next: 105 } ] },
      105: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-calls" ], "complete": "獲得了子鈞的冰河水晶！", "text": "先收在包包" },
          { "rewards": [ "stone-calls" ], "text": "查看石頭", "modal": "partner_7", "complete": "獲得了子鈞的冰河水晶！" } ] },
    },
    finished: {
      ...PartnerCallsBasic,
      0: {
        "npcDialog": "哈囉，我是迴響行動夥伴<mark>子鈞</mark>。<br>",
        "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" } ] } ,
      101: {
        "npcDialog": "還有什麼事嗎？",
        "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] } ,
    }
  },
  PartnerChat: {
    name: '對話千層派 - 品汶',
    shot: 'PartnerChatShot',
    sound: 'npc_partner_f',
    justFinish: {
      ...PartnerChatBasic,
      101: {
        "npcDialog": "很開心遇見你，送你我的<mark>石頭 - 薔薇石英</mark>，象徵著愛與包容。",
        "player": [ { "text": "好耶！謝謝", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭哦～",
        "player": [ { "text": "有空經過會去找他的！", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-chat" ], "complete": "獲得了品汶的薔薇石英！", "text": "先收在包包" },
          { "rewards": [ "stone-chat" ], "text": "查看石頭", "modal": "partner_10", "complete": "獲得了品汶的薔薇石英！" } ] },
    },
    finished: {
      ...PartnerChatBasic,
      0: { "npcDialog": "嗨嗨！我是迴響行動夥伴品汶！", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 },  { "text": "沒事，不小心按到" } ] } ,
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  PartnerDog: {
    name: '浪犬博士 - Lucy',
    shot: 'PartnerDogShot',
    sound: 'npc_partner_f',
    justFinish: {
      ...PartnerDogBasic,
      101: {
        "npcDialog": "很開心認識你～送你我的<mark>石頭 - 化石</mark>，是某天泰瑞在山上挖出來的～去鑑定看看吧？",
        "player": [ { "text": "好耶！謝謝", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭哦～",
        "player": [ { "text": "有空經過會去找他的！", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-dog" ], "complete": "獲得了 Lucy 的化石！", "text": "先收在包包" },
          { "rewards": [ "stone-dog" ], "text": "查看石頭", "modal": "partner_11", "complete": "獲得了 Lucy 的化石！" } ] },
    },
    finished: {
      ...PartnerDogBasic,
      0: { "npcDialog": "哈囉～我是迴響行動夥伴 <mark>Lucy</mark>！", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" } ] },
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  PartnerFlaneur: {
    name: '玖樓 - 維綱',
    shot: 'PartnerFlaneurShot',
    sound: 'npc_m_3',
    justFinish: {
      ...PartnerFlaneurBasic,
      101: {
        "npcDialog": "送你我的<mark>石頭 - 鹽</mark>，共享生活的餐桌上不可或缺的存在，不是攝影用的銀鹽。",
        "player": [ { "text": "耶！那我收下了", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭哦～",
        "player": [ { "text": "有空經過會去找他的！", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-flaneur" ], "complete": "獲得了維綱的鹽！", "text": "先收在包包" },
          { "rewards": [ "stone-flaneur" ], "text": "查看石頭", "modal": "partner_5", "complete": "獲得了維綱的鹽！" } ] },
    },
    finished: {
      ...PartnerFlaneurBasic,
      0: { "npcDialog": "嗨！我是迴響行動夥伴<mark>維綱</mark>。", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" }  ] } ,
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  PartnerHappen: {
    name: '好伴設計 - 家緯',
    shot: 'PartnerHappenShot',
    sound: 'npc_partner_m',
    justFinish: {
      ...PartnerHappenBasic,
      101: {
        "npcDialog": "送你我的<mark>石頭 - 礫石</mark>，來自好伴旁的台中綠川，有淨化水質的用途。",
        "player": [ { "text": "耶！那我收下了", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭哦～",
        "player": [ { "text": "有空經過會去找他的！", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-happen" ], "complete": "獲得了家緯的礫石！", "text": "先收在包包" },
          { "rewards": [ "stone-happen" ], "text": "查看石頭", "modal": "partner_9", "complete": "獲得了家緯的礫石！" } ] },
    },
    finished: {
      ...PartnerHappenBasic,
      0: { "npcDialog": "嗨～我是迴響行動夥伴<mark>家緯</mark>！", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" }  ] } ,
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  PartnerHsu: {
    name: '複雜生活節 - 皓甯',
    shot: 'PartnerHsuShot',
    sound: 'npc_partner_m',
    justFinish: {
      ...PartnerHsuBasic,
      101: {
        "npcDialog": "有緣認識，給你我的<mark>石頭 - 砭石</mark>，是中醫刮痧用的神器！",
        "player": [ { "text": "這麼好！謝啦！", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭！",
        "player": [ { "text": "有空經過會去找他的！", next: 103 }, { "text": "好（⋯⋯原來是要我跑腿嗎）", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-hsu" ], "complete": "獲得了皓甯的砭石！", "text": "先收在包包" },
          { "rewards": [ "stone-hsu" ], "text": "查看石頭", "modal": "partner_2", "complete": "獲得了皓甯的砭石！" } ] },
    },
    finished: {
      ...PartnerHsuBasic,
      0: {
        "npcDialog": "嗨！我是迴響行動夥伴<mark>許皓甯</mark>。",
        "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" }  ] } ,
      101: {
        "npcDialog": "還有什麼事嗎？",
        "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] } ,
    }
  },
  PartnerLeo: {
    name: '創咖啡 - LEO',
    shot: 'PartnerLeoShot',
    sound: 'npc_partner_m',
    justFinish: {
      ...PartnerLeoBasic,
      101: {
        "npcDialog": "有緣認識，給你我的<mark>石頭 - 木炭</mark>，可以烘出炭培咖啡，濃厚得讓人想起創業之苦。",
        "player": [ { "text": "這麼好！謝啦！", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭！",
        "player": [ { "text": "有空經過會去找他的！", next: 103 }, { "text": "好（⋯⋯原來是要我跑腿嗎）", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-leo" ], "complete": "獲得了 LEO 的木炭！", "text": "先收在包包" },
          { "rewards": [ "stone-leo" ], "text": "查看石頭", "modal": "partner_6", "complete": "獲得了 LEO 的木炭！" } ] },

    },
    finished: {
      ...PartnerLeoBasic,
      0: { "npcDialog": "嘿！我是迴響行動夥伴 <mark>LEO</mark>！", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" }  ] } ,
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  PartnerPley: {
    name: '玩轉學校 - 阿屁',
    shot: 'PartnerPleyShot',
    sound: 'npc_partner_m',
    justFinish: {
      ...PartnerPleyBasic,
      101: {
        "npcDialog": "森林相遇也算是有緣，送你我的<mark>石頭 - 石油</mark>，逆轉未來遊戲中狂剷共和國的經濟命脈，其實是塑膠做的道具。",
        "player": [ { "text": "耶！那我收下了", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭哦～",
        "player": [ { "text": "有空經過會去找他的！", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-pley" ], "complete": "獲得了阿屁的石油！", "text": "先收在包包" },
          { "rewards": [ "stone-pley" ], "text": "查看石頭", "modal": "partner_8", "complete": "獲得了阿屁的石油！" } ] },
    },
    finished: {
      ...PartnerPleyBasic,
      // 0: { "npcDialog": "嗨～我是迴響行動夥伴<mark>阿屁</mark>！", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" }  ] } ,
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，你是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  PartnerSunnyrush: {
    name: '藺子 - 怡雅',
    shot: 'PartnerSunnyrushShot',
    sound: 'npc_partner_f',
    justFinish: {
      ...PartnerSunnyrushBasic,
      101: {
        "npcDialog": "很開心認識你～送你我的<mark>石頭 - 紅磚</mark>，跟藺草一樣從苑裡帶來的特產，上好品質。",
        "player": [ { "text": "好耶！謝謝", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭哦～",
        "player": [ { "text": "有空經過會去找他的！", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-sunnyrush" ], "complete": "獲得了怡雅的紅磚！", "text": "先收在包包" },
          { "rewards": [ "stone-sunnyrush" ], "text": "查看石頭", "modal": "partner_3", "complete": "獲得了怡雅的紅磚！" } ] },
    },
    finished: {
      ...PartnerSunnyrushBasic,
      0: { "npcDialog": "哈囉～我是迴響行動夥伴<mark>怡雅</mark>！", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" } ] },
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  PartnerWink: {
    name: '眨眨眼 - 張維',
    shot: 'PartnerWinkShot',
    sound: 'npc_partner_f',
    justFinish: {
      ...PartnerWinkBasic,
      101: {
        "npcDialog": "很開心認識你～（眨眼）送你我的<mark>石頭 - 隕石</mark>，星星眨眨眼，星塵掉下來～",
        "player": [ { "text": "好耶！謝謝", next: 102 } ] },
      102: {
        "npcDialog": "有空路過山屋，可以去找<mark>煮石頭湯</mark>的阿德，他在募集石頭哦～",
        "player": [ { "text": "有空經過會去找他的！", next: 103 } ] },
      103: {
        "npcDialog": "喏，給你！（眨眼）<mark>歡迎再找我聊聊！</mark>",
        "player": [
          { "rewards": [ "stone-wink" ], "complete": "獲得了張維的隕石！", "text": "先收在包包" },
          { "rewards": [ "stone-wink" ], "text": "查看石頭", "modal": "partner_12", "complete": "獲得了張維的隕石！" } ] },
    },
    finished: {
      ...PartnerWinkBasic,
      0: { "npcDialog": "哈囉！！！我是迴響行動夥伴<mark>張維</mark>！（眨眼）<br>", "player": [ { "next": 1, "text": "想聊聊天" }, { "text": "確定一下，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事，不小心按到" }  ] } ,
      101: { "npcDialog": "還有什麼事呢？", "player": [ { "next": 1, "text": "想繼續聊聊天" }, { "text": "想再問一次，妳是⋯⋯？", "next": 10 }, { "text": "行動夥伴是⋯？", "next": 200 }, { "text": "沒事了，bye！" }  ] }
    }
  },
  IssueWizard: {
    name: '覺察巫師',
    notFinish: {
      0: { "npcDialog": "hoho，我是<mark>覺察巫師</mark>，你在尋找什麼嗎⋯⋯", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "hoho，我看見了，人人都在尋找、認識自己", "player": [ { "next": 2 } ] } ,
      ...IssueWizardBasic,
      18: { "npcDialog": "如果你對這個議題有興趣，可以這樣展開行動⋯⋯", "player": [ { "text": "快告訴我！", next: 19 } ] },
      19: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 20 } ] },
      20: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-wizard" ], "text": "馬上打開！", "modal": "team_10", "complete": "獲得了覺察巫師的花！" }, { "rewards": [ "flower-wizard" ], "complete": "獲得了覺察巫師的花！", "text": "先收在包包" } ] },
    },
    finished: {
      0: { "npcDialog": [
        "hoho，現在，跟我一起深呼吸，吸氣⋯⋯吐氣⋯⋯", "hoho，怎麼樣我也不會告訴你我昨天不小心閃到腰的糗事", "hoho，你覺得你自己是誰呢？"
      ], "player": [ { "text": "想再聽你聊一次！", next: 1 }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "hoho，我看見了，人人都在尋找、認識自己", "player": [ { "next": 2 } ] } ,
      ...IssueWizardBasic,
      18: { "npcDialog": "如果你對這個議題有興趣，可以這樣展開行動⋯⋯", "player": [ { "text": "再告訴我一次！", next: 19 } ] },
      19: { "npcDialog": "其實⋯我送給你的<mark>花</mark>，裡面都有記著更詳細的議題訊息哦！試著從背包內<mark>使用它</mark>來閱讀吧！", "player": [ { "text": "好～謝啦！" } ] },
    }
  },
  IssueLRRH: {
    name: '月經平權小紅帽',
    notFinish: {
      0: { "npcDialog": "蹦！我是<mark>月經平權小紅帽</mark>！所有月經有關的事，就是我的事！", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "啊⋯⋯你問我什麼是月經平權？", "player": [ { "next": 2 } ] } ,
      ...IssueLRRHBasic,
      13: { "npcDialog": "讓更多大眾、老師瞭解這個議題，幫助到更多有需求的月經者。", "player": [ { "text": "你們在做的事情好有意義，我可以如何參與其中呢？", next: 14 } ] },
      14: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 15 } ] },
      15: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-LRRH" ], "text": "馬上打開！", "modal": "team_5", "complete": "獲得了月經平權小紅帽的花！" }, { "rewards": [ "flower-LRRH" ], "complete": "獲得了月經平權小紅帽的花！", "text": "先收在包包" } ] },
    },
    finished: {
      0: { "npcDialog": [
        "勇敢大聲呼喊月經！不是<mark>大姨媽</mark>也不是<mark>那個</mark>啦！", "蹦！來<a href='https://www.facebook.com/watch/littleredhoodtw/' target='_blank'>小紅帽的臉書粉專</a>陪我玩吧：）", "蹦！萬綠叢中一點紅，就是我啦～"
      ], "player": [ { "text": "想再聽你分享一次！", next: 1 }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "啊⋯⋯你想再聽一次月經平權是嗎？", "player": [ { "next": 2 } ] } ,
      ...IssueLRRHBasic,
      13: { "npcDialog": "讓更多大眾、老師瞭解這個議題，幫助到更多有需求的月經者。", "player": [ { "text": "謝謝你的再次分享！", next: 14 } ] },
      14: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  IssueFamily: {
    name: '育兒奶爸',
    notFinish: {
      0: { "npcDialog": "嘻嘻，我是<mark>育兒奶爸</mark>！你是來探險的嗎？要不要加入我們的野餐休息一會兒呢？", "player": [ { "next": 1, "text": "好哇！無情開吃" }, { "text": "不了，謝謝" } ] } ,
      1: { "npcDialog": "這是我們的孩子，她叫做莎莉，現在 5 個月大，小小的手掌很可愛吧！", "player": [ { "next": 2, "text": "你們家平時是爸爸照顧小孩？" } ] } ,
      ...IssueFamilyBasic,
      13: { "npcDialog": "不止是媽媽需要社群的支持，我們爸爸平時也是有很多疑惑，會想要和有經驗的人一起交流討論的。", "player": [ { "text": "原來如此，真是讓我大開眼界呢！", next: 14  } ] },
      14: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 15 } ] },
      15: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-family" ], "text": "馬上打開！", "modal": "team_2", "complete": "獲得了育兒奶爸的花！" }, { "rewards": [ "flower-family" ], "complete": "獲得了行動奶爸的花！", "text": "先收在包包" } ] },
    },
    finished: {
      0: { "npcDialog": [
        "奇怪，我的育兒書放到哪去⋯⋯", "下一次有遇到身邊的新手爸爸，歡迎和他分享我的故事喔！", "我的小莎莉真的好可愛，我是最幸福的爸爸！"
      ], "player": [ { "text": "想再聽你分享一次你的故事！", next: 1 }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "這是我們的孩子，她叫做莎莉，現在 5 個月大，小小的手掌很可愛吧！", "player": [ { "next": 2, "text": "你們家平時是爸爸照顧小孩？" } ] } ,
      ...IssueFamilyBasic,
      13: { "npcDialog": "不止是媽媽需要社群的支持，我們爸爸平時也是有很多疑惑，會想要和有經驗的人一起交流討論的。", "player": [ { "text": "謝謝你的再次分享！", next: 14  } ] },
      14: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  IssueMovieRabbit: {
    name: '電影兔',
    notFinish: {
      0: { "npcDialog": "咻，我是<mark>電影兔</mark>，沒想到會遇到探險者，想一起<mark>看電影</mark>嗎？", "player": [ {"text": '什麼電影啊？', "next": 1 } ] } ,
      1: { "npcDialog": "哦，你問我會播映什麼啊？", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "這裡是<mark>二輪戲院</mark>，因此播映的大都是首輪下檔的電影。你對二輪戲院有興趣嗎？", "player": [ { "next": 3, "text": "有，跟我說說吧" }, { "text": "沒有，我想自己去晃晃" } ] } ,
      ...IssueMovieRabbitBasic,
      15: { "npcDialog": "讓臺灣人保有<mark>多元的觀影選擇</mark>，同時也保留了珍貴的<mark>文化記憶</mark>！", "player": [ { "text": "想知道更多！", next: 16  } ] },
      16: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 17 } ] },
      17: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-movie-rabbit" ], "text": "馬上打開！", "modal": "team_9", "complete": "獲得了電影兔的花！" }, { "rewards": [ "flower-movie-rabbit" ], "complete": "獲得了電影兔的花！", "text": "先收在包包" } ] },
    },
    finished: {
      0: { "npcDialog": [
        "今晚⋯⋯我想來點⋯⋯經典鴉血麻辣燙加杏苞菇。叮咚！二輪片要開始了！", "1. 請打開 googlemap 直接搜尋「<mark>二輪戲院</mark>」<br>2. 點擊「導航」", "我在家鄉有一片紅羅蔔田喔！"
      ], "player": [ { "text": "想再聽你聊一次二輪戲院！", next: 3 }, { "text": "再見，後會有期" }] },
      ...IssueMovieRabbitBasic,
      15: { "npcDialog": "讓臺灣人保有<mark>多元的觀影選擇</mark>，同時也保留了珍貴的<mark>文化記憶</mark>！", "player": [ { "text": "謝謝你再次分享！", next: 16  } ] },
      16: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  IssueClimber:{
    name: '森林登山客',
    notFinish: {
      0: { "npcDialog": "今天的天氣真適合帶上裝備到山林裡走一走啊！你說是吧？", "player": [ { "next": 1, "text": "是阿，我也喜歡從事戶外活動！" }, { "text": "我比較喜歡在室內吹冷氣", "next": "1" } ] } ,
      1: { "npcDialog": "原來是這樣阿⋯⋯", "player": [ { "next": 2 } ] } ,
      ...IssueClimberBasic,
      11: { "npcDialog": "我們就可以在維持原本的生活品質下，持續的親近大自然了。", "player": [ { "text": "如果我想更接近自然山林，可以怎麼做？", next: 12 } ] },
      12: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 13 } ] },
      13: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-climber" ], "text": "馬上打開！", "modal": "team_4", "complete": "獲得了森林登山客的花！" }, { "rewards": [ "flower-climber" ], "complete": "獲得了森林登山客的花！", "text": "先收在包包" } ] },
    },
    finished: {
      0: { "npcDialog": [
        "好癢，我的登山鞋裡好像有條蛇！", "想接近森林，「來去住一晚」是一個適合新手的好方案喔！", "你也來爬山嗎？注意安全別迷路了喔！"
      ], "player": [ { "text": "我想看如何展開議題", next: 1 }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "想再和你聊一次山林！", "player": [ { "next": 2 } ] } ,
      ...IssueClimberBasic,
      11: { "npcDialog": "我們就可以在維持原本的生活品質下，持續的親近大自然了。", "player": [ { "text": "謝謝你的再次分享！", next: 12 } ] },
      12: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  IssueSnowMan: {
    name: '雪堡',
    notFinish: {
      0: { "npcDialog": "陌生人！我是有著外冷內熱性格的<mark>雪堡</mark>。", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "我正在練習與<mark>政治立場對立</mark>的人溝通，你願意和我聊聊嗎？", "player": [ { "next": 2, "text": "好阿" }, { "text": "先算了吧" } ] } ,
      ...IssueSnowManBasic,
      15: { "npcDialog": "如果是<mark>建設性</mark>的，可以成為<mark>溝通的契機</mark>，不見得是不好的喔！", "player": [ { "text": "真有趣，想進一步瞭解與異溫層溝通的細節", next: 16 } ] },
      16: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 17 } ] },
      17: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-snow-man" ], "text": "馬上打開！", "modal": "team_7", "complete": "獲得了雪堡的花！" }, { "rewards": [ "flower-snow-man" ], "complete": "獲得了雪堡的花！", "text": "先收在包包" } ] },
    },
    finished: {
      0: { "npcDialog": [
        "就算熱情到融化了自己，我也會很開心的。", "你上一次與異溫層溝通是什麼時候呢？", "深⋯⋯呼⋯⋯吸⋯⋯"
      ], "player": [ { "text": "想再聽你聊一次", next: 1 }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "好啊，我最近正在練習與<mark>政治立場對立</mark>的人溝通", "player": [ { "next": 2 } ] } ,
      ...IssueSnowManBasic,
      15: { "npcDialog": "如果是<mark>建設性</mark>的，可以成為<mark>溝通的契機</mark>，不見得是不好的喔！", "player": [ { "text": "真有趣，謝謝你的再次分享！", next: 16 } ] },
      16: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  IssueElf: {
    name: '關懷小精靈',
    notFinish: {
      0: { "npcDialog": "Weeee！你⋯⋯你在看我嗎？", "player": [ { "next": 1, "text": "你在這裡做什麼阿？" },{"next": 100,"text": "沒啊，自以為喔" } ] } ,
      1: { "npcDialog": "我是關懷小精靈，我正在四處巡邏喔，我的工作是關懷並協助<mark>有情緒煩惱的大學生</mark>^^", "player": [ { "next": 2,"text": "情緒煩惱的大學生？" } ] } ,
      ...IssueElfBasic,
      18: { "npcDialog": "因此，關懷大學生的煩惱，不應操之過急直接找解法，找到<mark>根本的問題來源</mark>，才是面對情緒首要做的事情。", "player": [ {"text": "我對這個議題有感觸，告訴我更多！", next: 19 } ] },
      19: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 20 } ] },
      20: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-elf" ], "text": "馬上打開！", "modal": "team_3", "complete": "獲得了關懷小精靈的花！" }, { "rewards": [ "flower-elf" ], "complete": "獲得了關懷小精靈的花！", "text": "先收在包包" } ] },

      100: { "npcDialog": "好⋯⋯好吧。（默默躲回樹後面）" }
    },
    finished: {
      0: { "npcDialog": [
        "對你來說，怎樣才是成功呢？", "我是精靈，不是神燈 Ginnie，請不要對我許願！", "有對長耳朵偶爾也會困擾的呢，能選的帽子好少⋯⋯"
      ], "player": [ { "text": "想再聽你聊一次", next: 1 }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "好啊！我是關懷小精靈，正在四處巡邏。我的工作是關懷並協助<mark>有情緒煩惱的大學生</mark>^^", "player": [ { "next": 2, "text": "情緒煩惱的大學生？" } ] } ,
      ...IssueElfBasic,
      18: { "npcDialog": "因此，關懷大學生的煩惱，不應操之過急直接找解法，找到<mark>根本的問題來源</mark>，才是面對情緒首要做的事情。", "player": [ {"text": "謝謝你的再次分享！", next: 19 } ] },
      19: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  IssueTourGuide: {
    name: '提燈嚮導',
    notFinish: {
      0: { "npcDialog": "Hey！這位朋友，看來你有點疑惑，你迷路了嗎？", "player": [ { "next": 1, "text": "你是嚮導嗎？跟我聊一聊吧！" } ] } ,
      1: { "npcDialog": "我其實是一位老師喔，我平時的任務是<mark>陪伴與引導高中生</mark>。", "player": [ { "next": 2, "text": "哦，請你多說一些吧！" }, { "text": "這不是我感興趣的，我自己逛逛就好" } ] } ,
      ...IssueTourGuideBasic,
      16: { "npcDialog": "因此學生需要其他搜尋與瞭解科系的管道，像是<mark>學長姐的經驗分享</mark>等等。", "player": [ {"text": "請讓我知道更多高中生探索的相關議題！", next: 17 } ] },
      17: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 18 } ] },
      18: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開。", "player": [ { "rewards": [ "flower-tour-guide" ], "text": "馬上打開！", "modal": "team_6", "complete": "獲得了提燈嚮導的花！" }, { "rewards": [ "flower-tour-guide" ], "complete": "獲得了提燈嚮導的花！", "text": "先收在包包" } ] }
    },
    finished: {
      0: { "npcDialog": [
        "我的學生怎麼跑得一個都不見人影？還是，是我迷路了？", "Hey！社團經驗很寶貴，找時間回顧一下吧！", "歡迎來我的 IG 逛逛<br>IG：<a href='https://www.instagram.com/findurself0620/' target='_blank'>findurself0620</a>"
      ], "player": [ { "text": "想再聽你說一次", next: 1 }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "我是一位老師，平時的任務是<mark>陪伴與引導高中生</mark>。", "player": [ { "next": 2 } ] } ,
      ...IssueTourGuideBasic,
      16: { "npcDialog": "因此學生需要其他搜尋與瞭解科系的管道，像是<mark>學長姐的經驗分享</mark>等等。", "player": [ {"text": "請讓我知道更多高中生探索的相關議題！", next: 17 } ] },
      17: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  IssueBear: {
    name: '黑雄',
    sound: 'npc_bear',
    notFinish: {
      0: { "npcDialog": "黑嘿，你膽子真大，居然主動找我說話。別人一看到我身上的傷疤，就覺得是凶神惡煞，躲得遠遠的。", "player": [ {text: '你是⋯⋯', "next": 2 } ] } ,
      // 1: { "npcDialog": "", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "啊，忘了自我介紹了⋯⋯我是<mark>黑雄</mark>，是一個<mark>高關懷少年</mark>，黑嘿。", "player": [ { "next": 3, "text": "原來，我能和你聊一聊嗎？" }, { "text": "光速逃跑" } ] } ,
      ...IssueBearBasic,
      15: { "npcDialog": "就像你剛剛願意和我聊下去，才可能認識我的<mark>實際的樣子</mark>喔！黑嘿。", "player": [ { "text": "我願意認識更多！", next: 16 } ] },
      16: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 17 } ] },
      17: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-bear" ], "text": "馬上打開！", "modal": "team_11", "complete": "獲得了黑雄的花！" }, { "rewards": [ "flower-bear" ], "complete": "獲得了黑雄的花！", "text": "先收在包包" } ] }
    },
    finished: {
      0: { "npcDialog": [
        "真想趕快長大，有能力幫助和我一樣遭遇的少年⋯⋯", "想跟我比力氣嗎？黑嘿。", "你知道嗎，蜂蜜是這世界上最棒的食物！"
      ], "player": [ { "text": "想再聽一次你的故事", next: 2 }, { "text": "再見，後會有期" }] },
      2: { "npcDialog": "好啊，我是<mark>黑雄</mark>，是一個<mark>高關懷少年</mark>，黑嘿。", "player": [ { "next": 3, "text": "你好，黑雄！" }] } ,
      ...IssueBearBasic,
      15: { "npcDialog": "就像你剛剛願意和我聊下去，才可能認識我的<mark>實際的樣子</mark>喔！黑嘿。", "player": [ { "text": "謝謝你再次跟我分享！", next: 16 } ] },
      16: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  IssueFisherman: {
    name: '釣魚的人',
    sound: 'npc_m_4',
    notFinish: {
      0: { "npcDialog": "Hi 你好，<br>找我有什麼事嗎？", "player": [ { "next": 1, "text": "你在釣魚嗎？" } ] } ,
      1: { "npcDialog": "是阿，我喜歡一邊釣魚一邊思考！最近在想一位叔叔的事。", "player": [ { "next": 2 } ] } ,
      ...IssueFishermanBasic,
      13: { "npcDialog": "無論如何，叔叔在我心中仍是那個很親切、很照顧我的長輩！", "player": [ { "text": "我也認為這是一個值得更多人關注的議題，想聽你說更多！", next: 14 } ] },
      14: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 15 } ] },
      15: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-fisherman" ], "text": "馬上打開！", "modal": "team_1", "complete": "獲得了釣魚的人的花！" }, { "rewards": [ "flower-fisherman" ], "complete": "獲得了釣魚的人的花！", "text": "先收在包包" } ] }
    },
    finished: {
      0: { "npcDialog": [
        "已經過了三個小時，浮標一點動靜也沒有⋯⋯", "想更瞭解更生人議題，可以追蹤<a href='https://www.facebook.com/darkroompeople' target='_blank'>暗房裡的人</a>，他們會定期發布資訊喔！", "嗯⋯⋯真想念小時候叔叔常帶我去買糖啊。"
      ], "player": [ { "next": 1, "text": "想再聽一次你的故事" }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "好啊，我喜歡一邊釣魚一邊思考！最近在想一位叔叔的事。", "player": [ { "next": 2 } ] } ,
      ...IssueFishermanBasic,
      13: { "npcDialog": "無論如何，叔叔在我心中仍是那個很親切、很照顧我的長輩！", "player": [ { "text": "這個議題真的好重要，謝謝你再次分享！", next: 14 } ] },
      14: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] }
    }
  },
  IssueCarpentar: {
    name: '木匠姊姊',
    notFinish: {
      0: { "npcDialog": "嗨，我是木匠姊姊迦瞿，我是最喜歡蒐集傢俱的木匠！<mark>聽故事找我，做東西找我弟弟！</mark>", "player": [ { "next": "1" } ] } ,
      1: { "npcDialog": "關於<mark>傢俱</mark>的挑選與回收我有很多心得，你想聽嗎？", "player": [ { "text": "好阿", "next": "2" }, { "text": "謝謝，先不用了" } ] } ,
      ...IssueCarpentarBasic,
      16: { "npcDialog": "臺北、臺中、臺南的環保單位甚至會在收取後進行評估，讓傢俱有機會到<mark>二手傢俱賣場</mark>供民眾挑選喔。", "player": [ { "text": "原來是這樣啊！我想知道更多", next: 17 } ] },
      17: { "npcDialog": "送給你我的<mark>花</mark>吧！裡面記載著更詳細的議題訊息，可以從背包內<mark>使用它</mark>來閱讀。", "player": [ { "text": "太好了，謝謝你！", next: 18 } ] },
      18: { "npcDialog": "有興趣的話，可以馬上打開來看唷！也可以隨時從包包裡打開", "player": [ { "rewards": [ "flower-carpentar" ], "text": "馬上打開！", "modal": "team_8", "complete": "獲得了迦瞿的花！" }, { "rewards": [ "flower-carpentar" ], "complete": "獲得了迦瞿的花！", "text": "先收在包包" } ] }
    },
    finished: {
      0: { "npcDialog": [
        "動手 DIY 超好玩的啦！", "你試過買二手傢俱嗎？<br>既環保又省荷包喔！", "我愛紫色沙發！"
      ], "player": [ { "text": "想再聽你聊一次二手傢俱！", next: 1 }, { "text": "再見，後會有期" }] },
      1: { "npcDialog": "關於傢俱的<mark>挑選</mark>與<mark>回收</mark>我真的有好多心得", "player": [ { "text": "恩，請繼續說下去！", "next": "2" } ] } ,
      ...IssueCarpentarBasic,
      16: { "npcDialog": "臺北、臺中、臺南的環保單位甚至會在收取後進行評估，讓傢俱有機會到<mark>二手傢俱賣場</mark>供民眾挑選喔。", "player": [ { "text": "原來是這樣啊！謝謝你再次分享", next: 17 } ] },
      17: { "npcDialog": "我送給你的<mark>花</mark>，裡面記載著更詳細的議題訊息哦！可以從背包內<mark>使用它</mark>來閱讀。", "player": [] },
    }
  },
  BaseStation:{
    onlyTalking: {
      0: { "npcDialog": "不曉得把基地台搭建在這裡是為了接收什麼信號？咦，對面的山頂好像在閃爍著什麼⋯⋯", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "到更高的山頂去一探究竟吧！", "player": [ {"text": "繼續前行" } ] }
    }
  },
  CableCarStation: {
    name: '纜車',
    justFinish: {
      0: { "npcDialog": "貌似是連接到山頂的纜車，但好像只提供從山頂下來的服務。", "player": [ { "text": "好吧" }, { "text": "踹一腳洩憤", "next": "1" } ] } ,
      1: { "npcDialog": "（整座纜線晃動了一下，貌似聽到山頂傳來尖叫聲⋯⋯）", "player": [ { "text": "快逃" } ] }
    }
  },
  Sign1:{
    name: '告示牌',
    notFinish: {
      0: { "npcDialog": "霧濃，危險。請<mark>點燃營火</mark>後再前行。", "player": [ {} ] }
    },
    justFinish: {
      0: { "npcDialog": "前方是通往<mark>行動大山</mark>的路，建議初次登入的玩家，可以先爬完這座山，再去探索其他的地方。", "player": [ {} ] }
    }
  },
  Hiker1: {
    name: '登山客阿明',
    notFinish: {
      0: { "npcDialog": "哎呀，霧這麼濃根本沒辦法爬山呀⋯⋯據說霧會散不掉是因為山屋前的<mark>營火</mark>熄滅了。", "player": [ {next: 1} ] },
      1: { "npcDialog": "總覺得有點奇怪，霧這麼濃火哪點得起來？山屋裡的<mark>老協作</mark>，平常到底是怎麼點火的？⋯⋯"},
    },
    justFinish: {
      // 0: { "npcDialog": "嗨，你第一次爬這座山嗎？聽說山頂上有<mark>纜車可以直達山屋</mark>喔，所以不用擔心下山的時候會太累！" }
      0: { "npcDialog": "這座山很大，不急於一天之內爬完，關掉網頁之後，你下次還是會出現在一樣的位置喔！" }
    }
  },
  Hiker2: {
    name: '拿旗子的登山客',
    notFinish: {
      0: { "npcDialog": "那會按呢！霧遮重根本沒法度爬山⋯⋯是 m̄ 是因為<mark>火	hoa 去</mark>啊？" }
    },
    justFinish: {
      0: { "npcDialog": "少年家，你頭一擺來遮 hiou3？偷偷仔	kah 你講，山頂有人 le <mark>翕相</mark>喔！", "player": [ {next: 1, text: "聽無"} ] },
      1: { "npcDialog": "<mark>拍照</mark>啦！山頂有人在拍照啦！那裡景色很棒，快到頂端去看看吧。" }
    }
  },
  Hiker3: {
    name: '登山客帽子哥',
    notFinish: {
      0: { "npcDialog": "為何、為何營火熄滅，我們，腳步如此急切⋯⋯", "player": [ {next: 1} ] },
      1: { "npcDialog": "世界灰暗，我依然過著我的生活，等待有心人在闌珊處<mark>點亮燈火</mark>⋯⋯"},
    },
    justFinish: {
      0: { "npcDialog": "Yo～Check it. 來到這千萬不要感到驚悚，即便你只是這裡的新手。", "player": [ {next: 1, text: '你在饒舌嗎⋯⋯'} ] },
      1: { "npcDialog": "如果你也一樣緬懷從前，那走左邊會是你的首選。霧裡濃濃飄著懷舊的味道，引領你走入<mark>時空的隧道</mark>。" },
    }
  },
  CableCar: {
    name: '山頂纜車',
    justFinish: {
      0: {
        changeName: 'player',
        npcDialog: "這裡竟然有座纜車，要搭乘嗎？",
        player: [
          {
            text: '搭乘下山',
            cableCar: true
          },
          {
            text: '真的是先不要',
          }
        ]
      }
    },
  },
  y2027_stone: {
    name: 'Rethink2027——十年願景',
    notFinish: {
      // 0: { "npcDialog": "明明只是普通的岩石，卻給人一種莊嚴的感覺，似乎跟其他石頭不太一樣。", "changeName": "player" }
      // ...StoneBasic
      0: { "npcDialog": "石碑中源源不絕的行動能量正在滲出，是否伸出手感應？", "player": [ { "next": 1, "text": "伸手感應", "event": ['y2027_stone'], ongoing: 'true', delNPCs: ['y2027_stone'] } ], "changeName": "未知的石碑" } ,
      1: { "npcDialog": "（石碑上的文字漸漸湧現）", "player": [ { "next": 0 } ]},
    },
    onGoing: {
      0: {
        changeName: 'player',
        npcDialog: "石碑上刻著：十年願景。",
        player: [
          {
            text: '仔細看石碑上的文字⋯',
            modal: 's1_stone_tablet'
          }
        ]
      }
    },
    justFinish: {
      0: { "npcDialog": "石碑上刻著：十年願景。以及，咦？<br>是一些<mark>看不懂的語言</mark>⋯⋯", "player": [ { "next": 1, "text": "試著解讀" }, { "text": "看十年願景", "modal": "s1_stone_tablet" } ], "changeName": "player" } ,
      1: { "npcDialog": "我是行動大神，<br>我沒有形體，", "player": [ { "next": 2 } ], "changeName": "未知的碑文" } ,
      2: { "npcDialog": "精神散佈在宇宙間，<br>我是宇宙擴張的原因、地球轉動的動力，", "player": [ { "next": 3 } ], "changeName": "未知的碑文" } ,
      3: { "npcDialog": "是讓四季和日月更迭的原因，<br>我是行動的原因。", "player": [ { "text": "伸出手感應", next: 4, recoverNPCs: ['GodLight'] } ], "changeName": "未知的碑文" } ,
      4: { "npcDialog": "身體彷彿被什麼能量吸了進去，我感受到，一道光打在我身上⋯⋯", "player": [ { "complete": "true", "transferAndDialog": {x: 206, y: 20}, recoverNPCs: ['ActionGod', 'ActionGodFloor']  } ], "changeName": "player" }
    },
    finished: {
      0: { "npcDialog": "石碑上刻著：十年願景。以及，咦？<br>是一些<mark>看不懂的語言</mark>⋯⋯", "player": [ { "next": 1, "text": "試著解讀" }, { "text": "看十年願景", "modal": "s1_stone_tablet" } ], "changeName": "player" } ,
      1: { "npcDialog": "我是行動大神，<br>我沒有形體，", "player": [ { "next": 2 } ], "changeName": "未知的碑文" } ,
      2: { "npcDialog": "精神散佈在宇宙間，<br>我是宇宙擴張的原因、地球轉動的動力，", "player": [ { "next": 3 } ], "changeName": "未知的碑文" } ,
      3: { "npcDialog": "是讓四季和日月更迭的原因，<br>我是行動的原因。", "player": [ { "text": "伸出手感應", next: 4 } ], "changeName": "未知的碑文" } ,
      4: { "npcDialog": "迴響大神已經離開石碑了，但他當初留給我一個小人徽章，或許能從徽章找到他？", "changeName": "player" } ,
    },
  },
  y2020_9: {
    name: '2020 - Ending Rainbow',
    justFinish: {
      0: { "npcDialog": "這蠟燭的周圍⋯⋯是<mark>七彩的光芒</mark>，似乎有什麼要從中誕生了？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "做網站要崩潰時，所有人跑到陽台去看<mark>彩虹</mark>，這是第四屆正要開啟報名的時候⋯⋯", "player": [ { "next": 2 } ], "image": "2020_9" } ,
      2: { "npcDialog": "到了尾聲，這個線上展上線前，也是瀕臨崩潰之際。", "player": [ { "next": 3 } ], "image": "2020_9" } ,
      3: { "npcDialog": "所有人 WFH 在家裡面遠端連線 、支持，完成我們心目中那最完整、能串連所有人事物的成果。", "player": [ { "text": "點燃蠟燭", "event": [ "y2020_9" ], "delNPCs": [ "y2020_9" ] } ], "image": "2020_9" }
    },
  },
  y2020_8: {
    name: '2020 - 新嘗試——議題團隊',
    justFinish: {
      0: { "npcDialog": "這裡散發出了過去從未有過的光芒，是第四屆<mark>全新的嘗試</mark>？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "今年的重大突破之一，就是拉長青年的行動。我們和已行之有年的「<mark>議題團隊</mark>」，一起合作、探詢：", "player": [ { "next": 2 } ], "image": "2020_8-5" } ,
      2: { "npcDialog": "「號召<mark>志工</mark>的心法？把人找來了，然後呢？」", "player": [ { "next": 3 } ], "image": "2020_8-2" } ,
      3: { "npcDialog": "六組議題團隊，過去幾年下來，都透過實際行動累積一定程度與<mark>志工</mark>相關的知識、經驗。", "player": [ { "next": 4 } ], "image": "2020_8-4" } ,
      4: { "npcDialog": "他們藉由這次的合作機會，試著寫出「<mark>號召</mark>的方法論」，找到所謂「創新志工」的新可能。", "player": [ { "next": 5 } ], "image": "2020_8-3" } ,
      5: { "npcDialog": "第一步，往往非常困難，有機會邀請您來聽我們的線上直播，分享這一次的實驗心得。", "player": [ { "text": "點燃蠟燭", "event": [ "y2020_8" ], "delNPCs": [ "y2020_8" ] } ], "image": "2020_8-6" }
    },
  },
  y2020_7: {
    name: '2020 - 評審的視角',
    justFinish: {
      0: { "npcDialog": "這個畫面，是<mark>評審</mark>嗎？蠟燭的火光映照出了一些樣貌⋯⋯", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "如同每一屆的評審，每一屆的行動夥伴，每一次的評選，都再一次提醒著迴響夥伴：", "player": [ { "next": 2 } ], "image": "2020_7-1" } ,
      2: { "npcDialog": "「我們坐在所謂的評審室、評審桌前看到的提案，有些人可能是用盡了勇氣，<mark>第一次</mark>踏出第一步行動的人；", "player": [ { "next": 3 } ], "image": "2020_7-1" } ,
      3: { "npcDialog": "有些人可能深陷議題許久，<mark>第一次</mark>成為行動者去改變現況⋯⋯」", "player": [ { "next": 4 } ], "image": "2020_7-1" } ,
      4: { "npcDialog": "而面對這樣的團隊，我們如何秉持著迴響初衷？", "player": [ { "next": 5 } ], "image": "2020_7-2" } ,
      5: { "npcDialog": "評審給予的不只是夠不夠資格獲得資源，而是團隊們可以得到什麼<mark>方法和幫助</mark>，讓青年們踏出下一步行動。", "player": [ { "text": "點燃蠟燭", "event": [ "y2020_7" ], "delNPCs": [ "y2020_7" ] } ], "image": "2020_7-3" }
    },
  },
  y2020_6: {
    name: '2020 - 不行動的行動',
    justFinish: {
      0: { "npcDialog": "到了這座蠟燭，氣氛開始緩和下來，彷彿身心靈都慢慢<mark>靜下來</mark>了。", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "照片裡戴紅帽子的人，是二屆<mark>行動團隊</mark>，三、四屆的時候，多半由她來擔任<mark>主持人</mark>。", "player": [ { "next": 2 } ], "image": "2020_6-2" } ,
      2: { "npcDialog": "特別是在營造氛圍、凝聚大家的心的時候。", "player": [ { "next": 3 } ], "image": "2020_6-2" } ,
      3: { "npcDialog": "或許是某種共時的巧合吧？第三、第四屆的青年團隊，有越來越多人在探討<mark>心靈層面</mark>的議題", "player": [ { "next": 4 } ], "image": "2020_6-2" } ,
      4: { "npcDialog": "領域觸及憂鬱、自殺、更生、部落薩滿、求學挫折⋯⋯等不同的範圍。", "player": [ { "next": 5 } ], "image": "2020_6-2" } ,
      5: { "npcDialog": "「閉上眼睛，深呼吸、長吐氣，行動過程中，『靜下來、停下來、放下來』也是無比重要的抉擇。」", "player": [ { "next": 6 } ], "image": "2020_6-1" } ,
      6: { "npcDialog": "而我們也要適時鼓勵彼此，做出「<mark>不行動</mark>」的行動。", "player": [ { "text": "點燃蠟燭", "event": [ "y2020_6" ], "delNPCs": [ "y2020_6" ] } ], "image": "2020_6-1" }
    },
  },
  y2020_5: {
    name: '2020 - 任務包 / 迴圈包',
    justFinish: {
      0: { "npcDialog": "好複雜⋯⋯這個蠟燭附近擺滿了各種「<mark>工具</mark>」？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "又來到每一年大會師重頭戲之一——<mark>工作坊</mark>。每一次對機制組的夥伴而言，都是新的挑戰。", "player": [ { "next": 2 } ], "image": "2020_5-1" } ,
      2: { "npcDialog": "我們持續優化所有<mark>硬體</mark>（思考方法、行動迴圈包）和<mark>軟體</mark>（活動安排、適度的社交交流、迴響精神的鼓舞），", "player": [ { "next": 3 } ], "image": "2020_5-1" } ,
      3: { "npcDialog": "當這些狀態平衡，才能讓我們輸出的知識體系，被青年更好理解、使用。", "player": [ { "next": 4 } ], "image": "2020_5-3" } ,
      4: { "npcDialog": "「學到一生能帶著走的能力，」是驗證迴響不只是創業競賽，最好的證據。", "player": [ { "text": "點燃蠟燭", "event": [ "y2020_5" ], "delNPCs": [ "y2020_5" ] } ], "image": "2020_5-2" }
    },
  },
  y2020_4: {
    name: '2020 - 大本營',
    justFinish: {
      0: { "npcDialog": "這個蠟燭的香氛，給人好安心的感覺，就像是<mark>家</mark>一樣⋯⋯", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "今年，我們要讓大家知道，迴響本身，就是行動者的大本營。", "player": [ { "next": 2 } ], "image": "2020_4-2" } ,
      2: { "npcDialog": "每個來到線上官網、實體空間，就像是在爬山路的歷程一樣，每個人有不同的頂峰要征服。", "player": [ { "next": 3 } ], "image": "2020_4-2" } ,
      3: { "npcDialog": "但中間的休憩站、可以充電交流的大本營，就是<mark>迴響山屋</mark>！", "player": [ { "next": 4 } ], "image": "2020_4-1" } ,
      4: { "npcDialog": "因此，現場布滿了植物、蕨類，露營用具、木頭器材，為的就是要全身心都感受到被鼓舞、被激勵。", "player": [ { "next": 5 } ], "image": "2020_4-3" } ,
      5: { "npcDialog": "「行動路上絕不會一路安穩，但你會知道只要繼續前行，就會遇見你的伙伴。」", "player": [ { "text": "點燃蠟燭", "event": [ "y2020_4" ], "delNPCs": [ "y2020_4" ] } ], "image": "2020_4-4" }
    },
  },
  y2020_3: {
    name: '2020 - 絕無僅有的主視覺',
    justFinish: {
      0: { "npcDialog": "又是<mark>顏料</mark>了！幾乎每一屆都有顏料，但這次好像沒看見白 T-shirt？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "迴響一直以來都跟顏料很有緣份（想起那些絹印的衣服了嗎？）⋯⋯那第四屆呢？", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "第四屆活動現場的主視覺設計，就讓設計師們，直接用手畫出來吧！", "player": [ { "next": 3 } ], "image": "2020_3-1" } ,
      3: { "npcDialog": "空白的布幕上，投影出官網的營火區域，象徵著大會師現場，就是大家的大本營。", "player": [ { "next": 4 } ], "image": "2020_3-1" } ,
      4: { "npcDialog": "而空白處的塗鴉，則是行動團隊一一加上去的情緒線條、或是象徵行動的物件。", "player": [ { "next": 5 } ], "image": "2020_3-3" } ,
      5: { "npcDialog": "如假似真的主視覺，一樣絕無僅有。", "player": [ { "text": "點燃蠟燭", "event": [ "y2020_3" ], "delNPCs": [ "y2020_3" ] } ], "image": "2020_3-2" }
    },
  },
  y2020_2: {
    name: '2020 - 概念啊！',
    justFinish: {
      0: { "npcDialog": "蠟燭燃燒的燭火上，漂浮著攝影機的影像，是<mark>攝影師</mark>嗎？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "謹以此代表，謝謝所有歷屆<mark>概念啊</mark>攝影公司的攝影師們。", "player": [ { "next": 2 } ], "image": "2020_2-1" } ,
      2: { "npcDialog": "這些攝影師，不只是攝影師，還是具備迴響精神的攝影師，一起行動、靜下來反思、用心感受場佈的細節。", "player": [ { "next": 3 } ], "image": "2020_2-2" } ,
      3: { "npcDialog": "這些攝影師，不只是攝影師，還是跟著一起響應、熱切回應號召者的夥伴，", "player": [ { "next": 4 } ], "image": "2020_2-3" } ,
      4: { "npcDialog": "試著從鏡頭的語彙，去述說他們看見的事件、紀錄內心感動的時刻。", "player": [ { "next": 5 } ], "image": "2020_2-3" } ,
      5: { "npcDialog": "謝謝你們，非常非常。", "player": [ { "text": "點燃蠟燭", "event": [ "y2020_2" ], "delNPCs": [ "y2020_2" ] } ], "image": "2020_2-4" }
    },
  },
  y2020_1: {
    name: '2020 - 三大精神的火光',
    justFinish: {
      0: { "npcDialog": "是蠟燭？蠟燭的周圍似乎圍繞著三種顏色？他們各自蘊含著不同的能量⋯⋯", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "<mark>三大精神</mark>，貫穿每一個行動，牽引著每一位迴響人。", "player": [ { "next": 2 } ], "image": "2020_1" } ,
      2: { "npcDialog": "「<mark>先動</mark>，再說。」", "player": [ { "next": 3 } ], "image": "2020_1" } ,
      3: { "npcDialog": "「懷抱夢想，但從<mark>做得到</mark>的事情開始。」", "player": [ { "next": 4 } ], "image": "2020_1" } ,
      4: { "npcDialog": "「將遇到的困難，轉化成<mark>學習</mark>的機會。」", "player": [ { "next": 5 } ], "image": "2020_1" } ,
      5: { "npcDialog": "這些概念很容易懂，但不容易做得到。", "player": [ { "next": 6 } ], "image": "2020_1" } ,
      6: { "npcDialog": "做到了，也不見得能持久。", "player": [ { "next": 7 } ], "image": "2020_1" } ,
      7: { "npcDialog": "維持了，還要有容乃大，讓夥伴和自己都可以經歷這些體驗。", "player": [ { "next": 8, "text": "點燃蠟燭", "event": [ "y2020_1" ], "delNPCs": [ "y2020_1" ] } ], "image": "2020_1" }
    },
  },
  y2020_stone: {
    name: '迴響計畫第四屆 - 2020',
    notFinish: {
      // 0: { "npcDialog": "石碑上好像有什麼文字⋯⋯照智者所說的，我似乎要先去「點亮」周圍的<mark>蠟燭</mark>？", "player": [ {} ], "changeName": "未知的石碑" }
      ...StoneBasic
    },
    justFinish: {
      0: { "npcDialog": "石碑中源源不絕的行動能量正在滲出，是否伸出手感應？", "player": [ { "next": 1, "text": "伸手感應", "event": ['y2020_stone'], complete: 'true', delNPCs: ['y2020_stone'] } ], "changeName": "未知的石碑" } ,
      1: { "npcDialog": "（石碑上的文字漸漸湧現）", "player": [ { "next": 0 } ]},
    },
    finished: {
      0: { "npcDialog": "這些<mark>蠟燭</mark>⋯⋯是我們在第四屆時，送給行動團隊們的禮物！", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "2020 年，是與世界連線的一年，我們思考了更多「<mark>迴響之外</mark>的可能」。", "player": [ { "text": "一探究竟", "modal": "y2020_stone" }, { "text": "無情離去" } ] }
    }
  },
  y2019_9: {
    name: '2019 - 獨有的獎狀',
    justFinish: {
      0: { "npcDialog": "放了幾張<mark>獎狀</mark>在三角錐裡，是給誰的呢？", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "不知道你知不知道，但這是一屆<mark>完全沒有獎金</mark>的一屆。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "而這也是一個契機，讓我們終於找到一種<mark>屬於迴響的獎狀</mark>。", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "「除了給錢，評審能給予什麼陪伴、建議，讓青年團隊離開迴響後，還有<mark>下一步的資源</mark>，可以更走進社會？」", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "No.094 波波實驗室_民主永遠不會過期獎", "player": [ { "next": 5 } ], "image": "2019_9-1" } ,
      5: { "npcDialog": "No.078_小紅帽_ INFINITY", "player": [ { "next": 6 } ], "image": "2019_9-2" } ,
      6: { "npcDialog": "No.105_詩門倍思_泰雅文化復興獎", "player": [ { "text": "點亮三角錐", "event": [ "y2019_9" ], "delNPCs": [ "y2019_9" ] } ], "image": "2019_9-3" }
    },
  },
  y2019_8: {
    name: '2019 - 依然熟悉的行動夥伴',
    justFinish: {
      0: { "npcDialog": "<mark>行動夥伴</mark>！我看見他們的身影了，一群熟悉的人呀⋯⋯", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "在資源如此匱乏的一屆，我們該如何維持以往的規模，給新的青年同樣的資源呢？", "player": [ { "next": 2 } ], "image": "2019_8-2" } ,
      2: { "npcDialog": "在這樣變動的一年，感謝仍然有<mark>行動夥伴</mark>的支持，持續陪伴著迴響。", "player": [ { "next": 3 } ], "image": "2019_8-2" } ,
      3: { "npcDialog": "行動夥伴和青年們討論一個又一個新的<mark>發現</mark>、新的<mark>社會議題</mark>、新的<mark>洞察</mark>⋯⋯", "player": [ { "next": 4 } ], "image": "2019_8-1" } ,
      4: { "npcDialog": "於此同時，行動夥伴們也看見新的自己。", "player": [ { "text": "點亮三角錐", "event": [ "y2019_8" ], "delNPCs": [ "y2019_8" ] } ], "image": "2019_8-3" }
    },
  },
  y2019_7: {
    name: '2019 - 業界評審的映像',
    justFinish: {
      0: { "npcDialog": "孔洞中隱約能看見<mark>評審</mark>的身影，不曉得他們在迴響的角色是？，", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "有時候，的確什麼都不知道，會不小心誤闖禁區，但危險<mark>不該</mark>是阻礙行動的原因。", "player": [ { "next": 2 } ], "image": "2019_7" } ,
      2: { "npcDialog": "評審們，有時候擔任的角色，就是讓青年更加明白：", "player": [ { "next": 3 } ], "image": "2019_7" } ,
      3: { "npcDialog": "你的意圖或許良善，但「可以<mark>做得到</mark>的事」究竟有那些？", "player": [ { "next": 4 } ], "image": "2019_7" } ,
      4: { "npcDialog": "團隊是否有真的認識<mark>利害關係人</mark>的<mark>需求</mark>？", "player": [ { "next": 5 } ], "image": "2019_7" } ,
      5: { "npcDialog": "團隊如果要繼續走下去，還有哪些<mark>專業</mark>的面向需要增進？", "player": [ { "next": 6 } ], "image": "2019_7" } ,
      6: { "npcDialog": "為團隊找到業界<mark>實務經驗</mark>的評審，是迴響給予的另一個資源陪伴。", "player": [ { "text": "點亮三角錐", "delNPCs": [ "y2019_7" ], "event": [ "y2019_7" ] } ], "image": "2019_7" }
    },
  },
  y2019_6: {
    name: '2019 - 一直都在的教練',
    justFinish: {
      0: { "npcDialog": "三角錐裡有些照片，這我記得！是<mark>設計思考教練</mark>吧！", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "無論在哪裡，加入迴響，就會有<mark>教練</mark>們支持行動團隊！", "player": [ { "next": 2 } ], "image": "2019_6" } ,
      2: { "npcDialog": "在這過程中，每一位教練也都跟著團隊在學習。", "player": [ { "next": 3 } ], "image": "2019_6" } ,
      3: { "npcDialog": "不只是認識青年現在關注的議題，也從引導團隊的過程中，學習與不同類型行動者的<mark>溝通技巧</mark>。", "player": [ { "next": 4 } ], "image": "2019_6" } ,
      4: { "npcDialog": "看看自己如何以協助者的角度，去推動團隊的對話。", "player": [ { "next": 5 } ], "image": "2019_6" } ,
      5: { "npcDialog": "這是一個行動者的大學校，所有人都一起成長進步。", "player": [ { "text": "點亮三角錐", "event": [ "y2019_6" ], "delNPCs": [ "y2019_6" ] } ], "image": "2019_6" }
    },
  },
  y2019_5: {
    name: '2019 - 迴響之友',
    justFinish: {
      0: { "npcDialog": "三角錐裡好像閃過了一些畫面，是迴響的誰呢？", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "這一踢，陪伴我們好多次社群發文的時刻。", "player": [ { "next": 2, "text": "他們是誰？" } ], "image": "2019_5" } ,
      2: { "npcDialog": "喔，這兩位在前兩屆是<mark>志工</mark>、是<mark>教練</mark>呢！", "player": [ { "next": 3, "text": "咦，那這一屆呢？" } ], "image": "2019_5" } ,
      3: { "npcDialog": "當她們終於準備好的時候，也來開啟了自己的行動，成為第三屆的<mark>行動團隊</mark>！", "player": [ { "next": 4 } ], "image": "2019_5" } ,
      4: { "npcDialog": "迴響的資源，等著被各位使用，只要你隨時準備好，就用你喜歡的方式和角色，獲取你要的支持吧。", "player": [ { "text": "點亮三角錐", "event": [ "y2019_5" ], "delNPCs": [ "y2019_5" ] } ], "image": "2019_5" }
    },
  },
  y2019_4: {
    name: '2019 - 公部門合作',
    justFinish: {
      0: { "npcDialog": "上面印著<mark>青年署</mark>的 Logo 啊？是教育部青年署嗎？", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "和青年署的合作，每一年都能從過程中學習。", "player": [ { "next": 2 } ], "image": "2019_4-1" } ,
      2: { "npcDialog": "透過無數次來回的溝通、共創，也才更加明白，要從<mark>制度</mark>層次去推廣<mark>實驗</mark>性質的提案是很不容易的。", "player": [ { "next": 3 } ], "image": "2019_4-1" } ,
      3: { "npcDialog": "需要做到真正站在彼此的立場，去同理彼此的處境。在與「<mark>公部門合作</mark>」這件事，我們也在持續學習。", "player": [ { "next": 4 } ], "image": "2019_4-1" } ,
      4: { "npcDialog": "感謝在第三屆時，能有機會整理這一路走來的突破。", "player": [ { "next": 5 } ], "image": "2019_4-2" } ,
      5: { "npcDialog": "不只是青年在行動呢！我們和公部門的夥伴，也一直在推動更多的可能。", "player": [ { "next": 6 } ], "image": "2019_4-2" } ,
      6: { "npcDialog": "希望能讓這些理念藉由真摯的行動蔓延，轉化合作夥伴們的 DNA！", "player": [ { "text": "點亮三角錐", "event": [ "y2019_4" ], "delNPCs": [ "y2019_4" ] } ], "image": "2019_4-2" }
    },
  },
  y2019_3: {
    name: '2019 - 迴響出版',
    justFinish: {
      0: { "npcDialog": "裡面放了<mark>一本書</mark>？叫做什麼⋯⋯行動⋯⋯求生，看不清楚了⋯⋯", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "終於，也在這一屆，出版了《<mark>行動旅人求生筆記</mark>》。", "player": [ { "next": 2, "text": "筆記？" } ], "image": "2019_3-1" } ,
      2: { "npcDialog": "不是指南、不是工具書，就是我們的筆記。", "player": [ { "next": 3, "text": "裡面都寫些什麼？" } ], "image": "2019_3-1" } ,
      3: { "npcDialog": "所有從一開始的自我辯證、堅持的局面、接受到的幫助，以及業界評審獨特的支持和鼓舞等等⋯⋯", "player": [ { "next": 4 } ], "image": "2019_3-2" } ,
      4: { "npcDialog": "一群人一起踩出了一個起頭，待後人持續開挖自己的道路。", "player": [ { "text": "點亮三角錐", "event": [ "y2019_3" ], "delNPCs": [ "y2019_3" ] } ], "image": "2019_3-3" }
    },
  },
  y2019_2: {
    name: '2019 - 新樣貌的說明會',
    justFinish: {
      0: { "npcDialog": "透過三角錐的孔洞，看見了第三屆特別的<mark>說明會</mark>樣貌。", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "說明會的場次，因為<mark>預算</mark>的關係，沒有辦法像前兩屆一樣到處跑跳。", "player": [ { "next": 2 } ], "image": "2019_2-1" } ,
      2: { "npcDialog": "但是第三屆又想要突破迴響的受眾，希望可以接觸到<mark>更廣泛的年齡層</mark>。", "player": [ { "next": 3 } ], "image": "2019_2-1" } ,
      3: { "npcDialog": "不只是在學學子，在<mark>上班族</mark>的青年，也是我們希望可以擾動的對象。", "player": [ { "next": 4 } ], "image": "2019_2-2" } ,
      4: { "npcDialog": "想處理的議題，也不一定要是社會創新範疇所流行的環保議題。", "player": [ { "next": 5 } ], "image": "2019_2-3" } ,
      5: { "npcDialog": "可能是更接近自己生命、來自<mark>生活底層</mark>的議題，從最深刻的事出發，再困難也會找到一個開頭。", "player": [ { "next": 6 } ], "image": "2019_2-3" } ,
      6: { "npcDialog": "在今年的說明會中，我們開始邀請有初步經驗的<mark>青年團隊</mark>，來一同分享行動的歷程，向外回饋給更多群眾。", "player": [ { "text": "點亮三角錐", "event": [ "y2019_2" ], "delNPCs": [ "y2019_2" ] } ], "image": "2019_2-4" }
    },
  },
  y2019_1: {
    name: '2019 - 前所未有的挑戰',
    justFinish: {
      0: { "npcDialog": "這是⋯⋯三角錐嗎？裡面似乎蘊含了不少訊息，透出了隱隱的<mark>危機感</mark>。", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "「好的，那就開始吧。」", "player": [ { "next": 2 } ], "image": "2019_1" } ,
      2: { "npcDialog": "第三屆面臨了前所未有的挑戰——三屆以來<mark>最低的預算</mark>。", "player": [ { "next": 3 } ], "image": "2019_1" } ,
      3: { "npcDialog": "在所有事被限縮的情況下，我們開始思考鼓勵行動<mark>最核心</mark>的要件是什麼？", "player": [ { "next": 4 } ], "image": "2019_1" } ,
      4: { "npcDialog": "對於迴響而言，我們的最小可行行動，該聚焦在何處呢？", "player": [ { "text": "點亮三角錐", "event": [ "y2019_1" ], "delNPCs": [ "y2019_1" ] } ], "image": "2019_1" }
    },
  },
  y2019_stone: {
    name: '迴響計畫第三屆 - 2019',
    notFinish: {
      // 0: { "npcDialog": "石碑上好像有什麼文字⋯⋯照智者所說的，我似乎要先去「點亮」周圍的<mark>三角錐</mark>？", "player": [ {} ], "changeName": "未知的石碑" }
      ...StoneBasic
    },
    justFinish: {
      0: { "npcDialog": "石碑中源源不絕的行動能量正在滲出，是否伸出手感應？", "player": [ { "next": 1, "text": "伸手感應", "event": ['y2019_stone'], complete: 'true', delNPCs: ['y2019_stone'] } ], "changeName": "未知的石碑" } ,
      1: { "npcDialog": "（石碑上的文字漸漸湧現）", "player": [ { "next": 0 } ]},
    },
    finished: {
      0: { "npcDialog": "這些<mark>三角錐</mark>勾起了三屆的回憶呀⋯⋯三角錐裡面放滿了大家給行動團隊的回饋。", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "2019 年，是遭逢轉折的一年，卻也是讓我們更清楚「<mark>迴響是什麼</mark>」的一年。", "player": [ { "text": "一探究竟", "modal": "y2019_stone" }, { "text": "無情離去" } ] }
    }
  },
  y2018_8: {
    name: '2018 - 星球徽章',
    justFinish: {
      0: { "npcDialog": "盆栽附近圍繞著<mark>星球</mark>，這裡是外太空嗎！", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "「帶走一顆屬於你的行星。」", "player": [ { "next": 2 } ], "image": "2018_8-1" } ,
      2: { "npcDialog": "這是專屬於迴響的浪漫吧。", "player": [ { "next": 3 } ], "image": "2018_8-1" } ,
      3: { "npcDialog": "迴響創造了行動的城市，也創了行動的星系，然後用我們的語彙，想告訴青年：", "player": [ { "next": 4 } ], "image": "2018_8-2" } ,
      4: { "npcDialog": "「嘿，你可能找不到怎麼描述自己的定位和價值，那或許只是因為目前台灣，還沒有符合你的體系而已啦。」", "player": [ { "next": 5 } ], "image": "2018_8-2" } ,
      5: { "npcDialog": "送青年一顆星球，點亮心中的光火，沒想到，其中一位青年的媽媽，拿著迴響的<mark>星球徽章</mark>說：", "player": [ { "next": 6 } ], "image": "2018_8-4" } ,
      6: { "npcDialog": "「喏，給妳的，好好玩吧。」", "player": [ { "next": 7, "text": "點亮盆栽", "event": [ "y2018_8" ], "delNPCs": [ "y2018_8" ] } ], "image": "2018_8-3" }
    },
  },
  y2018_7: {
    name: '2018 - 青年迴響行動節',
    justFinish: {
      0: { "npcDialog": "這個盆栽⋯⋯浮現了一幅幅<mark>滂薄的景象</mark>，是什麼呢？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "第二屆的<mark>行動節</mark>，是<mark>規模最大</mark>的一屆，不知道下一次還有行動節的時候，會長成什麼模樣？", "player": [ { "next": 2 } ], "image": "2018_7-1" } ,
      2: { "npcDialog": "每一組團隊，都有設計師韋呈的心意，為團隊的議題畫下<mark>相符合的插畫</mark>搭配。", "player": [ { "next": 3 } ], "image": "2018_7-3" } ,
      3: { "npcDialog": "這除了是要了解議題以外，更是花了無數的時間在構思。", "player": [ { "next": 4 } ], "image": "2018_7-3" } ,
      4: { "npcDialog": "在華山的展場裡，每一個團隊使用被分配到的區塊，去展現兩個月的成果。", "player": [ { "next": 5 } ], "image": "2018_7-2" } ,
      5: { "npcDialog": "有靜態展、有互動體驗、有商品販售、有分享新知。", "player": [ { "next": 6 } ], "image": "2018_7-4" } ,
      6: { "npcDialog": "熱鬧喧嘩，看見了社會上某一小部分的行動縮影，光是如此，就覺得激勵人心。", "player": [ { "next": 7, "text": "點亮盆栽", "event": [ "y2018_7" ], "delNPCs": [ "y2018_7" ] } ], "image": "2018_7-5" }
    },
  },
  y2018_6: {
    name: '2018 - 行動夥伴',
    justFinish: {
      0: { "npcDialog": "這個我知道！是象徵<mark>行動夥伴</mark>的盆栽！", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "又見面了，行動夥伴們。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "是這一屆的時候，王維綱說了：「創業只有兩步，<mark>這一步</mark>和<mark>下一步</mark>。」", "player": [ { "next": 3 } ], "image": "2018_6-1" } ,
      3: { "npcDialog": "Pin 李品汶每一次都會分享她的信仰：「<mark>Be the change</mark> you want to see in the world。」", "player": [ { "next": 4 } ], "image": "2018_6-2" } ,
      4: { "npcDialog": "而好久不見的楊宇綸，誠懇地分享自己如何突破關卡，走進人群<mark>述說與倡議</mark>；", "player": [ { "next": 5 } ], "image": "2018_6-3" } ,
      5: { "npcDialog": "至於阿德啊⋯⋯似乎在這一年說了：", "player": [ { "next": 6, "text": "" } ] } ,
      6: { "npcDialog": "「反正你做不喜歡的事也很痛苦，幹嘛不就<mark>做喜歡的事</mark>，也是痛苦，至少你喜歡？」", "player": [ { "next": 7, "text": "點亮盆栽", "delNPCs": [ "y2018_6" ], "event": [ "y2018_6" ] } ], "image": "2018_6-4" }
    },
  },
  y2018_5: {
    name: '2018 - 設計思考教練',
    justFinish: {
      0: { "npcDialog": "盆栽上寫著 <mark>Coach</mark>，難道是名牌嗎？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "完成任務包，經過評選後進入到大會師的組別，每一組都有設計思考<mark>教練</mark>的陪伴。", "player": [ { "next": 2 } ], "image": "2018_5-1" } ,
      2: { "npcDialog": "青年會在什麼時候找迴響教練呢？", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "不知道怎麼開會、和夥伴吵架沒有共識的時候；下一次的行動要實驗的目標有點模糊的時候；", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "又或者，想做的事情好像超乎能力、卻又想挑戰自己的時候。", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "在三個月的時間裡，和教練聊聊的過程中，似乎也就在某些青年的行為模式裡，養成了許多新的思考方法。", "player": [ { "next": 6 } ], "image": "2018_5-2" } ,
      6: { "npcDialog": "打破習慣，往不曾想過的地方試試吧！", "player": [ { "next": 7, "text": "點亮盆栽", "event": [ "y2018_5" ], "delNPCs": [ "y2018_5" ] } ], "image": "2018_5-3" }
    },
  },
  y2018_4: {
    name: '2018 - 和室與瑜珈',
    justFinish: {
      0: { "npcDialog": "這盆栽⋯⋯散發出一種<mark>令人放鬆</mark>的氣息，這究竟是什麼東西呢？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "休息，充電，反思，也是行動重要的一環。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "第二屆也更想凸顯這一件事，因此在活動的過程還有空間上，有幸讓夥伴們能具象化<mark>日本和室</mark>的空間。", "player": [ { "next": 3, "text": "好棒！" } ], "image": "2018_4-1" } ,
      3: { "npcDialog": "我們也邀請了<mark>瑜珈老師</mark>到現場，讓青年團隊可以放鬆、舒展一下。", "player": [ { "next": 4 } ], "image": "2018_4-2" } ,
      4: { "npcDialog": "有時候執行團隊，真的是一群不會放過自己的人，", "player": [ { "next": 5 } ], "image": "2018_4-2" } ,
      5: { "npcDialog": "克服挑戰、限制，把真正想呈現、表達的事，盡力做到完整，為的就是那一刻的感受。", "player": [ { "next": 6, "text": "點亮盆栽", "event": [ "y2018_4" ], "delNPCs": [ "y2018_4" ] } ], "image": "2018_4-3" }
    },
  },
  y2018_3: {
    name: '2018 - 散落的絹印材料',
    justFinish: {
      0: { "npcDialog": "盆栽周圍散落<mark>顏料<mark>跟</mark>絹網</mark>，這是什麼東西呢？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "絹印的概念，不，或是說，「一人一件擁有自己<mark>獨一無二</mark>的衣服」的信念，就這樣被流傳下來。", "player": [ { "next": 2 } ], "image": "2018_3" } ,
      2: { "npcDialog": "<mark>動手做</mark>的過程，總是印象深刻，永遠都是新的體驗。", "player": [ { "next": 3 } ], "image": "2018_3" } ,
      3: { "npcDialog": "甚至讓離開學校很久的 30 世代青年，也有眼睛一亮的時刻。", "player": [ { "next": 4 } ], "image": "2018_3" } ,
      4: { "npcDialog": "「<mark>創造</mark>」是會讓人幸福的，而任何一個微小的舉動，都能為自己的生活帶來不一樣的感動。", "player": [ { "next": 5 } ], "image": "2018_3" } ,
      5: { "npcDialog": "（雖然還是要一邊教學、洗絹網、塗顏料、用刮板⋯⋯）", "player": [ { "next": 6, "text": "點亮盆栽", "event": [ "y2018_3" ], "delNPCs": [ "y2018_3" ] } ], "image": "2018_3" }
    },
  },
  y2018_2: {
    name: '2018 - Read Me First',
    justFinish: {
      0: { "npcDialog": "盆栽上貼著「<mark>Read Me First</mark>」，這是什麼意思？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "迴響的活動，總是會有 Read Me First，", "player": [ { "next": 2 } ], "image": "2018_2" } ,
      2: { "npcDialog": "那是因為我們往往無法確定，每一位讀者如何理解即將發生的事？他吸收了多少的前情提要？", "player": [ { "next": 3 } ], "image": "2018_2" } ,
      3: { "npcDialog": "「能夠掌握多一點點資訊，人會更有<mark>信心</mark>、更有<mark>安全感</mark>一些些。」", "player": [ { "next": 4 } ], "image": "2018_2" } ,
      4: { "npcDialog": "迴響從來就不是一個容易理解的地方，因為外面的世界，和迴響有點不同，", "player": [ { "next": 5 } ], "image": "2018_2" } ,
      5: { "npcDialog": "這裡你會被完全地鼓勵行動、被持續拷問反思的結果、無法直接收到下一步的指示。", "player": [ { "next": 6 } ], "image": "2018_2" } ,
      6: { "npcDialog": "「<mark>以你為中心</mark>，真的什麼都可以」，你會<mark>怎麼行動</mark>？", "player": [ { "next": 7, "text": "點亮盆栽", "event": [ "y2018_2" ], "delNPCs": [ "y2018_2" ] } ], "image": "2018_2" }
    },
  },
  y2018_1: {
    name: '2018 - 大會師',
    justFinish: {
      0: { "npcDialog": "這個盆栽，浮現出了第二屆<mark>大會師</mark>的畫面。", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "大會師的現場，就是讓青年團隊不間斷在做「<mark>自我介紹</mark>」的時刻。", "image": "2018_1-1", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "在迴響計畫，青年會逐步地拉近自己和社會的距離，慢慢去建構行動和世界的關係。", "image": "2018_1-1", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "我們是誰？我們要處理什麼題目？我們嘗試什麼解決方法？我碰到什麼難題？", "image": "2018_1-2", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "當每一次表達自己為何出發，要去向哪裡時，眼下的路，就會逐漸清晰。", "image": "2018_1-2", "player": [ { "text": "點亮盆栽", "event": ['y2018_1'], delNPCs: ['y2018_1'] } ] }
    },
  },
  y2018_stone: {
    name: '迴響計畫第二屆 - 2018',
    notFinish: {
      // 0: { "npcDialog": "石碑上好像有什麼文字⋯⋯照智者所說的，我似乎要先去「點亮」周圍的<mark>盆栽</mark>？", "player": [ {} ], "changeName": "未知的石碑" }
      ...StoneBasic
    },
    justFinish: {
      0: { "npcDialog": "石碑中源源不絕的行動能量正在滲出，是否伸出手感應？", "player": [ { "next": 1, "text": "伸手感應", "event": ['y2018_stone'], complete: 'true', delNPCs: ['y2018_stone'] } ], "changeName": "未知的石碑" } ,
      1: { "npcDialog": "（石碑上的文字漸漸湧現）", "player": [ { "next": 0 } ]},
    },
    finished: {
      0: { "npcDialog": "<mark>盆栽</mark>是二屆的重要回憶呀⋯⋯行動者們都端了一盆蘊含生命力的盆栽回家。", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "2018 年，是所有東西都想要更好的一屆，我們希望摸索出目前所見最好的做法。", "player": [ { "next": 2, "text": "一探究竟", "modal": "y2018_stone" }, { "text": "無情離去" } ] }
    }
  },
  y2017_8: {
    name: '2017 - 青年迴響行動節',
    justFinish: {
      0: { "npcDialog": "好多氣球！這是怎麼回事？", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "在迴響的最後結尾，應該要是個盛大的慶典，像是所有人都在為「<mark>行動</mark>」慶祝著。", "player": [ { "next": 2, "text": "慶典？" } ] } ,
      2: { "npcDialog": "就是「青年迴響行動節」！在迴響最後的這一個時刻，<mark>沒有所謂的輸贏</mark>。", "image": "2017_8", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "在行動節中，參觀的路人也是行動者，總有一天，他們都會<mark>為了自己在意的事行動</mark>⋯⋯", "image": "2017_8", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "當行動者踏上自己的旅程時，他所散發的<mark>能量和決心</mark>，就像派對盛典一樣，", "image": "2017_8", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "他們吸引所有人的目光，並且讓大家決定一起投入試試！", "image": "2017_8", "player": [ { "text": "點亮氣球", "event": ['y2017_8'], delNPCs: ['y2017_8'] } ] }
    },
  },
  y2017_7: {
    name: '2017 - 第一屆的行動夥伴',
    justFinish: {
      0: { "npcDialog": "這裡有好多前輩！但他們不被稱為業師，而是稱作⋯⋯<mark>夥伴</mark>？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "嘿，第一群陪伴我們的<mark>行動夥伴</mark>。", "image": "2017_7-1", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "和青年保持一定的距離，在青年前行時，分享自己行動過程中的歷程，用著各自的方式進行交流。", "image": "2017_7-1", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "「為什麼這是你<mark>最想做的事</mark>呢？」", "image": "2017_7-2", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "「你從背後看見什麼<mark>時代訊息</mark>？」", "image": "2017_7-2", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "「用午餐的時間，一起聊聊看？」", "image": "2017_7-2", "player": [ { "text": "點亮氣球", "event": ['y2017_7'], delNPCs: ['y2017_7'] } ] }
    },
  },
  y2017_6: {
    name: '2017 - 成為自己',
    justFinish: {
      0: { "npcDialog": "這裡擺放了一些團隊過往的影像紀錄，要看看嗎？", "player": [ { "next": 1, "text": "當然好！" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "迴響計畫，並不塑造一個理想的樣子讓人追尋，而是希望你們找到<mark>自己的樣子</mark>。", "player": [ { "next": 2, "text": "像是什麼？" } ] } ,
      2: { "npcDialog": "中間那一位是藺子的創辦人怡雅，在她左手邊的兩位青年團隊，他們的題目和品牌是《浪犬博士》。", "image": "2017_6", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "他們並不是寫了多好的商業計劃書所以走到現在，而是因為，這是他們想做的事情。", "image": "2017_6", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "迴響也就只是搭建了這樣的平台，讓行動者<mark>來</mark>、<mark>聚集</mark>、<mark>成為自己</mark>，然後出發走自己的路。", "image": "2017_6", "player": [ { text: '點亮氣球', "event": ['y2017_6'], delNPCs: ['y2017_6']} ] }
    },
  },
  y2017_5: {
    name: '2017 - 2027',
    justFinish: {
      0: { "npcDialog": "攤車上隱約看得見「<mark>2027</mark>」，是什麼意思呢？", "player": [ { "next": 1, "text": "一探究竟" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "Rethink <mark>2027</mark><br>第一屆，就排了這樣的大合照。", "image": "2017_5", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "我們想知道，<mark>十年</mark>後，會是什麼模樣呢？", "image": "2017_5", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "或許，看到這裡，你可以想想<mark>十年前的自己</mark>，能想像現在的模樣嗎？", "image": "2017_5", "player": [ { text: '點亮氣球', "event": ['y2017_5'], delNPCs: ['y2017_5'] }, ] }
    },
  },
  y2017_4: {
    name: '2017 - 行動帶來的改變',
    justFinish: {
      0: { "npcDialog": "這裡展示了好多團隊的<mark>行動紀錄</mark>呢！", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "青年們因為迴響所提供的「任務包」，所以去<mark>採訪</mark>了許多人，驗證自己的想法。", "image": "2017_4-4", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "在過程中，給他們帶來的<mark>觀念上的改變</mark>，比蒐集資料、擬定策略還要重要。", "image": "2017_4-3", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "「原來，<mark>不只是如此</mark>⋯⋯」", "image": "2017_4-5", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "「原來，還有<mark>不同的聲音</mark>⋯⋯」", "image": "2017_4-5", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "「原來，眼前的困境，是因為<mark>另一個因素</mark>所造成的⋯⋯」", "image": "2017_4-5", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "我們知道青年們學會了傾聽他人、放下自己心中原本的假設與設定、接受新的觀點，", "image": "2017_4-2", "player": [ { "next": 7 } ] } ,
      7: { "npcDialog": "「不由得感動得起雞皮疙瘩。」——Jarah 寫於《行動旅人求生筆記》。", "image": "2017_4-1", "player": [ { "next": 8, "text": "點亮氣球", "event": ['y2017_4'], delNPCs: ['y2017_4'] } ] }
    },
  },
  y2017_3: {
    name: '2017 - 穿什麼衣服好呢？',
    justFinish: {
      0: { "npcDialog": "咦？攤車上有一些<mark>顏料</mark>跟 <mark>T-shirt</mark>，用來做什麼的呢？", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" },
      1: { "npcDialog": "讓所有人穿上同一款衣服，可以讓現場更有凝聚感喔！", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "青年迴響的 T-shirt，應該要設計成什麼模樣？是否要有迴響 LOGO？還是不斷出現的行動小人？⋯⋯", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "這時突然有人說：「為什麼我們不讓穿的人<mark>自己決定</mark>想要什麼圖樣？」", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "那就<mark>絹印</mark>吧！", "image": "2017_3-1", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "買全素的衣服，讓青年在大會師 DIY，完成屬於自己的衣服！", "image": "2017_3-2", "player": [ { text: "點亮氣球", "event": ['y2017_3'], delNPCs: ['y2017_3'] } ] }
    },
  },
  y2017_2: {
    name: '2017 - 一起 Swing Dance！',
    justFinish: {
      0: { "npcDialog": "攤車周圍響起了<mark>舞曲</mark>，這是怎麼回事呢？⋯⋯", "player": [ { "next": "1", "text": "仔細聆聽" }, { "text": "無情離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "你可知，每一位在照片裡出現的青年，他們的行動，都默默影響著身邊遇到的某一群人。", "image": "2017_2", "player": [ { "next": 2, "text": "" } ], "changeName": "" } ,
      2: { "npcDialog": "就像 Swing Taiwan 的初衷，單純地想要分享快樂，行走至今，歡樂遍布多少？", "image": "2017_2", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "這就是<mark>行動的文化</mark>，隨時有動有靜，過程中創造新的可能⋯⋯", "image": "2017_2-2", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "好多人都在那個新的可能中，幸福地活出理想模樣。", "image": "2017_2-2", "player": [ { "next": 5, "text": "點亮氣球", "event": ['y2017_2'], delNPCs: ['y2017_2'] } ] }
      // modal: 'y2017_2'
    },
  },
  y2017_1: {
    name: '2017 - 大合照少了誰？',
    justFinish: {
      0: { "npcDialog": "是攤車跟氣球耶⋯⋯攤車上擺了迴響團隊的<mark>大合照</mark>，要觀看嗎？", "player": [ { "next": 1, "text": "仔細查看" }, { "text": "無情離去" } ], "changeName": "player" },
      1: { "npcDialog": "<mark>大合照</mark>，是我們每一屆珍貴的回憶碎片。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "可是不只這些、不只這些，每一次大合照裡，都<mark>少了一些人</mark>⋯⋯", "image": "2017_1", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "攝影師、其他活動籌辦夥伴、支持行動團隊的利害關係人、無緣進到大會師的青年們。", "image": "2017_1", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "還有青年署工作夥伴們、先行離去的行動夥伴、行程滿滿的評審們⋯⋯", "image": "2017_1", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "⋯⋯這樣連連看，總有一天會<mark>連到你</mark>吧。", "image": "2017_1", "player": [ { text: "點亮氣球", "event": ['y2017_1'], delNPCs: ['y2017_1'] } ] }
    },
  },
  y2017_stone: {
    name: '迴響計畫第一屆 - 2017',
    notFinish: {
      // 0: { "npcDialog": "石碑上好像有什麼文字⋯⋯照智者所說的，我似乎要先去「點亮」周圍<mark>攤車上的氣球</mark>？", "player": [ {} ], "changeName": "未知的石碑" }
      ...StoneBasic
    },
    justFinish: {
      0: { "npcDialog": "石碑中源源不絕的行動能量正在滲出，是否伸出手感應？", "player": [ { "next": 1, "text": "伸手感應", "event": ['y2017_stone'], complete: 'true', delNPCs: ['y2017_stone'] } ], "changeName": "未知的石碑" } ,
      1: { "npcDialog": "（石碑上的文字漸漸湧現）", "player": [ { "next": 0 } ]},
    },
    finished: {
      0: { "npcDialog": "想到<mark>攤車</mark>與<mark>氣球</mark>，就彷彿回到了 2017 年第一屆的行動節⋯⋯", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "2017 年，是迴響計畫的第一年，我們從這一年開始展望 10 年後的樣子，也就是 <mark>Rethink 2027</mark>。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "東敲西打，我們刻劃出最初的樣子——<mark>一個以行動為核心的孵化計畫</mark>。", "player": [ { "modal": "y2017_stone", "text": "一探究竟" }, { "text": "無情離去" } ] }
    },
  },
  CentryGate: {
    name: '迴響紀元',
    justFinish: {
      0: {
        changeName: 'player',
        npcDialog: "門上寫著<mark>迴響紀元</mark>⋯⋯",
        player: [
          {
            text: '查看告示牌',
            modal: 's1_intro_route'
          },
          {
            text: '迴響紀元是？',
            modal: 's1_intro_stone'
          }
        ]
      }
    },
  },
  Sage: {
    name: '神出鬼沒的智者',
    justFinish: {
      0: {
        npcDialog: "看你孤身一人，出發前，聽老朽念兩句吧⋯⋯",
        player: [
          {
            next: 1
          }
        ]
      },
      1: {
        npcDialog: "行動之路上，總要有<mark>夥伴</mark>才能心安啊⋯⋯",
        player: [
          {
            text: '去哪裡找夥伴？',
            next: 2
          }
        ]
      },
      2: {
        npcDialog: "登頂沿途，散落著<mark>姿態不一的石像</mark>，據說很早以前就在那裡了，或許他們會有答案⋯⋯",
        player: [
          {
            next: 3
          }
        ]
      },
      3: {
        npcDialog: "念念不忘，必有迴響，有燈就有人。",
        player: [
          {
            next: 4
          }
        ]
      },
      4: {
        npcDialog: "這盒<mark>火柴</mark>就送給你當禮物吧！",
        player: [
          {
            rewards: ['matches'],
            complete: true
          }
        ]
      }
    },
    finished: {
      0: {
        npcDialog: "沿途中，多注意那些姿態慵懶的<mark>巨人石像</mark>⋯⋯",
      }
    }
  },
  // TODO: 抽by趴 - 還沒有製作
  Broomman: {
    name: '掃地僧',
    sound: 'npc_m_alder',
    notFinish: {
      0: { "npcDialog": "（颼颼冷風吹過山頂，有個僧人默默地拿著掃帚掃著地。）", "player": [ { "next": "1", "text": "請問你是？" }, { "text": "不予理會" } ], "changeName": "player" } ,
      1: { "npcDialog": "我是個平凡的僧人，日以繼夜在山裡掃地，汲取日月精華，修習我的<mark>功課</mark>⋯⋯", "player": [ { "next": 2, "text": "功課？" } ] } ,
      2: { "npcDialog": "除了掃地以外，就是研究這些<mark>石碑</mark>⋯⋯", "player": [ { "next": 3, "text": "石碑？" } ] } ,
      3: { "npcDialog": "登頂的過程，你是否看見了許多<mark>大石頭</mark>呢？", "player": [ { "next": 100 } ] } ,
      100: { "npcDialog": "他們傳承著從 2017 以來累積的迴響動能，只要能解讀這些石碑的話，我就能⋯⋯", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "寫出我的<mark>武功秘笈</mark>了⋯⋯", "player": [ { "next": 5, "text": "石碑有幾座？" } ] } ,
      5: { "npcDialog": "據說世上共有<mark>六座</mark>未被雕刻的石碑，五座就位於這座山的山路上，最後一座，則在迴圈森林深處⋯⋯", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "傳說中，東方的迴圈森林裡，住著一群具有靈性的小動物，他們守護著能<mark>刻出石碑原形的器具</mark>", "player": [ { "next": 7 } ] } ,
      7: { "npcDialog": "若你此生有幸能解讀所有石碑，再回來找我吧⋯⋯", "player": [ { "ongoing": "踏上雕刻石碑之旅，去迴圈森林尋找小動物吧！" } ] }
    },
    onGoing: {
      0: { "npcDialog": "據說世上共有<mark>六座</mark>未被雕刻的石碑，若你此生有幸能解讀所有石碑，再回來找我吧⋯⋯", "player": [ { "next": 1, "text": "石碑在哪呢？" }, { "text": "離開" } ] } ,
      1: { "npcDialog": "有五座石碑位於行動大山的登頂之路，一座石碑在迴圈森林的最深處⋯⋯", "player": [ { "next": 2, "text": "你身旁的這座特別大？" } ] } ,
      2: { "npcDialog": "這座石碑是特別的，若你有幸解讀出其他石碑，我再告訴你一個秘密吧⋯⋯" }
    },
    justFinish: {
      0: { "npcDialog": "你竟然解讀出所有的石碑了嗎？原來如此⋯⋯", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "迴響四年來迭代出的經驗與工具，讓我的修行能拼起最後一片拼圖⋯⋯", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "對練武之人而言，最重要並非花俏的武術，而是根基，也就是常人所理解的內力。", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "迴響的內力，奠基於<mark>設計思考</mark>這套心法，並且揉合了精實創業，以及在社會議題上的所見所聞⋯⋯", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "才終於成為了現在的迴響。", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "而如今，我終於寫出這本武功秘笈⋯⋯如果沒有你的幫助的話，我是絕對辦不到的。", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "這就是我們締結的緣分吧⋯⋯我要把這本秘笈送給你，希望你能好好地運用它。", "player": [ { complete: '解讀了所有石碑，讓掃地僧得以把畢生修行寫成武功秘笈！', "next": 7, "text": "謝謝你，前輩" } ] } ,
      7: { "npcDialog": "他叫做 <mark>Rookie's Guide</mark>，即便是初學者，也能夠輕易的理解何謂設計思考。", "player": [ { "next": 0 } ] } ,
      // 8: { "npcDialog": "player，是你點燃了繼續前進的燈火，你該往更大、更遠的世界邁進。你看見我身旁的<mark>石碑</mark>了嗎？", "player": [ { "next": 1 } ] } ,
      // 9: { "npcDialog": "他是迴響的傳說——<mark>行動大神</mark>曾經寄宿的場所，據說召喚神的聖物，就藏在南邊的<mark>迴響大澤</mark>裡。", "player": [ { "next": 10 } ] } ,
      // 10: { "npcDialog": "好希望此生，還能一賭大神的風采啊⋯⋯", "player": [ {complete: true} ] }
    },
    finished: {
      0: { "npcDialog": "player，是你點燃了繼續前進的燈火，你該往更大、更遠的世界邁進。你看見我身旁的<mark>石碑</mark>了嗎？", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "他是迴響的傳說「<mark>行動大神</mark>」曾經寄宿的場所，據說探索完整個世界，就能使祂現世。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "如果你探索了所有任務，再回來找這個石碑吧。好希望此生，還能一賭大神的風采啊⋯⋯"}
    }
    // justFinish: {
    //   0: { "npcDialog": "颱風過境，誓言下落不明，我找不到那些行動過的痕跡⋯⋯", "player": [ { "next": 1, "text": "聽不懂" }, { "next": 1,  "text": "真熟悉啊⋯⋯" } ] } ,
    //   1: { "npcDialog": "據說曾經登頂的行動者們，在迴圈森林裡留下了<mark>行動的寶貴經驗</mark>，但森林裡明明就只有，<mark>一群動物</mark>⋯⋯？", "player": [ { "next": 2, "text": "動物？" } ] } ,
    //   2: { "npcDialog": "那裡是不是有甚麼秘密⋯⋯?", "player": [ { "next": 3, "text": "我來去看看！" } ] }
    // },
  },
  // TODO: 抽by趴 - 拿到筆記前後都有一個版本了，但不確定規則沒有繼續完成。不確定考古學家任務完成，需不需要再探索完六大巨人後，還要回來找考古學家，才算完成？需要回來的話，文案還會需要新增和修改，提示他要回來找考古學家
  Archaeologist: {
    name: '考古學家',
    notFinish: {
      0: { "npcDialog": "我的考古挖掘工作，已經持續了很長一段時間了⋯⋯", "player": [ { next: 1 } ] } ,
      1: { "npcDialog": "對於歷史文物，似乎開始有了新的想法，你有興趣聽嗎？", "player": [ { "next": 2, "text": "說說看吧！我聽" }, { "text": "轉身離開" } ] } ,
      2: { "npcDialog": "不管挖掘出甚麼文物，其實都是在幫助我們了解那個時代的人⋯⋯", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "在意那時候的人是怎麼想的。他們如何生活，又如何解決生活中的問題，創造生而為人的豐富性。", "player": [ { "next": 4 } ] },
      4: { "npcDialog": "相反的，他們也在意後世的我們。這個共同體的想像，成了超越當下和個人的力量⋯⋯", "player": [ { "next": 5 } ] },
      5: { "npcDialog": "創造了不可思議的現象。比如⋯⋯<mark>巨大的石像</mark>！？", "image": 'info_Golem', "player": [ { "next": 6, "text": "巨大的石像？" }, { "next": 50, "text": "恩⋯⋯沒興趣" } ]  },
      6: { "npcDialog": "你也注意到嗎？⋯⋯迴響大本營遍布著的古老巨石像！", "image": 'info_Golem', "player": [ { "next": 7, "text": "我有印象！灰灰的，很巨大！" }, { "next": 7, "text": "有嗎？⋯⋯我再多注意一下⋯⋯" }  ] },
      7: { "npcDialog": "雖然不知道他們的來歷。不過在我漫長的考古生涯中⋯⋯", "image": 'info_Golem', "player": [ { "next": 8, } ] },
      8: { "npcDialog": "刻意塑造的巨大石像多半暗示著<mark>對那個時代而言重要</mark>的訊息⋯", "image": 'info_Golem', "player": [ { "next": 9, "text": "什麼訊息？" }, { "next": 51, "text": "沒有興趣" }] },
      9: { "npcDialog": "陰暗的石像內，隱約可見來自久遠時代的<mark>壁畫</mark>，應該是在訴說某些故事⋯⋯", "player": [ { "next": 12 } ] },
      // 10: { "npcDialog": "瞭解那些故事，或許可以幫助我們瞭解迴響大本營為何存在。但⋯⋯", "player": [ { "next": 11, "text": "但？" } ] },
      // 11: { "npcDialog": "由於年代久遠，這些壁畫還在解譯中。", "player": [ { "next": 12, "text": "那怎麼辦？" } ] },
      12: { "npcDialog": "我手邊有一本「行動旅人求生筆記」，是解譯的過程中，拼湊出來的故事。", "player": [ { "next": 13 } ] },
      13: { "npcDialog": "搭配這份筆記，應該可以更瞭解壁畫中的故事了⋯考古的工作是促成對話，這本<mark>筆記</mark>，就送給你吧！", "player": [ { "text": "太好了吧！謝謝你", rewards: ["book-first"], ongoing: "獲得了考古學家的筆記，快去巨人石像內部探險！" } ] },
      50: { "npcDialog": "世代之間、跨越地域的限制，因為生而為人，而跟所有的過去有關⋯多麼奇妙的一件事啊！", "player": [] },
      51: { "npcDialog": "念念不忘，必有迴響，有燈就有故事。", "player": [] },
    },
    onGoing: {
      0: { "npcDialog": "還沒出發去石像探險啊？", "player": [ { next: 1 } ] },
      1: { "npcDialog": "那⋯⋯你聽過航海家探測器的故事嗎？我想⋯石像的訊息和那應該是相通的！", "player": [ { next: 2, text: "什麼訊息？" } ] },
      2: { "npcDialog": "<mark>航海家金唱片</mark>（Voyager Golden Records）是一張於1977年隨兩艘航海家探測器發射到太空的唱片。", "player": [ { next: 3 } ] },
      3: { "npcDialog": "唱片內收錄了用以表述地球上各種文化及生命的聲音及圖像，以期宇宙中其他外星高智慧生物發現。", "player": [ { next: 4 } ] },
      4: { "npcDialog": "裡面有一段卡特總統的訊息", "player": [ { next: 5 } ] },
      5: { "npcDialog": "“ 要是其中一個文明捕獲航海家探測器並明白唱片裡收錄的內容，那麼這就是我們的訊息⋯⋯", "player": [ { next: 6 } ] },
      6: { "npcDialog": "我們嘗試在我們的時光裡活著，或許有天會在你們的時光裡活著。", "player": [ { next: 7 } ] },
      7: { "npcDialog": "我們期望總有一天，解決了我們正面對的難題後，可以聯合一起成為一個銀河系文明。", "player": [ { next: 8 } ] },
      8: { "npcDialog": "這張唱片代表我們的希望、我們的決心與我們的善意，在這個浩瀚的宇宙。	“", "player": [ { next: 9 } ] },
      9: { "npcDialog": "——吉米·卡特，1977 年 6 月 16 日", "player": [ { text: "恩⋯⋯酷！" } ] },
    },
    justFinish: {
      0: { "npcDialog": "已經探險完所有石像了嗎⋯⋯能夠考究出這些壁畫的秘密，真是太好了，原來這就是迴響所傳遞的訊息。", "player": [ { "next": 1, "complete": "探索了全部石像，幫助考古學家解密了迴響大本營的所有壁畫！" } ] } ,
      1: { "npcDialog": "player，我要謝謝你的行動力，幫我的考古進程更往前了一步。", "player": [ { "next": 0 } ] } ,
    },
    finished: {
      0: { "npcDialog": "話說，你聽過<mark>迴響大神</mark>嗎？", "player": [ { "next": 1, "text": "那是？" } ] } ,
      1: { "npcDialog": "我也不是很清楚，但我一直對這個傳說很著迷，據說只要完成了這個世界所有的探索，就能在山頂召喚出祂。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "<mark>連按兩次 B</mark> 你會看見任務界面，完成前面的十個任務後，到山頂找<mark>願景石碑</mark>看看吧。", "player": [ { "text": "我知道了" } ] }
    }
  },
  ToolTable: {
    name: '工具櫃',
    justFinish: {
      0: {
        changeName: 'player',
        npcDialog: "一個看起來頗有歷史的工具櫃⋯⋯看起來是有很多使用痕跡的工具，好像會對探險有幫助。",
        player: [
          {
            text: '拿起來看看',
            modal: 's0_tools'
          },
          { text: '等等再說' }
        ]
      }
    },
  },
  // TODO: 抽by趴 - 掛軸箱會掉出釣魚線，有一個基本款了，歡迎抽修改～
  HangingScrollBox: {
    name: '掛軸箱',
    justFinish: {
      0: {
        changeName: 'player',
        npcDialog: "裡面放了好多<mark>掛軸</mark>，看起來是個掛軸箱。",
        player: [{ text: '拿起掛軸來看看', next: 1 }, { text: '等等再說' }]
      },
      1: {
        changeName: 'player',
        npcDialog: "看起來是之前活動使用的主視覺掛軸。看起來應該是用不到了，把上面的釣魚線解下來嗎？",
        player: [{ text: '解下來', rewards: ['fishing-line'], complete: '取得釣魚線！難道這遊戲可以釣魚嗎？' }, {text: '不予理會'}]
      }
    },
    finished: {
      0: {npcDialog: "這裡已經沒有東西了。"}
    }
  },
  PostIt: {
    name: 'Post-It 牆',
    justFinish: {
      0: {
        changeName: 'player',
        npcDialog: "Post-It 牆貼了許多之前的行動者所在乎的社會議題⋯⋯是否閱讀「大象的故事」？",
        player: [
          {
            text: '大象的故事',
            next: 99
          },
          // {
          //   text: '迴響計畫簡介',
          //   modal: 's0_intro',
          // },
          {
            text: '不想看'
          }
        ]
      },
      99: {
        npcDialog: "1935 年的百老匯演出，喜劇演員 Jimmy Durante 牽了一隻活生生的大象走過舞台。",
        player: [
          {
            next: 1
          }
        ]
      },
      1: {
        npcDialog: "警察衝出來攔住他問道：「你牽著一隻大象想幹什麼？」",
        player: [
          {
            next: 2
          }
        ]
      },
      2: {
        npcDialog: "「什麼？什麼大象？」Jimmy 回答。",
        player: [
          {
            next: 3
          }
        ]
      },
      3: {
        npcDialog: "對大象<mark>視而不見</mark>的戲碼帶來了笑聲，也讓人印象深刻。",
        player: [
          {
            text: '繼續看',
            next: 4
          }
        ]
      },
      4: {
        npcDialog: "<mark>「房間裡的大象」</mark>，用以比喻眾人故意忽視一個擺在大家眼前的大麻煩。",
        player: [
          {
            next: 5
          }
        ]
      },
      5: {
        npcDialog: "「解決任何問題的第一步，就是<mark>承認那裡有一個問題存在</mark>。」——《新聞急先鋒》第一季",
        player: [
          {
            next: 6
          }
        ]
      },
      6: {
        npcDialog: "你的<mark>生活</mark>之中，也藏著大象嗎？",
        player: [
          {
            next: 7
          }
        ]
      },
      7: {
        changeName: 'player',
        npcDialog: "這裡有好多人寫下他們看見的大象：媒體、文化、貧窮、教育⋯⋯我也要<mark>寫上我所看見的問題</mark>嗎？",
        player: [
          {
            text: '拿起紙筆',
            input: {
              question: '我看見的大象是：',
              key: 'hmw'
            },
            next: 8
          },
          {
            text: '還沒準備好',
            next: 9
          }
        ]
      },
      8: {
        changeName: 'player',
        npcDialog: "看見問題就是行動的第一步！話說<mark>隔壁的老人</mark>好像一直在看我⋯⋯",
        player: [
          {complete: true}
        ]
      },
      9: {
        changeName: 'player',
        npcDialog: "我還需要一點時間沈澱，先去找其他人聊聊好了！等我想到了，<mark>再回來這裡寫</mark>。",
        player: [
          {complete: true}
        ]
      },
    },
    finished: {
      0: {
        changeName: 'player',
        npcDialog: "Post-It 牆貼了許多之前的行動者所在乎的社會議題⋯⋯當中也有我寫下的大象！要修改嗎？",
        player: [
          // {
          //   text: '迴響計畫簡介',
          //   modal: 'credits',
          // },
          {
            text: '要重寫大象',
            input: {
              question: '我看見的大象是：',
              key: 'hmw'
            },
            next: 1
          },
          {
            text: '不想看'
          }
        ]
      },
      1: {
        changeName: 'player',
        npcDialog: "看見問題就是行動的第一步！我要繼續去探索世界了。",
      },
    }
  },
  treeAx: {
    name: '樹根上的斧頭',
    notFinish: {
      0: { "npcDialog": "這裡有一把斧頭⋯⋯", "player": [ { "next": 1, "text": "用力拔出" }, { "text": "轉身離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "（耗盡全身力氣，斧頭仍紋絲不動。）", "player": [ { "ongoing": "true" } ] }
    },
    onGoing: {
      0: { "npcDialog": "這裡有一把斧頭⋯⋯", "player": [ { "next": 1, "text": "用力拔出" }, { "text": "轉身離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "（耗盡全身力氣，斧頭仍紋絲不動。）", "player": [ { "event": ["treeAx"] } ] }
    },
    justFinish: {
    0: { "npcDialog": "這裡有一把斧頭⋯⋯", "player": [ { "next": 1, "text": "用力拔出" }, { "text": "轉身離去" } ], "changeName": "player" } ,
    1: { "npcDialog": "（耗盡全身力氣，斧頭仍紋絲不動。）", "player": [ { "complete": "true" } ] }
    },
    finished: {
      0: { "npcDialog": "這裡有一把斧頭⋯⋯", "player": [ { "next": 1, "text": "用力拔出" }, { "text": "轉身離去" } ], "changeName": "player" } ,
      1: { "npcDialog": "（耗盡全身力氣，斧頭終於鬆動了一些。）", "player": [ { "text": "放棄" }, { "text": "繼續用力！", "next": "2" } ] } ,
      2: { "npcDialog": "（斧頭被你的意志撼動，終於從樹根上脫落了！）", "player": [ { "text": "收下斧頭", "rewards": [ "ax" ], "delNPCs": [ "treeAx" ], delMission: [ "treeAx" ] } ] }
    }
  },
  Television: {
    name: '桌上的老舊幻燈片機器',
    justFinish: {
      0: {
        changeName: 'player',
        npcDialog: "有一部老舊的幻燈片機欸！說明牌上寫的是青年迴響計畫行動歷程⋯⋯",
        player: [
          {
            next: 1
          }
        ]
      },
      1: {
        changeName: 'player',
        npcDialog: "好像是一個關於採取行動，一階一階登頂的紀錄。",
        player: [
          {
            text: '打開看看',
            modal: 's0_epitome'
          },
          { text: '等等再說' }
        ]
      }
    },
  },
  // Gate: {
  //   justFinish: {
  //     0: {
  //       changeName: 'player',
  //       npcDialog: '對面好像有東西，但颱風把橋沖走了⋯⋯只好先往山上走了。',
  //     }
  //   },
  // },
  Intro: {
    justFinish: {
      0: {
        npcDialog: '你好這裡是迴響計畫的說明會！要來報名聽聽看嗎？',
        player: [
          {
            text: '我想聽！',
            modal: 's1-step-1'
          },
          { text: '默默離開' }
        ]
      }
    },
  },
  TurnOnLieHouse7: { // 森林左方
    name: '石像內的壁畫',
    justFinish: {
      0: {
        npcDialog: '這裡有個神秘的燈座呢⋯⋯⋯⋯',
        player: [
          {
            text: '用火柴點火',
            next: 1,
            event: ['LieHouse7'],
            complete: true
          },
        ]
      },
      1: { npcDialog: '燈火照亮了石像內部，顯現出了牆上的壁畫。', player: [{next: 0}]}
    },
    finished: {
      0: { npcDialog: '壁畫上刻著一些字⋯⋯⋯', player: [ { text: '看下去', next: 1 },{ text: '沒有興趣'} ]},
      1: {
        npcDialog: '每年大型活動前，迴響會招募一群<mark>志工</mark>⋯⋯',
        player: [ { next: 2 } ] ,
        changeName: 'player',
      },
      2: {
        npcDialog: '他們是現場的小裁縫，穿針引線，縫補執行團隊力所不能及之處。',
        player: [ { next: 3 } ] ,
        changeName: 'player',
      },
      3: {
        npcDialog: '迴響的精神和他們的行動力混紡，織起一張接住每一個期待的網⋯⋯',
        player: [ { next: 4 } ] ,
        changeName: 'player',
      },
      4: {
        npcDialog: '執行團隊的願望、參與青年的想像，他們跑來跑去，慎重的像對待自己的夢想一樣。',
        player: [ { next: 5 } ] ,
        changeName: 'player',
      },
      5: {
        npcDialog: '我們用這座石像紀念這群小裁縫⋯⋯',
        player: [ { next: 6 } ] ,
        changeName: 'player',
      },
      6: {
        npcDialog: '這是最古老的區塊鏈，可以把在意的人和他們的故事，永遠留在時間裡，不能被竄改⋯⋯',
        player: [ { next: 7 } ] ,
        changeName: 'player',
      },
      7: {
        npcDialog: '將被所有點亮的人存取，去到只有零和一到不了的地方⋯⋯',
        player: [ { text: '繼續看下去', modal: 'giant_room_1' }, { text: '轉身離去' } ] ,
        changeName: 'player',
      }
    }
  },
  TurnOnLieHouse6: { // 森林上方
    name: '石像內的壁畫',
    justFinish: {
      0: {
        npcDialog: '這裡有個神秘的燈座呢⋯⋯⋯⋯',
        player: [
          {
            text: '用火柴點火',
            next: 1,
            event: ['LieHouse6'],
            complete: true
          },
        ]
      },
      1: { npcDialog: '燈火照亮了石像內部，顯現出了牆上的壁畫。', player: [{next: 0}]}
    },
    finished: {
      0: { npcDialog: '壁畫上刻著一些字⋯⋯⋯', player: [ { text: '看下去', next: 1 },{ text: '沒有興趣'} ]},
      1: {
        npcDialog: '每年迴響開始前，總召會依據當年的需求招募<mark>執行團隊</mark>⋯⋯',
        player: [ { next: 2 } ] ,
        changeName: 'player',
      },
      2: {
        npcDialog: '有些人來自過往的迴響團隊，有些是曾經的志工，他們像夏天的竹筍，在迴響開始的季節冒出來。',
        player: [ { next: 3 } ] ,
        changeName: 'player',
      },
      3: {
        npcDialog: '割下來吧，總召心裡大概會這樣想，但他們多半還是苦的。',
        player: [ { next: 4 } ] ,
        changeName: 'player',
      },
      4: {
        npcDialog: '要把迴響的水煮滾，用前一兩個月的時間燙⋯⋯',
        player: [ { next: 5 } ] ,
        changeName: 'player',
      },
      5: {
        npcDialog: '等到水冷卻以後，鮮甜的滋味慢慢凝聚，冰在冰箱或拿來熬湯，四五個月的時間裡，變出好多道佳餚。',
        player: [ { next: 6 } ] ,
        changeName: 'player',
      },
      6: {
        npcDialog: '這座石像紀念我們在滾水裡品嚐彼此的苦⋯⋯',
        player: [ { next: 7 } ] ,
        changeName: 'player',
      },
      7: {
        npcDialog: '和端上桌前，在冰箱裡相互取暖、一起在熱湯裡擠出滋味的時光⋯⋯',
        player: [ { text: '繼續看下去', modal: 'giant_room_6' }, { text: '轉身離去' } ] ,
        changeName: 'player',
      }
    }
  },
  TurnOnLieHouse5: { // 森林入口
    name: '石像內的壁畫',
    justFinish: {
      0: {
        npcDialog: '這裡有個神秘的燈座呢⋯⋯⋯⋯',
        player: [
          {
            text: '用火柴點火',
            next: 1,
            event: ['LieHouse5'],
            complete: true
          },
        ]
      },
      1: { npcDialog: '燈火照亮了石像內部，顯現出了牆上的壁畫。', player: [{next: 0}]}
    },
    finished: {
      0: { npcDialog: '壁畫上刻著一些字⋯⋯⋯', player: [ { text: '看下去', next: 1 },{ text: '沒有興趣'} ]},
      1: {
        npcDialog: '<mark>青年署的夥伴</mark>和迴響，',
        player: [ { next: 2 } ] ,
        changeName: 'player',
      },
      2: {
        npcDialog: '像彼此的小丑魚和海葵，幫助對方探險，保護彼此在意的地方⋯⋯',
        player: [ { next: 3 } ] ,
        changeName: 'player',
      },
      3: {
        npcDialog: '新的概念，要長成一個完整計畫的模樣⋯⋯',
        player: [ { next: 4 } ] ,
        changeName: 'player',
      },
      4: {
        npcDialog: '像迷路的小丑魚，在大海裡流浪，在威脅大到中斷冒險之前，碰巧遇上海葵那樣難得。',
        player: [ { next: 5 } ] ,
        changeName: 'player',
      },
      5: {
        npcDialog: '該有多少隻小丑魚，來回多少趟？',
        player: [ { next: 7 } ] ,
        changeName: 'player',
      },
      7: {
        npcDialog: '這座石像紀念迴響和公部門一起實驗、共同成長的過往⋯⋯',
        player: [ { text: '繼續看下去', modal: 'giant_room_5' }, { text: '轉身離去' } ] ,
        changeName: 'player',
      }
    }
  },
  TurnOnLieHouse4: { // 山頂
    name: '石像內的壁畫',
    justFinish: {
      0: {
        npcDialog: '這裡有個神秘的燈座呢⋯⋯⋯⋯',
        player: [
          {
            text: '用火柴點火',
            next: 1,
            event: ['LieHouse4'],
            complete: true
          },
        ]
      },
      1: { npcDialog: '燈火照亮了石像內部，顯現出了牆上的壁畫。', player: [{next: 0}]}
    },
    finished: {
      0: { npcDialog: '壁畫上刻著一些字⋯⋯⋯', player: [ { text: '看下去', next: 1 },{ text: '沒有興趣'} ]},
      1: {
        npcDialog: '<mark>議題組織</mark>是青年團隊們的挖土機，在最脆弱的地基上，硬是挖一個洞⋯⋯',
        player: [ { next: 2 } ] ,
        changeName: 'player',
      },
      2: {
        npcDialog: '願意往下看就能清楚有哪些需要補的內容，在結構穩固的行動中，教會他們挖坑，',
        player: [ { next: 3 } ] ,
        changeName: 'player',
      },
      3: {
        npcDialog: '才能讓更多人跳進來響應。',
        player: [ { next: 4 } ] ,
        changeName: 'player',
      },
      4: {
        npcDialog: '這座石像紀念我們第一次合作⋯⋯',
        player: [ { next: 7 } ] ,
        changeName: 'player',
      },
      7: {
        npcDialog: '和那些用滴雞精的作法，一個字一個字滴出來的號召方法們⋯⋯',
        player: [ { text: '繼續看下去', modal: 'giant_room_4' }, { text: '轉身離去' } ] ,
        changeName: 'player',
      }
    }
  },
  TurnOnLieHouse3: { // 2018 山洞前
    name: '石像內的壁畫',
    justFinish: {
      0: {
        npcDialog: '這裡有個神秘的燈座呢⋯⋯⋯⋯',
        player: [
          {
            text: '用火柴點火',
            next: 1,
            event: ['LieHouse3'],
            complete: true
          },
        ]
      },
      1: { npcDialog: '燈火照亮了石像內部，顯現出了牆上的壁畫。', player: [{next: 0}]}
    },
    finished: {
      0: { npcDialog: '壁畫上刻著一些字⋯⋯⋯', player: [ { text: '看下去', next: 1 },{ text: '沒有興趣'} ]},
      1: {
        npcDialog: '<mark>設計思考教練</mark>是中世紀的騎士，一身武藝、渾身是膽⋯⋯',
        player: [ { next: 2 } ] ,
        changeName: 'player',
      },
      2: {
        npcDialog: '在迴響的國裡自成一格，先是四處流浪，和各種行動提案交戰。',
        player: [ { next: 3 } ] ,
        changeName: 'player',
      },
      3: {
        npcDialog: '直到遇到最堅定的那些行動者，就會化身成一面盾牌，保護每一個剛萌芽的想法⋯⋯',
        player: [ { next: 4 } ] ,
        changeName: 'player',
      },
      4: {
        npcDialog: '變成一支長劍，刺穿躊躇的網⋯⋯',
        player: [ { next: 5 } ] ,
        changeName: 'player',
      },
      5: {
        npcDialog: '必要的時候，化作一匹快馬，載著行動者們，直接去打大魔王的關卡。',
        player: [ { next: 6 } ] ,
        changeName: 'player',
      },
      6: {
        npcDialog: '這座石像紀念迴響的騎士們⋯⋯',
        player: [ { next: 7 } ] ,
        changeName: 'player',
      },
      7: {
        npcDialog: '哪怕是站著倒著，劍都歪了，眼光還是向前的。',
        player: [ { text: '繼續看下去', modal: 'giant_room_3' }, { text: '轉身離去' } ] ,
        changeName: 'player',
      }
    }
  },
  TurnOnLieHouse2: { // 山屋左側
    name: '石像內的壁畫',
    justFinish: {
      0: {
        npcDialog: '這裡有個神秘的燈座呢⋯⋯⋯⋯',
        player: [
          {
            text: '用火柴點火',
            next: 1,
            event: ['LieHouse2'],
            complete: true
          },
        ]
      },
      1: { npcDialog: '燈火照亮了石像內部，顯現出了牆上的壁畫。', player: [{next: 0}]}
    },
    finished: {
      0: { npcDialog: '壁畫上刻著一些字⋯⋯⋯', player: [ { text: '看下去', next: 1 },{ text: '沒有興趣'} ]},
      1: {
        npcDialog: '像外星人來到地球那樣，<mark>行動夥伴</mark>帶著黑科技降臨迴響⋯⋯',
        player: [ { next: 2 } ] ,
        changeName: 'player',
      },
      2: {
        npcDialog: '每年我們都要向宇宙發射求助的光，在幾個最亮的恆星上⋯⋯',
        player: [ { next: 3 } ] ,
        changeName: 'player',
      },
      3: {
        npcDialog: '會在瞬間收到「我們將再度造訪迴響」的訊號。',
        player: [ { next: 4 } ] ,
        changeName: 'player',
      },
      4: {
        npcDialog: '夥伴是用時間綁成約定的結，握著還不確定就能上攀的繩伴⋯⋯',
        player: [ { next: 5 } ] ,
        changeName: 'player',
      },
      5: {
        npcDialog: '我們用這座石像紀念這些老朋友⋯⋯',
        player: [ { next: 7 } ] ,
        changeName: 'player',
      },
      // 6: {
      //   npcDialog: '這座石像紀念迴響的騎士們⋯⋯',
      //   player: [ { next: 7 } ] ,
      //   changeName: 'player',
      // },
      7: {
        npcDialog: '他們有些是陪著迴響四屆的社會創新組織，有的當年也曾參加過迴響。',
        player: [ { text: '繼續看下去', modal: 'giant_room_2' }, { text: '轉身離去' } ] ,
        changeName: 'player',
      }
    }
  },
  Counter: {
    name: '山屋櫃檯',
    notFinish: {
      0: { "npcDialog": "歡迎光臨，請問要 Check-in 嗎？在這個世界裡，凡事只要<mark>按鍵盤 A 鍵</mark>就可以解決唷，包括跟我對話！", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "搭配<mark>鍵盤的上下鍵</mark>並且<mark>使用 A 鍵</mark>，可以選擇不同的答覆來回應唷！", "player": [ { "next": 2, "text": "原來如此" }, { "next": 2,"text": "好酷喔！" }, { "next": 2,"text": "知道了啦" } ] } ,
      2: { "npcDialog": "歡迎來到迴響大本營，需要為您介紹<mark>青年迴響計畫</mark>嗎？", "player": [ { "next": 70, "text": "那是什麼？" }, { "next": 3,"text": "我很熟了" } ] } ,
      ...RethinkIntro,
      75: { "npcDialog": "好！歡迎來到<mark>迴響大本營</mark>線上展，我這就為您辦理登山證，請告訴我您的名字！", "player": [ {
        input: {
          question: '為你的角色取名吧：',
          key: 'name'
        },
        text: '為角色取名',
        next: 5
      } ] },
      3: { "npcDialog": "好的，原來是熟人了！那麼我就直接為您辦理登山證，請告訴我您的名字！", "player": [ {
        input: {
          question: '為你的角色取名吧：',
          key: 'name'
        },
        next: 5
      } ] } ,
      5: { "npcDialog": "我來看看你叫做什麼名字⋯⋯", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "<mark>player</mark>啊⋯⋯很棒的名字，之後想更改名字，只要到這裡找我就好囉！", "player": [ { "next": 7 } ] } ,
      7: { "npcDialog": "我們會即時<mark>儲存</mark>您的<mark>遊玩進度</mark>，即便退出視窗，仍可繼續之前的探索，除非您使用無痕模式開啟網頁。", "player": [ {
        next: 8,
        ongoing: '獲得登山證，完成新手教學！',
        passAisle: ['newbie'],
        completeFirst: true,
        rewards: ['passport'],
        "delNPCs": [ "ActionGod", "ActionGodFloor", "GodLight" ] // trick
      } ] } ,
      8: { "npcDialog": "那麼，祝您旅途愉快，現在就發給您登山證。按 <mark>B</mark> 打開<mark>背包</mark>來查看吧！", "player": [ { "next": "100" } ] } ,
      // next to onGoing 100
    },
    onGoing: {
      100: {
        npcDialog: '對了，外面的霧還很濃，建議<mark>先在山屋內找其他人對話</mark>再出去，會比較安全喔！',
        player: [
          {
            text: '我知道了！',
            // next: 999
          }
        ]
      },
        // 101: {
        //   npcDialog: '最後給你個小提示，任何時候想要退出對話或視窗，只要按<mark>鍵盤 Q 鍵</mark>就可以囉！',
        //   player: [
        //     {
        //       text: '原來如此',
        //       next: 0
        //     }
        //   ]
        // },
      // 999: {
      //   npcDialog: '還有什麼事情嗎？有什麼好奇的事都可以問我唷！',
      //   player: [
      //     {
      //       text: '探索指南',
      //       next: 1
      //     },
      //     {
      //       text: '操作說明',
      //       next: 30
      //     },
      //     {
      //       text: '我想瞭解這一帶',
      //       next: 10
      //     },
      //     {
      //       text: '沒事了，再見！',
      //     },
      //   ]
      // },
      0: {
        npcDialog: '您好，還需要提供什麼服務嗎？',
        player: [
          // {
          //   text: '探索指南',
          //   next: 1
          // },
          {
            text: '操作說明',
            next: 30
          },
          // {
          //   text: '瞭解這一帶',
          //   next: 10
          // },
          {
            text: '介紹迴響',
            next: 70
          },
          {
            text: '我想改名字',
            next: 20
          },
        ]
      },
      75: {
        npcDialog: '您好，還需要提供什麼服務嗎？',
        player: [
          // {
          //   text: '探索指南',
          //   next: 1
          // },
          {
            text: '操作說明',
            next: 30
          },
          // {
          //   text: '瞭解這一帶',
          //   next: 10
          // },
          {
            text: '介紹迴響',
            next: 70
          },
          {
            text: '我想改名字',
            next: 20
          },
        ]
      },
      ...RethinkIntro,
      // 1: { "npcDialog": "初來乍到的行動者，通常都不知道該從何探索起，於是我們提供了幾個<mark>探索指南</mark>⋯⋯", "player": [ { "next": 2, text: '如何探索' }, {"next": 77, text: '觀展心態'} ] } ,
      // 2: { "npcDialog": "建議你可以先探索山屋，並點燃山屋前的<mark>營火</mark>。", "player": [ { "next": 3 } ] } ,
      // 3: { "npcDialog": "再去探索左區的路線——迴響大山，在登頂處你能找到<mark>頂上風景</mark>。", "player": [ { "next": 4 } ] } ,
      // 4: { "npcDialog": "熟悉這裡的生態後，再去探索右區的吊橋後路線——迴圈森林，在森林深處找到<mark>迴圈森林的召喚</mark>。", "player": [ { "next": 5 } ] } ,
      // 5: { "npcDialog": "探索完森林後，可以去下區密道裡的迴響大澤，探索神秘的<mark>澤中傳說</mark>！", "player": [ { "next": 6, "text": "我怎麼都聽不懂" } ] } ,
      // 6: { "npcDialog": "行動指南就放在你的背包裡唷，退出對話後，點按<mark>兩次 B 鍵</mark>，就可以看見任務界面了！", "player": [ { "text": "我知道了！" }, { "text": "我還想問問題", "next": "0" } ] },
      // 77: { "npcDialog": "迴響計畫至今已經四年，首次用 <mark>RPG 線上展</mark>的方式，把四年來的林林總總展示於此遊戲當中。", "player": [ { "next": 78 } ] } ,
      // 78: { "npcDialog": "左區的登頂歷程，你能回顧迴響計畫<mark>四年來</mark>的里程碑與<mark> 2027 願景</mark>。", "player": [ { "next": 79 } ] } ,
      // 79: { "npcDialog": "右區的森林，我們藉由幫動物們解決問題，認識迴響的<mark>行動工具</mark>。", "player": [ { "next": 710 } ] } ,
      // 710: { "npcDialog": "下區的迴響大澤，能夠認識到迴響<mark>文化</mark>重視的<mark>行動價值</mark>。", "player": [ { "next": 711 } ] } ,
      // 711: { "npcDialog": "帶著好奇心，把這當作是一款<mark>有趣的遊戲</mark>，跟偶遇的 NPC 聊聊，認識迴響裡大大小小的故事吧！", "player": [ { "next": 712 } ] } ,
      // 712: { "npcDialog": "如果還想知道更多，如知識庫、眾力小宇宙、任務包、出版物等⋯⋯可以到<a href='http://rethinktaiwan.com/' target='_blank'>入口頁</a>一探究竟。", "player": [ { "text": "我知道了！" }, { "text": "還有問題想問", "next": "0" } ] },
      10: {
        npcDialog: '這裡是迴響大本營的中心——<mark>回音山屋</mark>，',
        player: [
          {
            next: 11
          }
        ]
      },
      11: {
        npcDialog: '是行動者們啟程前的暫歇處，藏有歷代探險家的故事和行動者為後人留下的工具。',
        player: [
          {
            next: 12
          }
        ]
      },
      12: {
        npcDialog: '迴響大本營，附近包含<mark>左</mark>、<mark>右</mark>及<mark>下方</mark>三條秘徑⋯⋯',
        player: [
          {
            text: '想知道左邊有什麼',
            next: 1310
          },
          {
            text: '想知道右邊有什麼',
            next: 1320
          },
          {
            text: '想知道下方有什麼',
            next: 1330
          }
        ]
      },
      14: {
        npcDialog: '好的，您還想了解哪一條路徑呢？',
        player: [
          {
            text: '想知道左邊有什麼',
            next: 1310
          },
          {
            text: '想知道右邊有什麼',
            next: 1320
          },
          {
            text: '想知道下方有什麼',
            next: 1330
          }
        ]
      },
      1310: {
        npcDialog: '左邊是行動大山，由古老的板塊推升，沿途可以看到<mark>迴響四年來的演化歷程</mark>。',
        player: [
          {
            next: 1311
          }
        ]
      },
      1311: {
        npcDialog: '一路拾級而上，在<mark>最後的觀景台</mark>可以清楚的看到遠方。值得一訪！',
        player: [
          {
            text: '懂了！'
          },
          {
            text: '想了解其他路徑',
            next: 14
          }
        ]
      },
      1320: {
        npcDialog: '右邊是迴圈森林，據說蘊含著許多前人的智慧結晶，能從中學習到許多<mark>行動方法</mark>。',
        player: [
          {
            next: 1321
          }
        ]
      },
      1321: {
        npcDialog: '<mark>森林的中心</mark>似乎藏著什麼秘密，是個值得探訪的地方！',
        player: [
          {
            text: '原來如此'
          },
          {
            text: '想了解其他路徑',
            next: 14
          }
        ]
      },
      1330: {
        npcDialog: '下區是迴響大澤，到那裡得要搭船才能遊覽唷！據說有一個<mark>擺渡人</mark>能協助你遊湖。',
        // npcDialog: '這要從一個故事說起，從前從前有一個太監⋯⋯',
        player: [
          {
            next: 1331
          }
        ]
      },
      1331: {
        npcDialog: '至於大澤有什麼嘛⋯⋯他有一些神秘的傳說，我也不是很清楚，建議<mark>先去過左右兩區</mark>再到大澤一遊吧！',
        player: [
          {
            text: '原來如此！'
          },
          {
            text: '想了解其他路徑',
            next: 14
          }
        ]
      },
      20: {
        changeName: '山屋鮭魚臺',
        npcDialog: '您也想吃鮭魚嗎？⋯⋯真調皮，我們不會外流或記錄您的資料，您可以取任何您喜歡的名字。',
        player: [
          {
            text: '填寫名字',
            input: {
              question: '為你的小人取名吧：',
              key: 'name'
            },
            next: 21
          },
          {
            text: '沒事了',
          }
        ]
      },
      21: {
        npcDialog: '我來看看你叫做什麼名字⋯⋯',
        player: [
          {
            next: 22
          }
        ]
      },
      22: {
        changeName: 'player',
        npcDialog: '我的名字叫做 <mark>player</mark>！',
        player: [
          {
            next: 23
          }
        ]
      },
      23: {
        npcDialog: 'player 啊⋯⋯很棒的名字，之後想更改名字，只要到這裡找我就好囉！',
        player: [
          {
            text: '好的，謝謝！'
          }
        ]
      },
      30: {
        npcDialog: '您可以試著按 <mark>A</mark> 與物件或人物<mark>互動</mark>，藉此開啟任務或獲得道具！按 <mark>Q</mark> 則可以隨時退出對話。',
        player: [
          {
            text: '任務？道具？',
            next: 300
          },
          // {
          //   text: '道具有什麼用？',
          //   next: 3020
          // },
          // {
          //   text: '我想認識網站團隊',
          //   next: 3030
          // },
          // {
          //   text: '想對官網許願',
          //   next: 3040
          // }
        ]
      },
      300: { "npcDialog": "按<mark>一次 B 鍵</mark>，你可以看見<mark>背包</mark>，按<mark>兩次 B 鍵</mark>，你會看見<mark>任務</mark>界面，也可以用滑鼠左上角的 icon 來打開。", "player": [ { "next": 301 } ] } ,
      301: { "npcDialog": "道具欄除了蒐集解任務需要的物品，也會存放四處蒐集的<mark>知識與資訊</mark>，只要點按<mark> A 鍵</mark>就能打開查閱。", "player": [ { "next": 302 } ] } ,
      302: { "npcDialog": "任務是探索迴響大本營的指南，只要照著<mark>任務的指示</mark>走，就能一步步發現森林裡的驚喜。", "player": [ { "next": 303 } ] } ,
      303: { "npcDialog": "當然，我們還是準備了一些<mark>彩蛋</mark>是獨立於任務之外的，就靠你的好奇心去發掘囉！", "player": [ { "text": "我知道了！" }, { "text": "我還有問題", "next": "0" } ] }
      // 31: {
      //   npcDialog: '好的，請問您還有什麼問題呢？',
      //   player: [
      //     {
      //       text: '什麼是解任務？',
      //       next: 3010
      //     },
      //     {
      //       text: '道具有什麼用？',
      //       next: 3020
      //     },
      //     {
      //       text: '我想認識網站團隊',
      //       next: 3030
      //     }
      //     {
      //       text: '想對官網許願',
      //       next: 3040
      //     }
      //   ]
      // },
      // 3010: {
      //   npcDialog: '在迴響之森裡，任務多半與人有關，與人對話，會獲得任務。',
      //   player: [
      //     {
      //       next: 3011
      //     }
      //   ]
      // },
      // 3011: {
      //   npcDialog: '部分的任務需要取得特定的道具，解任務時，只要身上帶著物品，按 A 就會<mark>自動使用</mark>。還有什麼疑惑嗎？',
      //   player: [
      //     {
      //       text: '沒事了！謝謝你'
      //     },
      //     {
      //       text: '我還有其他問題',
      //       next: 31
      //     },
      //   ]
      // },
      // 3020: {
      //   npcDialog: '有些任務會需要使用道具，得要預先蒐集，道具都收藏在<mark>畫面左上角的背包</mark>裡。',
      //   player: [
      //     {
      //       next: 3021
      //     }
      //   ]
      // },
      // 3021: {
      //   npcDialog: '可以點擊<mark>鍵盤 B 鍵</mark>叫出背包查看！多去跟這世界的人事物互動，就會獲得道具。',
      //   player: [
      //     {
      //       next: 3022
      //     }
      //   ]
      // },
      // 3022: {
      //   npcDialog: '如果對包包裡的道具感到好奇，可以在背包的畫面中 <mark>按 A 使用道具</mark>，會獲得道具的指示唷。',
      //   player: [
      //     {
      //       text: '躍躍欲試！謝謝你'
      //     },
      //     {
      //       text: '我還有其他問題',
      //       next: 31
      //     },
      //   ]
      // },
      // 3030: {
      //   npcDialog: '她們三個是經過特化後的人類，時區、思考方式溝通的方式，都跟一般人不同。',
      //   player: [
      //     {
      //       next: 3031
      //     }
      //   ]
      // },
      // 3031: {
      //   npcDialog: '如果想要進一步認識他們，可以私訊<a href="https://www.facebook.com/RethinkTaiwan2027/" target="_blank">迴響粉專</a>或<a href="https://www.instagram.com/rethinktaiwan2027/" target="_blank">IG</a>，我們有一台轉譯機，會再幫您轉知。',
      //   player: [
      //     {
      //       text: '真香啊～'
      //     },
      //     {
      //       text: '我還有其他問題',
      //       next: 31
      //     },
      //   ]
      // },
      // 3040: {
      //   npcDialog: '許願基本上是不行，因為要趕工的酷東西滿多的，不過如果你真的很有靈感⋯⋯',
      //   player: [
      //     {
      //       next: 3041
      //     }
      //   ]
      // },
      // 3041: {
      //   npcDialog: '你可以私訊給<a href="https://www.facebook.com/RethinkTaiwan2027/" target="_blank">粉專</a>或 <a href="https://www.instagram.com/rethinktaiwan2027/" target="_blank">IG</a>，靈感一經採用，我們將會⋯⋯⋯⋯⋯⋯⋯⋯在心底偷偷期待你能看到。',
      //   player: [
      //     {
      //       text: '手刀前往'
      //     },
      //     {
      //       text: '我還有其他問題',
      //       next: 31
      //     },
      //   ]
      // },
    },
    justFinish: {
      0: { "npcDialog": "哇，初始營火、頂上風景、迴圈森林跟澤中女神⋯⋯你已經探索完<mark>所有地方</mark>了呢！", "player": [ { "next": 2, "text": "那當然！" } ] } ,
      // 1: { "npcDialog": "我已經沒什麼能為你導覽的了，記得，迴響山屋永遠是你可以回來歇息之處。", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "最後，這個東西就送給你吧！這是我們團隊的<mark>對講機</mark>，打開背包按 A 使用對講機，就能<mark>找到我們</mark>。", "player": [ { "next": 3, "text": "竟然！" } ] } ,
      3: { "npcDialog": "很高興遇到跟我們<mark>頻率</mark>一樣的人，我們會在頻道裡面相見，祝旅途愉快！", "player": [ { "text": "謝謝妳！再見", "rewards": [ "walkie-talkie" ], "complete": "探索完畢！得到了迴響的對講機，趕快打開來看看吧！" } ] }
    },
    finished: {
      0: { "npcDialog": "你也許已經發現，這個網站裡面仍有許多未被挖掘的<mark>彩蛋</mark>與<mark>驚喜</mark>。", "player": [ { "next": 1, "text": "像是什麼？" } ] } ,
      1: { "npcDialog": "領養過狗狗了嗎？製作了哪些道具呢？跟行動夥伴、議題 NPC 們聊得如何呢？", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "提醒您，只要您沒有清除瀏覽記錄，即便重新整理或關閉瀏覽器，您的<mark>遊玩進度</mark>仍會存在。", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "也就是說，不急於今天探索出所有的彩蛋，你可以留待明日、未來，任何你有餘裕的時刻。", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "當您決定繼續探索，你可以<mark>按兩次 B</mark> 打開探索指南，您會發現任務變<mark>多</mark>了！", "player": [ { "next": 5 } ] } ,
      5: { "npcDialog": "行動夥伴 - 阿德正在煮石頭湯，需要蒐集迴響大本營裡面所有行動夥伴的<mark>石頭</mark>共十二顆。", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "山頂的掃地僧正在研究<mark>石碑</mark>的內文，若你解讀了所有石碑，就去山頂找他吧！", "player": [ { "next": 7 } ] } ,
      7: { "npcDialog": "下區大澤前的木匠弟弟，正在製作他的畢生傑作，需要的<mark>素材</mark>可多了！", "player": [ { "next": 8 } ] } ,
      8: { "npcDialog": "迴圈森林深處，老樹當中的考古學家，正在探索世上六個<mark>巨人石像</mark>的蹤跡。", "player": [ { "next": 9 } ] } ,
      9: { "npcDialog": "最後，澤中女神正在蒐集所有議題 NPC 的<mark>花朵</mark>，等你拿齊了，就送到澤中給她吧！", "player": [ { "next": 10, "text": "然後呢？" } ] } ,
      10: { "npcDialog": "如果你忘記，隨時可以按兩次 B 打開探索指南，當你完成方才提及的所有任務⋯⋯", "player": [ { "next": "11" } ] } ,
      11: { "npcDialog": "將會有另一個<mark>神秘的結局</mark>在等著你。", "player": [ { "text": "我知道了！" } ] }
    }
  },
  TableGuy: {
    name: '向左走向右走？',
    notFinish: {
      0: {
        changeName: '學號 763092',
        npcDialog: '霧好濃啊⋯⋯這樣根本<mark>哪裡都去不了</mark>，能不能想辦法讓霧散掉呀？',
        player: [
          {
            next: 1
          }
        ]
      },
      1: {
          changeName: '學號 784533',
          npcDialog: '據說點燃山屋前的<mark>營火</mark>就能讓霧散去了？不曉得山屋上方的那個<mark>老協作</mark>會不會有辦法⋯⋯'
      }
    },
    justFinish: {
      0: { "npcDialog": "左邊的山路才是<mark>初心者</mark>該走的路線啊！你能看到這座森林的<mark>起源</mark>，還能看見山頂的絕美風景呢。", "player": [ { "next": 1 } ], "changeName": "學號 763092" } ,
      1: { "npcDialog": "但右邊的森林才是<mark>老骨頭</mark>最棒的路線！那裡有許多可愛小動物，還能學習到許多<mark>行動的方法</mark>！", "player": [ { "next": 2 } ], "changeName": "學號 784533" } ,
      2: { "npcDialog": "你呢？你要走左邊還右邊？", "player": [ { "next": 3 } ], "changeName": "（兩人轉頭看向你）" } ,
      3: { "npcDialog": "我⋯⋯我⋯⋯（感受到兩人凌厲的眼神）", "player": [ { "next": 4, "text": "走左邊" }, { "text": "走右邊", "next": "6" }, { "text": "走下面", "next": "8" }, { "text": "默默逃走" } ], "changeName": "player" } ,
      4: { "npcDialog": "啊哈⋯⋯哈哈哈哈哈哈！你太有眼光了，我這就告訴你一個秘密！", "player": [ { "next": 5 } ], "changeName": "學號 763092" } ,
      5: { "npcDialog": "登頂之後你會看見一個<mark>攝影師</mark>，他可以幫你拍下你跟<mark>山頂風景</mark>的合照喔！", "player": [ { "text": "哇喔！太酷了" } ], "changeName": "學號 763092" } ,
      6: { "npcDialog": "選得好！<mark>如果已經去過左邊</mark>了，走右區是最棒的！我這就告訴你一個迴圈森林的秘密⋯⋯", "player": [ { "next": 7 } ], "changeName": "學號 784533" } ,
      7: { "npcDialog": "帶著你身上的<mark>火柴盒</mark>，就可以去迴圈森林的<mark>中心點</mark>，探索那邊的秘密喔！", "player": [ { "text": "哇喔！太酷了" } ], "changeName": "學號 784533" } ,
      8: { "npcDialog": "⋯⋯", "player": [ { "next": 9 } ], "changeName": "（兩人同時看向我）" } ,
      9: { "npcDialog": "你走開！！！我們不想看到你。", "player": [ { "text": "（無辜逃走）" } ], "changeName": "（兩人同時看向我）" }
    }
  },
  LieMan: {
    name: '睡覺的人',
    justFinish: {
      0: {
        npcDialog: 'ㄜ⋯⋯嗚⋯⋯嗯⋯⋯小小人⋯⋯從樹上⋯⋯掉下來了⋯⋯⋯⋯',
        player: [
          {
            next: 1
          }
        ]
      },
      1: {
        npcDialog: '我只是⋯⋯按 A ⋯⋯推樹而已⋯⋯不是⋯⋯故意的⋯⋯⋯⋯',
        player: [
          {
            next: 2
          }
        ]
      },
      2: {
        npcDialog: '好奇怪⋯⋯的叫聲⋯⋯到底什麼是⋯⋯<mark>How Might We</mark>⋯⋯'
      }
    }
  },
  FirstElephant: {
    name: '房間裡的大象',
    notFinish: {
      0: {
        changeName: '未知的聲音',
        npcDialog: '沒人看得見我⋯⋯'
      }
    },
    justFinish: {
      0: {
        npcDialog: '你看見我了嗎？真感人⋯⋯想跟你分享<a href="https://drive.google.com/drive/folders/1xFmPSjPkrTMTE5WXlKW_agvhLVZAzz4-?usp=sharing" target="_blank">大象包</a>。看見問題是行動的第一步，繼續前行吧⋯⋯'
      }
    },
  },
  Bonfire: {
    name: '營火',
    notFinish: {
      0: {
        npcDialog: '（營火熄滅了，整座森林死氣沈沈的）',
        player: [
          {
            next: 1
          }
        ]
      },
      1: {
        changeName: 'player',
        npcDialog: '濕氣太重了，尋常的火柴好像點不著，該怎麼辦呢⋯⋯<mark>山屋裡的人</mark>會有辦法嗎？',
      }
    },
    justFinish: {
      0: {
        npcDialog: '（是否點燃營火？）',
        player: [
          {
            text: '用火柴點火',
            event: ['BonfireFire', 'FirstElephant'],
            complete: '濃霧已散去！可以自由探索迴響大本營了！',
            passAisle: ['fog'],
            clearFog: true,
            delMission: ['FirstFog'],
            next: 1
          },
          {
            text: '不要',
            next: 2
          }
        ]
      },
      1: { npcDialog: '（營火已點燃！感受到這溫暖的火焰⋯⋯周圍的<mark>濃霧竟然散去了</mark>。）', player: [ { next: 2 } ] },
      2: { npcDialog: '（凋零而孤獨的營火正在無聲的抗議啊⋯⋯）' }
    },
    finished: {
      0: { npcDialog: '（營火已點燃！感受到這溫暖的火焰⋯⋯周圍的濃霧竟然散去了。）', player: [ { text: '探索指南', next: 2 }, { text: '轉身離開' } ] },
      2: { "npcDialog": "你看見了房間裡的大象，付諸行動，因而點燃營火，照亮了世界。", "player": [ { "next": 3 } ], changeName: '青年迴響計畫 Rethink2027' } ,
      3: { "npcDialog": "這種<mark>鼓勵行動</mark>的氛圍，就是迴響在做的事情，很高興你跟迴響的距離更近了一些。", "player": [ { "next": 4 } ], changeName: '青年迴響計畫 Rethink2027' } ,
      4: { "npcDialog": "仔細看畫面的左下角，看見了嗎？這是你所照亮的遼闊世界，你可以用這張地圖當作你探索的指引。", "player": [ { "next": 5 } ], changeName: '青年迴響計畫 Rethink2027' } ,
      5: { "npcDialog": "你所身處的地方是一個開放世界的<mark>線上展</mark>，你可以到處晃晃，挑你有興趣的主題去看。", "player": [ { "next": 6 } ], changeName: '青年迴響計畫 Rethink2027' } ,
      6: { "npcDialog": "山屋裡的老協作將會引導你知道更多探索的細節⋯⋯", "player": [ { text: '去找他！', "transferAndDialog": {x: 149, y: 209} }, { text: '我不要' } ], changeName: '青年迴響計畫 Rethink2027'  } ,
      // 5: { "npcDialog": "我們建議第一次來的人先往<mark>左邊</mark>探索，需要更多資訊可以找山屋櫃檯，或<mark>按兩次 B 鍵</mark> 找到探索指南！", "player": [ { "next": 6 } ] } ,
      // 6: { "npcDialog": "這個世界很大，不急於一次就探索完畢。抱著輕鬆的心情，去探索我們準備的驚喜吧！", "player": [ { "next": 7, "text": "好！" } ] }
    }
  },
  Alder: {
    name: '老協作',
    sound: 'npc_m_alder',
    notFinish: {
      0: { "npcDialog": "小伙子，偶素迴響大本營ㄉ老協作，這裡ㄉ人都尊稱我為「辣個引路人」。", "player": [ { "next": 1, "text": "好好說話" } ], "changeName": "辣個引路人" } ,
      1: { "npcDialog": "喔好吧⋯⋯年輕人，你想到屋外探險嗎？", "player": [ { "next": 2, "text": "想" } ] } ,
      2: { "npcDialog": "<mark>行動</mark>以前，你要先看到問題的本質啊⋯⋯你先去山屋上方，看過<mark>貼著臺灣地圖的 post-it 牆</mark>吧。", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "等你寫下了你看見的<mark>大象</mark>，再回來找我吧⋯⋯", "player": [ {ongoing: '到山屋上方的牆面，尋找關於「大象」的線索。'} ] }
    },
    onGoing: {
      0: { "npcDialog": "你先去山屋上方，看過<mark>貼著臺灣地圖的 post-it 牆</mark>吧。", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "等你寫下了你看見的<mark>大象</mark>，再回來找我吧⋯⋯", "player": [ {} ] }
    },
    justFinish: {
      0: { "npcDialog": "哦？你已經寫下你心中的<mark>大象</mark>了⋯⋯這四年來，我在迴響看過許多人，在這裡指出他們看見的大象。", "player": [ { "next": 1 } ] } ,
      1: { "npcDialog": "你們這些行動者的出現，讓我對未來更有期待了。這是我珍藏已久的<mark>火柴盒</mark>，就送給你吧！", "player": [ { "next": 2 } ] } ,
      2: { "npcDialog": "最近天氣不好，<mark>外頭的營火</mark>都熄滅了，使得許多人無法踏出行動⋯⋯火柴盒或許能派上用場。", "player": [ { "rewards": [ "matches" ], "complete": "得到了老協作傳承的火柴盒，快去點燃營火吧！" } ] }
    },
    finished: {
      0: { "npcDialog": "初來乍到的行動者，通常都不知道該從何探索起，於是我們提供了幾個<mark>探索指南</mark>⋯⋯", "player": [ { "next": 1, "text": "請告訴我！" }, { "text": "不想聽" } ] } ,
      1: { "npcDialog": "建議你先探訪<mark>左區</mark>的路線——行動大山。登頂歷程，你能回顧迴響計畫四年來的里程碑與 2027 願景。", "player": [ { "next": "2" } ] } ,
      2: { "npcDialog": "之後再去探索右區吊橋後的路線——迴圈森林。藉由幫動物們解決問題，認識迴響的<mark>行動工具</mark>。", "player": [ { "next": 3 } ] } ,
      3: { "npcDialog": "最後是下區的迴響大澤，你能夠認識到迴響的文化所重視的<mark>團隊價值</mark>。", "player": [ { "next": 4 } ] } ,
      4: { "npcDialog": "在左、右、下區的<mark>終點</mark>，你分別能看見三個<mark>奇觀</mark>：頂上風景、迴圈森林的召喚以及澤中傳說。", "player": [ { "next": 5, "text": "那是什麼？" } ] } ,
      5: { "npcDialog": "自己去探索吧！左下角的地圖有<mark>三個發亮的點</mark>，那就是奇觀的位置。按兩次 B 打開任務指南能看見線索。", "player": [ { "next": 6 } ] } ,
      6: { "npcDialog": "等你探索完三個奇觀，再回來回音山屋找<mark>櫃檯</mark>吧，她會引領妳進入 RPG 線上展的<mark>結局</mark>⋯⋯", "player": [ { text: '我知道了！', "next": "7" } ] } ,
      // 7: { "npcDialog": "放開心胸去探索吧！就當作是在散步，看到有趣的事物時，何不佇足觀賞片刻呢？", "player": [ { "text": "我知道了！" } ] }
      // 0: {
      //   npcDialog: '有什麼問題麼？',
      //   player: [
      //     {
      //       text: '想知道其他人寫的大象',
      //       next: 1
      //     },
      //     {
      //       text: '想要改名',
      //       next: 4
      //     }
      //   ]
      // },
      // 1: {
      //   npcDialog: '這座森林住著許多的<mark>小小人</mark>，他們曾與許多來來往往的行動者們相遇⋯⋯',
      //   player: [
      //     {
      //       next: 2
      //     }
      //   ]
      // },
      // 2: {
      //   npcDialog: '據說他們記下了歷年來那些行動者們面臨的<mark>議題</mark>，不妨去找找看小小人，聽聽他們所寫下的問題',
      //   player: [
      //     {
      //       text: '小小人在哪裡？',
      //       next: 3
      //     }
      //   ]
      // },
      // 3: {
      //   npcDialog: '我也不曉得（抓頭），但<mark>櫃檯樓上那個睡覺的人</mark>好像有看過小小人出沒？'
      // },
      // 4: {
      //   changeName: '老鮭魚協作',
      //   npcDialog: '你也想吃鮭魚麼？⋯⋯總之，改名的話，去找<mark>山屋櫃檯</mark>啦'
      // }
    }
  },
  Dog: {
    name: '山屋前的狗狗',
    notFinish: {
      0: { "npcDialog": "哇！好可愛的狗狗喔！（跟狗相處一陣子後，狗狗突然朝某個方向用力吠叫）", "player": [ { "next": 1 } ], "changeName": "player" } ,
      1: { "npcDialog": "汪！汪！汪！汪！汪！汪！汪！汪！汪！", "player": [ { "text": "彎腰摸頭安撫", "next": "5" }, { "text": "讓狗狗去吠叫的方向看看", "next": "5" }, { "text": "自己去狗狗吠叫的方向看看", "next": "2" }, { "text": "輕聲細語叫他安靜", "next": "5" } ] } ,
      2: { "npcDialog": "（狗狗突然用力<mark>甩動身體</mark>，不太想理人的樣子。）", "player": [ { "text": "幫狗狗檢查身上的髒污", "next": "6" }, { "text": "擁抱狗狗", "next": "6" }, { "text": "彎腰摸狗狗的頭", "next": "6" }, { "text": "離他遠一點", "next": "3" } ] } ,
      3: { "npcDialog": "狗狗突然開始在<mark>魚屍體</mark>跟<mark>垃圾</mark>上翻滾，他在做什麼呢？⋯⋯", "player": [ { "text": "享受這些臭味", "next": "4" }, { "text": "肚子餓了", "next": "7" }, { "text": "把自己的氣味留在地上", "next": "7" }, { "text": "討主人關注", "next": "7" } ], "changeName": "player" } ,
      4: { "npcDialog": "（狗狗開心的搖尾巴！彷彿你是他<mark>命中注定的主人</mark>，於是開始跟著你走了⋯⋯）", "player": [ { "text": "認養他！", complete: '認養了山屋前的狗狗！帶著他一起探索世界吧！', getDog: true, delMission: 'Dog', delNPCs: ['Dog'] } ] } ,
      5: { "npcDialog": "（狗狗突然用力<mark>甩動身體</mark>，不太想理人的樣子。）", "player": [ { "next": 6, "text": "幫狗狗檢查身上的髒污" }, { "text": "擁抱狗狗", "next": "6" }, { "text": "彎腰摸狗狗的頭", "next": "6" }, { "text": "離他遠一點", "next": "6" } ] } ,
      6: { "npcDialog": "狗狗突然開始在<mark>魚屍體</mark>跟<mark>垃圾</mark>上翻滾，他在做什麼呢？⋯⋯", "player": [ { "next": 7, "text": "享受這些臭味" }, { "text": "肚子餓了", "next": "7" }, { "text": "把自己的氣味留在地上", "next": "7" }, { "text": "討主人關注", "next": "7" } ], "changeName": "player" } ,
      7: { "npcDialog": "（狗狗垂頭喪氣，回去繼續睡覺了。似乎要把全部的反應都做對，他才會開心⋯⋯）", "player": [ { "next": 8 } ] } ,
      8: { "npcDialog": "唉，如果可以更懂一些跟<mark>狗狗互動</mark>的技巧，或是能<mark>聽懂他們說的話</mark>，那就好了⋯⋯", "player": [ {} ], "changeName": "player" }
    },
    justFinish: {
      0: { "npcDialog": "哇！好可愛的狗狗喔！（跟狗相處一陣子後，狗狗突然朝某個方向用力吠叫）", "player": [ { "next": 1 } ], "changeName": "player" } ,
      1: { "npcDialog": "我幫你翻譯！狗狗說：汪！汪！那邊好像有危險⋯⋯不知道是什麼，不敢過去看，<mark>你可以幫我看一下嗎</mark>？", "player": [ { "text": "彎腰摸頭安撫", "next": "5" }, { "text": "讓狗狗去吠叫的方向看看", "next": "5" }, { "text": "自己去狗狗吠叫的方向看看", "next": "2" }, { "text": "輕聲細語叫他安靜", "next": "5" } ], "changeName": "elfName" } ,
      2: { "npcDialog": "（狗狗突然用力<mark>甩動身體</mark>）他說：壓力好大⋯⋯我跟你<mark>不認識</mark>吧？不要一直盯著我看。", "player": [ { "text": "幫狗狗檢查身上的髒污", "next": "6" }, { "text": "擁抱狗狗", "next": "6" }, { "text": "彎腰摸狗狗的頭", "next": "6" }, { "text": "離他遠一點", "next": "3" } ], "changeName": "elfName" } ,
      3: { "npcDialog": "（狗狗突然開始在<mark>魚屍體</mark>跟<mark>垃圾</mark>上翻滾）他說：氣味好重！好<mark>開心</mark>ㄚ～～～", "player": [ { "text": "享受這些臭味", "next": "4" }, { "text": "肚子餓了", "next": "7" }, { "text": "把自己的氣味留在地上", "next": "7" }, { "text": "討主人關注", "next": "7" } ], "changeName": "elfName" } ,
      4: { "npcDialog": "（狗狗開心的搖尾巴！彷彿你是他<mark>命中注定的主人</mark>）他說：你好棒！可以當我的主人嗎？", "player": [ { "text": "認養他！", complete: '認養了山屋前的狗狗！帶著他一起探索世界吧！', getDog: true, delMission: 'Dog', delNPCs: ['Dog'] } ], "changeName": "elfName" } ,
      5: { "npcDialog": "（狗狗突然用力<mark>甩動身體</mark>）他說：壓力好大⋯⋯我跟你<mark>不認識</mark>吧？不要一直盯著我看。", "player": [ { "next": 6, "text": "幫狗狗檢查身上的髒污" }, { "text": "擁抱狗狗", "next": "6" }, { "text": "彎腰摸狗狗的頭", "next": "6" }, { "text": "離他遠一點", "next": "6" } ], "changeName": "elfName" } ,
      6: { "npcDialog": "（狗狗突然開始在<mark>魚屍體</mark>跟<mark>垃圾</mark>上翻滾）他說：氣味好重！好<mark>開心</mark>ㄚ～～～", "player": [ { "next": 7, "text": "享受這些臭味" }, { "text": "肚子餓了", "next": "7" }, { "text": "把自己的氣味留在地上", "next": "7" }, { "text": "討主人關注", "next": "7" } ], "changeName": "elfName" } ,
      7: { "npcDialog": "（狗狗垂頭喪氣，回去繼續睡覺了。）", "player": [ { "next": 8 } ] } ,
      8: { "npcDialog": "唉，elfName都已經幫我翻譯了，但似乎要把全部的反應都做對，狗狗才會開心⋯⋯", "player": [ {} ], "changeName": "player" }
    }
  },
  Kettle: {
    name: '熱水壺',
    justFinish: {
      0: { "npcDialog": "山屋裡的熱水壺，給人溫暖的感覺。是哪些人一起煮出來的呢？", "player": [ { "text": "歷屆工作人員名單", modal: 'credits' }, { "text": "無情離去" } ], "changeName": "player" }
    },
  },
  BulletinBoard: {
    name: '公佈欄',
    justFinish: {
      0: { "npcDialog": "這裡有迴響計畫的介紹喔！在這個網站的體驗還愉快嗎？歡迎給我們一些意見：<a href='https://forms.gle/7puQALnybEk3RdiR9' target='_blank'>問卷連結</a>。", "player": [ { "text": "迴響計畫簡介", modal: 's0_intro' }, { "text": "離開" } ] }
    },
  }
  // TurnOffLieHouse1: {
  //   onlyTalking: {
  //     0: {
  //       npcDialog: '這裡有個神秘的燈座呢⋯⋯⋯⋯',
  //       player: [
  //         { text: '默默離開' },
  //         {
  //           text: '無情放火',
  //           next: 1,
  //           event: ['LieHouse1']
  //         },
  //       ]
  //     },
  //     1: {
  //       npcDialog: '幽暗的密室被火照亮了！房間裡的大象漸漸顯露出來⋯⋯大象似乎想說些什麼？',
  //       changeName: '房間裡的大象',
  //       player: [
  //         {
  //           delMission: 'TurnOffLieHouse1'
  //         }
  //       ]
  //     }
  //   },
  // }
}
