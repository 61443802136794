<template>
  <Modal :name="'ax_teamwork'" ref="modal" :isWhite="true">
    <h5><b>和夥伴的工作習慣不同，有時會產生衝突，但好像不知道怎麼講開的話...怎麼辦？</b></h5>
    <br />
    <br />
    <p>跟人有關的事情，問神明一定是最後一個選項。</p>
    <p>雖然有這樣的問題，現在問肯定是來不及了，不過下次或許你可以先試著在組成團隊時，就邀請大家寫這份使用說明書：<a href="https://docs.google.com/presentation/d/1sI-PF-x75jv8d8Vi3Hme3-eB_ZbCGPWiDiMjMXExJL8/edit#slide=id.gd599a005f5_0_0" target="_blank">使用說明書範例</a> ，這樣等到真的有什麼衝突了，有機會翻看一下，才知道會不會是自己踩雷了，也會知道可以如何開啟溝通。</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
