<template>
  <Modal :name="'partner_8'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>玩轉學校／阿屁(哲宇)</h3>
    <p>關於【玩轉學校】</p>
    <p>#教學的起點_來自於啟發學習動機</p>
    <p>#教育的終點_是學到改善生活環境</p>
    <p>在遊戲情境中，學生透過經驗學到的，不只是知識。玩轉是一間沒有校舍與固定老師的學校，藉由遊戲，點燃孩子自主學習的熱情、交換彼此的生命經驗。</p>
    <p>曾經也是個過動兒的阿屁，嘗試改變傳統教育以升學為目標的限制。透過遊戲，選擇用行動來實踐自己的社工魂！玩轉將世界送到孩子面前，創造一個充滿混亂與未知問題的情境，讓孩子從解決問題的過程中，學會如何和這個世界互動。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_8.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
