export const hmw = [{"y":"2017","id":"014","hmw":"我們如何能夠讓公益結合城市生活？","members":"茂原徐, 黃楷雯"},
{"y":"2017","id":"017","hmw":"我們如何幫助人們走出負面情緒，並讓對方感到溫暖？","members":"許宛庭, 周永祐"},
{"y":"2017","id":"018","hmw":"我們如何能讓青年更重視樂齡教育，推動長青族互動橋梁？","members":"高廷, 吳翰昇"},
{"y":"2017","id":"021","hmw":"我們如何讓人們期待每天的生活？","members":"陳玟潔, 吳金薇"},
{"y":"2017","id":"024","hmw":"我們如何能吸收原民慶典精神，體現薪火相傳共創豐年？","members":"黃譯霆, 張仲凱"},
{"y":"2017","id":"025","hmw":"我們如何能讓偏遠地區學童說出自己的需要？","members":"蕭任佑, 溫朗東"},
{"y":"2017","id":"030","hmw":"我們如何能夠讓優質的小誌或獨立刊物更觸及到民眾？","members":"許珮絨, 黃潔瑄"},
{"y":"2017","id":"031","hmw":"我們如何能夠有效移除在港灣中漂浮的垃圾？","members":"陳思穎, 陳亮吟, 曾鈺婷"},
{"y":"2017","id":"032","hmw":"我們如何能在都市叢林中體現鄰里關懷的防災安全社區？","members":"蔡伯昌, 蔡淑婷"},
{"y":"2017","id":"033","hmw":"我們如何能夠讓同樣生活在大安區的人有更多的凝聚力？","members":"鄭宜珊, 鄭小群"},
{"y":"2017","id":"035","hmw":"我們如何能讓想追夢想分享的人們都找到最適合的夥伴？","members":"徐筱晴, 藍恩民, 楊汶庭"},
{"y":"2017","id":"036","hmw":"我們如何能夠讓年長者嶄露頭角，活得更自信？","members":"陳巧穎, 李牧豐, 施宏奕"},
{"y":"2017","id":"038","hmw":"我們如何透過教育加強學童與在地歷史文化的連結？","members":"林莞婷, 董音"},
{"y":"2017","id":"040","hmw":"我們如何能夠落實都市裡的居住正義？","members":"柯思菱, 黃廷崴"},
{"y":"2017","id":"042","hmw":"我們如何能讓想要充實自己的人能容易得到講座資訊？","members":"鄭宜湘, 王佩安"},
{"y":"2017","id":"046","hmw":"我們如何能藉由改變資源回收桶來提升資源使用的效用？","members":"何姿萩, 李玠葭"},
{"y":"2017","id":"047","hmw":"我們如何找回與家人間的緊密聯繫？","members":"洪盈軒, 許耕毓, 施冠全"},
{"y":"2017","id":"048","hmw":"我們如何能夠減少機車使用，改善臺北市容與環境品質？","members":"江浚懿, 陳逸軒, 吳念蓁"},
{"y":"2017","id":"049","hmw":"我們如何能夠重振廟宇文化之風光，凝聚地方意識？","members":"蘇鋐濬, 盧薏, 邱芸茜"},
{"y":"2017","id":"050","hmw":"我們如何能夠減輕育兒者的心理壓力及照顧負擔？","members":"呂昀儒, 鄒喻合"},
{"y":"2017","id":"052","hmw":"我們如何讓「行善」這件事能夠更到位？","members":"王馨, 李夢亭, 吳政憲"},
{"y":"2017","id":"053","hmw":"我們如何能夠讓台北外的地區也有豐富的活動資源？","members":"鄭安婷, 劉兆濠"},
{"y":"2017","id":"054","hmw":"我們如何能夠幫助人們實現夢想，不輕易停下？","members":"陳昱安, 顏肇霆, 鄧楷齡"},
{"y":"2017","id":"055","hmw":"我們如何能夠更健康跟自由的與胸部對話？","members":"巫怡萱, 盧德榮, 羅苡庭"},
{"y":"2017","id":"057","hmw":"我們如何匯聚點點微光，透過交流與同理把世界照亮？","members":"徐佑昀, 黃煦扉"},
{"y":"2017","id":"059","hmw":"我們如何能夠讓流浪狗找到一個不會棄養牠的飼主？","members":"林子馨, 翁欣怡"},
{"y":"2017","id":"061","hmw":"我們如何能夠啟發學生在體制教育之下跳脫體制？","members":"張艿安, 游佳恩, 韓宇軒"},
{"y":"2017","id":"062","hmw":"我們如何能夠讓那些需要被幫助的老人能夠長期被關注？","members":"陳宥瑾, 黃詩晴"},
{"y":"2017","id":"063","hmw":"我們如何讓拾荒者、書籍和閱讀大眾產生連結？","members":"李翊群, 徐靖婷"},
{"y":"2017","id":"064","hmw":"我們如何能夠讓銀髮族走出戶外並且接受新事物？","members":"李芝茜, 林芷岑, 曾瑞揚"},
{"y":"2017","id":"067","hmw":"我們如何能拉回在青春中偏離軌道迷失方向的青少年？","members":"邱奕醇, 成瑋盛"},
{"y":"2017","id":"068","hmw":"我們如何能夠翻轉攝影師遇到的種種困境？","members":"鐘弘育, 石羽, 劉佩姍"},
{"y":"2017","id":"069","hmw":"我們如何能夠讓城市空間更有趣而且有更多可能？","members":"左楚浩, 張凱翔"},
{"y":"2017","id":"071","hmw":"我們如何能夠簡化公共wifi登入流程讓生活更便利？","members":"朱恆慶, 林罡北, 高培倫"},
{"y":"2017","id":"073","hmw":"我們如何能夠讓大眾更方便的滿足均衡飲食的需求？","members":"魏千棻, 王開彥"},
{"y":"2017","id":"077","hmw":"我們如何能夠讓舊背包創造最大價值與展現個人品味？","members":"王耀德, 劉育"},
{"y":"2017","id":"078","hmw":"我們如何能夠讓各地小農產出的好產品廣受消費者青睞？","members":"張哲瑋, 黃曉嵐"},
{"y":"2017","id":"081","hmw":"我們如何能夠讓研究者收集到足夠且有效的樣本？","members":"楊凱翔, 尤子瑄"},
{"y":"2017","id":"082","hmw":"我們如何能夠讓國人正視含糖飲料的潛在危機？","members":"黃詩雅, 王君婷"},
{"y":"2017","id":"084","hmw":"我們如何能夠讓身邊的人對環保有更多體認？","members":"施沛萱, 徐可庭"},
{"y":"2017","id":"086","hmw":"我們如何能夠透過醫療系統，接觸每個學齡前弱勢家庭？","members":"洪翊誠, 劉平成"},
{"y":"2017","id":"087","hmw":"我們如何能夠在城市的任何一處都喝到乾淨的飲用水？","members":"許育羚, 周穎, 林韻如"},
{"y":"2017","id":"090","hmw":"我們如何能讓青年發現自我價值，看見更多的未來可能？","members":"陳致遠, 張力堃, 張瓈文"},
{"y":"2017","id":"091","hmw":"我們如何能打造適宜人居的永續城市，使人民生活在地化？","members":"王思婷, 譚詠光"},
{"y":"2017","id":"094","hmw":"我們如何能夠讓失能者更容易找到居家復健治療師？","members":"廖國翔, 宋承恩"},
{"y":"2017","id":"099","hmw":"我們如何能夠讓流口水患者保有舒適並拾回自尊？","members":"廖又閮, 張簡育珊"},
{"y":"2017","id":"100","hmw":"我們如何能夠讓臺灣藝術創作者有更多的生存機會？","members":"何菘育, 陳原禾"},
{"y":"2017","id":"102","hmw":"我們如何能夠讓更多人透過愛上青苔強化對生態的重視？","members":"林珮汝, 黃源森"},
{"y":"2017","id":"103","hmw":"我們如何引導兒童及年輕人深刻地認識身邊的自然環境？","members":"黃姵綺, 鄭學謙, 張蔓欣"},
{"y":"2017","id":"105","hmw":"我們如何能夠讓競技運動增添更多誘因及出路？","members":"謝仁傑, 錢泊菘, 蔡佳妤"},
{"y":"2017","id":"106","hmw":"我們如何讓大眾不要對使用 kratom 的人貼上錯標籤？","members":"劉益宏, 許羽宗, 于敬生"},
{"y":"2017","id":"107","hmw":"我們如何能夠吃對魚，讓魚業資源永續？","members":"吳佳駿, 高菁秀"},
{"y":"2017","id":"108","hmw":"當外國人問起時，我們如何能夠自豪地介紹臺灣的故事？","members":"陳怡臻, 李芝瑩, 呂妤安"},
{"y":"2017","id":"110","hmw":"我們如何能夠改變教育體制，讓人們創造自己獨特的價值？","members":"陳亮吟, 廖得宇"},
{"y":"2017","id":"111","hmw":"我們如何能夠鼓勵青少年投入農務，彼此互為生命夥伴？","members":"羅以樂, 李英綺"},
{"y":"2017","id":"112","hmw":"我們如何能夠透過做中學讓學生們累積經驗和技能？","members":"陳韋蓉, 黃文君, 沈潔伃"},
{"y":"2017","id":"113","hmw":"我們如何在日常生活中找到一起說客語的朋友？","members":"謝佳真, 呂慧真, 吳文睿"},
{"y":"2017","id":"115","hmw":"我們如何能夠幫助弱勢學童擁有面對困境的勇氣？","members":"陳思妤, 陳思嘉"},
{"y":"2017","id":"116","hmw":"我們如何能夠提升年輕族群對台語的認同感和使用率？","members":"賴怡秀, 盧冠名, 呂韋葶"},
{"y":"2017","id":"117","hmw":"我們如何讓學生真正與多元文化產生連結？","members":"胡鈞媛, 曾薈芯"},
{"y":"2017","id":"118","hmw":"我們如何讓購買二手衣成為選擇，減少新衣購買量？","members":"李佳盈, 陳慶容, 洪于捷"},
{"y":"2017","id":"119","hmw":"我們如何能夠培養民眾擁有媒體識讀的能力？","members":"謝岱倪, 劉芳瑀"},
{"y":"2017","id":"120","hmw":"我們如何能夠增加弱勢青少年的生活經驗與未來規劃？","members":"陳妤庭, 楊子漩, 蕭妙芹"},
{"y":"2017","id":"121","hmw":"我們如何能從早午餐店開始改善使用塑膠吸管的習慣？","members":"陳金燕, 曾子庭"},
{"y":"2017","id":"122","hmw":"我們如何能夠讓臺灣木材自給率提升？","members":"林郁庭, 田詠葳, 吳佩珈"},
{"y":"2017","id":"123","hmw":"我們如何能夠翻轉台灣的讓座文化？","members":"李思穎, 黃郁琦"},
{"y":"2017","id":"124","hmw":"我們如何能夠讓街道上的塗鴉成為都市的特色？","members":"黃子軒, 藍家蓁, 張耀文"},
{"y":"2017","id":"125","hmw":"我們如何改善都市內擁擠危險的道路環境？","members":"羅怡雯, 劉巽怡"},
{"y":"2017","id":"126","hmw":"我們如何解決在圖書館找不到書的窘境，改善書籍管理？","members":"蕭嘉蕙, 梁毓志"},
{"y":"2017","id":"127","hmw":"我們如何能夠確實將『魚菜共生』推廣至社區民眾？","members":"葉浩宇, 林鑫宏, 顏爾佑"},
{"y":"2017","id":"128","hmw":"我們如何能夠讓環保變得很方便？","members":"方于文, 陳逸庭, 李汭蔆"},
{"y":"2017","id":"131","hmw":"我們如何能夠透過哲學普及的方式，讓小學生學習思考？","members":"謝睿哲, 劉昱余"},
{"y":"2017","id":"132","hmw":"我們如何能夠讓表演藝術課程貼近生活並提高保留價值？","members":"宗慶弘, 于立舫"},
{"y":"2017","id":"133","hmw":"我們如何協助婦女媽媽們解決二度就業及托育問題？","members":"蔡吉章, 柳軍亞"},
{"y":"2017","id":"135","hmw":"我們如何讓台灣運動選手更被大眾認識？","members":"陳曦, 林冠甫, 曹雅晴"},
{"y":"2017","id":"136","hmw":"我們如何能以學生身分，補足新世代中教育的缺口？","members":"李芸軒, 林佑潔, 李容慶"},
{"y":"2017","id":"139","hmw":"我們如何能夠讓捷運高架橋更美？","members":"Tony, Rex"},
{"y":"2017","id":"140","hmw":"我們如何能夠改善學生受科技影響造成注意力缺失的問題？","members":"黃藍祈, 黃茂彰"},
{"y":"2017","id":"142","hmw":"我們如何能夠啟蒙大學生的公民意識進而關注公共議題？","members":"高瑋臨, 薛融暄, 吳思璇"},
{"y":"2017","id":"143","hmw":"我們如何能幫助高中生認識科系，更精準掌握未來方向？","members":"劉勇廷, 劉以正, 李亞柔"},
{"y":"2017","id":"144","hmw":"我們如何能拉近朋友與家人的距離？","members":"馮小蓁, 劉家瑀, 鄧曉郁"},
{"y":"2017","id":"146","hmw":"我們如何能夠讓 CSR 成為員工的小幸福？","members":"胡雁婷, 林庠序"},
{"y":"2017","id":"147","hmw":"我們如何能夠改善校園內垃圾處理問題？","members":"江宛蓁, 汪玓瑩, 蔡郁玟"},
{"y":"2017","id":"148","hmw":"我們如何能夠在排除科技的情況下，增加人與人的互動？","members":"賴曉萱, 張晏禔, 區庭毓"},
{"y":"2017","id":"151","hmw":"我們如何能夠讓台灣美妝品牌成為世界愛用？","members":"許珉瑄, 許雅棻"},
{"y":"2017","id":"154","hmw":"我們如何能夠拉近人與人之間的真實距離？","members":"林士淵, 鄭宇傑, 林育賢"},
{"y":"2017","id":"155","hmw":"我們如何能在手機上用母語溝通？","members":"葉人豪, 盧三智, 劉士楷"},
{"y":"2017","id":"156","hmw":"我們如何媒合食物供需兩方藉以減少食物浪費？","members":"陳意淳, 蕭元瑄, 薛岱恩"},
{"y":"2017","id":"157","hmw":"我們如何能夠引發當地居民對於週遭環境的關注度？","members":"王子健, 林城億"},
{"y":"2017","id":"160","hmw":"我們如和能夠讓急救知識能廣泛的傳達給民眾？","members":"黃子軒, 吳承穎, 陳俊伊"},
{"y":"2017","id":"161","hmw":"我們如何能夠更了解自己居住的城市呢？","members":"吳岱芸, 張芷茜"},
{"y":"2017","id":"162","hmw":"我們如何能改善過度依賴行動裝置所造成的冷漠？","members":"劉怡君, 張孟珺, 高紹軒"},
{"y":"2017","id":"164","hmw":"我們如何能讓藝術人才在緬甸發展？","members":"彭艷婷, 黃英樺"},
{"y":"2017","id":"165","hmw":"我們如何能夠減少食物浪費？","members":"陳信甫, 黃閔貞"},
{"y":"2017","id":"166","hmw":"我們如何能夠用娛樂的方式讓年輕人更認識台灣文化？","members":"梁芸瑄, 鄭丁嘉, 李懿晟"},
{"y":"2017","id":"167","hmw":"我們如何能夠使心理疾病患者被好好理解？","members":"古典雅, 薛名喨"},
{"y":"2017","id":"168","hmw":"我們如何能夠讓學生在學校教室裡就能自燃求知慾 ？","members":"朱讚美, 李祖源"},
{"y":"2017","id":"169","hmw":"我們如何能夠讓傳統市場真正與科技時代接軌？","members":"謝欣汝, 楊子瑩, 林永盛"},
{"y":"2017","id":"170","hmw":"我們如何讓大學生撐竿跳入好企業？","members":"陳可峻, 王咨涵"},
{"y":"2017","id":"172","hmw":"我們如何能打破僵化的語言交換模式？","members":"劉宛湘, 李昀儒"},
{"y":"2017","id":"174","hmw":"我們如何能夠讓理工人與異性交際並洗刷社會的汙名化？","members":"楊俊宏, 林政豪"},
{"y":"2017","id":"175","hmw":"我們如何能夠創造讓原住民更能認同自己文化的環境？","members":"邱立馨, 林庭鳳, 高雯"},
{"y":"2017","id":"176","hmw":"我們如何能夠提供工作弱勢者合理的工作機會？","members":"王欣如, 陳世偉"},
{"y":"2017","id":"177","hmw":"我們如何能夠重建民眾對藥物回收的認知與提高回收量？","members":"周芷晴, 楊晴懿"},
{"y":"2017","id":"179","hmw":"我們如何能夠以新型態的開門方式來降低大眾行車風險？","members":"黃偉哲, 林姿萍"},
{"y":"2017","id":"180","hmw":"我們如何能夠翻轉農業形象以增進青年家鄉認同感？","members":"余欣盈, 魏曉恩, 林梓鈞"},
{"y":"2017","id":"181","hmw":"我們如何顛覆大眾對學校教育的想像？","members":"連建榮, 羅于晴, 林莞婷"},
{"y":"2017","id":"182","hmw":"我們如何能夠藉珍惜新店線鐵道的串連加強地方的認同？","members":"陳瑞益, 張沛齊"},
{"y":"2017","id":"185","hmw":"我們如何能夠在快速更迭的時代中使傳統技藝保留價值？","members":"邱福緯, 賴靖雯"},
{"y":"2017","id":"186","hmw":"我們如何能夠讓喜歡健身的朋友找到夥伴並互相交流？","members":"楊彥伯, 彭冠瑋"},
{"y":"2017","id":"188","hmw":"我們如何能夠幫助大學生找到自己的熱情所在？","members":"林妏憶, 丁薇心, 張智鈞"},
{"y":"2017","id":"191","hmw":"我們如何能夠使青少年勇於嘗試體制外的教育模式？","members":"謝雯如, 賴書晴"},
{"y":"2017","id":"192","hmw":"我們如何能用更即時貼心的方式幫助憂鬱的朋友活出新生？","members":"麥約瑟, 許偉忠, 詹惠文"},
{"y":"2017","id":"193","hmw":"我們如何能夠讓畢業後的人加強交流呢？","members":"蔡奕戎, 楊凱丞, 甯韋庭"},
{"y":"2017","id":"194","hmw":"我們如何能夠讓都市與鄉村的高中生更了解彼此？","members":"楊詠萱, 邱子瑜"},
{"y":"2017","id":"196","hmw":"我們如何能夠使得未來求職者了解多元職業選擇？","members":"李宛諭, 陳若涵"},
{"y":"2017","id":"197","hmw":"我們如何能夠讓高中生有效的探索科系與人生？","members":"許匡毅, 葉家均"},
{"y":"2017","id":"199","hmw":"我們如何能夠讓更多人同理憂鬱症患者的內心與困境？","members":"藍子晴, 留鈺淳, 李俜怡"},
{"y":"2017","id":"200","hmw":"我們如何能夠建立安心照護空間？","members":"陳鉉文, 鄭翔耀"},
{"y":"2017","id":"203","hmw":"我們如何能夠讓台語不要消失，保存這項重要的文化資產？","members":"陳泱霖, 謝博翰"},
{"y":"2017","id":"204","hmw":"我們如何改善臺灣道路上遍布菸蒂的問題？","members":"陳彥廷, 林藝, 王書妤"},
{"y":"2017","id":"205","hmw":"我們如何能夠讓國中生認為基礎知識有學習的價值？","members":"黃政豪, 吳宗錡, 賴予亭"},
{"y":"2017","id":"206","hmw":"我們如何能夠讓家長尊重孩子對未來的選擇？","members":"鍾宜秀, 楊鈞鈞"},
{"y":"2017","id":"208","hmw":"我們如何能夠讓北捷的使用者體驗更加完善？","members":"葉菘揚, 林翊珊, 歐昭佑"},
{"y":"2017","id":"209","hmw":"我們如何能夠透過媒體整合再現藝能科？","members":"劉佩宜, 吳和陵, 陳美瑜"},
{"y":"2017","id":"210","hmw":"我們如何能夠對資源做更有效的利用，提高生活便利性？","members":"詹貽茜, 林怡廷, 柯智恩"},
{"y":"2017","id":"211","hmw":"我們如何能夠讓學英文不再是為了考試？","members":"王葦蓉, 鄭惠慈"},
{"y":"2017","id":"212","hmw":"我們如何能夠讓心靈脆弱的人有抒發的管道？","members":"劉容君, 白庭宇"},
{"y":"2017","id":"213","hmw":"我們如何讓臺灣匠人的專業技藝價看見並扭轉市場生態？","members":"徐嘉臨, 廖宜涓"},
{"y":"2017","id":"214","hmw":"我們如何透過招牌呈現臺北歷史文化之美？","members":"吳詩翎, 何富珊, 瞿長緣"},
{"y":"2017","id":"217","hmw":"我們如何能夠減輕消防員工作量？","members":"王俊曜, 吳嘉恩, 蔡奇勳"},
{"y":"2017","id":"218","hmw":"我們如何能夠減少青年到偏鄉作教育及服務學習的失落？","members":"吳芸蕎, 吳宇倫"},
{"y":"2017","id":"219","hmw":"我們如何能讓垃圾減量、珍惜資源成為一種日常生活態度？","members":"陳穎蓉, 陳奎男, 陳琦典"},
{"y":"2017","id":"221","hmw":"我們如何能夠透過認識自己，產生自信去澆灌生命？","members":"許芳瑜, 邱渝雅"},
{"y":"2017","id":"224","hmw":"我們如何能夠讓閒置空間重獲新生命？","members":"賴品媜, 張家瑜, 劉佑群"},
{"y":"2017","id":"225","hmw":"我們如何能在生活中有效減少食物浪費？","members":"鄧年娣, 黃莫乖, 王惠琳"},
{"y":"2017","id":"226","hmw":"我們如何塑造讓台灣學子勇於開口說英文的環境？","members":"陳思宇, 李依庭, 廖英僑"},
{"y":"2017","id":"227","hmw":"我們如何能夠讓民眾落實醫療分級？","members":"江俊廷, 曾柏硯, 林裕恒"},
{"y":"2017","id":"228","hmw":"我們如何提高漁民、漁販、消費者自覺性來達到年年有魚？","members":"洪韻筑, 王傳惠, 劉逸穎"},
{"y":"2017","id":"231","hmw":"我們如何能夠讓大家享用美食又不傷害環境？","members":"謝萱, 王健宇, 張允欣"},
{"y":"2017","id":"235","hmw":"我們如何能夠減少海漂垃圾並保護海洋環境？","members":"林佳昀, 李其芸, 李品琦"},
{"y":"2017","id":"236","hmw":"我們如何能提供青少年一個詢問愛情相關問題的管道？","members":"謝勝仲, 羅于涵, 無"},
{"y":"2017","id":"238","hmw":"我們如何能夠讓社會大眾再次重視真相？","members":"蔡尚諭, 羅少君, 陳建宇"},
{"y":"2017","id":"239","hmw":"我們如何能夠提高大眾主動參與綠化環境的興趣和行動？","members":"王甄淳, 曹乃文, 許郁楨"},
{"y":"2017","id":"240","hmw":"我們如何能夠讓國中生真實了解電競夢？","members":"黃柏翰, 黃應宇"},
{"y":"2017","id":"242","hmw":"我們如何能夠讓都市的閒置空間讓許多人發現及運用？","members":"傅玉松, 許益昇"},
{"y":"2017","id":"244","hmw":"我們如何解決雙北上班族沒時間遛狗的問題？","members":"李佳芳, 鍾亞嶙, 張祐禎"},
{"y":"2017","id":"245","hmw":"我們如何從最基本的民生需求來了解多元文化？","members":"蕭翰翔, 簡毓瑩"},
{"y":"2017","id":"246","hmw":"如何藉由資料視覺化的網站來讓需要的人知道資訊？","members":"詹喬智, 胡中瀚"},
{"y":"2017","id":"247","hmw":"我們如何讓中年人的生活有發展的可能性？","members":"符雅琪, 余宜庭, 鄭曉蔓"},
{"y":"2017","id":"249","hmw":"我們如何在交通工具上不滑手機，來場有溫度的乘車之旅？","members":"楊祐欽, 張承棋, 王毓齊"},
{"y":"2017","id":"251","hmw":"我們如何能夠打破都市的冷漠？","members":"林思漢, 劉芸秀"},
{"y":"2017","id":"252","hmw":"我們如何能夠鼓勵平常人平時的文章創作？","members":"駱昀陽, 陳昌碩, 范瀚仁"},
{"y":"2017","id":"253","hmw":"我們如何能夠拒絕孤老，讓長者擁有美好的人際關係？","members":"黃毓茹, 黃詩蘋"},
{"y":"2017","id":"254","hmw":"我們如何能夠翻轉社會大眾對不同職業階級的觀感？","members":"夏敬權, 賴瑞翎, 楊秉樺"},
{"y":"2017","id":"255","hmw":"我們如何能夠減少山難的搜救時間？","members":"黃致岳, 黃怡珊"},
{"y":"2017","id":"257","hmw":"我們如何能夠真正創造出一個友善流浪動物的環境？","members":"陳盈穎, 吳明修"},
{"y":"2017","id":"258","hmw":"我們如何能夠讓大學生主動關心社會並對議題做出行動？","members":"陳怡安, 林苡晴"},
{"y":"2017","id":"259","hmw":"我們如何能夠讓學生的思維和行動，跳出慣性的邏輯？","members":"黃川, 疏哲"},
{"y":"2017","id":"260","hmw":"我們如何能夠提升全民時事敏感度與思考能力？","members":"賴辰威, 徐鵬翔, 陳哲霨"},
{"y":"2017","id":"261","hmw":"我們如何能夠讓他們在這片土地留下一些好的回憶？","members":"石喻仁, 戴浚宏"},
{"y":"2017","id":"262","hmw":"我們如何能夠帶動台灣人在閒暇時也往知識層面靠攏？","members":"Maurice Horodezky, 廖又欣"},
{"y":"2017","id":"263","hmw":"我們如何能夠尊重每位認真付出的工作者與他們的工作？","members":"高珮容, 蔡絜如, 張詔如"},
{"y":"2017","id":"265","hmw":"我們如何能藉由寫信喚醒對重要的人深刻的回憶？","members":"賴巧姈, 林祐任"},
{"y":"2017","id":"266","hmw":"我們如何能夠讓老化的社區重返年輕活力？","members":"蔣於恬, 殷強遠, 陳安德"},
{"y":"2017","id":"267","hmw":"我們如何能夠讓高中生深入認識科系並做適合的職涯選擇？","members":"楊光城, 蔡宏文, 陳亭安"},
{"y":"2017","id":"268","hmw":"我們如何改善自閉症孩子在校園中受到的不友善對待？","members":"陳瑀婕, 林傳恩, 許家瑋"},
{"y":"2017","id":"271","hmw":"我們如何能夠以雞肋的剩布問題，來解決寒士生計問題？","members":"黃晨豪, 陳怡芳"},
{"y":"2017","id":"272","hmw":"我們如何能夠重新活絡台灣新鮮感降低的旅遊行程？","members":"黃昱翔, 陳柏毅"},
{"y":"2017","id":"273","hmw":"我們如何能夠讓環保的理念落實在外食族的每一餐？","members":"張之綾, 廖雅琴"},
{"y":"2017","id":"274","hmw":"我們如何能夠以科技的方式，幫助聽損兒童及其家庭？","members":"陳品亘, 郭子琳, 李知芸"},
{"y":"2017","id":"275","hmw":"我們如何能夠打破同溫層、提高「以認養代替購買寵物」的機率？","members":"歐雅雯, 王叡謙, 劉子嘉"},
{"y":"2017","id":"276","hmw":"我們如何能讓健身同好者之間的知識與實際經驗透過交流？","members":"劉昂里, 王育薰"},
{"y":"2017","id":"277","hmw":"我們如何能夠走出網路、活出自我？","members":"彭柏翔, 林純卉, 許家綺"},
{"y":"2017","id":"278","hmw":"我們如何能夠建立，真正屬於我們自己的國家？","members":"陳芷羚, 李嘉宇, 林子翔"},
{"y":"2017","id":"279","hmw":"我們如何能夠達到多餘食物的共享及再利用性？","members":"張甯雅, 林文元"},
{"y":"2017","id":"280","hmw":"我們如何打破對非洲的刻板印象，開啟對話與連結？","members":"盧韋辰, 何佩佳"},
{"y":"2017","id":"282","hmw":"我們如何能夠讓環保杯的使用更加的方便甚至引領潮流？","members":"廖佳倫, 楊七喜"},
{"y":"2017","id":"283","hmw":"我們如何能夠讓產品沒負擔的被更多需要的人所使用？","members":"滕長琳, 賴柏巖"},
{"y":"2017","id":"284","hmw":"我們如何能夠讓學生留在校園，不要學壞？","members":"顏婉如, 楊雅婷"},
{"y":"2017","id":"285","hmw":"我們如何能夠讓青少年瞭解性騷擾範疇並預防性騷擾發生？","members":"徐亦立, 郭昱辰"},
{"y":"2017","id":"286","hmw":"我們如何能夠讓台灣街舞傳承與交流？","members":"周杰, 鄭雅倩"},
{"y":"2017","id":"287","hmw":"我們如何提升台灣人的環境保護意識？","members":"楊永楨, 胡家瑄, 楊畯棋"},
{"y":"2017","id":"288","hmw":"我們如何能夠讓這座城市的人自發的綠美化周遭環境？","members":"謝晨, 薛十方"},
{"y":"2017","id":"289","hmw":"我們如何能夠翻轉老觀念，共創美好高齡新社會？","members":"陳鐸文, 陳昱志"},
{"y":"2017","id":"290","hmw":"我們如何能夠解決起不來的室友鬧鐘問題？","members":"陶揚, 陳柏光"},
{"y":"2017","id":"291","hmw":"我們如何能夠將文字與編輯內化成生活的一部份？","members":"蔡牧恒, 吳凌瑜, 陳一瑄"},
{"y":"2017","id":"293","hmw":"我們如何能夠透過植物染服飾改善全球第二大污染源？","members":"周政宇, 陳常吏"},
{"y":"2017","id":"295","hmw":"我們如何能夠讓大人重視孩子的思考？","members":"曾挺瑋, 許鈺婷"},
{"y":"2017","id":"298","hmw":"我們如何能夠讓民眾清楚台北市的藝術行蹤進而親近藝術？","members":"蕭宇軒, 蕭宇彤"},
{"y":"2017","id":"299","hmw":"我們如何能降低台灣中小企業進入東南亞市場時需花費的？","members":"許文瑄, 紀雅齡, 張語倩"},
{"y":"2017","id":"300","hmw":"我們如何能夠藉由網路將台灣行銷到全世界？","members":"葉德偉, 李志允, 李宜真"},
{"y":"2017","id":"301","hmw":"我們如何能夠避免服務污染的產生？","members":"楊元嘉, 郭冠廷"},
{"y":"2017","id":"302","hmw":"我們如何協助台灣新興體育選手發展？","members":"蔡宗杭, 李奕辰"},
{"y":"2017","id":"303","hmw":"我們如何讓現代學生意識到手機成癮的嚴重性？","members":"張慧安, 董建維, 林昕翰"},
{"y":"2017","id":"304","hmw":"我們如何能夠讓飛行少年再次獲得學習的機會？","members":"邱鬧, 陳巧玟"},
{"y":"2017","id":"305","hmw":"我們如何能夠有效利用資源使偏鄉兒童得到最好教育？","members":"魏言安, 馬安里"},
{"y":"2017","id":"307","hmw":"我們如何能夠在現實中營造更活潑自在的同志交友社群？","members":"黃語婕, 鄧欣容"},
{"y":"2017","id":"308","hmw":"我們如何能夠讓年輕人更願意了解東南亞的人與文化？","members":"王詠心, 李芸萱"},
{"y":"2017","id":"309","hmw":"我們如何能夠讓每個人發揮環保精神，建構環保人生？","members":"毛美能, 黃玄堯"},
{"y":"2017","id":"310","hmw":"我們如何能夠讓眾人脫離對「時尚」的迷思？","members":"楊筠, 黃宣仁"},
{"y":"2017","id":"311","hmw":"我們如何能夠陪伴孩子看到更多人生選擇？","members":"鄭筠靜, 戴禧"},
{"y":"2017","id":"312","hmw":"我們如何能夠讓大眾對社會事件不要只保持三分鐘熱度？","members":"李靜慧, 楊方喻, 陳萱寧"},
{"y":"2017","id":"313","hmw":"我們如何透過誘導，循序在消費者腦中輸入減塑指令？","members":"游艾佳, 羅蕙心, 陳靜怡"},
{"y":"2017","id":"316","hmw":"我們如何能夠彌補台灣教育的不足之處？","members":"林子文, 賴彥廷"},
{"y":"2017","id":"317","hmw":"我們如何解決國小校園霸凌？","members":"黃柏榮, 曾資穎"},
{"y":"2017","id":"318","hmw":"我們如何能夠讓臺灣人開車願意禮讓行人？","members":"鄭禕仁, 陳可耘"},
{"y":"2017","id":"320","hmw":"我們如何能夠提高網購服飾的消費體驗讓期待接近現實？","members":"黃彥榮, 李玟賢"},
{"y":"2017","id":"321","hmw":"我們如何能夠讓漁業能與觀光、環境良好結合？","members":"楊桑, 陳彥樺"},
{"y":"2017","id":"322","hmw":"我們如何能夠讓高中畢業學生進行科系上的選填？","members":"張法瑾, 張柏禹"},
{"y":"2017","id":"324","hmw":"我們如何能夠讓臺灣青年認識自身所生長的土地","members":"林冠緯, 林小芸"},
{"y":"2017","id":"325","hmw":"我們如何能夠透過遊戲，讓大家了解自己的身體？","members":"簡士閎, 王永慶"},
{"y":"2017","id":"326","hmw":"我們如何破除網路世界的性別對立僵局？","members":"陳佑孟, 黃渟諭"},
{"y":"2017","id":"327","hmw":"我們如何能夠改變台灣負面與不信任的社會氛圍？","members":"賴柏, 張尹文"},
{"y":"2017","id":"328","hmw":"我們如何能夠減少垃圾量，盡己所能愛地球呢？","members":"李元晴, 鄭伊芩, 盧奕雯"},
{"y":"2017","id":"329","hmw":"我們如何能過練習，讓更多青年勇於踏上獨遊的道路？","members":"林揖, 楊欣茹"},
{"y":"2018","id":"002","hmw":"我們如何能讓大家一同愛上生活的這塊土地上的人事物，並進而產生歸屬感與凝聚文化認同？","members":"林薇, 王嘉田"},
{"y":"2018","id":"003","hmw":"我們如何能夠讓小孩有正確的理財觀念？","members":"林彥佑, 盧俞伶, 周岳蓉"},
{"y":"2018","id":"004","hmw":"如何能夠系統性的減低研究生對論文寫作的壓力？","members":"陳漢寧, 黎映君, 黃新"},
{"y":"2018","id":"005","hmw":"我們如何能夠將台灣文化館舍資源推廣至大眾？","members":"穆昱佳, 王文岳, 王群皓"},
{"y":"2018","id":"006","hmw":"我們如何能夠讓兩個世代減少因不理解而產生的衝突？","members":"梅軒榮, 陳伯麒"},
{"y":"2018","id":"009","hmw":"我們如何能夠讓大眾建立正確的理財素養以及理財規劃，並付諸行動？","members":"江俊廷, 陳敦捷, 鄭郁臻"},
{"y":"2018","id":"011","hmw":"我們如何能夠轉變興南社區化學農藥耕種的現況？","members":"王玉彰, 王立俞"},
{"y":"2018","id":"013","hmw":"我們如何能夠降低台灣氣候災難的影響？","members":"盧俊龍, 張艾文"},
{"y":"2018","id":"014","hmw":"我們如何能夠提高大眾對行車紀錄影片上傳的動機？ 讓道路事件的受害者獲得協助。","members":"葉俊甫, 黃奕勛"},
{"y":"2018","id":"015","hmw":"我們如何能夠復興老街文化並吸引當地青年返鄉就業？","members":"林思嘉, 李函融, 林辰陽"},
{"y":"2018","id":"016","hmw":"我們如何能夠利用現有資源創造利潤以維持收容所營運？","members":"姜正祥, 林哲豪"},
{"y":"2018","id":"017","hmw":"我們如何能夠讓兒童認識性別多元價值？","members":"謝睿哲, 劉昱余"},
{"y":"2018","id":"020","hmw":"我們如何能夠減少臺灣廢棄物的產量？","members":"吳采潔, 謝萱, 黃楹逸"},
{"y":"2018","id":"022","hmw":"我們如何能讓耕牛文化找到與現代人生活的連結，創造新生的能量？","members":"溫祥淵, 游育婷"},
{"y":"2018","id":"023","hmw":"我們如何能讓現代人走入傳統市場？","members":"賴瑞翎, 李傑倫"},
{"y":"2018","id":"024","hmw":"我們如何能夠減少一次性吸管的使用與丟棄？","members":"鍾馨慧, 姚芮安, 張琳"},
{"y":"2018","id":"025","hmw":"我們如何能讓獨居的人以較低的預算買到分量適當且多樣的食材？","members":"高境廷, 黃琳雅"},
{"y":"2018","id":"027","hmw":"我們如何能夠運用民族植物體現並活化在地文化？","members":"王宏恩, 王懷恩, 舞瑪夫-達給魯頓"},
{"y":"2018","id":"030","hmw":"我們如何能夠提升台灣民眾對服飾穿搭「美」的培養，並落實在日常生活中？","members":"劉禧洲, 徐尚謙"},
{"y":"2018","id":"031","hmw":"我們如何能夠讓學童了解自己吃了什麼？","members":"林俐安, 陶俊成, 簡香靜"},
{"y":"2018","id":"033","hmw":"我們如何能夠將教科書的使用效益最大化以減少浪費？","members":"陳信全, 黃襄悅"},
{"y":"2018","id":"034","hmw":"我們如何能夠讓家長提供孩子健康使用3C產品的環境和方法？","members":"陳揚名, 程國維, 徐永欣"},
{"y":"2018","id":"035","hmw":"我們如何能夠讓小孩適度使用3C，家庭教育有新的互動？","members":"柯皓元, 吳元凱"},
{"y":"2018","id":"036","hmw":"我們如何能夠讓在/來台灣的人與山林更有連結？","members":"魏天儀, 黃湘文"},
{"y":"2018","id":"037","hmw":"我們如何能減少日常生活塑膠製品出現在海洋中？","members":"沈貝珊, 姜芯寧, 吳家泓"},
{"y":"2018","id":"038","hmw":"我們如何能夠將網路上的原始資料轉為可用教材？","members":"涂家銘, 楊政憲, 賴其旻"},
{"y":"2018","id":"039","hmw":"我們如何能夠讓新南村的廢棄哈密瓜做更多附加價值的農副產品？","members":"劉冠賢, 陳茗萱"},
{"y":"2018","id":"040","hmw":"我們如何能夠活化台灣工藝，讓更多人看到並了解台灣工藝的價值？","members":"吳怡璇, 洪志杰, 蔣宜君"},
{"y":"2018","id":"042","hmw":"我們如何能夠將觀光發展落實在地方傳統產業和文史,加深旅客、在地居民、土地三者的連結？","members":"魏嘉宏, 王柏元, 魏宏臻"},
{"y":"2018","id":"045","hmw":"我們如何能夠讓「平凡而不凡」的人生故事被看見？","members":"楊詠筑, 張祖維"},
{"y":"2018","id":"046","hmw":"我們如何能夠讓爸爸多參與育兒的過程，改善家庭中不平均的家務分工？","members":"黃竹, 杜威儒, 謝璿"},
{"y":"2018","id":"047","hmw":"我們如何能以圖會友，創造對話，傳遞故事，改變社會大眾因不了解而冷漠所導致的不信任感？","members":"吳家慶, 李佳伊"},
{"y":"2018","id":"049","hmw":"我們如何讓小資族也能夠整修自己夢想中的房子？","members":"曾元志, 莊皓仁"},
{"y":"2018","id":"050","hmw":"我們如何能夠使社會大眾能接納男性軟弱的一面？","members":"余子悅, 沈偉謙, 金瑄庭"},
{"y":"2018","id":"051","hmw":"我們如何不濫用言論自由-將霸凌的傷害降到最低？","members":"謝沛嵐, 劉俐君"},
{"y":"2018","id":"052","hmw":"我們如何能讓臺灣學生願意主動提問？","members":"王虹尹, 陳宇珩, 詹詠竣"},
{"y":"2018","id":"053","hmw":"我們如何能夠促進世代間的想法交流？","members":"郭姵辰, 黃昱翔, 黃昱潔"},
{"y":"2018","id":"054","hmw":"我們如何讓特教學生能更有更多參與社會工作的機會？","members":"陳婷郁, 江俐萱, 梁晏瑜"},
{"y":"2018","id":"055","hmw":"我們如何能夠讓台北捷運的字體系統呈現一致性標準且具有觀光實力？","members":"陳翰璋, 林昱伶, 陳宇翔"},
{"y":"2018","id":"057","hmw":"我們如何活化二輪電影院形象，並發現未來經營想像？","members":"林芸萱, 李彥霖"},
{"y":"2018","id":"058","hmw":"我們如何能夠教育我們的父母了解下一代需要培養個人目標，而讀書只是達成目標的手段？","members":"黃詩晴, 陳宥瑾"},
{"y":"2018","id":"059","hmw":"我們如何能夠讓農民賺到更多錢？","members":"陳奕銓, 杜昀臻, 林宜萱"},
{"y":"2018","id":"060","hmw":"我們如何能夠讓都市裡的居住者對自己所在地產生認同與歸屬感？","members":"林裕仁, 柯盈瑄"},
{"y":"2018","id":"061","hmw":"我們如何解決市場剩菜直接被丟棄的問題？","members":"黃鈺琁, 伍芝容, 蔡佩妤"},
{"y":"2018","id":"062","hmw":"如何能展現台灣的豐富旅遊資源以及旅遊資訊？","members":"洪偉翔, 廖珮均"},
{"y":"2018","id":"071","hmw":"我們如何讓退休族群找到生存價值？","members":"陳巧華, 黃健純"},
{"y":"2018","id":"073","hmw":"我們如何能讓老師在養成階段就能學著看見學生的需求？","members":"邱鬧, 楊惠如, 周秉毅"},
{"y":"2018","id":"077","hmw":"我們如何能夠讓大學生重視食農教育，並落實於生活中？","members":"劉庭碩, 王庭郁, 詹宇雯"},
{"y":"2018","id":"078","hmw":"我們如何能夠協助銀髮族尋找有意義的退休生活？","members":"林揖, 杜易瑄, 何欣芸"},
{"y":"2018","id":"079","hmw":"我們如何能讓台灣的學生快樂學習，而不是為了升學而學習？","members":"陳千皓, 王爾奇, 李哲銘"},
{"y":"2018","id":"080","hmw":"我們如何能夠提高臺灣大眾對心理諮商的接受程度？","members":"林珩, 杜蒨妮, 許晴雯"},
{"y":"2018","id":"082","hmw":"我們如何能夠提升電子發票載具的使用率？","members":"張嘉恩, 歐芷芸, 許瀚文"},
{"y":"2018","id":"083","hmw":"我們如何能夠使社會價值觀不再限制「休學作為生涯探索的適當可能性」？","members":"王淨丞, 胡家瑜"},
{"y":"2018","id":"084","hmw":"我們如何能夠在社群媒體之外找到更多人與人之間互動的連結？","members":"左惠心, 黃婷瑜"},
{"y":"2018","id":"085","hmw":"我們如何能夠突破傳統學科教育，在影視動畫方面開啟多元學習之路？","members":"蔡雅馨, 張雅筑"},
{"y":"2018","id":"086","hmw":"我們如何能夠讓醫學生看見更多不同的職涯可能、認識與找到更適合自己的路？","members":"陳以佑, 郭旭彥"},
{"y":"2018","id":"087","hmw":"我們如何透過可食植物的認識，增加人們飲食的多重選項，重建人與自然的連結？","members":"彭西澤, 黃庭安, 沈家銘"},
{"y":"2018","id":"088","hmw":"我們能夠提出「系統化」科系探索的方法，並用工作坊的模式大量複製，幫助有需要的高中生？","members":"陳佩欣, 葉家均, 許匡毅"},
{"y":"2018","id":"089","hmw":"我們如何能夠讓孩子在課後學習有更多的選擇？","members":"謝安琪, 洪志豪"},
{"y":"2018","id":"090","hmw":"我們如何能夠在以美食天堂為傲的台灣，做出穆斯林友善的飲食環境？","members":"詹昰琮, 何欣芮"},
{"y":"2018","id":"091","hmw":"我們如何能夠讓每個人做好準備一起走向健康老化？","members":"賴冠傑, 楊媛婷, 許竣為"},
{"y":"2018","id":"092","hmw":"我們如何能夠讓金山能將特產以更多元精緻的方式呈現？","members":"劉昱辰, 劉昱琦"},
{"y":"2018","id":"093","hmw":"我們如何能夠讓人自發性地，發出正確的、可被解讀的求救信號？","members":"林芳妤, 陳君芃"},
{"y":"2018","id":"094","hmw":"我們如何能夠改善一次性餐盒與餐具使用氾濫的問題？","members":"林宜蓁, 王佳緯, 郭征諺"},
{"y":"2018","id":"095","hmw":"我們如何能夠喚起社會對失智症的理解與做到好好告別？","members":"王鈺棋, 施品甄"},
{"y":"2018","id":"096","hmw":"我們如何能夠讓需要急救的人第一時間被發現？","members":"林子曜, 簡宏倫"},
{"y":"2018","id":"097","hmw":"我們如何能夠使台灣大眾更正確的認識新住民及其文化？","members":"廖采瀠, 姜文婷"},
{"y":"2018","id":"098","hmw":"我們如何讓學子在現有的體制下擁有開發自己興趣的機會並能真正嘗試及探索？","members":"謝智恩, 廖羿函, 張瓈文"},
{"y":"2018","id":"101","hmw":"如何讓環保的實踐者能夠自己的社交圈中無痕的影響身邊他人一起成為談論者甚至實踐者？","members":"王品人, 林韋任"},
{"y":"2018","id":"104","hmw":"我們如何能夠幫助台灣高齡者為社會貢獻他們的經驗，體現他們的價值，成功的開啟職場新生涯？","members":"鐘佳明, 李權恩, 陳蓓芸"},
{"y":"2018","id":"105","hmw":"我們如何能夠我們如何能夠提升課外活動的學習成效，有效培養大學生的軟實力？","members":"林鈺宸, 程可萱, 李易錩"},
{"y":"2018","id":"107","hmw":"我們如何將課本上的知識與實際的生活連結在一起呢？","members":"陳幸琪, 藍怡雯"},
{"y":"2018","id":"109","hmw":"我們如何能夠讓劣質二手衣物達成永續再生利用？","members":"陳世翊, 陳逸紋, 湛姮瑜"},
{"y":"2018","id":"110","hmw":"我們如何能夠讓閱聽者接受資訊時先暫停想一下？","members":"陳胤錡, 陳儒元"},
{"y":"2018","id":"112","hmw":"我們如何能夠讓新手也能以低成本的方式學會自己提供服務或產品的能力？","members":"施介山, 施貿騰"},
{"y":"2018","id":"113","hmw":"我們如何能夠讓臺灣年輕人具備新世代所需的創新創業能力？","members":"蔡蔚澤, 羅翊維"},
{"y":"2018","id":"115","hmw":"我們如何能夠讓農產業的生產者獲得穩定收入的保障？","members":"簡子翔, 林琳潔"},
{"y":"2018","id":"116","hmw":"我們如何能夠提升外籍看護工對於長輩的照護技巧？","members":"簡君儒, 王彥婷"},
{"y":"2018","id":"118","hmw":"我們如何能夠透過改善拖延的行為，達到解除內心的不安與恐懼，並提高績效與生產力？","members":"李情, 廖庭萱"},
{"y":"2018","id":"120","hmw":"我們如何能夠降低大學生車禍事故發生率？","members":"劉宜珊, 王易喬, 廖沁旋"},
{"y":"2018","id":"121","hmw":"我們如何能夠改善珠寶首飾材料取得與生產過程中的環境污染？","members":"曾于真, 倪苑茹"},
{"y":"2018","id":"122","hmw":"如何能夠讓臺灣更廣階層/族群的民眾關注並參與當地傳統活動,進而保留這些珍貴的文化財？","members":"杜澤威, 陳昊禹"},
{"y":"2018","id":"125","hmw":"我們如何能夠從齒為國人的口腔健康做出改變？","members":"賴盈廷, 彭顓顓"},
{"y":"2018","id":"127","hmw":"我們如何能夠讓餐廳使用相對性對環境與人體傷害較低的清潔劑呢？","members":"陳名儀, 傅秋瑜"},
{"y":"2018","id":"128","hmw":"我們如何能夠加速AI普及化，協助文科生了解AI的相關應用與創造自己的價值？","members":"林宣旭, 賴薏如, 陳俊嘉"},
{"y":"2018","id":"129","hmw":"我們如何能後讓憂鬱症患者和非患者之間的關係，不再那麼緊張？","members":"毛御亘, 楊筑鈞"},
{"y":"2018","id":"131","hmw":"我們如何豐富博物館觀眾的參觀經驗？","members":"李紹鏞, 蔡欣穎"},
{"y":"2018","id":"132","hmw":"我們如何能夠制定生態旅遊標章制度來使台灣業者能維護自然並同時讓旅客更了解這片土地 ？","members":"王辰慧, 鍾逸, 郭于禎"},
{"y":"2018","id":"133","hmw":"我們如何能夠讓每個人找到自己想要關注的事？","members":"李旻, 葉雯玉, 許晏翎"},
{"y":"2018","id":"136","hmw":"我們如何能夠塑造豪放式打嗝成為有趣的形象？","members":"陳憶綸, 鄭羽彤"},
{"y":"2018","id":"137","hmw":"我們如何讓國外旅客透過輕鬆有趣的方式認識台灣景點背後的歷史和文化？","members":"殷偉珊, 郭安婷, 王敏儒"},
{"y":"2018","id":"139","hmw":"當文創口號遍佈的今日，我們如何能夠讓藝術真正的進入我們的日常生活？","members":"高翊展, 吳礽喻"},
{"y":"2018","id":"141","hmw":"我們如何能夠重新「設計」音樂課氛圍？","members":"林曉君, 許智堯"},
{"y":"2018","id":"142","hmw":"我們如何能夠幫助音樂系學生在籌備音樂會的過程中，逐步建立所需的相關能力？","members":"趙昕柔, 周佳蓁"},
{"y":"2018","id":"144","hmw":"我們如何能夠降低家人之間了解彼此的門檻？","members":"張軒綾, 周昭蓉, 許勝嵐"},
{"y":"2018","id":"145","hmw":"我們如何能夠讓小農有其專屬的管道販售他們的產品？","members":"徐若涵, 陳德冠"},
{"y":"2018","id":"146","hmw":"我們如何能夠把「飲食教育」，包裝成大眾更願意買單的商品？","members":"徐禎岑, 蔡尚宏"},
{"y":"2018","id":"149","hmw":"我們如何能夠讓社會大眾瞭解白海豚的困境，讓大家知道這片土地正在發生什麼？","members":"林冠瑩, 周苡丞, 張韶芸"},
{"y":"2018","id":"150","hmw":"我們如何能夠協助做到水果客製化，讓不同條件的水果找到適合它的主人？","members":"王馨, 李夢亭"},
{"y":"2018","id":"151","hmw":"我們如何能夠提升高中生的自我探索能力？","members":"賴怡諄, 陳智翔, 周冠妤"},
{"y":"2018","id":"152","hmw":"我們如何能夠讓部落學童感受到長者的智慧，並與長者創造共同的回憶？","members":"李旻佑, 黃瀞鋐, 楊姍燁"},
{"y":"2018","id":"153","hmw":"我們如何能夠讓年輕人勇於跳出舒適圈？","members":"鄭尹臻, 陳郁蓁"},
{"y":"2018","id":"154","hmw":"我們如何能夠讓長者和外界有連結？","members":"吳姿樺, 吳承哲, 黃筑萱"},
{"y":"2018","id":"155","hmw":"我們如何能讓職場上的年輕人有更多認識自己、參與社會議題的機會，從中建立自信和成就感？","members":"游涵茹, 郭品妤"},
{"y":"2018","id":"156","hmw":"我們如何能夠讓社會大眾認識理解不同性傾向性別特質的人？","members":"黃岑湲, 鄭涵文"},
{"y":"2018","id":"157","hmw":"我們如何能夠讓台灣更多的人有正確的理財觀念，讓他們自學理財的時候不會誤入歧途？","members":"趙寬敏, 蔡沛軒"},
{"y":"2018","id":"158","hmw":"我們如何能讓平溪、十分老街的店家不只是販售天燈，而有更多結合當地特色的經濟發展？","members":"張蘋, 吳華琳, 董寧"},
{"y":"2018","id":"160","hmw":"我們如何能夠從在地、教育等面向出發，摘除高雄「文化沙漠」的標籤，找出高雄特色？","members":"郭子瑋, 王煒雯, 王怡臻"},
{"y":"2018","id":"161","hmw":"我們如何讓高中生們正視自己意願的重要性？","members":"黃瑞怡, 黃詩芸"},
{"y":"2018","id":"162","hmw":"我們如何協助長者們健康並且有尊嚴的生活？","members":"侯松佑, 侯彥竹"},
{"y":"2019","id":"006","hmw":"我們如何能夠讓人提升自我覺察、持續探索並認識自己？","members":"方軾妤, 王瑞儀"},
{"y":"2019","id":"017","hmw":"我們如何能夠減少飲料杯的使用量？","members":"孫麗, 陳德馨"},
{"y":"2019","id":"019","hmw":"我們如何能夠增加中文系學生的自信？","members":"楊詠筑, 吳啟豪"},
{"y":"2019","id":"020","hmw":"我們如何能夠讓新住民和移工獲得更多就業資訊？","members":"符嘉尹, 羅弘杰, 李孟一"},
{"y":"2019","id":"021","hmw":"我們如何能夠提高台灣人的垃圾分類認知？","members":"吳羿穎, 黃柏禎"},
{"y":"2019","id":"022","hmw":"我們如何能夠促進社會住宅周邊鄰里增加對社宅的認識，消彌因陌生感產生的衝突與誤解？","members":"呂欣庭, 賴婷姿"},
{"y":"2019","id":"023","hmw":"我們如何能夠提升台灣父母的英文共讀意願？","members":"王偉晴, 余珂"},
{"y":"2019","id":"025","hmw":"我們如何有效提升大眾對思覺失調症的認知和正名意義？","members":"吳意晴, 林憶沁, 童紫宣"},
{"y":"2019","id":"026","hmw":"我們如何能夠讓大家意識到環保的重要性，並且做出持續行動？","members":"劉姿劭, 廖淳琳, 莊孟婕"},
{"y":"2019","id":"027","hmw":"我們如何能夠幫助已經有初步未來目標的高中生，了解並規劃適合自己的方向？","members":"鄭柏軒, 杜亞訊, 張立翔"},
{"y":"2019","id":"028","hmw":"我們如何能降低民眾查找公眾運輸票卷系統釋出車票的時間成本，提高公共運輸系統的利用率？","members":"林罡北, 洪雅筠, 孫敬"},
{"y":"2019","id":"029","hmw":"我們如何能夠讓臺灣的大學生對自己的未來有明確的方向？","members":"吳芊愛, 陳祖翎"},
{"y":"2019","id":"030","hmw":"我們如何能讓自學者找到同一條路上的夥伴？","members":"李尚恩, 童宇陞"},
{"y":"2019","id":"031","hmw":"我們如何能夠讓台灣的民眾瞭解動物是生命不只是食物？","members":"劉曉帆, 何宜臻"},
{"y":"2019","id":"032","hmw":"我們如何重新建立世代間的信任感以提升橫向溝通的意願？","members":"丁琳, 江育萱, 吳端容"},
{"y":"2019","id":"033","hmw":"我們如何讓更多人主動認識原住民部落的真實面？","members":"王芸潔, 吳辰恩, 張祖維"},
{"y":"2019","id":"034","hmw":"我們如何能夠讓人們聚集、凝結，組織社區共好網絡？","members":"邱于真, 林宜萱"},
{"y":"2019","id":"035","hmw":"我們如何能夠減少花材一次性浪費，並創造新價值？","members":"李承蔚, 陳郁婕"},
{"y":"2019","id":"036","hmw":"我們如何讓擔心未來，但找不到資源和方法的的大學生，願意嘗試與付出行動來探索？","members":"潘信達, 余哲覟, 許翔傑"},
{"y":"2019","id":"037","hmw":"我們如何能夠在都市裡，重新與自然建立連結？","members":"山夢嫻, 賴彥廷"},
{"y":"2019","id":"039","hmw":"我們如何能夠幫助青年根據自己的條件做出有效的生涯選擇評估？","members":"翁儷庭, 鍾明倢, 鄭昕宜"},
{"y":"2019","id":"041","hmw":"我們如何能夠運用聲音讓人們親近自然？","members":"郭昱均, 邱育淇, 陳鈞儀"},
{"y":"2019","id":"042","hmw":"我們如何能夠讓民主走進生活，讓「更好的社會」不在他方，而就在每個當下的對話與連結裡？","members":"吳孟軒, 鍾儀, 簡靖慈"},
{"y":"2019","id":"043","hmw":"我們如何能強化民眾垃圾分類的習慣與行動力？","members":"鄭俞鈞, 曾詠恬"},
{"y":"2019","id":"044","hmw":"我們如果能夠讓台灣自身的廢棄漁網轉化新生？","members":"張哲源, 吳佐恩"},
{"y":"2019","id":"045","hmw":"我們如何能夠排解年輕人的憂愁？","members":"施建同, 林倢妤, 陳芊妤"},
{"y":"2019","id":"047","hmw":"我們如何能夠解決社會新鮮人進入職場的不適應？","members":"岑家忻, 林怡瑩"},
{"y":"2019","id":"048","hmw":"我們如何能夠改善親子溝通的模式，以利於找到家庭的共識？","members":"梁祐祥, 楊紫晴"},
{"y":"2019","id":"050","hmw":"我們如何能夠讓容易緊張焦慮的人試圖放鬆？","members":"戴依萍, 邱奕馨"},
{"y":"2019","id":"051","hmw":"我們如何能夠解決大學生之間同溫層的問題？","members":"唐珮庭, 易采蝶, 戴偉傑"},
{"y":"2019","id":"052","hmw":"我們如何能夠重現雜貨店帶給村子的生命力，以及帶給人的感動？","members":"王如璇, 唐偉倫"},
{"y":"2019","id":"053","hmw":"我們如何能夠解決流浪動物的問題？","members":"林馨融, 朱佳恩, 詹紫綺"},
{"y":"2019","id":"054","hmw":"我們如何能夠讓不同溫層的人彼此對話理解？","members":"石慧婷, 侯怡安, 李佩璇"},
{"y":"2019","id":"056","hmw":"我們如何能夠為受刑人和更生人在與社會之間建立一座可以相互溝通與理解的橋梁？","members":"朱鴻埕, 蔡妙涵"},
{"y":"2019","id":"057","hmw":"我們如何能夠減少台灣外送美食一次性餐盒的使用量？","members":"邱以慈, 簡妙珊"},
{"y":"2019","id":"058","hmw":"我們如何能夠讓台灣人親近海，意識到我們身在海中央？","members":"謝瑞彬, 王甄薇, 陳思儀"},
{"y":"2019","id":"059","hmw":"我們如何能夠讓理財與記帳的正確觀念，藉由融入日常生活的方式，達成改善社會問題之目的？","members":"黃煥升, 劉筠楷"},
{"y":"2019","id":"060","hmw":"我們如何能夠讓藝術治療更普及化來陪伴大眾生活？","members":"石婷伃, 陳翰諹, 王維琪"},
{"y":"2019","id":"061","hmw":"我們如何能夠讓憂鬱症患者之陪伴者學會處理自身情緒，並找到與患者間最理想的互動模式？","members":"葉樺暄, 楊品瑜, 呂心怡"},
{"y":"2019","id":"062","hmw":"我們如何讓大家放下手機，多與周遭的人互動？","members":"陳宴馨, 江昱萱"},
{"y":"2019","id":"063","hmw":"我們如何能夠讓機車族真正遠離 PM2.5 的危害？","members":"陳定隆, 廖堉盛, 郭智成"},
{"y":"2019","id":"064","hmw":"我們如何能夠收集在地長者的故事、資料、照片來真正的呈現在地特色？","members":"麻青聖, 王閔"},
{"y":"2019","id":"065","hmw":"我們如何能夠復興臺灣的傳統手作技藝？","members":"鍾語謙, 潘家安"},
{"y":"2019","id":"066","hmw":"我們如何能夠讓每個聲音都被好好傾聽並給予溫柔的回饋？","members":"鄭惟謙, 陳韋彤"},
{"y":"2019","id":"067","hmw":"我們如何能夠讓年輕族群更了解國樂，翻轉國樂傳統的刻板印象？","members":"羅婉綾, 歐謹華, 李宜蓁"},
{"y":"2019","id":"068","hmw":"我們如何拉近鄰居（社區）之間的距離？","members":"李欣儒, 謝治成, 沈欣儀"},
{"y":"2019","id":"071","hmw":"我們如何能夠幫助高中生審視在社團活動中獲得的成就與寶貴經驗，並將之延續？","members":"張耀倫, 林楷翰"},
{"y":"2019","id":"073","hmw":"我們如何能夠提升雨天的室內體驗？減輕帶雨傘出門的負擔？解決攜帶雨具所造成的不便？","members":"余采玲, 李珞懿, 柯竣嚴"},
{"y":"2019","id":"074","hmw":"我們如何能夠讓面臨挫折與沮喪的青年能夠重新找到快樂的方法？","members":"游焰熾, 卓恩合, 陳韻年"},
{"y":"2019","id":"076","hmw":"我們如何能夠在執行食農教育同時，讓民眾產生認知及行為上的改變？","members":"張澤豪, 許宸睿"},
{"y":"2019","id":"077","hmw":"我們如何能夠減少從學生變社畜的不適應？","members":"黃郁倫, 林柔安, 張芳綺"},
{"y":"2019","id":"078","hmw":"我們如何能夠消弭臺灣女性無家者因月經貧窮所造成的困境？","members":"李品璇, 謝怡璇, 林薇"},
{"y":"2019","id":"079","hmw":"我們如何能夠讓租房子真正透明化，有效捍衛房客的權利？","members":"戴欣蕾, 李大順"},
{"y":"2019","id":"080","hmw":"我們如何能夠有效地改善充斥著網路霸凌的社會？","members":"田少山, 石家瑋"},
{"y":"2019","id":"081","hmw":"我們如何能夠讓台灣民眾在 2020 總統大選做出理性的決定？","members":"楊景翔, 楊宜華"},
{"y":"2019","id":"082","hmw":"我們如何能夠使民眾真正落實一次性用品減量行動？","members":"蕭怡萍, 姚芮安"},
{"y":"2019","id":"083","hmw":"如何讓老人一目瞭然公車搭乘與路線？","members":"林劭懿, 吳嘉恩, 梁雅禎"},
{"y":"2019","id":"084","hmw":"我們如何能夠讓非行青少年重返社群？","members":"邱奕醇, 陳韋志, 成瑋盛"},
{"y":"2019","id":"086","hmw":"我們如何能夠確實實踐教育資源均勻分配的理想？","members":"邱嘉羚, 黃炯中"},
{"y":"2019","id":"087","hmw":"我們如何能解決年輕人的兩性與人際問題？","members":"吳威麟, 刁一凡"},
{"y":"2019","id":"088","hmw":"我們如何能夠讓臺灣的媒體減少錯誤訊息的傳播？","members":"許瑞卿, 陳秀蓉"},
{"y":"2019","id":"089","hmw":"我們如何能夠改善「考上好學校」即「標準答案」的單一價值觀？","members":"丁奕均, 李沁璇"},
{"y":"2019","id":"090","hmw":"我們如何能夠協助長期租屋族在能夠負擔的成本下達到良好生活品質的租屋環境？","members":"黃炳曄, 張家甄"},
{"y":"2019","id":"092","hmw":"我們如何能夠將腳踏車的安全性提昇，同時減少意外的發生？","members":"蔡長霖, 林頎, 簡宜信"},
{"y":"2019","id":"094","hmw":"我們如何能夠讓 18-24 歲的年輕人在餐桌上不尷尬地討論起政治議題？","members":"林柏均, 黃語婕, 鄧欣容"},
{"y":"2019","id":"095","hmw":"我們如何能夠有效提升回收玻璃導入產品實用性及普及度，使其二次價值能更好發揮出來？","members":"陳棣, 顧芸熙, 關穎生"},
{"y":"2019","id":"096","hmw":"我們如何能夠讓人體驗到與自然接觸的優點？","members":"蔡孟宏, 劉筱涵, 劉長青"},
{"y":"2019","id":"097","hmw":"我們如何能夠讓大眾或是對護理感興趣的學生更加了解其發展出路、遭遇困境與迷思破除？","members":"丁瓘媛, 陳建達, 黃逸庭"},
{"y":"2019","id":"098","hmw":"我們如何用議題遊戲解決食物浪費的問題？","members":"許博雅, 高郁晴, 丁偉翔"},
{"y":"2019","id":"099","hmw":"我們如何能夠提升民眾對於原生植物的認知與覺察？","members":"葉芷婷, 陳家豪"},
{"y":"2019","id":"100","hmw":"我們如何能夠透過海洋教育提升國人對於環境意識的提升？","members":"蔡承華, 鄭博元"},
{"y":"2019","id":"101","hmw":"我們如何能夠從非負面／不同的／創新的視角，觀看／評價／思考出軌這件事？","members":"莊琇雯, 白家瑞"},
{"y":"2019","id":"102","hmw":"我們如何能夠幫助大學生找到足以獻身的目標？","members":"楊博丞, 李家泓"},
{"y":"2019","id":"103","hmw":"我們如何能夠有效解決創作授權的爭議，提昇保護著作權並增加著作在利用的可能性？","members":"吳柏礽, 吳礽喻"},
{"y":"2019","id":"104","hmw":"我們如何能真正打破異溫層之間的隔閡，進行一個既跨性別也跨時代的溝通？","members":"陳瑄蕙, 高菲, 陳嘉萱"},
{"y":"2019","id":"105","hmw":"我們如何能夠提升原住民部落的小孩對於文化傳統的參與度？","members":"簡怡岑, 劉紹萱, 楊純宜"},
{"y":"2019","id":"107","hmw":"我們如何能夠讓人們能辨識訊息，知道該如何理解資訊，並採取合適的行動達到有效溝通？","members":"蘇紫雯, 陳彥妏"},
{"y":"2019","id":"109","hmw":"我們如何讓社會大眾了解保險也是隨著時代改變且在自己人生不同階段都需檢視規劃的商品？","members":"黃紹華, 邱翊瑋"},
{"y":"2019","id":"110","hmw":"我們如何能夠建立民主教育的支持體系和生態圈？","members":"楊逸頎, 邱品昱, 沈潔伃"},
{"y":"2019","id":"111","hmw":"我們如何能夠帶身心障礙者（家庭）走出家門？","members":"羅元佐, 陳品鈞, 羅蕙心"},
{"y":"2019","id":"112","hmw":"我們如何能夠打破社會的束縛，教育體制的枷鎖，讓人們學自己所想，擇自己所愛？","members":"闕玉欣, 郭晏瑜"},
{"y":"2019","id":"113","hmw":"我們如何能夠改變校園霸凌事件中沉默的多數？","members":"柯廷歡, 簡致宏"},
{"y":"2019","id":"114","hmw":"我們如何能夠讓異溫層之間溝通時，減少不理性的表現，達到溝通真正的目的？","members":"蔡逸泓, 洪于雯"},
{"y":"2019","id":"115","hmw":"我們能夠如何為精神疾病去污名？","members":"美月, 許詠淇, 陳珮緹"},
{"y":"2019","id":"116","hmw":"我們如何能夠協助小農得到合適的行銷資源？","members":"吳憶茹, 顏琪"},
{"y":"2019","id":"118","hmw":"我們如何讓獨居的長輩能吃得健康與營養？","members":"陳禹心, 石佳蓉"},
{"y":"2019","id":"119","hmw":"我們如何能夠讓生涯探索活動除了追求生計外，還能進一步探究自身價值觀以及夢想生活狀態？","members":"陳揚名, 陳思諪"},
{"y":"2019","id":"121","hmw":"我們如何能夠培養出獨特的地方感並創造出地方價值？","members":"盧柏翰, 羅凱文"},
{"y":"2019","id":"122","hmw":"我們如何能夠讓網球這項運動更友善更能連結彼此？","members":"廖貫竹, 何易展, 蔡承良"},
{"y":"2019","id":"124","hmw":"我們如何能夠提升因電動車興起的用路安全？","members":"顧芸熙, 陳棣, 韓詠而"},
{"y":"2019","id":"126","hmw":"我們如何能夠讓民眾有感地去關注和實踐自己及家人的飲食均衡與疑慮抉擇呢？","members":"鄭秉漢, 陳嘉俊, 簡郁璇"},
{"y":"2019","id":"127","hmw":"我們如何能夠重建人們與媒體之間的信任關係？","members":"李昀倢, 高虹均, 朱峻廷"},
{"y":"2019","id":"128","hmw":"我們如何能夠打破議題的邊界，集結更多社會力量一同解決社會問題？","members":"蔡沛姍, 簡毓萱"},
{"y":"2019","id":"129","hmw":"我們如何能夠讓服務性營隊切合服務對象需求？","members":"蔡珮歆, 蔡采吟, 廖子齊"},
{"y":"2019","id":"130","hmw":"我們如何能夠使一個涉及多個領域的專業問題在短時時間內獲得專業人士的真正解答？","members":"李惟慈, 陳怡蓁, 姚韋辰"},
{"y":"2019","id":"131","hmw":"我們如何能在夜間幫助需要注射藥品的病患度過難關？","members":"嚴毓欣, 邵紫婷"},
{"y":"2019","id":"132","hmw":"我們如何能夠讓有能力做選擇的消費者，有意識地做出對環境與社會相對有益的選擇？","members":"黃奕嘉, 曹旭宏"},
{"y":"2019","id":"133","hmw":"我們如何能夠讓臺灣的學生在工作中找到喜歡的事並認為它有所價值？","members":"林佳儀, 徐慧安"},
{"y":"2019","id":"134","hmw":"我們如何能夠讓大眾重視垃圾分類問題且確實執行回收？","members":"林紫彤, 邱芳鈺"},
{"y":"2019","id":"135","hmw":"我們如何讓青年參與農業？","members":"蔡昀珊, 王冠筑, 王賢慈"},
{"y":"2019","id":"136","hmw":"我們如何能降低傳統市場中塑膠袋的使用率？","members":"徐浩洋, 蘇泳銓"},
{"y":"2019","id":"137","hmw":"我們如何能讓女性傾聽自己的天賦，進而對自身的價值更有自信、更活出自己的樣子？","members":"曹伊裴, 吳思璇"},
{"y":"2019","id":"138","hmw":"我們如何能夠幫助空巢期的父母以及爺爺奶奶重新找回生活的重心？","members":"蕭仲恩, 林鼎惟"},
{"y":"2019","id":"139","hmw":"我們如何能夠讓社會大眾理解聾人/聽障的溝通特質達成溝通無障礙目標？","members":"鄭照斌, 林哲瑩, 涂佩瑜"},
{"y":"2019","id":"140","hmw":"我們如何能夠透過「整理」，幫助大家反思自己與物的連結，以及更加瞭解自己生活的樣貌？","members":"周家楹, 柯穎吟, 楊芷渝"},
{"y":"2019","id":"141","hmw":"我們如何能夠讓癮君子既能夠在滿足吸菸的需求，同時讓菸頭不在散落在城市的角落呢？","members":"姜遠良, 吳晨隆"},
{"y":"2019","id":"142","hmw":"我們如何能夠減少臺灣的生產端至消費端的剩食？","members":"熊才誠, 相宇欣, 簡子策"},
{"y":"2019","id":"143","hmw":"我們如何能夠找到理想的伴侶？","members":"周穎, 林韻如"},
{"y":"2019","id":"144","hmw":"我們如何能夠讓願意服務的大學生真正認識地方，進而透過自己的專長設計適合的地方服務？","members":"謝函妤, 汪中安"},
{"y":"2020","id":"027","hmw":"我們能夠讓咖啡渣落實再生循環經濟，並達到永續零廢棄的生產目標？","members":"朱紅溱, 沈家銘"},
{"y":"2020","id":"029","hmw":"我們如何能夠改善不健康的審美和 BodyShaming 文化並重新愛上我們的身體？","members":"李孟璟, 黃浩瑋"},
{"y":"2020","id":"030","hmw":"我們如何能夠讓台灣的獨居老人了解附近的「逃生路線」？","members":"李祥樂, 江汶叡"},
{"y":"2020","id":"032","hmw":"我們如何能夠讓台灣的國高中生在在學期間就接軌社會並發現自我價值？","members":"吳姿儀, 何懿哲, 賴澤霖"},
{"y":"2020","id":"033","hmw":"我們如何能讓臺灣的年輕人與家人開啟對話、促進溝通？","members":"陳世芃, 蕭子喬, 馮治豪"},
{"y":"2020","id":"034","hmw":"我們如何讓社會住宅實踐社會福利功能？","members":"楊雅晴, 章美惠"},
{"y":"2020","id":"036","hmw":"我們如何能夠重新看待我們的文化，換個方式持續延續下去？","members":"張舒晴, 吳守智"},
{"y":"2020","id":"039","hmw":"我們如何能夠協助孩童透過對話練習學會探索自我與人際溝通？","members":"鍾珮倫, 張依妍"},
{"y":"2020","id":"042","hmw":"我們如何能夠優化現有的倒垃圾方式？","members":"蔡旻錫, 吳嘉恩, 蔡竟恆"},
{"y":"2020","id":"043","hmw":"我們如何能夠與偏鄉小農一起共創在地特色？","members":"陳映辰, 莊凱詠, 黃瓈葳"},
{"y":"2020","id":"046","hmw":"我們如何能夠幫助青年將焦慮轉為有效的成長？","members":"王偲涵, 張云慈"},
{"y":"2020","id":"047","hmw":"我們如何能讓更多人看見器官捐贈的重要性，並且認同、願意參與？","members":"楊喻茹, 易采蝶"},
{"y":"2020","id":"049","hmw":"我們如何能夠讓社區的年輕人從小建立地方的認同感，讓他們多多參與社區事務？","members":"蔡念琦, 林信易, 王詩雲"},
{"y":"2020","id":"050","hmw":"如何讓有才華的表演新星，能有一展長才並持續成長的空間？","members":"吳何, 陳冠霖"},
{"y":"2020","id":"051","hmw":"我們如何能夠讓台灣人關注國際新聞？","members":"賴韻筑, 洪子芸"},
{"y":"2020","id":"055","hmw":"我們如何能夠讓人才穩定注入在部落裡面？我們如何能夠不斷的更新新資訊及技術？","members":"張薇恩, 曾敏銳, 董聖華"},
{"y":"2020","id":"056","hmw":"我们如何能夠營造一個環境使父母與小孩能夠心平氣和地有效溝通？","members":"黃宇萱, 黃愉芳, 黃展昱"},
{"y":"2020","id":"057","hmw":"我們如何讓臺灣教育體制下的學生能在沒有社會期望壓力下，將分數看淡，快樂學習？","members":"李柏儒, 洪振嘉, 蘇錡"},
{"y":"2020","id":"059","hmw":"我們如何能夠減少馬拉松造成的垃圾污染？","members":"余昀蓁, 楊捷昀, 沈鈺真"},
{"y":"2020","id":"060","hmw":"我們如何能夠改變高中生和大學生不健康的生活作息？","members":"翁龍玉, 蔡欣怡"},
{"y":"2020","id":"061","hmw":"我們如何能夠改正主流A片當中錯誤的「性」刻板印象？","members":"呂澐謙, 蘇揚"},
{"y":"2020","id":"062","hmw":"我們如何能夠讓臺灣的傳統養殖業、捕撈漁業，以產業創新方式，呈現新價值？","members":"趙俊傑, 郭珮蓉"},
{"y":"2020","id":"064","hmw":"我們如何導正不好的老闆或讓它消亡？","members":"古瀞荃, 郭晉楓"},
{"y":"2020","id":"065","hmw":"我們如何能讓事業廢棄物不再繼續污染環境？","members":"張婉真, 張佳晉, 汪叡聖"},
{"y":"2020","id":"067","hmw":"我們如何讓身心疾病的照顧者（陪伴者）有意識的調適壓力，改善生活品質？","members":"陳韋廷, 陳亭羽"},
{"y":"2020","id":"068","hmw":"我們如何能夠  協助退休的年長者找到生活中讓他們感興趣的事物？","members":"蔡小童, 林劭學"},
{"y":"2020","id":"069","hmw":"我們如何能夠提升偏鄉孩童的競爭意識，進而縮小與都市孩童間的差距？","members":"林欣慧, 鄭淯文"},
{"y":"2020","id":"070","hmw":"我們如何讓民眾簡單獲得正確實用的醫學知識？","members":"葉宗儒, 盧貞宇, 呂俊叡"},
{"y":"2020","id":"071","hmw":"我們如何能夠讓人們在吃飯前能想到洗手，並認真的用肥皂清洗乾淨？","members":"陳佳妤, 陳佳妤, 呂采蓁"},
{"y":"2020","id":"072","hmw":"我們如何能夠幫助人們因應情緒與心理壓力？","members":"鄭婷如, 韓岳樺, 羅心雅"},
{"y":"2020","id":"073","hmw":"我們如何能夠改善外食族蔬果攝取量不足的問題？","members":"林芷婕, 黃品禎, 林彥甫"},
{"y":"2020","id":"075","hmw":"我們如何讓更多人有機會認識台灣原住民族泛靈/祖靈信仰、巫醫祭儀等的傳統文化療癒？","members":"胡醴云, Hana"},
{"y":"2020","id":"079","hmw":"我們如何讓精神疾病患者身邊的人願意接觸並用正確的方式對待患者？","members":"蔡縈綸, 王柔惠, 徐薪淳"},
{"y":"2020","id":"083","hmw":"如何讓知道孩子是同性戀的父母，減少對同性戀的排斥感，與孩子保持良善真誠的溝通？","members":"蕭淳予, 李芳詡, 孫熙辰"},
{"y":"2020","id":"085","hmw":"我們如何能夠讓台灣的海洋生態保育得到台灣人的重視？","members":"鍾依靜, 劉家瑀, 朱維臻"},
{"y":"2020","id":"086","hmw":"如何能夠讓居民透過適合自己的方式來喚起對於在地文化的意識？","members":"呂旻融, 陳良昆"},
{"y":"2020","id":"090","hmw":"我們如何能夠讓更多青年關注聯合國永續發展目標並且呢在生活中實踐這些目標呢？","members":"李玉晴, 林佑宣"},
{"y":"2020","id":"092","hmw":"我們如何減少便當的食物浪費同時讓消費者吃得更健康？","members":"廖奕婷, 黃羽彤"},
{"y":"2020","id":"094","hmw":"我們如何能夠讓新手教師得到足夠的支持？","members":"李振弘, 楊舒婷, 陳禹蓁"},
{"y":"2020","id":"095","hmw":"如何拉近城市中人的距離？","members":"朱曼綾, 林佑宣, 李玉晴"},
{"y":"2020","id":"096","hmw":"如何輔助學生在現有資源下找到屬於自己的讀書方式？","members":"黃婉婷, 郭旌維"},
{"y":"2020","id":"097","hmw":"我們如何讓民眾養成習慣辨別每則新聞的品質？","members":"陳星穎, 張聖雯, 謝函妤"},
{"y":"2020","id":"098","hmw":"我們如何能夠讓高關懷青少年走回正軌，融入社會？","members":"曹瑋, 吳昕愷"},
{"y":"2020","id":"099","hmw":"我們如何讓東港鎮的延平老街重新活絡起來？","members":"鄭文宏, 施怡均"},
{"y":"2020","id":"100","hmw":"我們如何能夠讓年輕人知道自己吃的餐點中的成分是什麼","members":"曾逸萱, 朱品瑜, 張惠婷"},
{"y":"2020","id":"101","hmw":"用現代人喜愛的方式重新讓傳統糕點產業重生","members":"李昭嫻, 吳致萱, 陳怡妏"},
{"y":"2020","id":"102","hmw":"我們如何能夠使青年（高中生）找到適合自己的學習方式？","members":"王宥筌, 吳俊毅"},
{"y":"2020","id":"103","hmw":"我們如何能夠協助提高情緒障礙患者的病識感？","members":"吳殊慧, 范庭安, 馬欣寧"},
{"y":"2020","id":"104","hmw":"我們如何能夠讓民眾重新思考現場互動的可能，避免被手機替代或者是控制？","members":"黎偉翰, 黃渝棉"},
{"y":"2020","id":"105","hmw":"我們如何能夠讓部落文化健康站的健康促進服務更貼近族群文化？","members":"蔣靜怡, 黃奕芸"},
{"y":"2020","id":"106","hmw":"我們如何引導與協助高中生在選組選系時進行生涯探索？","members":"鄭竣業, 王安珩, 黃家安"},
{"y":"2020","id":"107","hmw":"我們如何能讓獨立工作者持有自制力完成工作？","members":"張力堃, 林芸如, 林芸萱"},
{"y":"2020","id":"108","hmw":"我們如何能夠幫助接觸基督信仰的人，在性別議題上找到自身在教義與時代思潮中的認同？","members":"高至宣, 陳庭筠"},
{"y":"2020","id":"109","hmw":"我們如何能夠讓大學生了解自我健康管理的重要？","members":"林建豪, 鍾坤翰, 張宣綸"},
{"y":"2020","id":"110","hmw":"我們如何能夠讓國中生習慣主動思考，並找出自己想過的人生？","members":"陳希涵, 林資堯, 黃彥瑋"},
{"y":"2020","id":"113","hmw":"我們如何協助女性突破社會期待的限制，以發掘真正想成就的自我？","members":"詹涵睿, 林彤"},
{"y":"2020","id":"115","hmw":"我們如何能夠協助高中畢業後無所事事（不想升學和工作）的青年找出方向？","members":"林書卉, 陳貞似"},
{"y":"2020","id":"116","hmw":"我們如何能夠透過思辨來告訴高中孩子思考過程的重要性？","members":"沈芸涓, 禇懿萱"},
{"y":"2020","id":"117","hmw":"我們如何能夠協助新青年進入農村？","members":"龔哲敬, 莊庭維, 邱子濂"},
{"y":"2020","id":"118","hmw":"我們如何能夠減少流浪狗數量？","members":"郭惠妮, 洪嘉娸"},
{"y":"2020","id":"119","hmw":"我們如何能夠在現有大學制度下盡可能減少學歷貶值所造成的影響","members":"簡華君, 葉承鑫, 施宣奇"},
{"y":"2020","id":"120","hmw":"我們如何能夠協助青年找到並認同自我價值？","members":"謝心俞, 沈子倢, 胡乃文"},
{"y":"2020","id":"121","hmw":"我們如何能夠跳脫功利的教育體制，建立公平適切的學習環境，以降低青年對未來的焦慮？","members":"蔡明瑜, 吳易儒, 林晏廷"},
{"y":"2020","id":"122","hmw":"如何讓中低學習信心的學生，重拾學習好奇心以定位自我價值，更有能力去做生涯選擇？","members":"邱威辰, 沈于晴, 洪詩涵"},
{"y":"2020","id":"123","hmw":"我們如何建構促進公共議題討論之平台，營造尊重友善的環境，增進溝通，凝聚共識？","members":"簡立恆, 林沛璇, 莊智程"},
{"y":"2020","id":"124","hmw":"我們如何將正確「教育知識」給學生與家長，改善台灣補習盛行現象？","members":"高嘉蔚, 高振翔"},
{"y":"2020","id":"125","hmw":"我們如何能夠讓象徵著台灣傳統文化及工藝的三合院繼續保存、傳承下去？","members":"高綺涓, 吳宗霖, 游勝宏"},
{"y":"2020","id":"126","hmw":"我們如何能夠讓這些品質很好的布料廢棄物被丟棄前，進行更有價值的利用？","members":"譚振中, 楊竣皓, 余芷憶"},
{"y":"2020","id":"128","hmw":"我們如何能夠讓社會大眾用同理的角度看待精神疾患？","members":"戴韻涵, 劉佩琳, 陳育心"},
{"y":"2020","id":"132","hmw":"我們如何能夠讓臺灣人增加本土語言的使用？","members":"周廷翰, 黃一峻"},
{"y":"2020","id":"134","hmw":"如何在連結有需求的學生及心輔資源？","members":"曹孟芩, 吳嘉禧"},
{"y":"2020","id":"135","hmw":"如何提高大專生的公共政策參與率？","members":"洪瑞隆, 白凱媞, 吳灃晉"},
{"y":"2020","id":"136","hmw":"如何讓想透過實體交友的人能夠找到符合自身需求的機制，跳脫目前以結婚為前提的制式？","members":"蔡宥杏, 蔡宥潔, 陳佳儀"},
{"y":"2020","id":"138","hmw":"我們如何協助臺灣年輕人認識勞動性質產業、技工，並讓更多新血投入逐漸沒落的產業","members":"陳柏宣, 羅健銘, 施怡慧"},
{"y":"2020","id":"139","hmw":"我們如何能夠教育當地（雲林四湖鄉）民眾友善環境？","members":"盧彥綺, 盧品渝"},
{"y":"2020","id":"140","hmw":"我們如何能夠幫助大學生排解心理壓力？","members":"徐瑋慈, 陳凱馨, 陳盈帆"},
{"y":"2020","id":"142","hmw":"我們如何能夠推動大學學區租借站，組成環保餐盒聯盟，減少大量一次性餐盒使用？","members":"李佳佳, 方思渝"},
{"y":"2020","id":"143","hmw":"我們如何能夠讓時下青年願意主動了解並關心地方社會議題？","members":"王湘婷, 陳姿螢"},
{"y":"2020","id":"144","hmw":"我們如何能夠落實自殺防治專線的功能？","members":"劉家蓉, 顏依莎"},
{"y":"2020","id":"145","hmw":"我們如何能夠在不引響外送產業發的前提下，減少因外送而產生的一次性免洗餐具被使用？","members":"許睿哲, 趙冠淇, 黃育鴻"},
{"y":"2020","id":"146","hmw":"我們如何能夠協助現代父母以適切的方式陪伴小孩？","members":"陳俐璇, 林昕瑤"},
{"y":"2020","id":"147","hmw":"我們如何讓被淘汰的傢俱找到新的被利用的機會，重新找到物品的價值與產生循環的可能？","members":"劉芃汝, 陳劭恩, 余孟翰"},
{"y":"2020","id":"148","hmw":"如何幫助長輩在與次世代溝通的過程中，有效表達自己真實的感受？","members":"宋采洵, 林迦仁, 石文佳"},
{"y":"2020","id":"149","hmw":"如何幫助有意求職或轉換職涯者確立方向、建立信心與積極做出行動？","members":"郟彩君, 林幸秀, 王婷萱"},
{"y":"2020","id":"150","hmw":"我們如何能夠協助大學生及職場新鮮人擁有正向的生活態度、與情緒上的滿足？","members":"呂俐, 黃驛惠, 陳采昀"},
{"y":"2020","id":"151","hmw":"我們如何能夠使家長認同「放手讓孩子探索職涯」比追求成績、學歷更重要？","members":"高曼瑄, 楊芮華, 鍾婷"},
{"y":"2020","id":"152","hmw":"我們如何創造讓青少年與自我對話的機會？","members":"黃筠雅, 陳宥亘, 張舒植"},
{"y":"2020","id":"153","hmw":"我們如何能夠探討酒癮背後的心理因素，協助酒精成癮者戒酒並幫助其家庭走出難關？","members":"方郁婷, 林志育, 蔡少涵"},
{"y":"2020","id":"154","hmw":"我們如何使大眾更加認識精神疾病及同理精神疾患面對病情的壓力？","members":"陳阡予, 陳思妤"},
{"y":"2020","id":"155","hmw":"我們如何能夠增加人們與暗夜星空的連結，讓人們願意共同守護暗夜星空 ？","members":"黃仲甫, 許舒安, 李芝瑩"},
{"y":"2020","id":"157","hmw":"我們如何在社會對於「成功」的樣板價值下，幫助大學生講出自身經驗，並處理自身感受？","members":"曾舜英, 彭宣玶, 胡睿竹"},
{"y":"2020","id":"159","hmw":"我們如何創造一個能互相理解、多元文化的共融圈？","members":"蕭蕙心, 陳靖宜"},
{"y":"2020","id":"160","hmw":"我們如何能夠彌平南北學生的資源差異？","members":"林欣汝, 洪嘉謙, 陳思穎"},
{"y":"2020","id":"161","hmw":"我們如何能夠創造更環保的臺灣廟會？","members":"許青純, 郭怡均, 楊佳臻"},
{"y":"2020","id":"162","hmw":"我們如何讓無國籍的孩子獲得更完善的生活保障（教育、醫療上）？","members":"童筑君, 黃敏瑜, 張振宇"},
{"y":"2020","id":"163","hmw":"我們何提供偏遠地區國小教師更好的支持，以提升或延續教師動能與熱情？","members":"謝馥伊, 張容誠, 胡凱婷"},
{"y":"2020","id":"164","hmw":"我們如何能創造更具發展性的 NPO 工作環境？","members":"盛子芸, 郭妍希"},
{"y":"2020","id":"165","hmw":"我們如何能夠讓每一個大人在任何時刻都可以定義自己「大人的樣子」？","members":"布子如, 林玟秀, 王巧文"},
{"y":"2020","id":"166","hmw":"我們如何能夠讓臺灣外觀上看不出具有身心障礙身分的人保有隱私同時又能使用無障礙設施？","members":"楊崴晴, 鄒凱宇"},
{"y":"2020","id":"168","hmw":"我們如何能夠讓吸菸者不會將煙蒂垃圾隨手丟棄？","members":"姜遠良, 林佑淳"},
{"y":"2020","id":"169","hmw":"我們要如何讓年輕人在現實生活中更勇敢去發表意見？","members":"林煜埕, 何明璟"},
{"y":"2020","id":"170","hmw":"我們如何能重新設計以孩子需求為出發的課後時間？","members":"宋奇勳, 易采蝶"},
{"y":"2020","id":"171","hmw":"我們要怎麼以簡單易懂的方式紀錄台灣防疫的成果，延續防疫成功所帶來的社會凝聚力？","members":"黃顥源, 黃泓叡, 楊芯霈"},
{"y":"2020","id":"172","hmw":"我們如何能夠積極預防私密影像外流呢？","members":"蔡昀珊, 傅敬倫"},
{"y":"2020","id":"173","hmw":"我們如何能夠解決青年在階段轉換中的迷惘？","members":"林怡均, 陳柔瑄, 洪婉甄"},
{"y":"2020","id":"174","hmw":"該如何讓戶外教育脫離市場價格競爭？","members":"范祐齊, 王郁傑"},
{"y":"2020","id":"175","hmw":"我們如何讓高中生願意主動去生涯探索，並因應他們的探索提供他們真正需要的資源？","members":"李昱蓉, 李伃恩"},
{"y":"2020","id":"176","hmw":"我們如何能夠讓傳統工藝使民眾產生興趣並成為休閒育樂的選擇？","members":"蕭舒帆, 傅妤欣"},
{"y":"2020","id":"178","hmw":"我們如何能夠改善對於「女性夜行權」的社會觀感？","members":"趙韋程, 顏婷玉, 吳威廷"},
{"y":"2020","id":"179","hmw":"我們如何能夠讓年輕一輩看見傳統市場的優點？","members":"盧淑君, 戴庭筠, 王瑜翎"},
{"y":"2020","id":"180","hmw":"我們如何能夠讓台灣社會（尤其是 108 課綱的中小學生）開啟認識東南亞文化的契機？","members":"李如寶, 林芳如"},
{"y":"2020","id":"181","hmw":"我們如何能夠推廣環保又能兼顧和朋友之間的友誼？","members":"黃詣晴, 曹旻"},
{"y":"2020","id":"183","hmw":"我們如何能夠降低兒少在面臨心理問題時的求助門檻？","members":"黃靜盈, 黃婉婷"},
{"y":"2020","id":"184","hmw":"如果創造特教生未來多元就業可能性？","members":"鄭茗鴻, 賴信村"},
{"y":"2020","id":"185","hmw":"我們如何能夠讓年輕人主動跨出舒適圈，認識興趣相投的同好朋友？","members":"盧映瑄, 黃澄貞"},
{"y":"2020","id":"186","hmw":"我們如何幫助適應升學教育的學生接納戲劇是一個重要的、會影響到他人生的課程？","members":"余祐萱, 陳奎碧"},
{"y":"2020","id":"188","hmw":"大學生如何能夠幫助高中生探索學習的意義、目的與價值呢？","members":"楊鎧綾, 林世峰, 賈曼伶"},
{"y":"2020","id":"190","hmw":"如何能夠減少購物所帶來的垃圾量，以及讓人們知道購物時所帶來的社會責任？","members":"鄭本源, 施佳佑"},
{"y":"2020","id":"191","hmw":"我們如何能夠讓民眾願意支持藝文產業並讓投入在藝文產業的工作者能夠有穩定的環境？","members":"湯苡菲, 陳羿含, 廖彥竹"},
{"y":"2020","id":"192","hmw":"我們如何能夠促進父執輩與年輕一代的溝通？","members":"江昀蒨, 郭子寧"},
{"y":"2020","id":"193","hmw":"我們如何能夠降低職場畢業生對於經濟與未來生活的焦慮？","members":"陳孜云, 陳怡年"},
{"y":"2020","id":"194","hmw":"我們如何能夠提升社會大眾對海洋的共感，讓國民認識海洋永續發展的重要性？","members":"張凱捷, 林安柔, 陳思穎"},
{"y":"2020","id":"195","hmw":"我們如何保存新屋在地文化與產業，並吸引青年返鄉？","members":"洪以庭, 魏鈺娟"},
{"y":"2020","id":"198","hmw":"我們如何能夠讓嬰幼兒衣服能夠有效的被使用？","members":"張芳菊, 徐兆德"},
{"y":"2020","id":"200","hmw":"我們如何讓大家找到適合自己的隊友？","members":"簡慧萱, 郭伯康, 張惜恩"},
{"y":"2020","id":"201","hmw":"我們如何能夠讓心理疾病患者多個心靈窗口,幫助他們走出內心的小房子？","members":"柯雨辰, 葉佩彣, 洪藝豪"},
{"y":"2020","id":"202","hmw":"我們如何能夠將獎學金或補助計畫分配給有能力卻缺乏學習進修資源的學生？","members":"朱祈東, 沈子軒"},
{"y":"2020","id":"203","hmw":"如何改善屏東地區因地理環境所造成的教育資源不平均？","members":"賴信村, 鄭茗鴻, 洪文宏"},
{"y":"2020","id":"204","hmw":"如何透過學生貸款（其他金融工具）的方式，建立大學生的金融思維，培養良好信用？","members":"葉烜嘉, 連佩儀, 陳沛錞"},
{"y":"2020","id":"206","hmw":"我們如何能夠幫助大學生在經過大學四年後，擁有更多歸屬感？","members":"葉念君, 童楷傑"},
{"y":"2020","id":"207","hmw":"我們如何能夠讓每個青年都能在與社會取得平衡的過程中，培養生存的自信和勇氣？","members":"蔡伃婷, 蔡詠竺"},
{"y":"2020","id":"208","hmw":"我們如何提醒人們透過主動聆聽，開始察覺聲音對情緒的影響？","members":"唐中純, 許達文"},
{"y":"2020","id":"209","hmw":"我們如何能夠讓台灣民眾認識國內電影產業以及電影創作者，進而願意支持並觀看國片？","members":"張寧靖, 高仲凱"},
{"y":"2020","id":"210","hmw":"我們如何能夠讓台灣的腳踏車使用者有安全的用路環境？","members":"邱毓云, 洪苡軒"},
{"y":"2020","id":"212","hmw":"如何協助臺灣年輕人認識高科技智慧製造產業，並讓更多莘莘學子加入傳統隱形冠軍產業？","members":"江水平, 李昭嫻"},
{"y":"2020","id":"213","hmw":"我們如何讓社會新鮮人理性分析後做出職涯決定？","members":"張芷瑄, 粘彤, 江東晉"},
{"y":"2020","id":"214","hmw":"如何解決讓社會（企業、家長）知道不是到了大學畢業才是與社會接軌的開始？","members":"黃子君, 林于涵"},
{"y":"2020","id":"215","hmw":"我們如何能讓在飲食上有諸多限制的腎臟病友，找回食材的美味及飲食的尊嚴？","members":"蔡岑珊, 陳敏而"},
{"y":"2020","id":"216","hmw":"如何能夠讓藝術創作者在現今社會能夠被支持，而不是自己的理想被當成空談？","members":"吳品瑩, 廖子睿, 何佳禹"},
{"y":"2020","id":"217","hmw":"我們如何能重新找回自信與生活的勇氣與熱情？","members":"周穎, 林韻如"},
{"y":"2020","id":"218","hmw":"如何能讓較有能力的人們透過簡單方式將幫助貧窮兒少？","members":"江依維, Fidyanita Safitri"},
{"y":"2020","id":"220","hmw":"我們如何能夠讓人們關心社會議題，並願意聆聽他人的想法及溝通？","members":"張原榕, 余采柔, 黃子豪"},
{"y":"2020","id":"222","hmw":"我們如何能夠使學生對於世界產生好奇、對於學習投入熱情，建立自己對未來的輪廓？","members":"黃品恩, 黃品純, 羅雋甯"},
{"y":"2020","id":"223","hmw":"我們如何讓台灣學生族群在面臨需要接受諮商輔導時，能夠即時得到幫助？","members":"周育承, 張芷綾"}]
