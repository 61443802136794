<template>
  <Modal :name="'y2017_2'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2017_2.jpg" alt=""> -->
    <p>你可知，每一位在照片裡出現的青年，他們的行動，都默默影響著身邊遇到的某一群人。</p>
    <p>就像 Swing Taiwan 的初衷，單純地想要分享快樂，行走至今，歡樂遍布多少？</p>
    <p>這就是行動的文化，隨時有動有靜，過程中創造新的可能，而好多人都在那個新的可能中，幸福地活出理想模樣。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
