<template>
  <Modal :name="'y2018_2'" ref="modal">
    <!-- <img src="./images/2018_2.jpg" alt=""> -->
    <p>迴響的活動，總是會有 Read Me First，那是因為我們往往無法確定，每一位讀者如何理解即將發生的事？他吸收了多少的前情提要？ </p>
    <p>「能夠掌握多一點點資訊，人會更有信心、更有安全感一些些。」</p>
    <p>迴響從來就不是一個容易理解的地方，因為外面的世界，和迴響有點不同，這裡你會被完全地鼓勵行動、被持續拷問反思的結果、無法直接收到下一步的指示，「以你為中心，真的什麼都可以」，你會怎麼行動？</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
