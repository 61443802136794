<template>
  <Modal :name="'partner_12'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>ZAZA眨眨眼／張維</h3>
    <p>關於【ZAZA眨眨眼】</p>
    <p>#偏鄉攝影培力 #用攝影開啟孩子未來想像力</p>
    <p>ZAZA 是由攝影、教育、社工、心理、行銷、設計專業的行動者，組成的年輕教育團隊。藝術確是一股解放心靈、賦予才能的極大力量。 我們希望透過攝影教育，引導孩童藉由鏡頭為自己發聲、獲得成功自信，而這份自信能成為翻轉命運的起點，陪孩子迎接之後的人生挑戰。</p>
　　 <p>過去十年，在柬埔寨我們服務超過 300 位偏鄉出身的孩童及青年、資助 120 戶受 Covid-19 疫情影響的急難家庭。在台灣我們與台北市立美術館、中正紀念堂、蒙特梭利小學、華山青鳥書店合作舉辦攝影展及工作坊，創造 10 萬人次線上線下觀展了解跨國偏鄉教育議題。未來我們將協力在地用影像教育，與孩子創造更多奇幻的故事。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_12.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
