<template>
  <Modal :name="'partner_5'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>玖樓／維綱(Jerry)</h3>
    <p>關於【玖樓】</p>
    <p>＃不只是出租公寓 #我們搭起連結不同社群和世代的橋</p>
    <p>「難道生活就只有上班、通勤、跟自己的床嗎？ 為什麼我們不能讓家裡客廳變成咖啡廳？」成立於 2015 年的玖樓相信，好的居住環境不只是硬體設備，更重要的是人與人之間的關係，致力於打造共生公寓，從修繕整理、規劃設計到社群經營，讓閒置的空間能重新被利用，嘗試著各種空間共享的可能。</p>
    <p>玖樓期待建立一個屬於個人的社群聚集地、生活和工作的緩衝區，讓一群擁抱創新的人，在疏離的城市中重新建立社區，也共同想像並實踐理想生活的樣貌。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_5.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
