<template>
  <Modal :name="'y2017_6'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2017_6.jpg" alt=""> -->
    <p>當人們一踏入迴響這個圈子後，好像就踏不出去了呢！</p>
    <p>中間那一位是藺子的創辦人怡雅，在她左手邊的兩位青年團隊，他們的題目和品牌是《浪犬博士》。</p>
    <p>看著這些照片，想著迴響也就是搭建了這樣的平台，讓行動者來、聚集、成為自己，然後出發走自己的路
</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
