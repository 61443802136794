<template>
  <Modal :name="'item_stone_soup'" ref="modal" :isWhite="true">
    <h3>石頭湯食譜</h3>
    <p>有許多關於石頭湯的故事，講的大概都是一群飢餓的人想到法子，一邊熬煮石頭，一邊講述[美味]的遠景，吸引圍觀的人把真正的食材投入湯裡，最後果然煮出美味的湯的故事。</p>
    <p>迴響的行動夥伴們，正在做的都是看起來很厲害的事情，這些故事讓人著迷，我們常常被吸引，有時候甚至會誤以為，靠近很厲害的人，就能獲得重要的東西。</p>
    <br>
    <br>
    <p>不過，只是一塊普通的石頭而已，在迴響的官網裡花費力氣，一一對話完畢後只會獲得這個東西。但這或許是我們能夠從他們身上學到的，最珍貴的事情。</p>
    <p>現在看起來再厲害、再困難的事情，一定都有第一步。如果能重播，那個跨出第一步的時刻，一定是一件他們當時做得到的，像煮石頭湯那樣只能硬著頭皮試試看的事情。</p>
    <p>不過是找一塊石頭而已，不妨這樣想吧！實在覺得太難了，對自己沒有自信的時候，可以先找一塊石頭，先做一件你一定做得到的事情。</p>
    <p>分享給你迴響的第一個石頭，是當年（2017）提案給青年署迴響計畫 ，直到今天有些事情真的做到了，有些天差地遠，無論如何好險當時有把它拿來煮，用盡全力吆喝著，行動換來行動，才有今天的一點成績。</p>
    <p>分享出來青澀的時期，儘管丟臉，希望能給你一些勇敢，像迴響這樣的計畫，也有開始的時候，你現在心裡正在想的那件事情，只是開始的話，應該也不會難到哪去！？</p>
    <br>
    <br>
    <p>•　<a href="https://drive.google.com/file/d/0B0VmJ9KtWHZvaV84WVBBZXliVHc/view?usp=sharing&resourcekey=0-wXl_O_ZgrfUDc6biliSj1A" target="_blank">第一版提案簡報</a><br>•　<a href="https://drive.google.com/file/d/0B0VmJ9KtWHZvYVlCQkF3YkVXdGc/view?usp=sharing&resourcekey=0-1JXKeLQQIC6ob_Mh_krVTg" target="_blank">第二版提案簡報</a>（後面還有很多版本，不過這個是由第一版換來的夥伴協助設計的，更有意義）<br>•　<a href="https://docs.google.com/document/d/1Mxj7PMfA5MeFi9mNiv4FQxji6dJ7vsq6M4pxbpGKiHY/edit?usp=sharing" target="_blank">提案企劃</a></p>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
