<template>
  <Modal :name="'y2020_3'" ref="modal" :isWhite="true">
    <img src="./images/2020_3-1.jpg" alt="">
    <p>有了先前的經驗：就是徒手迴響衣 DIY、第三屆沒有足夠的經費就有精簡的作法...</p>
    <p>那麼，第四屆活動現場的主視覺設計，就讓設計師們，直接用手畫出來吧！</p>
    <p>空白的布幕上，投影出官網的營火區域，象徵著大會師現場，就是大家的大本營，而空白處的塗鴉，則是行動團隊一一加上去的情緒線條、或是象徵行動的物件。</p>
    <p>如假似真的主視覺，一樣絕無僅有。</p>
    <img src="./images/2020_3-2.jpg" alt="">
    <img src="./images/2020_3-3.jpg" alt="">
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
