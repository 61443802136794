<template>
  <Modal :name="'s2_studio_2'" ref="modal" :isWhite="true">
    <h3>行動迴圈</h3>
    <p>「追根究底，假如不懂重新站起來的方法，我們就不願意冒險。假如我們勇敢的時間夠多，一定會跌倒。」- Brené Brown 《召喚勇氣》</p>
    <p>真實世界比我們理解的複雜許多，大多初期的行動都不會如預期，剛開始探索之際，為了協助青年能有效地推進議題，我們結合了團隊過往設計思考的教練經驗，參考精實執行 (Running Lean) 和精實創業 (Lean Startup) 的方法論，設計了行動迴圈的概念，可以讓團隊的每次討論、行動時，都可以有清楚的目標。</p>
    <br>
    <br>
    <img calss="w-350" src="./images/action-loop.svg" alt="">
    <h4>迴圈從圖中左下角開始：</h4>
    <p>•　從一個最小可行的行動方案來展開行動<br>•　從行動中獲得回饋、觀察、訪談...等等整理出新的資訊<br>•　停下來反思，比對新資訊與舊觀點，整理成新的觀點<br>•　根據新的觀點，發想不同的切入點，規劃成具體的行動方案</p>
    <h4>行動迴圈知易行難...</h4>
    <p>行動迴圈順著看會覺得很好理解、是一個理所當然的框架，然而多數時候，深陷在行動中的團隊往往不會照著迴圈來。</p>
    <p>想像今天你是一個蠻有行動力的青年，你想激起一個議題的討論、推廣，於是你辦了一場演講活動，聯絡講師、確定講題、行銷宣傳...當天真的來了一群人。</p>
    <p>可是這天的你，忙著報到、打電話給沒有來的人、確定投影機與冷氣，等到確定一切就定位，演講已經開始四十分鐘，心裡也有些浮躁，緊接著活動結束，拍完合照便開心地送走講師、觀眾。</p>
    <p>隔天起床，當你問自己「你還可以為議題做些什麼？」，發現好像沒有從昨天的行動中獲得新資訊，或者說，沒有意識到要「有意識」的這麼做，感覺昨天的演講成效不錯，是不是再繼續辦更多講座?</p>
    <p>這是常見的狀況，把這個案例放進行動迴圈，有一個具體的方案、有行動力，但是這樣的行動，沒有帶給團隊足夠的新資訊，因此團隊對於議題的觀點也沒有辦法順利深化，更難發想出有意義的新行動規劃。</p>
    <br>
    <br>
    <h4>有了行動迴圈可以怎麼做？</h4>
    <p>「所有行動，都要檢視那是要為我們帶來甚麼新資訊?為什麼我們需要這些新資訊？行動的規劃是否真的能幫助我們帶來新資訊?」 </p>
    <p>辦講座沒有不好，但我們規劃時，可以有意識的安排事前、事後的訪談、問卷。試著在活動中除了聽講，也加入更多討論的環節，試著在人力的安排上，確保夥伴都能參與、觀察重要的討論</p>
    <p>這樣，我們才能在過程中獲得更多的資訊，找到對於議題更有效的切入點，才能真的「越做越深」</p>
    <br>
    <br>
    <h4>行動工具模板</h4>
    <p>
      •　行動前整理假設，幫助聚焦的法寶：<a href='https://xn--docs-kq0g30b.google.com/presentation/d/e/2PACX-1vRGpgse7m7ta2iph7QRYWvChrw80pjK_2Lz258yWQD9zQWhgPStjAEvu58-ksYrmYCQBp8CyC4KWbEK/pub?start=false&loop=false&delayms=3000&slide=id.ge4740eef85_0_0' target="_blank">行動規劃書</a><br />
      •　行動後整理新資訊的法寶：<a href="https://docs.google.com/presentation/d/e/2PACX-1vRAhvItruXiwDqQ6tVv2GW_8mzTK9qDVdITm1fIn1MEgZODe8Yzp2LG8aV0flD3gCxQMADxi2-Z9rFg/pub?start=false&loop=false&delayms=3000&slide=id.ge71a5f0f4a_0_0" target="_blank">打包捕捉表</a><br />
      •　幫助切換視角，從複雜的觀點中找到行動切入點的工具：<a href='https://docs.google.com/presentation/d/e/2PACX-1vTXXWxpPsf53rsvaOUDWtNEEjwuXTTr7itzPpqvWg-HRKPfAf1_S6GeZESyrW5mQJoyc-AThBIhYMWv/pub?start=false&loop=false&delayms=3000&slide=id.p' target="_blank">HMW(How Might We) 問句</a>
    </p>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
