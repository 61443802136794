<template>
  <Modal :name="'y2017_4'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2017_4-1.jpg" alt=""> -->
    <p>從提問之後，透過任務包去採訪、認證，和別人談一談所帶來的觀念上的改變，比蒐集資料、擬定策略還要重要。</p>
    <p>當這些團隊改變行動的方案，是因為「原來，不只是如此...」、「原來，還有不同的聲音...」、「原來，眼前的困境，是因為另一個因素所造成的...」</p>
    <p>我們知道青年們學會了˙，願意傾聽他人、放下自己心中原本的假設與設定、接受新的觀點，「不由得感動得起雞皮疙瘩」Jarah 寫於《行動旅人求生筆記》。</p>
    <!-- <img src="./images/2017_4-2.jpg" alt="">
    <img src="./images/2017_4-3.jpg" alt="">
    <img src="./images/2017_4-4.jpg" alt="">
    <img src="./images/2017_4-5.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
