<template>
  <Modal :name="'y2020_4'" ref="modal" :isWhite="true">
    <img src="./images/2020_4-1.jpg" alt="">
    <p>今年，我們要讓大家知道，迴響本身，就是行動者的大本營。</p>
    <p>每個來到線上官網、實體空間，就像是在爬山路的歷程一樣，每個人有不同的頂峰要征服，但中間的休憩站、可以充電交流的大本營，就是迴響山屋！</p>
    <p>因此，現場布滿了植物、蕨類，露營用具、木頭器材，為的就是要全身心都感受到被鼓舞、被激勵，「行動路上絕不會一路安穩，但你會知道只要繼續前行，就會遇見你的伙伴。」</p>
    <img src="./images/2020_4-2.jpg" alt="">
    <img src="./images/2020_4-3.jpg" alt="">
    <img src="./images/2020_4-4.jpg" alt="">
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
