<template>
  <Modal :name="'planet_empathy'" ref="modal" :isWhite="true">
    <h3>同理 Empathy</h3>
    <p class="t-center">「多一點體諒和一顆關心他人的心，世界就會大不相同。」</p>
    <br />
    <p>同理，是想盡辦法向對方那邊靠近，不為任何其他附帶的目的，回到人和人之間的關係，不特別求方法、不執著用工具。</p>
    <p>迴響重視的價值是，面對面時注意，我們是更靠近他人，還是更靠近我們自己。</p>
    <img src="../../images/world/NPC/events/Planet4Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
