<template>
  <Modal :name="'y2017_1'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2017_1.jpg" alt=""> -->
    <p>不只這些，不只這些，每一次大合照裡，都少了一部分的人...... </p>
    <p>攝影師、其他活動籌辦夥伴、支持行動團隊的利害關係人、無緣進到大會師的青年們、所有青年署相關的工作夥伴們、先行離去的行動夥伴、行程滿滿的評審們</p>
    <p>......這樣連連看，總有一天會連到你吧。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
