<template>
  <Modal :name="'y2019_9'" ref="modal" :isWhite="true">
    <p>不知道你知不知道，但這是一屆完全沒有獎金的一屆。</p>
    <p>而這也是一個契機，讓我們終於找到一種屬於迴響的獎狀，「除了給錢，評審你能給予什麼陪伴、建議，讓青年團隊離開迴響後，還有下一步的資源，可以更走進社會？」</p>
    <br>
    <br>
    <p>No.094 波波實驗室_民主永遠不會過期獎</p>
    <!-- <img src="./images/2019_9-1.jpg" alt=""> -->
    <br>
    <p>No.078_小紅帽_ INFINITY</p>
    <!-- <img src="./images/2019_9-2.jpg" alt=""> -->
    <br>
    <p>No.105_詩門倍思_泰雅文化復興獎</p>
    <!-- <img src="./images/2019_9-3.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
