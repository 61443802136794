<template>
  <Modal :name="'giant_room_6'" ref="modal">
    <br/>
    <br/>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <h5 class="t-center">(古壁畫的某一段落，紀錄著這段話⋯⋯)</h5>
    <br/>
    <br/>
    <p>每一年執行團隊都會打趣的問彼此這個問題「如果明年還有迴響，你還會回來嗎？為什麼？」</p>
    <p>直到今年，因為某些因素，可能沒有辦法這麼樂觀的預期，明年還是理所當然的會有迴響。<br/>我們十個人相約，在八月的尾聲一起去一趟恆春旅行，像是在為迴響的這段旅行劃下終點，走到最難的地方，走到最南的地方說再見。</p>
    <p>一天晚上在鈕釦倉庫喝酒，因為臨時的大雨，躲到樹屋裡的一個小房間裡，原先只能容下兩個人的空間，一下子塞了九個人，在有限的空間裡，必須貼緊彼此的感覺，是關於執行團隊日常的一種隱喻。</p>
    <p>雨停了出來走走，大街上說著笑著又下起了大雨，前面的人開始跑了起來，後面的不知道為什麼也跟著跑了，按著包包的君芃一邊快走一邊說「其實我有帶傘」。索性就不拿出來了。遇到大雨的時候，就算自己有傘，倘若撐不起所有人的天空，有傘的人也會跟著淋雨，與其說是社會規範、潛規則，我會想把他看成迴響的浪漫。</p>
    <p>一時一刻，覺得自己和這些人的命運相連，哪裡有狹窄的出口，也不願獨自走出去，寧可相信我們這麼多人，要能對世上的堅硬，不苟且的鑿出一個洞來。</p>
    <p>其實是不需要淋雨的，跑過了幾條街，幾分鐘過去，雨也就停了。冷靜下來的時候想，其實是不需要淋雨的，但如果可以盡情的跑，為什麼不呢？日常裡想取巧的時刻，是放下渴望的時刻，自我會慢慢和現實妥協，於是不方便的事，反而讓我們更能辨認出彼此。下雨的時候也可以說跑就跑，雨停後才到 7-11 躲雨，在很飽的時候買啤酒和零食，想像這個夜晚，有二十四個小時。</p>
    <p>這個計畫雖然限制很多，在這個計畫中旅行的我們，一直試著回應自己的心，於是某種程度上，也是自由的。</p>
    <p>到底誰會先說再見？我知道我一定哭泣，走的時候記得說愛我。</p>
    <br/>
    <br/>
    <h3 class="t-center">⋯⋯</h3>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
