<template>
  <Modal :name="'s0_intro'" ref="modal">
    <h4>青年迴響計畫，<br/>協助青年為自己在意的社會議題跨出第一步行動，<br/>只要帶著問題就可以參加，接著用行動，拉近自身與未知的距離。</h4>
    <p>其實，要展開行動，沒有想像中那麼簡單，你的親朋好友會過度關心你賺不賺錢、有沒有未來；社會上有創業競賽，但大多是錦上添花型，等你已經相對成熟，再多推你一把，但從行動最開頭，最模糊的時候，反而沒有太多的協助。</p>
    <p>
      迴響計畫就是在協助這一塊，你只要帶著模糊的問題來，<br/>
      我們會提供行動的架構與方法、行動夥伴與教練的陪伴，<br/>
      協助你把一個模糊的問題慢慢轉換成你的第一步行動，<br/>
      讓你可以在行動中，直接試著為你覺得社會上怪怪的事情，做些什麼。
    </p>
    <p>
      三個月的計畫期間，團隊其實沒辦法大幅度促成議題改變，<br/>
      但當青年以行動代替抱怨、成為其他議題專案的 First Follower，<br/>
      甚至延續專案，變成更完整的方案、品牌、組織，<br/>
      讓未來有更多「社會的可能性」被看見，<br/>
      便能持續改善「社會議題看似完全無法解決」的惡性循環。
    </p>
    <br/>
    <br/>
    <p>
      主辦機關｜青年們的後盾 教育部青年發展署<br/>
      承辦單位｜有事找這家 Alpha Team<br/>
      協力夥伴｜我們的戰友 好伴社計 DateMyself Octave 魚肚白設計<br/>
      合作單位｜我們的基地 D-School@NTU 台大創新設計學院
    </p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
