<template>
  <Modal :name="'planet_iteration'" ref="modal" :isWhite="true">
    <h3>迭代 Iteration</h3>
    <p class="t-center">「前進，修正，再前進。」</p>
    <br />
    <p>迭代是懂得擁抱每一個不完美，是與伙伴一起前進的秘密咒語，不費力去獨佔片刻的成就，不刻意去責怪當下發生的問題。</p>
    <p>迴響重視的價值是，我們一起變的更好，或者，我們一起變的更好，你二選一。</p>
    <img src="../../images/world/NPC/events/Planet5Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
