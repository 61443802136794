<template>
  <Modal :name="'s2_team_type_tree_7'" ref="modal" :isWhite="true">
    <h5 class="t-center">團隊樣貌</h5>
    <br>
    <h3>擦肩而過</h3>
    <p>這些團隊曾經參加過迴響，但因為總總原因，迴響當初沒有足夠的能力來看到這些團隊的堅持與潛力，未來也還是可能會有其他遺珠之憾。這些團隊沒有停止行動，反而走得更遠、帶來更多的影響。應該還有更多更多曾經參與迴響的團隊還不曾相認過，祝福你們在行動的路上持續有好事發生。</p>
    <br>
    <br>
    <br>
    <p>2017 衣服圖書館：「我們如何讓購買二手衣成為選擇，減少新衣購買量?」</p>
    <p>2017 金魚腦：「我們如何能從早午餐店開始改善使用塑膠吸管的習慣？」</p>
    <p>2019 逆風劇團：「我們如何能夠讓非行青少年重返社群？」</p>
    <p>2020 雞湯來了：「我們如何能讓臺灣的年輕人與家人開啟對話、促進溝通？」</p>
    <br>
    <br>
    <br>
    <!-- <h5 class="t-center">像這樣的團隊，還有 2019 小紅帽、2017 浪犬博士...</h5> -->
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
