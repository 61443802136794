/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'main' %> (and
// <%= stylesheet_pack_tag 'main' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
// import Navbar from '../navbar.vue'
// import Marquee from '../marquee.vue'
// import Home from '../home.vue'
// import Universe from '../universe.vue'
import axios from 'axios'
// import tabletop from 'tabletop'
import Vue2Editor from "vue2-editor";
import VueGtag from "vue-gtag"
import VCalendar from 'v-calendar';
import VueRouter from 'vue-router'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import VueScrollactive from 'vue-scrollactive';
import Cookies from 'js-cookie'
import Vuex from 'vuex'
import Vue2TouchEvents from 'vue2-touch-events'
import VueTypedJs from 'vue-typed-js'

import {Howl} from 'howler';
import sounds from './../sounds'

import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(Vuex)
Vue.use(Vue2TouchEvents, {
    disableClick: false,
    touchClass: '',
    tapTolerance: 10,
    touchHoldTolerance: 400,
    swipeTolerance: 30,
    longTapTimeInterval: 400,
    namespace: 'touch'
})


import { check_mobile, get_random_int, delay } from '../js/map/global.js'
import { items, drops } from '../js/map/items.js'
import { missions } from '../js/map/missions.js'
import { objects, grids, npcs } from '../js/map/map.js'
import { dialogs } from '../js/map/dialogs.js'
import { hmw } from '../js/map/hmw.js'
import { events, dogStop } from '../js/map/events.js'

const store = new Vuex.Store({
  state: {
    items, // used in drops and backpack
    drops, // used in action.dispatch
    missions, // used in dialog and map(develop)
    dialogs, // used in dialog and backpack
    objects, // used in object and map(develop)
    grids, // used in object and map(develop)
    hmw, // for elf in action.dispatch
    npcs, //
    events,
    dogStop,
    isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    map: {  // whole map will be initiate in map.vue created()
      collider: new Array(),
      tree: new Array(),
      fish: new Array(),
      hole: new Array(),
      mission: new Array(),
      drop: new Array(),
      event: new Array(),
      dogStop: new Array(),
      bgmMain: new Array(),
      bgmAddon: new Array(),
    },
    chooseNPCName: '', // for developer
    isLoading: true,
    isInTeachingMode: false,
    unit: 25, // px
    xMax: 301,
    yMax: 381, // + 60 + 20
    viewWidth: 78,
    viewHeight: 60,
    // viewWidth: 60,
    // viewHeight: 20,
    appearence: {
      drops: [], // empty when start
      nowActiveElf: false
    },
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    player: { // player default (save in cookie)
      name: '無名的行動者',
      hmw: '寫下你看見的社會問題。',
      elfName: '迴響小小人',
      dogName: '',
      myStone: '我所在意的任何事情⋯⋯',
      avatar: {
        x: 150, // origin
        y: 225  // origin
      },
      isTakingPicture: false,
      isInCableCar: false,
      teachingStep: 0,
      state: { // all state would be saved in cookie
        isFirst: true,
        clearFog: false,
        // completeFirstEnd: false,
        missionMax: 5,
        inventoryMax: 120,
        missionComplete: [],
        // missionComplete: ['Atou', 'TrunkLight', 'PartnerFlavor', 'ToolTrafficLight', 'Broomman', 'Archaeologist', 'LakeGoddess'],
        // missionComplete: ['Atou', 'TrunkLight'],
        missionOngoing: [],
        items: [],
        // ingredients: [],
        events: [],
        // events: ['LakeGoddess'], // new image
        delNPCs: [], // disappear image
        delMissions: [],
        passAisle: [],
        hasDog: false,
        hasElf: false,
        hasBoat: false,
        // picture: {
        hasPicture: false,
        pictureDate: null,
        pictureNameList: '',
        pictureHasElf: false,
        pictureHasDog: false,
        pictureHmw: '',
        pictureUserImgIndex: 0,
        pictureDogImgIndex: 0,
        pictureElfImgIndex: 0,
        // }
      },
      interaction: { // instant, no need to record in cookie
        isActing: false, // when acting (push tree, pick up...), any other action is forbidden temporarily
        nowDialog: '', // npc said...
        nowDialogImage: '', // dialog image...
        nowAnswers: [], // player said...
        nowAnswerIndex: 0, // what player choose
        isFishing: false, // note that fish sec is random so I need to manage it here
        whatIGet: '', // note that 'get the item' interact between avatar and dialog
      }
    },
    backpack: {
      isOpen: false,
      tabIndex: 0,
      inventoryIndex: 0,
      missionIndex: 0,
      allMissions: ['Alder', 'Atou', 'TrunkLight', 'FerryMan', 'Counter', 'PartnerFlavor', 'Broomman', 'ToolTrafficLight', 'Archaeologist', 'LakeGoddess', 'ActionGod'],
      // missionMax: 11, move to state
      sortedItems: [],
      // sortedMission: [],
      isSoundBGMOpened: true,
      isSoundEffectOpened: true,
    },
    modal: {
      components: [], // initial by modals.vue
      isOpen: false,
    },
    functions: {
      reminder: null,
      postit: {
        post: null,
        remove: null,
      },
      avatar: {
        pressArrowKey: null,
        pressA: null,
        calculateAvatarPosition: null
      },
      backpack: {
        pressArrowKey: null,
        pressA: null,
        pressB: null,
        pressEsc: null
      },
      NPCs: {
        pressArrowKey: null
      },
      objects: {
        pressA: null
      },
      drops: {
        pressA: null
      },
      dialog: {
        pressArrowKey: null,
        pressA: null,
        pressEsc: null,
        dialog: null
      },
      modal: {
        pressEsc: null,
        pressDownA: null,
        pressUpA: null
      }
    },
    soundIds: {
      bgmMain: {},
      bgmAddon: {},
      effect: {}
    }
  },
  getters: {
    transitionStyle: state => {
      const x = (state.windowWidth / 2)-((state.player.avatar.x+0.54) * state.unit)
      const y = (state.windowHeight / 2)-(state.player.avatar.y * state.unit)
      return 'transform: ' + 'translate3d(' + x + 'px,' + y + 'px, 0px);'
      // return 'transform: ' + 'translate3d(' + ((state.windowWidth / 2)-(state.player.avatar.x * state.unit)) + 'px,' +
      // ((state.windowHeight / 2)-(state.player.avatar.y * state.unit)) + 'px, 0px);'
      // note that z is 0px because it would be change by every different object when avatar walking
    },
    getDogRunTime: (state, getters) => {
      const coordinate = getters.digSthFromHole
      const xPow = Math.pow((state.player.avatar.x - coordinate.x),2)
      const yPow = Math.pow((state.player.avatar.y - coordinate.y),2)
      const distance = Math.sqrt(xPow + yPow)
      const bufferSec = 0.3 // if dog is very close to hole
      const sec = ((distance / 3.5) + bufferSec ).toFixed(1)
      // console.log(sec)
      return sec
    },
    digSthFromHole: state => {
      let nowX = state.player.avatar.x - 5
      let nowY = state.player.avatar.y - 5
      for (let i = 0; i < 10; i++) {
        for (let j = 0; j < 10; j++) {
          // console.log((nowX+i) + ',' + (nowY+j))
          if (state.map.hole[nowX+i][nowY+j]) {
            // console.log('there is!')
            return {
              'x': nowX + i,
              'y': nowY + j
            }
          }
        }
      }
      return false
    },
    colliderAroundMeX: state => {
      let xShift = 0
      let nowX = state.player.avatar.x
      let nowY = state.player.avatar.y
      if (!state.map.collider[nowX-1][nowY]) xShift--
      if (!state.map.collider[nowX+1][nowY]) xShift++

      return xShift
    },
    colliderAroundMeY: state => {
      let yShift = 0
      let nowX = state.player.avatar.x
      let nowY = state.player.avatar.y
      if (!state.map.collider[nowX][nowY-1]) yShift--
      if (!state.map.collider[nowX][nowY+1]) yShift++
      return yShift
    },
    ableToFish: (state, getters) => {
      // console.log(getters.fishOnLeft)
      // console.log(getters.fishOnRight)
      let hasFishingRob = false
      for (let item of state.player.state.items) {
        // console.log(item)
        if (item.name == 'fishingRob') hasFishingRob = true
      }
      if (hasFishingRob) return (getters.fishOnLeft || getters.fishOnRight) || getters.fishBottom
      else return false
    },
    fishOnLeft: state => {
      return state.map.fish[state.player.avatar.x-1][state.player.avatar.y]
    },
    fishOnRight: state => {
      return state.map.fish[state.player.avatar.x+1][state.player.avatar.y]
    },
    fishBottom: state => {
      return state.map.fish[state.player.avatar.x][state.player.avatar.y+1]
    },
    whatIsPickingUp: (state, getters) => {
      // wait to improve
      const x = state.player.avatar.x + getters.whereTheDropIsX
      const y = state.player.avatar.y + getters.whereTheDropIsY
      return state.map.drop[x][y]
    },
    whereTheDropIsX: state => {
      let x = state.player.avatar.x
      let y = state.player.avatar.y
      if (state.map.drop[x][y]) return (0) // this grid always first
      else if (state.map.drop[x-1][y]) return (-1)
      else if (state.map.drop[x+1][y]) return 1
      return 0
    },
    whereTheDropIsY: state => {
      let x = state.player.avatar.x
      let y = state.player.avatar.y
      if (state.map.drop[x][y]) return (0) // this grid always first
      else if (state.map.drop[x][y-1]) return (-1)
      else if (state.map.drop[x][y+1]) return 1
      return 0
    },
    treeAroundMe: (state, getters) => {
      // order: left -> right -> top -> bottom
      if (getters.whereTheTreeX) {
        return {
          'x': state.player.avatar.x + getters.whereTheTreeX,
          'y': state.player.avatar.y
        }
      }
      else if (getters.whereTheTreeY) {
        return {
          'x': state.player.avatar.x,
          'y': state.player.avatar.y + getters.whereTheTreeY
        }
      }
      else return false
      // return getters.treeOnLeft || getters.treeOnRight
    },
    whereTheTreeX: state => {
      if (state.map.tree[state.player.avatar.x-1][state.player.avatar.y]) return -1
      if (state.map.tree[state.player.avatar.x+1][state.player.avatar.y]) return 1
      return 0
    },
    whereTheTreeY: state => {
      if (state.map.tree[state.player.avatar.x][state.player.avatar.y-1]) return -1
      if (state.map.tree[state.player.avatar.x][state.player.avatar.y+1]) return 1
      return 0
    },
  },
  mutations: {
    setPlayerCompleteFirst(state) {
      state.player.state.isFirst = false
    },
    pushNewPassAisle(state, value) {
      state.player.state.passAisle.push(value)
    },
    setChosenNPCName(state, value) {
      state.chooseNPCName = value
    },
    setTeachingMode(state, value) {
      state.isInTeachingMode = value
    },
    setNewbieColliders(state, value) {
      for (let i = 0; i < state.grids.specialColliders.newbie.length; i++) {
        let x = state.grids.specialColliders.newbie[i].x
        let y = state.grids.specialColliders.newbie[i].y
        state.map.collider[x][y] = value
      }
    },
    setSpecialCollidersAisle(state, category) {
      // console.log(category)
      for (let i = 0; i < state.grids.specialColliders[category].length; i++) {
        let x = state.grids.specialColliders[category][i].x
        let y = state.grids.specialColliders[category][i].y
        state.map.collider[x][y] = true
      }
    },
    initialPostItFunction(state, functions) {
      state.functions.postit.post = functions.post
    },
    initialNPCsFunction(state, functions) {
      state.functions.NPCs.pressArrowKey = functions.pressArrowKey
    },
    initialModalFunction(state, functions) {
      state.functions.modal.pressA = functions.pressA
      state.functions.modal.pressEsc = functions.pressEsc
    },
    initialObjectsFunction(state, functions) {
      state.functions.objects.pressA = functions.pressA
    },
    initialDialogFunction(state, functions) {
      state.functions.dialog.pressArrowKey = functions.pressArrowKey
      state.functions.dialog.pressA = functions.pressA
      state.functions.dialog.pressEsc = functions.pressEsc
      state.functions.dialog.dialog = functions.dialog
    },
    initialDropsFunction(state, functions) {
      state.functions.drops.pressA = functions.pressA
    },
    initialBackpackFunction(state, functions) {
      state.functions.backpack.pressArrowKey = functions.pressArrowKey
      state.functions.backpack.pressA = functions.pressA
      state.functions.backpack.pressB = functions.pressB
      state.functions.backpack.pressEsc = functions.pressEsc
    },
    initialAvatarFunction(state, functions) {
      // console.log(functions)
      state.functions.avatar.pressArrowKey = functions.pressArrowKey
      state.functions.avatar.pressA = functions.pressA
      state.functions.avatar.calculateAvatarPosition = functions.calculateAvatarPosition
    },
    initialReminder(state, reminder) {
      state.functions.reminder = reminder
    },
    initialModal(state, payload) {
      Vue.set(state.modal.components, payload.name, payload.modal)
    },
    // modal
    setModalOpenTrue(state, modalName) {
      state.modal.components[modalName].$refs.modal.open = true
      state.modal.isOpen = true
    },
    setModalOpenFalse(state, modalName) {
      state.modal.components[modalName].$refs.modal.open = false
      state.modal.isOpen = false
    },
    // modal
    changeUnitValue(state, value) {
      state.unit = value
    },
    labelAvatarIsActing(state, value) {
      state.player.interaction.isActing = value
      // console.log(value)
    },
    // cookie
    clearCookie(state) {
      Cookies.remove('cookieState0910');
      Cookies.remove('cookieInfo0910');
      Cookies.remove('cookieItems0910');
      Cookies.remove('cookieEvents0910');
      Cookies.remove('cookieMissionComplete0910');
      Cookies.remove('cookieMissionOngoing0910');
      window.location.reload();
    },
    inputPlayerData(state, payload) {
      Vue.set(state.player, payload.key, payload.message)
    },
    // cookie
    getDog(state) {
      state.player.state.hasDog = true
    },
    getElf(state) {
      state.player.state.hasElf = true
    },
    assignWindowWidth(state, width) {
      state.windowWidth = width
    },
    assignWindowHeight(state, height) {
      state.windowHeight = height
    },
    // map
    initialAllMaps(state) {
      const keys = Object.keys(state.map)
      for (let key of keys) {
        for (let i = 0; i < state.xMax; i++) {
          let array = new Array()
          for (let j = 0; j < state.yMax; j++) {
            if (key == 'collider') array.push(true)
            else array.push(false)
          }
          state.map[key].push(array)
        }
      }
    },
    assignMapValue(state, payload) {
      let y_array = state.map.[payload.category][payload.x]
      let y_index = payload.y
      Vue.set(y_array, y_index, payload.value)
    },
    assignDropMapValue(state, payload) {
      // state.map.drop[payload.x][payload.y] = payload.value
      let y_array = state.map.drop[payload.x]
      let y_index = payload.y
      Vue.set(y_array, y_index, payload.value)
    },
    assignMissionMapValue(state, payload) {
      // state.map.mission[payload.x][payload.y] = payload.value
      let y_array = state.map.mission[payload.x]
      let y_index = payload.y
      Vue.set(y_array, y_index, payload.value)
    },
    assignEventMapValue(state, payload) {
      let y_array = state.map.event[payload.x]
      let y_index = payload.y
      Vue.set(y_array, y_index, payload.value)
    },
    assignBgmMainMapValue(state, payload) {
      let y_array = state.map.bgmMain[payload.x]
      let y_index = payload.y
      const value = `${payload.value}:${payload.volumn}`
      Vue.set(y_array, y_index, value)
    },
    assignBgmAddonMapValue(state, payload) {
      let y_array = state.map.bgmAddon[payload.x]
      let y_index = payload.y
      const value = `${payload.value}:${payload.volumn}`
      Vue.set(y_array, y_index, value)
    },
    // map
    // player
    setPlayerCoordinate(state, coordinate) {
      // console.log(coordinate)
      Vue.set(state.player.avatar, 'x', coordinate.x)
      Vue.set(state.player.avatar, 'y', coordinate.y)
    },
    setPlayerFishing(state, boolean) {
      state.player.interaction.isFishing = boolean
    },
    setDialog(state, text) {
      state.player.interaction.nowDialog = text
    },
    setDialogImage(state, src) {
      state.player.interaction.nowDialogImage = src
    },
    setAnswers(state, array) {
      state.player.interaction.nowAnswers = array
    },
    setAnswerIndex(state, index) {
      state.player.interaction.nowAnswerIndex = index
    },
    toggleBackpack(state, bool) {
      state.backpack.isOpen = bool;
    },
    setBackpackTabIndex(state, number) {
      state.backpack.tabIndex = number;
    },
    setBackpackIndex(state, payload) {
      state.backpack[payload.category+'Index'] = payload.number;
    },
    addToDropList(state, json) {
      state.appearence.drops.push(json)
    },
    missionOngoing(state, missionName) {
      state.player.state.missionOngoing.push(missionName)
      const missionInfo = state.missions[missionName]
      if (!missionInfo.noShow) {
        let missionContent = state.player.interaction.nowAnswers[state.player.interaction.nowAnswerIndex].ongoing
        if (missionContent === true) {
          const title = missionInfo.missionTitle
          state.functions.reminder('承接了任務：' + title + ' !')
        }
        else if (typeof missionContent == 'string') {
          state.functions.reminder(missionContent)
        }
      }

    },
    recordEvent(state, event) {
      state.player.state.events.push(event)
    },
    recordDelNPC(state, delNPC) {
      state.player.state.delNPCs.push(delNPC)
    },
    removeDelNPC(state, recoverNPC) {
      // animation
      const dom = document.getElementsByClassName(recoverNPC)[0]
      dom.classList.remove('fadeOut')
      if (dom) dom.style.display = 'block'
      else console.log('error! there is no ' + recoverNPC + ' in our NPC assets!')

      // state record
      let success = false
      for (let i in state.player.state.delNPCs) {
        let delNPC = state.player.state.delNPCs[i]
        if (delNPC == recoverNPC) {
          state.player.state.delNPCs.splice(i, 1)
          success = true
        }
      }
      // if (!success) alert('sth wrong!')
    },
    recordDelMission(state, missionName) {
      state.player.state.delMissions.push(missionName)
    },
    initialSounds(state) {
      // console.log('start to load sound')
      state.soundIds = {
        bgmMain: {
          h: new Howl({ src: [sounds.main_happy], html5: true, loop: true, volume: 0 }),
          c: new Howl({ src: [sounds.main_cave], html5: true, loop: true, volume: 0 }),
          t: new Howl({ src: [sounds.main_top], html5: true, loop: true, volume: 0 }),
          e: new Howl({ src: [sounds.main_examine], html5: true, loop: true, volume: 0 }),
        },
        bgmAddon: {
          b: new Howl({ src: [sounds.addon_birds], html5: true, loop: true, volume: 0 }),
          w: new Howl({ src: [sounds.addon_wind], html5: true, loop: true, volume: 0 }),
          f: new Howl({ src: [sounds.addon_fire], html5: true, loop: true, volume: 0 }),
          wf: new Howl({ src: [sounds.addon_waterfall], html5: true, loop: true, volume: 0 }),
          o: new Howl({ src: [sounds.addon_osawa], html5: true, loop: true, volume: 0 }),
          l: new Howl({ src: [sounds.addon_lake_goddess], html5: true, loop: true, volume: 0  })
        },
        effect: {
          ding: new Howl({ src: [sounds.ding], html5: true }),
          dialog_choose: new Howl({ src: [sounds.dialog_choose], html5: true }),
          dialog_select: new Howl({ src: [sounds.dialog_select], html5: true }),
          // drop_fish_point: new Howl({ src: [sounds.drop_fish_point], html5: true }),
          gain_obj: new Howl({ src: [sounds.gain_obj], html5: true }),
          // mission_complete: new Howl({ src: [sounds.mission_complete], html5: true }),
          npc_partner_m: new Howl({ src: [sounds.npc_partner_m], html5: true }),
          npc_partner_f: new Howl({ src: [sounds.npc_partner_f], html5: true }),
          npc_m: new Howl({ src: [sounds.npc_m], html5: true }),
          npc_m_2: new Howl({ src: [sounds.npc_m_2], html5: true }),
          npc_m_3: new Howl({ src: [sounds.npc_m_3], html5: true }),
          npc_m_4: new Howl({ src: [sounds.npc_m_4], html5: true }),
          npc_m_alder: new Howl({ src: [sounds.npc_m_alder], html5: true }),
          npc_m_god: new Howl({ src: [sounds.npc_m_god], html5: true }),
          npc_bear: new Howl({ src: [sounds.npc_bear], html5: true }),
          open_b: new Howl({ src: [sounds.open_b], html5: true }),
          close_b: new Howl({ src: [sounds.close_b], html5: true }),
          shake_tree: new Howl({ src: [sounds.shake_tree], html5: true }),
          tog_popup: new Howl({ src: [sounds.tog_popup], html5: true }),
          tree_drop_obj: new Howl({ src: [sounds.tree_drop_obj], html5: true }),
          hmw: new Howl({ src: [sounds.hmw], html5: true }),
          fly_fishing: new Howl({ src: [sounds.fly_fishing], html5: true }),
          take_pic: new Howl({ src: [sounds.take_pic], html5: true }),
          take_cable_car: new Howl({ src: [sounds.take_cable_car], html5: true }),
          rock_moving: new Howl({ src: [sounds.rock_moving], html: true }),
          // walk_step: new Howl({ src: [sounds.effect_walk_step], html5: true }),
        }
      }
    },
    // setSoundBgmVolume(state, {type, name, volume}) {
    //   if(state.backpack.isSoundBGMOpened) {
    //     if(!state.soundIds[type][name].playing) {
    //       state.soundIds[type][name].play();
    //     } else {
    //       state.soundIds[type][name].fade(state.soundIds[type][name].volume(), volume, 300)
    //     }
    //   }
    // },
    checkCurrentGridSound(state) {
      if(state.backpack.isSoundBGMOpened) {
        const main = state.map.bgmMain[state.player.avatar.x][state.player.avatar.y]
        if (typeof main === 'string') {
          const name = main.split(':')[0]
          const volume = main.split(':')[1]
          const currentVolume = state.soundIds.bgmMain[name].volume();
          if (state.soundIds.bgmMain[name]) {
            const isPlaying = state.soundIds.bgmMain[name].playing();
            if(volume!=0 && !isPlaying) state.soundIds.bgmMain[name].play();
            else if(volume==0 && isPlaying) state.soundIds.bgmMain[name].stop();
            else if(volume!=currentVolume) state.soundIds.bgmMain[name].fade(currentVolume, volume, 500)
          }
        }

        const addon = state.map.bgmAddon[state.player.avatar.x][state.player.avatar.y]
        if (typeof addon === 'string') {
          const name = addon.split(':')[0]
          const volume = addon.split(':')[1]
          const currentVolume = state.soundIds.bgmAddon[name].volume();
          if(
            (name==='f' && state.player.state.events.indexOf('BonfireFire')!==-1)||
            (name!=='f')
            ) {
            if (state.soundIds.bgmAddon[name]) {
              const isPlaying = state.soundIds.bgmAddon[name].playing();
              if(volume!=0 && !isPlaying) state.soundIds.bgmAddon[name].play();
              else if(volume==0 && isPlaying) state.soundIds.bgmAddon[name].stop();
              else if(volume!=currentVolume) state.soundIds.bgmAddon[name].fade(currentVolume, volume, 500)
            }
          }
        }
      }
    },
    // stopAllSounds(state) {
    //   Object.keys(state.soundIds).map(soundKey =>
    //     Object.keys(state.soundIds[soundKey]).map(name => state.soundIds[soundKey][name].stop())
    //   )
    // },
    stopAllBGMSounds(state) {
      Object.keys(state.soundIds.bgmMain).map(name => state.soundIds.bgmMain[name].stop())
      Object.keys(state.soundIds.bgmAddon).map(name => state.soundIds.bgmAddon[name].stop())
    },
    stopAllEffectSounds(state) {
      Object.keys(state.soundIds.effect).map(name => state.soundIds.effect[name].stop())
    },
    playSoundEffect(state, name) {
      if(state.backpack.isSoundEffectOpened) {
        if (state.soundIds.effect[name]) state.soundIds.effect[name].play()
      }
    },
    playSoundEffectWithRandomVolume(state, name) {
      if(state.backpack.isSoundEffectOpened) {
        if (state.soundIds.effect[name]) {
          state.soundIds.effect[name].volume(1+(get_random_int(3))/10)
          state.soundIds.effect[name].play()
        }
      }
    },
    updatePicture(state, data) {
      state.player.state.hasPicture = data.hasPicture;
      state.player.state.pictureDate = data.pictureDate;
      state.player.state.pictureNameList = data.pictureNameList;
      state.player.state.pictureHasElf = data.pictureHasElf;
      state.player.state.pictureHasDog = data.pictureHasDog;
      state.player.state.pictureHmw = data.pictureHmw;
      state.player.state.pictureUserImgIndex = data.pictureUserImgIndex;
      state.player.state.pictureDogImgIndex = data.pictureDogImgIndex;
      state.player.state.pictureElfImgIndex = data.pictureElfImgIndex;
    }
  },
  actions: {
    moveAndDetectNPCEvent({state}) {
      state.functions.NPCs.pressArrowKey()
    },
    set_special_aisle({state, commit}) {
      for (let aisle of state.player.state.passAisle) {
        commit('setSpecialCollidersAisle', aisle)
      }
    },
    async assignPlayerStateByCookie({state}) {
      let cookieState = await Cookies.get('cookieState0910')
      let cookieInfo = await Cookies.get('cookieInfo0910')
      let cookieItems = await Cookies.get('cookieItems0910')
      let cookieEvents = await Cookies.get('cookieEvents0910')
      let cookieMissionComplete = await Cookies.get('cookieMissionComplete0910')
      let cookieMissionOngoing = await Cookies.get('cookieMissionOngoing0910')
      if (cookieInfo) {
        cookieInfo = JSON.parse(cookieInfo)
        Vue.set(state.player, 'name', cookieInfo.name)
        Vue.set(state.player, 'elfName', cookieInfo.elfName)
        Vue.set(state.player, 'myStone', cookieInfo.myStone)
        Vue.set(state.player, 'hmw', cookieInfo.hmw)
        Vue.set(state.player, 'avatar', cookieInfo.avatar)
      }
      if (cookieState) {
        cookieState = JSON.parse(cookieState)
        // console.log(cookieState)
        await Vue.set(state.player, 'state', cookieState)
      }
      if (cookieItems) {
        // console.log(cookieItems)
        cookieItems = JSON.parse(cookieItems)
        Vue.set(state.player.state, 'items', cookieItems)
      } else { Vue.set(state.player.state, 'items', []) }
      if (cookieEvents) {
        // console.log(cookieEvents)
        cookieEvents = JSON.parse(cookieEvents)
        Vue.set(state.player.state, 'events', cookieEvents)
      }
      // else { state.player.state.events = [] }
      if (cookieMissionComplete != null) {
        cookieMissionComplete = JSON.parse(cookieMissionComplete)
        Vue.set(state.player.state, 'missionComplete', cookieMissionComplete)
      }
      // else { state.player.state.missionComplete = [] }
      if (cookieMissionOngoing != null) {
        cookieMissionOngoing = JSON.parse(cookieMissionOngoing)
        Vue.set(state.player.state, 'missionOngoing', cookieMissionOngoing)
      } else { state.player.state.missionOngoing = [] }
      // console.log(state.player)
      // Vue.set(state.player, 'interaction', cookie.interaction)
      // note that now interaction no need to record
    },
    async savePlayerStateInCookie({state}) {
      // basic info
      const playerInfo = {
        'name': state.player.name,
        'elfName': state.player.elfName,
        'myStone': state.player.myStone,
        'hmw': state.player.hmw,
        'avatar': state.player.avatar,
      }
      // state
      let playerState = JSON.parse(JSON.stringify(state.player.state))
      delete playerState['items']
      delete playerState['events']
      delete playerState['missionComplete']
      await delete playerState['missionOngoing']
      // console.log(playerInfo)
      Cookies.set('cookieInfo0910', playerInfo, { expires: 365 })
      Cookies.set('cookieState0910', playerState, { expires: 365 })
      Cookies.set('cookieItems0910', state.player.state.items, { expires: 365 })
      Cookies.set('cookieEvents0910', state.player.state.events, { expires: 365 })
      Cookies.set('cookieMissionComplete0910', state.player.state.missionComplete, { expires: 365 })
      Cookies.set('cookieMissionOngoing0910', state.player.state.missionOngoing, { expires: 365 })
    },
    delEvents({state}, delEvent) {
      for (let i in state.player.state.events) {
        let event = state.player.state.events[i]
        if (event == delEvent) {
          state.player.state.events.splice(i, 1)
        }
      }
    },
    async takeOnBoat({state, commit}) {
      let dom = document.getElementsByClassName('dog')[0]
      if (dom) dom.style.transition = '0s'
      await delay(10)
      commit('setPlayerCoordinate', {'x': 160, 'y': 299})
      await delay(300)
      commit('setPlayerCoordinate', {'x': 160, 'y': 300})
      await delay(200)
      state.player.state.hasBoat = true
      if (dom) dom.style.transition = '0.22s linear'
    },
    async takeOffBoat({state, commit}) {
      let dom = document.getElementsByClassName('dog')[0]
      if (dom) dom.style.transition = '0s'
      state.player.state.hasBoat = false
      await commit('setPlayerCoordinate', {'x': 160, 'y': 297})
      if (dom) dom.style.transition = '0.22s linear'
    },
    async transfer({state, commit}, coordinate) {
      const world_dom = document.getElementsByClassName('world')[0]
      world_dom.style['animation'] = 'blackFade 2s both linear'
      commit('labelAvatarIsActing', true)
      await delay(1000)
      await commit('setPlayerCoordinate', coordinate)
      state.functions.NPCs.pressArrowKey()
      await delay(1000)
      commit('labelAvatarIsActing', false)
      world_dom.style['animation'] = ''
    },
    async transferAndDialog({state, dispatch}, coordinate) {
      await dispatch('transfer', coordinate)
      state.functions.dialog.pressA()
    },
    async readyToTakePicture({state, commit, dispatch}) {
      const dialog_dom = document.getElementsByClassName('dialog_box')[0]
      const atou_dom = document.getElementsByClassName('AtouShot')[0]
      dialog_dom.style.display = 'none'
      commit('labelAvatarIsActing', true)
      await dispatch('transfer', {'x': 206, 'y': 24})
      state.player.isTakingPicture = true
      atou_dom.style.display = 'block'
      await delay(1000)
      dialog_dom.style.display = 'block'
    },
    async takePicture({state, commit}) {
      // console.log('take!')
      commit('playSoundEffect', 'take_pic')
      const dialog_dom = document.getElementsByClassName('dialog_box')[0]
      const atou_dom = document.getElementsByClassName('AtouShot')[0]
      const world_dom = document.getElementsByClassName('world')[0]
      dialog_dom.style.display = 'none'
      await delay(1000)
      world_dom.style['animation'] = 'pictureFlash 1s both'

      // execute take picture function
      state.modal.components['item_picture'].$refs.modal.open = true
      state.modal.components['item_picture'].$refs.modal.show = false
      await delay(500)
      state.modal.components['item_picture'].generate(commit)
      // const updatedPictureData = state.modal.components['item_picture'].generate()
      // console.log('updatedPictureData', updatedPictureData)
      // await commit('updatePicture', updatedPictureData)
      // takePictureFunction()
      await delay(500)
      state.modal.components['item_picture'].$refs.modal.open = false
      state.modal.components['item_picture'].$refs.modal.show = true

      await delay(2000)
      world_dom.style['animation'] = 'blackFade 2s both linear'
      await delay(1000)
      atou_dom.style.display = 'none'
      await commit('setPlayerCoordinate', {'x': 184, 'y': 53})
      await delay(1000)
      dialog_dom.style.display = 'block'
      state.player.isTakingPicture = false
      world_dom.style['animation'] = 'none'
      commit('labelAvatarIsActing', false)
    },
    getNewAislePass({commit}, value) {
      commit('pushNewPassAisle', value)
      commit('setSpecialCollidersAisle', value)
    },
    async activateCableCar({state, commit, dispatch}) { // 236, 58 -> 125, 222 | 86, 164
      const cable_msec = 50000
      const map_dom = document.getElementsByClassName('map')[0]
      const ori_sec = map_dom.style.transition
      dispatch('forbidActionForMillisecond', cable_msec+2700) // 2700ms for buffer
      await delay(500)
      // set the position
      await commit('setPlayerCoordinate', {'x': 256, 'y': 54})
      await delay(1000)
      // start
      // commit('stopAllSounds')
      commit('stopAllBGMSounds');
      commit('stopAllEffectSounds');
      commit('playSoundEffect', 'take_cable_car')
      const cable_car_dom = document.getElementsByClassName('CableCar')[0]
      cable_car_dom.style.display = 'none'
      state.player.isInCableCar = true // make z-index highest
      map_dom.style.transition = (cable_msec/1000)+'s linear'
      await commit('setPlayerCoordinate', {'x': 108, 'y': 230})
      await delay(cable_msec)
      // finish
      const cable_car_open_dom = document.getElementsByClassName('CableCarOpen')[0]
      const cable_car_bottom_dom = document.getElementsByClassName('CableCarBottom')[0]
      cable_car_open_dom.style.display = 'block'
      state.player.isInCableCar = false
      map_dom.style.transition = ori_sec
      state.functions.avatar.calculateAvatarPosition(0, 1, false, false, true)
      await delay(300)
      state.functions.avatar.calculateAvatarPosition(0, 1, false, false, true)
      await delay(300)
      state.functions.avatar.calculateAvatarPosition(0, 1, false, false, true)
      await delay(300)
      cable_car_open_dom.style.display = 'none'
      cable_car_bottom_dom.style.display = 'block'
      await delay(300)
      cable_car_bottom_dom.style.transform = 'translate3d(1000px, -1190px, 2000px)'
      await delay(20000)
      cable_car_dom.style.display = 'block'
      cable_car_bottom_dom.style.display = 'none'
      // 1.19
      commit('stopAllEffectSounds');
    },
    reorder_items({state}) {
      state.backpack.sortedItems = []
      const orders = ['寶物', '道具', '石頭', '花', '素材', '水生', '植物', '昆蟲', '垃圾']

      for (let order of orders) {
        for (let i = state.player.state.items.length - 1; i >= 0; i--) {
          let item = state.player.state.items[i]
          if (order == state.items[item.name].category) {
            state.backpack.sortedItems.push(item)
          }
        }
      }
      // console.log(state.backpack.sortedItems)
    },
    reorder_missions({state}) {
      state.backpack.sortedMission = []
      const myComplete = state.player.state.missionComplete
      const myOngoing = state.player.state.missionOngoing
      for (let missionName of myOngoing) {
        if (state.missions[missionName] && !state.missions[missionName].noShow) {
          state.backpack.sortedMission.push({
            'name': missionName,
            'status': 'missionOngoing'
          })
        }
      }
      for (let missionName of myComplete) {
        if (state.missions[missionName] && !state.missions[missionName].noShow) {
          // console.log(state.missions[missionName])
          state.backpack.sortedMission.push({
            'name': missionName,
            'status': 'missionComplete'
          })
        }
      }
      state.backpack.sortedMission.reverse()
      // console.log(this.backpack.sortedMission)
    },
    // async endLoading({state, commit, dispatch}) {
      // state.isLoading = false;
      // commit('initialSounds')
      // if (state.player.state.isFirst) dispatch('activate_newbie_teaching')
    // },
    activate_newbie_teaching({state, dispatch, commit}) {
      // setTimeout(() => {
      state.isInTeachingMode = true
      // }, 2000)
    },
    completeFirst({commit}) {
      setTimeout(() => {
        commit('setPlayerCompleteFirst')
      }, 3000)
    },
    clearFog({state}) {
      document.getElementsByClassName('fog')[0].style.animation = 'fadeOut 2s both'
      setTimeout(() => {
        state.player.state.clearFog = true
      }, 3000)
    },
    missionComplete({state, commit, dispatch}, missionName) {
      state.player.state.missionComplete.push(missionName)
      if (missionName == 'Counter') state.player.state.missionMax = 11
      const missionInfo = state.missions[missionName]
      if (!missionInfo.noShow) {
        let missionContent = state.player.interaction.nowAnswers[state.player.interaction.nowAnswerIndex].complete
        if (missionContent === true) {
          const title = missionInfo.missionTitle
          state.functions.reminder('完成了任務：' + title + ' !')
        }
        else if (typeof missionContent == 'string') {
          state.functions.reminder(missionContent)
        }
      }
      // del ongoing
      for (let i in state.player.state.missionOngoing) {
        if (state.player.state.missionOngoing[i] == missionName) {
          state.player.state.missionOngoing.splice(i, 1)
          break
        }
      }
    },
    postIt({state}, payload) {
      state.functions.postit.post(payload)
    },
    pickUpAndDelDrop({state, dispatch}, coordinate) {
      // del appearence of drop
      for (let index in state.appearence.drops) {
        let dropCoordinate = state.appearence.drops[index]
        if (dropCoordinate.x == coordinate.x && dropCoordinate.y == coordinate.y) {
          if (state.appearence.drops[index].name == 'elf') dispatch('elfJump', index)
          else {
            // state.appearence.drops.splice(index, 1)
            Vue.set(state.appearence.drops[index], 'x', -1000)
            Vue.set(state.appearence.drops[index], 'y', -1000)
            // means del its appearence on map
          }
        }
      }
      // del on map value
      let yArray = state.map.drop[coordinate.x]
      let yIndex = coordinate.y
      Vue.set(yArray, yIndex, false)
    },
    elfJump({state},index) {
      // console.log(document.getElementsByName(index)[0])
      // console.log(index)
      const dom = document.getElementsByName(index)[0]
      dom.classList.add('elfJump')
      state.appearence.nowActiveElf = index
    },
    elfRun({state}) {
      // call by dialog.vue (press A to end dialog)
      let index = state.appearence.nowActiveElf
      const dom = document.getElementsByName(index)[0]
      dom.classList.remove('elfJump')
      if (get_random_int(2) == 0) dom.classList.add('elfRun')
      else {
        dom.classList.add('elfRun')
        dom.classList.add('elfRunReverse')
      }
    },
    dropSth({commit}, payload) {
      if (payload.value != 'empty') {
        // empty means: no things drop!
        commit('assignDropMapValue', { // map value
          'x': payload.x,
          'y': payload.y,
          'value': payload.value
        })
        commit('addToDropList', { // appearence
          'x': payload.x,
          'y': payload.y,
          'name': payload.value
        })
      }
    },
    async openModal({commit, state}, modalName) {
      await commit('setModalOpenTrue', modalName)
      state.modal.components[modalName].$refs.modal.$refs.scrollbox.focus()

      // initial keyboard functions
      const modalComponent = state.modal.components[modalName].$refs.modal
      state.functions.modal.pressDownA = modalComponent.press_down_A
      state.functions.modal.pressUpA = modalComponent.press_up_A
      state.functions.modal.pressEsc = modalComponent.press_esc

      // disable click to close
      if (check_mobile()) {
        // modalComponent.$refs.map_modal.style.pointerEvents = 'none'
        modalComponent.$refs.scrollbox.style.pointerEvents = 'auto'
      }

      if(modalName=='item_picture') {
        setTimeout(() => {
          state.modal.components['item_picture'].draw()
        }, 200);
      }

      commit('playSoundEffect', 'tog_popup')
    },
    async closeModal({commit, state}, modalName) {
      await commit('setModalOpenFalse', modalName)

      commit('playSoundEffect', 'tog_popup')
    },
    inventorySwitch({commit, state}, bool) {
      console.log(state.backpack.isOpen)
      if(!state.backpack.isOpen) {
        commit('playSoundEffect', 'open_b')
      } else {
        commit('playSoundEffect', 'close_b')
      }
      commit('toggleBackpack', bool)
    },
    forbidActionForMillisecond({commit}, ms) {
      commit('labelAvatarIsActing', true)
      setTimeout(() => {
        commit('labelAvatarIsActing', false)
      }, ms)
    },
    toggleBGMSoundSwitch({state, commit}) {
      if(state.backpack.isSoundBGMOpened) {
        state.backpack.isSoundBGMOpened = false;
        commit('stopAllBGMSounds');
      } else {
        state.backpack.isSoundBGMOpened = true;
      }
    },
    toggleBGMSound({commit, state, dispatch}) {
      dispatch('toggleBGMSoundSwitch')
      commit('checkCurrentGridSound')
    },
    toggleEffectSoundSwitch({state, commit}) {
      if(state.backpack.isSoundEffectOpened) {
        state.backpack.isSoundEffectOpened = false;
        commit('stopAllEffectSounds');
      } else {
        state.backpack.isSoundEffectOpened = true;
      }
    },
    toggleEffectSound({commit, state, dispatch}) {
      dispatch('toggleEffectSoundSwitch')
      // commit('checkCurrentGridSound')
    },
    editItemNumber({state}, item) {
      for (let i in state.player.state.items) {
        let myItem = state.player.state.items[i]
        if (myItem.name == item.name) {
          myItem.number = item.number
        }
        if (myItem.number == 0) {
          state.player.state.items.splice(i, 1)
        }
      }
    },
    getNewItem({dispatch, commit, state}, reward) {
      if (reward.name == 'elf') reward.name = 'seed' // elf give u seed
      let hasThisItem = false
      for (let i in state.player.state.items) {
        let myItem = state.player.state.items[i]
        if (reward.name == myItem.name) {
          myItem.number++
          hasThisItem = true
        }
      }
      if (!hasThisItem) { // it's first time to get this item
        let json = {'name': reward.name, 'number': 1}
        state.player.state.items.push(json)
      }

      if (reward.name == 'seed') {
        setTimeout(() => commit('playSoundEffect', 'hmw'), 600)
        dispatch('elfSayHMW')
      } else if (reward.name != 'passport' && reward.name != 'badge') {
        setTimeout(() => commit('playSoundEffect', 'gain_obj'), 300)
        // state.functions.reminder('得到 ' + reward.name + ' 了！')
        let dialogJson = {
          'changeName': state.player.name,
          'npcDialog': '得到<mark>'+state.items[reward.name].name+'</mark>囉！按 B 打開背包來查看吧。'
        }
        setTimeout(() => {
          state.functions.dialog.dialog(dialogJson)
          state.player.interaction.whatIGet = reward.name
        }, 300)
      }
    },
    elfSayHMW({state}) {
      const random = get_random_int(state.hmw.length)
      const hmw = state.hmw[random]
      let dialogJson = {
        'changeName': '小小人',
        'npcDialog': 'How Might Weeee!',
        'player': [
          {
            'elf': hmw.hmw,
            'from': '來自 '+hmw.y+' 年迴響計畫第 ' + hmw.id + ' 組<br>' + hmw.members
          }
        ]
        // note that elf is a special command, it will made a new dialog in special rule
      }
      setTimeout(() => {
        state.functions.dialog.dialog(dialogJson)
      }, 300)
    },
    async goFishing({state, commit, dispatch}, fishery) {
      const random = get_random_int(5) + 1
      state.player.interaction.isFishing = true
      state.player.interaction.isActing = true
      return new Promise((resolve) => {
        commit('playSoundEffect', 'fly_fishing')
        setTimeout(async () => {
          const getItem = await dispatch('randomly_from', fishery)
          dispatch('getNewItem', {'name': getItem})
        }, (random * 1000) - 300) //
        setTimeout(async () => {
          state.player.interaction.isFishing = false
          state.player.interaction.isActing = false
          resolve(true)
        }, random * 1000)
      })

    },
    randomly_from({state}, category) {
      let total = 0
      for (let itemJson of state.drops[category]) {
        const key = Object.keys(itemJson)[0]
        total += itemJson[key]
      }
      const random = get_random_int(total)
      let min = 0
      for (let itemJson of state.drops[category]) {
        const itemName = Object.keys(itemJson)[0]
        const num = itemJson[itemName]
        const max = min + num
        if (min <= random && random < max) {
          return itemName
          break;
        }
        min += num
      }
    },
    reminder({state}, message) {
      state.functions.reminder(message)
    }
  }
});




Vue.use(VueTypedJs)
Vue.use(VueSweetalert2);
Vue.use(VueRouter)
Vue.use(router)
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc'  // Use <vc-calendar /> instead of <v-calendar />
  // ...other defaults
});
Vue.use(Vue2Editor);

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
window.__forceSmoothScrollPolyfill__ = true;

// if (document.domain == "rethinktaiwan.com" || document.domain == 'www.rethinktaiwan.com') {
  Vue.use(VueGtag, {
    config: { id: "G-617CMW4NLE" }
  });
// }

Vue.use(VueScrollactive);

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '[data-behavior="app"]', // 在 application.html.erb 找到是這個特徵的元素，原本是 #app，但我們透過 entry point 的 layout 那邊改成這個
    router: router,
    store, // vuex
    // components: {
    //   'vue-img-loader': VueImgLoader
    // }
    // render: h => h(App),
    // components: {
    //   Navbar,
    //   Marquee,
    //   Home,
    //   Universe,
    //   App,
    //   VueScrollactive
    // }
  })
})


// import 'flickity/dist/flickity.min.css';

// import Vue from 'vue'
// import BootstrapVue from 'bootstrap-vue'
// Vue.use(BootstrapVue);
// Vue.use(require('bootstrap-vue/dist/bootstrap-vue.common.min'));
// import { BTabs, BTab } from 'bootstrap-vue'
// Vue.component('b-tabs', BTabs)
// Vue.component('b-tab', BTab)

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)




// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
