<template>
  <Modal :name="'y2020_6'" ref="modal" :isWhite="true">
    <img src="./images/2020_6-2.jpg" alt="">
    <p>照片裡戴紅帽子的人，是二屆行動團隊，三、四屆的時候，多半由她來擔任主持人，特別是在營造氛圍、凝聚大家的心的時候。</p>
    <p>或許是某種同步、共時的巧合吧，第三、第四屆的青年團隊，有越來越多團隊在探討心靈層面所受到的創傷議題，領域觸及憂鬱、自殺、更生、部落薩滿、求學挫折...等不同的範圍。</p>
    <p>「閉上眼睛，深呼吸、長吐氣，行動過程中，「靜下來、停下來、放下來」也是無比重要的抉擇。」而我們也要適時鼓勵彼此，做出「不行動」的行動。</p>
    <img src="./images/2020_6-1.jpg" alt="">
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
