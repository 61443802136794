import { render, staticRenderFns } from "./planet-bravery.vue?vue&type=template&id=3d55f3d0&"
import script from "./planet-bravery.vue?vue&type=script&lang=js&"
export * from "./planet-bravery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports