<template>
  <Modal :name="'team_10'" ref="modal" :isWhite="true">
    <br>
    <br>
    <p>找一天試試看獨自專心用餐，不看3C螢幕等任何東西，過程中細細觀察自己身體有什麼細微反應？對用餐的空間有什麼五感上的新發現？</p>
    <p>在睡前簡短寫下今天讓你感到對自己滿意、幸福的瞬間，是發生了什麼事？接著寫下明天想如何給自己創造新一天？連續寫一週後再回頭檢視看有什麼有趣的發現。</p>
    <br>
    <br>
    <h5>＊以上資訊由迴響計畫 2019 生活擺態 提供</h5>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
