<template>
  <Modal :name="'partner_9'" ref="modal" :isWhite="true">
    <h5 class="t-center">行動夥伴</h5>
    <h3>好伴社計／家緯</h3>
    <p>關於【好伴社計】</p>
    <p>＃透過社會設計讓每個人都能成為改變社會的實踐者</p>
    <p>好伴社計是一間從台中出發的設計事務所，透過帶有社會設計內涵的企劃及實作，引導眾人一起解決複雜的公共議題，創造社會價值。</p>
    <p>好伴也持續將社會設計應用在教育創新、銀髮參與、青年行動等領域，召喚並連結多⽅關係⼈，共同設計出具有社會影響力的行動方案。</p>
    <br/>
    <h4 class="t-center">2020 致親愛的迴響</h4>
    <img src="./images/partner_9.jpeg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
