<template>
  <Modal :name="'y2019_4'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2019_4-1.jpg" alt=""> -->
    <p>和青年署的合作，每一年都能從過程中學習，也透過無數次來回的溝通、共創，也才更加明白，要從制度層次去推廣實驗性質的提案，需要做到真正站在彼此的立場，去同理彼此的處境。</p>
    <p>這一路走來，感謝在第四屆時，能有機會整理這過往的突破。</p>
    <p>不只是青年在行動呢！我們和公部門的夥伴，也一直在推動更多的可能，讓「行動精神」蔓延、轉化許多合作夥伴的 DNA！</p>
    <!-- <img src="./images/2019_4-2.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
