<template>
  <Modal :name="'y2019_7'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2019_7.jpg" alt=""> -->
    <p>有時候，的確什麼都不知道，會不小心誤闖禁區，但危險不該是阻礙行動的原因。</p>
    <p>評審們，有時候擔任的角色，就是讓青年更加明白，意圖或許良善，但「可以做得到的事」究竟有那些呢？團隊是否有真的認識利害關係人的需求？團隊如果要繼續走下去，有哪些專業的面向需要增進？</p>
    <p>為團隊找到業界實務經驗的評審，是迴響給予的另一個資源陪伴。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
