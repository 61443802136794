<template>
  <Modal :name="'y2018_stone'" ref="modal">
    <h3>2018 年，是所有東西都想要更好的一屆</h3>
    <p>第一次的「再來一遍」，有許多東西想留下，也有許多東西想改變，我們仍然在摸索的路上：什麼才是最好的作法？</p>
    <h4>確立迴響三大精神</h4>
    <p>從「指引性」的考量出發，把精神之一從「行動從不簡單，但會很值得」修正為「將失敗化為學習的機會」，到 2021 年為止都沒有再改動，你可以在迴響的各式文案、活動設計找到這些概念。</p>
    <h4>更盛大的行動節，同時也模糊了部分行動團隊的目標</h4>
    <p>從原本僅有最終 10 組團隊參與行動節，這屆迴響讓 30 組團隊通通參展了，找了華山紅酒廠的大型場地，讓足夠多的團隊與社會有更多元的對話</p>
    <p>對於行動團隊來說，的確促成很多與大眾的對話，但因為團隊的議題、行動階段多元，不是所有團隊在此刻都最需要曝光，對這種團隊，最終發表會與行動節變成分散心力的的兩個目標，影響動能。</p>
    <h4>第一次做迴響研究，模糊地從模糊中找線索</h4>
    <p>計畫結束後的半年，第一次停下腳步，試著了解「迴響計畫到底做對什麼」，從研究中，發現「行動工具」的使用，會影響團隊與教練間溝通的聚焦程度，影響下一屆開發迴圈工具包的基礎。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
