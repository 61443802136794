<template>
  <Modal :name="'y2018_6'" ref="modal">
    <p>又見面了，行動夥伴們。</p>
    <p>是這一屆的時候，</p>
    <p>王維綱說：「創業只有兩步路，這一步和下一步」；</p>
    <!-- <img src="./images/2018_6-1.jpg" alt=""> -->
    <p>Pin 李品汶每一次都會分享她的信仰：「Be the change you want to see in the world」；</p>
    <!-- <img src="./images/2018_6-2.jpg" alt=""> -->
    <p>而好久不見的楊宇綸，誠懇地分享自己如何突破關卡，走進人群述說與倡議；</p>
    <!-- <img src="./images/2018_6-3.jpg" alt=""> -->
    <p>至於阿德啊...不知道是不是這個時候說到：「反正你做不喜歡的事也很痛苦，幹嘛不就做喜歡的事，也是痛苦，至少你喜歡？」</p>
    <!-- <img src="./images/2018_6-4.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
