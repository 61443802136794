<template>
  <Modal :name="'y2017_stone'" ref="modal" :isWhite="true">
    <h3>2017，計畫開始的第一年，刻畫出最主要的模樣</h3>
    <h4>以「行動」為核心的孵化計畫：</h4>
    <p>想要報名活動，不是填寫一個報名表等待錄取通知，迴響作為一個鼓勵行動的計畫，要報名了，就直接動起來吧！以任務包取代書面審查，直接試著做做看，最早完成的 40 組正式加入當年的迴響計畫。</p>
    <h4>行動夥伴，一群也期待社會上有更多行動發生的人們：</h4>
    <p>一群在社會創新領域 3-5 年的行動先行者。行動夥伴的意見不代表正解，而是陪著團隊一起找答案。</p>
    <h4>三大精神，持續行動的基本信念</h4>
    <p>第一年的行動工具沒有那麼完整，但我們相信有些信念可以指引著大家持續前進，直到目前，這些精神都還是繼續沿用著。</p>
    <h4>協助青年行動的具體方法：</h4>
    <p>第一年以設計思考、精實創業為基礎，指引了迴響計畫的不同階段，還取了口語化的「來吧！問題」、「去吧！行動」、「上吧！大夥」的名字。每年，我們也會依照實際的狀況持續的迭代行動的架構設計。</p>
    <h4>以青年為本的實體活動：</h4>
    <p>像是：大會師的入場 Read Me First 寫著：「今天沒有規定，沒有非如何不可的事情，所有你靈光一閃想到可以做的事情，都可以做，所有你不想做的事情，都可以不做。」</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
