<template>
  <Modal :name="'y2017_7'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2017_7-1.jpg" alt=""> -->
    <p>嘿，第一群陪伴我們的行動夥伴。</p>
    <p>和青年保持一定的距離，在青年前行時，分享自己行動過程中的歷程，用著各自的方式進行交流。</p>
    <p>「為什麼這是你最想做的事呢？」</p>
    <p>「你從背後看見什麼時代訊息？」</p>
    <p>「用午餐的時間，一起聊聊看？」</p>
    <!-- <img src="./images/2017_7-2.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
