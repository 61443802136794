<template>
  <Modal :name="'s2_theory_of_change'" ref="modal">
    <p>
      密林深處有一塊空地，光線恰到好處，飛鳥魚獸都在聽<br>
      聽我們談談迴響當年的行動提案：「我們如何讓採取行動變成一件自然而然的事情」。
    </p>
    <br>
    <br>

    <h5>我們看到的問題</h5>
    <h4>社會議題看似完全無法解決的惡性循環</h4>
    <p>每個人都有這種時刻，看到了一些自己的可能性，很快的會因為現實的壓力、限制、機會成本而停止探索。這種事情在面對社會議題時，會有更大的阻力，會覺得自己更做不到些什麼事情，當這個開始變的越來越困難，整個社會回應公共議題的力道就會降低，因為我們看問題的觀點、提出階段性解法的人才、提出多元解方的人才都會受限，從而創造了「社會議題看似完全無法解決」的惡性循環。</p>
    <br>
    <br>

    <h5>我們認為問題的成因</h5>
    <h4>行動的預期投資報酬率太低</h4>
    <p>然而，現階段，鼓勵青年把想法變成行動，太沒有投資報酬率，離真正可見的改變，還有一段距離。做這件事情的價值和效益不容易被衡量，所以不容易找到願意單純贊助這件事情發生的資源擁有者。當大家的關注點都是成果時，開始的實驗、試錯、可笑但對個人發展或議題發展而言重要的成果，其價值就會被低估，進而被忽略。</p>
    <br>
    <br>

    <h5>如果這個問題不解決，會有什麼後果？</h5>
    <h4>掌握資源的人嘗試成本大，但社會需要各個層面的推動</h4>
    <p>於是乎，很多創新，都只能仰賴天時地利人和、機緣。而不是有系統、有階段的被推動出來。這會造成另一個問題，就是好像只有掌握巨大資源的人才能做出改變，但掌握巨大資源的人改變的機會成本過高，採取行動常常需要雷聲很大，面對系統性的問題時，卻不是靠一聲很大的雷聲來解決的，而是各個層面都有一定程度的變革和創新，於是，台灣這個社會，常常有很多單次故事，卻很少持續推動的變革。</p>
    <img src="./images/s2_theory_of_change.png" alt="">
    <br>
    <br>

    <h5>在這個議題下，我們想先聚焦在...</h5>
    <h4>建立行動的正向迴圈</h4>
    <p>當這個社會有越來越多青年勇於對社會議題展開行動，就有越來越多大議題下的小面向浮現，這些問題都不小，但沒有大到足以吸引主流社會的關注，於是他們都還有改善的空間，也就還有值得探索和行動的空間：</p>
    <p>浪犬議題下的飼主教育、無家者議題下的月經貧窮、地方議題下的牛車文化保存、兩性議題下的父親育兒、環保議題下的港口垃圾...</p>
    <p>還有好多好多看起來好像很大，似乎距離我們很遠的議題，像一道超大的牆，根本想像不到有越過去的一日。然而，一旦真的走近，就會發現一些我們其實可以施力的點，踏著這些點慢慢往上爬，就有機會被更多人看見。當這些議題被展開，我們就打開了一條新的路徑，原本覺得與議題毫不相干的青年，也有機會看到自己可以施力的地方，於是跟著一起爬了起來。</p>
    <br>
    <br>

    <h5>我們覺得可行的解決方案...</h5>
    <h4>行動的雪球效應</h4>
    <p>行動有機會成本，但也有機會效益，其實也不是什麼機會效益，就是機會而已。一個看的更遠、找到夥伴、爬的更好的機會，當然過程中還是會有力量不夠，構不著下一個岩點的時刻，此時只要與議題貼的更近就好，像開始時那樣，總會有我們可以施力的地方。</p>
    <p>行動的數量變多，案例也會變多，迴響提供的方法論、教練和行動工具，也會發展得越來越完整。當有一天關注這些議題的青年們，數量變的更多了，因為投入而能夠清楚地說出自己的故事時，大人、掌握資源的人就會看到，也願意下來一起幫忙。</p>
    <br>
    <br>

    <h5>想像這個問題被解決後的理想社會...</h5>
    <h4>然後就別再繞圈圈了，還是回到自己吧...</h4>
    <p>當我們連對社會議題都能乾脆的起而行，都能知道要運用哪些工具，都會懂得鼓勵身邊的人嘗試時，我們就會更有信心，在面對自己的議題，在做自己真的想做的事情，並且，在過程中更認識我們自己。等到這個社會上的每一個人，都清楚找到屬於他們的位置時，我們就會放下要攻擊他者、要詆毀別人的暴力，知道我們在這裡，我們附近還有誰，我們都需要彼此幫助，一起努力。</p>
    <p>我們期待迴響能用我們的行動方法和陪伴青年動起來的經驗，成為促成這個變革的一份子，現在看起來有點天方夜譚，像憑空變個理論出來，但如果你也同意，跟著一起行動了，這裡的故事，就會真的成為我們的變革理論。</p>
    <img src="" alt="">
    <br>
    <br>

    <h5>我們與這個問題的關係是...</h5>
    <h4>因為我們也是正在行動的人</h4>
    <p>不管你在那個年紀，心裡都有一個青年時的自己吧？你是什麼樣的青年呢？我想你一定考慮很多未來的事情，一定也有一些放在抽屜或電腦資料夾裡，寫的完整卻還沒有行動的「計畫」。</p>
    <p>不過，迴響做到第四年，從這天往回看，點開第一年時的企劃書，發現我們真的做到了，當時寫起來會被視為多麼天真的事情，像空的格子一一被更美好的故事塗滿，這種爽快真想分享給你。行動、試著做做看，真得很困難，沒有人認同的時候、太過在意的時候，又更困難，但...算了，給你決定。</p>
    <p>最後，如果你真的起步了，無論走到哪裡，我們都希望你可以偶爾，為那些剛開始的人打氣，包容他們犯下的錯誤，擁抱那些與你想法不同的可能性，因為我們是如此幸運，可以依靠身邊的人的包容、支持、不孤單的走到這裡。</p>
    <p>迴響的影響力報告書，看起來好像是想要證明我們做了多麼了不得的事情，其實那是我們給我們自己的打氣、給曾經參與過迴響的朋友的打氣，希望他們都知道，我們還在努力。如果迴響是一個禮物，真想送給你。</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
