<template>
  <Modal :name="'y2019_3'" ref="modal" :isWhite="true">
    <!-- <img src="./images/2019_3-1.jpg" alt=""> -->
    <p>終於，也在這一屆，完成了《行動旅人求生筆記》，是的，不是指南，就是我們的筆記。</p>
    <p>所有一開始的自我辯證，想要堅持的局面，接受到的幫助，得到業界評審獨特的支持和鼓舞。</p>
    <p>一群人一起踩出了一個起頭，待後人持續開挖自己的道路。</p>
    <!-- <img src="./images/2019_3-2.jpg" alt=""> -->
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
