<template>
  <Modal :name="'planet_union'" ref="modal" :isWhite="true">
    <h3>團結 Union</h3>
    <p class="t-center">「一個人走得快，一群人走得遠。」</p>
    <br />
    <p>團結是地球超人的戒指，是被投觸身球時大家一起衝上去，是等最後一個人吃完飯，是隨時注意大家在哪裡。</p>
    <p>迴響重視的價值是，可以的話常把「我們」放在心上，不覺得那是別人的事情。</p>
    <img src="../../images/world/NPC/events/Planet11Event.svg" alt=""/>
  </Modal>
</template>

<script>
import Modal from './modal.vue';

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
</style>
